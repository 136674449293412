import { useCallback, useState } from "react";

function useDialog(defaultIsOpen = false) {
    const [isOpen, setIsOpen] = useState(defaultIsOpen);

    const closeDialog = useCallback(() => setIsOpen(false), []);
    const openDialog = useCallback(() => setIsOpen(true), []);
    const toggleDialog = useCallback(() => setIsOpen((wasOpen) => !wasOpen), []);

    return {
        isOpen,
        openDialog,
        closeDialog,
        toggleDialog,
        open: openDialog,
        close: closeDialog,
        toggle: toggleDialog,
    };
}

export default useDialog;
