import { Grow, Popper, styled } from "@mui/material";
import { TFunction } from "i18next";
import { SearchResult } from "../../domain/search";
import SearchResultPopupContainer from "./SearchResultPopupContainer";

const AboveModalPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.modal + 1, // on mobile it has to overlay the mobile menu
}));

interface ISearchResultPopupProps {
    anchorEl: HTMLElement | null;
    onHide: () => void;
    show: boolean;
    translate: TFunction;
    searchKey: any;
    isValidKey: boolean;
    searchInProgress: boolean;
    results?: SearchResult[];
}

const SearchResultPopup = ({
    show,
    anchorEl,
    onHide,
    translate,
    searchKey,
    isValidKey,
    searchInProgress,
    results,
}: ISearchResultPopupProps) => {
    const handleHide = (e: MouseEvent | TouchEvent) => {
        const { target } = e;
        // ignore hide requests when clicking on search input element
        if (anchorEl != null && !anchorEl.contains(target as Node)) {
            onHide();
        }
    };

    return (
        <AboveModalPopper
            placement="bottom-end"
            anchorEl={anchorEl}
            open={show}
            modifiers={[
                {
                    name: "flip",
                    enabled: true,
                },
                {
                    name: "preventOverflow",
                    enabled: true,
                    options: {
                        altAxis: true,
                    },
                },
            ]}
            popperOptions={{
                strategy: "fixed",
            }}
            transition
        >
            {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                    <SearchResultPopupContainer
                        searchKey={searchKey}
                        isValidKey={isValidKey}
                        onSelect={onHide}
                        translate={translate}
                        onHide={handleHide}
                        results={results}
                        searchInProgress={searchInProgress}
                    />
                </Grow>
            )}
        </AboveModalPopper>
    );
};
export default SearchResultPopup;
