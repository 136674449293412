import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CardContent, Skeleton, Stack, Typography, styled } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../components/Tooltip";
import { translationKeys } from "../../../translations/main-translations";

type EmbeddedObjectRootProps = {
    isLoading: boolean;
    isError: boolean;
    helperText?: string;
    errorTemplate: ReactNode;
};

const RootContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(1.5),
    "&:last-child": {
        paddingBottom: theme.spacing(1.5),
    },
}));

const Container = styled("div")(() => ({
    cursor: "not-allowed",
    display: "grid",
    gridTemplateAreas: "content",
    alignItems: "center",
    justifyItems: "center",
}));

const HelperText = styled(Tooltip)(() => ({
    cursor: "default",
    gridArea: "content",
    zIndex: 1,
}));

const ErrorContent = styled("div")(({ theme }) => ({
    gridArea: "content",
    filter: "blur(4px)",
    pointerEvents: "none",
    userSelect: "none",
    opacity: 0.2,
    padding: theme.spacing(1),
}));

export const EmbeddedObjectRoot = ({
    isLoading,
    isError,
    helperText,
    children,
    errorTemplate,
}: PropsWithChildren<EmbeddedObjectRootProps>) => {
    const { t } = useTranslation();

    if (isError) {
        return (
            <RootContent>
                <Container>
                    <HelperText title={helperText ?? t(translationKeys.VDLANG_FEED_EMBEDDED_OBJECT_NO_ACCESS_DESCRIPTION)}>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="body2" fontWeight="medium">
                                {helperText ?? t(translationKeys.VDLANG_FEED_EMBEDDED_OBJECT_NO_ACCESS)}
                            </Typography>
                            <InfoOutlinedIcon />
                        </Stack>
                    </HelperText>
                    <ErrorContent>{errorTemplate}</ErrorContent>
                </Container>
            </RootContent>
        );
    }

    if (isLoading) {
        return (
            <RootContent>
                <Stack spacing={1}>
                    <Typography variant="body2">
                        <Skeleton width="60%" />
                    </Typography>
                    <Typography variant="subtitle1">
                        <Skeleton width="80%" />
                    </Typography>
                    <Typography variant="body2">
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Typography>
                </Stack>
            </RootContent>
        );
    }

    return <RootContent>{children}</RootContent>;
};
