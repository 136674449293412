import { useCallback, useMemo } from "react";
import { z } from "zod";
import { useLanguage } from "./useLanguage";

const zNumber = z.coerce.number();

export function useAlphanumericStringSort(options?: Intl.CollatorOptions) {
    const language = useLanguage();

    const collator = useMemo(() => {
        return new Intl.Collator(language, { numeric: true, sensitivity: "base", ...options });
    }, [language, options]);

    const compare = useCallback(
        (a: string, b: string) => {
            const aParsedNumber = zNumber.safeParse(a);
            const bParsedNumber = zNumber.safeParse(b);
            if (aParsedNumber.success && bParsedNumber.success) {
                return aParsedNumber.data - bParsedNumber.data;
            }
            return collator.compare(a, b);
        },
        [collator],
    );

    return { compare };
}
