import { styled, Typography } from "@mui/material";
import { PlaceholderProps } from "react-select";
import { dropLegacyRef } from "../../../../lib/utils";
import { Option } from "../types";

const StyledSelectPlaceholder = styled(Typography)(({ theme }) => ({
    // Position absolutely, so that the placeholder does not move the input to the right
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
}));

const SelectPlaceholder = <OptionType extends Option, IsMulti extends boolean>({
    children,
    innerProps,
}: PlaceholderProps<OptionType, IsMulti>) => {
    return (
        <StyledSelectPlaceholder {...innerProps} color="textSecondary" ref={dropLegacyRef(innerProps.ref)}>
            {children}
        </StyledSelectPlaceholder>
    );
};

export default SelectPlaceholder;
