import React, { MutableRefObject, Ref } from "react";

// Hook taken from MaterialUI utils (not exposed as export)

function setRef<T>(ref: Ref<T> | undefined, value: T) {
    if (typeof ref === "function") {
        ref(value);
    } else if (ref) {
        // refs are typed as readonly
        // See: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31065
        (ref as MutableRefObject<T>).current = value;
    }
}

/**
 * This hook "merges" up to three refs and returns a new ref, which is useful in cases where more that one ref has to be attached to a dom node.
 * E.g. when combining MaterialUI components with react-select and both components need access to the same dom node via different refs.
 *
 * @export
 * @template T
 * @param {Ref<T>} [refA]
 * @param {Ref<T>} [refB]
 * @param {Ref<T>} [refC]
 * @returns
 */
export default function useForkedRef<T>(refA?: Ref<T>, refB?: Ref<T>, refC?: Ref<T>) {
    /**
     * This will create a new function if the ref props change and are defined.
     * This means react will call the old forkRef with `null` and the new forkRef
     * with the ref. Cleanup naturally emerges from this behavior
     */
    return React.useMemo(() => {
        if (refA == null && refB == null && refC == null) {
            return null;
        }
        return (refValue: T) => {
            setRef(refA, refValue);
            setRef(refB, refValue);
            setRef(refC, refValue);
        };
    }, [refA, refB, refC]);
}
