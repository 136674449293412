import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import {
    Button,
    Collapse,
    Grid,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemProps,
    ListItemText,
    MenuItem,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import { CommentDto } from "api-shared";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MarkdownMentions from "../../components/MarkdownMentions";
import ShowMore from "../../components/ShowMore";
import { useAllUsers, useCurrentUserId } from "../../domain/users";
import useTimezone from "../../hooks/useTimezone";
import { isSubmitEvent } from "../../lib/keybindings";
import { translationKeys } from "../../translations/main-translations";
import DateTz from "../DateTz";
import MarkdownEditorWithoutMentions, { MarkdownEditorUserMentionsOwnProps } from "../markdowneditor/MarkdownEditorWithoutMentions";
import MoreActionsMenu from "../MoreActionsMenu";
import Tooltip from "../Tooltip";
import UserEntryWithPopup from "../user/UserEntryWithPopup";
import UserName from "../user/UserName";
import { CommentBubble } from "./CommentBubble";

const editorClass = "Vd-Comment-editor";

const EditableCommentBubble = styled(CommentBubble)(({ theme }) => ({
    [`& .${editorClass}`]: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
    marginTop: theme.spacing(-0.75),
    flexShrink: 0,
}));

const HintText = styled("div")(({ theme }) => ({
    ...theme.typography.caption,
    color: theme.palette.text.disabled,
}));

const HeaderText = styled(Grid)(({ theme }) => ({
    paddingBottom: theme.spacing(0.5),
    flexGrow: 1,
    flexShrink: 1,
}));

interface ICommentProps extends Omit<ListItemProps, "button" | "translate"> {
    updateComment: (id: number, newText: string) => void;
    deleteComment: (id: number) => void;
    comment: CommentDto;
    customEditorComponent?: React.ComponentType<MarkdownEditorUserMentionsOwnProps>;
    disabled?: boolean;
}

const isValidComment = (newCommentText: string) => newCommentText.trim().length > 0;

const Comment = ({ comment, updateComment, deleteComment, customEditorComponent, disabled, ...listItemProps }: ICommentProps) => {
    const EditorComponent = customEditorComponent ?? MarkdownEditorWithoutMentions;

    const [newCommentText, setNewCommentText] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const users = useAllUsers();
    const currentUserId = useCurrentUserId();

    const { formatDateTime } = useTimezone();

    // keep an independent reference to the newCommentText
    // will be used by submitOnEnter callback so that it can stay stable even if newCommentText changes
    const tmpValue = useRef("");

    const { t: translate } = useTranslation();

    const updateNewCommentText = useCallback(
        (n: string) => {
            tmpValue.current = n;
            setNewCommentText(n);
        },
        [tmpValue],
    );

    const toggleEditComment = () => setIsEditing(!isEditing);

    const saveComment = useCallback(() => {
        updateComment(comment.id, tmpValue.current);
        setIsEditing(false);
    }, [comment.id, setIsEditing, updateComment, tmpValue]);

    const submitOnEnter = React.useCallback(
        (e: KeyboardEvent) => {
            if (isSubmitEvent(e) && isValidComment(tmpValue.current)) {
                saveComment();
            }
        },
        [saveComment, tmpValue],
    );
    const descriptionRef = useRef(null);

    return (
        <ListItem alignItems="flex-start" {...listItemProps}>
            <ListItemAvatar>
                <UserEntryWithPopup iconOnly user={users.find((u) => u.id === comment.createdById)} />
            </ListItemAvatar>
            <EditableCommentBubble>
                <Stack direction="row" alignItems="flex-start">
                    <HeaderText container alignItems="center">
                        <Typography component="div">
                            <b>
                                <UserName userId={comment.createdById} users={users} translate={translate} />
                            </b>
                        </Typography>
                        <HintText>
                            &nbsp;•&nbsp;
                            <DateTz fromNow date={comment.createdAt} withTooltip />
                        </HintText>
                        {comment.createdAt !== comment.updatedAt ? (
                            <HintText>
                                &nbsp;•&nbsp;
                                <Tooltip
                                    title={translate(translationKeys.VDLANG_COMMENT_EDITED_HINT, {
                                        date: formatDateTime(comment.updatedAt),
                                    })}
                                >
                                    <span>{translate(translationKeys.VDLANG_COMMENT_EDITED)}</span>
                                </Tooltip>
                            </HintText>
                        ) : null}
                    </HeaderText>
                    {comment.createdById === currentUserId && !disabled && (
                        <MoreActionsMenu
                            actions={[
                                <MenuItem key="edit" disabled={isEditing} onClick={toggleEditComment}>
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("Edit")} />
                                </MenuItem>,
                                <MenuItem key="delete" onClick={() => deleteComment(comment.id)}>
                                    <ListItemIcon>
                                        <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("Delete")} />
                                </MenuItem>,
                            ]}
                            buttonComponent={MenuButton}
                            buttonProps={{ size: "small" }}
                        />
                    )}
                </Stack>

                <Collapse in={isEditing}>
                    <Grid container spacing={1}>
                        {isEditing && (
                            <Grid item xs={12}>
                                <EditorComponent
                                    // make sure to pass only memoized callbacks here so that editor only re-renders if really needed
                                    value={comment.comment}
                                    onChange={updateNewCommentText}
                                    onKeyDown={submitOnEnter}
                                    className={editorClass}
                                    hideToolbar
                                />
                            </Grid>
                        )}
                        <Grid item>
                            <Button size="small" onClick={toggleEditComment}>
                                {translate("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={saveComment}
                                disabled={!isValidComment(newCommentText)}
                            >
                                {translate("Save comment")}
                            </Button>
                        </Grid>
                    </Grid>
                </Collapse>
                <Collapse in={!isEditing}>
                    <ShowMore height={240} translate={translate} contentRef={descriptionRef}>
                        <MarkdownMentions markdown={comment.comment} ref={descriptionRef} />
                    </ShowMore>
                </Collapse>
            </EditableCommentBubble>
        </ListItem>
    );
};

export default React.memo(Comment);
