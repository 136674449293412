import { SubTaskDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { useMeasureConfig } from "../../domain/measure-config";

export const useSubTaskLevel = (subTask: SubTaskDto): string | null => {
    const { t: translate } = useTranslation();
    const measureConfig = useMeasureConfig(subTask.measure);
    if (subTask.gateTaskId === null || subTask.measure.gateTasks === undefined) {
        return null;
    }
    const gateTask = subTask.measure.gateTasks?.find((gateTask) => gateTask.id === subTask.gateTaskId);
    const levelName = measureConfig?.gateTaskConfigs.find((config) => config.id === gateTask?.gateTaskConfigId);
    return levelName ? translate(levelName.name) : null;
};
