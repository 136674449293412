import { TextField } from "@mui/material";
import { HTMLProps } from "react";
import { ControlProps } from "react-select";
import { Option } from "../types";
import SelectInternalInputComponent from "./SelectInternalInputComponent";

const SelectControl = <OptionType extends Option, IsMulti extends boolean>({
    selectProps,
    isDisabled,
    innerRef,
    children,
    innerProps,
}: ControlProps<OptionType, IsMulti>) => {
    const { error, label, required, margin, textFieldProps, size, smallGutters } = selectProps;
    return (
        <TextField
            fullWidth
            disabled={isDisabled}
            error={error}
            size={size}
            label={label}
            margin={margin ?? "normal"}
            required={required}
            {...textFieldProps}
            InputProps={{
                inputComponent: SelectInternalInputComponent,
                inputProps: {
                    children,
                    inputRef: innerRef,
                    smallGutters,
                    // event handlers need to be attached to a div element, which is rendered by SelectInternalInputComponent
                    // but inputProps are required to match HTMLProps<HTMLInputElement>
                    ...(innerProps as HTMLProps<HTMLInputElement>),
                },
                ...textFieldProps?.InputProps,
            }}
            ref={innerRef}
        />
    );
};

export default SelectControl;
