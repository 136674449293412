import PreviewIcon from "@mui/icons-material/Preview";
import { alpha, Stack, styled, Typography } from "@mui/material";
import { EstimatesValue } from "api-shared";
import Dotdotdot from "react-dotdotdot";
import { useTranslation } from "react-i18next";
import MarkdownMentions from "../../../components/MarkdownMentions";
import { useIdeaQuery } from "../../../domain/ideas";
import useDialog from "../../../hooks/useDialog";
import { trackEvent } from "../../../infrastructure/tracking";
import { translationKeys } from "../../../translations/main-translations";
import { useIdeaEstimateConversion } from "../../ideas/hooks";
import IdeaDialog from "../../ideas/IdeaDialog";
import EmbeddedIdeaError from "./EmbeddedIdeaError";
import { EmbeddedObjectRoot } from "./EmbeddedObjectRoot";

const ideaPreviewLinkIconClass = "Vd-EmbeddedIdea-ideaPreviewLinkIcon";
const contentStackClass = "Vd-EmbeddedIdea-contentStack";

const EmbeddedIdeaContainer = styled("div")(({ theme }) => ({
    position: "relative",
    cursor: "pointer",
    maxHeight: 320,
    ["@media (hover: hover)"]: {
        [`& .${ideaPreviewLinkIconClass}`]: {
            opacity: 0,
        },
        "&:hover": {
            [`& .${ideaPreviewLinkIconClass}`]: {
                opacity: 1,
            },
            [`& .${contentStackClass}`]: {
                mask: `linear-gradient(to right, ${theme.palette.background.paper}, ${alpha(theme.palette.background.paper, 0)} 100%)`,
            },
        },
    },
}));

const IdeaPreviewLinkIcon = styled(PreviewIcon)({ position: "absolute", right: 0, bottom: 0 });

const IdeaTitle = styled(Typography)({
    overflowWrap: "anywhere",
});

const EmbeddedIdea = ({ id }: { id: number }) => {
    const { t } = useTranslation();

    const ideaDialog = useDialog();

    const ideaQuery = useIdeaQuery(id);
    const title = ideaQuery.data?.title ?? "";
    const description = ideaQuery.data?.description ?? "";

    const getIdeaConversionValues = useIdeaEstimateConversion();

    function getPotentialEstimateValue(potentialEstimate: EstimatesValue): string {
        const { potentialValue } = getIdeaConversionValues({
            potentialEstimateValue: potentialEstimate,
        });
        return potentialValue.replace("–", " - ");
    }

    return (
        <>
            <EmbeddedObjectRoot isLoading={ideaQuery.isLoading} isError={ideaQuery.isError} errorTemplate={<EmbeddedIdeaError />}>
                {ideaQuery.isSuccess && (
                    <EmbeddedIdeaContainer
                        onClick={() => {
                            ideaDialog.open();
                            trackEvent({ category: "Feed", action: "Feed Idea Opened" });
                        }}
                    >
                        <Stack spacing={1} className={contentStackClass}>
                            <Typography variant="body2">
                                {t(translationKeys.VDLANG_ID)}: {ideaQuery.data.displayId} {t(translationKeys.VDLANG_IDEAS_IDEA)}
                                {ideaQuery.data.potentialEstimate !== null
                                    ? ` • ${getPotentialEstimateValue(ideaQuery.data.potentialEstimate)}`
                                    : ""}
                            </Typography>
                            <Dotdotdot clamp={2} useNativeClamp>
                                <IdeaTitle variant="subtitle1">{title}</IdeaTitle>
                            </Dotdotdot>
                            {description.length > 0 ? (
                                <Dotdotdot clamp={3} useNativeClamp>
                                    {/* Only add first 4 lines to component as ... will clamp it after 3 and markdown parsing with mentions is expensive */}
                                    <MarkdownMentions markdown={description.split("\n\n").slice(0, 4).join("\n\n")} />
                                </Dotdotdot>
                            ) : null}
                        </Stack>
                        <IdeaPreviewLinkIcon className={ideaPreviewLinkIconClass} />
                    </EmbeddedIdeaContainer>
                )}
            </EmbeddedObjectRoot>
            {ideaQuery.isSuccess && <IdeaDialog open={ideaDialog.isOpen} onClose={ideaDialog.close} currentIdea={ideaQuery.data} />}
        </>
    );
};

export default EmbeddedIdea;
