import { ClickAwayListener, ClickAwayListenerProps, Paper, Typography, styled } from "@mui/material";
import { TFunction } from "i18next";
import React, { CSSProperties } from "react";
import { SearchResult } from "../../domain/search";
import SearchResultList from "./SearchResultList";
import SearchResultTitle from "./SearchResultTitle";

const Root = styled(Paper)(({ theme }) => ({
    width: 600,
    backgroundColor: theme.palette.background.paper,
    // support animation from top-right corner
    transformOrigin: "right top 0",
    maxHeight: "calc( 100vh - 64px)", // navbar height is ~64px
    overflow: "auto",
}));

interface ISearchResultPopupContainerProps {
    style?: CSSProperties;
    isValidKey: boolean;
    onHide: ClickAwayListenerProps["onClickAway"];
    translate: TFunction;
    onSelect: () => void;
    searchKey: string;
    searchInProgress: boolean;
    results?: SearchResult[];
}

const SearchResultPopupContainer = React.forwardRef<HTMLDivElement, ISearchResultPopupContainerProps>(
    ({ style, isValidKey, onHide, translate, onSelect, searchKey, searchInProgress, results }, ref) => (
        <ClickAwayListener onClickAway={onHide}>
            {/* ref and styles provided by transition component need to be applied to the dom element */}
            <Root style={style} ref={ref} variant="elevation" elevation={6}>
                <Typography variant="body2" fontWeight="medium" sx={{ p: 2 }}>
                    <SearchResultTitle
                        isValidKey={isValidKey}
                        isSearchInProgress={searchInProgress}
                        results={results}
                        translate={translate}
                    />
                </Typography>
                <SearchResultList
                    results={results}
                    isSearchInProgress={searchInProgress}
                    searchKey={searchKey}
                    onSelect={onSelect}
                    isValidKey={isValidKey}
                    highlightMatches
                />
            </Root>
        </ClickAwayListener>
    ),
);

export default SearchResultPopupContainer;
