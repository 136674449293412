import { Chip, ChipProps, chipClasses, styled } from "@mui/material";

const InlineChipBase = styled(Chip, { shouldForwardProp: (prop) => prop !== "component" })<{ component: React.ElementType }>(
    ({ theme }) => ({
        marginLeft: theme.spacing(),
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.text.primary,
        cursor: "inherit",
        overflow: "hidden", // enable ellipsis shortening
        display: "inline",
        verticalAlign: "middle",
        ...theme.typography.caption,
        [`&.${chipClasses.sizeSmall}`]: {
            // inline elements cannot set height directly, so use padding of 2.5px on each side to achieve target height of 20px
            paddingTop: theme.spacing(0.3125),
            paddingBottom: theme.spacing(0.3125),
        },
    }),
);

const InlineChip = (props: Omit<ChipProps, "component">) => <InlineChipBase component="span" {...props} />;

export default InlineChip;
