import { useMutation } from "@tanstack/react-query";
import { FeedbackType } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../infrastructure/notifications";
import { apiPost } from "../lib/api";
import { NotificationType } from "../lib/notifications";
import { translationKeys } from "../translations/main-translations";

export const useFeedback = () => {
    const dispatch = useDispatch();

    return useMutation({
        mutationFn: ({ type, message }: { type: FeedbackType; message: string }) => apiPost("feedback", { type, message }),
        onSuccess: (response, variables) => {
            dispatch(showNotificationEvent(NotificationType.SUCCESS, translationKeys.VDLANG_FEEDBACK_DIALOG_SUCCESS));
        },
    });
};
