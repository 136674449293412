import AddIcon from "@mui/icons-material/AddRounded";
import {
    Divider,
    List,
    ListItem,
    ListItemButton,
    listItemButtonClasses,
    listItemClasses,
    ListItemIcon,
    ListItemText,
    ListProps,
    Skeleton,
    styled,
} from "@mui/material";
import { SubTaskDto, UserDto } from "api-shared";
import { TFunction } from "i18next";
import { times } from "lodash";
import React, { useCallback } from "react";
import { IUseCachedOrderResult } from "../../hooks/useCachedOrder";
import { translationKeys } from "../../translations/main-translations";
import SubtaskListItem from "./SubtaskListItem";

const DenseListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: theme.spacing(4),
}));

const StyledList = styled(List)(({ theme }) => ({
    // consistent vertical padding for all list items
    [`& .${listItemClasses.root}, & .${listItemButtonClasses.root}`]: {
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(),
    },
}));

const SubtaskListSkeleton = (listProps: ListProps) => (
    <StyledList dense {...listProps}>
        {times(10, (i) => (
            <ListItem key={i} sx={{ borderBottom: `1px solid transparent` }}>
                {/* Use body1 variant for 24px lineheight text, which matches SubtaskListItemContent's height */}
                <ListItemText sx={{ my: 0 }} primaryTypographyProps={{ variant: "body1" }}>
                    <Skeleton />
                </ListItemText>
            </ListItem>
        ))}
    </StyledList>
);
interface ISubtaskListProps extends Omit<ListProps, "translate" | "title"> {
    disabled?: boolean;
    tasks?: SubTaskDto[];
    translate: TFunction;
    users: UserDto[];
    onOpen?: (item: SubTaskDto) => void;
    isCreatable: boolean;
    onTaskAdd?: () => void;
    updateOrder?: IUseCachedOrderResult["updateOrder"];
    noActivitiesMessage?: string;
    title?: string | null;
    currentTaskId?: number | null;
    onTitleSave?: (newTitle: string, addNewTask?: boolean) => void;
    onTitleAbort?: () => void;
    showLevelChip?: boolean;
    isSortable?: boolean;
}

const SubtaskList = ({
    updateOrder,
    disabled,
    tasks,
    translate,
    users,
    title,
    currentTaskId,
    isCreatable,
    onOpen,
    onTitleSave,
    onTitleAbort,
    onTaskAdd,
    noActivitiesMessage,
    showLevelChip = false,
    isSortable: isSortableProps = false,
    ...listProps
}: ISubtaskListProps) => {
    const isSortable = !disabled && isSortableProps;
    const noDataMessage = noActivitiesMessage ?? translate(translationKeys.VDLANG_ACTIVITIES_NO_ACTIVITIES);

    const handleOpenClick = useCallback(
        (id: number) => {
            const item = tasks?.find((task) => task.id === id);
            onOpen && item && onOpen(item);
        },
        [onOpen, tasks],
    );

    if (tasks === undefined) {
        return <SubtaskListSkeleton {...listProps} />;
    }

    return (
        <StyledList dense {...listProps}>
            {tasks.map((item, index) => (
                <SubtaskListItem
                    isSortable={isSortable && item.id > 0}
                    key={item.id}
                    id={item.id}
                    onClick={onOpen != null && item.id > 0 ? handleOpenClick : undefined}
                    selected={item.id === currentTaskId}
                    divider={index < tasks.length - 1}
                    item={item}
                    translate={translate}
                    users={users}
                    saveTitle={onTitleSave}
                    onAbortTitle={onTitleAbort}
                    title={title !== null && item.id === currentTaskId ? title : undefined}
                    inset={listProps.subheader != null}
                    showLevelChip={showLevelChip}
                />
            ))}
            {!disabled && isCreatable && tasks.length > 0 && <Divider />}
            {!disabled && isCreatable ? (
                <ListItemButton divider onClick={onTaskAdd}>
                    <DenseListItemIcon>
                        <AddIcon />
                    </DenseListItemIcon>
                    <ListItemText primaryTypographyProps={{ color: "textSecondary" }}>
                        {translate(translationKeys.VDLANG_ACTIVITIES_NEW_ACTIVITY_PLACEHOLDER)}
                    </ListItemText>
                </ListItemButton>
            ) : null}
            {(disabled === true || !isCreatable) && tasks.length <= 0 ? (
                <ListItem>
                    <ListItemText>{noDataMessage}</ListItemText>
                </ListItem>
            ) : null}
        </StyledList>
    );
};

export default React.memo(SubtaskList);
