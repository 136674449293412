import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";

interface IFeedUserGreetingProps {
    name: string;
}

const FeedUserGreeting = ({ name }: IFeedUserGreetingProps) => {
    const { t: translate } = useTranslation();

    const hour = new Date().getHours();

    // 0:00 - 11:00: Good morning
    let greeting = translationKeys.VDLANG_GREETING_MORNING;

    if (hour >= 18) {
        // 18:00 - 24:00 Good evening.
        greeting = translationKeys.VDLANG_GREETING_EVENING;
    }

    if (hour > 11) {
        // 11:00 - 18:00: Good afternoon
        greeting = translationKeys.VDLANG_GREETING_AFTERNOON;
    }

    return (
        <>
            {`${translate(greeting)} ${name},`}
            <br />
            {translate(translationKeys.VDLANG_FEED_FOR)}
        </>
    );
};

export default FeedUserGreeting;
