import { DialogContentText, TextField } from "@mui/material";
import { FeedbackType } from "api-shared";
import { TFunction } from "i18next";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { useFeedback } from "../../domain/feedback";
import { isSubmitEvent } from "../../lib/keybindings";
import { translationKeys } from "../../translations/main-translations";
import ActionItemDialog, { IActionItemDialogProps } from "./ActionItemDialog";

interface FeedbackDialogProps extends Omit<IActionItemDialogProps, "primary" | "onPrimary"> {
    type: FeedbackType;
    onClose: () => void;
    translate: TFunction;
}

const FeedbackDialog = ({ onClose, type, translate, ...dialogProps }: FeedbackDialogProps) => {
    const [message, setMessage] = useState("");

    const sendFeedback = useFeedback();
    const isFilled = () => message.trim().length > 0;

    const onChange = (event: ChangeEvent<HTMLInputElement>) => setMessage(event.target.value);

    const submit = () => {
        sendFeedback.mutate({ type, message });
        setMessage("");
    };

    const onSubmit = (event: KeyboardEvent<HTMLInputElement>) => {
        if (isSubmitEvent(event) && isFilled()) {
            event.preventDefault(); // Prevent the dialog from opening again
            submit();
            onClose();
        }
    };

    return (
        <ActionItemDialog
            onClose={onClose}
            primary={translationKeys.VDLANG_FEEDBACK_DIALOG_SEND}
            onPrimary={submit}
            primaryDisabled={!isFilled()}
            fullWidth
            translate={translate}
            {...dialogProps}
        >
            <DialogContentText>{translate(translationKeys.VDLANG_FEEDBACK_DIALOG_TEXT)}</DialogContentText>
            <TextField
                name="message"
                label={translate(translationKeys.VDLANG_FEEDBACK_DIALOG_LABEL)}
                multiline
                maxRows={4}
                value={message}
                onChange={onChange}
                onKeyDown={onSubmit}
                margin="normal"
                fullWidth
            />
        </ActionItemDialog>
    );
};

export default FeedbackDialog;
