import { TreeNodeDto } from "api-shared";
import { useAlphanumericStringSort } from "../hooks/useAlphanumericStringSort";
import { useLanguage } from "../hooks/useLanguage";
import { filterFieldOptions, getRawFieldOptions, translateTreeNodes, useDataForTableName } from "../lib/field-options";
import { Field } from "../lib/fields";
import { sortTreeNodes } from "../lib/sort";
import { NamedTreeNode } from "../lib/tree";

interface TreeProviderProps<ComponentProps> {
    field: Field;
    component: React.ComponentType<ComponentProps & { data: NamedTreeNode[] }>;
    componentProps: ComponentProps;
}

function TreeProvider<ComponentProps>({ field, component: Component, componentProps }: Readonly<TreeProviderProps<ComponentProps>>) {
    const lang = useLanguage();
    const treeNodes = useDataForTableName(field.tableName) as TreeNodeDto[];
    const { compare } = useAlphanumericStringSort();

    // Get options for tree nodes (required for filterFieldOptions)
    // We don't need 'OriginatorClient' here, so we use an empty string for clientName
    const rawOptions = getRawFieldOptions(treeNodes, null, field, "");

    // Filter options based on the field's 'attribute_id' and cast the result back to TreeNodeDto[] (required type for
    // translateTreeNodes)
    const filteredTreeNodes = filterFieldOptions(rawOptions, field, "attribute_id") as unknown as TreeNodeDto[];

    const sortedTreeNodes = sortTreeNodes(filteredTreeNodes, lang, compare);
    const translatedNodes = translateTreeNodes(sortedTreeNodes, lang);

    return <Component {...componentProps} data={translatedNodes} />;
}

export default TreeProvider;
