import { Chip, Stack, Tooltip, Typography, chipClasses, darken, styled } from "@mui/material";
import { blue } from "@mui/material/colors";
import { blend } from "@mui/system";
import { Reaction, ReactionType } from "api-shared";
import { groupBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useAllUsers, useCurrentUserId } from "../../domain/users";
import useDialog from "../../hooks/useDialog";
import { translationKeys } from "../../translations/main-translations";
import EmojiIcon from "./EmojiIcon";
import { SmallIconButton } from "./ReactionButton";
import ReactionSummaryDialog from "./ReactionSummaryDialog";

const ReactionsChip = styled(Chip, { shouldForwardProp: (name) => name !== "defaultChecked" })(({ theme, defaultChecked }) => ({
    borderColor: defaultChecked ? theme.palette.info.light : theme.palette.divider,
    backgroundColor: defaultChecked ? blend(theme.palette.background.paper, blue[500], 0.08) : theme.palette.background.paper,
    [`&.${chipClasses.clickable}:hover`]: {
        backgroundColor: darken(theme.palette.background.paper, theme.palette.action.hoverOpacity),
    },
    padding: theme.spacing(0, 0.5, 0, 1),
    [`& .${chipClasses.label}`]: {
        ...theme.typography.caption,
        paddingLeft: 0.5,
        paddingRight: 1,
    },
}));

interface IReactionSummaryProps<T extends ReactionType> {
    reactionConfig: Map<T, { icon: string; translationKey: string }>;
    allUserReactions: Reaction<T>[];
    className?: string;
    variant?: "standard" | "chip";
}

const ReactionSummary = <T extends ReactionType>({
    variant = "standard",
    reactionConfig,
    allUserReactions,
    className,
}: IReactionSummaryProps<T>) => {
    const dialog = useDialog();
    const { t } = useTranslation();

    const users = useAllUsers();
    const currentUserId = useCurrentUserId();

    const numberOfGivenReactions = allUserReactions.length;
    if (allUserReactions.length <= 0) {
        return null;
    }

    const activeReaction = allUserReactions.filter((reaction) => reaction.userId === currentUserId).length === 1;
    const reactionGroups = groupBy(allUserReactions, (reaction) => reaction.type);
    const sortedReactionGroups = new Map(
        Object.entries(reactionGroups)
            .sort((a, b) => b[1].length - a[1].length)
            .map(([k, v]) => [k as T, v]),
    );

    const uniqueReactionTypes = [...sortedReactionGroups.keys()];

    const emojiList = (
        <Stack component="span" direction="row" spacing={-0.25}>
            {uniqueReactionTypes.map((reaction) => (
                <EmojiIcon key={`emoji_${String(reaction)}`}>{reactionConfig.get(reaction)?.icon}</EmojiIcon>
            ))}
        </Stack>
    );
    const label = <Typography variant="body2">{numberOfGivenReactions}</Typography>;

    return (
        <>
            {variant === "standard" ? (
                <SmallIconButton
                    onClick={dialog.open}
                    className={className}
                    variant="text"
                    sx={{ color: "text.primary" }}
                    startIcon={emojiList}
                >
                    {label}
                </SmallIconButton>
            ) : (
                <Tooltip placement="top" title={t(translationKeys.VDLANG_REACTION_REACTIONCHIP)}>
                    <ReactionsChip
                        onClick={dialog.open}
                        className={className}
                        defaultChecked={activeReaction}
                        label={
                            <>
                                {uniqueReactionTypes.map((reaction) => reactionConfig.get(reaction)?.icon)} {numberOfGivenReactions}
                            </>
                        }
                        variant="outlined"
                        size="small"
                    />
                </Tooltip>
            )}
            <ReactionSummaryDialog
                open={dialog.isOpen}
                onClose={dialog.close}
                reactionConfig={reactionConfig}
                users={users}
                sortedReactionGroups={sortedReactionGroups}
            />
        </>
    );
};

export default ReactionSummary;
