import { Components, createTheme, inputAdornmentClasses, Theme, typographyClasses } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";
import { STYLEGUIDE_COLORS } from "../styles/colors";
import { SHADOWS } from "../styles/shadows";
import { environment } from "./environment";

// Augment theme typings to also support all of the picker options
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { mapValues } from "lodash";

import type { Shape } from "@mui/system";
declare module "@mui/material/styles" {
    interface Theme {
        shape: Shape & { borderRadiusLarge?: number };
    }
}

declare module "@mui/material/Alert" {
    interface AlertPropsVariantOverrides {
        inline: true;
    }
}

const getDefaultBackgroundAlertColors = (severity: string | undefined, variant: string | undefined) => {
    if (variant !== "inline") {
        return undefined;
    }
    if (severity === "warning") {
        return "#FFF4E5";
    }
    if (severity === "error") {
        return "#FDF0EC";
    }
    if (severity === "info") {
        return "#E5F6FD";
    }

    return "#EAFAF1";
};

const getDefaultAlertColors = (severity: string | undefined, variant: string | undefined) => {
    if (variant === "inline") {
        return undefined;
    }
    if (severity === "warning") {
        return "#663C00";
    }
    if (severity === "error") {
        return "#5E2C1C";
    }
    if (severity === "info") {
        return "#014361";
    }
    return "#14542E";
};

const getBorderType = (variant: string | undefined) => {
    if (variant === "standard") {
        return "outlined";
    } else if (variant === "inline") {
        return "none";
    }
    return undefined;
};

const generateComponentOverrides = (theme: Theme): Components<Theme> => {
    return {
        // Revert the following, unwanted behaviour of MUI:
        // - top/bottom dividers are hidden for expanded panels
        // - margins are increased for expanded panels
        MuiAccordion: {
            styleOverrides: {
                root: {
                    "&.Mui-expanded + &": {
                        "&:before": {
                            display: "block",
                        },
                    },
                    "&.Mui-expanded": {
                        margin: 0,
                        "&::before": {
                            opacity: 1,
                        },
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    minHeight: 0,
                    "&.Mui-expanded": {
                        minHeight: 0,
                    },
                },
                content: {
                    "&.Mui-expanded": {
                        // default margin of un-expanded content class
                        margin: theme.spacing(1.5, 0),
                    },
                },
            },
        },
        // fix styling on IE
        MuiAlert: {
            styleOverrides: {
                message: {
                    width: "100%",
                },
                action: {
                    flexShrink: 0,
                },
                root: ({ ownerState }) => ({
                    // show buttons below message on mobile
                    [theme.breakpoints.down("sm")]: {
                        flexWrap: "wrap",
                    },
                    // PaperProps of root element cannot be customized yet
                    border: getBorderType(ownerState.variant),
                    backgroundColor: getDefaultBackgroundAlertColors(ownerState.severity, ownerState.variant),
                    color: getDefaultAlertColors(ownerState.severity, ownerState.variant),
                }),
                icon: {
                    flexShrink: 0,
                    alignItems: "center",
                },
                standardError: {
                    borderColor: theme.palette.error.light,
                },
                standardInfo: {
                    borderColor: theme.palette.info.light,
                },
                standardSuccess: {
                    borderColor: theme.palette.success.light,
                },
                standardWarning: {
                    borderColor: theme.palette.warning.light,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: grey[400],
                    color: theme.palette.common.white,
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                li: {
                    color: theme.palette.text.secondary,
                    "&:last-of-type": {
                        color: theme.palette.text.primary,
                    },
                },
                separator: {
                    marginLeft: theme.spacing(0.5),
                    marginRight: theme.spacing(0.5),
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: "outlined",
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
                contained: {
                    "&:hover": {
                        boxShadow: theme.shadows[2],
                    },
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                subheader: {
                    marginTop: theme.spacing(0.5),
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    padding: 0,
                    // prominently show text that is not wrapped with MaterialUI Typography
                    fontFamily: !environment.isProduction ? "serif" : undefined,
                },
                ".recharts-tooltip-wrapper:focus": {
                    // recharts sets focus on the tooltip component, which sometimes results in an outline
                    // tooltips are not interactive here, so disable the outline
                    // Fix this globally, so it is not needed to fix it in each chart manually
                    outline: "none !important",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(2, 3),
                    gap: theme.spacing(),
                },
            },
            defaultProps: {
                // disable default spacing, as it is only applied between same-type children
                // replace with flexGap, which is much easier (not considered in upstream yet due to legacy browser support)
                disableSpacing: true,
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    ...theme.typography.body2,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    ...theme.typography.h6,
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                input: ({ ownerState }) => ({
                    ...theme.typography.body2,
                    ...(ownerState.size === "small" && { padding: theme.spacing(0.75, 1.5) }),
                    ...(ownerState.size === "medium" && { padding: theme.spacing(1, 1.5) }),
                    ...(ownerState.size === "large" && { padding: theme.spacing(1.25, 1.5) }),
                    ...(ownerState.size === "extralarge" && { padding: theme.spacing(1.75, 1.5) }),
                    ...(ownerState.startAdornment != null && { paddingLeft: 0 }),
                    ...(ownerState.endAdornment != null && { paddingRight: 0 }),
                }),
                adornedStart: {
                    paddingLeft: theme.spacing(1.5),
                },
                adornedEnd: {
                    paddingRight: theme.spacing(1.5),
                },
                multiline: {
                    // multiline padding is rendered on root instead of input, disable root padding and rely on input padding
                    padding: 0,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    marginBottom: theme.spacing(0.25),
                    ...theme.typography.body2,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: ({ ownerState }) => ({
                    ...theme.typography.body2,
                    ...(ownerState.size === "small" && { padding: theme.spacing(0.75, 1.5) }),
                    ...(ownerState.size === "medium" && { padding: theme.spacing(1, 1.5) }),
                    ...(ownerState.size === "large" && { padding: theme.spacing(1.25, 1.5) }),
                    ...(ownerState.size === "extralarge" && { padding: theme.spacing(1.75, 1.5) }),
                    ...(ownerState.startAdornment != null && { paddingLeft: 0 }),
                    ...(ownerState.endAdornment != null && { paddingRight: 0 }),
                }),
                adornedStart: {
                    paddingLeft: theme.spacing(1.5),
                },
                adornedEnd: {
                    paddingRight: theme.spacing(1.5),
                },
                multiline: {
                    // multiline padding is rendered on root instead of input, disable root padding and rely on input padding
                    padding: 0,
                },
            },
        },
        // Instead of showing a notched outline with integrated label, show the input's label always on top of the input element
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
                disableAnimation: true,
            },
            styleOverrides: {
                shrink: {
                    // use !important here to override more specific transform set by two combined classes, which cannot be overridden in theme
                    transform: "none !important",
                },
                formControl: {
                    position: "static",
                    marginBottom: theme.spacing(0.25),
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: "hover",
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: theme.spacing(5),
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow: theme.shadows[8],
                },
            },
            defaultProps: {
                MenuListProps: {
                    // Use dense menu styling (with body2 variant for item text) by default
                    dense: true,
                },
            },
        },
        // Revert dense menu items on desktop which are not in line with Material-UI specs
        // https://github.com/mui-org/material-ui/issues/17701#issuecomment-549062343
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    minHeight: theme.spacing(5),
                    [theme.breakpoints.up("sm")]: {
                        // Override default of MUI which sets minHeight to auto on sm-up
                        minHeight: theme.spacing(5),
                    },
                },
                dense: {
                    minHeight: theme.spacing(4),
                    [theme.breakpoints.up("sm")]: {
                        // Override default of MUI which sets minHeight to auto on sm-up
                        minHeight: theme.spacing(4),
                    },
                },
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                notched: false,
            },
            styleOverrides: {
                root: {
                    [`&:hover, &.Mui-disabled`]: {
                        // adornments such as icons should inherit hover/disabled styles
                        [`& .${inputAdornmentClasses.root}, & .${typographyClasses.root}`]: {
                            color: "inherit",
                        },
                    },
                },
                input: ({ ownerState }) => ({
                    ...theme.typography.body2,
                    ...(ownerState.size === "small" && { padding: theme.spacing(0.75, 1.5) }),
                    ...(ownerState.size === "medium" && { padding: theme.spacing(1, 1.5) }),
                    ...(ownerState.size === "large" && { padding: theme.spacing(1.25, 1.5) }),
                    ...(ownerState.size === "extralarge" && { padding: theme.spacing(1.75, 1.5) }),
                    ...(ownerState.startAdornment != null && { paddingLeft: 0 }),
                    ...(ownerState.endAdornment != null && { paddingRight: 0 }),
                }),
                adornedStart: {
                    paddingLeft: theme.spacing(1.5),
                },
                adornedEnd: {
                    paddingRight: theme.spacing(1.5),
                },
                multiline: {
                    // multiline padding is rendered on root instead of input, disable root padding and rely on input padding
                    padding: 0,
                },
            },
        },

        MuiPaper: {
            // Be warned: These overrides will be inherited by lots of components with larger surfaces, such as Card/Dialog/Menu/...
            styleOverrides: {
                root: ({ ownerState }) =>
                    !ownerState.square && {
                        borderRadius: theme.shape.borderRadiusLarge,
                    },
            },
            defaultProps: {
                variant: "outlined",
            },
        },
        MuiPopover: {
            defaultProps: {
                PaperProps: {
                    variant: "elevation",
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                MenuProps: {
                    MenuListProps: {
                        // Use dense menu styling (with body2 variant for item text) by default
                        dense: true,
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                thumb: {
                    boxShadow: theme.shadows[2],
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
        // Make all text fields outlined & dense by default
        MuiTextField: {
            defaultProps: {
                size: "medium",
            },
        },
        MuiToggleButton: {
            defaultProps: {
                color: "primary",
            },
            styleOverrides: {
                // styleOverride with classes, e.g. primary,  is not supported anymore for colors
                // See: https://github.com/mui/material-ui/issues/34163#issuecomment-1235145769
                root: ({ ownerState }) => ({
                    padding: theme.spacing(0.625, 1.375), // - 1px for borders
                    ...(ownerState.color === "primary" && {
                        color: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                    }),
                }),
            },
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                color: "primary",
            },
        },
        MuiTooltip: {
            defaultProps: {
                PopperProps: {
                    // See: https://popper.js.org/docs/v2/modifiers/hide/
                    modifiers: [
                        {
                            name: "hide",
                            phase: "main",
                        },
                    ],
                },
            },
            styleOverrides: {
                popper: {
                    // This attribute gets applied when the popper escapes the reference element's container
                    "&[data-popper-escaped]": {
                        visibility: "hidden",
                    },
                    // This attribute gets applied to the popper when the reference element is fully clipped and hidden from view
                    "&[data-popper-reference-hidden]": {
                        visibility: "hidden",
                    },
                },
                tooltip: {
                    backgroundColor: blueGrey[900],
                    ...theme.typography.subtitle2,
                    overflowWrap: "anywhere",
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variant: "body2",
            },
            styleOverrides: {
                root: {
                    "&::marker": {
                        fontWeight: "bold",
                    },
                },
            },
        },
    };
};

const defaultThemeOptions = {
    typography: {
        subtitle1: {
            fontWeight: 500,
            lineHeight: "1.5rem",
        },
        // When customizing `fontFamily`, we need to regenerate the DevExtreme css theme with the same fonts.
        // See `devextreme.ts`
    },
    shadows: SHADOWS,
    shape: {
        borderRadius: 4,
        borderRadiusLarge: 8,
    },
};

/**
 * Convert CSS color definitions to augmented MUI color objects. T and returned value are an object with exactly the same keys.
 *
 * @template TKey
 * @param {Record<TKey, string>} colors
 * @param {Theme} theme
 * @returns
 */
function generateExtraPalette<TKey extends string>(colors: Record<TKey, string>, theme: Theme) {
    return mapValues(colors, (value) => theme.palette.augmentColor({ color: { main: value } }));
}

function generateStandardPalette(colors: typeof STYLEGUIDE_COLORS) {
    const { primaryBlue, secondaryOrange, fontDark, fontGrey, fontInactive, successGreen, materialRed, backgroundLight, ...extraColors } =
        colors;

    const palette = {
        primary: {
            main: primaryBlue,
        },
        secondary: {
            main: secondaryOrange,
            contrastText: "#fff",
        },
        background: {
            default: backgroundLight,
            light: backgroundLight,
        },
        success: { main: successGreen },
        error: { main: materialRed },
        text: {
            primary: fontDark,
            secondary: fontGrey,
            disabled: fontInactive,
        },
    };
    return { palette, extraColors };
}

const generateTheme = () => {
    const { palette, extraColors } = generateStandardPalette(STYLEGUIDE_COLORS);

    const baseThemeOptions = {
        ...defaultThemeOptions,
        palette,
    };

    // We need to create a prototype theme object first from most of the basic options, so that we can use its helper functions for
    // generating spacing/colors/.. for component overrides and extra colors
    const baseTheme = createTheme(baseThemeOptions);
    const extraPalette = generateExtraPalette(extraColors, baseTheme);

    // This theme object will be used to generate component overrides, so it should be as complete as possible
    const coloredBaseTheme = {
        ...baseTheme,
        palette: {
            ...baseTheme.palette,
            ...extraPalette,
        },
    };

    return createTheme({
        ...baseThemeOptions,
        palette: {
            ...baseThemeOptions.palette,
            ...extraPalette,
        },
        components: generateComponentOverrides(coloredBaseTheme),
    });
};

export const defaultTheme = generateTheme();
