import { Slider, sliderClasses, SliderProps, styled } from "@mui/material";
import { teal } from "@mui/material/colors";
import { EstimatesValue } from "api-shared";
import React from "react";
import { z } from "zod";

const StyledSlider = styled(Slider, { shouldForwardProp: (name) => name !== "thumbOnHover" })<{ thumbOnHover?: boolean }>(
    ({ theme, thumbOnHover }) => ({
        color: teal[500],
        [`& .${sliderClasses.markLabel}`]: {
            color: theme.palette.text.primary,
            // Ah, MUI, why do you like complexity? Instead of a simple <input type="range" />, we get a fiesta of
            // <span /> elements with absolute positioning. 🙃
            [`&:nth-child(1 of .${sliderClasses.markLabel})`]: {
                transform: `translateX(0)`,
            },
            [`&:nth-child(5 of .${sliderClasses.markLabel})`]: {
                transform: "translateX(-100%)",
            },
        },
        // The slider will take 100% width by default plus the thumb radius if it is placed at the edges
        // This looks misaligned, so inset the slider such that the first label is roughly aligned with the FormLabel above the slider
        marginLeft: theme.spacing(1.25),
        width: `calc(100% - ${theme.spacing(2.5)})`,
        "&:not(:hover)": {
            ...(thumbOnHover && {
                [`& .${sliderClasses.thumb}, & .${sliderClasses.track}`]: {
                    visibility: "hidden",
                },
            }),
        },
    }),
);

const estimatesValidator = z.nativeEnum(EstimatesValue);

type EstimatesSliderProps = Omit<SliderProps, "value" | "onChange"> & {
    value: SliderProps["value"] | null;
    onChange: (newValue: EstimatesValue | null) => void;
};

const EstimatesSlider = React.forwardRef<"span", EstimatesSliderProps>(({ value, onChange, ...props }, ref) => {
    return (
        <StyledSlider
            value={value ?? EstimatesValue.MEDIUM}
            thumbOnHover={value == null}
            onChange={(e, newValue) => {
                const parseResult = estimatesValidator.safeParse(newValue);
                parseResult.success && onChange(parseResult.data);
            }}
            slotProps={value == null ? { thumb: { onClick: () => onChange?.(EstimatesValue.MEDIUM) } } : undefined}
            min={EstimatesValue.VERY_LOW}
            step={10}
            max={EstimatesValue.VERY_HIGH}
            {...props}
        />
    );
});

export default EstimatesSlider;
