import CloseRounded from "@mui/icons-material/CloseRounded";
import { IconButton, List, ListItemSecondaryAction, styled } from "@mui/material";
import { AclNamespaces, FeatureFlags } from "api-shared";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate, Route, useLocation } from "react-router-dom";
import RootRoutes from "../../components/RootRoutes";
import SidebarLayout from "../../components/SidebarLayout";
import SidebarNavItemButton from "../../components/sidebar/SidebarNavItemButton";
import SidebarPreferences, { sidebarPreferencesClasses } from "../../components/sidebar/SidebarPreferences";
import SidebarTitleItem from "../../components/sidebar/SidebarTitleItem";
import { useClientHasFeature } from "../../domain/client";
import { useUserHasAccessPermissionQuery } from "../../domain/permissions";
import { useCurrentUser } from "../../domain/users";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useIsMobile } from "../../lib/mobile";
import { RouteFor } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";
import Integrations from "./Integrations";
import NotificationsSettings from "./NotificationsSettings";
import ProfileUpdate from "./ProfileUpdate";
import TwoFactorAuthenticationProfile from "./TwoFactorAuthenticationProfile";
import UserPasswordReset from "./UserPasswordReset";

const tabs = [
    {
        path: RouteFor.user.settings,
        title: translationKeys.VDLANG_MY_PROFILE_TITLE,
    },
    {
        path: RouteFor.user.password,
        title: "Update Password",
    },
    {
        path: RouteFor.user.tfa,
        title: translationKeys.VDLANG_TFA,
    },
    {
        path: RouteFor.user.notifications,
        title: "notifications",
    },
];

const integrationsTab = {
    path: RouteFor.user.integrations,
    title: translationKeys.VDLANG_INTEGRATIONS_TITLE,
};

const Container = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    overflowY: "auto",
    height: "100%",
}));

const UserProfileSidebarPreferences = styled(SidebarPreferences)(({ theme }) => ({
    [`& .${sidebarPreferencesClasses.content}`]: {
        padding: theme.spacing(3, 1, 0),
    },
}));

export function UserProfile(props: PropsWithChildren<unknown>) {
    useDocumentTitle(translationKeys.VDLANG_MY_PROFILE_TITLE);

    const hasIntegrationsFeature = useClientHasFeature(FeatureFlags.FEATURE_INTEGRATIONS);
    const hasApiAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.Api);

    const { t: translate } = useTranslation();
    const user = useCurrentUser();
    const location = useLocation();
    const isMobile = useIsMobile();

    const [isOpen, setIsOpen] = useState(false);

    if (!hasApiAccessPermissionQuery.isSuccess) {
        return null;
    }

    const visibleTabs = hasIntegrationsFeature && hasApiAccessPermissionQuery.data.hasPermission ? [...tabs, integrationsTab] : tabs;

    const header = translate(translationKeys.VDLANG_MY_PROFILE_TITLE);

    if (user == null) {
        throw new Error("UserProfile used outside of AuthenticatedContext");
    }

    return (
        <SidebarLayout
            open={isMobile ? isOpen : undefined}
            onOpen={() => (isMobile ? setIsOpen(true) : undefined)}
            sidebar={
                <UserProfileSidebarPreferences
                    header={
                        <List disablePadding={true}>
                            <SidebarTitleItem>
                                {header}
                                {isMobile ? (
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end">
                                            <CloseRounded onClick={() => setIsOpen(false)} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                ) : null}
                            </SidebarTitleItem>
                        </List>
                    }
                >
                    {visibleTabs.map(({ path, title }) => (
                        <SidebarNavItemButton
                            key={title}
                            onClick={() => (isMobile ? setIsOpen(false) : undefined)}
                            selected={path === location.pathname}
                            component={Link}
                            to={path}
                        >
                            {translate(title)}
                        </SidebarNavItemButton>
                    ))}
                </UserProfileSidebarPreferences>
            }
            collapsedHeader={translate(translationKeys.VDLANG_MY_PROFILE_TITLE)}
        >
            <Container>
                <RootRoutes>
                    <Route path={RouteFor.user.view} element={<Navigate replace to={RouteFor.user.settings} />} />
                    <Route path={RouteFor.user.settings} element={<ProfileUpdate user={user} />} />
                    <Route path={RouteFor.user.password} element={<UserPasswordReset />} />
                    <Route path={RouteFor.user.tfa} element={<TwoFactorAuthenticationProfile />} />
                    <Route
                        path={RouteFor.user.notifications}
                        element={<NotificationsSettings enabledNotifications={user.enabledNotifications} />}
                    />
                    {hasIntegrationsFeature && hasApiAccessPermissionQuery && (
                        <Route path={RouteFor.user.integrations} element={<Integrations />} />
                    )}
                </RootRoutes>
            </Container>
        </SidebarLayout>
    );
}

export default UserProfile;
