import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BasicMessageDto, FilterDefinition, SearchConfigCreateInputDto, SearchConfigDto, SearchConfigListDto } from "api-shared";
import { apiDelete, apiGet, apiPatch, apiPost } from "../lib/api";

const SEARCH_CONFIGS_PATH = "search-configs";
export const SearchConfigQueryKeys = {
    all: () => [SEARCH_CONFIGS_PATH, "all"] as const,
};

export const useSearchConfigsQuery = () => {
    return useQuery({
        queryKey: SearchConfigQueryKeys.all(),
        queryFn: ({ signal }) =>
            apiGet<SearchConfigListDto>(SEARCH_CONFIGS_PATH, { signal }).then((searchConfigs) =>
                searchConfigs.map<SearchConfig>((sc) => ({ page: 0, ...sc })),
            ),
        suspense: true,
    });
};

interface CreateSearchConfigMutationOptions {
    onSuccess?: (data: SearchConfigDto) => void;
}

export const useCreateSearchConfigMutation = ({ onSuccess }: CreateSearchConfigMutationOptions) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (searchConfig: Omit<SearchConfigCreateInputDto, "id">) => apiPost<SearchConfigDto>(SEARCH_CONFIGS_PATH, searchConfig),
        onSuccess: async (response) => {
            await queryClient.invalidateQueries(SearchConfigQueryKeys.all());
            onSuccess?.(response);
        },
    });
};

export const useUpdateSearchConfigMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ id, ...restProps }: SearchConfigDto) => apiPatch<SearchConfigDto>(`${SEARCH_CONFIGS_PATH}/${id}`, restProps),
        onSuccess: (response) => {
            queryClient.invalidateQueries(SearchConfigQueryKeys.all());
        },
    });
};

export const useDeleteSearchConfigMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id: number) => apiDelete<BasicMessageDto>(`${SEARCH_CONFIGS_PATH}/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries(SearchConfigQueryKeys.all());
        },
    });
};

export const useSearchConfigsByFilter = (filterId: number | null) => {
    return useSearchConfigsQuery().data?.filter((searchConfig) => searchConfig.filterId === filterId) ?? [];
};

/**
 * Holds both persisted SearchConfig and temporary local data, e.g. page and temporary filter definition
 *
 * @export
 * @interface SearchConfig
 * @extends {SearchConfigDto}
 */
export interface SearchConfig extends SearchConfigDto {
    filter?: FilterDefinition;
    page: number;
}
