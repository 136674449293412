import { OutlinedTextFieldProps, TextField } from "@mui/material";
import React, { useState } from "react";

export interface IValidatedInputProps extends Omit<OutlinedTextFieldProps, "variant"> {
    isValid?: (value: unknown) => boolean;
    mandatory?: boolean;
    maxLength?: number;
}

const defaultIsValid = (value: unknown, maxLength: number | undefined) =>
    typeof value === "string" && value.trim().length > 0 && maxLength !== undefined ? value.trim().length < maxLength : true;

const ValidatedInput = React.forwardRef<HTMLDivElement, IValidatedInputProps>(
    ({ isValid = defaultIsValid, value, onChange, mandatory, maxLength, required, ...textFieldProps }, ref) => {
        const [touched, setTouched] = useState(false);

        const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setTouched(true);
            onChange?.(event);
        };

        return (
            <TextField
                fullWidth
                value={value}
                required={mandatory ?? required}
                id={textFieldProps.name}
                onChange={handleChange}
                margin="normal"
                error={touched && !isValid(value, maxLength)}
                {...textFieldProps}
                variant="outlined" // Force outline variant, because typings need to statically know which variant is used
            />
        );
    },
);

export default ValidatedInput;
