import { Moment } from "moment";

export const isOutsideBounds = (value: Moment | null, min?: Moment | null, max?: Moment | null) => {
    if (value == null) {
        return false;
    }
    if (!value.isValid()) {
        return true;
    }
    if (min != null && min.isAfter(value)) {
        return true;
    }
    return max != null && max.isBefore(value);
};
