import { GateTaskConfigDto, MEASURE_GATE_TASK_FIELD_PREFIX, MeasureGateTaskFieldNames } from "api-shared";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useGateTaskConfigs } from "../domain/measure-config";
import { translationKeys } from "../translations/main-translations";

const measureGateTaskFieldNameRegex = new RegExp(
    `${MEASURE_GATE_TASK_FIELD_PREFIX}_(\\d+)_(${[MeasureGateTaskFieldNames.StartDate, MeasureGateTaskFieldNames.DueDate, MeasureGateTaskFieldNames.CompletedAt].join("|")})`,
);

/**
 * Returns the translated name for a measure table column/field. Will also resolve dynamic parts of level and efficiency columns (level name, currency).
 */
const getTranslatedColumnLabel = (columnId: string, isoCode: string, translate: TFunction, gateTaskConfigs: GateTaskConfigDto[]) => {
    if (!columnId.startsWith(MEASURE_GATE_TASK_FIELD_PREFIX)) {
        return translate(columnId, { clientCurrency: isoCode });
    }

    const matches = measureGateTaskFieldNameRegex.exec(columnId);
    if (matches == null) {
        return columnId;
    }

    const [, gateTaskConfigId, gateTaskFieldName] = matches;
    const gateTaskConfig = gateTaskConfigs.find((gtc) => gtc.id === Number(gateTaskConfigId));

    if (gateTaskConfig == null) {
        return translate(translationKeys.VDLANG_GATE_TASK_COLUMN_NOT_FOUND);
    }

    const isDuplicateGate = gateTaskConfigs.filter((gtc) => gtc.name === gateTaskConfig.name).length > 1;
    const processNameSuffix = isDuplicateGate ? ` (${translate(gateTaskConfig.processName ?? "")})` : "";
    const gateTaskConfigName = `${translate(gateTaskConfig.name)}${processNameSuffix}`;

    return translate(`${translationKeys.VDLANG_GATE_TASK_COLUMNS}.${gateTaskFieldName}`, { levelName: gateTaskConfigName });
};

/**
 * Provides a callback that returns the translated name for a measure table column/field. The callback will also resolve dynamic parts of level and efficiency columns (level name, currency).
 */
export const useGetDynamicFieldLabel = () => {
    const { t } = useTranslation();
    const gateTaskConfigs = useGateTaskConfigs();

    const getDynamicColumnLabel = useCallback(
        (columnId: string, isoCode: string) => {
            return getTranslatedColumnLabel(columnId, isoCode, t, gateTaskConfigs);
        },
        [gateTaskConfigs, t],
    );

    return {
        getDynamicColumnLabel,
    };
};
