import { useTranslation } from "react-i18next";
import UserEntry from "../../../../components/user/UserEntry";
import { useCurrentUser } from "../../../../domain/users";

const CurrentUserEntry = () => {
    const user = useCurrentUser();
    const { t: translate } = useTranslation();
    return user ? <UserEntry disabled user={user} translate={translate} /> : null;
};
export default CurrentUserEntry;
