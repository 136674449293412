import MoreVertIcon from "@mui/icons-material/MoreVertRounded";
import { IconButton, IconButtonProps, Menu, MenuProps, SvgIconProps } from "@mui/material";
import React, { Fragment, ReactElement } from "react";
import useMenu from "../hooks/useMenu";
import Tooltip from "./Tooltip";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../translations/main-translations";

interface IMoreActionsMenuProps extends Omit<MenuProps, "open">, Partial<Pick<MenuProps, "open">> {
    actions: ReactElement[];
    buttonProps?: IconButtonProps;
    buttonComponent?: React.ElementType<IconButtonProps>;
    iconProps?: SvgIconProps;
    onOpen?: React.MouseEventHandler;
    onClose?: () => void;
    title?: string;
}

const MoreActionsMenu = ({
    actions,
    buttonComponent: ButtonComponent = IconButton,
    buttonProps,
    iconProps,
    onOpen,
    onClose,
    children,
    title = "",
    ...additionalMenuProps
}: IMoreActionsMenuProps) => {
    const { openMenu, makeCloseOnSelectHandler: closeMenuOnSelectHandler, menuProps } = useMenu({ onOpen, onClose });
    const { t } = useTranslation();

    return (
        <Fragment>
            <Menu {...menuProps} {...additionalMenuProps}>
                {actions.map((element) =>
                    React.cloneElement(element, {
                        // override onClick so menu can be closed when item is selected
                        onClick: closeMenuOnSelectHandler(element),
                    }),
                )}
            </Menu>
            <Tooltip title={title} placement="left">
                <ButtonComponent onClick={openMenu} aria-label={t(translationKeys.VDLANG_CONTEXT_MENU)} {...buttonProps}>
                    <MoreVertIcon {...iconProps} />
                </ButtonComponent>
            </Tooltip>
        </Fragment>
    );
};
export default MoreActionsMenu;
