import { styled } from "@mui/material";
import { Fragment, PropsWithChildren } from "react";
import Navbar from "./Navbar";

const Container = styled("div")(({ theme }) => ({
    height: `calc(100vh - ${theme.spacing(8)})`,
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(7.5),
        height: `calc(100vh - ${theme.spacing(7.5)})`,
    },
}));

const NavbarDecorator = ({ children }: PropsWithChildren<unknown>) => {
    return (
        <Fragment>
            <Navbar />
            <Container>{children}</Container>
        </Fragment>
    );
};

export default NavbarDecorator;
