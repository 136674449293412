import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";

const seperatingBreakpoint: Breakpoint = "md";

/**
 * Check if current screen dimensions are desktop. Opposite of @see useIsMobile
 *
 * @export
 * @returns
 */
export function useIsDesktop() {
    const theme = useTheme();
    const query = theme.breakpoints.up(seperatingBreakpoint);
    // without the noSsr flag, the hook triggers twice with a wrong value first
    return useMediaQuery(query, { noSsr: true });
}

/**
 * Check if current screen dimensions are mobile. Opposite of @see useIsDesktop
 *
 * @returns
 */
export function useIsMobile() {
    return !useIsDesktop();
}
