import { forwardRef } from "react";
import { ResponsiveContainer as RechartsResponsiveContainer, type ResponsiveContainerProps } from "recharts";
import DebounceDimensions from "./DebounceDimensions";

/**
 * A wrapper for recharts' ResponsiveContainer component, that fixes the ResizeObserver issues caused by to frequent re-rendering
 *
 */
const ResponsiveContainer = forwardRef<HTMLDivElement, ResponsiveContainerProps>(({ children, ...props }, ref) => {
    return (
        <RechartsResponsiveContainer {...props} ref={ref}>
            <DebounceDimensions>{children}</DebounceDimensions>
        </RechartsResponsiveContainer>
    );
});

export default ResponsiveContainer;
