import * as Sentry from "@sentry/react";
import { QueryKey } from "@tanstack/react-query";
import { ErrorConstantKeys } from "api-shared";
import { ApiError } from "../lib/api";
import { environment } from "../lib/environment";

/**
 * Errors that are expected and can safely not be reported to Sentry.
 *
 * Ignoring errors globally should generally be avoided, instead add them to the specific mutation/query whenever possible.
 * One exception would be when the error is expected to be ignored in all cases for many endpoints.
 */
const NO_REPORT_ERRORS = [ErrorConstantKeys.VDERROR_BAD_REQUEST_NEW_MEASURE_ASSIGNEE_NEEDED].map(String);

function extractValidationErrors(error: ApiError): unknown[] | undefined {
    if (Array.isArray(error.content)) {
        return error.content;
    }

    if (typeof error.content !== "string" && "errors" in error.content) {
        return error.content.errors;
    }
}

export function reportFailedRequest(error: Error, message: string | ErrorConstantKeys, queryKey?: QueryKey) {
    if (NO_REPORT_ERRORS.includes(String(message))) {
        return;
    }

    // Do not report internal api errors to sentry, those will be tracked by backend
    // But still report rate limiting errors (529) caused by client
    if (error instanceof ApiError && error.code >= 500 && error.code !== 529) {
        return;
    }

    if (error instanceof ApiError && (error.code === 401 || error.code === 403)) {
        // Do not report authorization errors (e.g. token expired or missing permissions) to sentry
        return;
    }

    if (error instanceof ApiError && error.code === 404) {
        // Do not report NotFound errors to sentry
        return;
    }

    if (document.visibilityState === "hidden") {
        // Unlike queries, mutations cannot be cancelled, so fall back to the visibility state "hidden" to detect tab/browser closing.
        // The drawback is, that this will also skip error reporting when tab is in background. Therefore, skip only the error reporting
        // part, but still dispatch notifications so when the user puts the tab back into foreground, the error will be displayed.
        return;
    }

    // Lookup message in ErrorConstantKeys to get a more human readable form of the error
    // getTranslationKeyForError is not helpful here, as it also combines multiple specific errors in
    // This will hold error constant values, e.g. VDERROR_BAD_FILTER_DEFINITION
    let technicalErrorMessage = ErrorConstantKeys[message as ErrorConstantKeys];
    if (typeof technicalErrorMessage !== "string") {
        // lookup failed, make sure to report message as a string
        technicalErrorMessage = typeof message === "string" ? message : JSON.stringify(message);
    }

    // avoid collapsing of all different kinds of failed requests into a single sentry issue due to same stack trace
    // Split by: human-readable error message, queryKey and optionally the server response code
    const fingerprint = ["Request failed", technicalErrorMessage];
    let extras: Record<string, unknown> | undefined;

    if (error instanceof ApiError) {
        fingerprint.push(String(error.code));
        const url = new URL(error.url);
        // use only relative url part to improve grouping by endpoint
        // Replace dynamic content in URL (ids) with a static placeholder ":id", to improve grouping by endpoint
        fingerprint.push(url.pathname.replaceAll(/\/\d+(\/|$)/gm, "/:id$1"));

        // Report validation errors to sentry as extra information if the zod-express-middleware returns an error or if the ErrorHandler returns an ValidationError
        const errors = extractValidationErrors(error);
        extras = error != null ? { validationErrors: JSON.stringify(errors) } : undefined;
    }

    // send to sentry even if message is null, because then an exception occurred, but is cannot be displayed as notification
    reportError(error, {
        tags: error instanceof ApiError ? { status_code: String(error.code) } : undefined,
        fingerprint,
        extras,
    });
}

interface IErrorReportingOptions {
    tags?: Record<string, string>;
    extras?: Record<string, unknown>;
    fingerprint?: string[];
}

export function reportError(error: Error, options: IErrorReportingOptions = {}) {
    const { tags, extras, fingerprint } = options;

    // Skip reporting of errors potentially caused by missing internet connection
    if (window?.navigator?.onLine === false && /failed to fetch/i.test(error.message)) {
        return;
    }

    // Only log errors to console on local environments, otherwise this console.error gets reported to sentry if captureConsoleIntegration is enabled on production
    if (environment.sentryEnvironment === "local") {
        // eslint-disable-next-line no-console
        console.error("Reporting error", error, options);
    }

    Sentry.withScope((scope) => {
        tags != null && scope.setTags(tags);
        extras != null && scope.setExtras(extras);
        fingerprint != null && scope.setFingerprint(fingerprint);
        Sentry.captureException(error);
    });
}
