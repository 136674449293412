import { PlaceholderProps } from "react-select";
import { Option } from "../types";
import SelectPlaceholder from "./SelectPlaceholder";

/**
 * Wraps SelectPlaceholder and hides the placeholder, as long as the menu is not open yet
 *
 * @template OptionType
 * @template IsMulti
 * @param {PlaceholderProps<OptionType, IsMulti>} props
 * @returns
 */
const UnobtrusiveSelectPlaceholder = <OptionType extends Option, IsMulti extends boolean>(props: PlaceholderProps<OptionType, IsMulti>) => {
    if (!props.selectProps.menuIsOpen) {
        return null;
    }

    return <SelectPlaceholder {...props} />;
};

export default UnobtrusiveSelectPlaceholder;
