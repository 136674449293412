import ArrowDropdownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { components, DropdownIndicatorProps } from "react-select";
import { Option } from "../types";

const SelectDropdownIndicator = <OptionType extends Option, IsMulti extends boolean>(
    props: DropdownIndicatorProps<OptionType, IsMulti>,
) => (
    <components.DropdownIndicator {...props}>
        <ArrowDropdownRoundedIcon color="action" />
    </components.DropdownIndicator>
);

export default SelectDropdownIndicator;
