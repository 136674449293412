import { KeyboardEvent as KeyboardEventReact } from "react";

const ENTER = "Enter";
const ESCAPE = "Escape";
const SPACE = " ";
const COMMA = ",";

export function isSubmitEvent(event: KeyboardEvent | KeyboardEventReact) {
    return isEnterEvent(event) && (event.ctrlKey || event.metaKey);
}

export function isEnterEvent(event: KeyboardEvent | KeyboardEventReact) {
    return event.key === ENTER;
}
export function isEscapeEvent(event: KeyboardEvent | KeyboardEventReact) {
    return event.key === ESCAPE;
}
export function isSpaceEvent(event: KeyboardEvent | KeyboardEventReact) {
    return event.key === SPACE;
}

export function isCommaEvent(event: KeyboardEvent | KeyboardEventReact) {
    return event.key === COMMA;
}
