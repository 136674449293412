import { Collapse, Link } from "@mui/material";
import { TFunction } from "i18next";
import React, { PropsWithChildren, useRef, useState } from "react";
import useForkedRef from "../hooks/useForkedRef";
import { translationKeys } from "../translations/main-translations";

export interface IShowMoreContainerProps {
    // height in pixels
    /**
     * Max allowed height before the "show more" button gets visible
     *
     * @type {number}
     * @memberof IShowMoreContainerProps
     */
    height: number;
    translate: TFunction;
    contentRef: React.RefObject<HTMLElement | null>;
    onToggled?: (boundingBox: DOMRect) => void;
}

enum OverflowStatus {
    Uninitialized,
    NoOverflow,
    Closed,
    Open,
}

function computeNewOverflowState(old: OverflowStatus, contentHeight: number, maxHeight: number) {
    if (contentHeight <= maxHeight) {
        return OverflowStatus.NoOverflow;
    }
    // has overflow
    if (old === OverflowStatus.Open || old === OverflowStatus.Closed) {
        // keep state if it has been set already (might have been modified manually by user)
        return old;
    }
    // Start out in closed state, if there was no overflow before or the state was not initialized yet
    return OverflowStatus.Closed;
}

const ShowMore = React.forwardRef<unknown, PropsWithChildren<IShowMoreContainerProps>>(
    ({ contentRef, children, height, translate, onToggled }, ref) => {
        const [status, setStatus] = useState(OverflowStatus.Uninitialized);

        const rootRef = useRef<HTMLElement>(null);
        const forkedRef = useForkedRef(ref, rootRef);

        // Start out with disabled transitions/animations, so that overflow state can be initialized lazily without showing animations
        const [disableTransition, setDisableTransition] = useState(true);

        const handleToggle = () => setStatus((old) => (old === OverflowStatus.Open ? OverflowStatus.Closed : OverflowStatus.Open));

        // watch actual content size and sync overflow state (will also be triggered once on ResizeObserver initialization)
        React.useLayoutEffect(() => {
            if (!contentRef.current) {
                return;
            }

            // initialize collapsed state once based on the content's size
            const { height: contentHeight } = contentRef.current.getBoundingClientRect();
            setStatus((old) => computeNewOverflowState(old, contentHeight, height));

            // additionally sync overflow state with changing dimensions of content
            const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                const [contentEntry] = entries;
                setStatus((old) => computeNewOverflowState(old, contentEntry.contentRect.height, height));
            });

            resizeObserver.observe(contentRef.current);

            // cleanup old observer before running effect with new dependencies (and creating a new observer)
            return () => resizeObserver.disconnect();
        }, [contentRef, height]);

        const buttonLabel =
            status === OverflowStatus.Open ? translate(translationKeys.VDLANG_SHOW_LESS) : translate(translationKeys.VDLANG_SHOW_MORE);

        return (
            <>
                <Collapse
                    ref={forkedRef}
                    style={{
                        ...(status === OverflowStatus.Uninitialized && {
                            // Avoid large items to be fully shown initially by restricting maxHeight during initialization
                            // Still render small items in their original height (but not larger)
                            maxHeight: height,
                            overflow: "hidden",
                            // reset default height properties set by Collapse component during initialization
                            height: "unset",
                        }),
                        minHeight: "unset", // always reset minHeight set by Collapse, so small elements only occupy space as needed
                        // Disable transitions during initialization
                        transition: disableTransition ? "none" : undefined,
                    }}
                    in={status !== OverflowStatus.Closed}
                    collapsedSize={height}
                    onExited={() => {
                        // enable transitions AFTER closed state has been reached, so any open/close actions triggered by the user will have
                        // transitions enabled
                        setDisableTransition(false);
                        const rect = rootRef.current?.getBoundingClientRect();
                        if (rect != null) {
                            onToggled?.(rect);
                        }
                    }}
                    onEntered={() => {
                        const rect = rootRef.current?.getBoundingClientRect();
                        if (rect != null) {
                            onToggled?.(rect);
                        }
                    }}
                >
                    {children}
                </Collapse>
                {status === OverflowStatus.Open || status === OverflowStatus.Closed ? (
                    <Link component="button" variant="body2" onClick={handleToggle} sx={{ flexShrink: 0 }}>
                        {buttonLabel}
                    </Link>
                ) : null}
            </>
        );
    },
);

export default ShowMore;
