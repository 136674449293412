import { Paper } from "@mui/material";
import { MenuProps } from "react-select";
import useForkedRef from "../../../../hooks/useForkedRef";
import { dropLegacyRef } from "../../../../lib/utils";

/**
 * A menu component that will not be absolutely positioned and takes part in the regular document flow. It ignores the placement passed via
 * props.
 *
 * This is useful in situations where the menu should not appear as an overlay or popup, e.g. because it is always opened or wrapped by a
 * component that takes care of positioning (e.g. Popover or Popper)
 *
 * @template OptionType
 * @template IsMulti
 * @param {MenuProps<OptionType, IsMulti>} props
 * @returns
 */
const SelectStaticMenuContainer = <OptionType, IsMulti extends boolean>({
    innerProps,
    innerRef,
    children,
}: MenuProps<OptionType, IsMulti>) => {
    const divRef = useForkedRef(innerRef, dropLegacyRef(innerProps.ref));

    // Use a paper with 0-elevation here to avoid border
    return (
        <Paper component="div" square {...innerProps} ref={divRef} variant="elevation" elevation={0}>
            {children}
        </Paper>
    );
};

export default SelectStaticMenuContainer;
