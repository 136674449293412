import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
    Box,
    Drawer,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    styled,
} from "@mui/material";
import { UserDto } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import { Location } from "react-router-dom";
import SearchInput from "../../components/input/SearchInput";
import { useClientFeatures } from "../../domain/client";
import { useInitialAccessPermissionsQueries } from "../../domain/permissions";
import useNavbarSearch from "../../hooks/useNavbarSearch";
import { getMobileNavItems } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";
import MobileNavLinkButton from "./MobileNavLinkButton";
import MobileNavbarUserMenu from "./MobileNavbarUserMenu";
import SearchResultList from "./SearchResultList";
import SearchResultTitle from "./SearchResultTitle";

const ExpandableList = styled(List, { shouldForwardProp: (name) => name !== "isOpen" })<{ isOpen: boolean }>(({ theme, isOpen }) => ({
    ...(!isOpen
        ? {
              minWidth: theme.spacing(38), // IE does not compute content size correctly
              maxWidth: theme.spacing(38), // max-width can be animated, width cannot
              transition: theme.transitions.create("max-width", {
                  easing: theme.transitions.easing.easeIn,
                  duration: theme.transitions.duration.leavingScreen,
              }),
          }
        : {
              maxWidth: "100vw",
              width: "100vw",
              transition: theme.transitions.create("max-width", {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
              }),
          }),
}));

interface IMobileNavbarDrawerProps {
    open: boolean;
    onClose?: () => void;
    translate: TFunction;
    searchKey: string;
    updateSearchKey: (newKey: string) => void;
    disabled?: boolean;
    currentUser: UserDto | null;
    location: Location;
}

const MobileNavbarDrawer = ({
    open,
    onClose,
    translate,
    searchKey,
    updateSearchKey,
    disabled,
    currentUser,
    location,
}: IMobileNavbarDrawerProps) => {
    const { searchInProgress, results, isValidKey } = useNavbarSearch(searchKey);
    const clientFeatures = useClientFeatures();

    const [searchOpen, setSearchOpen] = useState(false);

    const closeSearchView = () => {
        setSearchOpen(false);
        updateSearchKey("");
    };

    const handleResultSelect = () => {
        onClose?.();
        closeSearchView();
    };

    const accessPermissionQueries = useInitialAccessPermissionsQueries();

    if (!accessPermissionQueries.isSuccess) {
        return null;
    }

    const tabs = getMobileNavItems(clientFeatures, {
        hasIdeaAccess: accessPermissionQueries.hasIdeaAccessQuery.data.hasPermission,
        hasActivityAccess: accessPermissionQueries.hasActivityAccessQuery.data.hasPermission,
        hasFeedAccess: accessPermissionQueries.hasFeedAccessQuery.data.hasPermission,
        hasDashboardAccess: accessPermissionQueries.hasDashboardAccessQuery.data.hasPermission,
        hasProcessAccess: accessPermissionQueries.hasProcessAccessQuery.data.hasPermission,
        hasMethodAccess: accessPermissionQueries.hasMethodAccessQuery.data.hasPermission,
    });

    return (
        <Drawer open={open} onClose={onClose}>
            <ExpandableList isOpen={searchOpen}>
                {/* Typings demand that button prop must be available at compile time */}
                <ListItem divider button={searchOpen as true} onClick={closeSearchView}>
                    {searchOpen && (
                        <ListItemIcon>
                            <ChevronLeftRoundedIcon />
                        </ListItemIcon>
                    )}
                    <ListItemText primary={translate(translationKeys.VDLANG_MENU)} primaryTypographyProps={{ variant: "h6" }} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={onClose}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Box mt={4} px={2}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <SearchInput
                                searchKey={searchKey}
                                onChange={updateSearchKey}
                                translate={translate}
                                onFocus={() => setSearchOpen(true)}
                            />
                        </Grid>
                        {searchOpen && (
                            <Grid item xs={12}>
                                <FormLabel>
                                    <SearchResultTitle
                                        isSearchInProgress={searchInProgress}
                                        results={results}
                                        translate={translate}
                                        isValidKey={isValidKey}
                                    />
                                </FormLabel>
                                <SearchResultList
                                    results={results}
                                    isSearchInProgress={searchInProgress}
                                    searchKey={searchKey}
                                    onSelect={handleResultSelect}
                                    isValidKey={isValidKey}
                                />
                            </Grid>
                        )}

                        {!searchOpen && (
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {tabs.map((item) => (
                                        <Grid item xs={12} key={item.route}>
                                            <MobileNavLinkButton
                                                fullWidth
                                                key={item.route}
                                                disabled={disabled}
                                                to={item.route}
                                                onClick={onClose}
                                            >
                                                {translate(item.label)}
                                            </MobileNavLinkButton>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                        {!searchOpen && (
                            <Grid item xs={12}>
                                <MobileNavbarUserMenu location={location} user={currentUser} translate={translate} onSelect={onClose} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </ExpandableList>
        </Drawer>
    );
};

export default MobileNavbarDrawer;
