import CloseIcon from "@mui/icons-material/CloseRounded";
import { Button, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Notification, NotificationCustomAction, selectLastNotification } from "../infrastructure/notifications";
import { NotificationType } from "../lib/notifications";

const NotificationVariants = {
    [NotificationType.SUCCESS]: "success",
    [NotificationType.ERROR]: "error",
    [NotificationType.WARNING]: "warning",
    [NotificationType.UPDATE]: "default",
} as const;

const Notifier = () => {
    const [shownNotifications, setShownNotifications] = useState<Notification[]>([]);
    const lastFeedback = useSelector(selectLastNotification);
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    const { t: translate } = useTranslation();

    const makeActionRenderer = useCallback(
        (action?: NotificationCustomAction, key?: string | number) => (
            <>
                {action ? (
                    <Button
                        key="link"
                        color="inherit"
                        onClick={() => {
                            action.onClick();
                            closeSnackbar(key);
                        }}
                    >
                        {translate(action.label)}
                    </Button>
                ) : null}
                <IconButton key="close" color="inherit" onClick={() => closeSnackbar(key)}>
                    <CloseIcon />
                </IconButton>
            </>
        ),
        [closeSnackbar, translate],
    );

    const showNotification = useCallback(
        (notification: Notification) => {
            const translatedMessage = translate(notification.message, notification.details);
            enqueueSnackbar(translatedMessage, {
                variant: NotificationVariants[notification.type],
                action: makeActionRenderer(notification.action),
            });
        },
        [translate, enqueueSnackbar, makeActionRenderer],
    );

    useEffect(() => {
        if (lastFeedback != null && !shownNotifications.includes(lastFeedback)) {
            showNotification(lastFeedback);
            setShownNotifications((prevShownNotifications) => [...prevShownNotifications, lastFeedback]);
        }
    }, [lastFeedback, shownNotifications, showNotification]);

    return null;
};

export default Notifier;
