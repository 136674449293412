import { TFunction } from "i18next";
import React from "react";
import { SearchResult } from "../../domain/search";
import { translationKeys } from "../../translations/main-translations";

interface ISearchResultTitleProps {
    isSearchInProgress: boolean;
    isValidKey: boolean;
    results?: SearchResult[];
    translate: TFunction;
}

const SearchResultTitle: React.FC<ISearchResultTitleProps> = ({ isSearchInProgress, isValidKey, results, translate }) => {
    const countText = !isSearchInProgress && isValidKey && results != null ? ` (${results.length})` : "";
    // Typescript does not correctly recognize functions return strings (or other non-jsx types) as valid JSX element
    // Wrap in Fragment as a workaround
    return <>{`${translate(translationKeys.VDLANG_SEARCH_RESULTS)}${countText}`}</>;
};

export default SearchResultTitle;
