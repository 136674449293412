import { browsersRegex } from "virtual:supported-browsers";

const ignoresOutdatedBrowsers = localStorage.getItem("IGNORE_OUTDATED_BROWSER");

function outdatedBrowserCheck() {
    if (navigator !== undefined) {
        if (!browsersRegex.test(navigator.userAgent) && !navigator.userAgent.includes("Electron")) {
            if (navigator.language.split("-")[0] === "de") {
                document.location = `${document.location.origin}/veralteter_browser.html`;
            } else {
                document.location = `${document.location.origin}/outdated_browser.html`;
            }
        }
    } else {
        document.location = `${document.location.origin}/outdated_browser.html`;
    }
}

if (ignoresOutdatedBrowsers === null) {
    outdatedBrowserCheck();
}
