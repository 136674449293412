import { Button } from "@mui/material";
import moment from "moment-timezone";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAlphanumericStringSort } from "../hooks/useAlphanumericStringSort";
import Select from "./input/select/Select";
import { ISelectProps, Option } from "./input/select/types";

interface ITimezoneSelectProps extends Omit<ISelectProps<Option<string>, false>, "value" | "onChange"> {
    value: string | null;
    onChange: (newTimezone: string | null) => void;
}

const TimezoneSelect = ({ value, onChange, ...selectProps }: ITimezoneSelectProps) => {
    const browserTimezone = moment.tz.guess();
    const { t: translate } = useTranslation();
    const { compare } = useAlphanumericStringSort();

    const timezoneOptions = useMemo(() => {
        return moment.tz
            .names()
            .map((timezone) => ({
                value: timezone,
                label: `(GMT${moment.tz(timezone).format("Z")}) ${timezone}`.replace("_", " "), // (GTM+xx:yy) Name
            }))
            .sort((a, b) => compare(a.label, b.label));
    }, [compare]);

    return (
        <Fragment>
            <Select
                isSearchable
                label={translate("timezone")}
                options={timezoneOptions}
                value={timezoneOptions.find((tz) => tz.value === value)}
                onChange={(option) => onChange(option?.value ?? null)}
                {...selectProps}
            />
            {browserTimezone !== value ? (
                <Button onClick={() => onChange(browserTimezone)}>
                    {translate("timezone_current")}: {browserTimezone}.
                </Button>
            ) : null}
        </Fragment>
    );
};

export default TimezoneSelect;
