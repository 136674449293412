import ErrorIcon from "@mui/icons-material/ErrorRounded";
import WarningIcon from "@mui/icons-material/WarningRounded";
import { styled } from "@mui/material";
import { SubTaskDto, SubTaskStatus } from "api-shared";
import { TFunction } from "i18next";
import momentTimezone from "moment-timezone";
import useTimezone from "../../hooks/useTimezone";
import { translationKeys } from "../../translations/main-translations";
import Tooltip from "../Tooltip";

const SmallErrorIcon = styled(ErrorIcon)({
    fontSize: "1rem",
});

const SmallWarningIcon = styled(WarningIcon)({
    fontSize: "1rem",
});

interface ISubtaskListItemDueIconProps {
    subtask: SubTaskDto;
    translate: TFunction;
}

const SubtaskListItemDueIcon = ({ subtask, translate }: ISubtaskListItemDueIconProps) => {
    const { timezone } = useTimezone();

    // Due icons only need to be shown when status is open and a due date is set
    // This early return avoids computing any moment objects when not needed
    if (subtask.duedate == null) {
        return null;
    }

    if (subtask.status !== SubTaskStatus.STATUS_OPEN) {
        return null;
    }

    const duedate = momentTimezone.tz(subtask.duedate, timezone).endOf("day");
    const today = momentTimezone.tz(timezone);
    const warning = today.clone().add("3", "days");

    if (duedate.isAfter(warning)) {
        // Enough time left, nothing to do
        return null;
    }

    // At this point, the subtask is either due (= duedate is between today and warning dates) or overdue (=duedate is in the past)
    const isOverdue = duedate.isBefore(today);

    return (
        <Tooltip
            title={translate(
                isOverdue ? translationKeys.VDLANG_ACTIVITIES_OVERDUE_ICON_TOOLTIP : translationKeys.VDLANG_ACTIVITIES_DUE_ICON_TOOLTIP,
            )}
        >
            {isOverdue ? <SmallErrorIcon color="error" /> : <SmallWarningIcon color="secondary" />}
        </Tooltip>
    );
};

export default SubtaskListItemDueIcon;
