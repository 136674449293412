import { useEffect, useState } from "react";

const useDebounceDimensions = (width: number | undefined, height: number | undefined) => {
    const [cachedDimensions, setCachedDimensions] = useState({ width, height });

    useEffect(() => {
        // Delay the changed dimensions until the current frame has been finished. This also means that all
        // ResizeObserver events have been processed. That way, resize observer loop errors will be avoided
        requestAnimationFrame(() => {
            setCachedDimensions({ width, height });
        });
    }, [width, height]);

    return cachedDimensions;
};
export default useDebounceDimensions;
