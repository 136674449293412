import React from "react";
import type { CheckStatus } from "../../../lib/tree";

type TreeContextValue = {
    isMulti: boolean;
    checkStates: Map<number, CheckStatus>;
    isSelectableStates: Map<number, boolean>;
};

const TreeInputContext = React.createContext<TreeContextValue | undefined>(undefined);

export const TreeInputContextProvider = ({
    checkStates,
    isMulti,
    children,
    isSelectableStates,
}: React.PropsWithChildren<TreeContextValue>) => {
    const value = React.useMemo(() => ({ checkStates, isMulti, isSelectableStates }), [checkStates, isMulti, isSelectableStates]);
    return <TreeInputContext.Provider value={value}>{children}</TreeInputContext.Provider>;
};

export const useTreeInputContext = () => {
    const context = React.useContext(TreeInputContext);
    if (context === undefined) {
        throw new Error("Tree context not defined");
    }
    return context;
};
