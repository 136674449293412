import { ErrorConstantKeys, ErrorMessage } from "api-shared";
import { ErrorDetails } from "api-shared/src/error/ErrorDetails";
import { TFunction } from "i18next";
import { errorTranslations, isBadRequestError } from "../translations/error-translations";
import { ApiError } from "./api";

/**
 * Resolve an error message or constant to a translation key.
 *
 * Returns a specific error message if there is a ErrorConstantKey provided that has a specific message. If it does not have one or a string
 * is provided, it falls back to "Unknown Error".
 *
 * @param {(string | ErrorConstantKeys)} message
 * @returns
 */
export const getTranslationKeyForError = (message: string | ErrorConstantKeys) => {
    const errorCode = +message;
    let key = message;
    if (isBadRequestError(errorCode)) {
        key = ErrorConstantKeys.VDERROR_BAD_REQUEST;
    } else if (!(errorCode in errorTranslations.error)) {
        key = ErrorConstantKeys.VDERROR_UNKNOWN;
    }
    return `error.${key}`;
};

/**
 * Extract the error constant from the given message and map it to some human readable string, such as VDERROR_BAD_REQUEST
 *
 * @export
 * @param {ErrorMessage} content
 * @param {string} fallback
 * @returns
 */
export function resolveErrorConstantKey(content: ErrorMessage | string, fallback: string) {
    if (typeof content === "string") {
        // Deployment page
        return fallback;
    }

    if (!("message" in content)) {
        // ValidationError
        return fallback;
    }
    if (!(content.message in ErrorConstantKeys)) {
        // string message, not an ErrorConstantKeys
        return fallback;
    }
    return ErrorConstantKeys[content.message as ErrorConstantKeys];
}

/**
 * Extract message out of a request error.
 *
 * Understands both ApiErrors (such as BadRequest, ...) and other errors (such as NetworkError, TypeError, ...)
 *
 * @export
 * @param {(Error | ApiError)} error
 * @returns {({ message: string | ErrorConstantKeys; details: ErrorDetails })}
 */
export function extractErrorMessage(error: Error | ApiError): { message: string | ErrorConstantKeys; details: ErrorDetails } {
    if (error instanceof ApiError) {
        const { content } = error;
        return {
            message: typeof content !== "string" && "message" in content ? content.message : ErrorConstantKeys.VDERROR_UNKNOWN,
            details: typeof content !== "string" && "details" in content ? content.details : {},
        };
    }
    return {
        message: error.message,
        details: {},
    };
}

/**
 * Get a localized representation of the provided ApiError.
 *
 * Handles all ValidationErrors as "Bad Request" error
 *
 * @param {ApiError} apiError
 * @param {TFunction} translate
 * @returns
 */
export const getTranslatedErrorMessage = (apiError: ApiError, translate: TFunction) => {
    const { content: errorContent } = apiError;
    // Validation errors do only have errors property and no message+details, fall back to showing a general "Bad Request" error
    let messageKey: ErrorConstantKeys | string = ErrorConstantKeys.VDERROR_UNKNOWN; // Deployment page when errorContent type is string
    if (typeof errorContent !== "string" && "errors" in errorContent) {
        messageKey = ErrorConstantKeys.VDERROR_BAD_REQUEST;
    } else if (typeof errorContent !== "string") {
        messageKey = errorContent.message;
    }
    const details = typeof errorContent !== "string" && "details" in errorContent ? errorContent.details : {};
    return translate(getTranslationKeyForError(messageKey), details);
};

/**
 * Check if the provided Error or ErrorEvent is a non-breaking error that can be safely ignored. Those errors won't be reported to sentry
 * and won't show the error page.
 *
 * Be careful to add anything here, is this will applied EVERYWHERE (queries, mutations, global thrown errors, rejected promises, ...)
 *
 * If you want to ignore an error on a specific query/mutation, use consider using { meta: { ignoreErrors: true }} which will also skip
 * error handling, but only in that specific place.
 *
 * @param {unknown} error
 * @returns {boolean}
 */
export function isIgnoredError(error: unknown): boolean {
    if (error instanceof DOMException && error.name === "AbortError") {
        // Request was aborted, usually this means the browser terminated the request because the tab/window has been closed
        return true;
    }

    // ResizeObserver triggers errors, that are actually more like warnings instead of errors, so they should not stop the application
    const resizeObserverErrorMessages = [
        /ResizeObserver loop limit exceeded/,
        /ResizeObserver loop completed with undelivered notifications/,
    ];
    return error instanceof ErrorEvent && resizeObserverErrorMessages.some((regex) => regex.test(error.message));
}
