import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import { MatomoInstance, TrackEventParams } from "@datapunt/matomo-tracker-react/lib/types";
import { PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { environment } from "../lib/environment";

let tracking: MatomoInstance | undefined;
export const getTracker = () => {
    if (tracking === undefined && environment.trackingSiteId) {
        tracking = createInstance({
            urlBase: "https://piwik.valuedesk.de",
            siteId: +environment.trackingSiteId,
        });
    }
    return tracking;
};

export function matomoSetUserSession(userId: number, clientId: number) {
    const tracker = getTracker();
    tracker?.pushInstruction("setUserId", `u${userId}`);
    tracker?.pushInstruction("setCustomDimension", 1, `c${clientId}`);
}

export function matomoSetAnonymousSession() {
    matomoSetUserSession(-1, -1);
}

/**
 * Track a search. The type is used to set the origin of the search (e.g. grid, method guide, ...)
 *
 * @export
 * @param {string} keyword
 * @param {string} type
 * @param {number} [count]
 */
export function matomoTrackSiteSearch(keyword: string, type: string, count?: number) {
    const tracker = getTracker();
    tracker?.pushInstruction("trackSiteSearch", `__${type}__ ${keyword}`, false, count);
}

type DashboardEventAction =
    | "Widget Created"
    | "Widget Updated"
    | "Dashboard Created"
    | "Dashboard Updated"
    | "Dashboard Deleted"
    | "Dashboard Shared"
    | "Dashboard Withdrawn"
    | "Dashboard Selected"
    | "Dashboard Duplicated"
    | "Access Dialog Opened";
type ExportEventAction = "Print" | "Pdf" | "Csv";
type GridEventAction = "Process Pulse Hovered";
type FeedEventAction =
    | "Feed Items Loaded"
    | "Feed Item Commented"
    | "Feed Comment Seen"
    | "Feed Preview Opened"
    | "Feed Process Opened"
    | "Feed Idea Opened"
    | "Feed Entry Reaction Changed"
    | "Feed Not Found Measure";

type OppEventAction = "Create Opp Space" | "Create CTA";
type MeasureEventAction = "Measure Copy" | "Measure Preview Opened" | "Access Dialog Opened";
type AccessDialogEventAction = "Switched To User Tab" | "Switched To Group Tab";

interface BaseTrackingEvent {
    name?: string;
    value?: number;
}

interface DashboardEvent extends BaseTrackingEvent {
    category: "Dashboard";
    action: DashboardEventAction;
}

interface ExportEvent extends BaseTrackingEvent {
    category: "Export";
    action: ExportEventAction;
}

interface GridEvent extends BaseTrackingEvent {
    category: "Grid";
    action: GridEventAction;
}

interface FeedEvent extends BaseTrackingEvent {
    category: "Feed";
    action: FeedEventAction;
}

interface OppEvent extends BaseTrackingEvent {
    category: "Opp";
    action: OppEventAction;
}

interface MeasureEvent extends BaseTrackingEvent {
    category: "Measure";
    action: MeasureEventAction;
}

interface AccessDialogEvent extends BaseTrackingEvent {
    category: "Access Dialog";
    action: AccessDialogEventAction;
}

type TrackingEventOptions = DashboardEvent | ExportEvent | GridEvent | FeedEvent | OppEvent | MeasureEvent | AccessDialogEvent;

/**
 * Track an event, e.g. pdf export, printing a process or using the csv export in the Grid
 *
 * @export
 * @param {TrackingEventOptions} trackingEventOptions
 */
export function trackEvent({ category, action, name, value }: TrackingEventOptions) {
    const data: TrackEventParams & Record<string, any> = { category, action, name };
    if (value !== undefined) {
        data.value = value;
    }
    const tracker = getTracker();
    tracker?.trackEvent(data);
}

/**
 * MatomoRouterProvider provides Matomo hooks for children components
 * and it tracks page views on location change.
 *
 * It should be embedded within <Router>
 * https://github.com/jonkoops/matomo-tracker/issues/441
 */
export function MatomoRouterProvider({ children }: PropsWithChildren<unknown>) {
    const tracker = getTracker();

    // eslint-disable-next-line prefer-const
    let location = useLocation();
    useEffect(() => {
        // track page view on each location change
        tracker?.trackPageView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    if (tracker === undefined) {
        // Not wrapping in a fragment yields typescript errors
        return <>{children}</>;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <MatomoProvider value={tracker}>{children}</MatomoProvider>;
}
