import React, { ReactElement, useCallback, useState } from "react";

interface IUseMenuProps {
    onOpen?: React.MouseEventHandler;
    onClose?: () => void;
}

const defaultPositioning = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    } as const,
    transformOrigin: {
        vertical: "top",
        horizontal: "right",
    } as const,
};

const useMenu = ({ onOpen, onClose }: IUseMenuProps = {}) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const openMenu: React.MouseEventHandler = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
            typeof onOpen === "function" && onOpen(event);
        },
        [onOpen],
    );

    const closeMenu = useCallback(() => {
        setAnchorEl(null);
        typeof onClose === "function" && onClose();
    }, [onClose]);

    // This may be used to add menu closing to onClick handlers of child elements
    const makeCloseOnSelectHandler = (element: ReactElement) => (event: React.MouseEvent) => {
        closeMenu();
        const { onClick } = element.props;
        if (typeof onClick === "function") {
            onClick(event);
        }
    };

    const menuProps = {
        open: Boolean(anchorEl),
        anchorEl,
        onClose: closeMenu,
        ...defaultPositioning,
    };

    return {
        openMenu,
        closeMenu,
        makeCloseOnSelectHandler,
        menuProps,
        open: openMenu,
        close: closeMenu,
    };
};
export default useMenu;
