import { Sort } from "api-shared";
import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import PropTypes from "prop-types";
import useTimezone, { DateTimeFormatter } from "../../hooks/useTimezone";
import { translationKeys } from "../../translations/main-translations";

interface IDateGroup {
    label: translationKeys;
    end?: Moment;
}

const getStartOfGroup = (groups: IDateGroup[], index: number, sort: Sort, formatDate: DateTimeFormatter) => {
    const unsafeIndex = sort === Sort.ASCENDING ? index - 1 : index + 1;
    if (unsafeIndex >= groups.length && unsafeIndex < 0) {
        return null;
    }
    const earlierGroup = groups[unsafeIndex];

    if (earlierGroup?.end == null) {
        return null;
    }
    return earlierGroup?.end != null ? formatDate(earlierGroup.end.clone().add("1", "day")) : null;
};

function getDescription(start: string | null, end: string | null, label: string, translate: TFunction) {
    if (start == null && end == null) {
        return label;
    } else if (start == null) {
        return translate(translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OPEN_START, { date: end });
    } else if (end == null) {
        return translate(translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OPEN_END, { date: start });
    } else if (start !== end) {
        return `${start} ${translate("until")} ${end}`;
    }
    return start;
}

interface ISubtaskListDateGroupHeaderProps {
    translate: TFunction;
    sort: Sort;
    index: number;
}

const SubtaskListDateGroupHeader = ({ sort, index, translate }: ISubtaskListDateGroupHeaderProps) => {
    const { timezone, formatDate } = useTimezone();
    const now = moment.tz(timezone);

    const groups = [
        {
            label: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OVERDUE,
            end: now.clone().subtract("1", "day"),
        },
        { label: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_TODAY, end: now.clone().endOf("day") },
        { label: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_WEEK, end: now.clone().endOf("week") },
        { label: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_MONTH, end: now.clone().endOf("month") },
        { label: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_FUTURE },
        { label: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_NO_DUEDATE },
    ];

    // we might run into a race condition, where old group data will be rendered with new group labels
    // Then index might exceed groups array size
    if (groups[index] === undefined) {
        return null;
    }

    if (sort === Sort.DESCENDING) {
        groups.reverse();
    }

    const currentGroup = groups[index];
    const start = getStartOfGroup(groups, index, sort, formatDate);
    const end = currentGroup.end != null ? formatDate(currentGroup.end) : null;

    const label = translate(currentGroup.label);
    const description = getDescription(start, end, label, translate);

    return <>{`${label} (${description})`}</>;
};

SubtaskListDateGroupHeader.propTypes = {
    index: PropTypes.number.isRequired,
    translate: PropTypes.func.isRequired,
    sort: PropTypes.oneOf(Object.values(Sort)),
};

export default SubtaskListDateGroupHeader;
