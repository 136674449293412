import { useCallback, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useForkedRef from "./useForkedRef";

interface IUseInfiniteScrollingStateProps {
    isFetching: boolean;
    hasNextPage: boolean;
    loadPage?: (page: number) => void;
    loadNext?: () => void;
    parentRef?: React.Ref<unknown>;
    delayInMs?: number;
}

/**
 * Add pages support to react-infinite-scroll-hook.
 * Also widens the type of parentRef to accept anything.
 */
const useInfiniteScrollingState = ({
    isFetching,
    hasNextPage,
    loadPage,
    loadNext,
    parentRef,
    delayInMs,
}: IUseInfiniteScrollingStateProps) => {
    const [nextPage, setNextPage] = useState(1);

    const loadMoreItems = useCallback(() => {
        if (loadPage) {
            loadPage(nextPage);
            setNextPage((p) => p + 1);
        } else if (loadNext && !isFetching && hasNextPage) {
            loadNext();
        }
    }, [loadPage, loadNext, isFetching, hasNextPage, nextPage]);

    const [guardRef, { rootRef }] = useInfiniteScroll({
        loading: isFetching,
        hasNextPage: hasNextPage,
        onLoadMore: loadMoreItems,
        delayInMs,
        rootMargin: "0px 0px 40px 0px",
    });

    const combinedRef = useForkedRef(parentRef, rootRef);

    return {
        guardRef,
        rootRef: combinedRef,
    };
};

export default useInfiniteScrollingState;
