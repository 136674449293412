import { Grid, styled } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { PropsWithChildren } from "react";

const Root = styled(Grid)(({ theme }) => ({
    height: "100vh",
}));

const Left = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

const Right = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
    backgroundColor: lightBlue[50],
    padding: theme.spacing(0, 3),
}));

const Image = styled("img")(({ theme }) => ({
    width: "100%",
    maxWidth: 620,
}));

export interface ISplitLayoutProps {
    image: string;
}

function SplitLayout({ image, children }: PropsWithChildren<ISplitLayoutProps>) {
    return (
        <Root container>
            <Left item md={6} xs={12}>
                {children}
            </Left>
            <Right item container justifyContent="center" alignItems="center" md={6}>
                <Image src={image} alt="" />
            </Right>
        </Root>
    );
}

export default SplitLayout;
