import { Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import DialogTitle from "./DialogTitle";

// These classes will be applied to the dialogs children and make it easy to customize the different elements like this:
// const MyDialog = styled(Dialog)(({ theme }) => ({
//     [`& .${baseDialogClasses.title}`]: {
//         // some styles
//     }
// }))
export const baseDialogClasses = {
    root: "VdBaseDialog-root",
    paper: "VdBaseDialog-paper",
    title: "VdBaseDialog-title",
    content: "VdBaseDialog-content",
    actions: "VdBaseDialog-actions",
};

export interface IBaseDialogProps extends Omit<DialogProps, "title"> {
    open: boolean;
    onClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
    title?: React.ReactNode;
    actions?: ReactNode;
    menuActions?: ReactElement[];
    children?: ReactNode;
    shapedHeader?: boolean;
    titleLabel?: ReactNode;
    readOnlyLabel?: ReactNode;
    disableContentPadding?: boolean;
}

const BaseDialog = ({
    open,
    onClose,
    title,
    children,
    actions,
    menuActions,
    shapedHeader,
    titleLabel,
    readOnlyLabel,
    maxWidth = "sm",
    disableContentPadding,
    ...other
}: IBaseDialogProps) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth={maxWidth}
            disableRestoreFocus
            PaperProps={{
                variant: "elevation",
                className: baseDialogClasses.paper,
            }}
            {...other}
        >
            {readOnlyLabel}
            {title != null || (menuActions != null && menuActions.length > 0) ? (
                <DialogTitle shaped={shapedHeader} className={baseDialogClasses.title} actions={menuActions} titleLabel={titleLabel}>
                    {title}
                </DialogTitle>
            ) : null}
            <DialogContent dividers className={baseDialogClasses.content} sx={disableContentPadding ? { p: 0 } : undefined}>
                {children}
            </DialogContent>
            {actions !== undefined ? <DialogActions className={baseDialogClasses.actions}>{actions}</DialogActions> : null}
        </Dialog>
    );
};

export default BaseDialog;
