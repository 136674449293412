import MarkdownIt from "markdown-it";
import MarkdownItInsPlugin from "markdown-it-ins";

/**
 * Create a safe HTML from Markdown
 * https://github.com/markdown-it/markdown-it/blob/master/docs/security.md
 *
 * @param {string} value
 * @param {(id: number) => string} getNameForId
 * @param {(id: number, name: string) => string} renderMentionElement
 * @returns
 */
export const markdownToSafeHtml = (
    value: string | null | undefined,
    getNameForId: (id: number) => string,
    renderMentionElement: (id: number, name: string) => string,
): string => {
    if (value == null || value.length === 0) {
        return "";
    }

    // Markdown "cleans" whitespaces https://github.com/domchristie/turndown/issues/160
    // Restore whitespaces using helper
    // Keep in sync with CustomTransformers
    const sanitizedValue = value.replace(/\s\s\n/g, "§§§§\n"); // => <p>§§§§</p>

    const mdit = new MarkdownIt({
        linkify: true,
    }).use(MarkdownItInsPlugin); // Plugin adds support for conversion of ++ to <ins>

    // Allow svg - https://github.com/markdown-it/markdown-it/issues/941 - https://github.com/markdown-it/markdown-it/pull/769
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const originalValidateLink = mdit.validateLink;
    mdit.validateLink = (url) => {
        return originalValidateLink(url) || url.startsWith("data:image/svg+xml;");
    };

    // Target _blank and rel noopener for links
    mdit.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        tokens[idx].attrPush(["target", "_blank"]);
        tokens[idx].attrPush(["rel", "noopener"]);
        return self.renderToken(tokens, idx, options);
    };

    let html = mdit.render(sanitizedValue);

    // Emojis are already stored as unicode chars but we need to recover our mentions.
    html = restoreHtmlMentions(html, getNameForId, renderMentionElement);

    // Replace <ins> tags with <u>, because quill only supports <u> for underlining content - let's keep this for lexical as well
    html = html.replaceAll("<ins>", "<u>").replaceAll("</ins>", "</u>");

    // Restore whitespaces using helper
    html = html.replace(/§§§§/g, "<br>");

    return html;
};

/**
 * Render element for @uid:id placeholders
 *
 * @param {string} html
 * @param {(id: number) => string} getNameForId
 * @param {(id: number, name: string) => string} renderMentionElement
 * @returns {string}
 */
function restoreHtmlMentions(
    html: string,
    getNameForId: (id: number) => string,
    renderMentionElement: (id: number, name: string) => string,
): string {
    let newHtml: string[] = [];
    const regValidateFormat = /@uid:\d+/g; // validate format @uid:123

    // Find token and optional trailing white-space
    const splittedData = html.split(/(@uid:\d+(\s)?)/g);
    // Iterate the array of new insert segment. The regex group makes sure to keep the mention token as it is.
    // "hello @uid:1 world" => ["hello ", "@uid:1", " world"]
    for (const group of splittedData) {
        // Check if the current element is a mention token and includes a white-space
        if (regValidateFormat.test(group)) {
            // Extract NUMBER from {@NUMBER}
            const regFindId = /@uid:(\d+)(\s)?/g;

            // Already tested for group now extract the id
            const exec = regFindId.exec(group);
            // This should never happen as we tested this already before executing the parse. But if you fu** up the regex...
            if (exec === null) {
                continue;
            }
            const id = Number(exec[1]);
            // Find the name for the id
            const name = getNameForId(id);
            // Restore space if needed
            const space = exec[2] != null ? "" : "&nbsp;";

            const elemet = renderMentionElement(id, name);

            newHtml = [...newHtml, `${elemet}${space}`];
        } else {
            newHtml = [...newHtml, group];
        }
    }
    return newHtml.join("");
}
