import { AttributeTitle } from "api-shared";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { Field } from "../../lib/fields";
import { translationKeys } from "../../translations/main-translations";
import Select from "./select/Select";
import { ISelectProps, Option } from "./select/types";

function isSameMultiValue<T>(oldValue: T | T[], newValue: T[]) {
    // newValue is always an array
    // oldValue might be an array or null
    const sanitizedOldValue = Array.isArray(oldValue) ? oldValue : [];

    // both are arrays, so compare arrays
    return sanitizedOldValue.length === newValue.length && newValue.every((v) => sanitizedOldValue.includes(v));
}

export function compareValues<T>(
    newOptions: T | T[] | null,
    currentValue: number | number[] | null,
    accessor: (option: T) => number,
): { isSame: boolean; newValue: number[] | number | null } {
    if (Array.isArray(newOptions)) {
        const newValue = newOptions.map(accessor);
        return {
            newValue,
            isSame: isSameMultiValue(currentValue, newValue),
        };
    }
    const newValue = newOptions != null ? accessor(newOptions) : null;
    return {
        newValue,
        isSame: newValue === (currentValue ?? null),
    };
}

export interface FieldSelectOption<T> extends Option<T> {
    id: number;
    name: string;
}

export interface IUseFieldSelectOptionsPropsProps<T = any> {
    value: T | T[] | null;
    isMulti: boolean | undefined;
    updateValue: (newValue: T | T[] | null) => void;
    options: FieldSelectOption<T>[];
    field: Field;
}
export function useFieldSelectOptionsProps({ value, isMulti, updateValue, options, field }: IUseFieldSelectOptionsPropsProps<number>) {
    const isOriginatorClient = field.title === AttributeTitle.OriginatorClient;
    const sanitizedValue = isOriginatorClient && value === null ? 0 : value;

    const selectedOptions =
        isMulti && Array.isArray(sanitizedValue)
            ? options.filter((o) => sanitizedValue.includes(o.id))
            : options.filter((o) => sanitizedValue != null && o.id === +sanitizedValue);
    const onChange: ISelectProps<FieldSelectOption<number>, boolean>["onChange"] = useCallback(
        (newOptions: any) => {
            const currentValue = value;
            const { isSame, newValue } = compareValues<FieldSelectOption<number>>(newOptions, currentValue, (o) => o.id);
            // only propagate when value changed
            if (!isSame) {
                updateValue(newValue);
            }
        },
        [value, updateValue],
    );
    const allOptionsFixed = options.every(({ isFixed }) => isFixed);

    return { value: selectedOptions, onChange, allOptionsFixed };
}

export interface IUseFieldSelectPropsProps {
    isMulti: boolean | undefined;
    field: Field;
    translate: TFunction;
    disabled: boolean;
    isClearable?: boolean;
}

export function useFieldSelectProps({ isMulti, field, translate, disabled, isClearable: isClearableProps }: IUseFieldSelectPropsProps) {
    const isOriginatorClient = field.title === AttributeTitle.OriginatorClient;

    // allow override of isClearable via props
    const isClearable = isClearableProps ?? (!isMulti && !isOriginatorClient);

    const noOptionsMessage = useCallback(
        ({ inputValue }: { inputValue: string }) => {
            return translate(
                inputValue.length > 0 && inputValue.length < 3
                    ? translationKeys.VDLANG_SELECT_TYPE_AT_LEAST_3_CHARACTERS
                    : translationKeys.VDLANG_SELECT_NO_RESULTS,
            );
        },
        [translate],
    );

    const shortenedOptionsMessage = translate(translationKeys.VDLANG_SELECT_MORE_OPTIONS_HINT);
    const placeholder = translate(translationKeys.VDLANG_SELECT_PLEASE_SELECT);
    const inputId = field.title;

    return {
        isMulti,
        isClearable,
        noOptionsMessage,
        shortenedOptionsMessage,
        placeholder,
        isSearchable: true,
        inputId,
        isDisabled: disabled,
        margin: "none" as const,
        isCreatable: Boolean(field.isCreatable),
    };
}

export interface IFieldSelectProps
    extends IUseFieldSelectPropsProps,
        IUseFieldSelectOptionsPropsProps<number>,
        // For some reason isMulti gives compiler errors, although the resolved types match, so omit isMulti here
        Omit<ISelectProps<FieldSelectOption<number>, boolean>, "value" | "options" | "isMulti"> {}

const FieldSelect = (props: IFieldSelectProps) => {
    const {
        options,
        disabled,
        value, // typed version of field.value
        updateValue,
        isClearable: isClearableProps,
        field,
        translate,
        ...otherProps
    } = props;

    const fieldSelectProps = useFieldSelectProps(props);
    const fieldSelectOptionsProps = useFieldSelectOptionsProps(props);

    return (
        <Select
            getOptionLabel={({ name, label }) => name ?? label} // Fallback for creatable options from react-select
            getOptionValue={({ id }) => String(id)}
            options={options}
            {...fieldSelectProps}
            isClearable={fieldSelectProps.isClearable && (!fieldSelectOptionsProps.allOptionsFixed || fieldSelectProps.isCreatable)}
            {...fieldSelectOptionsProps}
            {...otherProps}
        />
    );
};

export default FieldSelect;
