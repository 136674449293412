import { FormLabel, FormLabelProps, styled } from "@mui/material";
import { PropsWithChildren } from "react";
import InfoIcon from "./icons/InfoIcon";

const StyledFormLabel = styled(FormLabel)<Pick<OverlineLabelProps, "size">>(({ theme, size }) => ({
    letterSpacing: 2,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "0.75rem",
    textTransform: "uppercase",
    lineHeight: 1.33,
    display: "inline-block",
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    ...(size === "small" && {
        lineHeight: 1.6,
        fontSize: "0.625rem",
        fontWeight: theme.typography.fontWeightMedium,
        letterSpacing: theme.typography.pxToRem(1.5),
    }),
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    color: theme.palette.action.active,
    marginLeft: theme.spacing(0.5),
    fontSize: theme.typography.body1.fontSize,
    verticalAlign: "-0.2em",
}));

interface OverlineLabelProps extends FormLabelProps {
    hint?: string;
    size?: "small" | "medium";
}

const OverlineLabel = ({ hint, children, ...props }: PropsWithChildren<OverlineLabelProps>) => {
    return (
        <StyledFormLabel {...props}>
            {children}
            {hint !== undefined && <StyledInfoIcon title={hint} />}
        </StyledFormLabel>
    );
};

export default OverlineLabel;
