import { Grow } from "@mui/material";
import { MenuProps } from "react-select";
import { Option } from "../types";
import SelectMenuContainer from "./SelectMenuContainer";

const SelectMenu = <OptionType extends Option, IsMulti extends boolean>({
    placement = "bottom",
    ...menuProps
}: MenuProps<OptionType, IsMulti>) => {
    const originY = placement === "top" ? "bottom" : "top";
    return (
        <Grow in style={{ transformOrigin: `right ${originY} 0` }}>
            <SelectMenuContainer placement={placement} {...menuProps} />
        </Grow>
    );
};
export default SelectMenu;
