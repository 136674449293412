import { CurrencyDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../translations/main-translations";
import Select from "./Select";
import { ISelectProps, Option } from "./types";

interface ICurrencySelectProps extends Omit<ISelectProps<Option<number>>, "onChange" | "value"> {
    currencies: CurrencyDto[];
    onChange: (newValue: number | null) => void;
    value: number | null;
    labelVariant?: "code" | "full";
    disabled?: boolean;
}

const CurrencySelect = ({ currencies, onChange, value, labelVariant = "full", disabled, ...selectProps }: ICurrencySelectProps) => {
    const { t: translate } = useTranslation();

    const currencyOptions: Option<number>[] = currencies.map((currency) => ({
        label: labelVariant === "code" ? `${currency.isoCode}` : `${currency.isoCode} (${currency.name})`,
        primaryLabel: `${currency.isoCode} (${currency.name})`,
        value: currency.id,
    }));

    const selectedCurrency = currencyOptions.find((o) => o.value === value) ?? null;

    const handleChange = (option: Option<number> | null) => onChange(option !== null ? option.value : null);

    return (
        <Select
            isMulti={false} // no support for multi selection needed yet
            options={currencyOptions}
            value={selectedCurrency}
            onChange={handleChange}
            placeholder={translate(translationKeys.VDLANG_SELECT_PLEASE_SELECT)}
            isDisabled={disabled}
            {...selectProps}
        />
    );
};

export default CurrencySelect;
