import { Link, LinkProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../hooks/useLanguage";
import { ExternalRoutes } from "../lib/routes";
import { Language, translationKeys } from "../translations/main-translations";

const PrivacyPolicyLink = ({ className, ...linkProps }: LinkProps) => {
    const language = useLanguage();
    const { t: translate } = useTranslation();
    const privacyPolicy = language === Language.EN ? ExternalRoutes.privacyPolicy[Language.EN] : ExternalRoutes.privacyPolicy[Language.DE];

    return (
        <Link href={privacyPolicy} component="a" target="_blank" className={className} {...linkProps}>
            {translate(translationKeys.VDLANG_PRIVACY_POLICY)}
        </Link>
    );
};

export default PrivacyPolicyLink;
