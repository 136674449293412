import { Card, styled, Typography } from "@mui/material";
import React from "react";
import logo from "../static/images/logo_blau.svg";
import PrivacyPolicyLink from "./PrivacyPolicyLink";

const Container = styled("div")(({ theme }) => ({
    width: "100%",
    maxWidth: theme.breakpoints.values.sm,
    margin: "0px auto",
    padding: theme.spacing(3),
    paddingBottom: 0,
}));

const Logo = styled("img")(({ theme }) => ({
    width: 320,
    maxWidth: "100%",
    padding: theme.spacing(3),
}));

const CenteredContainer = styled("div")({
    textAlign: "center",
});

interface IStandaloneCardProps {
    noCenter?: boolean;
}

const StandaloneCard = ({ children, noCenter }: React.PropsWithChildren<IStandaloneCardProps>) => {
    return (
        <Container>
            <CenteredContainer>
                <Logo alt="valuedesk-logo" src={logo} />
            </CenteredContainer>
            <Card component={noCenter ? "div" : CenteredContainer}>{children}</Card>
            <CenteredContainer>
                <Typography align="center" variant="caption">
                    <PrivacyPolicyLink color="textSecondary" />
                </Typography>
            </CenteredContainer>
        </Container>
    );
};

export default StandaloneCard;
