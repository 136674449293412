import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Grid, styled } from "@mui/material";
import { AclNamespaces, AclPermissions, CreateSupplierRequestBody } from "api-shared";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { useCreateSupplierMutation } from "../../domain/suppliers";
import useDialog from "../../hooks/useDialog";
import { FilledField, mergeFieldValues } from "../../lib/fields";
import { translationKeys } from "../../translations/main-translations";
import CreateSupplierDialog from "../../view/admin/suppliers/CreateSupplierDialog";
import Authorization from "../Authorization";
import Tooltip from "../Tooltip";
import FieldSelect, { IFieldSelectProps } from "./FieldSelect";

const AddButton = styled(Button)(({ theme }) => ({
    // align button height with the select
    padding: theme.spacing(0.625),
    minWidth: 0,
}));

interface ISupplierFieldSelectProps extends IFieldSelectProps {
    field: FilledField;
    isMulti: boolean;
    disabled: boolean;
    translate: TFunction;
}

const SupplierFieldSelect = (props: ISupplierFieldSelectProps) => {
    const { field, updateValue, isMulti, disabled, translate } = props;

    const dialog = useDialog();
    const createSupplier = useCreateSupplierMutation().mutate;

    const handleCreateSupplier = useCallback(
        (supplier: CreateSupplierRequestBody) => {
            createSupplier(supplier, {
                onSuccess: (response) => {
                    const merged = mergeFieldValues(field.value, response.id, isMulti);
                    const values = merged.split(",").map((value) => Number(value));
                    updateValue(values);
                },
            });
        },
        [createSupplier, field.value, updateValue, isMulti],
    );

    const tooltip = translate(translationKeys.VDLANG_PROCESS_TOOLTIPS_ADD_FIELD_OPTION, {
        name: translate("tableName.suppliers"),
    });

    return (
        <Grid container wrap="nowrap" alignItems="stretch" spacing={1}>
            <CreateSupplierDialog open={dialog.isOpen} createSupplier={handleCreateSupplier} onClose={dialog.closeDialog} />
            <Grid item zeroMinWidth flexGrow={1} maxWidth="100%">
                <FieldSelect {...props} />
            </Grid>
            <Authorization namespace={AclNamespaces.Supplier} permission={AclPermissions.Create}>
                <Grid item>
                    <Tooltip title={tooltip}>
                        <span>
                            <AddButton onClick={dialog.openDialog} disabled={disabled}>
                                <AddIcon />
                            </AddButton>
                        </span>
                    </Tooltip>
                </Grid>
            </Authorization>
        </Grid>
    );
};

export default SupplierFieldSelect;
