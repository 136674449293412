import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";
import { defaultTheme } from "../lib/themes";

const ThemeSelector = ({ children }: React.PropsWithChildren<unknown>) => {
    useEffect(() => {
        // Make theme availabe as global variable for better DX
        (window as any).theme = defaultTheme;
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline enableColorScheme />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default ThemeSelector;
