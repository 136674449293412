import { KeyboardEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiValue, OnChangeValue } from "react-select";
import { isCommaEvent, isEnterEvent, isSpaceEvent } from "../../lib/keybindings";
import { translationKeys } from "../../translations/main-translations";
import Select from "./select/Select";
import { ISelectProps, Option } from "./select/types";

interface IMultiIntegerSelectProps extends Omit<ISelectProps<Option<number>>, "onChange" | "value"> {
    value: (string | number)[];
    onChange: (newValues: number[]) => void;
}

const INTEGER_REGEX = /^\d*$/;

const MultiIntegerInput = ({ value, onChange, isDisabled, error, label }: IMultiIntegerSelectProps) => {
    const selectedValues = value.map((v) => ({ label: v.toString(), value: Number(v) }));
    const [inputValue, setInputValue] = useState<string>("");
    const [showOnlyDigitsHelp, setShowOnlyDigitsHelp] = useState<boolean>(false);
    const [showAlreadyExistsHelp, setShowAlreadyExistsHelp] = useState<boolean>(false);
    const { t: translate } = useTranslation();

    const hasValue = selectedValues.length !== 0;

    const updateValue = (newValue: MultiValue<Option<number>>) => {
        onChange(newValue.map((v) => v.value));
    };

    const handleChange = (value: OnChangeValue<Option, true>) => {
        updateValue(value);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (inputValue === "") {
            return;
        }
        if (isEnterEvent(event) || isSpaceEvent(event) || isCommaEvent(event)) {
            const parsedNewInput = +inputValue;
            const valueAlreadyExists = selectedValues.findIndex(({ value }) => value === parsedNewInput) > -1;
            event.preventDefault();
            if (valueAlreadyExists) {
                setShowAlreadyExistsHelp(true);
                return;
            }
            const newValues = [...selectedValues, { label: parsedNewInput.toString(), value: parsedNewInput }];
            updateValue(newValues);
            setInputValue("");
        }
        setShowAlreadyExistsHelp(false);
    };

    const handleInputChange = (inputValue: string) => {
        if (!INTEGER_REGEX.test(inputValue)) {
            setShowOnlyDigitsHelp(true);
            return;
        }
        setInputValue(inputValue);
        setShowOnlyDigitsHelp(false);
    };

    const helpMsg = showOnlyDigitsHelp
        ? translate(translationKeys.VDLANG_INTEGER_INPUT_ONLY_DIGITS)
        : showAlreadyExistsHelp
          ? translate(translationKeys.VDLANG_ENTRY_ALREADY_EXISTS)
          : null;

    return (
        <Select
            margin="none"
            isSearchable
            isClearable={hasValue}
            onChange={handleChange}
            value={selectedValues}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            onKeyDown={handleKeyDown}
            isMulti
            menuIsOpen={false}
            label={label}
            isDisabled={isDisabled}
            textFieldProps={{ helperText: helpMsg }}
            error={error}
        />
    );
};

export default MultiIntegerInput;
