import { TextField } from "@mui/material";
import useNavbarSearch from "../../hooks/useNavbarSearch";
import SearchResultList from "./SearchResultList";

const MIN_CHARS_BEFORE_RESULTS = 5;

interface CallToActionTitleInputProps {
    title: string;
    updateTitle: React.ChangeEventHandler<HTMLInputElement>;
    isTitleError: boolean;
    helperText: React.ReactNode;
    label: React.ReactNode;
    placeholder: string;
}

const CallToActionTitleInput = ({ title, updateTitle, isTitleError, helperText, label, placeholder }: CallToActionTitleInputProps) => {
    const { results, isValidKey } = useNavbarSearch(title, MIN_CHARS_BEFORE_RESULTS);

    return (
        <>
            <TextField
                required
                fullWidth
                autoFocus
                name="title"
                margin="normal"
                label={label}
                error={isTitleError}
                helperText={helperText}
                placeholder={placeholder}
                onChange={updateTitle}
                value={title}
            />
            {isValidKey && results !== undefined && (
                <SearchResultList
                    results={results}
                    searchKey={title}
                    maxResults={3}
                    minLength={MIN_CHARS_BEFORE_RESULTS}
                    isValidKey={title.length >= MIN_CHARS_BEFORE_RESULTS && isValidKey}
                    showSearchHints={false}
                    embedded
                />
            )}
        </>
    );
};

export default CallToActionTitleInput;
