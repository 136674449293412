import { DialogContent, FormControl, FormLabel, Grid } from "@mui/material";

import { AclNamespaces, AclPermissions, IdeaDto } from "api-shared";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import Alert from "../../components/Alert";
import ActionItemDialog from "../../components/dialogues/ActionItemDialog";
import Select from "../../components/input/select/Select";
import { Option } from "../../components/input/select/types";
import SingleUser from "../../components/user/SingleUser";
import { useMeasureConfigs } from "../../domain/measure-config";
import { useUsersHavingPermissionQuery } from "../../domain/permissions";
import { useAllUsers } from "../../domain/users";
import { tryTranslate } from "../../lib/translate";
import { translationKeys } from "../../translations/main-translations";

export interface IConvertIdeaDialogProps {
    onConvert: (userId: number, measureConfigId: number) => void;
    onClose: () => void;
    isOpen: boolean;
    idea: IdeaDto;
    ownerId: number | null;
    hasUnsavedChanges: boolean;
}

const ConvertIdeaDialog = ({ isOpen, onConvert, onClose, idea, ownerId, hasUnsavedChanges }: IConvertIdeaDialogProps) => {
    const { t } = useTranslation();

    const responsibleQuery = useUsersHavingPermissionQuery({
        permission: AclPermissions.Responsible,
        namespace: AclNamespaces.Process,
        fact: {},
    });
    const allUsers = useAllUsers();

    const measureConfigs = useMeasureConfigs();
    const measureConfigOptions = measureConfigs.map(({ id, name }) => ({
        value: id,
        label: t(name),
    }));

    const [userId, setUserId] = useState<number | null>(ownerId);
    const [measureConfigId, setMeasureConfigId] = useState<number | null>(measureConfigs[0]?.id ?? null);

    const valuestreamQuery = useUsersHavingPermissionQuery(
        {
            permission: AclPermissions.Read,
            namespace: AclNamespaces.Valuestream,
            fact: measureConfigId !== null ? { id: measureConfigId } : {},
        },
        measureConfigId !== null,
    );

    useEffect(() => {
        if (isOpen) {
            setUserId(ownerId);
        }
    }, [isOpen, ownerId]);

    if (!responsibleQuery.isSuccess) {
        return null;
    }

    const editors = allUsers.filter((u) => {
        return responsibleQuery.data.combinedUserIds.includes(u.id) && valuestreamQuery.data?.combinedUserIds?.includes(u.id);
    });
    const ideaResponsibleCanBeProcessResponsible = editors.find((e) => e.id === userId) !== undefined;

    const handleConvert = () => {
        if (userId && measureConfigId) {
            onConvert(userId, measureConfigId);
        }
    };

    const primaryTextTranslationKey = hasUnsavedChanges
        ? translationKeys.VDLANG_IDEAS_SAVE_AND_CONVERT
        : translationKeys.VDLANG_IDEAS_CONVERT;
    const alertText = t(hasUnsavedChanges ? translationKeys.VDLANG_IDEAS_SAVE_AND_CONVERT_INFO : "action_cannot_be_undone");

    if (valuestreamQuery.isLoading) {
        return null;
    }

    return (
        <ActionItemDialog
            open={isOpen}
            onClose={onClose}
            primaryDisabled={!userId || !ideaResponsibleCanBeProcessResponsible}
            primary={primaryTextTranslationKey}
            onPrimary={handleConvert}
            translate={t}
            title={t(translationKeys.VDLANG_IDEAS_CONVERT_TITLE, {
                opportunity: tryTranslate(t, idea.title),
            })}
        >
            <DialogContent sx={{ p: 0 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Select
                            margin="none"
                            label={t(translationKeys.VDLANG_DATA_IMPORT_PROCESS_TYPE)}
                            value={measureConfigOptions.find((option) => option.value === measureConfigId)}
                            options={measureConfigOptions}
                            onChange={(option) => setMeasureConfigId((option as SingleValue<Option>)?.value ?? null)}
                            menuPortalTarget={document.body}
                            isMulti={false}
                        />
                    </Grid>
                    {!ideaResponsibleCanBeProcessResponsible && (
                        <Grid item xs={12}>
                            <Alert severity="warning">{t(translationKeys.VDLANG_IDEAS_NO_PROCESS_RESPONSIBILITY_INFO)}</Alert>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel>{t(translationKeys.VDLANG_IDEAS_PROCESS_RESPONSIBLE)}</FormLabel>

                            <SingleUser
                                users={editors}
                                user={editors.find((u) => u.id === userId) ?? null}
                                translate={t}
                                closeOnSelect
                                update={(userId: number | null) => setUserId(userId)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="info">{alertText}</Alert>
                    </Grid>
                </Grid>
            </DialogContent>
        </ActionItemDialog>
    );
};

export default ConvertIdeaDialog;
