import { CurrencyDto } from "api-shared";
import React, { PropsWithChildren } from "react";

const CurrencyContext = React.createContext<ICurrencyContext | undefined>(undefined);

export const CurrencyContextProvider = ({ currency, children }: PropsWithChildren<ICurrencyContext>) => {
    const value = React.useMemo(
        () => ({
            currency,
        }),
        [currency],
    );
    return <CurrencyContext.Provider value={value}>{children}</CurrencyContext.Provider>;
};

interface ICurrencyContext {
    currency: CurrencyDto;
}

/**
 * Hook for easy access to the context's value. Throws an error if not used inside the context.
 *
 * @returns {ICurrencyContext}
 */
export const useCurrencyContext = (): CurrencyDto => {
    const context = React.useContext(CurrencyContext);
    if (context === undefined) {
        throw new Error("Currency context not defined");
    }
    return context.currency;
};

export default useCurrencyContext;
