import { nonNullable } from "api-shared";
import { useMemo, useRef, useState } from "react";
import { useCostLeverSearch } from "../domain/methods/cost-lever";
import { useDebounce } from "../hooks/useDebounce";
import FieldSelect, { FieldSelectOption, IFieldSelectProps } from "./input/FieldSelect";
import LoadingAnimation from "./loading/LoadingAnimation";

interface IMethodSelectProps extends IFieldSelectProps {
    options: FieldSelectOption<number>[];
}

function showAllOptions() {
    return true;
}

const PaddedLoadingAnimation = () => <LoadingAnimation sx={{ p: 1 }} />;

const MethodSelect = ({ options, ...props }: IMethodSelectProps) => {
    // remember if all methods should be shown, e.g. when initially opening or the trimmed search key is empty
    const [showAll, setShowAll] = useState(true);

    // remember the callback to signal the select component when search results arrived
    const resultCallback = useRef<((options: FieldSelectOption<number>[]) => void) | null>(null);

    const [searchKey, setSearchKey] = useState("");
    const debouncedSearchKey = useDebounce(searchKey);

    const searchQuery = useCostLeverSearch({
        searchKey: debouncedSearchKey,
        onSuccess: (data) => {
            const callback = resultCallback.current;

            if (callback === null) {
                return;
            }

            resultCallback.current = null;

            // keep order of search results
            const filteredOptions = data.map((r) => options.find(({ id }) => r === id)).filter(nonNullable);
            callback(filteredOptions);
        },
    });

    const isLoading = !showAll && searchQuery.isLoading;

    const updateSearchKey: IFieldSelectProps["loadOptions"] = (newKey, callback) => {
        const trimmedKey = newKey.trim();
        const newShowAll = trimmedKey.length === 0;

        setShowAll(newShowAll);

        if (newShowAll) {
            // resolve immediately
            return callback(options);
        }

        // remember callback to resolve options when result set arrives
        resultCallback.current = callback;
        setSearchKey(trimmedKey);
    };

    const components = useMemo(() => (isLoading ? { MenuList: PaddedLoadingAnimation } : undefined), [isLoading]);

    return (
        <FieldSelect
            options={options}
            loadOptions={updateSearchKey}
            loadingMessage={() => null} // hide loading message so that it doesn't appear right before the loading animation
            filterOption={showAllOptions} // override default filter function which would show only those methods whose label overlaps with the search string
            components={components}
            disableFilter={true}
            {...props}
        />
    );
};

export default MethodSelect;
