import PersonIcon from "@mui/icons-material/PersonRounded";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import { UserDto, zShortText } from "api-shared";
import { tz } from "moment-timezone";
import React, { createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../components/Alert";
import DangerButton from "../../components/DangerButton";
import DeleteDialog from "../../components/dialogues/DeleteDialog";
import Form from "../../components/Form";
import CompanySelect from "../../components/input/CompanySelect";
import CurrencySelect from "../../components/input/select/CurrencySelect";
import LanguageSelect from "../../components/input/select/LanguageSelect";
import LoadingButton from "../../components/LoadingButton";
import TimezoneSelect from "../../components/TimezoneSelect";
import UploadInput from "../../components/UploadInput";
import { useCurrencies } from "../../domain/currencies";
import { useDeleteUserAvatar, useUpdateUserProfile, useUploadUserAvatar } from "../../domain/users";
import { useLanguage } from "../../hooks/useLanguage";
import { baseUrl } from "../../lib/api";
import { isLanguage, Language, translationKeys } from "../../translations/main-translations";

const OverflowingCard = styled(Card)({
    // Allow selectbox to overflow outside the card
    overflow: "visible",
});

const Label = styled(FormLabel)(({ theme }) => ({
    marginBottom: theme.spacing(),
}));

const AvatarImage = styled("img")({
    maxWidth: "100%",
    maxHeight: "100%",
});

const DefaultAvatar = styled(PersonIcon)({
    width: "100%",
    height: 256,
});

const AvatarUploadInput = styled(UploadInput)({ height: "100%" });

interface AvatarDetails {
    selectedFile: File | null;
    hasValidFile: boolean;
}

interface ProfileUpdateProps {
    user: UserDto;
}

export default function ProfileUpdate(props: Readonly<ProfileUpdateProps>) {
    /*
     * State
     */
    const formRef = createRef<HTMLFormElement>();
    const { t: translate } = useTranslation();
    const appLanguage = useLanguage();
    const deleteAvatarMutation = useDeleteUserAvatar();
    // Use specific mutations for each part of the profile so that they are independent loading states
    const updateUserProfileMutation = useUpdateUserProfile();
    const updateProfilePictureMutation = useUploadUserAvatar();
    const updateUserLanguageAndRegionMutation = useUpdateUserProfile();
    const currencies = useCurrencies();

    const [avatarDeleteDialogOpen, setAvatarDeleteDialogOpen] = useState(false);
    const [avatarDetails, setAvatarDetails] = useState<AvatarDetails>({
        selectedFile: null,
        hasValidFile: false,
    });
    const [userDetails, setUserDetails] = useState<Record<string, string | undefined | null>>({
        realname: props.user.realname,
        displayname: props.user.displayname,
        telephoneNumber: props.user.telephoneNumber,
        mobileNumber: props.user.mobileNumber,
        department: props.user.department,
        position: props.user.position,
    });
    const [timezone, setTimezone] = useState<string | null | undefined>(() => props.user.timezone ?? tz.guess());
    const [currencyId, setCurrencyId] = useState<number | null>(props.user.currencyId);
    const [company, setCompany] = useState<number | null>(props.user.companyId);
    // Fall back to auto-configured language (guessed from browser settings) when user does not have a language configured yet
    const [language, setLanguage] = useState<Language>(isLanguage(props.user.language) ? props.user.language : appLanguage);

    const companyChanged = props.user.companyId !== company;

    const detailsChanged = Object.entries(userDetails).some(
        ([key, newValue]) => newValue !== (props.user as unknown as Record<string, string>)[key],
    );
    const dirtyUserDetails = companyChanged || detailsChanged;

    /*
     * "isDirty" check for language and timezone
     */

    const isLanguageDirty = language !== null && (props.user.language === null || !props.user.language.startsWith(language));
    const isCurrencyDirty = currencyId !== props.user.currencyId;
    const isTimezoneDirty = timezone !== props.user.timezone;
    const dirtyConfig = isLanguageDirty || isCurrencyDirty || isTimezoneDirty;

    const isValidUserFields = () => {
        return Object.values(userDetails).every((value) => value == null || zShortText.safeParse(value).success);
    };

    /*
     * Input callbacks
     */

    const openDeleteDialog = () => setAvatarDeleteDialogOpen(true);
    const closeDeleteDialog = () => setAvatarDeleteDialogOpen(false);

    const handleCompanyChange = (companyId: number | number[] | null) => {
        if (Array.isArray(companyId)) {
            return;
        }
        setCompany(companyId);
    };

    // to submit forms since IE doesn't submit forms if submit button is outside the form.
    const submitInfos = () => {
        const submittedForm = formRef.current;

        if (submittedForm === null || dirtyUserDetails === false || !isValidUserFields()) {
            return;
        }

        const body = {
            ...userDetails,
            ...(company != null && { companyId: company }),
        };

        updateUserProfileMutation.mutate(body, {
            onSuccess: () => {
                submittedForm.reset();
            },
        });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value },
        } = event;

        setUserDetails({
            ...userDetails,
            [name]: value,
        });
    };

    const selectedFileChanged = (selectedFile: File | null, hasValidFile: boolean) => setAvatarDetails({ selectedFile, hasValidFile });

    const triggerFileUpload = () => {
        const file = avatarDetails.selectedFile;

        if (file === null) {
            return;
        }

        const data = new FormData();
        data.append("file", file);
        updateProfilePictureMutation.mutate(data, { onSuccess: () => selectedFileChanged(null, false) });
    };

    const submitConfigs = () => {
        if (dirtyConfig === false) {
            return;
        }

        const newLanguage = language !== props.user.language ? language : null;
        const newCurrencyId = currencyId !== props.user.currencyId ? currencyId : null;
        const newTimezone = timezone !== props.user.timezone ? timezone : null;
        const body = {
            ...(newLanguage != null && { language: newLanguage }),
            ...(newCurrencyId != null && { currencyId: newCurrencyId }),
            ...(newTimezone != null && { timezone: newTimezone }),
        };

        if (Object.entries(body).length === 0) {
            return;
        }

        updateUserLanguageAndRegionMutation.mutate(body);
    };

    const handleLanguageChange = (language: Language | null) => language != null && setLanguage(language);
    const onTimezoneChanged = (tz: string | null) => tz != null && setTimezone(tz);

    const isScimUser =
        (props.user.scimExternalId !== null && props.user.scimExternalId.length > 0) ||
        (props.user.scimUserName !== null && props.user.scimUserName.length > 0);

    return (
        <Grid container spacing={1} justifyContent="center" data-testid="profile-update">
            <DeleteDialog
                item="avatar"
                translate={translate}
                open={avatarDeleteDialogOpen}
                onClose={closeDeleteDialog}
                onDelete={() => deleteAvatarMutation.mutate()} // argument types of onDelete and mutate do not match
            />
            <Grid item xs={12} md={7}>
                <OverflowingCard data-testid="user-details">
                    <CardHeader title={translate(translationKeys.VDLANG_UPDATE_PROFILE)} />
                    <CardContent>
                        {isScimUser ? <Alert severity="info">{translate(translationKeys.VDLANG_USER_PROFILE_SCIM_INFO)}</Alert> : null}
                        <Form ref={formRef} onSubmit={submitInfos}>
                            <FormGroup>
                                <FormControl margin="normal">
                                    <Label>{translate("email")}</Label>
                                    <Typography variant="body2">{props.user.email}</Typography>
                                </FormControl>
                                {!isScimUser ? (
                                    <>
                                        {Object.entries(userDetails).map(([propertyName, value]) => (
                                            <TextField
                                                key={propertyName}
                                                margin="normal"
                                                label={translate(propertyName)}
                                                value={value}
                                                onChange={handleInputChange}
                                                name={propertyName}
                                                error={value != null && !zShortText.safeParse(value).success}
                                            />
                                        ))}
                                    </>
                                ) : null}
                                <CompanySelect
                                    fullWidth
                                    label={translate(translationKeys.VDLANG_MY_PROFILE_COMPANY)}
                                    value={company}
                                    handleChange={handleCompanyChange}
                                    margin="normal"
                                />
                            </FormGroup>
                        </Form>
                    </CardContent>
                    <CardActions>
                        <LoadingButton
                            isLoading={updateUserProfileMutation.isLoading}
                            color="primary"
                            role="submit"
                            disabled={!dirtyUserDetails || !isValidUserFields()}
                            onClick={submitInfos}
                        >
                            {translate(translationKeys.VDLANG_SAVE)}
                        </LoadingButton>
                    </CardActions>
                </OverflowingCard>
            </Grid>
            <Grid item xs={12} md={7}>
                <OverflowingCard>
                    <CardHeader title={translate(translationKeys.VDLANG_UPDATE_PICTURE)} />
                    <CardContent>
                        <Grid container spacing={1} wrap="nowrap">
                            <Grid item xs={6}>
                                {props.user.imageHash ? (
                                    <AvatarImage alt="avatar" src={`${baseUrl}static/${props.user.imageHash}`} />
                                ) : (
                                    <DefaultAvatar color="action" />
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <AvatarUploadInput
                                    translate={translate}
                                    updateFile={selectedFileChanged}
                                    file={avatarDetails.selectedFile}
                                    onlyImages
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <LoadingButton
                            color="primary"
                            disabled={!avatarDetails.hasValidFile}
                            onClick={triggerFileUpload}
                            isLoading={updateProfilePictureMutation.isLoading}
                        >
                            {translate(translationKeys.VDLANG_UPLOAD)}
                        </LoadingButton>
                        <DangerButton
                            soft
                            disabled={!props.user.imageHash || updateProfilePictureMutation.isLoading}
                            onClick={openDeleteDialog}
                        >
                            {translate(translationKeys.VDLANG_DELETE_AVATAR)}
                        </DangerButton>
                    </CardActions>
                </OverflowingCard>
            </Grid>
            <Grid item xs={12} md={7} data-testrole="language-and-region">
                <OverflowingCard>
                    <CardHeader title={translate(translationKeys.VDLANG_SWITCH_LANGUAGE)} />
                    <CardContent>
                        <Form onSubmit={() => submitConfigs()}>
                            <LanguageSelect value={language} onChange={handleLanguageChange} margin="normal" translate={translate} />
                            <CurrencySelect
                                currencies={currencies}
                                value={currencyId}
                                onChange={setCurrencyId}
                                margin="normal"
                                label={translate(translationKeys.VDLANG_MY_PROFILE_CURRENCY)}
                            />
                            <TimezoneSelect value={timezone ?? null} onChange={onTimezoneChanged} />
                        </Form>
                    </CardContent>
                    <CardActions>
                        <LoadingButton
                            isLoading={updateUserLanguageAndRegionMutation.isLoading}
                            color="primary"
                            disabled={!dirtyConfig}
                            onClick={() => submitConfigs()}
                        >
                            {translate(translationKeys.VDLANG_SAVE)}
                        </LoadingButton>
                    </CardActions>
                </OverflowingCard>
            </Grid>
        </Grid>
    );
}
