import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import ValidatedInput, { IValidatedInputProps } from "./ValidatedInput";

const validateEmail = (value: unknown) => typeof value === "string" && isEmail(value, { allow_utf8_local_part: false });

const EmailInput = (validatedInputProps: IValidatedInputProps) => {
    const { t: translate } = useTranslation();
    return <ValidatedInput name="email" label={translate("email")} isValid={validateEmail} type="email" {...validatedInputProps} />;
};

export default EmailInput;
