import { UseMutateFunction, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UiStateDto } from "api-shared";
import { apiGet, apiPut } from "../lib/api";

const UI_STATE_PATH = "ui-state";

export const UiStateQueryKeys = {
    all: () => [UI_STATE_PATH] as const,
};

export const useUiStateQuery = (enabled = true) => {
    return useQuery({
        queryKey: UiStateQueryKeys.all(),
        queryFn: ({ signal, queryKey }) => apiGet<UiStateDto>(queryKey[0], { signal }),
        suspense: true,
        enabled,
        meta: {
            ignoreErrors: true,
        },
    });
};

export const useUpdateUiStateMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (state: Partial<UiStateDto>) => {
            const uiState = queryClient.getQueryData<UiStateDto>(UiStateQueryKeys.all());
            const newUiState = { ...uiState, ...state };
            return apiPut<UiStateDto>(UI_STATE_PATH, newUiState);
        },
        onMutate: async (state: Partial<UiStateDto>) => {
            await queryClient.cancelQueries(UiStateQueryKeys.all());
            const uiState = queryClient.getQueryData<UiStateDto>(UiStateQueryKeys.all());
            const newUiState = { ...uiState, ...state };
            queryClient.setQueryData(UiStateQueryKeys.all(), newUiState);
            return { previousState: uiState };
        },
        onError: (err, state, context) => {
            if (context !== undefined) {
                queryClient.setQueryData(UiStateQueryKeys.all(), context.previousState);
            }
        },
        onSettled: () => queryClient.invalidateQueries(UiStateQueryKeys.all()),
    });
};

// Custom return type based on enabled state of the query. If the query is disabled, we return null for the uiState.data!
export function useUiState<TEnabledBoolean extends boolean = true>(
    enabled?: TEnabledBoolean,
): [TEnabledBoolean extends false ? null : UiStateDto, UseMutateFunction<UiStateDto, unknown, Partial<UiStateDto>, unknown>];

export function useUiState(enabled = true): [UiStateDto | null, UseMutateFunction<UiStateDto, unknown, Partial<UiStateDto>, unknown>] {
    const uiState = useUiStateQuery(enabled);
    const updateUiState = useUpdateUiStateMutation();
    // The data from useUiStateQuery is guaranteed by loading the data early on application start (Suspense).
    // Therefore, the data is definitely avalable here! => "!" after uiState.data is fine.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return enabled ? [uiState.data!, updateUiState.mutate] : [null, updateUiState.mutate];
}
