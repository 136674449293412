import { styled } from "@mui/material";

export const CommentBubble = styled("div")(({ theme }) => ({
    flex: "1 1 auto",
    borderRadius: theme.shape.borderRadius,
    borderTopLeftRadius: 0,
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(1, 1, 1, 2),
    overflow: "hidden", // hint mention chips inside to shorten if not enough space
}));
