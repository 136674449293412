import DeleteIcon from "@mui/icons-material/DeleteRounded";
import { Alert, Divider, FormLabel, IconButton, List, styled, Typography } from "@mui/material";
import { UserDto } from "api-shared";
import { TFunction } from "i18next";
import React from "react";
import InfoIcon from "../../components/icons/InfoIcon";
import FilterableUserList from "./FilterableUserList";
import UserEntryWithPopup from "./UserEntryWithPopup";

const Root = styled("div")(({ theme }) => ({
    width: "100%",
    maxWidth: 370,
}));

const UserListContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(2, 3, 1),
}));

const StyledList = styled(List)({
    width: "100%",
});

const ActionContainer = styled("div")(({ theme }) => ({
    color: theme.palette.action.active,
    paddingRight: theme.spacing(2),
}));

const Label = styled(FormLabel)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
    margin: theme.spacing(2, 3, 0, 2),
}));

export interface ISelectUsersProps {
    available?: (user: UserDto) => boolean;
    selected: number[] | number | null;
    translate: TFunction;
    disabled?: boolean;
    disabledUsers?: number[];
    addUser: (id: number) => void;
    removeUser: (id: number) => void;
    users: UserDto[];
    selectedTitle?: React.ReactNode;
    labelChange: string;
    labelFill: string;
    disabledMessage?: string;
    disabledUserMessage?: string;
    fixed?: number[];
    fixedLabel?: string;
    hintText?: string;
    showSelectedUsers?: boolean;
    hideRemoveIcon?: boolean;
}

const SelectUsers = React.forwardRef<HTMLDivElement, ISelectUsersProps>(
    (
        {
            available,
            selected,
            translate,
            disabled,
            disabledUsers,
            hideRemoveIcon,
            addUser,
            removeUser,
            users,
            selectedTitle,
            labelChange,
            labelFill,
            disabledMessage,
            disabledUserMessage,
            fixed,
            fixedLabel,
            hintText,
            showSelectedUsers = true,
        },
        ref,
    ) => {
        let selectedUsers: number[] = [];
        if (Array.isArray(selected)) {
            selectedUsers = selected;
        } else if (selected != null) {
            selectedUsers = [selected];
        }

        const getAction = (userId: number) => {
            if (fixed?.includes(userId)) {
                return (
                    <ActionContainer>
                        <InfoIcon title={fixedLabel} />
                    </ActionContainer>
                );
            }
            return !disabled && !hideRemoveIcon ? (
                <IconButton onClick={() => removeUser(userId)} title={translate("Remove")}>
                    <DeleteIcon />
                </IconButton>
            ) : null;
        };

        const availableUsers = typeof available === "function" ? users.filter(available) : users;

        return (
            <Root ref={ref}>
                {showSelectedUsers && selectedUsers.length > 0 ? (
                    <>
                        <UserListContainer>
                            <Label>{selectedTitle}</Label>
                            <StyledList>
                                {selectedUsers.map((selectedUserId) => (
                                    <UserEntryWithPopup
                                        key={selectedUserId}
                                        user={users.find(({ id }) => id === selectedUserId)}
                                        disableGutters
                                        action={getAction(selectedUserId)}
                                    />
                                ))}
                            </StyledList>
                        </UserListContainer>
                        <Divider />
                    </>
                ) : null}

                {hintText ? <StyledAlert severity="info">{hintText}</StyledAlert> : null}

                <UserListContainer>
                    {!disabled ? (
                        <FilterableUserList
                            users={availableUsers}
                            disabledUsers={disabledUsers}
                            selectedUsers={selectedUsers}
                            translate={translate}
                            disabled={disabled}
                            disabledUserMessage={disabledUserMessage}
                            onSelect={addUser}
                            labelChange={labelChange}
                            labelFill={labelFill}
                        />
                    ) : (
                        <Typography>{disabledMessage}</Typography>
                    )}
                </UserListContainer>
            </Root>
        );
    },
);
export default SelectUsers;
