import { Button, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import React, { ErrorInfo, PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { reportError } from "../infrastructure/sentry";
import { LOCAL_STORAGE_NEW_VERSION_AVAILABLE_KEY } from "../lib/bootstrap";
import { Environment, environment, fetchConfig } from "../lib/environment";
import { isIgnoredError } from "../lib/error";
import { translationKeys } from "../translations/main-translations";
import StandaloneCard from "./StandaloneCard";

interface IErrorBoundaryProps extends WithTranslation {
    children: React.ReactNode;
}

interface IErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends PureComponent<IErrorBoundaryProps, IErrorBoundaryState> {
    state = {
        hasError: false,
    };

    componentDidMount() {
        window.addEventListener("error", this.handleErrorEvent);
        window.addEventListener("unhandledrejection", this.handleErrorEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("error", this.handleErrorEvent);
        window.removeEventListener("unhandledrejection", this.handleErrorEvent);
    }

    // Catches only errors inside of react lifecycle and render methods
    // As of now, this catching react errors is only available on class components, but not on functional components
    componentDidCatch = (error: Error, info: ErrorInfo) => this.handleError(error, { errorInfo: JSON.stringify(info) });

    // Catches error events on window object (unhandledrejection, error)
    handleErrorEvent = (error: ErrorEvent | PromiseRejectionEvent) => {
        if (isIgnoredError(error)) {
            return;
        }
        const moreInfo = JSON.stringify(error);
        const errorName = `${error.type} ${error instanceof ErrorEvent ? error.message : ""}`;
        // Stacktrace is the same for all errors reported here. therefore we add a custom fingerprint.
        this.handleError(new Error(errorName), { moreInfo }, [errorName]);
    };

    isNewVersionAvailable = async () => {
        try {
            const newConfig = await fetchConfig();
            const newEnvironment = Environment.from(newConfig);
            return newEnvironment.version !== environment.version;
        } catch (e) {
            // Be defensive here, any error means that we cannot be sure that a new version is available
            return false;
        }
    };

    handleError = async (error: Error, extras?: Record<string, unknown>, fingerprint?: string[]) => {
        // Refresh the page if the user fails to fetch any dynamically loaded chunks because of no internet connection.
        // Normally the user then will see the error boundary because of the missing chunk and the resulting error.
        // If the page is refreshed and the user is still offline the browser will show its offline page.
        if (window?.navigator?.onLine === false && /failed to fetch/i.test(error.message)) {
            this.refresh();
        }

        if (isIgnoredError(error)) {
            return;
        }
        const isNewVersionAvailable = await this.isNewVersionAvailable();
        if (isNewVersionAvailable) {
            localStorage.setItem(LOCAL_STORAGE_NEW_VERSION_AVAILABLE_KEY, "1");
            this.refresh();
        } else {
            this.setState({ hasError: true });
            reportError(error, {
                extras,
                fingerprint,
            });
        }
    };

    refresh = () => window.location.reload();

    // Do not use router here, so that a full page reload is triggered
    navigateToHome = () => window.location.assign("/");

    render() {
        const { children, t: translate } = this.props;
        return this.state.hasError ? (
            <StandaloneCard>
                <CardHeader title={translate(translationKeys.VDLANG_ERROR_PAGE_SOMETHING_WENT_WRONG)} />
                <CardContent>
                    <Typography>{translate(translationKeys.VDLANG_ERROR_PAGE_RECOMMENDATION)}</Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={this.refresh}>{translate(translationKeys.VDLANG_ERROR_PAGE_RELOAD_BUTTON)}</Button>
                    <Button onClick={this.navigateToHome}>{translate(translationKeys.VDLANG_ERROR_PAGE_START_PAGE)}</Button>
                </CardActions>
            </StandaloneCard>
        ) : (
            children
        );
    }
}

export default withTranslation()(ErrorBoundary);
