import { useQuery } from "@tanstack/react-query";
import { LatestViewedMeasuresDto } from "api-shared";
import { apiGet } from "../../lib/api";

const VIEWED_MEASURES_PATH = "measures/viewed";

export const ViewedMeasuresKeys = {
    all: [VIEWED_MEASURES_PATH] as const,
};

export const useLatestViewedMeasures = () => {
    return useQuery({
        queryKey: ViewedMeasuresKeys.all,
        queryFn: ({ signal }) => apiGet<LatestViewedMeasuresDto>(VIEWED_MEASURES_PATH, { signal }),
    });
};
