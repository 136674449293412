import React, { Suspense } from "react";
import { isActiveUser, useAllUsers } from "../../domain/users";
import { MarkdownEditorUserMentionsOwnProps } from "./MarkdownEditorWithoutMentions";

const MarkdownEditor = React.lazy(() => import("./MarkdownEditor"));

export default function MarkdownEditorUserMentionsAll(props: MarkdownEditorUserMentionsOwnProps) {
    const allUsers = useAllUsers();
    const mentionIds = allUsers.filter(isActiveUser).map(({ id }) => id);
    const mentionResolve = allUsers.map((sel) => sel.id);

    return (
        <Suspense>
            <MarkdownEditor {...props} mentionActiveUsers={mentionIds} mentionAllUsers={mentionResolve} />
        </Suspense>
    );
}
