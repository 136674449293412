import { Grid, styled } from "@mui/material";
import { ValueContainerProps } from "react-select";
import { Option } from "../types";

const Root = styled(Grid, { shouldForwardProp: (name) => name !== "isMulti" })<{ isMulti: boolean }>(({ theme, isMulti }) => ({
    flexGrow: 1,
    flexShrink: 1,
    overflow: "hidden",
    minWidth: 0,
    gap: theme.spacing(0.5), // vertical and horizontal spacing between chips
    // Make sure to keep height even if no DropdownIndicator or other icon on the right is visible
    // so that input paddings add up with correctly to the desired height
    minHeight: theme.spacing(2.5),
    flexWrap: "nowrap",
    ...(isMulti && {
        // For multi selects, the children will be chips, that have a slightly larger height than regular text (24px vs 20px)
        // Counter this by applying a small negative margin to the Grid, so that the original input paddings can still be used
        marginBottom: theme.spacing(-0.25),
        marginTop: theme.spacing(-0.25),
        minHeight: theme.spacing(3),
        flexWrap: "wrap",
    }),
}));

const SelectValueContainer = <OptionType extends Option, IsMulti extends boolean>({
    children,
    isMulti,
}: ValueContainerProps<OptionType, IsMulti>) => {
    return (
        <Root container alignItems="center" isMulti={isMulti}>
            {children}
        </Root>
    );
};

export default SelectValueContainer;
