import { Grid, styled, useTheme } from "@mui/material";

import { SubTaskDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, Cell, XAxis, YAxis } from "recharts";
import ResponsiveContainer from "../../components/ResponsiveContainer";
import SubtaskEffortChartLabel from "./SubtaskEffortChartLabel";

type Effort = Pick<SubTaskDto, "estimatedEffort" | "trackedTime">;

const RootGrid = styled(Grid, { shouldForwardProp: (name) => name !== "disabled" })<{ disabled?: boolean }>(({ theme, disabled }) => ({
    ...(disabled && { opacity: theme.palette.action.disabledOpacity }),
}));

interface ISubtaskEffortChartProps {
    activity: Effort;
    // dense mode for ready only use cases => smaller chart and labels, no pointer cursor
    dense?: boolean;
    disabled?: boolean;
}

export enum EffortStatus {
    EMPTY = "empty",
    ESTIMATED = "estimated",
    ACTUAL = "actual",
    ESTIMATED_AND_ACTUAL = "estimated_actual",
    ESTIMATED_AND_ACTUAL_OVERBOOKED = "estimated_actual_overbooked",
}

export interface IBarData {
    estimatedEffort: number;
    overbookedTime: number;
    trackedTime: number;
    empty: number;
}

const leftCornerRounded: [number, number, number, number] = [4, 0, 0, 4];
const rightCornerRounded: [number, number, number, number] = [0, 4, 4, 0];

const getBarData = (activity: Effort): { status: EffortStatus; data: IBarData } => {
    const effort = activity.estimatedEffort ?? 0;
    const time = activity.trackedTime ?? 0;

    // no estimation and no tracked time yet
    if (effort === 0 && time === 0) {
        return { status: EffortStatus.EMPTY, data: { estimatedEffort: 0, trackedTime: 0, overbookedTime: 0, empty: 1 } };
    }

    // estimated and tracked
    if (effort > 0 && time > 0) {
        if (effort > time) {
            // calculate effort and time bars
            return {
                status: EffortStatus.ESTIMATED_AND_ACTUAL,
                data: {
                    estimatedEffort: effort - time,
                    trackedTime: time,
                    overbookedTime: 0,
                    empty: 0,
                },
            };
        } else {
            // calculate overbooked and time bars
            return {
                status: EffortStatus.ESTIMATED_AND_ACTUAL_OVERBOOKED,
                data: {
                    trackedTime: effort,
                    overbookedTime: time - effort,
                    estimatedEffort: 0,
                    empty: 0,
                },
            };
        }
    }

    return {
        status: effort > 0 ? EffortStatus.ESTIMATED : EffortStatus.ACTUAL,
        data: { estimatedEffort: effort, trackedTime: time, overbookedTime: 0, empty: 0 },
    };
};

const SubtaskEffortChart = ({ activity, dense = false, disabled }: ISubtaskEffortChartProps) => {
    const theme = useTheme();
    const { data, status } = getBarData(activity);
    const { t: translate } = useTranslation();
    return (
        <RootGrid container alignItems="center" justifyContent="center" disabled={disabled}>
            <Grid item xs={12}>
                <ResponsiveContainer height={dense ? 20 : 26}>
                    <BarChart
                        margin={{ top: 5, left: 1, right: 1, bottom: 5 }}
                        data={[data]}
                        layout="vertical"
                        stackOffset="expand"
                        style={{ cursor: dense ? "initial" : "pointer" }}
                    >
                        <XAxis hide type="number" />
                        <YAxis hide type="category" dataKey="name" />
                        <Bar
                            key="trackedTime"
                            dataKey="trackedTime"
                            stackId="0"
                            fill={theme.palette.primary.main}
                            fillOpacity="0.08"
                            radius={
                                status === EffortStatus.ACTUAL || activity.estimatedEffort === activity.trackedTime ? 4 : leftCornerRounded
                            }
                        >
                            <Cell stroke={theme.palette.primary.main} strokeWidth={1} />
                        </Bar>
                        <Bar
                            key="overbookedTime"
                            dataKey="overbookedTime"
                            stackId="0"
                            fill={theme.palette.secondary.main}
                            fillOpacity="0.08"
                            radius={rightCornerRounded}
                        >
                            <Cell stroke={theme.palette.secondary.main} strokeWidth={1} />
                        </Bar>
                        <Bar
                            key="estimatedEffort"
                            dataKey="estimatedEffort"
                            stackId="0"
                            fill={theme.palette.grey[100]}
                            radius={status === EffortStatus.ESTIMATED ? 4 : rightCornerRounded}
                        >
                            <Cell stroke={theme.palette.grey[400]} strokeWidth={1} />
                        </Bar>
                        <Bar key="empty" dataKey="empty" stackId="0" fill={theme.palette.common.white} radius={4} legendType="none">
                            <Cell stroke={theme.palette.grey[400]} strokeWidth={1} />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
            <SubtaskEffortChartLabel
                dense
                estimatedEffort={activity.estimatedEffort ?? 0}
                trackedTime={data.trackedTime}
                overbookedTime={data.overbookedTime}
                empty={data.empty}
                status={status}
                translate={translate}
            />
        </RootGrid>
    );
};

export default SubtaskEffortChart;
