import { TextField } from "@mui/material";

import { MAX_INTEGER_DIGITS, validateRationalNumber } from "api-shared";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useLanguage } from "../../hooks/useLanguage";
import useRationalNumber from "../../hooks/useRationalNumber";
import { isValidCurrencyInput } from "../../lib/currency";
import { isEnterEvent } from "../../lib/keybindings";
import { translationKeys } from "../../translations/main-translations";

function getHint(
    translate: TFunction,
    { hasInvalidCharacter, hasInvalidCurrencyValue }: Record<string, boolean>,
    maxDigits?: number,
): string | null {
    if (hasInvalidCharacter) {
        return translate(translationKeys.VDLANG_RATIONAL_INPUT_INVALID_CHARACTERS);
    }

    if (hasInvalidCurrencyValue && maxDigits !== undefined) {
        return translate(translationKeys.VDLANG_RATIONAL_INPUT_DIGIT_LIMIT, {
            maxIntegerDigits: MAX_INTEGER_DIGITS,
            maxFractionalDigits: maxDigits,
        });
    }
    if (hasInvalidCurrencyValue) {
        return translate(translationKeys.VDLANG_RATIONAL_INPUT_OTHER_ERROR, { maxIntegerDigits: MAX_INTEGER_DIGITS });
    }
    return null;
}

interface FormattedInputProps {
    value: string | number | null;
    maxDigits?: number;
    disabled?: boolean;
    updateValue: (newValue: number | string | null, forceUpdate?: boolean) => void;
    translate: TFunction;
    fullWidth?: boolean;
}

const FormattedNumberInput = ({ value, maxDigits, disabled, updateValue, translate, fullWidth = true }: FormattedInputProps) => {
    const locale = useLanguage();
    const [input, setInput] = useState<string | null>(null);
    const [displayValue, setDisplayValue] = useState<string | null>(input ?? value?.toString() ?? null);

    const [hasInvalidCharacter, setHasInvalidCharacter] = useState(false);
    const [hasInvalidValue, setHasInvalidValue] = useState(false);

    const { formatRationalNumber, formatRationalNumberInput, sanitizeRationalNumberInput } = useRationalNumber({ language: locale });
    const originalInput = value ? value.toString() : "";

    function onFocus() {
        if (input === null) {
            const formattedOriginalInput = formatRationalNumberInput(originalInput);
            setInput(formattedOriginalInput);
        }
    }

    function onBlur() {
        // if input is null here, neither onFocus nor onChange have been fired so nothing to do
        if (input === null) {
            return;
        }

        // Use the standard format that is saved to the database
        const sanitizedInput = sanitizeRationalNumberInput(input);

        // Check if sanitizing the input produced valid values

        const isValid = sanitizedInput === "" || validateRationalNumber(sanitizedInput, maxDigits);

        // Show an error when the currency values are too large
        if (!isValid) {
            setHasInvalidValue(true);
            return;
        }

        const hasChanges = originalInput !== sanitizedInput;
        // Only send an update request when there are changes
        if (hasChanges) {
            const value = sanitizedInput !== "" ? Number(sanitizedInput) : null;
            updateValue(value, true);
            setDisplayValue(String(value));
        }
        setInput(null);

        // At this point everything is valid and the error hints can be removed
        setHasInvalidCharacter(false);
        setHasInvalidValue(false);
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const input = e.target.value;

        // Check if the input characters are valid on each keystroke
        if (isValidCurrencyInput(input)) {
            setInput(input);
            setHasInvalidCharacter(false);
        } else {
            setHasInvalidCharacter(true);
        }
    }

    function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (isEnterEvent(event)) {
            onBlur();
        }
    }

    let currentTextFieldValue = input;
    if (currentTextFieldValue == null && displayValue != null) {
        currentTextFieldValue = formatRationalNumber(Number(displayValue), { numberOfFractionalDigits: maxDigits });
    }

    const helperText = getHint(translate, { hasInvalidCharacter, hasInvalidCurrencyValue: hasInvalidValue }, maxDigits);

    return (
        <TextField
            value={currentTextFieldValue ?? ""}
            helperText={helperText ?? ""}
            disabled={disabled}
            error={helperText !== null}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            fullWidth={fullWidth}
        />
    );
};

export default FormattedNumberInput;
