import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { Button, Collapse, ListItemAvatar, ListItemButton, ListItemText, styled, Typography } from "@mui/material";
import { AclNamespaces, AclPermissions, UserDto } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import { Link, Location } from "react-router-dom";
import UserAvatar from "../../components/user/UserAvatar";
import UserName from "../../components/user/UserName";
import { useUserHasAccessPermissionQuery, useUserHasPermissionQuery } from "../../domain/permissions";
import { useLanguage } from "../../hooks/useLanguage";
import { getUserMenuNavItems, RouteFor } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";
import MobileNavButton from "./MobileNavButton";
import MobileNavLinkButton from "./MobileNavLinkButton";
import useUserLogout from "./useUserLogout";

const ExpandIcon = styled(ArrowDropDownRoundedIcon, { shouldForwardProp: (name) => name !== "isOpen" })<{ isOpen: boolean }>(
    ({ theme, isOpen }) => ({
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shorter,
        }),
        ...(isOpen && { transform: "rotate(180deg)" }),
    }),
);

interface IMobileNavbarUserMenuProps {
    user: UserDto | null;
    location: Location;
    translate: TFunction;
    onSelect?: () => void;
}

const MobileNavbarUserMenu = ({ user, location, translate, onSelect }: IMobileNavbarUserMenuProps) => {
    const [open, setOpen] = useState(false);
    const toggleMenu = () => setOpen(!open);
    const language = useLanguage();

    const logout = useUserLogout();

    const hasSuperAdminAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.SuperAdmin);
    const hasAdminAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.Admin);
    const hasExcelImportPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.Api,
        permission: AclPermissions.Import,
        fact: {},
    });

    if (
        !hasSuperAdminAccessPermissionQuery.isSuccess ||
        !hasAdminAccessPermissionQuery.isSuccess ||
        !hasExcelImportPermissionQuery.isSuccess
    ) {
        return null;
    }

    if (user == null) {
        return (
            <Button variant="contained" color="primary" component={Link} to={RouteFor.user.login}>
                {translate("Login")}
            </Button>
        );
    }

    const menuItems = getUserMenuNavItems(
        language,
        hasSuperAdminAccessPermissionQuery.data.hasPermission,
        hasAdminAccessPermissionQuery.data.hasPermission,
        hasExcelImportPermissionQuery.data.hasPermission,
    ).filter((item) => item.showMobile);

    return (
        <>
            <ListItemButton disableGutters onClick={toggleMenu}>
                <ListItemAvatar>
                    <UserAvatar user={user} />
                </ListItemAvatar>
                <ListItemText disableTypography>
                    <Typography fontWeight="medium" noWrap display="block" variant="body1">
                        <UserName user={user} translate={translate} />
                    </Typography>
                </ListItemText>
                <ExpandIcon isOpen={open} />
            </ListItemButton>
            <Collapse in={open}>
                <div>
                    {menuItems.map((item) => (
                        <MobileNavLinkButton
                            key={item.route}
                            fullWidth
                            sx={{ px: 0 }}
                            to={item.route}
                            target={item.external ? "_blank" : undefined}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{
                                    variant: "body1",
                                    color: "inherit",
                                }}
                            >
                                {translate(item.label)}
                            </ListItemText>
                        </MobileNavLinkButton>
                    ))}
                    <MobileNavButton fullWidth sx={{ px: 0 }} onClick={logout}>
                        <ListItemText
                            inset
                            primaryTypographyProps={{
                                variant: "body1",
                                color: "inherit",
                            }}
                        >
                            {translate(translationKeys.VDLANG_SECTIONS_LOGOUT)}
                        </ListItemText>
                    </MobileNavButton>
                </div>
            </Collapse>
        </>
    );
};

export default MobileNavbarUserMenu;
