import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { OnChangeValue } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { translationKeys } from "../../../translations/main-translations";
import { useSelectComponents } from "./components";
import { useAsyncSelectOptions, useSelectStyles } from "./hooks";
import { ISelectProps, MultiValueHandler, Option, SingleValueHandler, ValueHandler } from "./types";

export function createSelectHandler<T, IsMulti extends boolean = false>(
    onChange: ValueHandler<T, IsMulti>,
): (v: OnChangeValue<Option<T>, IsMulti>) => void {
    return (newOption: OnChangeValue<Option<T>, IsMulti>) => {
        if (Array.isArray(newOption)) {
            // Type safety is guaranteed by generics that cannot be asserted at runtime
            const newOptions = newOption as ReadonlyArray<Option<T>>;
            const handler = onChange as MultiValueHandler<T>;

            const newValues = newOptions.map((o) => o.value);
            handler(newValues);
        } else {
            // Type safety is guaranteed by generics that cannot be asserted at runtime
            const newOp = newOption as Option<T> | null;
            const handler = onChange as SingleValueHandler<T>;

            handler(newOp?.value ?? null);
        }
    };
}

// IsMulti should be false, when not set, to resemble the majority of cases where the select is a single select
// In case the isMulti prop is not known at compile time, it should resolve to "boolean", so typings cover both cases
const RegularSelect = <OptionType extends Option, IsMulti extends boolean>(props: ISelectProps<OptionType, IsMulti>) => {
    return <AsyncSelect {...props} />;
};

// Mark the "Create XYZ" option with a flag, so that the Option component can render it with an "add" icon
const makeCreatableOption = (value: string, label: React.ReactNode) => ({ value, label, isNew: true }) as Option;

const CreatableSelect = <OptionType extends Option, IsMulti extends boolean>(props: ISelectProps<OptionType, IsMulti>) => {
    const { t: translate } = useTranslation();

    const formatCreateLabel = useCallback(
        (input: string) => translate(translationKeys.VDLANG_SELECT_CREATE_OPTION, { input }),
        [translate],
    );

    const getNewOptionData = useCallback((value: string, label: React.ReactNode) => makeCreatableOption(value, label) as OptionType, []);

    return <AsyncCreatableSelect getNewOptionData={getNewOptionData} formatCreateLabel={formatCreateLabel} {...props} />;
};

const Select = <OptionType extends Option, IsMulti extends boolean = false>(props: ISelectProps<OptionType, IsMulti>) => {
    const { components, options, disableFilter, onCreateOption, ...other } = props;

    const selectStyles = useSelectStyles(props);
    const selectComponents = useSelectComponents(props);
    const asyncSelectOptionsProps = useAsyncSelectOptions(props);

    // Both components need to be pulled out because there will be typing issues otherwise
    const SelectComponent = onCreateOption === undefined ? RegularSelect : CreatableSelect;

    return (
        <SelectComponent
            styles={selectStyles}
            components={selectComponents}
            isSearchable={false}
            isClearable={false}
            hideSelectedOptions={false}
            closeMenuOnSelect={!props.isMulti}
            menuPlacement="auto"
            onCreateOption={onCreateOption}
            {...asyncSelectOptionsProps}
            {...other}
        />
    );
};

export default Select;
