import { useQuery } from "@tanstack/react-query";
import { SearchResultDto, SearchResultListDto, SearchResultMatch } from "api-shared";
import queryString from "query-string";
import { matomoTrackSiteSearch } from "../infrastructure/tracking";
import { apiGet } from "../lib/api";
import { RouteFor } from "../lib/routes";

export type SearchResult = {
    id: number;
    displayId: number;
    type: "measure" | "idea";
    title: string | React.ReactNode[];
    isActive: boolean;
    href: string;
    matches?: SearchResultMatch[];
};

export const convertSearchResult = ({ item: { id, displayId, type, title, isActive }, matches }: SearchResultDto): SearchResult => ({
    id,
    displayId,
    type,
    title,
    isActive,
    href: type === "measure" ? RouteFor.measure.forId(id) : RouteFor.opportunities.forId(id),
    matches,
});

export const DEFAULT_SEARCH_DELAY = 500;

const SearchQueryKeys = {
    forKey: (key: string) => ["search", key] as const,
};

export function useGlobalSearch(searchKey: string, enabled = true) {
    return useQuery({
        queryKey: SearchQueryKeys.forKey(searchKey),
        queryFn: async ({ queryKey, signal }) => {
            const url = `search?${queryString.stringify({ query: queryKey[1] })}`;
            const dtos = await apiGet<SearchResultListDto>(url, { signal });
            return dtos.map(convertSearchResult);
        },
        onSuccess: (searchResult) => {
            matomoTrackSiteSearch(searchKey, "global", searchResult.length);
        },
        enabled,
    });
}
