import { ListSubheader, styled } from "@mui/material";
import { Sort, SubTaskDto, SubTaskGroupBy, UserDto } from "api-shared";
import { TFunction } from "i18next";
import SubtaskListDateGroupHeader from "./SubtaskListDateGroupHeader";
import SubtaskListMeasureGroupHeader from "./SubtaskListMeasureGroupHeader";
import SubtaskListPriorityGroupHeader from "./SubtaskListPriorityGroupHeader";
import SubtaskListUserGroupHeader from "./SubtaskListUserGroupHeader";

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    ...theme.typography.body1,
    padding: theme.spacing(3, 4, 2),
    color: theme.palette.text.primary,
}));

interface ISubtaskListGroupHeaderProps {
    groupBy: SubTaskGroupBy;
    sort: Sort;
    sampleTask: SubTaskDto;
    index: number;
    translate: TFunction;
    users: UserDto[];
}

const SubtaskListGroupHeader = ({ groupBy, sort, sampleTask, index, translate, users }: ISubtaskListGroupHeaderProps) => {
    if (sampleTask == null) {
        return null;
    }

    let header = null;
    switch (groupBy) {
        case SubTaskGroupBy.MEASURE:
            header = (
                <SubtaskListMeasureGroupHeader
                    measure={sampleTask.measure}
                    effect={sampleTask.effect}
                    effectivenessStart={sampleTask.effectivenessStart != null ? new Date(sampleTask.effectivenessStart) : undefined}
                />
            );
            break;
        case SubTaskGroupBy.DUEDATE:
            header = <SubtaskListDateGroupHeader sort={sort} index={index} translate={translate} />;
            break;
        case SubTaskGroupBy.ASSIGNED_TO:
            header = <SubtaskListUserGroupHeader translate={translate} users={users} userId={sampleTask.assignedToId} />;
            break;
        case SubTaskGroupBy.CREATED_BY:
            header = <SubtaskListUserGroupHeader translate={translate} users={users} userId={sampleTask.createdById} />;
            break;
        case SubTaskGroupBy.PRIORITY:
            header = <SubtaskListPriorityGroupHeader translate={translate} priority={sampleTask.priority} />;
            break;
        default:
    }

    return header != null ? <StyledListSubheader disableSticky>{header}</StyledListSubheader> : null;
};

export default SubtaskListGroupHeader;
