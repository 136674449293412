import { Button, ButtonProps, CircularProgress } from "@mui/material";

interface ILoadingButtonProps extends ButtonProps {
    isLoading: boolean;
}

const LoadingButton = ({ isLoading, disabled, fullWidth, children, size, ...buttonProps }: ILoadingButtonProps) => {
    const loadingSizes: Record<NonNullable<ButtonProps["size"]>, number> = {
        small: 16,
        medium: 20,
        large: 26,
    };
    const loadingAnimation = <CircularProgress color="inherit" size={loadingSizes[size ?? "medium"]} />;
    return (
        <Button
            endIcon={!fullWidth && isLoading ? loadingAnimation : null}
            disabled={isLoading || disabled}
            fullWidth={fullWidth}
            size={size}
            {...buttonProps}
        >
            {fullWidth && isLoading ? loadingAnimation : children}
        </Button>
    );
};

export default LoadingButton;
