import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BasicMessageDto, ErrorConstantKeys, LoginDto, LoginTwoFactorAuthenticationVerifyDto, LoginTypeDto, OtpauthDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../infrastructure/notifications";
import { apiPost } from "../lib/api";
import { NotificationType } from "../lib/notifications";
import { UsersQueryKeys } from "./users";

const LOGIN_PROVIDER_PATH = "authentication/provider";
const TWOFACTOR_TOTP_PATH = "authentication/2fa/totp/generate";
const TWOFACTOR_VERIFY_PATH = "authentication/2fa/totp/verify";

interface UseLoginProviderOptions {
    email: string;
    onError?: () => void;
    onSuccess?: (response: LoginTypeDto) => void;
    enabled?: boolean;
    meta?: { ignoreErrors: boolean };
}

export const useLoginProvider = ({ email, onError, onSuccess, enabled, meta }: UseLoginProviderOptions) => {
    return useQuery({
        queryKey: [LOGIN_PROVIDER_PATH, email] as const,
        queryFn: ({ queryKey }) => apiPost<LoginTypeDto>(LOGIN_PROVIDER_PATH, { email: queryKey[1] }),
        onSuccess,
        onError,
        enabled,
        meta,
    });
};

export const useTwoFactorAuthenticationTotpSetup = () => {
    return useMutation({
        mutationFn: (data: LoginDto) => apiPost<OtpauthDto>(TWOFACTOR_TOTP_PATH, data),
    });
};

export const useTwoFactorAuthenticationVerifySetup = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: LoginTwoFactorAuthenticationVerifyDto) => apiPost<BasicMessageDto>(TWOFACTOR_VERIFY_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries(UsersQueryKeys.current());
            dispatch(showNotificationEvent(NotificationType.SUCCESS, "success"));
        },
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_INCORRECT_TFA_TOKEN,
        },
    });
};
