import { styled, Typography } from "@mui/material";
import { RouteFor } from "../../lib/routes";
import Link from "../Link";
import MeasureIdChip from "../MeasureIdChip";
import Tooltip from "../Tooltip";

// From the W3 Specs: The 'text-decoration' property on descendant elements cannot have any effect on the decoration of the ancestor.
// (https://www.w3.org/TR/CSS21/text.html#lining-striking-props)
// Requirement: The chip should not be underlined on hover of the link element. "text-decoration: none" does not work, so reverse the logic:
// No hover text-decoration by default and add text-decoration for text on link hover

const titleClass = "MeasureLink-title";

const LinkWithTitleHover = styled(Link)(({ theme }) => ({
    overflowWrap: "anywhere",
    display: "flex",
    alignItems: "flex-start",
    color: theme.palette.text.primary,
    [`&:hover .${titleClass}`]: {
        textDecoration: "underline",
    },
}));

const MeasureChip = styled(MeasureIdChip)(({ theme }) => ({
    marginRight: theme.spacing(),
    marginTop: theme.spacing(0.25),
    "&:hover": {
        cursor: "pointer",
    },
}));

interface IMeasureTitleProps {
    measureId: number;
    title: string;
    clientIid: number;
    className?: string;
}

const MeasureLink = ({ measureId, title, clientIid, className }: IMeasureTitleProps) => (
    <Tooltip title={title}>
        <Typography component="div" className={className}>
            <LinkWithTitleHover underline="none" to={RouteFor.measure.forId(measureId)}>
                <MeasureChip measureId={clientIid} />
                <span className={titleClass}>{title}</span>
            </LinkWithTitleHover>
        </Typography>
    </Tooltip>
);

export default MeasureLink;
