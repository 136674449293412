import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { styled } from "@mui/material";
import { ClearIndicatorProps, components } from "react-select";
import { Option } from "../types";

const ClearIndicatorIcon = styled(ClearRoundedIcon)({
    fontSize: "1rem",
});

const SelectClearIndicator = <OptionType extends Option, IsMulti extends boolean>(props: ClearIndicatorProps<OptionType, IsMulti>) => (
    <components.ClearIndicator {...props}>
        <ClearIndicatorIcon color="action" />
    </components.ClearIndicator>
);

export default SelectClearIndicator;
