import ArrowForwardRounded from "@mui/icons-material/ArrowForwardRounded";
import { Chip, Stack, chipClasses, styled } from "@mui/material";
import IdeaIdChip, { IIdeaIdChipProps } from "../view/ideas/IdeaIdChip";
import MeasureIdChip from "./MeasureIdChip";

const WrapperChip = styled(Chip)(({ theme }) => ({
    borderColor: theme.palette.divider,
    height: "min-content",
    padding: theme.spacing(0.25, 0.5),
    [`& > .${chipClasses.label}`]: {
        padding: 0,
    },
}));

const LabeledChip = styled(Chip, { shouldForwardProp: (prop) => prop !== "dense" })<{ dense?: boolean }>(({ theme, dense }) => ({
    backgroundColor: theme.palette.background.paper,
    maxWidth: "100%",
    height: "fit-content",

    [`& > .${chipClasses.label}`]: {
        padding: theme.spacing(0, dense ? 0 : 0.5),
        color: theme.palette.text.secondary,
        ...theme.typography.caption,
        lineHeight: theme.spacing(2.25),
    },
}));

const ArrowIcon = styled(ArrowForwardRounded)(({ theme }) => ({
    color: theme.palette.text.secondary,
    height: theme.spacing(2),
}));

type MeasureBreadcrumbProps = {
    label?: string;
    measureDisplayId: number;
    ideaDisplayId?: number;
    ideaChipProps?: Partial<IIdeaIdChipProps>;
    ideaChipTestId?: string;
    dense?: boolean;
};

export const MeasureBreadcrumb = ({
    label,
    measureDisplayId,
    ideaDisplayId,
    ideaChipProps = {},
    ideaChipTestId,
    dense,
}: MeasureBreadcrumbProps) => {
    const displayIdChips = (
        <Stack direction="row" alignItems="center" spacing={dense ? 0 : 0.5} useFlexGap>
            {ideaDisplayId != null ? (
                <>
                    <IdeaIdChip ideaId={ideaDisplayId} data-testid={ideaChipTestId} {...ideaChipProps} />
                    <ArrowIcon />
                </>
            ) : null}
            <LabeledChip
                avatar={
                    // Wrap with fragment to disable styles that would normally be applied by LabeledChip on its
                    // avatar
                    <>
                        <MeasureIdChip measureId={measureDisplayId} size="small" />
                    </>
                }
                label={label}
                size="small"
                dense={dense}
            />
        </Stack>
    );

    if (label === undefined) {
        return displayIdChips;
    }

    return <WrapperChip variant="outlined" label={displayIdChips} />;
};
