import { LAST_LOGIN_PROVIDER_EMAIL_STORAGE_KEY, LOGIN_REFERRER_KEY } from "api-shared";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { signOutEvent } from "../../lib/authentication-saga";

const useUserLogout = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        // If someone logs out manually he really wants to get rid of the last login
        localStorage.removeItem(LAST_LOGIN_PROVIDER_EMAIL_STORAGE_KEY);
        // Users also don't want to go back to any older page especially on client change
        localStorage.removeItem(LOGIN_REFERRER_KEY);
        dispatch(signOutEvent());
    }, [dispatch]);
};

export default useUserLogout;
