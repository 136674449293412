import { useInfiniteQuery } from "@tanstack/react-query";
import { CommentStreamDto, CommentStreamRequestQuery } from "api-shared";
import { apiGet } from "../lib/api";

const COMMENT_STREAM_PATH = "/comment-stream";

export const CommentStreamKeys = {
    all: (params: CommentStreamRequestQuery = {}) => [COMMENT_STREAM_PATH, "all", params] as const,
};

export const useCommentStream = (queryParams: CommentStreamRequestQuery, enabled = true) => {
    return useInfiniteQuery({
        enabled,
        queryKey: CommentStreamKeys.all(queryParams),
        queryFn: async ({ queryKey, signal, pageParam }) => {
            const params = new URLSearchParams();
            if (pageParam != null) {
                params.append("maxDate", pageParam);
            }
            queryKey[2].entities?.forEach((entity: string) => {
                params.append("entities[]", entity);
            });
            queryKey[2].commentedBy?.forEach((id) => {
                params.append("commentedBy[]", String(id));
            });
            queryKey[2].responsiblePerson?.forEach((id) => {
                params.append("responsiblePerson[]", String(id));
            });
            const url = params.size > 0 ? `${queryKey[0]}?${params.toString()}` : queryKey[0];
            return apiGet<CommentStreamDto>(url, { signal });
        },
        getNextPageParam: (lastPage) => {
            const lastItem = lastPage.items[lastPage.items.length - 1];
            return lastItem?.createdAt ?? null;
        },
    });
};
