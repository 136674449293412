import { zodResolver } from "@hookform/resolvers/zod";
import CheckCircleOutlineRounded from "@mui/icons-material/CheckCircleOutlineRounded";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Button, Link, Stack, Typography, styled } from "@mui/material";
import {
    AclNamespaces,
    CreateIdeaRequestBody,
    DiscardIdeaRequestBody,
    DiscardReasons,
    IdeaDto,
    IdeaStatus,
    SearchIdeaRequestBody,
    zCreateIdeaRequestBody,
} from "api-shared";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCreateIdeaMutation, useDiscardIdeaMutation, useIdeaAttributesQuery, useUpdateIdeaMutation } from "../../../src/domain/ideas";
import Alert from "../../components/Alert";
import BaseDialog, { IBaseDialogProps } from "../../components/dialogues/BaseDialog";
import DialogBackButton from "../../components/dialogues/DialogBackButton";
import DialogButton from "../../components/dialogues/DialogButton";
import { useUserHasAccessPermissionQuery } from "../../domain/permissions";
import { useAllUsers, useCurrentUserId } from "../../domain/users";
import { useLanguage } from "../../hooks/useLanguage";
import useTimezone from "../../hooks/useTimezone";
import { trackEvent } from "../../infrastructure/tracking";
import { formatUserFromId } from "../../lib/formatters";
import { replaceImage } from "../../lib/history";
import { replaceMentionUsers } from "../../lib/mention";
import { useIsDesktop } from "../../lib/mobile";
import { RouteFor } from "../../lib/routes";
import { Language, translationKeys } from "../../translations/main-translations";
import IdeaContextForm from "./IdeaContextForm";
import { useIdeaConversion } from "./hooks";

export interface IIdeaDialogProps extends Omit<IBaseDialogProps, "actions" | "classes" | "translate" | "title"> {
    open: boolean;
    onClose: () => void;
    measureCreatorId?: number;
    currentIdea: IdeaDto | null;
    searchQuery?: SearchIdeaRequestBody;
    isReadonly?: boolean;
    hideProcessButton?: boolean;
}

const SuccessIcon = styled(CheckCircleOutlineRounded)(({ theme }) => ({
    color: theme.palette.defaultGreen.main,
}));
const InfoIcon = styled(InfoOutlined)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
}));

const Banner = styled(Stack)(({ theme }) => ({
    backgroundColor: "rgba(33, 150, 243, 0.08)",
}));

const BannerText = styled(Typography)(({ theme }) => ({
    // custom color for banner - only used here for now
    color: theme.palette.primary.dark,
}));

const getDiscardReason = (discardReasonId: number, language: Language) => {
    const discardReason = DiscardReasons.find(({ id }) => id === discardReasonId);
    return discardReason === undefined ? "" : discardReason[language];
};

const IdeaDialog = ({
    onClose,
    open,
    measureCreatorId,
    currentIdea,
    searchQuery,
    hideProcessButton = false,
    isReadonly = false,
    ...other
}: IIdeaDialogProps) => {
    const { t: translate } = useTranslation();
    const { formatDate, formatTime } = useTimezone();
    const ideaAttributesQuery = useIdeaAttributesQuery();
    const createIdeaMutation = useCreateIdeaMutation(() => trackEvent({ category: "Opp", action: "Create Opp Space" }));
    const updateIdeaMutation = useUpdateIdeaMutation();
    const discardIdeaMutation = useDiscardIdeaMutation();
    const convertIdeaToMeasure = useIdeaConversion();
    const users = useAllUsers();
    const currentUserId = useCurrentUserId();
    const hasProcessAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.Process);

    const isNewIdea = currentIdea === null;
    const isDiscarded = currentIdea?.status === IdeaStatus.DISCARDED;
    const isConverted = currentIdea?.status === IdeaStatus.CONVERTED;
    const language = useLanguage();

    const userFormattingOptions = { translate: translate };

    const [ownerId, setOwnerId] = useState(currentIdea?.ownerId ?? currentUserId);

    const {
        handleSubmit,
        reset,
        control,
        getValues,
        formState: { errors, isValid, isDirty },
    } = useForm<CreateIdeaRequestBody>({
        mode: "onChange",
        resolver: zodResolver(zCreateIdeaRequestBody),
        defaultValues: currentIdea ?? undefined,
    });

    const hasUnsavedChanges = isDirty || isNewIdea || ownerId !== currentIdea?.ownerId;

    useEffect(() => {
        if (open) {
            reset(
                {
                    description: currentIdea?.description,
                    title: currentIdea?.title,
                    timeEstimate: currentIdea?.timeEstimate,
                    potentialEstimate: currentIdea?.potentialEstimate,
                    effortEstimate: currentIdea?.effortEstimate,
                    fields: currentIdea?.fields,
                },
                { keepDirtyValues: true, keepTouched: true, keepDirty: true, keepIsValid: true, keepErrors: true },
            );
        }
    }, [
        currentIdea?.description,
        currentIdea?.title,
        currentIdea?.potentialEstimate,
        currentIdea?.timeEstimate,
        currentIdea?.fields,
        currentIdea?.effortEstimate,
        open,
        reset,
    ]);

    const saveIdea = (values: CreateIdeaRequestBody, onSuccess?: (idea: IdeaDto) => void) => {
        const { effortEstimate = null, timeEstimate = null, potentialEstimate = null, ...data } = values;

        if (ownerId !== null) {
            if (!isNewIdea) {
                updateIdeaMutation.mutate(
                    { ideaId: currentIdea.id, potentialEstimate, timeEstimate, effortEstimate, ...data, ownerId },
                    { onSuccess: onSuccess },
                );
            } else {
                createIdeaMutation.mutate({ timeEstimate, potentialEstimate, effortEstimate, ...data, ownerId }, { onSuccess: onSuccess });
            }
        }
    };

    const onSubmit = handleSubmit((data) => {
        saveIdea(data);
        onClose();
    });

    const handleConvert = (userId: number, measureConfigId: number) => {
        if (hasUnsavedChanges) {
            // get form values manually because convert does not trigger idea form submit
            const data = getValues();
            saveIdea(data, (idea) => {
                convertIdeaToMeasure(idea, userId, measureConfigId);
            });
        } else {
            convertIdeaToMeasure(currentIdea, userId, measureConfigId);
        }
    };

    const handleDiscard = ({ discardReason, discardStatement }: DiscardIdeaRequestBody) => {
        if (currentIdea == null) {
            return;
        }
        discardIdeaMutation.mutate({ ideaId: currentIdea.id, discardReason, discardStatement });
    };

    const handleUpdateOwnerId = (newOwnerId: number) => {
        setOwnerId(newOwnerId);
    };

    const primaryText = isNewIdea
        ? translationKeys.VDLANG_IDEAS_ADD_IDEA_BUTTON_LABEL
        : translationKeys.VDLANG_IDEAS_SAVE_IDEA_BUTTON_LABEL;
    const primaryButton = (
        <DialogButton
            name={primaryText}
            key={primaryText}
            onClick={onSubmit}
            disabled={!isValid || !hasUnsavedChanges}
            variant="contained"
            onClose={onClose}
            translate={translate}
            role="submit"
        />
    );

    const secondaryButton = <DialogBackButton key="back" translate={translate} onClose={onClose as React.MouseEventHandler} />;

    const actions = [secondaryButton, primaryButton];

    const isDesktop = useIsDesktop();

    if (!hasProcessAccessPermissionQuery.isSuccess) {
        return null;
    }

    return (
        <BaseDialog open={open} onClose={onClose} fullScreen={!isDesktop} actions={actions} maxWidth="lg" disableContentPadding {...other}>
            {isConverted &&
                currentIdea?.convertedAt != null &&
                currentIdea.convertedById != null &&
                currentIdea.referencedMeasure != null && (
                    <Alert severity="success" variant="inline" icon={<SuccessIcon />} sx={{ py: 0 }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <span>
                                {translate(translationKeys.VDLANG_IDEAS_CONVERTED_FROM_INFO, {
                                    userName: formatUserFromId(currentIdea.convertedById, users, { ...userFormattingOptions }),
                                    date: formatDate(currentIdea.convertedAt),
                                    time: formatTime(currentIdea.convertedAt),
                                })}
                            </span>
                            {hasProcessAccessPermissionQuery.data.hasPermission && !hideProcessButton ? (
                                <Button
                                    variant="text"
                                    color="inherit"
                                    component={Link}
                                    href={RouteFor.measure.forId(currentIdea.referencedMeasure.id)}
                                >
                                    {translate(translationKeys.VDLANG_IDEAS_SHOW_MEASURE)}
                                </Button>
                            ) : null}
                        </Stack>
                    </Alert>
                )}
            {currentIdea != null && isDiscarded && currentIdea.discardedAt != null && currentIdea.discardedById != null && (
                <Banner direction="row" p={1.5} gap={2} alignItems="center">
                    <InfoIcon />
                    <Stack direction="column">
                        <BannerText variant="body2">
                            {translate(translationKeys.VDLANG_IDEAS_DISCARDED_BY_INFO, {
                                discarder: formatUserFromId(currentIdea.discardedById, users, { ...userFormattingOptions }),
                                date: formatDate(currentIdea.discardedAt),
                                time: formatTime(currentIdea.discardedAt),
                            })}
                        </BannerText>
                        <BannerText variant="body2">
                            {translate(translationKeys.VDLANG_IDEAS_DISCARDED_BY_INFO_REASON, {
                                reason: currentIdea.discardReason != null ? getDiscardReason(currentIdea.discardReason, language) : "",
                            })}
                        </BannerText>
                        <BannerText variant="body2">
                            {translate(translationKeys.VDLANG_IDEAS_DISCARDED_BY_INFO_STATEMENT, {
                                statement: replaceImage(replaceMentionUsers(users, currentIdea.discardStatement ?? "", translate)) ?? "",
                            })}
                        </BannerText>
                    </Stack>
                </Banner>
            )}
            <IdeaContextForm
                onSubmit={onSubmit}
                onConvert={handleConvert}
                measureCreatorId={measureCreatorId}
                control={control}
                errors={errors.title}
                translate={translate}
                idea={currentIdea ?? undefined}
                attributes={ideaAttributesQuery.data}
                hasUnsavedChanges={hasUnsavedChanges}
                ownerId={ownerId}
                updateOwnerId={handleUpdateOwnerId}
                onDiscard={handleDiscard}
                onClose={onClose}
                isReadonly={isReadonly || isDiscarded || isConverted}
                searchQuery={searchQuery}
            />
        </BaseDialog>
    );
};

export default React.memo(IdeaDialog);
