declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _hsq?: any[];
    }
}

enum HubspotMethod {
    Identify = "identify",
    SetPath = "setPath",
    TrackPageView = "trackPageView",
}

interface IHubspotIdentity {
    email: string;
}

export class HubspotTracker {
    private readonly hsq: any[];

    constructor() {
        this.hsq = window._hsq = window._hsq ?? [];
    }

    public setPath(path: string): void {
        this.hsq.push([HubspotMethod.SetPath, path]);
    }

    public trackPageView(): void {
        this.hsq.push([HubspotMethod.TrackPageView]);
    }

    public identify(identity: IHubspotIdentity): void {
        this.hsq.push([HubspotMethod.Identify, identity]);
    }

    public embedTrackingCode(hubId: string): HTMLScriptElement {
        // Avoid duplicates by removing existing tracking code
        this.removeTrackingCode();

        // Create and append the new tracking code
        const trackingCode = document.createElement("script");
        trackingCode.type = "text/javascript";
        trackingCode.id = "hs-script-loader";
        trackingCode.defer = true;
        trackingCode.async = true;
        trackingCode.src = `//js.hs-scripts.com/${hubId}.js`;
        return document.body.appendChild(trackingCode);
    }

    public removeTrackingCode(): void {
        const existingTrackingCode = document.getElementById("hs-script-loader") as HTMLScriptElement | null;

        if (existingTrackingCode !== null) {
            existingTrackingCode.remove();
        }
    }
}
