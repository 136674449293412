import { useState } from "react";

export const useProgress = () => {
    const [progress, setProgress] = useState(0);

    const updateProgress = (event: ProgressEvent) => setProgress(Math.round((event.loaded * 100) / event.total));

    const setOrUpdateProgress = (input: ProgressEvent | number) => {
        if (input instanceof ProgressEvent) {
            updateProgress(input);
        } else {
            setProgress(input);
        }
    };

    return [progress, setOrUpdateProgress] as const;
};
