import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { DiscardIdeaRequestBody, IdeaDto } from "api-shared";
import { useTranslation } from "react-i18next";
import MoreActionsMenu from "../../components/MoreActionsMenu";
import DiscardEntityDialog from "../../components/dialogues/DiscardEntityDialog";
import useDialog from "../../hooks/useDialog";
import { useLanguage } from "../../hooks/useLanguage";
import { useIsMobile } from "../../lib/mobile";
import { translationKeys } from "../../translations/main-translations";

interface IdeaContextMenuProps {
    idea?: IdeaDto;
    onDiscard: (discardInfo: DiscardIdeaRequestBody) => void;
    onConvert: () => void;
}

const IdeaContextMenu = ({ idea, onDiscard, onConvert }: IdeaContextMenuProps) => {
    const { t: translate } = useTranslation();
    const isMobile = useIsMobile();
    const language = useLanguage();

    const deleteIdeaDialog = useDialog();

    const handleContextMenuDiscardClick = () => {
        deleteIdeaDialog.open();
    };

    const handleDiscard = (reason: number, statement: string) => {
        onDiscard({ discardReason: reason, discardStatement: statement });
    };

    const mobileActions = idea
        ? [
              <MenuItem key="convertToMeasure" onClick={onConvert}>
                  <ListItemIcon>
                      <AddCircleIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={translate(translationKeys.VDLANG_IDEAS_CONVERT_IDEA)} />
              </MenuItem>,
          ]
        : [];

    const deleteAction = (
        <MenuItem key="discard" onClick={handleContextMenuDiscardClick}>
            <ListItemIcon>
                <DeleteIcon color="error" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translate(translationKeys.VDLANG_IDEAS_DISCARD_IDEA)} primaryTypographyProps={{ color: "error" }} />
        </MenuItem>
    );

    return (
        <>
            {idea && (
                <DiscardEntityDialog
                    open={deleteIdeaDialog.isOpen}
                    onClose={deleteIdeaDialog.close}
                    defaultReason={null}
                    defaultStatement={null}
                    onDiscard={handleDiscard}
                    translate={translate}
                    currentLanguage={language}
                    entityName={translationKeys.VDLANG_IDEAS_IDEA}
                />
            )}
            <MoreActionsMenu
                actions={[...(isMobile ? mobileActions : []), ...(idea ? [deleteAction] : [])]}
                buttonProps={{
                    size: "small",
                    style: {},
                }}
            />
        </>
    );
};

export default IdeaContextMenu;
