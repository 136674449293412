import { Stack, Typography, styled } from "@mui/material";
import UserAvatar from "../../../components/user/UserAvatar";

const UserEventContent = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    gap: theme.spacing(2),
    alignItems: "center",
}));

/*
 * This component is used to display an error message in the feed when a user is not available.
 * The dummy data is used to show the layout of the component. It's blurred and not clickable.
 */
const EmbeddedUserEventError = () => (
    <UserEventContent>
        <UserAvatar />
        <Typography variant="body2">Lorem ipsum dolor sit amet, consectetur adipisicing elit!</Typography>
    </UserEventContent>
);

export default EmbeddedUserEventError;
