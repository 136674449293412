import { Collapse, Grid, Link, styled, Typography } from "@mui/material";
import { effortConverter, SubTaskHistoryDto, UserDto } from "api-shared";
import { TFunction } from "i18next";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useState } from "react";
import { useGateTaskConfigs } from "../../domain/measure-config";
import { DateTimeFormatter } from "../../hooks/useTimezone";
import { formatUserFromId } from "../../lib/formatters";
import { markdownToSafeHtml } from "../../lib/markdown";
import { translationKeys } from "../../translations/main-translations";
import { useMeasureContext } from "../../view/MeasureContext";
import Tooltip from "../Tooltip";

// const DateContainer = styled("div")(({ theme }) => ({}))
const DateContainer = styled("div")(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

const Citation = styled("div")(({ theme }) => ({
    borderLeft: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(),
    color: theme.palette.text.primary,
}));

const DarkText = styled("span")(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const Grow = styled(Grid)(({ theme }) => ({
    flexGrow: 1,
    flexShrink: 1,
    width: 0, // fixes grow for IE/Safari
}));

const ParentGrid = styled(Grid, { shouldForwardProp: (name) => name !== "hasDivider" })<{ hasDivider: boolean }>(
    ({ theme, hasDivider }) => ({
        ...(hasDivider && {
            borderBottom: `1px solid ${theme.palette.divider}`,
        }),
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(),
    }),
);

const HistoryTypography = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.caption.fontSize,
    overflowWrap: "anywhere",
    color: theme.palette.text.secondary,
}));

interface IExtendedSubtaskHistoryItemDto extends SubTaskHistoryDto {
    user?: UserDto;
    date: Date;
}

interface ISubtaskHistoryItemProps {
    item: IExtendedSubtaskHistoryItemDto;
    formatDate: DateTimeFormatter;
    formatDateTime: DateTimeFormatter;
    translate: TFunction;
    users: UserDto[];
    hideDivider: boolean;
    formatTime: DateTimeFormatter;
}

const getTranslationKeyForHistoryEntryBase = ({ attribute }: IExtendedSubtaskHistoryItemDto) => {
    return `subtask_history.${attribute}`;
};
const getTranslationKeyForHistoryEntry = ({ type, attribute }: IExtendedSubtaskHistoryItemDto) => {
    return `subtask_history.${attribute}.${type}`;
};
const SubtaskHistoryItem = ({ item, formatDate, formatDateTime, translate, users, hideDivider, formatTime }: ISubtaskHistoryItemProps) => {
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapse = () => setCollapsed(!collapsed);
    const measure = useMeasureContext();
    const gateTaskConfigs = useGateTaskConfigs();
    function getGateTaskName(value: string) {
        const gateTask = measure.gateTasks.find(({ id }) => id === +value);
        const gateTaskConfig = gateTaskConfigs.find(({ id }) => id === gateTask?.gateTaskConfigId);
        return gateTaskConfig ? translate(gateTaskConfig.name) : value;
    }

    const getTimestampDetails = (value: string | null): { date?: string; time?: string } => {
        return value != null
            ? {
                  date: formatDate(moment.utc(value)),
                  time: formatTime(moment.utc(value)),
              }
            : {};
    };

    const formatValue = (attribute: string, value: string | null) => {
        if (value == null) {
            return null;
        }
        switch (attribute) {
            case "assigned_to":
                return formatUserFromId(+value, users, { translate });
            case "duedate":
                return formatDate(value);
            case "status":
                return translate(`subtask_status.${value}`).toLocaleLowerCase();
            case "priority":
                return translate(`subtask_priority.${value}`).toLocaleLowerCase();
            case "remind_at":
                return getTimestampDetails(value);
            case "gate_task_id":
                return getGateTaskName(value);
            case "estimated_effort":
            case "tracked_time":
                return effortConverter(+value);
            default:
                return value;
        }
    };

    const getNameForId = (id: number) => {
        return formatUserFromId(+id, users, { translate });
    };

    const renderMentionElement = (id: number, name: string) => {
        return name;
    };

    const getText = () => {
        const key = getTranslationKeyForHistoryEntry(item);

        // cannot render markdown to string/html, so we need a special case here
        if (item.attribute === "description") {
            if (item.newValue === null || item.newValue === "") {
                return translate(`${getTranslationKeyForHistoryEntryBase(item)}.clear_override`);
            }
            return (
                <>
                    {`${translate(key)} `}
                    <Link href="#" onClick={toggleCollapse} onKeyPress={toggleCollapse}>
                        {translate(translationKeys.VDLANG_ACTIVITIES_SHOW_CHANGES)}
                    </Link>
                    <Collapse in={collapsed}>
                        <Citation
                            dangerouslySetInnerHTML={{ __html: markdownToSafeHtml(item.newValue, getNameForId, renderMentionElement) }}
                        />
                    </Collapse>
                </>
            );
        }
        if (item.attribute === "remind_at") {
            return translate(key, getTimestampDetails(item.newValue));
        }

        const data = {
            previousValue: formatValue(item.attribute, item.previousValue),
            newValue: formatValue(item.attribute, item.newValue),
        };
        return translate(key, data);
    };

    return (
        <ParentGrid container justifyContent="space-between" wrap="nowrap" alignItems="center" hasDivider={!hideDivider}>
            <Grow item>
                <HistoryTypography variant="body2">
                    <DarkText>{getNameForId(item.userId)}</DarkText> {getText()} {/* can contain dom node */}
                </HistoryTypography>
            </Grow>
            <Grid component={DateContainer} item>
                <Tooltip title={formatDateTime(item.date)}>
                    <Typography variant="caption">{formatDate(item.date)}</Typography>
                </Tooltip>
            </Grid>
        </ParentGrid>
    );
};

SubtaskHistoryItem.propTypes = {
    item: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
    formatDate: PropTypes.func.isRequired,
    formatDateTime: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SubtaskHistoryItem;
