import { Navigate } from "react-router-dom";
import { useClientFeatures } from "../domain/client";
import { useInitialAccessPermissionsQueries } from "../domain/permissions";
import { useCurrentUser } from "../domain/users";
import { useIsDesktop } from "../lib/mobile";
import { getFirstPageRoute } from "../lib/routes";

const RootRedirect = () => {
    const currentUser = useCurrentUser();
    const clientFeatures = useClientFeatures();
    const isDesktop = useIsDesktop();

    const accessPermissionQueries = useInitialAccessPermissionsQueries();

    if (!accessPermissionQueries.isSuccess) {
        return null;
    }

    const fallbackRoute = getFirstPageRoute(currentUser, clientFeatures, isDesktop, {
        hasIdeaAccess: accessPermissionQueries.hasIdeaAccessQuery.data.hasPermission,
        hasActivityAccess: accessPermissionQueries.hasActivityAccessQuery.data.hasPermission,
        hasFeedAccess: accessPermissionQueries.hasFeedAccessQuery.data.hasPermission,
        hasDashboardAccess: accessPermissionQueries.hasDashboardAccessQuery.data.hasPermission,
        hasProcessAccess: accessPermissionQueries.hasProcessAccessQuery.data.hasPermission,
        hasMethodAccess: accessPermissionQueries.hasMethodAccessQuery.data.hasPermission,
    });
    return <Navigate replace to={fallbackRoute} />;
};

export default RootRedirect;
