import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AttributeTable, CreateSupplierRequestBody, SupplierDto, SupplierListDto } from "api-shared";
import { useDispatch } from "react-redux";
import { apiGet, apiPost } from "../lib/api";
import { registerFieldData } from "./field-data";

const SUPPLIERS_PATH = "suppliers";

export const SupplierQueryKeys = {
    all: [SUPPLIERS_PATH] as const,
};

export const useSuppliersQuery = (enabled = true) => {
    const dispatch = useDispatch();
    return useQuery({
        queryKey: SupplierQueryKeys.all,
        queryFn: ({ signal }) => apiGet<SupplierListDto>(SUPPLIERS_PATH, { signal }),
        enabled,
        onSuccess: (response) => {
            dispatch(registerFieldData(AttributeTable.Suppliers, response));
        },
    });
};

export const useCreateSupplierMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: CreateSupplierRequestBody) => apiPost<SupplierDto>(SUPPLIERS_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries(SupplierQueryKeys.all);
        },
    });
};
