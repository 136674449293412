import { Grid, styled, Typography } from "@mui/material";
import { DiscardReasons, HistoryEventType, IdeaHistory, IdeaHistoryTable, UserDto } from "api-shared";
import { TFunction } from "i18next";
import Tooltip from "../../components/Tooltip";
import { useLanguage } from "../../hooks/useLanguage";
import useTimezone from "../../hooks/useTimezone";
import { formatUserFromId } from "../../lib/formatters";
import { replaceImage } from "../../lib/history";
import { replaceMentionUsers } from "../../lib/mention";
import { translationKeys } from "../../translations/main-translations";
import { useIdeaEstimateConversion } from "./hooks";

interface IIdeaHistoryItem {
    historyItem: IdeaHistory;
    measureCreatorId?: number;
    translate: TFunction;
    users: UserDto[];
    hideDivider: boolean;
}

export enum IdeaHistoryType {
    POTENTIAL = "potentialEstimate",
    TIME = "timeEstimate",
    EFFORT = "effortEstimate",
    UPDATED_BY = "updatedById",
    DESCRIPTION = "description",
    DISCARD_REASON = "discardReason",
    DISCARD_STATEMENT = "discardStatement",
    OWNER = "ownerId",
}
const DateContainer = styled("div")(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

const DarkText = styled("span")(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 0),
}));

const StyledNoBorderGrid = styled(Grid)(({ theme }) => ({
    borderBottom: `0px`,
    padding: theme.spacing(1, 0),
}));

const Grow = styled(Grid)(({ theme }) => ({
    flexGrow: 1,
    flexShrink: 1,
    width: 0, // fixes grow for IE/Safari
}));

const StyledText = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    overflowWrap: "anywhere",
}));

const IdeaHistoryItem = ({ historyItem, measureCreatorId, users, translate, hideDivider }: IIdeaHistoryItem) => {
    const { formatDate, formatDateTime } = useTimezone();
    const language = useLanguage();
    const getIdeaConversionValues = useIdeaEstimateConversion();

    const getNameForId = (id: number) => {
        return formatUserFromId(+id, users, { translate });
    };

    const formatValue = (value: string | null, attribute: string) => {
        // Slider values cannot have null has new value, so ignore that case
        if (attribute === IdeaHistoryType.POTENTIAL && value !== null) {
            const { potentialValue } = getIdeaConversionValues({
                potentialEstimateValue: +value,
            });
            return potentialValue;
        }
        if (attribute === IdeaHistoryType.TIME && value !== null) {
            return translate(`${translationKeys.VDLANG_IDEAS_TIME_ESTIMATE_SLIDER_VALUE}.${value}`);
        }
        if (attribute === IdeaHistoryType.EFFORT && value !== null) {
            return translate(`${translationKeys.VDLANG_IDEAS_EFFORT_ESTIMATE_SLIDER_VALUE}.${value}`);
        }
        if (attribute === IdeaHistoryType.DESCRIPTION || attribute === IdeaHistoryType.DISCARD_STATEMENT) {
            const imageReplacedValue = value !== null ? replaceImage(value) : value;
            // Replace mention tokens with actual user names
            return replaceMentionUsers(users, imageReplacedValue ?? "", translate);
        }
        if (attribute === IdeaHistoryType.DISCARD_REASON) {
            const discardReason = DiscardReasons.find(({ id }) => String(id) === value);
            return discardReason === undefined ? "" : discardReason[language];
        }
        if (attribute === IdeaHistoryType.OWNER && value !== null) {
            return formatUserFromId(Number(value), users, { translate });
        }

        return value;
    };

    const ownerName = historyItem.ownerId != null ? getNameForId(historyItem.ownerId) : translate("deleted_user");

    const measureCreatorName =
        measureCreatorId !== undefined && historyItem.attribute === "convertedById" ? getNameForId(measureCreatorId) : null;

    const getText = () => {
        if (historyItem.tableName === IdeaHistoryTable.IdeaValue) {
            return translate(`idea_history.fields.${historyItem.operationType}`, {
                user: ownerName,
                attribute: translate(historyItem.attribute),
                value: historyItem.operationType !== HistoryEventType.DELETE ? historyItem.newValue : undefined,
            });
        }

        const key = `idea_history.${historyItem.attribute}.${historyItem.operationType}`;
        const data = {
            newValue: formatValue(historyItem.newValue, historyItem.attribute),
        };

        return translate(key, data);
    };

    const GridComponent = hideDivider ? StyledNoBorderGrid : StyledGrid;
    return (
        <GridComponent container justifyContent="space-between" wrap="nowrap" alignItems="flex-start">
            <Grow item>
                <StyledText>
                    <DarkText>{measureCreatorName ?? ownerName} </DarkText>
                    {getText()}
                </StyledText>
            </Grow>
            <Grid component={DateContainer} item>
                <Tooltip title={formatDateTime(historyItem.datetime)}>
                    <StyledText>{formatDate(historyItem.datetime)}</StyledText>
                </Tooltip>
            </Grid>
        </GridComponent>
    );
};
export default IdeaHistoryItem;
