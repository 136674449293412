import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ListItem, ListItemButton, ListItemIcon, styled, Theme } from "@mui/material";
import classNames from "classnames";
import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import DragHandle from "./DragHandle";

interface IDndItemProps<IdType extends UniqueIdentifier = UniqueIdentifier> {
    children: ReactNode;
    onClick?: (id: IdType) => void;
    showHandle?: boolean;
    selected?: boolean;
    divider?: boolean;
    className?: string;
    id: IdType;
    disabled?: boolean;
}

const classes = {
    isDragging: "DndItem-isDragging",
    withBorder: "DndItem-withBorder",
};

const stylesCreator = ({ theme }: { theme: Theme }) => ({
    [`&.${classes.isDragging}`]: {
        zIndex: 1, // make sure dragging item is always over other list items
        [`&.${classes.withBorder}`]: {
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    },
});

const StyledListItem = styled(ListItem)(stylesCreator);

const StyledListItemButton = styled(ListItemButton)(stylesCreator);

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: 0, // reset default minWidth of 56px
    paddingRight: theme.spacing(2),
}));

const Grow = styled("div")({
    display: "flex",
    flexGrow: 1,
    minWidth: 0,
});

const DndItem = <IdType extends UniqueIdentifier>({
    onClick,
    className,
    showHandle,
    children,
    selected,
    divider,
    id,
    disabled = false,
}: IDndItemProps<IdType>) => {
    const { t: translate } = useTranslation();
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, disabled });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleClick = useCallback(() => onClick?.(id), [onClick, id]);

    const Component = onClick != null ? StyledListItemButton : StyledListItem;
    const buttonProps = onClick != null ? { onClick: handleClick, selected } : {};

    const handleProps = { ...attributes, ...listeners };
    return (
        <Component
            className={classNames(className, isDragging && classes.isDragging, divider && classes.withBorder)}
            style={style}
            divider={divider}
            ref={setNodeRef}
            disabled={disabled}
            {...(!showHandle && handleProps)} // without a draghandle, make sure to attach the listeners to the whole item
            {...(onClick != null && buttonProps)}
        >
            {showHandle ? (
                <CustomListItemIcon {...handleProps}>
                    <DragHandle translate={translate} />
                </CustomListItemIcon>
            ) : null}
            <Grow>{children}</Grow>
        </Component>
    );
};

export default DndItem;
