import { Container, Paper, Stack, Typography, alpha, styled, useMediaQuery, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";
import blueLogo from "../static/images/logo_blau.svg";
import whiteLogo from "../static/images/logo_weiss.svg";
import PrivacyPolicyLink from "./PrivacyPolicyLink";

const Root = styled(Stack)(({ theme }) => ({
    height: "100vh",
    backgroundImage: `radial-gradient(circle at 50% 50%, ${alpha(theme.palette.valueBlue.main, 0.84)}, ${
        theme.palette.valueBlue.main
    } 58%)`,
}));

const Logo = styled("img")(({ theme }) => ({
    width: 256,
}));

const Content = styled(Paper)(({ theme }) => ({
    width: "100%",
    maxWidth: theme.breakpoints.values.sm,
    [theme.breakpoints.down("sm")]: {
        height: "100vh",
    },
}));

function BlueStandaloneCard({ children }: PropsWithChildren<unknown>) {
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Root justifyContent="center" alignItems="center">
            {!isXS ? <Logo src={whiteLogo} alt="Valuedesk" sx={{ mb: 8 }} /> : null}
            <Content square={isXS}>
                <Container fixed maxWidth="xs" sx={{ py: 10, px: 3 }}>
                    <Stack>
                        <Stack alignItems="center">{isXS ? <Logo src={blueLogo} alt="Valuedesk" sx={{ mb: 12 }} /> : null}</Stack>
                        <div>{children}</div>
                    </Stack>
                </Container>
            </Content>
            <Typography align="center" variant="caption">
                <PrivacyPolicyLink color="valueBlue.contrastText" />
            </Typography>
        </Root>
    );
}

export default BlueStandaloneCard;
