import { Alert, Card, CardActions, CardContent, CardHeader, Grid } from "@mui/material";
import { IdentityProviderType } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../components/Form";
import LoadingButton from "../../components/LoadingButton";
import PasswordInput from "../../components/input/PasswordInput";
import SetPasswordInput from "../../components/input/SetPasswordInput";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useLoginProvider } from "../../domain/login-provider";
import { useConfirmUserPasswordReset } from "../../domain/password-reset";
import { useCurrentUser } from "../../domain/users";
import { translationKeys } from "../../translations/main-translations";

const UserPasswordReset = () => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState<string | null>(null);
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);

    const user = useCurrentUser();
    const loginProviderTypeQuery = useLoginProvider({ email: user?.email ?? "" });

    const changePassword = useConfirmUserPasswordReset();

    const { t: translate } = useTranslation();

    const handleNewPasswordChange = (newPassword: string, passwordValid: boolean) => {
        setNewPassword(newPassword);
        setIsNewPasswordValid(passwordValid);
    };

    const submit = () => {
        if (isNewPasswordValid && newPassword != null) {
            changePassword.mutate({ oldPassword, newPassword });
        }
    };

    if (!loginProviderTypeQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const isPasswordProvider = loginProviderTypeQuery.data.type === IdentityProviderType.Password;

    return (
        <Grid container spacing={2} justifyContent="center" data-testid="user-password-reset">
            <Grid item xs={12} md={7}>
                {isPasswordProvider ? (
                    <Card>
                        <CardHeader title={translate("Update Password")} />
                        <CardContent>
                            <Form onSubmit={submit}>
                                <PasswordInput
                                    name="oldPassword"
                                    value={oldPassword}
                                    onChange={(event) => setOldPassword(event.target.value)}
                                    label={translate("old password")}
                                    margin="normal"
                                    required
                                />
                                <SetPasswordInput
                                    name="newPassword"
                                    onPasswordChange={handleNewPasswordChange}
                                    margin="normal"
                                    label={translate("new password")}
                                    required
                                />
                            </Form>
                        </CardContent>
                        <CardActions>
                            <LoadingButton
                                isLoading={changePassword.isLoading}
                                variant="contained"
                                onClick={submit}
                                disabled={!isNewPasswordValid || oldPassword.length < 1}
                            >
                                {translate("Change Password")}
                            </LoadingButton>
                        </CardActions>
                    </Card>
                ) : (
                    <Card>
                        <CardHeader title={translate("Update Password")} />
                        <CardContent>
                            <Alert severity="info">{translate(translationKeys.VDLANG_USER_PROFILE_PASSWORD_SSO_INFO)}</Alert>
                        </CardContent>
                    </Card>
                )}
            </Grid>
        </Grid>
    );
};

export default UserPasswordReset;
