import { useCallback, useState } from "react";
import { Language } from "../../translations/main-translations";
import ActionItemDialog, { IActionItemDialogProps } from "./ActionItemDialog";
import DiscardEntityForm from "./DiscardEntityForm";

interface DiscardEntityDialogProps extends IActionItemDialogProps {
    defaultReason: number | null | undefined;
    onDiscard: (reason: number, statement: string) => void;
    defaultStatement: string | null | undefined;
    currentLanguage: Language;
    entityName: string;
    isInMeasureContext?: boolean;
}

const DiscardEntityDialog = ({
    defaultReason,
    onDiscard,
    open,
    onClose,
    translate,
    defaultStatement,
    currentLanguage,
    entityName,
    isInMeasureContext = false,
}: DiscardEntityDialogProps) => {
    const [reason, setReason] = useState(defaultReason ?? null);
    const [statement, setStatement] = useState<string | undefined | null>(undefined);
    const [isValid, setIsValid] = useState(false);

    const discardEntity = () => {
        if (isValid && reason != null && statement !== undefined) {
            onDiscard(reason, statement ?? "");
        }
    };

    const updateValue = useCallback((name: "reason" | "statement", value: string | number, isValid: boolean) => {
        setIsValid(isValid);
        if (name === "reason" && typeof value === "number") {
            setReason(value);
        } else if (name === "statement" && typeof value === "string") {
            setStatement(value);
        }
    }, []);

    const label = translate("discardEntity", { entityName: translate(entityName) });
    return (
        <ActionItemDialog
            open={open}
            onClose={onClose}
            action="discard"
            primary={label}
            primaryIsTranslated
            primaryDanger
            onPrimary={discardEntity}
            primaryDisabled={!isValid}
            title={`${label}?`}
            translate={translate}
        >
            <DiscardEntityForm
                reason={reason}
                statement={defaultStatement}
                onChange={updateValue}
                updateValue={updateValue}
                translate={translate}
                currentLanguage={currentLanguage}
                isInMeasureContext={isInMeasureContext}
            />
        </ActionItemDialog>
    );
};

export default DiscardEntityDialog;
