import { useMutation } from "@tanstack/react-query";
import { BasicMessageDto, SetPasswordResultDto, TokenValidationDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../infrastructure/notifications";
import { apiPost } from "../lib/api";
import { NotificationType } from "../lib/notifications";
import { FeedbackTranslationKeys } from "../translations/notification-translations";

interface SetPasswordInputDto {
    token: string;
    password: string;
}

export const usePasswordReset = () => {
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (email: string) => apiPost<BasicMessageDto>("password-reset/request", { email }),
        onSuccess: () => {
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_OWN_PASSWORD_RESET_SENT));
        },
    });
};

export const useConfirmPasswordReset = () => {
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: SetPasswordInputDto) => apiPost<SetPasswordResultDto>("password-reset/set", data),
        onSuccess: () => {
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_PASSWORD_CHANGED));
        },
    });
};

interface PasswordChangeDto {
    oldPassword: string;
    newPassword: string;
}
export const useConfirmUserPasswordReset = () => {
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: PasswordChangeDto) => apiPost<BasicMessageDto>("users/current/resetPassword", data),
        onSuccess: () => {
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_PASSWORD_CHANGED));
        },
    });
};

export const useValidatePasswordResetToken = () => {
    return useMutation({
        mutationFn: (token: string) => apiPost<BasicMessageDto>("password-reset/validate", { token }),
        meta: {
            ignoreErrors: true,
        },
    });
};

export const useConfirmInvite = () => {
    return useMutation({
        mutationFn: (data: SetPasswordInputDto) => apiPost<SetPasswordResultDto>("invite/set", data),
        meta: {
            ignoreErrors: true,
        },
    });
};

export const useValidateInviteToken = (onSuccess?: (data: TokenValidationDto) => void) => {
    return useMutation({
        mutationFn: (token: string) => apiPost<TokenValidationDto>("invite/validate", { token }),
        onSuccess,
        meta: {
            ignoreErrors: true,
        },
    });
};
