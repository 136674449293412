import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ActivityTemplateIcon from "../../components/icons/ActivityTemplateIcon";
import { useActivityTemplate } from "../../domain/methods/activity-template";
import { useApplyActivityTemplate } from "../../domain/subtasks";
import useDialog from "../../hooks/useDialog";
import { useLanguage } from "../../hooks/useLanguage";
import { translationKeys } from "../../translations/main-translations";
import ApplyActivityTemplateDialog from "./ApplyActivityTemplateDialog";

interface ApplyActivityTemplateStandaloneProps {
    measureId: number;
    methodId: number;
}

const ApplyActivityTemplateStandalone = ({ methodId, measureId }: ApplyActivityTemplateStandaloneProps) => {
    const language = useLanguage();
    const { t: translate } = useTranslation();

    const dialog = useDialog();
    const applyActivityTemplate = useApplyActivityTemplate().mutate;

    const activityTemplateQuery = useActivityTemplate({ methodId });
    const applyTemplate = (templateId: number, activityIds: number[]) => applyActivityTemplate({ measureId, templateId, activityIds });

    if (!activityTemplateQuery.isSuccess || activityTemplateQuery.data.items.length === 0) {
        return null;
    }

    return (
        <Grid container>
            <ApplyActivityTemplateDialog
                activityTemplate={activityTemplateQuery.data}
                applyActivityTemplate={applyTemplate}
                open={dialog.isOpen}
                onClose={dialog.close}
                language={language}
                translate={translate}
            />
            <Button variant="text" onClick={dialog.open} startIcon={<ActivityTemplateIcon />}>
                {` ${translate(translationKeys.VDLANG_SHOW_ACTIVITY_TEMPLATE)}`}
            </Button>
        </Grid>
    );
};

export default ApplyActivityTemplateStandalone;
