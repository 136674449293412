import AddCircleRounded from "@mui/icons-material/AddCircleRounded";
import ChangeCircleRounded from "@mui/icons-material/ChangeCircleRounded";
import {
    CardHeader,
    cardHeaderClasses,
    Divider,
    Paper,
    Skeleton,
    Stack,
    styled,
    Typography,
    typographyClasses,
    TypographyProps,
} from "@mui/material";
import { purple, teal } from "@mui/material/colors";
import { FeedSummaryDto, UserDto } from "api-shared";
import moment, { MomentInput } from "moment";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";
import FeedSummaryIcon from "./FeedSummaryIcon";

const SummaryItemHeader = styled(CardHeader)(({ theme }) => ({
    padding: theme.spacing(2, 2, 0),
    [`& .${cardHeaderClasses.content} .${typographyClasses.root}`]: {
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

const SummaryItemContent = styled("div")(({ theme }) => ({
    padding: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr auto",
    columnGap: theme.spacing(2),
}));

const AddIcon = styled(AddCircleRounded)(({ theme }) => ({
    color: teal[500],
}));

const SyncIcon = styled(ChangeCircleRounded)(({ theme }) => ({
    color: theme.palette.magicPurple.main,
}));

interface IFeedSummaryProps {
    data?: FeedSummaryDto;
    user: UserDto;
}

const TypographySkeleton = (props: TypographyProps) => (
    // Make skeleton span the full css grid row
    <Typography gridColumn="1/-1" {...props}>
        <Skeleton />
    </Typography>
);

interface DualTextProps {
    primary: string | null;
    secondary: string;
}
const DualText = ({ primary, secondary }: DualTextProps) => {
    return (
        <>
            <Typography noWrap>{primary}</Typography>
            <Typography color="textSecondary">{secondary}</Typography>
        </>
    );
};

const FeedSummary = ({ data, user }: IFeedSummaryProps) => {
    const { t: translate } = useTranslation();

    const getDateToDiff = (date: MomentInput) => {
        if (date) {
            const now = moment();
            const other = moment(date);
            const days = Number(moment.duration(now.diff(other)).asDays().toFixed(0));
            const hours = Number(moment.duration(now.diff(other)).asHours().toFixed(0));
            if (hours <= 0) {
                return translate(translationKeys.VDLANG_MOMENT_AGO);
            } else if (days <= 0) {
                return translate(translationKeys.VDLANG_HOURS_AGO, {
                    hours,
                });
            }
            return translate(translationKeys.VDLANG_DAYS_AGO, {
                days,
            });
        }
        return "-";
    };

    return (
        <Paper>
            <Stack>
                <SummaryItemHeader
                    avatar={
                        <FeedSummaryIcon backgroundColor={teal[50]}>
                            <AddIcon />
                        </FeedSummaryIcon>
                    }
                    title={translate(translationKeys.VDLANG_LAST_CREATED_PROCESS)}
                />
                <SummaryItemContent>
                    {data === undefined ? (
                        <TypographySkeleton />
                    ) : (
                        <DualText primary={user.displayname} secondary={getDateToDiff(data.lastCreatedMeasureUser)} />
                    )}
                    {data === undefined ? (
                        <TypographySkeleton />
                    ) : (
                        <DualText primary={data.clientName} secondary={getDateToDiff(data.lastCreatedMeasure)} />
                    )}
                </SummaryItemContent>
                <Divider />
                <SummaryItemHeader
                    avatar={
                        <FeedSummaryIcon backgroundColor={purple[50]}>
                            <SyncIcon />
                        </FeedSummaryIcon>
                    }
                    title={translate(translationKeys.VDLANG_LAST_UPDATED_PROCESS)}
                />
                <SummaryItemContent>
                    {data === undefined ? (
                        <TypographySkeleton />
                    ) : (
                        <DualText primary={user.displayname} secondary={getDateToDiff(data.lastUpdatedMeasureUser)} />
                    )}
                    {data === undefined ? (
                        <TypographySkeleton />
                    ) : (
                        <DualText primary={data.clientName} secondary={getDateToDiff(data.lastUpdatedMeasure)} />
                    )}
                </SummaryItemContent>
            </Stack>
        </Paper>
    );
};

export default FeedSummary;
