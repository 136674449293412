import { FilterDefinition, ICompareExpression, MEASURE_FIELDS, MeasureStatus, OPERATORS_FOR_TYPE, Operators } from "api-shared";

export const buildInitialCondition = (): ICompareExpression => ({
    field: MEASURE_FIELDS.status.name,
    operator: Operators.In,
    values: [MeasureStatus.STATUS_OPEN, MeasureStatus.STATUS_CLOSED] as unknown as string[],
});

export const buildInitialFilterDefinition = (): FilterDefinition => [buildInitialCondition()];

export const buildNewTitleCondition = (): ICompareExpression => ({
    field: MEASURE_FIELDS.title.name,
    operator: OPERATORS_FOR_TYPE[MEASURE_FIELDS.title.type][0],
    values: [""],
});

const areConditionsValid = (conditions: ICompareExpression[]) => {
    //we return true here, so that there is a possibility to save no filter as filter
    if (conditions.length === 0) {
        return true;
    }
    return conditions.every(({ operator, values }) => {
        const hasMultipleValues = values != null && values.length > 1;
        const hasSingleValidValue = values?.[0] != null && values[0] !== "";
        // We don't care about values with this operator
        return operator === Operators.NotSet || hasMultipleValues || hasSingleValidValue;
    });
};

export const buildFilterDefinitionResult = (newConditions: ICompareExpression[]): [FilterDefinition, boolean] => {
    return [newConditions, areConditionsValid(newConditions)];
};
