import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
    CurrentGateType,
    ErrorConstantKeys,
    FilterDefinition,
    FilteredMeasuresDto,
    ScopeDto,
    Sort,
    zMeasureIdSearchRequestBody,
} from "api-shared";
import { z } from "zod";
import { ApiRequestOptions, apiPost } from "../../lib/api";

const MEASURES_PATH = "measures/search";
const MEASURES_ID_SEARCH_PATH = "measures/id-search";

export const MeasureListKeys = {
    all: [MEASURES_PATH, "list"] as const,
    idSearch: [MEASURES_ID_SEARCH_PATH] as const,
    deskAll: () => [...MeasureListKeys.all, "desk"] as const,
    desk: (column?: number) => [...MeasureListKeys.all, "desk", { byCategory: column }] as const,
    list: () => [...MeasureListKeys.all, "list"] as const,
    csv: () => [...MeasureListKeys.all, "csv"] as const,
};

export interface IMeasuresQuery {
    filter?: FilterDefinition;
    filterId?: number;
    page: number;
    pageSize: number;
    orderBy?: string;
    sort?: Sort;
    byCategory?: CurrentGateType;
    scope?: ScopeDto;
    measureConfigIds?: number[];
    enabled?: boolean;
}

type MeasuresQueryOptions = IMeasuresQuery & {
    enabled?: boolean;
    meta?: any;
};

const zMeasureIdsQueryOptions = zMeasureIdSearchRequestBody.extend({
    enabled: z.boolean().optional(),
    meta: z.any(),
});

type MeasureIdsQueryOptions = z.infer<typeof zMeasureIdsQueryOptions>;

function getMeasures(
    { filter, filterId, page, pageSize, orderBy, sort, measureConfigIds, scope }: IMeasuresQuery,
    options: ApiRequestOptions,
) {
    const data = {
        page: page + 1,
        pageSize,
        orderBy,
        sort,
        measureConfigIds,
        filter,
        scope,
        filterId,
    };
    return apiPost<FilteredMeasuresDto>(MEASURES_PATH, data, options);
}

/**
 * Returns a filtered list of measure display ids
 */
export const useMeasureIds = (key: string, { enabled = true, meta = {}, ...options }: MeasureIdsQueryOptions) => {
    return useQuery({
        queryKey: [...MeasureListKeys.idSearch, key, options] as const,
        queryFn: ({ signal }) => apiPost<number[]>(MEASURES_ID_SEARCH_PATH, options, { signal }),
        keepPreviousData: false,
        enabled,
        meta,
    });
};

export const useMeasures = (key: string, { enabled = true, meta = {}, ...options }: MeasuresQueryOptions) => {
    return useQuery({
        queryKey: [...MeasureListKeys.list(), key, options] as const,
        queryFn: ({ queryKey, signal }) => getMeasures(queryKey[4], { signal }),
        keepPreviousData: true, // paginated query, keep old data while fetching new pages
        enabled,
        meta,
    });
};

export const useDeskMeasures = (column: number, options: IMeasuresQuery) => {
    return useInfiniteQuery({
        queryKey: [...MeasureListKeys.desk(column), options] as const,
        queryFn: ({ queryKey, pageParam = 0, signal }) => getMeasures({ ...queryKey[4], page: pageParam }, { signal }),
        getNextPageParam: (lastPage: FilteredMeasuresDto, allPages: FilteredMeasuresDto[]) => {
            const totalFetchedItems = allPages.reduce((sum, page) => sum + page.measures.length, 0);
            // use matchingItems from the most recent fetched page. It my ay be different from the other pages when processes have been
            // created/updated in between
            if (totalFetchedItems < lastPage.matchingItems) {
                return allPages.length;
            }
        },
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_INVALID_FILTER_DEFINITION,
        },
    });
};

export const useMeasuresCSV = ({ enabled = true, ...options }: IMeasuresQuery) => {
    return useQuery({
        queryKey: [...MeasureListKeys.csv(), options] as const,
        queryFn: ({ queryKey, signal }) => getMeasures(queryKey[3], { signal }),
        enabled,
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_INVALID_FILTER_DEFINITION,
        },
    });
};
