import { styled } from "@mui/material";
import OverlineLabel from "../OverlineLabel";
import Select from "../input/select/Select";
import { ISelectProps, Option } from "../input/select/types";

const BottomMarginSelect = styled(Select)(({ theme }) => ({
    marginBottom: theme.spacing(),
})) as typeof Select;

const SidebarSelect = <OptionType extends Option = Option, IsMulti extends boolean = false>({
    label,
    ...props
}: ISelectProps<OptionType, IsMulti>) => {
    return (
        <>
            {label != null && <OverlineLabel>{label}</OverlineLabel>}
            <BottomMarginSelect fullWidth margin="none" {...props} />
        </>
    );
};

export default SidebarSelect;
