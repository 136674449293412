import { useQuery } from "@tanstack/react-query";
import { AttributeTable, GroupListDto } from "api-shared";
import { useDispatch } from "react-redux";
import { apiGet } from "../lib/api";
import { registerFieldData } from "./field-data";

const GROUPS_PATH = "groups";

export const GroupsQueryKeys = {
    all: [GROUPS_PATH] as const,
};

export const useGroups = (enabled = true) => {
    const dispatch = useDispatch();
    return useQuery({
        enabled,
        queryKey: GroupsQueryKeys.all,
        queryFn: ({ signal }) => apiGet<GroupListDto>(GROUPS_PATH, { signal }),
        onSuccess: (response) => {
            dispatch(registerFieldData(AttributeTable.GroupsWithAccess, response));
        },
    });
};
