import { AclNamespaces, AclPermissions, DEFAULT_FILTER_ID } from "api-shared";
import { useFiltersQuery } from "../../domain/filters";
import { useUserHasPermissionQuery } from "../../domain/permissions";
import { useSubTasks } from "../../domain/subtasks";
import { useUiState } from "../../domain/ui-state";
import { useCurrentUserId } from "../../domain/users";
import { filterAssignedCreatedIds } from "../../lib/subtask";

export function useSubTaskQuery() {
    const [
        {
            activityGroupBy,
            activityGroupOrderBy,
            activityGroupSort,
            activityGrouping,
            activityOrderBy,
            activitySort,
            activityStatus,
            activityCreatedByIds,
            activityAssignedToIds,
            activityFilterId,
        },
    ] = useUiState();
    const hasPersonFilterPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.User,
        permission: AclPermissions.Filter,
        fact: {},
    });
    const currentUserId = useCurrentUserId();

    const [filteredAssignedToIds, filteredCreatedByIds] =
        hasPersonFilterPermissionQuery.isSuccess && hasPersonFilterPermissionQuery.data.hasPermission
            ? [activityAssignedToIds, activityCreatedByIds]
            : filterAssignedCreatedIds(currentUserId, activityAssignedToIds, activityCreatedByIds);

    // Generate a stable reference, that can be consumed by useDebounce
    const queryOptions = {
        grouping: activityGrouping,
        orderBy: activityOrderBy,
        sort: activitySort,
        status: activityStatus,
        groupBy: activityGroupBy,
        groupOrderBy: activityGroupOrderBy,
        groupSort: activityGroupSort,
        createdByIds: filteredCreatedByIds,
        assignedToIds: filteredAssignedToIds,
        filterId: activityFilterId ?? DEFAULT_FILTER_ID,
    };

    const filters = useFiltersQuery();
    const filterDefinition = filters.data?.find((filter) => filter.id === queryOptions.filterId)?.definition;

    return useSubTasks({
        filter: {
            ...queryOptions,
            filterDefinition: JSON.stringify(filterDefinition),
        },
        filterDefinition,
    });
}
