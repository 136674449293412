import { useQuery } from "@tanstack/react-query";
import { ActivityTemplateDto, FeatureFlags } from "api-shared";
import { apiGet } from "../../lib/api";
import { useClientHasFeature } from "../client";

type UseActivityTemplateOptions = {
    methodId?: number | null;
};

export const useActivityTemplate = ({ methodId }: UseActivityTemplateOptions) => {
    const hasFeature = useClientHasFeature(FeatureFlags.FEATURE_ACTIVITY_TEMPLATES);

    return useQuery({
        queryKey: ["methods", methodId, "activity-template"] as const,
        // queryKey[1] (aka methodId) cannot be null because of enabled flag
        queryFn: ({ queryKey, signal }) => apiGet<ActivityTemplateDto>(`methods/${queryKey[1] ?? 0}/activity-template`, { signal }),
        enabled: hasFeature && methodId != null,
    });
};
