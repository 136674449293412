import React from "react";

interface IFormProps extends React.HTMLProps<HTMLFormElement> {
    onSubmit?: React.FormEventHandler<HTMLFormElement>;
    children: React.ReactNode;
}

// forwardRef is needed because this is a functional component not a React.Component
const Form = React.forwardRef<HTMLFormElement, IFormProps>(({ children, onSubmit, ...other }, ref) => (
    <form
        method="POST"
        ref={ref}
        {...other}
        onSubmit={(e) => {
            e.preventDefault();
            onSubmit?.(e);
        }}
    >
        {children}
        {/* Add hidden submit field so that onSubmit will get triggered on enter */}
        <input type="submit" hidden />
    </form>
));
export default Form;
