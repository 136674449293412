import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ACCESS_TOKEN_STORAGE_KEY, getAuthToken, signOutEvent } from "../lib/authentication-saga";
import { bootstrapEvent } from "../lib/bootstrap";

export const STORAGE_KEY = "storage";

/**
 * When logging out in the foreground tab, perform logout also for all other tabs in the background.
 *
 * This is achieved using an event listener on the local storage that watches for changes on the access token.
 *
 * @export
 */
export function useBackgroundLogout(): void {
    const dispatch = useDispatch();
    const location = useLocation();

    const handleAccessTokenState = useCallback(
        (event: StorageEvent) => {
            const { key, newValue } = event;
            if (key === ACCESS_TOKEN_STORAGE_KEY && newValue == null) {
                dispatch(signOutEvent(location.pathname));
            }
        },
        [dispatch, location.pathname],
    );

    useEffect(() => {
        window.addEventListener(STORAGE_KEY, handleAccessTokenState);
        return () => {
            window.removeEventListener(STORAGE_KEY, handleAccessTokenState);
        };
    }, [handleAccessTokenState]);
}

/**
 * If the window/tab regains focus, check if there is an access token and attempt to login with that token.
 *
 * This is useful in case multiple tabs are open and one of them logs out. The background tabs will automatically logout too, and if one
 * of them is focused again, this hook checks if there was a different successfull login attempt in the meantime and if so, continues as
 * that user
 */
export function useForegroundLogin(): void {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    useEffect(() => {
        const logoutHandler = () => {
            const authToken = getAuthToken();
            // visibility is not availabe on event, but only as property on the document
            if (document.visibilityState !== "visible" || authToken == null) {
                return;
            }
            // Try to log in when the page gets focus and there is an authToken
            dispatch(bootstrapEvent(queryClient));
        };
        window.addEventListener("visibilitychange", logoutHandler);
        return () => {
            window.removeEventListener("visibilitychange", logoutHandler);
        };
    }, [dispatch, queryClient]);
}
