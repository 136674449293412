import {
    Box,
    Button,
    ButtonProps,
    ClickAwayListener,
    Grow,
    InputAdornment,
    InputBaseProps,
    Popper,
    styled,
    TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import type { Instance } from "@popperjs/core";
import moment, { Moment } from "moment";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isOutsideBounds } from "../../../lib/date";
import { translationKeys } from "../../../translations/main-translations";
import Tooltip from "../../Tooltip";
import DatePickerIcon from "./DatePickerIcon";
import DatePickerPopover from "./DatePickerPopover";

const ModalPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.modal + 1,
}));

type SizelessButtonProps = Omit<ButtonProps, "size">;
const InputButton = React.forwardRef<HTMLButtonElement, SizelessButtonProps>((props, ref) => <Button ref={ref} {...props} />);

// These are the spacing units so that the button sizes match the input sizes
// They achieve the same sizes, but spacing units are not exactly equal to the input sizes
const VerticalPaddings = {
    small: 0.5,
    medium: 0.75,
    large: 1,
    extralarge: 1.5,
};

const DateButton = styled(InputButton, { shouldForwardProp: (name) => name !== "size" })<Pick<InputBaseProps, "size" | "placeholder">>(
    ({ theme, placeholder, size = "medium" }) => ({
        padding: theme.spacing(VerticalPaddings[size], 1.5),
        color: placeholder ? theme.palette.text.disabled : theme.palette.text.secondary,
        gap: theme.spacing(),
        ...theme.typography.body2,
    }),
);

interface IDatePickerProps {
    disabled?: boolean;
    date: Moment | null;
    onDateChange?: (newValue: Moment | null) => void;
    shouldCloseOnChange?: (date: Moment | null) => boolean;
    onReminderChange?: (date: Moment | null) => void;
    remindAt?: Date | null;
    size?: InputBaseProps["size"];
    minDate?: Moment;
    maxDate?: Moment;
    placeholder?: string;
    infoMessage?: string;
    isClearable?: boolean;
}

const DatePicker = ({
    disabled,
    date,
    onDateChange,
    onReminderChange,
    remindAt,
    size = "medium",
    minDate,
    maxDate,
    placeholder,
    shouldCloseOnChange,
    infoMessage,
    isClearable = true,
}: IDatePickerProps) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const { t: translate } = useTranslation();

    const open = Boolean(anchorEl);
    const dateTemplate = moment.localeData().longDateFormat("L");
    const placeholderText = placeholder ?? dateTemplate.toLowerCase();

    const updateFromCalendar = (newMoment: Moment | null) => {
        if (newMoment !== null && isOutsideBounds(newMoment, minDate, maxDate)) {
            // ignore updates on invalid moments
            return;
        }
        if (!isClearable && newMoment === null) {
            // ignore updates on empty moments if not clearable
            return;
        }

        onDateChange?.(newMoment);

        if (!shouldCloseOnChange || shouldCloseOnChange(newMoment)) {
            setAnchorEl(null);
        }
    };

    const updateReminder = (date: moment.Moment | null) => onReminderChange?.(date);

    const containerRef = useRef<HTMLDivElement>(null);
    const handleClickAway = (event: MouseEvent | TouchEvent) => {
        if (anchorEl == null) {
            // dialog already closed
            return;
        }

        if (anchorEl !== event.target && event.target instanceof HTMLElement && anchorEl.contains(event.target)) {
            // clicked element is not the parent container, but inside of it, so it is one of the input elements (or below)
            // dont hide the dialog then
            return;
        }

        setAnchorEl(null);
    };

    const popperRef = useRef<Instance>(null);
    const handleToggleReminder = () => popperRef?.current?.update();

    return (
        <>
            <ModalPopper
                placement="bottom-start"
                anchorEl={anchorEl}
                open={open}
                modifiers={[
                    {
                        name: "preventOverflow",
                        options: {
                            altAxis: true, // also prevent overflow on the vertical axis, horizontal is enabled by default
                        },
                    },
                ]}
                popperOptions={{
                    strategy: "fixed",
                }}
                transition
                popperRef={popperRef}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Grow style={{ transformOrigin: "top right" }} {...TransitionProps}>
                            <DatePickerPopover
                                autoFocus
                                date={date}
                                onChange={updateFromCalendar}
                                translate={translate}
                                remindAt={remindAt}
                                onReminderChange={updateReminder}
                                onReminderToggled={handleToggleReminder}
                                minDate={minDate}
                                maxDate={maxDate}
                                infoMessage={infoMessage}
                                defaultCalendarMonth={minDate}
                            />
                        </Grow>
                    </ClickAwayListener>
                )}
            </ModalPopper>
            {/* container to provide a stable DOM node for the popper */}
            <Box ref={containerRef}>
                {open ? (
                    // MobileDatePicker (or autodetection with DatePicker) does not work well, as a custom popup component is used
                    <DesktopDatePicker
                        value={date}
                        onChange={updateFromCalendar}
                        disableOpenPicker
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                size={size}
                                error={props.error ?? isOutsideBounds(date, minDate, maxDate)}
                                autoFocus
                                // html size hint for native input component (~10 characters)
                                inputProps={{
                                    ...props.inputProps,
                                    size: 10,
                                    placeholder: translate(translationKeys.VDLANG_DATE_PLACEHOLDER),
                                }}
                                InputProps={{
                                    ...props.InputProps,
                                    // replace OpenPickerButton with static icon
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DatePickerIcon remindAt={remindAt} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                        inputFormat={dateTemplate}
                        minDate={minDate}
                        maxDate={maxDate}
                        defaultCalendarMonth={minDate}
                    />
                ) : (
                    <Tooltip title={date != null && placeholder != null ? placeholder : ""}>
                        <DateButton
                            placeholder={date == null ? placeholderText : undefined}
                            variant="text"
                            onClick={() => setAnchorEl(containerRef.current)}
                            disabled={disabled}
                            size={size}
                        >
                            {date != null ? date.format("L") : placeholderText}
                            <DatePickerIcon remindAt={remindAt} />
                        </DateButton>
                    </Tooltip>
                )}
            </Box>
        </>
    );
};
export default DatePicker;
