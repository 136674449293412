import { PropsWithChildren, createContext, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { HubspotTracker } from ".";

const HubspotTrackingCodeContext = createContext<HubspotTracker | undefined>(undefined);

interface IHubspotTrackingCodeProps {
    hubId?: string;
}

export function HubspotTrackingCode({ hubId, children }: PropsWithChildren<IHubspotTrackingCodeProps>) {
    const tracker = useRef<HubspotTracker | null>(null);
    if (tracker.current === null) {
        tracker.current = new HubspotTracker();
    }

    const location = useLocation();

    // Embed and remove tracking code
    useEffect(() => {
        if (hubId !== undefined && hubId !== "") {
            tracker.current?.embedTrackingCode(hubId);
        }

        return () => tracker.current?.removeTrackingCode();
    }, [hubId]);

    // Track every location pathname change as page view
    useEffect(() => {
        tracker.current?.setPath(location.pathname);
        tracker.current?.trackPageView();
    }, [location.pathname]);

    return <HubspotTrackingCodeContext.Provider value={tracker.current}>{children}</HubspotTrackingCodeContext.Provider>;
}

export function useHubspotTrackingCode(): HubspotTracker {
    const context = useContext(HubspotTrackingCodeContext);

    if (context === undefined) {
        throw new Error("Hubspot Tracking Code context not available!");
    }

    return context;
}
