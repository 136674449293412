import { Checkbox, List, ListItem, ListItemIcon, ListItemText, Paper, styled } from "@mui/material";
import { ActivityTemplateItemDto, SubTaskStatus } from "api-shared";
import { TFunction } from "i18next";
import { ChangeEvent, Component } from "react";
import SubtaskStatusIcon from "../../components/tasks/SubtaskStatusIcon";
import { translateFromProperty } from "../../lib/translate";
import { Language, translationKeys } from "../../translations/main-translations";

const ActivityTemplateList = styled(List)({
    padding: 0,
});

const ActivityTemplateListItem = styled(ListItem)({
    padding: 0,
});

const CheckboxContainer = styled(ListItemIcon)(({ theme }) => ({
    borderRight: `1px solid ${theme.palette.divider}`,
    justifyContent: "center",
}));

interface IApplyActivityTemplateListProps {
    activityTemplateItems: ActivityTemplateItemDto[];
    selectedActivityIds: number[];
    onChange: (activityIds: number[]) => void;
    language: Language;
    translate: TFunction;
}

class ApplyActivityTemplateList extends Component<IApplyActivityTemplateListProps> {
    onSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        const { activityTemplateItems, onChange } = this.props;
        const selectedActivityIds = checked ? activityTemplateItems.map((item) => item.id) : [];
        onChange(selectedActivityIds);
    };

    onSelectItem = (event: ChangeEvent<HTMLInputElement>, id: number) => {
        const { selectedActivityIds: oldActivityIds, onChange } = this.props;
        const index = oldActivityIds.indexOf(id);

        if (index < 0) {
            onChange([...oldActivityIds, id]);
        } else {
            const selectedActivityIds = [...oldActivityIds];
            selectedActivityIds.splice(index, 1);
            onChange(selectedActivityIds);
        }
    };

    isSelected = (id: number) => this.props.selectedActivityIds.includes(id);

    render() {
        const { activityTemplateItems, selectedActivityIds, language, translate } = this.props;

        const isChecked = selectedActivityIds.length === activityTemplateItems.length;
        const isIndeterminate = selectedActivityIds.length > 0 && selectedActivityIds.length < activityTemplateItems.length;

        return (
            <Paper>
                <ActivityTemplateList>
                    <ActivityTemplateListItem divider>
                        <CheckboxContainer>
                            <Checkbox checked={isChecked} indeterminate={isIndeterminate} onChange={this.onSelectAll} />
                        </CheckboxContainer>
                        <ListItemText
                            primary={<strong>{translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_ALL)}</strong>}
                            primaryTypographyProps={{ variant: "body2" }}
                            sx={{ py: 0, pr: 1, pl: 2 }}
                        />
                    </ActivityTemplateListItem>
                    {activityTemplateItems.map((item) => (
                        <ActivityTemplateListItem divider key={item.order}>
                            <CheckboxContainer>
                                <Checkbox checked={this.isSelected(item.id)} onChange={(event) => this.onSelectItem(event, item.id)} />
                            </CheckboxContainer>
                            <ListItemIcon sx={{ py: 0, pr: 1, pl: 2 }}>
                                <SubtaskStatusIcon status={SubTaskStatus.STATUS_OPEN} />
                            </ListItemIcon>
                            <ListItemText
                                primary={translateFromProperty(item, "title", language)}
                                primaryTypographyProps={{ variant: "body2" }}
                                sx={{ p: 0 }}
                            />
                        </ActivityTemplateListItem>
                    ))}
                </ActivityTemplateList>
            </Paper>
        );
    }
}

export default ApplyActivityTemplateList;
