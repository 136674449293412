import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import withTooltip from "../../hocs/withTooltip";

const ValuedeskIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon viewBox="0 0 32.00001 25.978956" {...props} ref={ref}>
        <path
            d="m 31.985955,0.22712546 c 0.0214,-0.0534 0.0214,-0.11759 -0.0214,-0.16035 -0.0428,-0.0534 -0.1069,-0.0748 -0.17104,-0.0641 
            -5.30217,1.25071004 -10.60433,1.96693004 -15.77822,2.12728004 -5.29147,0.17104 -10.6150101,-0.23517 -15.82097009,-1.19726004 
            -0.0748,-0.0107 -0.14966,0.0321 -0.18173,0.0962 -0.0321,0.0748 0,0.14966 0.0641,0.19241 5.46250999,3.67731 11.85504009,5.47321 
            19.50897009,5.47321 0.60932,0 1.21865,-0.0107 1.84935,-0.0321 0,0 0,0 0,0 l 0.19241,-0.0107 c -1.66761,2.95039 -3.31385,
            5.8687195 -4.99215,8.8298095 -1.40037,-2.25556 -2.75799,-4.44698 -4.14767,-6.7025295 1.15451,-0.35277 2.22349,-0.68415 3.30317,
            -1.01554 v 0 c -4.9494,-0.12828 -9.4605201,-1.71037 -13.8754201,-3.97662 l 5.86872,9.3536095 7.5684101,12.07953 c 0.29932,
            0.47035 0.81243,0.75898 1.37899,0.75898 0.0107,0 0.0321,0 0.0428,0 0.57725,-0.0107 1.10106,-0.33139 1.37899,-0.83381 1.53934,
            -2.76867 4.72492,-8.49843 7.53635,-13.55472 0,0 5.61216,-10.1125995 5.90079,-10.63639954 0.12828,-0.23518 0.25656,-0.48104 
            0.39552,-0.72691 -0.0107,0.0107 -0.0107,0.0107 0,0 v 0 c 0,0 0,0 0,0 z"
            id="path79"
        />
    </SvgIcon>
));
export default withTooltip(ValuedeskIcon);
