import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GetMeasureNotificationsResponseDto, MeasureNotificationActions } from "api-shared";
import { apiGet, apiPatch, apiPost } from "../lib/api";

export const NotificationQueryKeys = {
    forMeasure: (measureId: number) => [`measure-notifications`, measureId] as const,
};
const getNotificationUrlByMeasureId = (measureId: number) => `measures/${measureId}/notifications`;

interface CreateNotificationInputDto {
    measureId: number;
    userId: number;
}

interface UpdateNotificationInputDto extends CreateNotificationInputDto {
    action: MeasureNotificationActions;
}

const getNotificationsQuery = ({ queryKey, signal }: QueryFunctionContext<ReturnType<(typeof NotificationQueryKeys)["forMeasure"]>>) =>
    apiGet<GetMeasureNotificationsResponseDto>(getNotificationUrlByMeasureId(queryKey[1]), { signal });

/*
 * API
 */
export const useMeasureNotifications = (
    measureId: number,
    onSuccessCallback?: (
        response: GetMeasureNotificationsResponseDto,
        oldNotifications: GetMeasureNotificationsResponseDto | undefined,
    ) => void,
) => {
    const queryClient = useQueryClient();
    const oldNotifications = queryClient.getQueryData<GetMeasureNotificationsResponseDto>(NotificationQueryKeys.forMeasure(measureId));

    return useQuery({
        queryKey: NotificationQueryKeys.forMeasure(measureId),
        queryFn: getNotificationsQuery,
        onSuccess: (response) => {
            onSuccessCallback?.(response, oldNotifications);
        },
    });
};

export const useCreateMeasureNotification = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ measureId, userId }: CreateNotificationInputDto) => apiPost(getNotificationUrlByMeasureId(measureId), { userId }),
        onSuccess: (_, { measureId }) => {
            // Use fetchQuery here (instead of query invalidation) to be able to define a new instance of the query function and add meta
            // data to it. This meta information is used to skip the onSuccess callback.
            queryClient.fetchQuery(NotificationQueryKeys.forMeasure(measureId), getNotificationsQuery, { meta: { skipOnSuccess: true } });
        },
    });
};

export const useUpdateMeasureNotification = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ measureId, userId, action }: UpdateNotificationInputDto) =>
            apiPatch(getNotificationUrlByMeasureId(measureId), { userId, action }),
        onSuccess: (_, { measureId }) => {
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(measureId));
        },
    });
};
