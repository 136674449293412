import { styled } from "@mui/material";

const SummaryIcon = styled("div", { shouldForwardProp: (name) => name !== "backgroundColor" })<{ backgroundColor: string }>(
    ({ theme, backgroundColor }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderRadius: "50%",
        backgroundColor,
    }),
);

export default SummaryIcon;
