import { MeasureDto } from "api-shared";
import React, { PropsWithChildren } from "react";

const MeasureContext = React.createContext<IMeasureContext | undefined>(undefined);

export const MeasureContextProvider = ({ measure, children }: PropsWithChildren<IMeasureContext>) => {
    const value = React.useMemo(() => ({ measure }), [measure]);
    return <MeasureContext.Provider value={value}>{children}</MeasureContext.Provider>;
};

interface IMeasureContext {
    measure: MeasureDto;
}

/**
 * Use the measure provided by the surrounded MeasureContext. Throws an error if used outside of a MeasureContext.
 *
 * @returns {IMeasureContext}
 */
export const useMeasureContext = (): MeasureDto => {
    const context = React.useContext(MeasureContext);
    if (context === undefined) {
        throw new Error("Measure context not defined");
    }
    return context.measure;
};

/**
 * Use the measure provided by the surrounded MeasureContext. Returns undefined if used outside of a MeasureContext.
 *
 * @returns {IMeasureContext}
 */
export const useUnsafeMeasureContext = (): MeasureDto | undefined => {
    const context = React.useContext(MeasureContext);
    return context?.measure;
};
