import ChevronLeftRounded from "@mui/icons-material/ChevronLeftRounded";
import {
    Checkbox,
    Chip,
    Collapse,
    Container,
    Link as ExternalLink,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import {
    ErrorConstantKeys,
    IdentityProviderType,
    LAST_LOGIN_PROVIDER_EMAIL_STORAGE_KEY,
    LOGIN_REFERRER_KEY,
    TwoFactorAuthenticationStatus,
} from "api-shared";
import { TFunction } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { z } from "zod";
import ErrorBanner from "../../components/ErrorBanner";
import Form from "../../components/Form";
import Link from "../../components/Link";
import LoadingButton from "../../components/LoadingButton";
import PrivacyPolicyLink from "../../components/PrivacyPolicyLink";
import SplitLayout from "../../components/SplitLayout";
import EmailInput from "../../components/input/EmailInput";
import PasswordInput from "../../components/input/PasswordInput";
import { useLoginProvider } from "../../domain/login-provider";
import { useForegroundLogin } from "../../hooks/useBackgroundTabs";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { LoginData } from "../../lib/api";
import { deleteTwoFactorAuthenticationRemember, getTwoFactorAuthenticationRemember, useLoginMutation } from "../../lib/authentication-saga";
import { getTranslationKeyForError } from "../../lib/error";
import { ExternalRoutes, RouteFor } from "../../lib/routes";
import background from "../../static/images/login_illustration.svg";
import logo from "../../static/images/logo_blau.svg";
import { translationKeys } from "../../translations/main-translations";

const zLoginLocationState = z.object({ referrer: z.string().min(1) });

function useLoginReferrer(isLoggedIn: boolean): string | null {
    const location = useLocation();

    const locationState = zLoginLocationState.safeParse(location.state);

    // Look at two places for a URL to redirect after a successful login:
    // a) location.state from react-router if in-app navigation has brought the user here
    // b) localStorage for referrers persisted to survive non-router navigation, e.g. after successful SSO login or after logout
    const referrer = locationState.success ? locationState.data.referrer : localStorage.getItem(LOGIN_REFERRER_KEY);

    useEffect(() => {
        // Cleanup localStorage on unmount, when the user was logged in. In that case, the navigation has already started and the referrer
        // is not needed anymore in localStorage
        // Keep the referrer when the user was not logged in, so it will be available next time (e.g. after successful SSO redirect)
        return () => {
            if (isLoggedIn) {
                localStorage.removeItem(LOGIN_REFERRER_KEY);
            }
        };
    }, [isLoggedIn]);
    return referrer;
}

enum LoginPageStates {
    ENTER_EMAIL,
    ENTER_PASSWORD,
    ENTER_TFA_TOKEN,
}

const LogoContainer = styled("div")(({ theme }) => ({
    height: 0,
    flexGrow: 1,
    display: "flex",
    [theme.breakpoints.down("md")]: {
        justifyContent: "center",
    },
}));

const FormContainer = styled("div")(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
        textAlign: "center",
    },
}));

const Footer = styled(Grid)(({ theme }) => ({
    flexGrow: 1,
    paddingBottom: theme.spacing(3),
}));

const MailChip = styled(Chip)(({ theme }) => ({
    padding: theme.spacing(0.5, 2, 0.5, 1),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
}));

const LoginButton = styled(LoadingButton)(({ theme }) => ({
    margin: theme.spacing(4, 0, 2),
}));

const Image = styled("img")({
    width: 256,
    height: "100%",
});

function extractSSOError(searchParams: URLSearchParams, translate: TFunction) {
    const error = searchParams.get("error");
    if (error == null) {
        return null;
    }
    const { message, details } = JSON.parse(error);
    return translate(getTranslationKeyForError(message), details);
}

interface LoginPageProps {
    isLoggedIn: boolean;
}

function LoginPage({ isLoggedIn }: Readonly<LoginPageProps>) {
    const lastLoginProviderEmail = localStorage.getItem(LAST_LOGIN_PROVIDER_EMAIL_STORAGE_KEY);

    const [email, setEmail] = useState(lastLoginProviderEmail ?? "");
    const [pageState, setPageState] = useState(
        lastLoginProviderEmail != null ? LoginPageStates.ENTER_PASSWORD : LoginPageStates.ENTER_EMAIL,
    );
    const [password, setPassword] = useState("");
    const [twoFactorAuthenticationToken, setTwoFactorAuthenticationToken] = useState<string>();
    const [twoFactorAuthenticationRemember, setTwoFactorAuthenticationRemember] = useState<boolean>();

    const { t: translate } = useTranslation();
    const [searchParams] = useSearchParams();

    const referrer = useLoginReferrer(isLoggedIn);

    const loginProviderQuery = useLoginProvider({
        email,
        onSuccess: ({ type }) => {
            if (type === IdentityProviderType.Password) {
                return;
            }

            if (referrer != null) {
                // Persist referrer because a non-router navigation is about to happen
                localStorage.setItem(LOGIN_REFERRER_KEY, referrer);
            }

            // Use browser redirects instead of react-router ones
            if (type === IdentityProviderType.SAML) {
                window.location.replace(`/api/authentication/login/auth/saml/?email=${encodeURIComponent(email)}`);
            } else if (type === IdentityProviderType.GitHub) {
                window.location.replace("/api/authentication/login/auth/github/");
            }
        },
        onError: () => {
            setPageState(LoginPageStates.ENTER_EMAIL);
        },
        enabled: pageState === LoginPageStates.ENTER_PASSWORD,
        // do not report errors to sentry, this may happen easily if email has been mistyped
        meta: { ignoreErrors: true },
    });

    const loginMutation = useLoginMutation();

    const isEmailValid = isEmail(email.trim());
    const isPasswordValid = password.length > 0;
    const isLoginValid = isEmailValid && isPasswordValid;

    const passwordEnabled =
        pageState === LoginPageStates.ENTER_PASSWORD &&
        loginProviderQuery.isSuccess &&
        loginProviderQuery.data.type === IdentityProviderType.Password;

    useForegroundLogin();

    useDocumentTitle(translationKeys.VDLANG_LOGIN_TITLE);

    const onEmailEntered = useCallback((event?: React.MouseEvent<HTMLButtonElement>) => {
        setPageState(LoginPageStates.ENTER_PASSWORD);

        event?.stopPropagation();
        event?.preventDefault();
    }, []);

    function onMailBack(event: React.MouseEvent<HTMLDivElement>) {
        localStorage.removeItem(LAST_LOGIN_PROVIDER_EMAIL_STORAGE_KEY);
        setPageState(LoginPageStates.ENTER_EMAIL);
    }

    function handleLogin(tfaToken: string | undefined) {
        if (isLoginValid && !loginMutation.isLoading /* Prevent race condition on enter and auto submit */) {
            localStorage.setItem(LAST_LOGIN_PROVIDER_EMAIL_STORAGE_KEY, email);

            // Use remember me key if exists
            const rememberKey = getTwoFactorAuthenticationRemember(email);
            const token = rememberKey ?? tfaToken;

            loginMutation.mutate(new LoginData(email, password, token, twoFactorAuthenticationRemember), {
                onSuccess: (response) => {
                    if (response.twoFactorAuthenticationActivated === TwoFactorAuthenticationStatus.Enabled) {
                        setPageState(LoginPageStates.ENTER_TFA_TOKEN);
                    }
                },
                onError: (error: any) => {
                    if ("message" in error && error.message === "VDERROR_INCORRECT_TFA_TOKEN") {
                        deleteTwoFactorAuthenticationRemember(email);
                        setPageState(LoginPageStates.ENTER_TFA_TOKEN);
                    }
                },
            });
        }
    }

    function onSubmit(event?: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) {
        handleLogin(twoFactorAuthenticationToken);

        if (event !== undefined) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    function changeTwoFactorAuthenticationToken(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const newToken = event.target.value;
        setTwoFactorAuthenticationToken(newToken);
        if (newToken.length === 6) {
            handleLogin(newToken);
        }
    }

    if (isLoggedIn) {
        return <Navigate to={referrer ?? "/"} />;
    }

    const ssoError = extractSSOError(searchParams, translate);

    return (
        <SplitLayout image={background}>
            <Container fixed maxWidth="xs" sx={{ height: "100%" }}>
                <Stack sx={{ height: "100%" }}>
                    <LogoContainer>
                        <Image src={logo} alt="Valuedesk" />
                    </LogoContainer>
                    <FormContainer>
                        {pageState === LoginPageStates.ENTER_PASSWORD || pageState === LoginPageStates.ENTER_EMAIL ? (
                            <Typography variant="h4" sx={{ mb: 4 }}>
                                {translate(translationKeys.VDLANG_LOGIN_TITLE)}
                            </Typography>
                        ) : null}
                        {pageState === LoginPageStates.ENTER_PASSWORD ? (
                            <MailChip label={email} variant="outlined" icon={<ChevronLeftRounded />} onClick={onMailBack} clickable />
                        ) : null}
                        {pageState === LoginPageStates.ENTER_PASSWORD || pageState === LoginPageStates.ENTER_EMAIL ? (
                            <Form onSubmit={onSubmit}>
                                <FormGroup>
                                    {!passwordEnabled ? (
                                        <EmailInput
                                            autoFocus
                                            value={email}
                                            size="extralarge"
                                            onChange={(event) => setEmail(event.target.value)}
                                        />
                                    ) : null}
                                    <Collapse in={passwordEnabled}>
                                        <PasswordInput
                                            size="extralarge"
                                            value={password}
                                            onChange={(event) => setPassword(event.target.value)}
                                        />
                                    </Collapse>
                                </FormGroup>
                                {loginProviderQuery.isError && (
                                    <ErrorBanner>
                                        {translate(getTranslationKeyForError(ErrorConstantKeys.VDERROR_BAD_AUTHENTICATION))}
                                    </ErrorBanner>
                                )}
                                {loginMutation.isError ? (
                                    <ErrorBanner>{translate(translationKeys.VDLANG_LOGIN_INCORRECT_EMAIL_OR_PASSWORD)}</ErrorBanner>
                                ) : null}
                                {ssoError ? (
                                    <ErrorBanner>
                                        <>{ssoError}</>
                                    </ErrorBanner>
                                ) : null}
                                {!passwordEnabled ? (
                                    <LoginButton
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                        isLoading={loginProviderQuery.isFetching}
                                        disabled={!isEmailValid}
                                        onClick={onEmailEntered}
                                    >
                                        {translate(translationKeys.VDLANG_LOGIN_NEXT)}
                                    </LoginButton>
                                ) : (
                                    <LoginButton
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                        isLoading={loginMutation.isLoading}
                                        disabled={!isLoginValid}
                                        onClick={onSubmit}
                                    >
                                        {translate(translationKeys.VDLANG_LOGIN_BUTTON)}
                                    </LoginButton>
                                )}
                                {passwordEnabled ? (
                                    <Typography variant="body2" align="center" sx={{ mt: 2, mb: 4 }}>
                                        <Link to={RouteFor.user.passwordReset} state={email} color="textSecondary">
                                            {translate(translationKeys.VDLANG_LOGIN_PASSWORD_RESET)}
                                        </Link>
                                    </Typography>
                                ) : (
                                    <>
                                        <Typography align="center" color="textSecondary" variant="body2" sx={{ mt: 4 }}>
                                            {translate(translationKeys.VDLANG_LOGIN_NOT_REGISTERED)}
                                        </Typography>
                                        <Typography align="center" variant="body2">
                                            <ExternalLink href={ExternalRoutes.support}>
                                                {translate(translationKeys.VDLANG_LOGIN_REGISTER)}
                                            </ExternalLink>
                                        </Typography>
                                    </>
                                )}
                            </Form>
                        ) : null}
                        {pageState === LoginPageStates.ENTER_TFA_TOKEN ? (
                            <>
                                <Typography variant="h4">{translate(translationKeys.VDLANG_TFA_SETUP_ENTER_CODE)}</Typography>
                                <Typography color="textSecondary" variant="body2" sx={{ mb: 2 }}>
                                    {translate(translationKeys.VDLANG_TFA_SETUP_HINT_VALIDATE_TOKEN)}
                                </Typography>
                                <Form onSubmit={onSubmit}>
                                    <TextField
                                        label={translate(translationKeys.VDLANG_TFA_SETUP_ENTER_CODE)}
                                        size="extralarge"
                                        margin="none"
                                        value={twoFactorAuthenticationToken ?? ""}
                                        onChange={changeTwoFactorAuthenticationToken}
                                        name="token"
                                        fullWidth
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="rememberTfa"
                                                checked={twoFactorAuthenticationRemember}
                                                value={twoFactorAuthenticationRemember}
                                                onChange={(event) => setTwoFactorAuthenticationRemember(event.target.checked)}
                                            />
                                        }
                                        label={translate(translationKeys.VDLANG_TFA_REMEMBER_DEVICE)}
                                    />
                                </Form>
                                <LoginButton
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    isLoading={loginMutation.isLoading}
                                    disabled={twoFactorAuthenticationToken !== undefined && twoFactorAuthenticationToken.length < 6}
                                    onClick={onSubmit}
                                >
                                    {translate(translationKeys.VDLANG_LOGIN_NEXT)}
                                </LoginButton>
                                {loginMutation.isError ? (
                                    <ErrorBanner>{translate(translationKeys.VDLANG_LOGIN_INCORRECT_TFA_TOKEN)}</ErrorBanner>
                                ) : null}
                            </>
                        ) : null}
                    </FormContainer>
                    <Footer container direction="column-reverse">
                        <Typography align="center" variant="caption">
                            <PrivacyPolicyLink color="textSecondary" />
                        </Typography>
                    </Footer>
                </Stack>
            </Container>
        </SplitLayout>
    );
}

export default React.memo(LoginPage);
