import FileCopyRounded from "@mui/icons-material/FileCopyRounded";
import { ListItemIcon, ListItemText, MenuItem, styled } from "@mui/material";
import { FilterDto, SHORT_TEXT_MAX_CHARS } from "api-shared";
import { KeyboardEvent, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import DangerMenuItem from "../../../../components/DangerMenuItem";
import ActionItemDialog from "../../../../components/dialogues/ActionItemDialog";
import { baseDialogClasses } from "../../../../components/dialogues/BaseDialog";
import DeleteDialog from "../../../../components/dialogues/DeleteDialog";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import EditableTextInline from "../../../../components/input/EditableTextInline";
import Tooltip from "../../../../components/Tooltip";
import useDialog from "../../../../hooks/useDialog";
import { isEnterEvent } from "../../../../lib/keybindings";
import { translationKeys } from "../../../../translations/main-translations";

interface IFilterDialogProps {
    open: boolean;
    filterId: number | null;
    filterName: string;
    isFilterValid: boolean;
    isFilterDeletable: boolean;
    canFilterBeDuplicated: boolean;
    onSave: () => void;
    onChange: (name: string) => void;
    onDelete: () => void;
    onClose: () => void;
    onDuplicate: () => void;
    filters: FilterDto[];
}
const TitleEditableText = styled(EditableTextInline)(({ theme, disabled }) => ({
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
}));

const DialogWithReducedTitlePadding = styled(ActionItemDialog)(({ theme }) => ({
    [`& .${baseDialogClasses.title}`]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const DangerListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.error.dark,
}));

const FilterDialog = ({
    open,
    filterId,
    filterName,
    isFilterValid,
    isFilterDeletable,
    canFilterBeDuplicated,
    onSave,
    onChange,
    onDelete,
    onClose,
    onDuplicate,
    children,
    filters,
}: PropsWithChildren<IFilterDialogProps>) => {
    const deleteDialog = useDialog();
    const { t: translate } = useTranslation();

    const isNameLengthValid = filterName.trim().length < SHORT_TEXT_MAX_CHARS;
    const isNoDuplicate = !filters.some((fil) => fil.name.trim() === filterName.trim() && fil.id !== filterId);
    const isNameValid = isNoDuplicate && isNameLengthValid;

    const onKeyDown = (event: KeyboardEvent) => isEnterEvent(event) && isFilterValid && isNameValid && onSave();

    const getFilterNameField = () => {
        let errorText = "";
        if (!isNameLengthValid) {
            errorText = translate(translationKeys.VDLANG_FILTER_DIALOG_FILTER_NAME_TOO_LONG);
        }
        if (!isNoDuplicate) {
            errorText = translate(translationKeys.VDLANG_FILTER_DIALOG_FILTER_NAME_EXISTS);
        }

        return (
            <TitleEditableText
                defaultIsEditing={filterName === ""}
                variant="subtitle1"
                placeholder={`${translate(translationKeys.VDLANG_FILTER_DIALOG_PLACEHOLDER)}...`}
                onTextSave={onChange}
                onTextChange={onChange}
                resetTextOnEmptyBlur={false}
                text={filterName}
                onKeyDown={onKeyDown}
                color="textPrimary"
                errorText={errorText}
            />
        );
    };
    const handleDuplicateFilter = () => {
        onDuplicate();
        onClose();
    };

    const getMenuActions = () => {
        if (filterId === null) {
            return [];
        }

        return [
            <Tooltip
                key="duplicate"
                title={canFilterBeDuplicated ? null : translate(translationKeys.VDLANG_FILTER_DIALOG_FILTER_NAME_TOO_LONG)}
            >
                <span>
                    <MenuItem onClick={handleDuplicateFilter} disabled={!canFilterBeDuplicated}>
                        <ListItemIcon>
                            <FileCopyRounded />
                        </ListItemIcon>
                        <ListItemText>{translate(translationKeys.VDLANG_FILTER_DIALOG_DUPLICATE)}</ListItemText>
                    </MenuItem>
                </span>
            </Tooltip>,
            <Tooltip key="delete" title={isFilterDeletable ? null : translate(translationKeys.VDLANG_FILTER_DIALOG_FILTER_IN_USE)}>
                <span>
                    <DangerMenuItem key="delete_filter" disabled={!isFilterDeletable} onClick={deleteDialog.openDialog}>
                        <DangerListItemIcon>
                            <DeleteIcon />
                        </DangerListItemIcon>
                        <ListItemText>{translate(translationKeys.VDLANG_FILTER_DIALOG_REMOVE)}</ListItemText>
                    </DangerMenuItem>
                </span>
            </Tooltip>,
        ];
    };

    const handleDeleteFilter = () => {
        onDelete();
        onClose();
    };

    return (
        <DialogWithReducedTitlePadding
            open={open}
            onClose={onClose}
            primary={translationKeys.VDLANG_SAVE}
            onPrimary={onSave}
            primaryDisabled={!isFilterValid || !isNameValid || filterName.trim() === ""}
            translate={translate}
            maxWidth="md"
            scroll="paper"
            title={getFilterNameField()}
            menuActions={getMenuActions()}
        >
            <DeleteDialog
                item="filter"
                open={deleteDialog.isOpen}
                onClose={deleteDialog.close}
                onDelete={handleDeleteFilter}
                translate={translate}
            />
            {children}
        </DialogWithReducedTitlePadding>
    );
};

export default FilterDialog;
