import { useQuery } from "@tanstack/react-query";
import { MeasureHistoryListDto } from "api-shared";
import { apiGet } from "../lib/api";

export const processHistoryKeys = {
    all: ["measures", " history"] as const,
    forMeasure: (measureId: number) => ["measures", " history", measureId] as const,
};

export const useProcessHistory = (measureId: number) => {
    return useQuery({
        queryKey: processHistoryKeys.forMeasure(measureId),
        queryFn: ({ signal, queryKey }) => apiGet<MeasureHistoryListDto>(`measures/${queryKey[2]}/history`, { signal }),
    });
};
