import CloudUploadIcon from "@mui/icons-material/CloudUploadRounded";
import { ButtonBase, SvgIconProps, Typography, alpha, styled } from "@mui/material";
import { ImageMimeTypes, nonNullable } from "api-shared";
import { TFunction } from "i18next";
import { HTMLProps, ReactNode } from "react";
import { ErrorCode, useDropzone } from "react-dropzone";
import { MAX_UPLOAD_SIZE } from "../lib/api";
import { formatSize } from "../lib/formatters";
import uploadNewFileIcon from "../static/images/upload_new_file.svg";
import { translationKeys } from "../translations/main-translations";

const classes = {
    icon: "Vd-UploadInput-icon",
};

const RootButtonBase = styled(ButtonBase, {
    shouldForwardProp: (name) => name !== "isDragAccept" && name !== "isDragReject",
})<{ isDragAccept: boolean; isDragReject: boolean }>(({ theme, isDragAccept, isDragReject }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2.5),
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: theme.spacing(1),
    [isDragAccept ? "&, &:hover" : "&:hover"]: {
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    },
    ...(isDragReject && {
        borderColor: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, theme.palette.action.hoverOpacity),
    }),

    [`& .${classes.icon}`]: {
        width: theme.spacing(8),
        minHeight: theme.spacing(8), // use minimum because not all icons are squared
    },
}));

const translations: Partial<Record<ErrorCode, translationKeys | null>> = {
    [ErrorCode.FileInvalidType]: translationKeys.VDLANG_INVALID_FILETYPE,
    [ErrorCode.FileTooLarge]: translationKeys.VDLANG_INVALID_FILESIZE_TOO_LARGE,
};

const UploadNewFileIcon = (props: HTMLProps<HTMLImageElement>) => <img src={uploadNewFileIcon} alt="" {...props} />;
const ReplaceFileIcon = (props: SvgIconProps) => <CloudUploadIcon color="primary" {...props} />;

// Filenames usually do not follow regular wording patterns, so allow them to break anywhere to avoid overflow
const Filename = styled("span")({
    overflowWrap: "anywhere",
});

interface IUploadInputProps {
    file: File | null;
    onlyImages?: boolean;
    translate: TFunction;
    updateFile: (newFile: File, isValid: boolean) => void;
    mimeTypes?: string | string[];
    customHelperText?: string | null;
    disabled?: boolean;
    className?: string;
}

const UploadInput = ({
    file,
    onlyImages,
    translate,
    updateFile,
    mimeTypes,
    customHelperText = null,
    disabled,
    className,
}: IUploadInputProps) => {
    const resolvedMimeTypes = onlyImages ? ImageMimeTypes : typeof mimeTypes === "string" ? [mimeTypes] : mimeTypes;
    // dropzone expects mimetypes to be mapped to allowed extensions, use an empty array as allowed extension to only rely on the mimetype
    const acceptMap = resolvedMimeTypes != null ? Object.fromEntries(resolvedMimeTypes.map((type) => [type, []])) : undefined;

    const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        accept: acceptMap,
        maxFiles: 1,
        multiple: false,
        maxSize: MAX_UPLOAD_SIZE,
        onDropAccepted: (files) => updateFile(files[0], true),
        onDropRejected: (fileRejections) => updateFile(fileRejections[0].file, false),
        disabled,
    });

    const placeholder = translate(
        onlyImages ? translationKeys.VDLANG_UPLOAD_SELECT_IMAGE : translationKeys.VDLANG_UPLOAD_DRAGDROP_DOCUMENT,
    );

    const IconComponent = file != null ? ReplaceFileIcon : UploadNewFileIcon;

    const isEmpty = file === null && fileRejections.length === 0;

    const errors = fileRejections.flatMap(({ file, errors }) => errors.map((error) => ({ error, file })));
    const hasTooManyFilesError = errors.some(({ error }) => error.code === ErrorCode.TooManyFiles);
    const otherErrors = errors
        .map(({ error, file }) => {
            const tKey = translations[error.code as ErrorCode];
            if (tKey == null) {
                return null;
            }
            return (
                <>
                    <Filename>{file.name}</Filename>
                    {`: ${translate(tKey)}`}
                </>
            );
        })
        .filter(nonNullable);

    const displayedErrors: (string | ReactNode)[] = customHelperText != null ? [customHelperText] : otherErrors;
    if (hasTooManyFilesError) {
        displayedErrors.unshift(translate(translationKeys.VDLANG_INVALID_FILECOUNT));
    }

    return (
        <RootButtonBase className={className} {...getRootProps()} isDragAccept={isDragAccept} isDragReject={isDragReject}>
            <input {...getInputProps()} />
            <IconComponent className={classes.icon} />
            {isEmpty ? <Typography color="textSecondary">{placeholder}</Typography> : null}
            {!isEmpty &&
                acceptedFiles.map((file) => (
                    <Typography key={file.name} color="textSecondary">
                        <Filename>{file.name}</Filename>
                        {` (${formatSize(file.size)})`}
                    </Typography>
                ))}
            {displayedErrors.map((message, index) => (
                <Typography key={typeof message === "string" ? message : index} color="error">
                    {message}
                </Typography>
            ))}
        </RootButtonBase>
    );
};
export default UploadInput;
