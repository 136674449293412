import { FormLabel, styled } from "@mui/material";
import { mergeCamelized } from "api-shared";
import { TFunction } from "i18next";
import { Trans } from "react-i18next";
import InfoIcon from "./icons/InfoIcon";

const Root = styled(FormLabel)(({ theme }) => ({
    lineHeight: "24px", // Height of the icon button next to the label
    marginBottom: theme.spacing(0.5),
}));

const getHint = (translate: TFunction, title: string, processName: string | undefined, clientName: string) => {
    const keys = [mergeCamelized(title, "hint")];
    if (processName != null) {
        keys.unshift(mergeCamelized(title, processName, "hint"));
    }
    const data = {
        processName: processName !== undefined ? translate(processName) : "",
        clientName,
        // if no translation is found return "" so in the next step the info icon can be hidden
        defaultValue: "",
    };

    // translate first only to check whether there is a hint inside of the translations
    const hasHint = translate(keys, data) !== "";
    if (!hasHint) {
        return null;
    }

    // Translate again, but this time use the Trans component, which is able to handle tags inside of the translation result, e.g. <strong>
    return (
        <InfoIcon
            title={
                <span>
                    <Trans t={translate} i18nKey={keys} values={data} />
                </span>
            }
        />
    );
};

interface IFieldLabelProps {
    className?: string;
    translate: TFunction;
    clientName: string;
    processName?: string;
    fieldTitle: string;
}

const FieldLabel = ({ className = "", translate, fieldTitle: title, processName, clientName }: IFieldLabelProps) => {
    const hint = getHint(translate, title, processName, clientName);
    const titles = [title];
    if (processName != null) {
        titles.unshift(mergeCamelized(titles[0], processName));
    }
    let label = translate(titles, { processName });
    // If we have a hint there should be a bit of space between the label and the "i"
    if (hint !== null) {
        label += " ";
    }

    return (
        <Root className={className}>
            {label}
            {hint}
        </Root>
    );
};

export default FieldLabel;
