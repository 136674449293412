import { TFunction } from "i18next";
import React from "react";
import { Language, translationKeys } from "../translations/main-translations";
import ActionItemDialog from "./dialogues/ActionItemDialog";
import MarkdownEditorUserMentionsMeasure from "./markdowneditor/MarkdownEditorUserMentionsMeasure";

interface DescriptionDialogProps {
    open: boolean;
    value: string;
    updateValue: (value: string) => void;
    onClose: () => void;
    disabled: boolean;
    lang: Language;
    translate: TFunction;
}

function DescriptionDialog({ open, value, updateValue, onClose, disabled, translate }: Readonly<DescriptionDialogProps>) {
    const [description, setDescription] = React.useState<string | null>(null);
    const [isInputDirty, setIsInputDirty] = React.useState(false);

    const displayValue = description ?? value;

    function onPrimary() {
        updateValue(displayValue);
        setIsInputDirty(false);
        onClose();
    }

    function handleChange() {
        if (!isInputDirty) {
            setIsInputDirty(true);
        }
    }

    function handleClose() {
        setDescription(null);
        setIsInputDirty(false);
        onClose();
    }

    return (
        <ActionItemDialog
            open={open}
            onPrimary={onPrimary}
            onClose={handleClose}
            primaryDisabled={disabled || !isInputDirty}
            primary={translate(translationKeys.VDLANG_SAVE)}
            primaryIsTranslated
            action="edit"
            item="description"
            maxWidth="lg"
            translate={translate}
        >
            <MarkdownEditorUserMentionsMeasure
                value={displayValue}
                updateValue={setDescription}
                onChange={handleChange}
                disabled={disabled}
                fullHeight
            />
        </ActionItemDialog>
    );
}

export default DescriptionDialog;
