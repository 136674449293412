import { AppBar, Grid, styled } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useCurrentUser } from "../../domain/users";
import { useIsDesktop } from "../../lib/mobile";
import DesktopNavbarContent from "./DesktopNavbarContent";
import MobileNavbarContent from "./MobileNavbarContent";
import NavbarLogo from "./NavbarLogo";

const RootAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.valueBlue.main,
}));

const ContainerGrid = styled(Grid)(({ theme }) => ({
    minHeight: theme.spacing(8),
    transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("md")]: {
        minHeight: theme.spacing(7.5),
        justifyContent: "space-between",
        transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

const Navbar = () => {
    const [searchKey, setSearchKey] = useState("");

    const { t: translate } = useTranslation();
    const currentUser = useCurrentUser();

    const isDesktop = useIsDesktop();

    const location = useLocation();

    const ContentComponent = isDesktop ? DesktopNavbarContent : MobileNavbarContent;

    return (
        <RootAppBar>
            <ContainerGrid container alignItems="center" wrap="nowrap">
                <Grid item style={{ alignSelf: "stretch" }}>
                    <NavbarLogo />
                </Grid>
                <ContentComponent
                    searchKey={searchKey}
                    location={location}
                    translate={translate}
                    currentUser={currentUser}
                    disabled={currentUser == null}
                    updateSearchKey={setSearchKey}
                />
            </ContainerGrid>
        </RootAppBar>
    );
};

export default React.memo(Navbar);
