import { CostLeverDto, Language, MethodDetailDto, TreeNodeDto, UserDto, ValueLeverDto } from "api-shared";
import { translateFromProperty } from "./translate";

type CostLeverCompare<T> = (a: T, b: T) => number;

/**
 * Return a compare function, that defines an order on the costlevers depending on the given property.
 *
 * The costlever names look like "PD-1 xyz". accessor is used to map input elements to a string on which the code (PD-1) is extracted.
 * Further comparison is done by @see compareMethodByCode
 *
 * @export
 * @param {string} accessor
 * @returns {CostLeverCompare}
 */
export function getCostLeverCompareByProperty<T>(accessor: keyof T): CostLeverCompare<T> {
    return (a, b) => {
        const aString = a != null ? String(a[accessor]) : "";
        const bString = b != null ? String(b[accessor]) : "";
        const aCode = aString.substring(0, aString.indexOf(" "));
        const bCode = bString.substring(0, bString.indexOf(" "));
        return compareMethodByCode(aCode, bCode);
    };
}

/**
 * Defines an order on methods given by their code (e.g. PD-1).
 * Methods will be ordered by MethodType (e.g. PD) and by their ID (e.g. 1).
 * @export
 * @param {(MethodDetailDto | CostLeverDto | string)} a CostLever or its code (e.g. PD-1)
 * @param {(MethodDetailDto | CostLeverDto | string)} b CostLever or its code (e.g. PD-1)
 * @returns {number}
 */
export function compareMethodByCode(a: MethodDetailDto | CostLeverDto | string, b: MethodDetailDto | CostLeverDto | string): number {
    const aCode = typeof a === "string" ? a : a.code;
    const bCode = typeof b === "string" ? b : b.code;

    const [aType, aId] = aCode.split("-");
    const [bType, bId] = bCode.split("-");

    const typeCompare = aType.localeCompare(bType);
    const clampedTypeCompare = typeCompare > 0 ? 1 : typeCompare < 0 ? -1 : 0;

    const idCmopare = Number(aId) - Number(bId);
    const clampedIdCompare = idCmopare > 0 ? 1 : idCmopare < 0 ? -1 : 0;

    return clampedTypeCompare * 10 + clampedIdCompare; // sort by type first, then by id
}

/**
 * Comparator for ValueLevers. Sorts them in order as on the method wall.
 *
 * @export
 * @param {ValueLeverDto} a
 * @param {ValueLeverDto} b
 * @returns {number}
 */
export function compareValueLever(a: ValueLeverDto, b: ValueLeverDto): number {
    return a.order - b.order;
}

/**
 * TODO: check, if this is the right way to do this?
 * @param a
 * @param b
 * @returns
 */
export function compareUsersByRealName(a: UserDto | null, b: UserDto | null): number {
    return compareNullableStrings(a != null ? a.realname : "", b != null ? b.realname : "");
}

export function compareUsersByDisplayName(a: UserDto | null, b: UserDto | null): number {
    return compareNullableStrings(a?.displayname ?? "", b?.displayname ?? "");
}

function compareNullableStrings(a: string | null, b: string | null): number {
    if (a == null) {
        return b == null ? 0 : -1;
    }
    return b == null ? 1 : a.localeCompare(b);
}

export function compareStringsByNumericValue(a: string, b: string): number {
    return +a - +b;
}

export function sortTreeNodes(treeNodes: TreeNodeDto[], language: Language, compareFn: (a: string, b: string) => number): TreeNodeDto[] {
    const sortedTreeNodes = [...treeNodes].sort((a, b) => {
        // sort tree nodes by order
        if (a.order < b.order) {
            return -1;
        } else if (a.order > b.order) {
            return 1;
        } else {
            // if order is the same, sort by name
            return compareFn(translateFromProperty(a, "name", language), translateFromProperty(b, "name", language));
        }
    });

    return sortedTreeNodes.map((treeNode) => ({
        ...treeNode,
        children: treeNode.children ? sortTreeNodes(treeNode.children, language, compareFn) : [],
    }));
}
