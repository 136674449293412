import { useQuery } from "@tanstack/react-query";
import { AttributeTable, TreeNodeDto, TreeNodeListDto } from "api-shared";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { apiGet } from "../lib/api";
import { memoizedGetPaths, renderPath } from "../lib/tree";
import { registerFieldData } from "./field-data";

const TREE_NODES_PATH = "tree-nodes";

export const TreeNodeQueryKeys = {
    all: [TREE_NODES_PATH] as const,
};

export const useTreeNodeQuery = (enabled = true) => {
    const dispatch = useDispatch();
    return useQuery({
        queryKey: TreeNodeQueryKeys.all,
        queryFn: ({ signal }) => apiGet<TreeNodeListDto>(TREE_NODES_PATH, { signal }),
        enabled,
        onSuccess: (response) => {
            dispatch(registerFieldData(AttributeTable.TreeNodes, response));
        },
    });
};

export type IndexedTreeNodePaths = Record<number, { nameDe: string; nameEn: string }>;

export const useIndexedTreeNodePaths = (): IndexedTreeNodePaths => {
    const query = useTreeNodeQuery();

    return useMemo(() => {
        if (!query.isSuccess) {
            return {};
        }

        const paths = memoizedGetPaths(query.data, true) as TreeNodeDto[][] | null;
        if (paths == null) {
            return {};
        }
        return Object.fromEntries(
            paths
                .filter((path) => path.length > 0)
                .map((path) => {
                    const { id } = path[path.length - 1];
                    const nameDe = renderPath(path, "nameDe");
                    const nameEn = renderPath(path, "nameEn");
                    return [id, { nameDe, nameEn }];
                }),
        );
    }, [query.isSuccess, query.data]);
};
