import { Suspense } from "react";
import { FEED_DEFAULT_PAGE_SIZE, FeedQueryKeys, useFeedInfinite } from "../../domain/feed";
import useInfiniteQueryPageReset from "../../hooks/useInfiniteQueryPageReset";
import useInfiniteScrollingState from "../../hooks/useInfiniteScrollingState";
import { trackEvent } from "../../infrastructure/tracking";
import FeedEntry from "./FeedEntry";
import FeedEntrySkeleton from "./FeedEntrySkeleton";

interface IFeedEntriesListParams {
    rootRef: React.Ref<unknown>;
    pageSize?: number;
}

const InfiniteFeedEntryList = ({ rootRef, pageSize }: IFeedEntriesListParams) => {
    const feedQuery = useFeedInfinite(pageSize);
    const hasNextPage = Boolean(feedQuery.hasNextPage);

    useInfiniteQueryPageReset(FeedQueryKeys.forInfinitePageSize(pageSize ?? FEED_DEFAULT_PAGE_SIZE));

    const { guardRef } = useInfiniteScrollingState({
        isFetching: feedQuery.isFetching,
        hasNextPage,
        loadPage: () => {
            feedQuery.fetchNextPage();
            trackEvent({ category: "Feed", action: "Feed Items Loaded" });
        },
        parentRef: rootRef,
    });
    const feedEntries = feedQuery?.data?.pages?.flatMap((page) => page.items) ?? [];

    return (
        <Suspense fallback={<FeedEntrySkeleton ref={guardRef} />}>
            {feedEntries.length > 0
                ? feedEntries.map((feedEntry) => <FeedEntry key={feedEntry.id} feedEntry={feedEntry} parentFeedPageSize={pageSize} />)
                : null}
            {hasNextPage ? <FeedEntrySkeleton ref={guardRef} /> : null}
        </Suspense>
    );
};

export default InfiniteFeedEntryList;
