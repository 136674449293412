import { alpha, ButtonProps, styled } from "@mui/material";
import { NavLink, NavLinkProps } from "react-router-dom";
import MobileNavButton from "./MobileNavButton";

const ActivatableMobileNavButton = styled(MobileNavButton)(({ theme }) => ({
    [`&.active`]: {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        color: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
            "@media (hover: none)": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            },
        },
    },
})) as typeof MobileNavButton;

const MobileNavLinkButton = (props: ButtonProps<typeof NavLink, NavLinkProps>) => (
    <ActivatableMobileNavButton component={NavLink} {...props} />
);

export default MobileNavLinkButton;
