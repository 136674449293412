import { SubTaskGroupBy } from "api-shared";
import { TFunction } from "i18next";
import { createSelectHandler } from "../../../components/input/select/Select";
import { ISelectProps, Option, ValueHandler } from "../../../components/input/select/types";
import SidebarSelect from "../../../components/sidebar/SidebarSelect";
import { translationKeys } from "../../../translations/main-translations";

const labelsMap = new Map([
    [SubTaskGroupBy.MEASURE, translationKeys.VDLANG_PROCESS],
    [SubTaskGroupBy.CREATED_BY, translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_CREATED_BY],
    [SubTaskGroupBy.ASSIGNED_TO, translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_ASSIGNED_TO],
    [SubTaskGroupBy.DUEDATE, translationKeys.VDLANG_DUE_DATE],
    [SubTaskGroupBy.PRIORITY, translationKeys.VDLANG_PRIORITY],
]);

interface IActivitiesGroupBySelectProps<IsMulti extends boolean = false>
    extends Omit<ISelectProps<Option<SubTaskGroupBy>, IsMulti>, "options" | "onChange" | "value"> {
    value: SubTaskGroupBy;
    onChange: ValueHandler<SubTaskGroupBy, IsMulti>;
    translate: TFunction;
    canFilterByPerson: boolean;
}

const PERSON_FILTER_OPTIONS = [SubTaskGroupBy.CREATED_BY, SubTaskGroupBy.ASSIGNED_TO];

const ActivitiesGroupBySelect = ({ value, onChange, translate, canFilterByPerson, ...selectProps }: IActivitiesGroupBySelectProps) => {
    const options = [...labelsMap.entries()]
        .filter((entry) => canFilterByPerson || !PERSON_FILTER_OPTIONS.includes(entry[0]))
        .map(([optionValue, translationKey]) => ({
            value: optionValue,
            label: translate(translationKey),
        }));
    const selectedOption = options.find((o) => o.value === value);
    return <SidebarSelect value={selectedOption} options={options} onChange={createSelectHandler(onChange)} {...selectProps} />;
};

export default ActivitiesGroupBySelect;
