import { useTranslation } from "react-i18next";
import { useClientName } from "../domain/client";
import {
    filterFieldOptions,
    getRawFieldOptions,
    sortFieldOptions,
    TranslatedOption,
    translateFieldOptions,
    useDataForTableName,
} from "../lib/field-options";
import { Field } from "../lib/fields";
import { useFlatTree } from "../lib/tree";
import { useLanguage } from "./useLanguage";
import usePrevious from "./usePrevious";

interface IUseFieldDataConfig {
    sort?: boolean;
    /**
     * For tree fields, optionally render the full tree path for each leaf value
     */
    fullTreePathValues?: boolean;
}

/**
 * Resolve the available options for each given field, according to the field's specification.
 *
 * Adding/Removing fields will result in an error, so guarantee that subsequently called hook work.
 *
 * ATTENTION: This calls hooks in a loop, which is generally a bad idea:
 * https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 *
 * BUT: The hook ensures that the amount, in which hooks are called, is stable.
 *
 * @param {Field[]} fields
 * @returns {TranslatedOption[][]}
 */
const useFieldData = (fields: Field[], { sort, fullTreePathValues }: IUseFieldDataConfig = {}): TranslatedOption[][] => {
    const previousFields = usePrevious(fields);

    if (previousFields.length !== fields.length) {
        throw new Error("Changing the number of fields is not supported");
    }
    const { t: translate } = useTranslation();
    const lang = useLanguage();

    const clientName = useClientName();

    const data: TranslatedOption[][] = [];
    // use sequential loop here to be safe that all hooks are called in the same order on every render
    for (const field of fields) {
        // linting does not allow calling hooks in a loop, but we ensure that hooks are always called in the same order and amount
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const storeOptions = useDataForTableName(field.tableName);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const treeOptions = useFlatTree(field.type, storeOptions, fullTreePathValues);
        const rawOptions = getRawFieldOptions(storeOptions as any, treeOptions as any, field, clientName);
        const optionsFiltered = filterFieldOptions(rawOptions, field, null);
        const translatedOptions = translateFieldOptions(optionsFiltered, field, lang, translate);
        const options = sort ? sortFieldOptions(translatedOptions, field) : translatedOptions;
        data.push(options);
    }

    return data;
};
export default useFieldData;
