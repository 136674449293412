import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import useDialog from "../../../hooks/useDialog";
import { translationKeys } from "../../../translations/main-translations";
import ActivitiesCSVExportDialog from "./ActivitiesCSVExportDialog";

interface ActivitiesCSVExportButtonProps {
    className?: string;
}

export function ActivitiesCSVExportButton({ className }: Readonly<ActivitiesCSVExportButtonProps>) {
    const { t: translate } = useTranslation();
    const dialog = useDialog();

    return (
        <div className={className}>
            {dialog.isOpen ? ( // Render the dialog only, if it is open.
                // The dialog uses the useFieldData hook, so which does not support changing the fields
                // so mount/unmount the dialog when open changes
                <ActivitiesCSVExportDialog open onClose={dialog.close} />
            ) : null}
            <Button fullWidth onClick={dialog.open}>
                {translate(translationKeys.VDLANG_EXPORT_CSV)}
            </Button>
        </div>
    );
}
