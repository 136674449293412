import type { MomentInput } from "moment";
import moment from "moment-timezone";
import { useCallback } from "react";
import { useCurrentClient } from "../domain/client";
import { useCurrentUser } from "../domain/users";
import { Language } from "../translations/main-translations";

export type DateTimeFormatter = (date: MomentInput, options?: IDateFormatOptions) => string;

export interface IUseTimezoneReturnValue {
    timezone: string;
    formatDateTime: DateTimeFormatter;
    formatMonthYear: DateTimeFormatter;
    formatShortDateTime: DateTimeFormatter;
    formatDate: DateTimeFormatter;
    formatTime: DateTimeFormatter;
}

export interface IDateFormatOptions {
    noTimezone?: boolean;
    fromNow?: boolean;
    locale?: Language;
}

/**
 * Its important to only input UTC dates into the functions returned by this hook.
 * The internal formatting applies the user timezone automatically if the noTimezone option is not set to true!
 */
function useTimezone(): IUseTimezoneReturnValue {
    const currentUser = useCurrentUser();
    const currentClient = useCurrentClient();
    const timezone = currentUser?.timezone ?? currentClient.timezone ?? moment.tz.guess();

    const format = useCallback(
        (date: MomentInput, format: string, options?: IDateFormatOptions) => {
            const resolvedOptions = {
                noTimezone: false,
                fromNow: false,
                ...options,
            };
            let m = moment.utc(date);
            if (!resolvedOptions.noTimezone) {
                m = m.tz(timezone);
            }
            if (resolvedOptions.locale) {
                m = m.locale(resolvedOptions.locale);
            }
            return resolvedOptions.fromNow ? m.fromNow() : m.format(format);
        },
        [timezone],
    );

    /**
     * Print beautiful formatted date and time with month as a word
     */
    const formatDateTime = useCallback((date: MomentInput, options?: IDateFormatOptions) => format(date, "LLL", options), [format]);

    /**
     * Print beautiful formatted date
     */
    const formatDate = useCallback(
        (date: MomentInput, options?: IDateFormatOptions) => {
            return format(date, "L", options);
        },
        [format],
    );

    /**
     * Print beautiful formatted month and year
     */
    const formatMonthYear = useCallback(
        (date: MomentInput, options?: IDateFormatOptions) => {
            return format(date, "MM-YYYY", options);
        },
        [format],
    );

    /**
     * Print beautiful formatted time
     */
    const formatTime = useCallback(
        (date: MomentInput, options?: IDateFormatOptions) => {
            return format(date, "LT", options);
        },
        [format],
    );
    /**
     * Print beautiful formatted date and time in a short format
     */
    const formatShortDateTime = useCallback(
        (date: MomentInput, options?: IDateFormatOptions) => {
            const formattedDate = formatDate(date, options);
            const formattedTime = formatTime(date, options);
            return `${formattedDate} ${formattedTime}`;
        },
        [formatDate, formatTime],
    );

    return {
        timezone,
        formatDateTime,
        formatShortDateTime,
        formatDate,
        formatMonthYear,
        formatTime,
    };
}
export default useTimezone;
