import { useQuery } from "@tanstack/react-query";
import {
    AclPermissions,
    FlatMeasureDto,
    GateTaskConfigDto,
    GetMeasureConfigsResponseDto,
    MeasureDto,
    SimplePermissionsDto,
    nonNullable,
} from "api-shared";
import { sortBy, uniq, uniqBy } from "lodash";
import { useDispatch } from "react-redux";
import { apiGet, apiPost } from "../lib/api";
import { translationKeys } from "../translations/main-translations";
import { registerFieldData } from "./field-data";

const MEASURE_CONFIG_PATH = "measure-configs";
export const MeasureConfigKeys = {
    all: [MEASURE_CONFIG_PATH] as const,
};

export const GateTaskConfigKeys = {
    all: ["gatetask-configs"] as const,
    entityPermissions: (input: QueryPermissions) => [...GateTaskConfigKeys.all, "entity", input] as const,
};

interface UseMeasureConfigsQueryOptions<T = GetMeasureConfigsResponseDto> {
    select?: (response: GetMeasureConfigsResponseDto) => T;
    enabled?: boolean;
    onSuccess?: (response: T) => void;
}

const useMeasureConfigsBaseQuery = <T = GetMeasureConfigsResponseDto>(
    { select, enabled, onSuccess }: UseMeasureConfigsQueryOptions<T> = { enabled: true },
) => {
    return useQuery({
        queryKey: MeasureConfigKeys.all,
        queryFn: ({ signal }) => apiGet<GetMeasureConfigsResponseDto>(MEASURE_CONFIG_PATH, { signal }),
        suspense: true,
        select,
        enabled,
        onSuccess,
    });
};

export const useMeasureConfigsQuery = (enabled = true) => {
    const dispatch = useDispatch();
    return useMeasureConfigsBaseQuery({
        enabled,
        onSuccess: (response) => {
            dispatch(registerFieldData("measure_configs", response));
            // already register gate_task_configs here as well, as onSuccess will not be triggered if a selector is provided
            dispatch(
                registerFieldData(
                    "gate_task_configs",
                    uniqBy(
                        response.flatMap((e) => e.gateTaskConfigs),
                        "id",
                    ),
                ),
            );
        },
    });
};

/* Selector Hooks */
export const useMeasureConfigs = () => {
    const measureConfigs = useMeasureConfigsQuery();
    return measureConfigs.isSuccess ? measureConfigs.data : [];
};

export const useMeasureConfigById = (id: number) => {
    const measureConfigsQuery = useMeasureConfigsQuery();
    return measureConfigsQuery.data?.find((measureConfig) => measureConfig.id === id);
};

export const useMeasureConfig = (measure: Pick<FlatMeasureDto, "measureConfigId">) => {
    return useMeasureConfigById(measure.measureConfigId);
};

export const useProcessName = (measure: Pick<FlatMeasureDto, "measureConfigId">) => {
    const measureConfig = useMeasureConfig(measure);
    return measureConfig?.name ?? translationKeys.VDLANG_UNKNOWN_PROCESS_NAME;
};

export const useEffectCategoryAttributes = () => {
    const measureConfigs = useMeasureConfigsQuery();
    return measureConfigs.isSuccess ? measureConfigs.data.flatMap(({ effectCategoryAttributes }) => effectCategoryAttributes) : [];
};

export const useEffectCategoryAttributesForMeasure = (measure: MeasureDto) => {
    const measureConfig = useMeasureConfig(measure);
    return measureConfig?.effectCategoryAttributes ?? [];
};

/* Gate Task Config Selector Hooks */
export const useGateTaskConfigs = (enabled = true) => {
    return (
        useMeasureConfigsBaseQuery({
            select: (response) => {
                const allGateTaskConfigs = response.flatMap((mc) => mc.gateTaskConfigs);
                // GateTaskConfigs can be duplicated if they are used in multiple measureConfigs
                return uniqBy(allGateTaskConfigs, "id");
            },
            enabled,
        }).data ?? []
    );
};

export const useHasSuccessorGateTaskConfig = (measureConfigId: number, gateTaskConfigId: number) => {
    const measureConfig = useMeasureConfigById(measureConfigId);
    const referenceGateTaskConfig = measureConfig?.gateTaskConfigs.find(({ id }) => id === gateTaskConfigId);
    if (referenceGateTaskConfig == null) {
        return false;
    }
    return measureConfig?.gateTaskConfigs.some(({ order }) => referenceGateTaskConfig.order < order);
};

export const usePredecessorGateTaskConfig = (measureConfigId: number, gateTaskConfigId: number) => {
    const measureConfig = useMeasureConfigById(measureConfigId);
    const gateTaskConfigs = measureConfig?.gateTaskConfigs;
    if (gateTaskConfigs == null) {
        return null;
    }
    const orderedGateTaskConfigs = gateTaskConfigs.toSorted((a, b) => b.order - a.order);
    const index = orderedGateTaskConfigs.findIndex((gtc) => gtc.id === gateTaskConfigId);
    return orderedGateTaskConfigs[index + 1]?.id ?? null;
};

export function getOrderedCalculationIdentifiers(gateTaskConfigs: GateTaskConfigDto[]) {
    const orderedGateTaskConfigs = sortBy(gateTaskConfigs, ["order"]);
    const calculationIdentifiers = orderedGateTaskConfigs.map((gtc) => gtc.calculationIdentifier).filter(nonNullable);
    // lodash uniq keeps order of input array, so calculationIdentifiers will be order like in the process
    return uniq(calculationIdentifiers);
}

interface QueryPermissions {
    permission: AclPermissions;
    measureConfigId: number;
}

export const useGetMeasureConfigsPermissions = ({
    permission,
    measureConfigId,
    enabled = true,
}: QueryPermissions & { enabled: boolean }) => {
    return useQuery({
        queryKey: GateTaskConfigKeys.entityPermissions({ permission, measureConfigId }),
        queryFn: ({ signal }) =>
            apiPost<SimplePermissionsDto>(`measure-configs/${measureConfigId}/permissions`, { permission }, { signal }),
        enabled,
    });
};
