import CheckIcon from "@mui/icons-material/CheckRounded";
import { Button, buttonClasses, styled } from "@mui/material";
import { SubTaskStatus } from "api-shared";
import { TFunction } from "i18next";
import PropTypes from "prop-types";
import { translationKeys } from "../../translations/main-translations";
import Tooltip from "../Tooltip";

const SubTaskCompleteButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.defaultDark.main}`,
    "&:hover": {
        border: `1px solid ${theme.palette.defaultDark.main}`,
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
            backgroundColor: "transparent",
        },
    },
    [`&.${buttonClasses.contained}.${buttonClasses.colorSecondary}`]: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.natureGreen.main,
        "&:hover": {
            backgroundColor: theme.palette.natureGreen.dark,
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                backgroundColor: theme.palette.natureGreen.main,
            },
        },
    },
}));

interface ISubtaskCloseButtonProps {
    status: SubTaskStatus;
    disabled: boolean;
    updateStatus: (newStatus: SubTaskStatus) => void;
    translate: TFunction;
}

const SubtaskCloseButton = ({ status, disabled, updateStatus, translate, ...buttonProps }: ISubtaskCloseButtonProps) => {
    const isCompleted = status === SubTaskStatus.STATUS_COMPLETED;
    const onClick = () => {
        if (typeof updateStatus === "function") {
            updateStatus(isCompleted ? SubTaskStatus.STATUS_OPEN : SubTaskStatus.STATUS_COMPLETED);
        }
    };
    return (
        <Tooltip title={isCompleted ? translate(translationKeys.VDLANG_ACTIVITIES_REOPEN_BUTTON_HINT) : ""}>
            <SubTaskCompleteButton
                startIcon={<CheckIcon />}
                variant={isCompleted ? "contained" : "outlined"}
                color={isCompleted ? "secondary" : "primary"}
                disabled={disabled}
                onClick={onClick}
                {...buttonProps}
            >
                {translate(
                    isCompleted
                        ? translationKeys.VDLANG_ACTIVITIES_REOPEN_BUTTON_LABEL
                        : translationKeys.VDLANG_ACTIVITIES_MARK_COMPLETED_BUTTON_LABEL,
                )}
            </SubTaskCompleteButton>
        </Tooltip>
    );
};

SubtaskCloseButton.propTypes = {
    status: PropTypes.oneOf(Object.values(SubTaskStatus).filter(Number.isInteger)),
    disabled: PropTypes.bool,
    updateStatus: PropTypes.func,
    translate: PropTypes.func.isRequired,
};
export default SubtaskCloseButton;
