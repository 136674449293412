import { Divider, FormGroup, Stack, Tab, Tabs, Typography, darken, styled, tabClasses, tabsClasses } from "@mui/material";
import {
    CostLeverDto,
    CreateIdeaRequestBody,
    CreateMeasureRequestBody,
    MeasureConfigDto,
    MethodDetailDto,
    zMinMaxString,
} from "api-shared";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import ActionItemDialog from "../../components/dialogues/ActionItemDialog";
import Form from "../../components/Form";
import Tooltip from "../../components/Tooltip";
import { useCurrentUserId } from "../../domain/users";
import { translationKeys } from "../../translations/main-translations";
import CallToActionTitleInput from "./CallToActionTitleInput";

const ActionConfigTabs = styled(Tabs)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    [`&.${tabsClasses.root}`]: {
        minHeight: theme.spacing(4.5),
    },
    [`& .${tabsClasses.indicator}`]: {
        display: "none",
    },
}));

const ActionConfigTab = styled(Tab)(({ theme }) => ({
    margin: theme.spacing(0, 0.5),
    minHeight: theme.spacing(4.5),
    // add divider between tabs, but not when selected tab is a sibling
    borderTop: `${theme.palette.divider} 1px solid`,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create("background-color", {
        duration: theme.transitions.duration.short,
    }),
    "&:first-child": {
        borderTop: "none",
    },
    [`&.${tabClasses.selected} + &`]: {
        // hide top border on tab after selected tab
        borderTop: "none",
    },
    "&:hover": {
        backgroundColor: darken(theme.palette.background.default, theme.palette.action.hoverOpacity),
    },
    [`&.${tabClasses.selected}`]: {
        color: theme.palette.primary.contrastText,
        borderRadius: theme.shape.borderRadius,
        background: `linear-gradient( 90deg, ${theme.palette.ocean.main}, ${theme.palette.primary.main})`,
        border: "none",
    },
}));

// In case of a disabled tab, we need to wrap it in a Tooltip to show the hint
// So we also need to delegate some styling to the wrapper below for consistency
const DisabledActionConfigTab = styled(Tab)(({ theme }) => ({
    minHeight: theme.spacing(4.5),
    minWidth: "100%",
    borderTop: `${theme.palette.divider} 1px solid`,
    borderRadius: theme.shape.borderRadius,
}));

// We need to wrap disabled tabs because Tooltip doesn't work directly on a disabled component
// The Tooltip unfortunately passes a bunch of non-DOM attributes from the Tabs component, so we need to stop forwarding them here
const TooltipChildWrapper = styled("div", {
    shouldForwardProp: (prop) => prop !== "fullWidth" && prop !== "indicator" && prop !== "textColor" && prop !== "selectionFollowsFocus",
})(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(0, 0.5),
    [`&:first-child > .${tabClasses.root}`]: {
        borderTop: "none",
    },
}));

const IdeaActionConfigTab = styled(Tab)(({ theme }) => ({
    margin: theme.spacing(0, 0.5),
    minHeight: theme.spacing(4.5),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create("background-color", {
        duration: theme.transitions.duration.short,
    }),
    borderTop: "none",
    "&:hover": {
        backgroundColor: darken(theme.palette.background.default, theme.palette.action.hoverOpacity),
    },
    [`&.${tabClasses.selected}`]: {
        minHeight: theme.spacing(4.5),
        color: theme.palette.primary.contrastText,
        borderRadius: theme.shape.borderRadius,
        background: `linear-gradient( 90deg, ${theme.palette.ocean.main}, ${theme.palette.primary.main})`,
        border: "none",
    },
}));

const StyledDivider = styled(Divider)({
    "&:before": {
        width: "50%",
    },
    "&:after": {
        width: "50%",
    },
});

interface CreateOption {
    optionConfigId: number;
    question: string;
    label: string;
    hint: string;
    optionType: OptionType;
}

interface CallToActionDialogProps {
    method?: CostLeverDto | MethodDetailDto;
    methodTitle?: string;
    onHide: () => void;
    createMeasure: (measure: CreateMeasureRequestBody) => void;
    translate: TFunction;
    show: boolean;
    measureConfigs: MeasureConfigDto[];
    createIdea?: (idea: CreateIdeaRequestBody) => void;
    initialMeasureTitle?: string;
    disabledMeasureConfigIds?: number[];
    disabledMeasureConfigHint?: string;
    userHasIdeaAccess: boolean;
    userCanCreateProcess: boolean;
}

enum OptionType {
    MEASURE,
    IDEA,
}

type ActiveOption = { type: OptionType.IDEA } | { type: OptionType.MEASURE; measureConfigId: number };

const IDEA_OPTION_ID = 1;
const hasValidTitle = (title: string) => zMinMaxString.safeParse(title).success;

function isValidSelection(title: string, activeStrategyType: number) {
    return hasValidTitle(title) && activeStrategyType !== 0;
}

function invalidTitleErrorMessage(title: string, translate: TFunction) {
    const validationResult = zMinMaxString.safeParse(title);
    if (validationResult.success) {
        return null;
    }
    const issues = validationResult.error.issues;
    if (issues[0].code === "too_small") {
        return translate(translationKeys.VDLANG_MEASURE_TITLE_EMPTY_ERROR);
    }
    if (issues[0].code === "too_big") {
        return translate(translationKeys.VDLANG_MEASURE_TITLE_TOO_LONG_ERROR);
    }
    return null;
}

const CallToActionDialog = ({
    method,
    onHide,
    createMeasure,
    methodTitle,
    translate,
    show,
    measureConfigs,
    createIdea,
    initialMeasureTitle,
    disabledMeasureConfigIds = [],
    disabledMeasureConfigHint,
    userCanCreateProcess,
    userHasIdeaAccess,
}: CallToActionDialogProps) => {
    const currentUserId = useCurrentUserId();
    const [activeOption, setActiveOption] = useState<ActiveOption>();

    const [title, setTitle] = useState(initialMeasureTitle ?? "");
    const [titleTouched, setTitleTouched] = useState(false);

    if (!show) {
        return null;
    }

    const defaultMeasureConfig = userCanCreateProcess && measureConfigs.length > 0 ? measureConfigs[0].id : undefined;

    const ideaOption: CreateOption = {
        label: translationKeys.VDLANG_CREATE_IDEA_LABELS,
        hint: `${translationKeys.VDLANG_CREATE_IDEA_LABELS_HINT}`,
        question: `${translationKeys.VDLANG_CREATE_IDEA_QUESTIONS}`,
        optionConfigId: IDEA_OPTION_ID,
        optionType: OptionType.IDEA,
    };

    const measureOptions: CreateOption[] = measureConfigs.map(({ id, name }) => ({
        label: `${translationKeys.VDLANG_CREATE_MEASURE_LABELS}.${name}`,
        hint: `${translationKeys.VDLANG_CREATE_MEASURE_HINTS}.${name}`,
        question: `${translationKeys.VDLANG_CREATE_MEASURE_QUESTIONS}.${name}`,
        optionConfigId: id,
        optionType: OptionType.MEASURE,
    }));

    const selectedMeasureConfig = activeOption?.type === OptionType.MEASURE ? activeOption.measureConfigId : defaultMeasureConfig;
    const selectedOption =
        activeOption?.type === OptionType.IDEA || defaultMeasureConfig === undefined
            ? ideaOption
            : measureOptions.find(({ optionConfigId }) => selectedMeasureConfig === optionConfigId);

    if (selectedOption === undefined) {
        // bad state selected
        return null;
    }

    const dialogTitle =
        method === undefined || activeOption?.type === OptionType.IDEA || methodTitle === undefined
            ? translate(selectedOption.label)
            : `${translate(selectedOption.label)} in ${methodTitle}`;

    const isValid =
        selectedOption.optionType === OptionType.MEASURE ? isValidSelection(title, selectedOption.optionConfigId) : hasValidTitle(title);

    const onMeasureTabChange = (event: React.SyntheticEvent, measureConfigId: string) => {
        setActiveOption({ type: OptionType.MEASURE, measureConfigId: Number(measureConfigId) });
    };

    const onIdeaTabChange = (event: React.SyntheticEvent) => {
        setActiveOption({ type: OptionType.IDEA });
    };

    const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
        setTitleTouched(true);
    };

    const reset = () => {
        setTitle("");
        setTitleTouched(false);
    };

    const create = () => {
        if (!isValid) {
            return;
        }

        if (selectedOption.optionType === OptionType.MEASURE) {
            const selectedMeasureConfig = measureConfigs.find(({ id }) => id === selectedOption.optionConfigId);
            if (!selectedMeasureConfig) {
                return;
            }

            const measure = {
                title: title.trim(),
                methodId: method != null ? method.id : undefined,
                measureConfigId: selectedMeasureConfig.id,
            };

            createMeasure(measure); // show feedback on creating an idea
        }

        if (selectedOption.optionType === OptionType.IDEA && currentUserId !== null) {
            const idea: CreateIdeaRequestBody = {
                title: title.trim(),
                description: "",
                ownerId: currentUserId,
            };
            createIdea?.(idea);
        }

        onHide();
        reset();
    };

    const cancel = () => {
        onHide();
        reset();
    };

    const errorText = titleTouched && !hasValidTitle(title) ? invalidTitleErrorMessage(title, translate) : null;
    const showDivider = userHasIdeaAccess && userCanCreateProcess && defaultMeasureConfig !== undefined && method === undefined;

    const ideaTabValue = selectedOption.optionType === OptionType.IDEA ? selectedOption.optionConfigId : false;
    const measureTabValue = selectedOption.optionType === OptionType.MEASURE ? selectedOption.optionConfigId : false;

    return (
        <ActionItemDialog
            open={show}
            onClose={cancel}
            action="create"
            primary={selectedOption.label}
            onPrimary={create}
            primaryDisabled={!isValid}
            title={dialogTitle}
            translate={translate}
        >
            <Stack spacing={2}>
                {userHasIdeaAccess && method === undefined ? (
                    <ActionConfigTabs
                        value={ideaTabValue}
                        onChange={onIdeaTabChange}
                        variant="fullWidth"
                        orientation="vertical"
                        textColor="inherit"
                    >
                        <IdeaActionConfigTab
                            label={translate(ideaOption.label)}
                            key={ideaOption.optionConfigId}
                            value={ideaOption.optionConfigId}
                        />
                    </ActionConfigTabs>
                ) : null}
                {showDivider ? (
                    <StyledDivider textAlign="center">
                        <Typography variant="body2" color="textSecondary">
                            {translate("or")}
                        </Typography>
                    </StyledDivider>
                ) : null}
                {userCanCreateProcess && defaultMeasureConfig !== undefined ? (
                    <ActionConfigTabs
                        value={measureTabValue}
                        onChange={onMeasureTabChange}
                        variant="fullWidth"
                        orientation="vertical"
                        textColor="inherit"
                    >
                        {measureOptions.map(({ label, optionConfigId: actionConfigId }) => {
                            if (disabledMeasureConfigIds.includes(actionConfigId)) {
                                return (
                                    <Tooltip key={actionConfigId} title={disabledMeasureConfigHint}>
                                        <TooltipChildWrapper>
                                            <DisabledActionConfigTab disabled label={translate(label)} />
                                        </TooltipChildWrapper>
                                    </Tooltip>
                                );
                            }
                            return <ActionConfigTab label={translate(label)} key={actionConfigId} value={actionConfigId} />;
                        })}
                    </ActionConfigTabs>
                ) : null}

                <Form onSubmit={create}>
                    <FormGroup>
                        <CallToActionTitleInput
                            title={title}
                            updateTitle={onTitleChange}
                            label={translate(selectedOption.question)}
                            isTitleError={errorText != null}
                            helperText={errorText}
                            placeholder={translate("Enter a title")}
                        />
                        <Typography
                            display="block"
                            sx={{ mt: 1, mb: 2, minHeight: (theme) => theme.spacing(5) }}
                            variant="caption"
                            color="textSecondary"
                        >
                            <Trans t={translate} i18nKey={selectedOption.hint} />
                        </Typography>
                    </FormGroup>
                </Form>
            </Stack>
        </ActionItemDialog>
    );
};

export default CallToActionDialog;
