import { formControlClasses, Popover, styled } from "@mui/material";
import { TFunction } from "i18next";
import { MultiValue, OnChangeValue } from "react-select";
import SelectStaticMenuContainer from "../../../components/input/select/components/SelectStaticMenu";
import Select from "../../../components/input/select/Select";
import SelectButton from "../../../components/input/select/SelectButton";
import { ISelectProps, Option, ValueHandler } from "../../../components/input/select/types";
import { useNonDeletedUsers } from "../../../domain/users";
import useMenu from "../../../hooks/useMenu";
import { formatUser } from "../../../lib/formatters";
import { translationKeys } from "../../../translations/main-translations";

interface IActivitiesUserSelectProps<IsMulti extends boolean = true>
    extends Omit<ISelectProps<Option<number>, IsMulti>, "options" | "value" | "onChange"> {
    value: number[];
    onChange: ValueHandler<number, IsMulti>;
    label: string;
    translate: TFunction;
    hasUnassignedUser: boolean;
    disabled?: boolean;
}

const SelectContainer = styled("div")(({ theme }) => ({
    width: theme.spacing(33),
    [`& .${formControlClasses.root}`]: {
        padding: theme.spacing(),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

const ActivitiesUserSelect = ({
    value,
    translate,
    onChange,
    label,
    hasUnassignedUser,
    className,
    disabled = false,
}: IActivitiesUserSelectProps) => {
    const { openMenu, menuProps } = useMenu();

    const users = useNonDeletedUsers();
    const unAssignedUser = {
        value: 0,
        label: translate(translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_UNASSIGNED_USER_OPTION),
    };
    const options = users.map((user) => ({
        value: user.id,
        label: formatUser(user, { translate }),
    }));
    hasUnassignedUser && options.unshift(unAssignedUser);
    const selectedOptions = options.filter((option) => value.includes(option.value));

    const updateValue = (newValue: MultiValue<Option<number>>) => {
        onChange(newValue.map((v) => v.value));
    };

    const handleChange = (value: OnChangeValue<Option, true>) => {
        updateValue(value);
    };

    return (
        <>
            <Popover {...menuProps}>
                <SelectContainer>
                    <Select
                        isClearable
                        isSearchable
                        menuIsOpen
                        isMulti
                        smallGutters
                        value={selectedOptions}
                        options={options}
                        onChange={handleChange}
                        placeholder={`${translate("Search")}...`}
                        components={{
                            Menu: SelectStaticMenuContainer,
                            DropdownIndicator: undefined,
                        }}
                    />
                </SelectContainer>
            </Popover>
            <SelectButton
                fullWidth
                label={translate(label)}
                count={selectedOptions.length}
                className={className}
                onClick={openMenu}
                sx={{ mt: 0.5, mb: 1.5 }}
                disabled={disabled}
            />
        </>
    );
};

export default ActivitiesUserSelect;
