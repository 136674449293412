import { cloneElement, ReactElement } from "react";
import useDebounceDimensions from "../hooks/useDebounceDimensions";

export interface IDebounceDimensionsProps {
    width?: number;
    height?: number;
    children: ReactElement;
}

/**
 * This component is intended to be used in combination with the ResponsiveContainer of recharts. It debounces updates to the injected
 * width and height props so that the ResizeObserver has enough time to finish processing its events and will not be interrupted by
 * re-rendering components
 *
 * @param {IDebounceDimensionsProps} { width, height, children }
 * @returns
 */
const DebounceDimensions = ({ width, height, children }: IDebounceDimensionsProps) => {
    const cachedDimensions = useDebounceDimensions(width, height);
    return cloneElement(children, cachedDimensions);
};

export default DebounceDimensions;
