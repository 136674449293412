import { useEffect, useState } from "react";

export const useDebounce = <T>(value: T, delay = 300) => {
    const [state, setState] = useState<T>(value);

    useEffect(() => {
        const timeout = setTimeout(() => setState(value), delay);
        return () => clearTimeout(timeout);
    }, [value, delay]);

    return state;
};
