import { InfiniteData, QueryFunctionContext, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    BasicMessageDto,
    FeedEntryDto,
    FeedEntryEmbeddedObjectType,
    FeedSummaryDto,
    FeedsDto,
    PostFeedEntryReactionBody,
    PostOrDeleteFeedEntryReactionParam,
} from "api-shared";
import queryString from "query-string";
import { apiDelete, apiGet, apiPost } from "../lib/api";
import { useCurrentUserId } from "./users";

const FEED_PATH = "feed";
const SUMMARY_PATH = "last";

interface FeedFilter {
    id: number;
    type?: FeedEntryEmbeddedObjectType;
}

export const FEED_DEFAULT_PAGE_SIZE = 5;
export const FeedQueryKeys = {
    all: [FEED_PATH] as const,
    summary: [FEED_PATH, SUMMARY_PATH] as const,
    forId: (id: number) => [...FeedQueryKeys.all, "byId", id] as const,
    forFilter: (filter: FeedFilter) => [...FeedQueryKeys.all, Number.MAX_SAFE_INTEGER, filter] as const,
    forInfinitePageSize: (pageSize: number, filterId?: number, filterType?: FeedEntryEmbeddedObjectType) =>
        [...FeedQueryKeys.all, { pageSize, filterId, filterType }] as const,
};

export const useFeedWithFilter = (filter: FeedFilter) => {
    return useQuery({
        queryKey: FeedQueryKeys.forFilter(filter),
        queryFn: ({ queryKey, signal }) => {
            const optId = queryKey[2] !== undefined ? `&filterId=${queryKey[2].id}` : "";
            const optType = queryKey[2]?.type !== undefined ? `&filterType=${queryKey[2].type}` : "";
            return apiGet<FeedsDto>(`${queryKey[0]}/?page=1&pageSize=${queryKey[1]}${optId}${optType}`, { signal });
        },
    });
};

export const useFeedInfinite = (pageSize = FEED_DEFAULT_PAGE_SIZE, filterId?: number, filterType?: FeedEntryEmbeddedObjectType) => {
    return useInfiniteQuery({
        queryKey: FeedQueryKeys.forInfinitePageSize(pageSize, filterId, filterType),
        queryFn: ({
            queryKey,
            pageParam = 1 as number,
            signal,
        }: QueryFunctionContext<ReturnType<typeof FeedQueryKeys.forInfinitePageSize>, number>) => {
            const params = {
                pageSize: queryKey[1].pageSize,
                page: pageParam,
                filterId,
                filterType,
            };
            return apiGet<FeedsDto>(`${queryKey[0]}/?${queryString.stringify(params)}`, { signal });
        },
        getNextPageParam: (lastPage: FeedsDto): number | undefined => {
            if (lastPage.page < lastPage.totalPages) {
                return lastPage.page + 1;
            }
        },
    });
};

export const useFeedEntry = (feedEntryId: number) => {
    return useQuery({
        queryKey: FeedQueryKeys.forId(feedEntryId),
        queryFn: ({ queryKey, signal }) => apiGet<FeedEntryDto>(`${queryKey[0]}/${queryKey[2]}`, { signal }),
        enabled: feedEntryId !== null,
    });
};

export const useFeedSummary = () => {
    return useQuery({
        queryKey: FeedQueryKeys.summary,
        queryFn: ({ queryKey, signal }) => apiGet<FeedSummaryDto>(`${queryKey[0]}/${queryKey[1]}`, { signal }),
    });
};

export const useFeedEntryReactionMutation = (updatePageSize = FEED_DEFAULT_PAGE_SIZE, filter?: FeedFilter) => {
    const queryClient = useQueryClient();
    const currentUserId = useCurrentUserId();

    return useMutation({
        mutationFn: ({ feedEntryId, type }: PostOrDeleteFeedEntryReactionParam & Partial<PostFeedEntryReactionBody>) => {
            const url = `${FEED_PATH}/${feedEntryId}/reactions`;
            return type ? apiPost<BasicMessageDto>(url, { type }) : apiDelete<BasicMessageDto>(url);
        },
        onSuccess: (_, { feedEntryId, type }) => {
            // Optimistically update the query data instead of triggering page-wise refetches
            queryClient.setQueryData<InfiniteData<FeedsDto>>(FeedQueryKeys.forInfinitePageSize(updatePageSize), (oldData) => {
                const newData = oldData?.pages?.map((page) => {
                    return {
                        ...page,
                        items: page.items.map((item) => {
                            if (item.id === feedEntryId) {
                                if (type !== undefined) {
                                    return {
                                        ...item,
                                        reactions: [
                                            ...item.reactions.filter((reaction) => reaction.userId !== currentUserId),
                                            { feedEntryId, type, userId: currentUserId },
                                        ],
                                    };
                                } else {
                                    return {
                                        ...item,
                                        reactions: item.reactions.filter((reaction) => reaction.userId !== currentUserId),
                                    };
                                }
                            } else {
                                return item;
                            }
                        }),
                    };
                });
                return {
                    ...oldData,
                    pages: newData,
                } as InfiniteData<FeedsDto>;
            });
            queryClient.invalidateQueries(FeedQueryKeys.forId(feedEntryId));
            if (filter !== undefined) {
                queryClient.invalidateQueries(FeedQueryKeys.forFilter(filter));
            }
        },
    });
};
