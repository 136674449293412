import { Chip, chipClasses, ChipProps, styled } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { SubTaskDto } from "api-shared";
import { useSubTaskLevel } from "../../../components/tasks/useSubtaskLevel";

interface IActivityLevelProps extends ChipProps {
    subTask: SubTaskDto;
}

const StyledChip = styled(Chip)(({ theme }) => ({
    backgroundColor: blueGrey[50],
    // ensure that line-height does not break vertical centering of text
    lineHeight: "normal",
    cursor: "inherit",
    borderRadius: theme.shape.borderRadius,
    height: theme.spacing(2.5),
    fontSize: theme.typography.caption.fontSize,
    [` .${chipClasses.label}`]: {
        padding: theme.spacing(0.125, 1),
    },
}));

const ActivityLevelChip = ({ subTask, ...restProps }: IActivityLevelProps) => {
    const activityLevel = useSubTaskLevel(subTask);
    if (activityLevel === null) {
        return null;
    }
    return <StyledChip label={activityLevel} {...restProps} />;
};

export default ActivityLevelChip;
