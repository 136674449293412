import { ListItemButton, ListItemButtonProps, styled } from "@mui/material";
import { LinkProps } from "react-router-dom";

const SidebarNavItemButton = styled(ListItemButton)<
    {
        // fix for `component` typing
        // see: https://github.com/mui/material-ui/issues/13921
        component?: React.ElementType<LinkProps>;
        to?: string;
    } & ListItemButtonProps
>(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    ...theme.typography.body2,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    color: theme.palette.text.secondary,
    "&.Mui-selected": {
        color: theme.palette.text.primary,
    },
}));
export default SidebarNavItemButton;
