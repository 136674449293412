import { FormControlLabel, FormGroup, Grid, InputAdornment, Switch, TextField, Typography } from "@mui/material";
import { SubTaskListDto } from "api-shared";
import { TFunction } from "i18next";
import { ChangeEvent } from "react";
import Tooltip from "../../../components/Tooltip";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { translationKeys } from "../../../translations/main-translations";
import { useTranslation } from "react-i18next";

interface ActivitiesCSVExportDialogContentProps {
    open: boolean;
    subtasks?: SubTaskListDto;
    startExport: () => void;
    translate: TFunction;
    closeDialog: () => void;
    dataAvailable: boolean;
    printFormatting: boolean;
    toggleAddExcelMetadata: boolean;
    filename: string;
    handleChangePrintFormatting: (event: ChangeEvent<HTMLInputElement>) => void;
    handleChangeAddExcelMetadata: (event: ChangeEvent<HTMLInputElement>) => void;
    handleChangeFilename: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ActivitiesCSVExportDialogContent = ({
    open,
    subtasks,
    startExport,
    translate,
    closeDialog,
    dataAvailable,
    printFormatting,
    toggleAddExcelMetadata,
    filename,
    handleChangePrintFormatting,
    handleChangeAddExcelMetadata,
    handleChangeFilename,
}: ActivitiesCSVExportDialogContentProps) => {
    const { t } = useTranslation();

    return (
        <ActionItemDialog
            action={translationKeys.VDLANG_SAVE}
            item="item"
            primary={translationKeys.VDLANG_EXPORT_CSV_BUTTON}
            onPrimary={startExport}
            primaryDisabled={subtasks == null || subtasks.length === 0 || !dataAvailable}
            title={translate(translationKeys.VDLANG_EXPORT_CSV)}
            translate={translate}
            open={open}
            onClose={closeDialog}
        >
            <Typography pb={2}>{translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_HINT)}</Typography>
            <TextField
                value={filename}
                onChange={handleChangeFilename}
                fullWidth={true}
                label={t(translationKeys.VDLANG_FILENAME)}
                InputProps={{
                    endAdornment: <InputAdornment position="end">.csv</InputAdornment>,
                }}
            />
            <FormGroup>
                <Tooltip title={translate(translationKeys.VDLANG_EXPORT_CSV_TOGGLE_EXCEL_METADATA_HINT)}>
                    <FormControlLabel
                        control={<Switch checked={toggleAddExcelMetadata} onChange={handleChangeAddExcelMetadata} />}
                        label={translate(translationKeys.VDLANG_EXPORT_CSV_TOGGLE_EXCEL_METADATA)}
                    />
                </Tooltip>

                <FormControlLabel
                    control={<Switch checked={printFormatting} onChange={handleChangePrintFormatting} />}
                    label={translate(translationKeys.VDLANG_EXPORT_CSV_TOGGLE_PRINT_FORMATTING)}
                />
            </FormGroup>
            {dataAvailable && (subtasks == null || subtasks.length === 0) ? (
                <Grid container justifyContent="center">
                    <Typography mt={2}>{translate(translationKeys.VDLANG_NO_PROCESSES)}</Typography>
                </Grid>
            ) : null}
            {!dataAvailable ? (
                <Grid container justifyContent="center">
                    <LoadingAnimation sx={{ mt: 2 }} />
                    <Typography>{translate(translationKeys.VDLANG_PREPARING_DATA)}</Typography>
                </Grid>
            ) : null}
        </ActionItemDialog>
    );
};

export default ActivitiesCSVExportDialogContent;
