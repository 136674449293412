import { TextField } from "@mui/material";
import { effortConverter, effortParser, validateEffortValue } from "api-shared";
import { ChangeEvent, useState } from "react";
import { isEnterEvent } from "../../lib/keybindings";

interface IEffortInputProps {
    value: number | null;
    updateValue: (newValue: number | null) => void;
    fullWidth?: boolean;
}

const EffortInput = ({ value, updateValue, fullWidth }: IEffortInputProps) => {
    const [input, setInput] = useState<string | null>(effortConverter(value));
    const [valid, setValid] = useState(true);

    const handleValidation = (e: ChangeEvent<HTMLInputElement>) => {
        setValid(validateEffortValue(e.target.value));
        setInput(e.target.value);
    };

    const handleFocus = () => {
        if (input === null) {
            setInput(effortConverter(input));
        }
    };

    const handleBlur = () => {
        // if input is null here, neither onFocus nor onChange have been fired so nothing to do
        if (input === null) {
            return;
        }

        // Use the standard format that is saved to the database
        const sanitizedInput = effortParser(input);

        // Only send an update request when there are changes
        if (value !== sanitizedInput && valid) {
            updateValue(sanitizedInput);
        } else {
            setInput(effortConverter(value));
        }

        // At this point everything is valid and the error hints can be removed
        setValid(true);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (isEnterEvent(event)) {
            handleBlur();
        }
    };

    return (
        <TextField
            value={input}
            onChange={handleValidation}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onFocus={handleFocus}
            fullWidth={fullWidth}
            error={!valid}
        />
    );
};

export default EffortInput;
