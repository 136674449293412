import { styled } from "@mui/material";
import NavButton from "./NavButton";

const MobileNavButton = styled(NavButton)(({ theme }) => ({
    margin: 0,
    justifyContent: "flex-start",
    textAlign: "left",
    // inactive
    color: theme.palette.text.secondary,
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

// Cast back to Button type so that overrides with component prop can be fully typed
export default MobileNavButton as typeof NavButton;
