import ArrowDropDownRounded from "@mui/icons-material/ArrowDropDownRounded";
import { Box, ButtonProps, InputBaseProps, Button as MuiButton, Stack, styled } from "@mui/material";
import type React from "react";
import { StaticBadge } from "../../StaticBadge";

const Button = styled(MuiButton)(({ theme }) => ({
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    borderColor: theme.palette.border.main,
    ...theme.typography.body2,
    ":disabled": {
        borderColor: theme.palette.border.main,
    },
}));

interface ISelectButtonProps extends Omit<ButtonProps, "size"> {
    label: React.ReactNode;
    count?: number;
    size?: InputBaseProps["size"]; // Replace button size options with input size options
    dropdownIcon?: React.ReactNode;
}

// These are the spacing units so that the button sizes match the input sizes
// They achieve the same sizes, but spacing units are not exactly equal to the input sizes
const VerticalPaddings = {
    small: 0.375,
    medium: 0.625,
    large: 0.875,
    extralarge: 1.375,
};

/**
 * This component optically resembles a closed compact select, but is a button instead. It can be used as trigger element to open
 * a custom menus, e.g. for the ScopeAttributeSelects in the processes sidebar
 *
 * @param {ISelectButtonProps} { label, count = 0, size = "medium", ...buttonProps }
 * @returns
 */
const SelectButton = ({ label, count = 0, size = "medium", dropdownIcon, ...buttonProps }: ISelectButtonProps) => {
    return (
        <Button
            variant="outlined"
            color="inherit"
            endIcon={
                <Stack direction="row" alignItems="center" spacing={1}>
                    {count > 0 && <StaticBadge badgeContent={count} color="primary" />}
                    {dropdownIcon ?? <ArrowDropDownRounded color="action" />}
                </Stack>
            }
            sx={{ py: VerticalPaddings[size] }}
            {...buttonProps}
        >
            {label}
            <Box sx={{ flexGrow: 1 }} />
        </Button>
    );
};

export default SelectButton;
