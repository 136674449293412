import React, { Suspense } from "react";
import { MarkdownEditorProps } from "./MarkdownEditor";

const MarkdownEditor = React.lazy(() => import("./MarkdownEditor"));

export type MarkdownEditorUserMentionsOwnProps = Omit<MarkdownEditorProps, "mentionResolve" | "mention">;

export default function MarkdownEditorWithoutMentions(props: MarkdownEditorUserMentionsOwnProps) {
    return (
        <Suspense>
            <MarkdownEditor {...props} />
        </Suspense>
    );
}
