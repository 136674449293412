import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Button, Grid, IconButton } from "@mui/material";
import { AclNamespaces, AclPermissions, UserDto } from "api-shared";
import { TFunction } from "i18next";
import { useCallback, useState } from "react";
import { Link, Location } from "react-router-dom";
import { useUserHasAccessPermissionQuery, useUserHasPermissionQuery } from "../../domain/permissions";
import { RouteFor } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";
import MobileNavbarDrawer from "./MobileNavbarDrawer";
import PlusButton from "./PlusButton";

interface IMobileNavbarContentProps {
    location: Location;
    disabled?: boolean;
    translate: TFunction;
    currentUser: UserDto | null;
    searchKey: string;
    updateSearchKey: (newKey: string) => void;
}

const MobileNavbarContent = ({ location, translate, searchKey, updateSearchKey, currentUser, disabled }: IMobileNavbarContentProps) => {
    const canCreateProcessQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.Process,
        permission: AclPermissions.Create,
        fact: {},
    });
    const hasIdeaAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.Idea);

    const [open, setOpen] = useState(false);
    const openDrawer = () => setOpen(true);
    const closeDrawer = useCallback(() => {
        setOpen(false);
        updateSearchKey("");
    }, [setOpen, updateSearchKey]);

    if (!canCreateProcessQuery.isSuccess || !hasIdeaAccessPermissionQuery.isSuccess) {
        return null;
    }

    const hasCreatePermission = canCreateProcessQuery.data.hasPermission || hasIdeaAccessPermissionQuery.data.hasPermission;

    return (
        <>
            <MobileNavbarDrawer
                open={open}
                onClose={closeDrawer}
                translate={translate}
                searchKey={searchKey}
                updateSearchKey={updateSearchKey}
                currentUser={currentUser}
                location={location}
            />
            {hasCreatePermission ? (
                <Grid item>
                    <PlusButton disabled={disabled} />
                </Grid>
            ) : null}
            <Grid item>
                {disabled ? (
                    <Button sx={{ mr: 2 }} component={Link} to={RouteFor.user.login} variant="contained" color="secondary">
                        {translate("Login")}
                    </Button>
                ) : (
                    <IconButton color="inherit" aria-label={translate(translationKeys.VDLANG_MENU)} onClick={openDrawer}>
                        <MenuRoundedIcon />
                    </IconButton>
                )}
            </Grid>
        </>
    );
};

export default MobileNavbarContent;
