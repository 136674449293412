import { UserDto } from "api-shared";
import { TFunction } from "i18next";
import { translationKeys } from "../../translations/main-translations";
import UserAvatar from "../user/UserAvatar";
import UserEntryInline from "../user/UserEntryInline";
import UserEntryWithPopup from "../user/UserEntryWithPopup";
import UserName from "../user/UserName";

interface ISubtaskListUserGroupHeaderProps {
    translate: TFunction;
    users: UserDto[];
    userId: number | null;
}

const SubtaskListUserGroupHeader = ({ translate, users, userId }: ISubtaskListUserGroupHeaderProps) => {
    if (userId == null) {
        return <>{translate(translationKeys.VDLANG_RESPONSIBLE_NOT_ASSIGNED)}</>;
    }

    const user = users.find((u) => u.id === userId);
    return (
        // Typography is still wrong here, because wrapping Button resets lots of CSS
        <UserEntryWithPopup
            triggerElement={
                <UserEntryInline
                    // inherit typography from parent component SubtaskListGroupHeader
                    typographyProps={{ variant: "inherit" }}
                    avatar={<UserAvatar user={user} size={24} />}
                    userName={<UserName user={user} withBadge translate={translate} />}
                />
            }
            user={user}
        />
    );
};

export default SubtaskListUserGroupHeader;
