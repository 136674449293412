import { styled, Typography } from "@mui/material";
import { omit } from "lodash";
import { NoticeProps } from "react-select";
import { Option } from "../types";

const SelectNoOptionsTypography = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1, 2),
}));

const SelectNoOptionsMessage = <OptionType extends Option, IsMulti extends boolean>({
    innerProps, // May be null, although react-select typings do not reflect that
    children,
}: NoticeProps<OptionType, IsMulti>) => {
    return (
        <SelectNoOptionsTypography {...omit(innerProps ?? {}, "ref")} color="textSecondary">
            {children}
        </SelectNoOptionsTypography>
    );
};
export default SelectNoOptionsMessage;
