import { Sort, SubTaskOrderBy } from "api-shared";
import { TFunction } from "i18next";
import { ComponentType, useMemo } from "react";
import Select from "../../../components/input/select/Select";
import { ISelectProps, Option } from "../../../components/input/select/types";
import { OrderedOptionHandler, useOrderOptions } from "../../../hooks/useOrderOptions";
import { translationKeys } from "../../../translations/main-translations";

const labels: Record<SubTaskOrderBy, translationKeys | string> = {
    [SubTaskOrderBy.DUEDATE]: translationKeys.VDLANG_DUE_DATE,
    [SubTaskOrderBy.ASSIGNED_TO]: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_ASSIGNED_TO,
    [SubTaskOrderBy.CREATED_AT]: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_CREATED_AT,
    [SubTaskOrderBy.TITLE]: "title",
    [SubTaskOrderBy.PRIORITY]: translationKeys.VDLANG_PRIORITY,
};

export interface IActivitiesOrderSelectProps extends Omit<ISelectProps<Option<string>, false>, "onChange" | "options" | "value"> {
    value: SubTaskOrderBy;
    order: Sort;
    translate: TFunction;
    onChange: OrderedOptionHandler<SubTaskOrderBy>;
    component?: ComponentType<ISelectProps<Option<string>, false>>;
}

const ActivitiesOrderSelect = ({ component, order, value, onChange, translate, ...otherProps }: IActivitiesOrderSelectProps) => {
    const Component = component ?? Select;
    const options = useMemo(
        () =>
            Object.entries(labels).map(([value, label]) => ({
                value: value as SubTaskOrderBy,
                label: translate(label),
            })),
        [translate],
    );

    const orderOptions = useOrderOptions(value, order, options, onChange);

    return (
        <Component label={translate(translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_LABEL)} {...orderOptions} {...otherProps} />
    );
};

export default ActivitiesOrderSelect;
