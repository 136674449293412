import { FieldDefinitionsDto, TranslationType } from "api-shared";
import { identity } from "lodash";
import { useTranslation } from "react-i18next";
import { useMeasureAttributes } from "../../../domain/endpoint";
import useFieldData from "../../../hooks/useFieldData";
import { Field, findField } from "../../../lib/fields";
import { translationKeys } from "../../../translations/main-translations";

type UseFieldOptionsOptions = {
    definitions?: FieldDefinitionsDto;
    fieldName?: string;
    withDefaults?: boolean;
    customizeField?: (field: Field) => Field;
};

export type FieldOption = {
    value: string;
    label: string;
    order?: number;
    id?: number;
    parentId?: number | null;
};

export const useFieldOptions = ({
    definitions = {},
    fieldName = "",
    withDefaults = true,
    customizeField = identity,
}: UseFieldOptionsOptions = {}) => {
    const { t: translate } = useTranslation();
    const measureAttributes = useMeasureAttributes();
    const field = findField(measureAttributes, definitions, fieldName);

    const fakeField: Field = {
        title: "",
        type: "fake",
        order: 0,
        quantity: 0,
        tableName: null,
        mandatory: false,
        isCreatable: null,
        options: {},
        translate: TranslationType.Map,
        ignoreResolvingErrors: true,
    };

    const fields = [customizeField(field ?? fakeField)];

    const [options] = useFieldData(fields, { sort: true });

    if (options.length === 0) {
        return undefined;
    }

    const defaultOptions: FieldOption[] = [
        { value: "undefined", label: translate(translationKeys.VDLANG_DASHBOARDS_UNDEFINED_GROUP) },
        { value: "null", label: translate(translationKeys.VDLANG_DASHBOARDS_UNDEFINED_GROUP) },
    ];

    // Never apply default options for gateTaskConfigs, as those are always set
    const isGateTaskConfigField = fields[0].tableName === "gate_task_configs";

    return [
        ...options.map(
            (o): FieldOption => ({
                value: String(o.value ?? o.id),
                label: o.label ?? o.name,
                order: o.order,
                id: o.id,
                parentId: o.parentId,
            }),
        ),
        ...(withDefaults && !isGateTaskConfigField ? defaultOptions : []),
    ];
};
