import { useFieldSelectProps, type IUseFieldSelectPropsProps } from "../FieldSelect";
import TreeInput, { TreeInputProps } from "./TreeInput";

interface IFieldTreeInputProps extends IUseFieldSelectPropsProps, Omit<TreeInputProps, "disabled"> {}

const sanitizeFieldValue = (value: unknown): number[] => {
    if (typeof value === "number") {
        return [value];
    }

    if (value === "" || value == null) {
        return [];
    }

    if (typeof value === "string") {
        return value
            .split(",")
            .filter((x) => x !== "")
            .map(Number)
            .filter((x) => !Number.isNaN(x));
    }

    if (!Array.isArray(value)) {
        return [];
    }

    return value.map(sanitizeFieldValue).flat();
};

const FieldTreeInput = (props: IFieldTreeInputProps) => {
    const fieldSelectProps = useFieldSelectProps(props);

    const arrayValue = sanitizeFieldValue(props.value);
    const value = props.isMulti ? arrayValue : (arrayValue[0] ?? null);

    const { disabled, isClearable, field, ...otherProps } = props;

    return <TreeInput {...fieldSelectProps} {...otherProps} value={value} disabled={disabled} />;
};

export default FieldTreeInput;
