import { ErrorMessage } from "api-shared";
import queryString from "query-string";
import { reportError } from "../infrastructure/sentry";
import { getAuthToken } from "./authentication-saga";
import { resolveErrorConstantKey } from "./error";

export const baseUrl = "/api/";
export const legacyBaseUrl = "/legacy/api-v1/";
export const MAX_UPLOAD_SIZE = 20 * 1024 * 1024;
const CONTENT_TYPE_JSON = "application/json";

export class LoginData {
    constructor(
        public readonly email: string,
        public readonly password: string,
        public readonly twoFactorAuthenticationToken?: string,
        public readonly twoFactorAuthenticationRemember?: boolean,
    ) {}

    getBody() {
        return queryString.stringify({
            email: this.email,
            password: this.password,
            twoFactorAuthenticationToken: this.twoFactorAuthenticationToken,
            twoFactorAuthenticationRemember: this.twoFactorAuthenticationRemember,
        });
    }
}

// Response where body has been convertet according to its content type
// content may be:
// content type text: string
// content type json: any
type ResponseWithContent = { response: Response; content: any };

/**
 * This error type is used to describe any errors returned by the backend, e.g. BadRequest or Unauthorized, ...
 *
 * this.message will be populated with a reverse-mapped ErrorConstantKey such as VDERROR_BAD_REQUEST and the like
 *
 * @export
 * @class ApiError
 * @extends {Error}
 */
export class ApiError extends Error {
    constructor(
        public code: number,
        public fetchError: string,
        public content: ErrorMessage | string,
        public url: string,
    ) {
        // Set message property to something readable like VDERROR_BAD_REQUEST
        super(resolveErrorConstantKey(content, fetchError));
    }
}

type Data = unknown;

export type ApiRequestOptions = {
    signal: AbortSignal | undefined;
};

export function apiGet<TResponse = unknown>(url: string, options: ApiRequestOptions) {
    // GET requests do not have a request body
    return apiCall<TResponse>("GET", url, undefined, options);
}

export function apiPatch<TResponse = unknown>(url: string, data: Data) {
    return apiCall<TResponse>("PATCH", url, data);
}

export function apiPut<TResponse = unknown>(url: string, data: Data) {
    return apiCall<TResponse>("PUT", url, data);
}

/**
 * Generally POST requests should not be aborted, because they indicate a mutation. But in certain scenarios if they are used to purely fetch data,
 * like in a search request, the frontend should be able to abort those generally longer running requests if the user navigates to a different page.
 */
export function apiPost<TResponse = unknown>(url: string, data?: Data, options?: ApiRequestOptions) {
    return apiCall<TResponse>("POST", url, data, options);
}

export function apiDelete<TResponse = unknown>(url: string, data?: Data) {
    return apiCall<TResponse>("DELETE", url, data);
}

export function apiCall<TResponse = unknown>(method: string, url: string, data?: Data, options?: ApiRequestOptions) {
    return httpCall<TResponse>(method, baseUrl + url, data, options);
}

function getHeaders(isLogin: boolean, isForm: boolean, token: string | undefined): Headers {
    const headers: Headers = new Headers();

    if (token != null) {
        headers.append("Authorization", "Bearer " + token);
    }

    if (!isForm && isLogin) {
        headers.append("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    }

    if (!isForm && !isLogin) {
        headers.append("Content-type", CONTENT_TYPE_JSON);
        headers.append("Accept", CONTENT_TYPE_JSON);
    }

    return headers;
}

export function httpCall<TResponse = unknown>(
    method: string,
    fullUrl: string,
    data?: Data,
    paramOptions?: ApiRequestOptions,
): Promise<TResponse> {
    const options: RequestInit = {
        method,
        credentials: "include",
        ...paramOptions,
    };

    if (data instanceof FormData) {
        options.body = data;
    } else if (data instanceof LoginData) {
        options.body = data.getBody();
    } else if (data != null && typeof data === "object") {
        options.body = JSON.stringify(data);
    } else if (data !== undefined) {
        reportError(new Error("Cannot recognize data, falling back to JSON.stringify"), { extras: { data: JSON.stringify(data) } });
        options.body = JSON.stringify(data);
    }

    const accessToken = getAuthToken();
    options.headers = getHeaders(data instanceof LoginData, data instanceof FormData, accessToken);

    return fetch(fullUrl, options)
        .then(decodeContent)
        .then((x) => handleFetchErrors<TResponse>(x));
    // Promise rejections will be handled by react-query, see lib/react-query.ts for any error handling logic
}

function decodeContent(response: Response): Promise<ResponseWithContent> {
    const contentType = response.headers.get("content-type");
    if (contentType?.includes(CONTENT_TYPE_JSON)) {
        return response.json().then((json) => ({ response, content: json }));
    } else {
        return response.text().then((text) => ({ response, content: text }));
    }
}

export function handleFetchErrors<TResponse>({ response, content }: ResponseWithContent): TResponse {
    if (response.ok) {
        return content;
    }

    // Request was successful, but API returned an error
    throw new ApiError(response.status, response.statusText, content, response.url);
}

interface ApiUploadOptions {
    onProgress?: (event: ProgressEvent) => void;
}

export function apiUpload<TResponse>(url: string, data: FormData, options?: ApiUploadOptions) {
    return new Promise<TResponse>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", baseUrl + url);

        const token = getAuthToken();
        if (token !== undefined) {
            xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        }

        if (options?.onProgress !== undefined) {
            xhr.upload.addEventListener("progress", options.onProgress);
        }

        const onError = (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
            if (xhr.status === 0) {
                // Technical error, no or incomplete response from API received
                reject(new Error(`XMLHTTPRequest error of type ${event.type}`, { cause: JSON.stringify(event) }));
            }
            // Request was successful, but API returned an error
            reject(new ApiError(xhr.status, xhr.statusText, xhr.response, xhr.responseURL));
        };

        xhr.addEventListener("error", onError);

        xhr.addEventListener("load", (event) => {
            if (xhr.status >= 200 && xhr.status < 300) {
                const response = JSON.parse(xhr.response);
                resolve(response);
            } else {
                onError(event);
            }
        });

        xhr.send(data);
    });
}
