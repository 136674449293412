import { AttributeTable } from "api-shared";
import { AppState } from "../infrastructure/store";
import { IEntity } from "../lib/field-options";
import { Endpoint } from "./endpoint";

const REGISTER_FIELD_DATA = "REGISTER_FIELD_DATA";

type FieldDataSource = "gate_task_configs" | "measure_configs" | "currencies" | Endpoint.ValueLevers | AttributeTable;

export const isFieldDataSource = (tableName: string | null): tableName is FieldDataSource => {
    return (
        tableName != null &&
        [...Object.values(AttributeTable), "gate_task_configs", "measure_configs", "currencies", "value-levers"].includes(tableName)
    );
};

export const registerFieldData = (key: FieldDataSource, values: IEntity[]) => ({
    type: REGISTER_FIELD_DATA,
    key,
    values,
});

type FieldDataState = Record<FieldDataSource, IEntity[]>;
const initialState: FieldDataState = {
    gate_task_configs: [],
    measure_configs: [],
    currencies: [],
    [AttributeTable.Suppliers]: [],
    [AttributeTable.TreeNodes]: [],
    [AttributeTable.CostLevers]: [],
    [AttributeTable.Companies]: [],
    [Endpoint.ValueLevers]: [],
    [AttributeTable.Projects]: [],
    [AttributeTable.CustomValues]: [],
    [AttributeTable.Users]: [],
    [AttributeTable.Departments]: [],
    [AttributeTable.GroupsWithAccess]: [],
};

export const fieldDataReducer = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state = initialState,
    action: ReturnType<typeof registerFieldData>,
) => {
    if (action.type === REGISTER_FIELD_DATA) {
        return state[action.key] === action.values ? state : { ...state, [action.key]: action.values };
    }
    return state;
};

export const selectFieldData = (state: AppState, tableName: string | null) => {
    return tableName !== null ? state.fieldData[tableName as FieldDataSource] : null;
};
