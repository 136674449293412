import { Alert, Stack, styled } from "@mui/material";
import { CalendarPicker, CalendarPickerProps } from "@mui/x-date-pickers/CalendarPicker";
import { Moment } from "moment";

const Root = styled(Stack)(({ theme }) => ({
    paddingBottom: theme.spacing(2),
    width: "328px",
}));

interface DatePickerInfoPopoverProps extends Omit<CalendarPickerProps<Moment>, "translate"> {
    infoMessage?: string;
}

const DatePickerInfoPopover = ({ date, onChange, infoMessage, ...calendarProps }: DatePickerInfoPopoverProps) => {
    return (
        <Root>
            {infoMessage && (
                <Alert severity="info" variant="inline">
                    {infoMessage}
                </Alert>
            )}
            <CalendarPicker<Moment> date={date} onChange={onChange} {...calendarProps} />
        </Root>
    );
};
export default DatePickerInfoPopover;
