import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    CreateSubTaskRequestBody,
    FilterDefinition,
    SubTaskDto,
    SubTaskFilterRequestQueryParams,
    SubTaskHistoryListDto,
    SubTaskListDto,
    SubTaskStatus,
    SubTasksOrderedDto,
    UpdateSubTaskRequestParams,
    formatDateForAPI,
    zUpdateSubTaskRequestBody,
} from "api-shared";
import moment from "moment";
import queryString from "query-string";
import { z } from "zod";
import { apiGet, apiPatch, apiPost } from "../lib/api";
import { formatDateTimeForApi } from "../lib/formatters";
import { NotificationQueryKeys } from "./measure-notifications";
import { processHistoryKeys } from "./process-history";
import { ProcessPulseQueryKeys } from "./process-pulse";

interface UpdateSubTaskOrderInputDto {
    measureId: number;
    oldOrder: number;
    newOrder: number;
}

interface ApplyActivitiesTemplateInputDto {
    measureId: number;
    templateId: number;
    activityIds: number[];
}

const zApiDate = z.date().or(z.string()).or(z.unknown().refine(moment.isMoment)).transform(formatDateForAPI);
const zApiDateTime = z.unknown().refine(moment.isMoment).transform(formatDateTimeForApi);
const zUpdateSubTaskChanges = zUpdateSubTaskRequestBody.extend({
    duedate: zApiDate.nullish(),
    remindAt: zApiDateTime.nullish(),
});
export type SubTaskUpdateChanges = z.input<typeof zUpdateSubTaskChanges>;

const SUBTASKS_PATH = "sub-tasks";

export const subTaskQueryKeys = {
    all: () => [SUBTASKS_PATH, "all"] as const,
    allWithFilter: (filter: SubTaskFilterRequestQueryParams, filterDefinition?: FilterDefinition) =>
        [...subTaskQueryKeys.all(), filter, filterDefinition] as const,
    forMeasure: (measureId: number) => [SUBTASKS_PATH, "for-measure", measureId] as const,
    forGateTask: (gateTaskId: number) => [SUBTASKS_PATH, "for-gateTask", gateTaskId] as const,
    byId: (subtaskId: number | null) => [SUBTASKS_PATH, subtaskId] as const,
    historyById: (subtaskId: number) => [SUBTASKS_PATH, "history", subtaskId] as const,
};

export const useSubTasksByMeasure = (measureId: number) => {
    return useQuery({
        queryKey: subTaskQueryKeys.forMeasure(measureId),
        queryFn: ({ signal }) => apiGet<SubTaskListDto>(`${SUBTASKS_PATH}/by-measure/${measureId}`, { signal }),
    });
};

export const useSubTasksByGateTask = (gateTaskId: number) => {
    return useQuery({
        queryKey: subTaskQueryKeys.forGateTask(gateTaskId),
        queryFn: ({ signal }) => apiGet<SubTaskListDto>(`${SUBTASKS_PATH}/by-gatetask/${gateTaskId}`, { signal }),
    });
};

type UseSubtaskOptions = {
    filter: SubTaskFilterRequestQueryParams;
    filterDefinition?: FilterDefinition;
    enabled?: boolean;
};

export const useSubTasks = ({ filter, filterDefinition, enabled = true }: UseSubtaskOptions) => {
    const queryClient = useQueryClient();
    return useQuery({
        enabled,
        queryKey: subTaskQueryKeys.allWithFilter(filter, filterDefinition),
        queryFn: ({ queryKey, signal }) => {
            const query = queryString.stringify(queryKey[2], { arrayFormat: "bracket" });
            return apiGet<SubTasksOrderedDto>(`${SUBTASKS_PATH}?${query}`, { signal });
        },
        onSuccess: (response: SubTasksOrderedDto) =>
            response.tasks.forEach((task) => queryClient.setQueryData(subTaskQueryKeys.byId(task.id), task)),
    });
};

export const useSubTask = (id: number | null) => {
    return useQuery({
        queryKey: subTaskQueryKeys.byId(id),
        // queryKey[1] (aka id) cannot be null because of enabled flag
        queryFn: ({ queryKey, signal }) => apiGet<SubTaskDto>(`${SUBTASKS_PATH}/${queryKey[1] ?? 0}`, { signal }),
        enabled: id != null,
        meta: {
            ignoreErrors: true,
        },
    });
};

export const useCreateSubtask = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: CreateSubTaskRequestBody) => apiPost<SubTaskDto>(SUBTASKS_PATH, data),
        onSuccess: (_response, { measureId }) => {
            queryClient.invalidateQueries(subTaskQueryKeys.forMeasure(measureId));
            queryClient.invalidateQueries(processHistoryKeys.forMeasure(measureId));
            queryClient.invalidateQueries(subTaskQueryKeys.all());
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
        },
    });
};

export const useUpdateSubtask = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, ...restProps }: SubTaskUpdateChanges & UpdateSubTaskRequestParams) =>
            apiPatch<SubTaskDto>(`${SUBTASKS_PATH}/${id}`, zUpdateSubTaskChanges.parse(restProps)),
        onSuccess: (response) => {
            queryClient.invalidateQueries(subTaskQueryKeys.forMeasure(response.measureId));
            queryClient.invalidateQueries(subTaskQueryKeys.byId(response.id));
            queryClient.invalidateQueries(subTaskQueryKeys.historyById(response.id));
            queryClient.invalidateQueries(subTaskQueryKeys.all());
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(response.measureId));
            queryClient.invalidateQueries(processHistoryKeys.forMeasure(response.measureId));
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(response.measureId));
        },
    });
};

export const useUpdateSubtaskOrder = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ measureId, ...restProps }: UpdateSubTaskOrderInputDto) =>
            apiPatch<SubTaskListDto>(`${SUBTASKS_PATH}/change-order/${measureId}`, restProps),
        onSuccess: (response) => {
            // Update order should only be called for multiple SubTasks. => Length of response > 0
            // Take first entry as representative for all measure related subtasks
            queryClient.invalidateQueries(subTaskQueryKeys.forMeasure(response[0].measureId));
            queryClient.invalidateQueries(subTaskQueryKeys.byId(response[0].id));
            queryClient.invalidateQueries(subTaskQueryKeys.all());
        },
    });
};

export const useDiscardSubtask = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (subTaskId: number) => apiPatch<SubTaskDto>(`${SUBTASKS_PATH}/${subTaskId}`, { status: SubTaskStatus.STATUS_REMOVED }),
        onSuccess: (response) => {
            queryClient.invalidateQueries(subTaskQueryKeys.byId(response.id));
            queryClient.invalidateQueries(subTaskQueryKeys.historyById(response.id));
            queryClient.invalidateQueries(subTaskQueryKeys.forMeasure(response.measureId));
            queryClient.invalidateQueries(subTaskQueryKeys.all());
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(response.measureId));
        },
    });
};

export const useApplyActivityTemplate = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ measureId, ...restProps }: ApplyActivitiesTemplateInputDto) =>
            apiPost<SubTaskDto>(`measures/${measureId}/activities`, restProps),
        onSuccess: (_response, { measureId }) => {
            queryClient.invalidateQueries(subTaskQueryKeys.forMeasure(measureId));
            queryClient.invalidateQueries(processHistoryKeys.forMeasure(measureId));
        },
    });
};

export const useSubTaskHistory = (subTaskId: number) => {
    return useQuery({
        queryKey: subTaskQueryKeys.historyById(subTaskId),
        queryFn: ({ signal }) => apiGet<SubTaskHistoryListDto>(`${SUBTASKS_PATH}/${subTaskId}/history`, { signal }),
    });
};
