import { alpha, Button, ButtonProps, styled } from "@mui/material";

const ValueBlueButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    transition: theme.transitions.create("margin"),
    [theme.breakpoints.down("lg")]: {
        margin: theme.spacing(0.25),
    },
    color: alpha(theme.palette.valueBlue.contrastText, 0.6),
    "&.Mui-disabled": {
        color: alpha(theme.palette.valueBlue.contrastText, theme.palette.action.disabledOpacity),
    },
    "&:hover": {
        backgroundColor: theme.palette.valueBlue.dark,
    },
}));

const NavButton = (props: ButtonProps) => <ValueBlueButton variant="text" {...props} />;

// Cast back to Button type so that overrides with component prop can be fully typed
export default NavButton as typeof Button;
