import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    BasicMessageDto,
    CommentDto,
    CommentListDto,
    CreateCommentInputDto,
    DeleteCommentInputDto,
    GetCommentsInputDto,
    UpdateCommentInputDto,
} from "api-shared";
import { apiDelete, apiGet, apiPatch, apiPost } from "../lib/api";
import { CommentStreamKeys } from "./comment-stream";
import { subTaskQueryKeys } from "./subtasks";

export const MEASURE_COMMENTS = "measure-comments";
export const SUBTASK_COMMENTS = "sub-task-comments";
export const IDEA_COMMENTS = "idea-comments";
export const FEED_COMMENTS = "feed-comments";

export enum CommentParentType {
    "MEASURE",
    "SUBTASK",
    "IDEA",
    "FEED",
}

interface CommentsBaseInputDto {
    parentType: CommentParentType;
    measureId?: number;
}

interface CommentsParentInputDto extends CommentsBaseInputDto {
    parentId: number;
}

interface QueryCommentsForMeasureInputDto {
    measureId: number;
    enabled: boolean;
}

const commentQueryKeys = {
    forParent: (input: CommentsParentInputDto) => ["comments", input] as const,
};

const COMMENT_PARENT_TYPE_PATHS = {
    [CommentParentType.MEASURE]: MEASURE_COMMENTS,
    [CommentParentType.SUBTASK]: SUBTASK_COMMENTS,
    [CommentParentType.IDEA]: IDEA_COMMENTS,
    [CommentParentType.FEED]: FEED_COMMENTS,
};

/*
 * API
 */

export const useComments = ({
    parentId,
    parentType,
    enabled = true,
}: GetCommentsInputDto & CommentsBaseInputDto & { enabled?: boolean }) => {
    return useQuery({
        queryKey: commentQueryKeys.forParent({ parentType, parentId }),
        queryFn: ({ signal }) => apiGet<CommentListDto>(COMMENT_PARENT_TYPE_PATHS[parentType] + `?parentId=${parentId}`, { signal }),
        enabled,
    });
};

export const useCreateComment = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ parentType, ...restProps }: CreateCommentInputDto & CommentsBaseInputDto) =>
            apiPost<CommentDto>(COMMENT_PARENT_TYPE_PATHS[parentType], restProps),
        onSuccess: (_response, { parentType, parentId, measureId }) => {
            if (parentType === CommentParentType.SUBTASK) {
                queryClient.invalidateQueries(subTaskQueryKeys.all());
                queryClient.invalidateQueries(subTaskQueryKeys.byId(parentId));
                if (measureId !== undefined) {
                    queryClient.invalidateQueries(subTaskQueryKeys.forMeasure(measureId));
                }
            }
            queryClient.invalidateQueries(commentQueryKeys.forParent({ parentType, parentId }));
            queryClient.invalidateQueries(CommentStreamKeys.all());
        },
    });
};

export const useUpdateComment = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ parentType, comment, commentId }: UpdateCommentInputDto & CommentsBaseInputDto) =>
            apiPatch<CommentDto>(COMMENT_PARENT_TYPE_PATHS[parentType] + `/${commentId}`, { comment }),
        onSuccess: (response, { parentType }) => {
            queryClient.invalidateQueries(commentQueryKeys.forParent({ parentType, parentId: response.parentId }));
            parentType === CommentParentType.SUBTASK && queryClient.invalidateQueries(subTaskQueryKeys.byId(response.parentId));
            queryClient.invalidateQueries(CommentStreamKeys.all());
        },
    });
};

export const useDeleteComment = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ parentType, commentId }: DeleteCommentInputDto & CommentsParentInputDto) =>
            apiDelete<BasicMessageDto>(COMMENT_PARENT_TYPE_PATHS[parentType] + `/${commentId}`),
        onSuccess: (_response, { parentType, parentId, measureId }) => {
            if (parentType === CommentParentType.SUBTASK) {
                queryClient.invalidateQueries(subTaskQueryKeys.all());
                queryClient.invalidateQueries(subTaskQueryKeys.byId(parentId));
                if (measureId !== undefined) {
                    queryClient.invalidateQueries(subTaskQueryKeys.forMeasure(measureId));
                }
            }
            queryClient.invalidateQueries(commentQueryKeys.forParent({ parentType, parentId: parentId }));
            queryClient.invalidateQueries(CommentStreamKeys.all());
        },
    });
};

export const useCommentsForMeasure = ({ measureId, enabled }: QueryCommentsForMeasureInputDto) => {
    return useComments({ parentId: measureId, parentType: CommentParentType.MEASURE, enabled });
};
