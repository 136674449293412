import ChevronLeftIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import { alpha, Button, ButtonProps, styled } from "@mui/material";

const StyledCloseButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(0.25),
    minWidth: 0,
    borderRadius: "50%",
    color: theme.palette.action.active,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    "&:hover": {
        backgroundColor: alpha(theme.palette.action.active, theme.palette.action.hoverOpacity),
        boxShadow: theme.shadows[2],
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
            backgroundColor: "transparent",
        },
    },
}));

interface ISidebarCloseButtonProps extends Omit<ButtonProps, "variant"> {
    direction: "left" | "right";
}

const SidebarCloseButton = ({ direction, ...buttonProps }: ISidebarCloseButtonProps) => {
    const CloseIcon = direction === "right" ? ChevronRightIcon : ChevronLeftIcon;
    return (
        <StyledCloseButton variant="contained" {...buttonProps}>
            <CloseIcon fontSize="small" />
        </StyledCloseButton>
    );
};

export default SidebarCloseButton;
