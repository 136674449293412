import { Button, Grid, styled, Typography } from "@mui/material";
import {
    AclNamespaces,
    AclPermissions,
    EMPTY_FILTER,
    FieldDefinitionsDto,
    FilterDefinition,
    ICompareExpression,
    IgnoreOperatorsForField,
    MeasureAttributeDto,
    MeasureFieldNames,
    UserDto,
} from "api-shared";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { Trans } from "react-i18next";
import Alert from "../../../../components/Alert";
import LoadingAnimation from "../../../../components/loading/LoadingAnimation";
import { useMeasureFieldDefinitionsQuery } from "../../../../domain/filters";
import { useUserHasPermissionQuery } from "../../../../domain/permissions";
import { SearchConfig } from "../../../../domain/search-config";
import useTimezone from "../../../../hooks/useTimezone";
import { translationKeys } from "../../../../translations/main-translations";
import Condition from "./Condition";
import { buildFilterDefinitionResult, buildNewTitleCondition } from "./conditions";

const ConditionGrid = styled("div")(({ theme }) => ({
    display: "grid",
    gap: theme.spacing(1),
    //fixed value for fieldselect to stop overflow
    gridTemplateColumns: "1fr 1fr minmax(10px, 1fr) auto",
    alignItems: "center",
}));

const EmptyStateContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 0),
}));

interface IFilterFormProps {
    affectedViews?: SearchConfig[];
    filterDefinition?: FilterDefinition;
    measureAttributes: MeasureAttributeDto[];
    fieldDefinitions?: FieldDefinitionsDto;
    users: UserDto[];
    onChange: (definition: FilterDefinition, isValid: boolean) => void;
    translate: TFunction;
    buildNewCondition?: () => ICompareExpression;
    disabled?: boolean;
    readonlyFields?: MeasureFieldNames[];
    ignoreConditionOperators?: IgnoreOperatorsForField;
}

const useFilterConditions = (filterDefinition: FilterDefinition, buildNewCondition?: () => ICompareExpression) => {
    const addCondition = useCallback(() => {
        const builder = buildNewCondition ?? buildNewTitleCondition;
        const newConditions = [...filterDefinition, builder()];
        return buildFilterDefinitionResult(newConditions);
    }, [filterDefinition, buildNewCondition]);

    const updateCondition = useCallback(
        (index: number, newCondition: Partial<ICompareExpression>) => {
            const newConditions = filterDefinition.map((oldCondition, currentIndex) =>
                currentIndex !== index
                    ? oldCondition
                    : {
                          ...oldCondition,
                          ...newCondition,
                      },
            );
            return buildFilterDefinitionResult(newConditions);
        },
        [filterDefinition],
    );

    const removeCondition = useCallback(
        (index: number) => {
            const newConditions = filterDefinition.length > 1 ? filterDefinition.filter((_, currentIndex) => currentIndex !== index) : [];
            return buildFilterDefinitionResult(newConditions);
        },
        [filterDefinition],
    );

    return {
        conditions: filterDefinition,
        addCondition,
        updateCondition,
        removeCondition,
    };
};

const FilterForm = ({
    affectedViews = [],
    filterDefinition = EMPTY_FILTER,
    measureAttributes,
    fieldDefinitions,
    users,
    onChange,
    buildNewCondition,
    translate,
    disabled = false,
    readonlyFields,
    ignoreConditionOperators,
}: IFilterFormProps) => {
    const { conditions, addCondition, updateCondition, removeCondition } = useFilterConditions(filterDefinition, buildNewCondition);
    const { formatDate } = useTimezone();
    const hasPersonFilterPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.User,
        permission: AclPermissions.Filter,
        fact: {},
    });

    // set query's enabled flag to false if fieldDefinitions are defined in props
    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery(fieldDefinitions == null);

    const handleAddCondition = () => {
        const result = addCondition();
        onChange(...result);
    };

    const handleUpdateCondition = (index: number, condition: Partial<ICompareExpression>) => {
        const result = updateCondition(index, condition);
        onChange(...result);
    };

    const handleRemoveCondition = (index: number) => {
        const result = removeCondition(index);
        onChange(...result);
    };

    const views = affectedViews
        .filter(({ filterId }) => filterId !== null)
        .map(({ name, isDefault }) => (isDefault ? translate(translationKeys.VDLANG_SEARCH_CONFIGS_STANDARD_NAME) : name));

    const resolvedFieldDefinitions = fieldDefinitions ?? fieldDefinitionsQuery.data;

    if (resolvedFieldDefinitions == null || !hasPersonFilterPermissionQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <Grid container spacing={2}>
            {views.length > 0 ? (
                <Grid item xs={12}>
                    <Alert severity="info">
                        <Trans<translationKeys>
                            i18nKey={translationKeys.VDLANG_FILTER_APPLIED_ACROSS_VIEWS_HINT}
                            values={{ count: views.length, views: views.join(", ") }}
                        />
                    </Alert>
                </Grid>
            ) : null}
            <Grid item xs={12}>
                {conditions.length !== 0 ? (
                    <ConditionGrid>
                        {conditions.map((condition, index) => (
                            <Condition
                                key={index}
                                field={condition.field}
                                operator={condition.operator}
                                values={condition.values}
                                measureAttributes={measureAttributes}
                                fieldDefinitions={resolvedFieldDefinitions}
                                users={users}
                                canFilterByPerson={hasPersonFilterPermissionQuery.data.hasPermission}
                                updateCondition={(condition) => handleUpdateCondition(index, condition)}
                                removeCondition={() => handleRemoveCondition(index)}
                                translate={translate}
                                formatDate={formatDate}
                                disabled={disabled || readonlyFields?.includes(condition.field as MeasureFieldNames)}
                                ignoreOperators={ignoreConditionOperators}
                            />
                        ))}
                    </ConditionGrid>
                ) : (
                    <EmptyStateContainer>
                        <Typography color="textSecondary">{translate(translationKeys.VDLANG_FILTER_FORM_NO_CONDITION)}</Typography>
                    </EmptyStateContainer>
                )}
            </Grid>
            {!disabled ? (
                <Grid item xs={12}>
                    <Button data-testid="add_condition" onClick={handleAddCondition}>
                        {translate(translationKeys.VDLANG_FILTER_FORM_ADD_CONDITION)}
                    </Button>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default FilterForm;
