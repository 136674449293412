import { regexGetMatches, UserDto } from "api-shared";
import { TFunction } from "i18next";
import { formatUserFromId } from "./formatters";

/**
 * Find user ids by mention-marker within text and replace the marker by the formatted user name
 *
 * @param {UserDto[]} users
 * @param {(string | null)} text
 * @param {TFunction} translate
 * @returns {(string | null)}
 */
export function replaceMentionUsers(users: UserDto[], text: string, translate: TFunction): string;
export function replaceMentionUsers(users: UserDto[], text: string | null, translate: TFunction): string | null {
    if (text == null || text.length === 0) {
        return text;
    }

    // Find all magic keys in the text and extract them
    const FIND_ID = /@uid:(\d+)/g;
    const idsMatches = regexGetMatches(text, FIND_ID, 1);
    let ids = idsMatches.length > 0 ? idsMatches.map((sel) => Number(sel)) : [];
    ids = [...new Set(ids)]; // unique

    // Replace user
    let replacedText = text;
    for (const userId of ids) {
        replacedText = replacedText.split(`@uid:${userId}`).join(formatUserFromId(userId, users, { translate }));
    }

    return replacedText;
}
