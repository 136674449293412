import MenuIcon from "@mui/icons-material/MenuRounded";
import { Box, Drawer, DrawerProps, IconButton, Stack, Typography, drawerClasses, styled, useTheme } from "@mui/material";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { getSidebarLayoutTransitionStyles } from "./SidebarLayout";

const ToggleButton = styled(IconButton)(({ theme }) => ({
    zIndex: theme.zIndex.appBar,
}));

const HeaderContainer = styled(Stack, { shouldForwardProp: (name) => name !== "isOpen" })<{ isOpen?: boolean }>(({ theme, isOpen }) => ({
    padding: theme.spacing(2.5),
    ...(!isOpen && {
        paddingLeft: 0,
        paddingRight: 0,
    }),
}));

const CollapsedHeaderComponent = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(0.5, 1, 0),
    boxShadow: theme.shadows[1],
    zIndex: 1,
}));

const RootDrawer = styled(Drawer)(({ theme, open }) => ({
    height: "100%", // TODO: check if this is still needed
    flexShrink: 0,
    transition: getSidebarLayoutTransitionStyles(theme, Boolean(open)),
    [`& .${drawerClasses.paper}`]: {
        position: "relative", // revert sticky positioning, so that drawer can adapt to side of its container
        zIndex: theme.zIndex.appBar - 1, // Fix closed sidebar still capturing scroll events
        borderTop: "none",
        transition: getSidebarLayoutTransitionStyles(theme, Boolean(open)),
    },
}));

function renderCollapsedHeader(header: ReactNode | ReactElement | string) {
    if (typeof header === "string") {
        return (
            // add right padding as much as the button on the left takes space
            // so text is centered across screen width (an not only centered within its container)
            <Typography variant="body1" align="center" pr={6} noWrap>
                {header}
            </Typography>
        );
    }
    return header;
}

interface ISidebarLayoutDrawerProps {
    onRight?: boolean;
    width: number;
    onToggle: React.MouseEventHandler;
    sidebar: ReactNode;
    collapsedHeader?: ReactNode | ReactElement | string;
    isVertical?: boolean;
}

interface IInternalSidebarLayoutDrawerProps
    extends ISidebarLayoutDrawerProps,
        Omit<DrawerProps, "variant">,
        Partial<Pick<DrawerProps, "variant">> {}

const SidebarLayoutDrawer = ({
    open,
    onRight,
    width,
    onToggle,
    sidebar,
    collapsedHeader,
    classes,
    isVertical,
    variant = "permanent",
    ...drawerProps
}: IInternalSidebarLayoutDrawerProps) => {
    const theme = useTheme();
    const anchor = theme.direction === "rtl" || onRight ? "right" : "left";

    const widthProps = useMemo(() => ({ sx: { width } }), [width]);

    return (
        <>
            <RootDrawer
                variant={variant}
                anchor={anchor}
                open={open}
                PaperProps={widthProps}
                sx={{ ...drawerProps.sx, ...widthProps.sx }}
                {...drawerProps}
            >
                {open ? (
                    sidebar
                ) : (
                    <HeaderContainer isOpen={open} direction="row" justifyContent="center">
                        <ToggleButton onClick={onToggle}>
                            <MenuIcon />
                        </ToggleButton>
                    </HeaderContainer>
                )}
            </RootDrawer>
            {isVertical && (
                <CollapsedHeaderComponent direction={onRight ? "row-reverse" : "row"} alignItems="center">
                    <ToggleButton onClick={onToggle}>
                        <MenuIcon />
                    </ToggleButton>
                    {collapsedHeader != null && (
                        <Box minWidth={0} flexGrow={1}>
                            {renderCollapsedHeader(collapsedHeader)}
                        </Box>
                    )}
                </CollapsedHeaderComponent>
            )}
        </>
    );
};

export default SidebarLayoutDrawer;
