import { styled } from "@mui/material";
import { TFunction } from "i18next";
import flagDe from "../../../static/images/de.svg";
import flagEn from "../../../static/images/en.svg";
import { defaultLanguage, Language, languages, translationKeys } from "../../../translations/main-translations";
import Select from "./Select";
import { ISelectProps, Option } from "./types";

const FLAGS = {
    de: flagDe,
    en: flagEn,
};

const LanguageOptionImage = styled("img")(() => ({
    maxHeight: "1rem",
}));

interface ILanguageSelectProps extends Omit<ISelectProps<Option<Language>>, "value" | "onChange"> {
    translate: TFunction;
    onChange: (newValue: Language | null) => void;
    value: Language | null;
}

const LanguageSelect = ({ translate, onChange, value, ...selectProps }: ILanguageSelectProps) => {
    const languageOptions: Option<Language>[] = languages.map((lang) => ({
        label: translate(lang),
        value: lang,
        icon: <LanguageOptionImage alt={`flag-${lang}`} src={FLAGS[lang]} />,
    }));

    // use prefix because language can be either 2-char (en) or a code (en-US)
    const selectedLanguage =
        languageOptions.find((o) => value?.startsWith(o.value)) ?? languageOptions.find((o) => o.value === defaultLanguage);

    const handleChange = (option: Option<Language> | null) => onChange(option != null ? option.value : null);

    return (
        <Select
            isMulti={false} // no support for multi selection needed yet
            options={languageOptions}
            value={selectedLanguage}
            onChange={handleChange}
            placeholder={translate(translationKeys.VDLANG_SELECT_PLEASE_SELECT)}
            label={translate("Language")}
            {...selectProps}
        />
    );
};

export default LanguageSelect;
