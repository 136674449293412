import { Alert, Card, Divider, Stack, styled } from "@mui/material";
import { type SubTaskDto } from "api-shared";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { CommentParentType, useCreateComment, useDeleteComment, useUpdateComment } from "../../domain/comment";
import { useCurrentUserCanCommentMeasure, useCurrentUserCanEditMeasure, useMeasureQuery } from "../../domain/measure/detail";
import { SubTaskUpdateChanges, useSubTask } from "../../domain/subtasks";
import { translationKeys } from "../../translations/main-translations";
import { MeasureContextProvider, useMeasureContext, useUnsafeMeasureContext } from "../../view/MeasureContext";
import DeleteDialog from "../dialogues/DeleteDialog";
import SubtaskDetailContent, { ISubtaskDetailContentProps } from "./SubtaskDetailContent";
import SubtaskDetailFooter from "./SubtaskDetailFooter";
import SubtaskDetailHeader from "./SubtaskDetailHeader";
import SubtaskDetailSkeleton from "./SubtaskDetailSkeleton";

const ScrollableContent = styled("div")(({ theme }) => ({
    height: "100%",
    padding: theme.spacing(3),
    overflow: "auto",
}));

interface SubtaskDetailProps extends Omit<ISubtaskDetailContentProps, "task" | "updateComment" | "deleteComment"> {
    task: SubTaskDto;
    translate: TFunction;
    onClose: () => void;
    onRemove: (id: number) => void;
    updateTask: (changes: SubTaskUpdateChanges) => void;
    updateTab: (newTab: boolean) => void;
    CloseIcon?: React.ComponentType;
    showHint?: boolean;
}

const SubtaskDetail = ({
    task,
    translate,
    onClose,
    disabled,
    onRemove,
    updateTask,
    updateTab,
    CloseIcon,
    showHint,
    ...detailProps
}: Readonly<SubtaskDetailProps>) => {
    const createCommentMutation = useCreateComment();
    const updateCommentMutation = useUpdateComment();
    const deleteCommentMutation = useDeleteComment();

    const measure = useMeasureContext();

    const updateComment = (commentId: number, comment: string) =>
        updateCommentMutation.mutate({ parentType: CommentParentType.SUBTASK, comment, commentId });
    const deleteComment = (commentId: number) =>
        deleteCommentMutation.mutate({ parentType: CommentParentType.SUBTASK, commentId, parentId: task.id, measureId: measure?.id });

    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const onDelete = () => {
        onRemove(task.id);
        typeof onClose === "function" && onClose();
        setShowRemoveDialog(false);
    };

    const userCanEditMeasure = useCurrentUserCanEditMeasure(measure);

    const userCanCommentMeasure = useCurrentUserCanCommentMeasure(measure);

    const isEditingDisabled = disabled || !userCanEditMeasure;

    const createComment = (comment: string) => {
        createCommentMutation.mutate({
            parentId: task.id,
            parentType: CommentParentType.SUBTASK,
            comment,
            measureId: measure?.id,
        });
        updateTab(true); // Select comments tab
    };

    return (
        <Card sx={{ height: "100%" }}>
            {onRemove != null && (
                <DeleteDialog
                    hideDescription
                    item="subtask"
                    translate={translate}
                    open={showRemoveDialog}
                    onClose={() => setShowRemoveDialog(false)}
                    onDelete={onDelete}
                />
            )}
            <Stack sx={{ height: "100%" }} alignItems="stretch">
                <SubtaskDetailHeader
                    showHint={showHint}
                    disabled={isEditingDisabled}
                    status={task.status}
                    onClose={onClose}
                    onRemove={() => setShowRemoveDialog(true)}
                    translate={translate}
                    updateStatus={(status) => updateTask({ status })}
                    CloseIcon={CloseIcon}
                />
                <ScrollableContent>
                    <SubtaskDetailContent
                        task={task}
                        translate={translate}
                        disabled={isEditingDisabled}
                        updateTask={updateTask}
                        updateTab={updateTab}
                        updateComment={updateComment}
                        deleteComment={deleteComment}
                        {...detailProps}
                    />
                </ScrollableContent>
                {userCanCommentMeasure && (
                    <>
                        <Divider />
                        <SubtaskDetailFooter translate={translate} createComment={createComment} />
                    </>
                )}
            </Stack>
        </Card>
    );
};

// Use wrapper component to handle data loading and rendering loading state so that the SubtaskDetail component itself can assume that
// measure and task are always there. This simplifies handling with measure permission hooks, as they expect a measure object to be
// available.
const SubtaskDetailDataResolver = ({
    taskId,
    onClose,
    translate,
    ...otherProps
}: Omit<SubtaskDetailProps, "measure" | "task"> & { taskId: number | null }) => {
    const taskQuery = useSubTask(taskId);

    const contextMeasure = useUnsafeMeasureContext();

    // Determine which measure should be passed to the MeasureContext
    // subTask.measure is a FlatMeasureDto which is not sufficient
    // A measure will be provided as props in the MeasureDetailView, in that case that measure can be used from context
    const measureQuery = useMeasureQuery(contextMeasure == null ? taskQuery.data?.measureId : undefined);
    const resolvedMeasure = contextMeasure ?? measureQuery.data;

    if (!taskQuery.isSuccess || resolvedMeasure?.id == null) {
        return (
            <Card component={ScrollableContent}>
                {taskQuery.isLoading || measureQuery.isLoading ? <SubtaskDetailSkeleton /> : null}
                {taskQuery.isError || measureQuery.isError ? (
                    <Alert severity="error" onClose={onClose}>
                        {translate(translationKeys.VDLANG_ACTIVITIES_NOT_ALLOWED_ERROR)}
                    </Alert>
                ) : null}
            </Card>
        );
    }

    return (
        <MeasureContextProvider measure={resolvedMeasure}>
            <SubtaskDetail {...otherProps} onClose={onClose} translate={translate} task={taskQuery.data} />
        </MeasureContextProvider>
    );
};

export default SubtaskDetailDataResolver;
