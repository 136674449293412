import HelpOutlineRounded from "@mui/icons-material/HelpOutlineRounded";
import { IconButton, Popover, styled } from "@mui/material";
import React from "react";
import NavbarHelpPopupContent from "./NavbarHelpPopupContent";

const HelpIconButton = styled(IconButton)(({ theme }) => ({
    padding: 0,
    color: theme.palette.valueBlue.contrastText,
    marginRight: theme.spacing(3.5),
    transition: theme.transitions.create("margin"),
    [theme.breakpoints.down("lg")]: {
        marginRight: theme.spacing(1),
    },
}));

const NavbarHelp = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <span>
            <HelpIconButton size="small" color="inherit" onClick={handleClick}>
                <HelpOutlineRounded />
            </HelpIconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <NavbarHelpPopupContent />
            </Popover>
        </span>
    );
};

export default NavbarHelp;
