import { Button, ButtonProps, Stack, TooltipProps, buttonClasses, iconClasses, keyframes, styled, tooltipClasses } from "@mui/material";

import { ReactionType } from "api-shared";
import { useTranslation } from "react-i18next";
import { trackEvent } from "../../infrastructure/tracking";
import { translationKeys } from "../../translations/main-translations";
import Tooltip from "../Tooltip";
import EmojiIcon from "./EmojiIcon";

type ReactionWithThumbsup<T extends ReactionType> = T | ReactionType.Thumbsup;

interface ReactionButtonProps<T extends ReactionType> {
    reactionConfig: Map<ReactionWithThumbsup<T>, { icon: string; translationKey: string }>;
    currentUserReaction: ReactionWithThumbsup<T> | undefined;
    onReactionChange: (type: ReactionWithThumbsup<T> | undefined) => void;
}

const bounce = keyframes`
0% { transform:translateY(-200%); }
25% { transform:translateY(-150%); }   
50% { transform:translateY(0%); }
70% { transform:translateY(-15%); }
80% { transform:translateY(0%); }
90% { transform:translateY(-7%); }
95% { transform:translateY(0%); }
98% { transform:translateY(-3%); }
100% { transform:translateY(0); }
`;

const EmojiIconClickable = styled(EmojiIcon)(({ theme }) => ({
    fontSize: theme.typography.h6.fontSize,
    cursor: "pointer",
    "&:hover": {
        transform: "scale(1.4)",
    },
    animation: `${bounce} 500ms ease`,
}));

export const SmallIconButton = styled(Button)<ButtonProps>(({ theme }) => ({
    [`& .${buttonClasses.startIcon}`]: {
        ...theme.typography.body2,
        fontWeight: "inherit",
    },
    // Additional selector needed to override font size of icon:  https://github.com/mui/material-ui/issues/28917#issuecomment-939862001
    [`& .${buttonClasses.startIcon} > *:nth-of-type(1)`]: {
        ...theme.typography.body2,
        fontWeight: "inherit",
    },
    [`& .${iconClasses.root}`]: {
        fontSize: theme.typography.body2.fontSize,
    },
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& > .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            // important to override the popper placement top margin
            margin: "0 !important",
        },
    }),
);

// We need to overwrite the styles for the "hide" popper modifier here because of Tooltip in Tooltip.
// Tooltips always count as "escaped" because of the component structure and will not be visible otherwise.
const NonVanishingTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
    () => ({
        [`&[data-popper-escaped]`]: {
            visibility: "visible",
        },
        [`&[data-popper-reference-hidden]`]: {
            visibility: "visible",
        },
    }),
);

const ReactionButton = <T extends ReactionType>({ onReactionChange, reactionConfig, currentUserReaction }: ReactionButtonProps<T>) => {
    const { t } = useTranslation();

    const handleSelectReactionClick = (type: ReactionWithThumbsup<T> | undefined) => {
        onReactionChange(currentUserReaction === type ? undefined : type);
        trackEvent({ category: "Feed", action: "Feed Entry Reaction Changed" });
    };

    const handleButtonClick = () => {
        if (currentUserReaction === undefined) {
            handleSelectReactionClick(ReactionType.Thumbsup);
        } else {
            handleSelectReactionClick(undefined);
        }
    };

    return (
        <>
            <LightTooltip
                data-testid="reaction-tooltip"
                enterDelay={0}
                leaveDelay={300}
                title={
                    <Stack direction="row" p={0.5} gap={1} overflow="hidden">
                        {[...reactionConfig.entries()].map(([type, reaction]) => (
                            <NonVanishingTooltip
                                key={reaction.translationKey}
                                title={t(reaction.translationKey)}
                                placement="top"
                                PopperProps={{ disablePortal: true }}
                            >
                                <EmojiIconClickable onClick={() => handleSelectReactionClick(type)} fontSize="small">
                                    {reaction.icon}
                                </EmojiIconClickable>
                            </NonVanishingTooltip>
                        ))}
                    </Stack>
                }
                placement="top-start"
                sx={{ backgroundColor: (theme) => theme.palette.background.default, color: (theme) => theme.palette.text.primary }}
            >
                <SmallIconButton
                    variant="text"
                    onClick={handleButtonClick}
                    sx={{
                        color: currentUserReaction !== undefined ? "primary.main" : "text.primary",
                    }}
                    startIcon={
                        currentUserReaction !== undefined ? <EmojiIcon>{reactionConfig.get(currentUserReaction)?.icon}</EmojiIcon> : null
                    }
                >
                    {currentUserReaction === undefined
                        ? t(translationKeys.VDLANG_REACTION_REACT)
                        : t(reactionConfig.get(currentUserReaction)?.translationKey ?? "")}
                </SmallIconButton>
            </LightTooltip>
        </>
    );
};

export default ReactionButton;
