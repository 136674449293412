import {
    CalculationHistoryType,
    CurrencyDto,
    EffectCategoryHistoryEntryDto,
    EffectField,
    EffectType,
    HistoryEventType,
    MeasureCalculationGranularity,
    type CalculationHistoryEntryDto,
} from "api-shared";
import { TFunction } from "i18next";
import { camelCase } from "lodash";
import moment, { MomentInput } from "moment";
import { CurrencyFormatter } from "../hooks/useCurrency";
import { DateTimeFormatter } from "../hooks/useTimezone";
import { calendarToFiscal, formatFiscalUnit } from "./fiscal-units";

interface HistoryDto {
    attribute: any;
    newValue: string | number | null;
}

export function isId(value: number) {
    return Number.isInteger(+value);
}

export function isMatching(targetValue: string | null | undefined, value: string | null) {
    return targetValue === undefined || targetValue === value;
}

export function attributeName({ attribute }: HistoryDto, translate: TFunction) {
    if (attribute == null || isId(+attribute)) {
        // dont try to translate ids
        return "";
    }
    // use camelCase here because of start_date and end_date
    return attribute.length > 0 ? translate([attribute, camelCase(attribute)]) : "";
}

export function formatCalculationValue(
    value: string | number | null,
    formatMonthYear: DateTimeFormatter,
    formatCurrency: CurrencyFormatter,
    customCurrency?: CurrencyDto | string,
) {
    if (value == null) {
        return "";
    }
    if (Number.isNaN(+value)) {
        return formatMonthYear(value as MomentInput);
    }
    return formatCurrency(value, customCurrency);
}

export function captureType(value: string | null, effectType: EffectType, translate: TFunction) {
    if (value === null) {
        return "";
    }
    if (value === "0") {
        return translate(`effect_type_${effectType}`);
    }
    return `${translate(EffectField.Initial)}/${translate(EffectField.Target)}`;
}

export function effectDate(item: CalculationHistoryEntryDto, fiscalYearStartMonth: number, translate: TFunction) {
    const startMoment = moment.utc(item.effectStartDate);
    const endMoment = moment.utc(item.effectEndDate);
    const durationInMonths = endMoment.diff(startMoment, "months") + 1; // endDate is the last day of the included month -> +1 for full month difference

    let granularity: MeasureCalculationGranularity | undefined = undefined;

    if (durationInMonths === 1) {
        // only a single month -> no fiscal year needed but still do not format as month range
        granularity = MeasureCalculationGranularity.MONTH;
    } else if (durationInMonths === 3 && startMoment.month() % 3 === fiscalYearStartMonth % 3) {
        // a quarter that is aligned with fiscalyear
        granularity = MeasureCalculationGranularity.FISCAL_QUARTER;
    } else if (durationInMonths === 12 && startMoment.month() === fiscalYearStartMonth) {
        // a year that is aligned with fiscal year
        granularity = MeasureCalculationGranularity.FISCAL_YEAR;
    }

    if (granularity != null) {
        // time range matches a fiscal unit -> prefer to display that
        return formatFiscalUnit(calendarToFiscal(startMoment, fiscalYearStartMonth, granularity), granularity, translate);
    }

    // fallback any for arbitrary timespan (e.g. when fiscal year has changed)
    // show start -> end timerange in month and year
    return `${startMoment.format("MMM YYYY")} - ${endMoment.format("MMM YYYY")}`;
}

export function getEffectType(historyEntries: (EffectCategoryHistoryEntryDto | CalculationHistoryEntryDto)[]) {
    const effectTypeEntry = historyEntries.find((entry) => {
        return (
            entry.historyType === CalculationHistoryType.EffectCategory &&
            entry.attribute === "effect_type" &&
            entry.operationType === HistoryEventType.INSERT &&
            entry.newValue !== null
        );
    });

    if (effectTypeEntry?.newValue == null) {
        return EffectType.Savings;
    }
    return +effectTypeEntry.newValue as EffectType;
}

export const replaceImage = (value: string): string => {
    const base64regex = /!\[.*\]\(data:([A-Za-z-+/]+);base64,(.+)\)/g;
    if (value && base64regex.test(value)) {
        return value.replaceAll(base64regex, "🖼");
    }
    return value;
};
