import React from "react";
import { useTranslation } from "react-i18next";
import { useCompany } from "../../domain/endpoint";
import UnconnectedUserEntryWithPopup, { IUnconnectedUserEntryWithPopupProps } from "./UnconnectedUserEntryWithPopup";

type IUserEntryWithPopupProps = Omit<IUnconnectedUserEntryWithPopupProps, "company" | "translate">;

const UserEntryWithPopup = React.forwardRef<any, IUserEntryWithPopupProps>((props, ref) => {
    const company = useCompany(props.user?.companyId ?? undefined);
    const { t: translate } = useTranslation();
    return <UnconnectedUserEntryWithPopup ref={ref} company={company} translate={translate} {...props} />;
});

export default UserEntryWithPopup;
