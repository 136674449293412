import { Button, ButtonProps } from "@mui/material";

interface IDangerButtonProps extends ButtonProps {
    soft?: boolean;
}

const DangerButton = ({ soft, ...otherProps }: IDangerButtonProps) => {
    return <Button color="error" variant={soft ? "outlined" : "contained"} {...otherProps} />;
};

export default DangerButton;
