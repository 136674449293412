import { useQuery } from "@tanstack/react-query";
import {
    CompletedEffectsRequestBody,
    FieldDefinitionsDto,
    LiveRunUpDto,
    LiveRunUpRequestBody,
    MeasureTimelineRequestBody,
    PivotRequestBody,
    PivotResponseDto,
    ProjectProgressDto,
    ProjectProgressRequestBody,
    ReportingCompletedEffectsDto,
    RollingForecastListDto,
    RollingForecastRequestBody,
    TimelineDto,
    WeeklySavingsRunUpRequestBody,
    WeeklySavingsRunUp,
    WaterfallDataRequestBody,
    WaterfallData,
} from "api-shared";
import { apiGet, apiPost } from "../lib/api";

export const ReportingQueryKeys = {
    all: ["reporting"] as const,
    completedEffects: () => [...ReportingQueryKeys.all, "completedEffects"] as const,
    completedEffectsFields: () => [...ReportingQueryKeys.completedEffects(), "fields"] as const,
    completedEffectsQuery: (config: CompletedEffectsRequestBody) => [...ReportingQueryKeys.completedEffects(), "query", config] as const,
    pivot: () => [...ReportingQueryKeys.all, "pivot"] as const,
    pivotFields: () => [...ReportingQueryKeys.pivot(), "fields"] as const,
    pivotQuery: (config: PivotRequestBody) => [...ReportingQueryKeys.pivot(), "query", config] as const,
    rollingForecast: (options: RollingForecastRequestBody) => [...ReportingQueryKeys.all, "rollingForecast", options] as const,
    liveRunUp: (options: LiveRunUpRequestBody) => [...ReportingQueryKeys.all, "liveRunUp", options] as const,
    timeline: (options: MeasureTimelineRequestBody) => [...ReportingQueryKeys.all, "timeline", options] as const,
    timelineFields: () => [...ReportingQueryKeys.all, "timeline", "fields"] as const,
    projectProgress: (options: ProjectProgressRequestBody) => [...ReportingQueryKeys.all, "projectProgress", options] as const,
    projectProgressFields: () => [...ReportingQueryKeys.all, "projectProgress", "fields"] as const,
    weeklySavingsRunUp: (options: WeeklySavingsRunUpRequestBody) => [...ReportingQueryKeys.all, "weeklySavingsRunUp", options] as const,
    waterfallData: (options: WaterfallDataRequestBody) => [...ReportingQueryKeys.all, "waterfallData", options] as const,
    waterfallFields: () => [...ReportingQueryKeys.all, "waterfallFields"] as const,
};

export const useCompletedEffects = (options: CompletedEffectsRequestBody, enabled = true) => {
    return useQuery({
        queryKey: ReportingQueryKeys.completedEffectsQuery(options),
        queryFn: async ({ queryKey }) => {
            const response = await apiPost<ReportingCompletedEffectsDto>("reporting/completed-effects/query", queryKey[3]);
            return response.data;
        },
        enabled,
    });
};

export const useCompletedEffectsFieldDefinitions = (enabled = true) => {
    return useQuery({
        enabled,
        queryKey: ReportingQueryKeys.completedEffectsFields(),
        queryFn: ({ signal }) => apiGet<FieldDefinitionsDto>("reporting/completed-effects/fields", { signal }),
    });
};

export const useTimelineFieldDefinitions = (enabled = true) => {
    return useQuery({
        enabled,
        queryKey: ReportingQueryKeys.timelineFields(),
        queryFn: ({ signal }) => apiGet<FieldDefinitionsDto>("reporting/timeline/fields", { signal }),
    });
};

type UsePivotDataOptions = PivotRequestBody & {
    enabled?: boolean;
};

export const usePivotData = ({ enabled = true, ...options }: UsePivotDataOptions) => {
    return useQuery({
        queryKey: ReportingQueryKeys.pivotQuery(options),
        queryFn: async ({ queryKey }) => {
            const response = await apiPost<PivotResponseDto>("reporting/pivot", queryKey[3]);
            return response.data;
        },
        enabled,
    });
};

export const usePivotFields = (enabled = true) => {
    return useQuery({
        enabled,
        queryKey: ReportingQueryKeys.pivotFields(),
        queryFn: ({ signal }) => apiGet<FieldDefinitionsDto>("reporting/pivot/fields", { signal }),
    });
};

export const useRollingForecastEffects = (options: RollingForecastRequestBody, enabled: boolean) => {
    return useQuery({
        queryKey: ReportingQueryKeys.rollingForecast(options),
        queryFn: async ({ queryKey, signal }) => apiPost<RollingForecastListDto>("reporting/forecast/query", queryKey[2], { signal }),
        enabled,
        meta: {
            // ignore error notification because errors are shown inline
            ignoreErrors: true,
        },
    });
};

export const useLiveRunUpData = (options: LiveRunUpRequestBody, enabled: boolean) => {
    return useQuery({
        queryKey: ReportingQueryKeys.liveRunUp(options),
        queryFn: async ({ queryKey, signal }) => apiPost<LiveRunUpDto>("reporting/live-run-up", queryKey[2], { signal }),
        enabled,
    });
};

export const useTimelineData = (options: MeasureTimelineRequestBody, enabled: boolean) => {
    return useQuery({
        queryKey: ReportingQueryKeys.timeline(options),
        queryFn: async ({ queryKey, signal }) => apiPost<TimelineDto>("reporting/timeline/query", queryKey[2], { signal }),
        enabled,
    });
};

export const useProjectProgressData = (options: ProjectProgressRequestBody, enabled: boolean) => {
    return useQuery({
        queryKey: ReportingQueryKeys.projectProgress(options),
        queryFn: async ({ queryKey, signal }) => apiPost<ProjectProgressDto>("reporting/project-progress", queryKey[2], { signal }),
        enabled,
    });
};

export const useProjectProgressFields = (enabled = true) => {
    return useQuery({
        enabled,
        queryKey: ReportingQueryKeys.projectProgressFields(),
        queryFn: ({ signal }) => apiGet<FieldDefinitionsDto>("reporting/project-progress/fields", { signal }),
    });
};

export const useWeeklySavingsRunUpData = (options: WeeklySavingsRunUpRequestBody, enabled: boolean) => {
    return useQuery({
        queryKey: ReportingQueryKeys.weeklySavingsRunUp(options),
        queryFn: ({ queryKey, signal }) => apiPost<WeeklySavingsRunUp>("reporting/weekly-savings-run-up", queryKey[2], { signal }),
        enabled,
    });
};

export const useWaterfallData = (options: WaterfallDataRequestBody, enabled: boolean) => {
    return useQuery({
        queryKey: ReportingQueryKeys.waterfallData(options),
        queryFn: ({ queryKey, signal }) => apiPost<WaterfallData>("reporting/waterfall", queryKey[2], { signal }),
        enabled,
    });
};

export const useWaterfallFields = (enabled = true) => {
    return useQuery({
        enabled,
        queryKey: ReportingQueryKeys.waterfallFields(),
        queryFn: ({ signal }) => apiGet<FieldDefinitionsDto>("reporting/waterfall/fields", { signal }),
    });
};
