import { Shadows } from "@mui/material/styles";

const STYLEGUIDE_SHADOWS = {
    none: "none",
    shadow2: "0px 0px 2px 0px rgba(14, 14, 14, 0.16), 0px 1px 2px 0px rgba(14, 14, 14, 0.12)",
    shadow4: "0px 0px 2px 0px rgba(14, 14, 14, 0.16), 0px 2px 4px 0px rgba(14, 14, 14, 0.12)",
    shadow8: "0px 0px 4px 0px rgba(14, 14, 14, 0.16), 0px 4px 8px 0px rgba(14, 14, 14, 0.12)",
    shadow16: "0px 0px 4px 0px rgba(14, 14, 14, 0.14), 0px 8px 16px 0px rgba(14, 14, 14, 0.12)",
    shadow32: "0px 0px 8px 0px rgba(14, 14, 14, 0.14), 0px 16px 32px 0px rgba(14, 14, 14, 0.12)",
    shadow64: "0px 0px 8px 0px rgba(14, 14, 14, 0.14), 0px 32px 64px 0px rgba(14, 14, 14, 0.12)",
};

export const SHADOWS: Shadows = [
    "none",
    STYLEGUIDE_SHADOWS.shadow2,
    STYLEGUIDE_SHADOWS.shadow4,
    STYLEGUIDE_SHADOWS.shadow8,
    STYLEGUIDE_SHADOWS.shadow8,
    STYLEGUIDE_SHADOWS.shadow8,
    STYLEGUIDE_SHADOWS.shadow16,
    STYLEGUIDE_SHADOWS.shadow16,
    STYLEGUIDE_SHADOWS.shadow16,
    STYLEGUIDE_SHADOWS.shadow16,
    STYLEGUIDE_SHADOWS.shadow16,
    STYLEGUIDE_SHADOWS.shadow16,
    STYLEGUIDE_SHADOWS.shadow16,
    STYLEGUIDE_SHADOWS.shadow32,
    STYLEGUIDE_SHADOWS.shadow32,
    STYLEGUIDE_SHADOWS.shadow32,
    STYLEGUIDE_SHADOWS.shadow32,
    STYLEGUIDE_SHADOWS.shadow32,
    STYLEGUIDE_SHADOWS.shadow32,
    STYLEGUIDE_SHADOWS.shadow32,
    STYLEGUIDE_SHADOWS.shadow32,
    STYLEGUIDE_SHADOWS.shadow32,
    STYLEGUIDE_SHADOWS.shadow32,
    STYLEGUIDE_SHADOWS.shadow32,
    STYLEGUIDE_SHADOWS.shadow32,
];
