import { defaultCurrency } from "../lib/defaults";
import { useCurrentClient } from "./client";

export function useCurrencies() {
    const currentClient = useCurrentClient();
    return currentClient.currencies ?? [];
}

export function useDefaultCurrency() {
    const currencies = useCurrencies();
    return currencies.find((c) => c.isDefault) ?? defaultCurrency;
}
