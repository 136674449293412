import { CommentDto } from "api-shared";
import CommentList from "../../components/comment/CommentList";
import MarkdownEditorUserMentionsIdea from "../../components/markdowneditor/MarkdownEditorUserMentionsIdea";
import { CommentParentType, useCreateComment, useDeleteComment, useUpdateComment } from "../../domain/comment";

export interface IIdeaCommentListProps {
    ideaId: number;
    comments: CommentDto[];
    isReadonly?: boolean;
}

const IdeaCommentList = ({ ideaId, comments, isReadonly = false }: IIdeaCommentListProps) => {
    const createCommentMutation = useCreateComment();
    const updateCommentMutation = useUpdateComment();
    const deleteCommentMutation = useDeleteComment();

    const updateComment = (commentId: number, comment: string) =>
        updateCommentMutation.mutate({ parentType: CommentParentType.IDEA, comment, commentId });

    const deleteComment = (commentId: number) =>
        ideaId && deleteCommentMutation.mutate({ parentType: CommentParentType.IDEA, commentId, parentId: ideaId });

    const createComment = (_: number, comment: string) =>
        ideaId &&
        createCommentMutation.mutate({
            parentId: ideaId,
            parentType: CommentParentType.IDEA,
            comment,
        });

    return (
        <CommentList
            parentId={ideaId}
            comments={comments}
            deleteComment={deleteComment}
            updateComment={updateComment}
            createComment={createComment}
            customEditorComponent={MarkdownEditorUserMentionsIdea}
            disabled={isReadonly}
        />
    );
};

export default IdeaCommentList;
