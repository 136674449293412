import { Button, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import Link from "../../components/Link";
import ValuedeskIcon from "../../components/icons/ValuedeskIcon";
import { RouteFor } from "../../lib/routes";

const RootButton = styled(Button)(({ theme }) => ({
    borderRadius: 0,
    height: "100%",
})) as typeof Button; // https://github.com/mui/material-ui/issues/15759#issuecomment-984553630

const WhiteAnimatedValuedeskIcon = styled(ValuedeskIcon)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.pxToRem(47),
    transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("md")]: {
        fontSize: theme.typography.pxToRem(39),
        transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

const NavbarLogo = () => {
    const { pathname } = useLocation();
    const queryClient = useQueryClient();
    const handleClick = () => {
        // if the current location is a protected route, invalidate all queries to ensure correct data is fetched
        if (pathname.includes(RouteFor.protected.view)) {
            queryClient.invalidateQueries();
        }
    };
    return (
        <RootButton component={Link} to="/" variant="contained" color="primary" onClick={handleClick}>
            <WhiteAnimatedValuedeskIcon fontSize="large" />
        </RootButton>
    );
};

export default NavbarLogo;
