import {
    buttonBaseClasses,
    DialogContentText,
    Divider,
    ListItemIcon,
    listItemIconClasses,
    ListItemText,
    MenuItem,
    menuItemClasses,
    MenuList,
    styled,
} from "@mui/material";
import { SearchResultMatch } from "api-shared";
import { isEqual, range } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import MeasureIdChip from "../../components/MeasureIdChip";
import { SearchResult } from "../../domain/search";
import { translationKeys } from "../../translations/main-translations";
import IdeaIdChip from "../ideas/IdeaIdChip";

const resetVerticalMarginClass = "resetVerticalMarginClass";
const CustomDivider = styled("li")({
    [`&.${resetVerticalMarginClass}`]: {
        // Reset the default margin when divider is placed between two buttons
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
});

const cssMinWidthFix = "cssMinWidthFix";

const SearchResultLink = styled(Link)(({ theme }) => ({
    [`& .${listItemIconClasses.root}.${cssMinWidthFix}`]: {
        minWidth: theme.spacing(7), // reserve a little more space for id chips, as they are larger than regular buttons
        justifyContent: "center",
    },
}));

const SearchResultMenuList = styled(MenuList, { shouldForwardProp: (prop) => prop !== "embedded" })<{ embedded?: boolean }>(
    ({ embedded, theme }) => ({
        overflow: "auto",
        paddingTop: 0,
        [`& .${buttonBaseClasses.root}.${menuItemClasses.root}`]: {
            minHeight: theme.spacing(4),
        },
        ...(embedded && {
            margin: theme.spacing(1, 0),
            paddingBottom: 0,
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius,
        }),
    }),
);

const highlightClass = "VdSearchResultList-highlight";

const SearchResultItemText = styled(ListItemText)(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize,
    width: 0, // enable ellipsis shortening on long titles
    [`& .${highlightClass}`]: {
        backgroundColor: "rgba(198, 167, 0, 0.25)",
    },
}));

function highlightSearchResultMatches(inputText: string, searchKey: string, regions: SearchResultMatch["indices"], minLength = 3) {
    const highlightedNodes: React.ReactNode[] = [];
    const filteredRegions = regions.filter((region) => {
        const isNumericTerm = !isNaN(Number(searchKey)); // to account for ID searches
        const matchCandidateLength = region[1] - region[0] + 1;
        // If there are multiple matches we only want to highlight the most relevant ones to reduce noise for the user
        if (regions.length > 1) {
            // Only include matches within a certain resonable range of the search key length
            const searchKeyLengthRange = range(searchKey.length >= 2 ? searchKey.length - 2 : 0, searchKey.length + 2);
            // For multiple matches in a single title, highlight only the most relevant
            return isNumericTerm || (matchCandidateLength >= minLength && searchKeyLengthRange.includes(matchCandidateLength));
        }
        // Unless searching for a number, limit highlights to minimum search key length or greater
        return isNumericTerm || matchCandidateLength >= minLength;
    });
    let nextUnhighlightedRegionStartingIndex = 0;
    filteredRegions.forEach((region, i) => {
        const lastRegionNextIndex = region[1] + 1;
        const elementKey = `${region.join()}-${i}`;
        const highlightedText = inputText.slice(region[0], lastRegionNextIndex).replace(" ", "\u00A0");
        // Low character count highlights should only be included if they match the search key's character order
        if (!(highlightedText.length <= 3 && !isEqual(highlightedText.toLowerCase(), searchKey.toLowerCase().slice(0, 3)))) {
            highlightedNodes.push(
                ...[
                    inputText.slice(nextUnhighlightedRegionStartingIndex, region[0]).replace(" ", "\u00A0"),
                    <span key={elementKey} className={highlightClass}>
                        {highlightedText}
                    </span>,
                ],
            );
            nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
        }
    });
    highlightedNodes.push(inputText.slice(nextUnhighlightedRegionStartingIndex).replace(" ", "\u00A0"));
    return highlightedNodes;
}

interface ISearchResultListProps {
    isValidKey?: boolean;
    onSelect?: () => void;
    searchKey: string;
    results?: SearchResult[];
    isSearchInProgress?: boolean;
    minLength?: number;
    maxResults?: number;
    showSearchHints?: boolean;
    embedded?: boolean;
    highlightMatches?: boolean;
}

const SearchResultList = ({
    isSearchInProgress,
    isValidKey,
    results,
    onSelect,
    searchKey,
    maxResults,
    highlightMatches,
    minLength = 3,
    embedded = false,
    showSearchHints = true,
}: ISearchResultListProps) => {
    const { t: translate } = useTranslation();

    const processedResults = useMemo(() => {
        if (results === undefined) {
            return;
        }
        if (!highlightMatches) {
            return results;
        }
        return results
            .filter(({ matches }: SearchResult) => matches !== undefined && matches.length > 0)
            .map(({ matches, ...item }: SearchResult) => {
                const highlightedItem = { ...item };
                if (matches !== undefined) {
                    matches.forEach((match) => {
                        if (match.value !== undefined && match.key === "title") {
                            highlightedItem.title = highlightSearchResultMatches(match.value, searchKey, match.indices, minLength);
                        }
                    });
                }
                return highlightedItem;
            });
    }, [results, highlightMatches, minLength, searchKey]);

    if (isSearchInProgress) {
        return <LoadingAnimation sx={{ mb: 2 }} />;
    }

    if (!isValidKey && showSearchHints) {
        return (
            <DialogContentText sx={{ p: 2 }}>{translate(translationKeys.VDLANG_SEARCH_INPUT_HINT, { count: minLength })}</DialogContentText>
        );
    }

    if (processedResults == null || processedResults.length <= 0) {
        if (!showSearchHints) {
            return null;
        }
        return (
            <DialogContentText sx={{ p: 2 }}>{translate(translationKeys.VDLANG_SEARCH_NO_RESULTS, { key: searchKey })}</DialogContentText>
        );
    }

    return (
        <SearchResultMenuList embedded={embedded}>
            {processedResults.slice(0, maxResults).map((result, i) => [
                <MenuItem
                    key={result.id}
                    onClick={onSelect}
                    component={SearchResultLink}
                    to={result.href}
                    target={embedded ? "_blank" : "_self"}
                    sx={{ opacity: result.isActive ? 1 : 0.5, pl: embedded ? 0.5 : undefined }}
                >
                    <ListItemIcon className={cssMinWidthFix}>
                        {result.type === "measure" ? (
                            <MeasureIdChip measureId={result.displayId} />
                        ) : (
                            <IdeaIdChip ideaId={result.displayId} />
                        )}
                    </ListItemIcon>
                    <SearchResultItemText
                        primary={result.title}
                        primaryTypographyProps={{ noWrap: true, display: "block", fontSize: "inherit" }}
                    />
                </MenuItem>,
                i < processedResults.slice(0, maxResults).length - 1 && (
                    <Divider className={resetVerticalMarginClass} variant="fullWidth" component={CustomDivider} />
                ),
            ])}
        </SearchResultMenuList>
    );
};

export default SearchResultList;
