import CancelIcon from "@mui/icons-material/CancelRounded";
import { Chip, SvgIconProps, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { MultiValueProps } from "react-select";
import Tooltip from "../../../Tooltip";
import { Option } from "../types";

const Root = styled(Chip)(() => ({
    overflow: "hidden", // needed for proper ellipsis shortening
    backgroundColor: grey[200],
    // leave some space on the right for input cursor, so it does not break into new line with long chips
    // chip margins 2*2px
    // input margins 2*2px
    // input width 2px
    // => required space adds up to 10px
    maxWidth: "calc(100% - 10px)",
}));

const Label = styled("span")<{ reverseDirection?: boolean }>(({ reverseDirection }) => ({
    overflow: "hidden",
    display: "inline-block", // disable default flex behaviour to enable ellipsis shortening
    textOverflow: "ellipsis",
    width: "100%",
    lineHeight: "normal",
    verticalAlign: "middle",
    ...(reverseDirection && {
        direction: "rtl", // enable shortening on left side by reversing direction
    }),
}));

const SelectMultiValue = <OptionType extends Option, IsMulti extends boolean>({
    selectProps,
    data,
    children,
    removeProps,
}: MultiValueProps<OptionType, IsMulti>) => {
    const { ellipsisLeft } = selectProps;

    const { IconComponent } = data;
    const icon = IconComponent !== undefined ? <IconComponent /> : data.icon;
    return (
        <Root
            tabIndex={-1}
            label={
                /* Need to wrap children in span, because Tooltip accesses its children props,
                 * which are not available on text-only children
                 */
                <Tooltip title={children ?? ""}>
                    <Label reverseDirection={ellipsisLeft}>
                        {/* Add LTR unicode to force correct content in case of numbers or special characters */}
                        {"\u202A"}
                        {children}
                        {"\u202C"}
                    </Label>
                </Tooltip>
            }
            icon={icon}
            onDelete={!data.isFixed ? removeProps.onClick : undefined}
            // removeProps are typed as HTMLDivElement props, but actually contain only event handlers
            deleteIcon={<CancelIcon {...(removeProps as SvgIconProps)} />}
            size="small"
        />
    );
};

export default SelectMultiValue;
