import { Collapse } from "@mui/material";
import { CommentDto } from "api-shared";
import CommentList from "../../components/comment/CommentList";
import MarkdownEditorUserMentionsAll from "../../components/markdowneditor/MarkdownEditorUserMentionsAll";
import { CommentParentType, useCreateComment, useDeleteComment, useUpdateComment } from "../../domain/comment";
import { trackEvent } from "../../infrastructure/tracking";

interface FeedEntryCommentsProps {
    feedEntryId: number;
    comments: CommentDto[];
    showComments: boolean;
}

const FeedEntryCommentList = ({ feedEntryId, comments, showComments }: FeedEntryCommentsProps) => {
    const createCommentMutation = useCreateComment();
    const updateCommentMutation = useUpdateComment();
    const deleteCommentMutation = useDeleteComment();

    function createComment(_: number, comment: string) {
        feedEntryId && trackEvent({ category: "Feed", action: "Feed Item Commented" });
        return (
            feedEntryId &&
            createCommentMutation.mutate({
                parentId: feedEntryId,
                parentType: CommentParentType.FEED,
                comment,
            })
        );
    }

    function updateComment(commentId: number, comment: string) {
        return updateCommentMutation.mutate({ parentType: CommentParentType.FEED, comment, commentId });
    }

    function deleteComment(commentId: number) {
        return (
            feedEntryId &&
            deleteCommentMutation.mutate({
                parentType: CommentParentType.FEED,
                commentId,
                parentId: feedEntryId,
            })
        );
    }

    // ASC by createdAt
    const sortedComments = [...comments].sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });

    return (
        <Collapse in={showComments}>
            <CommentList
                parentId={feedEntryId}
                comments={sortedComments}
                customEditorComponent={MarkdownEditorUserMentionsAll}
                deleteComment={deleteComment}
                updateComment={updateComment}
                createComment={createComment}
                hideMessage
            />
        </Collapse>
    );
};

export default FeedEntryCommentList;
