import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    AclPermissions,
    BasicPermissionDto,
    MeasureStatus,
    PermissionBasicRequestParams,
    PermissionPermissionRequestParams,
    SimplePermissionsDto,
    AclFact,
} from "api-shared";
import { apiDelete, apiPost, apiPut } from "../../lib/api";
import { NotificationQueryKeys } from "../measure-notifications";

type EntityQueryParams = { permission: AclPermissions; measureId: number };
type PermissionQueryParams = { permission: AclPermissions; fact: AclFact };

export const MeasurePermissionsQueryKeys = {
    all: ["measures-permission"] as const,
    entity: (params: EntityQueryParams) => [...MeasurePermissionsQueryKeys.all, "entity", params] as const,
    permission: (params: PermissionQueryParams) => [...MeasurePermissionsQueryKeys.all, "permission", params] as const,
};

// Queries

export const useMeasureEditorsQuery = (measureId: number) => {
    return useQuery({
        queryKey: MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Update, measureId }),
        queryFn: ({ queryKey: [, , { measureId, permission }], signal }) => {
            return apiPost<SimplePermissionsDto>(`measures/${measureId}/permissions`, { permission }, { signal });
        },
    });
};

export const useMeasureViewersQuery = (measureId: number) => {
    return useQuery({
        queryKey: MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Read, measureId }),
        queryFn: ({ queryKey: [, , { measureId, permission }], signal }) => {
            return apiPost<SimplePermissionsDto>(`measures/${measureId}/permissions`, { permission }, { signal });
        },
    });
};

export const useMeasurePermissionsQuery = ({ permission, measureId }: EntityQueryParams) => {
    return useQuery({
        queryKey: MeasurePermissionsQueryKeys.entity({ permission, measureId }),
        queryFn: ({ queryKey: [, , { measureId, permission }], signal }) => {
            return apiPost<SimplePermissionsDto>(`measures/${measureId}/permissions`, { permission }, { signal });
        },
    });
};

export const useUserHasMeasureStatusPermissionQuery = (status: MeasureStatus) => {
    const permission = AclPermissions.Update;
    const fact = { status };

    return useQuery({
        queryKey: MeasurePermissionsQueryKeys.permission({ permission, fact }),
        queryFn: ({ queryKey: [, , { permission, fact }], signal }) => {
            return apiPost<BasicPermissionDto>(`measures/permissions`, { permission, fact }, { signal });
        },
    });
};

// Mutations

export const useMeasurePermissionsAddGroupMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ entityId, id, permission }: PermissionPermissionRequestParams) => {
            return apiPut(`measures/${entityId}/permissions/group/`, { id, permission });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.all);
        },
    });
};

export const useMeasurePermissionsAddUserMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ entityId, id, permission }: PermissionPermissionRequestParams) => {
            return apiPut(`measures/${entityId}/permissions/user/`, { id, permission });
        },
        onSuccess: (_, { entityId }) => {
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.all);
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(entityId));
        },
    });
};

export const useMeasurePermissionsRemoveGroupMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ entityId, id }: PermissionBasicRequestParams) => {
            return apiDelete(`measures/${entityId}/permissions/group/${id}`);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.all);
        },
    });
};

export const useMeasurePermissionsRemoveUserMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ entityId, id }: PermissionBasicRequestParams) => {
            return apiDelete(`measures/${entityId}/permissions/user/${id}`);
        },
        onSuccess: (_, { entityId }) => {
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.all);
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(entityId));
        },
    });
};

// Helper functions that rely on queries or mutations above

export const useIsUserMeasureEditor = (measureId: number, userId: number | null) => {
    const measureEditorsQuery = useMeasureEditorsQuery(measureId);

    if (userId === null || !measureEditorsQuery.isSuccess) {
        return false;
    }

    return measureEditorsQuery.data.combinedUserIds.includes(userId);
};

export const useIsUserMeasureViewer = (measureId: number, userId: number | null) => {
    const measureViewersQuery = useMeasureViewersQuery(measureId);

    if (userId === null || !measureViewersQuery.isSuccess) {
        return false;
    }

    return measureViewersQuery.data.combinedUserIds.includes(userId);
};
