import { alpha, ButtonProps, styled } from "@mui/material";
import { NavLink, NavLinkProps } from "react-router-dom";
import NavButton from "./NavButton";

const ActivatableNavButton = styled(NavButton)(({ theme }) => ({
    [`&.active`]: {
        backgroundColor: theme.palette.valueBlue.dark,
        color: theme.palette.valueBlue.contrastText,
        "&:hover": {
            backgroundColor: alpha(theme.palette.valueBlue.dark, 0.5),
            "@media (hover: none)": {
                backgroundColor: theme.palette.valueBlue.dark,
            },
        },
    },
})) as typeof NavButton;

const NavLinkButton = (props: ButtonProps<typeof NavLink, NavLinkProps>) => <ActivatableNavButton component={NavLink} {...props} />;

export default NavLinkButton;
