import { Grid, InputAdornment, TextField } from "@mui/material";
import { Operators } from "api-shared";
import { TFunction } from "i18next";
import moment from "moment";
import React, { useState } from "react";
import { DateTimeFormatter } from "../../hooks/useTimezone";
import { translationKeys } from "../../translations/main-translations";
import InfoIcon from "../icons/InfoIcon";

interface IRelativeTimerangeInputProps {
    value: string;
    updateValues: (value: string) => void;
    translate: TFunction;
    operator: Operators;
    formatDate: DateTimeFormatter;
    fullWidth?: boolean;
    disabled?: boolean;
}

const getInfoIconTitle = (from: string, now: string, next: string, operator: Operators, translate: TFunction) => {
    switch (operator) {
        case Operators.Within:
            return translate(translationKeys.VDLANG_DATERANGE, { start: from, end: now });
        case Operators.Before:
            return translate(translationKeys.VDLANG_DATERANGE_BEFORE, { date: from });
        case Operators.InMore:
            return translate(translationKeys.VDLANG_DATERANGE_AFTER, { date: next });
        case Operators.InNext:
            return translate(translationKeys.VDLANG_DATERANGE, { start: now, end: next });
        default:
            return "";
    }
};

const RelativeTimerangeInput = ({
    updateValues,
    translate,
    operator,
    formatDate,
    value,
    fullWidth,
    disabled,
}: IRelativeTimerangeInputProps) => {
    const [unsupportedChar, setUnsupportedChar] = useState(false);

    const validateAndUpdateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();
        const unsupportedChar = value.match(/^[0-9]*$/g) == null;
        setUnsupportedChar(unsupportedChar);

        if (!unsupportedChar) {
            updateValues(value);
        }
    };

    const now = formatDate(moment().format());
    const from = formatDate(moment().subtract(value, "days").format());
    const next = formatDate(moment().add(value, "days").format());
    const infoIconTitle = getInfoIconTitle(from, now, next, operator, translate);

    const helperText = unsupportedChar ? translate(translationKeys.VDLANG_ONLY_NUMBERS) : null;

    return (
        <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
                <TextField
                    placeholder={translate(translationKeys.VDLANG_ENTER_NUMBER_OF_DAYS)}
                    value={value}
                    onChange={validateAndUpdateValue}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{translate("days")}</InputAdornment>,
                    }}
                    helperText={helperText}
                    fullWidth={fullWidth}
                    disabled={disabled}
                />
            </Grid>
            <Grid item>
                <InfoIcon title={infoIconTitle} />
            </Grid>
        </Grid>
    );
};
export default RelativeTimerangeInput;
