import { LegacyRef, Ref } from "react";

/**
 * Compares two arrays if the contain the same items. Items will be compared with Array.prototype.includes() and order is ignored.
 *
 * @export
 * @template T
 * @param {T[]} lhs
 * @param {T[]} rhs
 * @returns true if both arrays contain the same elements (order is ignored)
 */
export function shallowArrayEquals<T>(lhs: T[], rhs: T[]) {
    return lhs.length === rhs.length && lhs.every((item) => rhs.includes(item));
}

/**
 * Shallow compares an object by iterating over all keys of boths objects and compareing their values.
 *
 * @export
 * @param {*} lhs
 * @param {*} rhs
 * @returns {string[]} an array of keys that differ. Is empty when both objects are equal.
 */
export function getDifferingKeys(lhs: any, rhs: any): string[] {
    const keys = [...new Set([...Object.keys(lhs), ...Object.keys(rhs)])];
    return [...keys].filter((key) => lhs[key] !== rhs[key]);
}

/**
 * Shallow compares an object by iterating over all keys of boths objects and compareing their values.
 * Uses getDifferingKeys internally
 * @export
 * @param {*} lhs
 * @param {*} rhs
 * @returns {boolean} whether both objects are shallowly equal
 */
export function shallowObjectEquals(lhs: any, rhs: any): boolean {
    return getDifferingKeys(lhs, rhs).length === 0;
}

/**
 * Does nothing. Can be used where a function prop is required, but not used. Using this noOp function provides a stable reference, so that
 * memoization still works.
 *
 */
export function noOp(): void {
    // empty by purpose
}

export function compareObjects(aObject: any, bObject: any) {
    const hasUpdatedFields = Object.entries(aObject).some(([k, v]) => bObject[k] !== v);
    const hasRemovedFields = Object.entries(bObject).some(([k, v]) => aObject[k] !== v);
    return {
        hasChanges: hasUpdatedFields || hasRemovedFields,
        updated: {
            ...aObject,
            ...bObject,
        },
    };
}

/**
 * Transpose an array of dimension 2, e.g.
 * [[1,2], ["a", "b"]] => [[1, "a"], [2, "b"]]
 *
 * @export
 * @template T
 * @param {T[][]} array
 * @returns {T[][]}
 */
export function transpose<T>(array: T[][]): T[][] {
    if (array.length === 0) {
        return [];
    }
    return array[0].map((_, colIndex) => array.map((row) => row[colIndex]));
}

/**
 * Compare two date, that may possible be null or undefined.
 *
 * Null or undefined values will always considered smallest
 *
 * @param {(Date | null | undefined)} a
 * @param {(Date | null | undefined)} b
 * @returns
 */
export function compareNullableDate(a: Date | null | undefined, b: Date | null | undefined) {
    if (a === b) {
        return 0;
    }
    if (a == null) {
        return -1;
    }
    if (b == null) {
        return 1;
    }
    return a > b ? 1 : -1;
}

/**
 * This function is for interoperability as long as legacy string refs are still officially supported by react. It will just ignore legacy
 * refs and return a valid non-legacy ref for further work. This is useful e.g. when combining MaterialUI components with react-select, where
 * the Material-UI components do not support legacy refs anymore, but react-select is still typed as if they might use legacy refs (but they
 * actually do not).
 *
 * @export
 * @template T
 * @param {(LegacyRef<T> | undefined)} ref
 * @returns {(Ref<T> | undefined)}
 */
export function dropLegacyRef<T>(ref: LegacyRef<T> | undefined): Ref<T> | undefined {
    if (typeof ref === "string") {
        // eslint-disable-next-line no-console
        console.warn("Dropped legacy string ref", ref);
        return undefined;
    }
    return ref;
}

/**
 * Reduce an array of arrays into a flat array.
 *
 * @param {*} children array of arrays
 */
export function safeFlatMap<T>(children: T[][]): T[] {
    return Array.isArray(children) ? children.reduce((acc, ids) => acc.concat(ids), []) : [];
}
