import { FeatureFlags } from "api-shared";
import React from "react";
import { useClientHasFeature } from "../domain/client";

interface IFeatureFlagProps {
    feature: FeatureFlags;
    targetValue?: number;
    fallbackComponent?: React.ComponentType;
}

const FeatureFlag = ({
    feature,
    targetValue,
    fallbackComponent: FallbackComponent,
    children,
}: React.PropsWithChildren<IFeatureFlagProps>) => {
    const hasFeature = useClientHasFeature(feature, targetValue);
    if (hasFeature) {
        // Not wrapping in a fragment yields typescript errors
        return <>{children}</>;
    }
    if (FallbackComponent != null) {
        return <FallbackComponent />;
    }
    return null;
};

export default FeatureFlag;
