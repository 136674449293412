import { ListItemIcon, ListItemText } from "@mui/material";
import { OptionProps } from "react-select";
import Tooltip from "../../../Tooltip";
import { Option } from "../types";
import SelectOptionContainer from "./SelectOptionContainer";

const SelectOption = <OptionType extends Option, IsMulti extends boolean>(props: OptionProps<OptionType, IsMulti>) => {
    const { data, children, isSelected } = props;
    const { IconComponent, primaryLabel, secondaryLabel } = data as Option;
    const icon = IconComponent !== undefined ? <IconComponent /> : data.icon;
    const primary = primaryLabel ?? children;
    return (
        <SelectOptionContainer {...props}>
            {icon != null ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <Tooltip onlyOverflowing title={primary}>
                <ListItemText
                    primaryTypographyProps={{ variant: "body2", noWrap: true }}
                    primary={isSelected ? <strong>{primary}</strong> : primary}
                    secondary={isSelected && secondaryLabel != null ? <strong>{secondaryLabel}</strong> : secondaryLabel}
                />
            </Tooltip>
        </SelectOptionContainer>
    );
};

export default SelectOption;
