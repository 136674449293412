import { all, Effect, fork } from "redux-saga/effects";
import { getTranslationsSaga } from "../domain/translation";
import { authSaga } from "../lib/authentication-saga";
import { bootstrapSaga } from "../lib/bootstrap";
import { disposeSaga } from "../lib/dispose-saga";

/**
 * Root saga. Reponsible for setting up all other sagas.
 *
 * Starts bootstrapping process when sagas have been setup.
 *
 * @export
 * @returns {Generator<Effect>}
 */
export default function* root(): Generator<Effect> {
    // setup all "listening" sagas
    yield all([fork(getTranslationsSaga), fork(authSaga), fork(bootstrapSaga), fork(disposeSaga)]);
}
