import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFeedEntry } from "../../domain/feed";
import { RouteFor } from "../../lib/routes";
import FeedEntry from "./FeedEntry";
import FeedEntrySkeleton from "./FeedEntrySkeleton";

interface IFeedEntryDetailsProps {
    feedEntryId: number;
}

const FeedEntryDetails = ({ feedEntryId }: IFeedEntryDetailsProps) => {
    const feedEntryQuery = useFeedEntry(feedEntryId);
    const navigate = useNavigate();

    useEffect(() => {
        if (feedEntryQuery.isFetching) {
            return;
        }
        if (feedEntryQuery.isError && !feedEntryQuery.isLoading && feedEntryId !== undefined) {
            navigate(RouteFor.feed.view);
        }
    }, [feedEntryId, navigate, feedEntryQuery, feedEntryQuery.isFetching]);

    if (feedEntryQuery.isLoading) {
        return <FeedEntrySkeleton />;
    }

    if (!feedEntryQuery.isLoading && feedEntryQuery.data) {
        return <FeedEntry key={feedEntryQuery.data.id} feedEntry={feedEntryQuery.data} />;
    }

    return <></>;
};

export default FeedEntryDetails;
