import { UserDto } from "api-shared";
import { isActiveUser } from "../../domain/users";
import { translationKeys } from "../../translations/main-translations";
import SelectUsers, { ISelectUsersProps } from "./SelectUsers";

export interface ISingleUserContentProps
    extends Omit<ISelectUsersProps, "selected" | "labelChange" | "labelFill" | "addUser" | "removeUser"> {
    user: UserDto | null | undefined;
    updateUser: (id: number | null) => void;
}

const SingleUserContent = ({ user, updateUser, available = isActiveUser, translate, ...otherProps }: ISingleUserContentProps) => (
    <SelectUsers
        available={available}
        selected={user != null ? user.id : null}
        selectedTitle={translate(translationKeys.VDLANG_RESPONSIBLE_CURRENT_PERSON)}
        labelChange={translate(translationKeys.VDLANG_RESPONSIBLE_CHANGE_PERSON)}
        labelFill={translate("Assign responsible person")}
        addUser={updateUser}
        removeUser={() => updateUser(null)}
        translate={translate}
        {...otherProps}
    />
);

export default SingleUserContent;
