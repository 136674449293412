import { Alert, AlertTitle } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface IErrorBannerProps {
    title?: string | null;
}

const ErrorBanner = React.forwardRef<HTMLDivElement, PropsWithChildren<IErrorBannerProps>>(({ children, title }, ref) => (
    <Alert severity="error" ref={ref}>
        {title != null ? <AlertTitle>{title}</AlertTitle> : null}
        {children}
    </Alert>
));

export default ErrorBanner;
