import { Suspense } from "react";
import { Navigate, Route, Routes as RouterRoutes, useLocation } from "react-router-dom";
import LoadingAnimation from "../components/loading/LoadingAnimation";
import { useCurrentClientQuery } from "../domain/client";
import { useCustomTranslationsQuery } from "../domain/translation";
import { useUiStateQuery } from "../domain/ui-state";
import { useCurrentUserQuery } from "../domain/users";
import useTrackedSession from "../hooks/useTrackedSession";
import { AuthenticatedContextProvider } from "../infrastructure/AuthenticatedContext";
import { RouteFor, isUnauthenticatedRoute } from "../lib/routes";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import InviteConfirm from "./user/InviteConfirm";
import LoginPage from "./user/LoginPage";
import PasswordReset from "./user/PasswordReset";
import PasswordResetConfirm from "./user/PasswordResetConfirm";
import TwoFactorAuthenticationSetupPage from "./user/TwoFactorAuthenticationSetupPage";

const Routes = () => {
    const { pathname } = useLocation();

    const isUnauthenticated = isUnauthenticatedRoute(pathname);

    // only trigger user query on routes that require authentication
    const userQuery = useCurrentUserQuery(!isUnauthenticated);

    // only trigger client and translation queries, when user is authenticated
    const clientQuery = useCurrentClientQuery(userQuery.isSuccess);
    const areTranslationsApplied = useCustomTranslationsQuery(userQuery.isSuccess);
    const uiStateQuery = useUiStateQuery(userQuery.isSuccess);

    useTrackedSession();

    if (isUnauthenticated) {
        // Unauthenticated routes
        return (
            <Suspense fallback={<LoadingAnimation />}>
                <RouterRoutes>
                    <Route path={RouteFor.user.login} element={<LoginPage isLoggedIn={userQuery.isSuccess} />} />
                    <Route path={RouteFor.user.twoFactorAuthenticationSetup} element={<TwoFactorAuthenticationSetupPage />} />
                    <Route path={RouteFor.user.passwordReset} element={<PasswordReset />} />
                    <Route path={RouteFor.protected.passwordReset} element={<PasswordResetConfirm />} />
                    <Route path={RouteFor.protected.invite} element={<InviteConfirm />} />
                    <Route path="*" element={<Navigate to={RouteFor.user.login} state={{ referrer: pathname }} />} />
                </RouterRoutes>
            </Suspense>
        );
    }

    if (userQuery.isError) {
        return <Navigate to={RouteFor.user.login} state={{ referrer: pathname }} />;
    }

    if (!userQuery.isSuccess || !areTranslationsApplied || !uiStateQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    if (clientQuery.isError) {
        // We do not want to throw an error, when the authtoken expires and the queries are refetched.
        return <LoadingAnimation />;
    }

    if (!clientQuery.isSuccess) {
        // This will not happen with React.Suspense, but if it does, it should be reported to sentry
        throw new Error("No client details found");
    }

    return (
        <AuthenticatedContextProvider client={clientQuery.data} user={userQuery.data}>
            <AuthenticatedRoutes />
        </AuthenticatedContextProvider>
    );
};

export default Routes;
