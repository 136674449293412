import MuiInfoIcon from "@mui/icons-material/InfoRounded";
import { styled, SvgIconProps } from "@mui/material";
import React from "react";
import withTooltip from "../../hocs/withTooltip";

const StyledInfoIcon = styled(MuiInfoIcon)(() => ({
    verticalAlign: "-0.125em",
}));

const InfoIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => <StyledInfoIcon fontSize="inherit" {...props} ref={ref} />);

export default withTooltip(InfoIcon);
