import CancelRounded from "@mui/icons-material/CancelRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearRounded from "@mui/icons-material/ClearRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Chip, Divider, Grid, IconButton, Paper, Stack, styled, Typography } from "@mui/material";
import { TreeView } from "@mui/x-tree-view";
import React, { useDeferredValue, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    aggregateIsSelectableMap,
    aggregateSelectedMap,
    CheckStatus,
    searchByName,
    traverseTree,
    type NamedTreeNode,
} from "../../../lib/tree";
import { translationKeys } from "../../../translations/main-translations";
import SearchInput from "../SearchInput";
import type { Option } from "../select/types";
import { TreeInputContextProvider } from "./TreeInputContext";
import TreeInputTreeItem from "./TreeInputTreeItem";

const Header = styled(Paper)(({ theme }) => ({
    flexShrink: 0,
    paddingTop: theme.spacing(1.5),
}));

const SearchField = styled(SearchInput)(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

const SelectedNodesStack = styled(Stack)(({ theme }) => ({
    maxHeight: theme.spacing(24),
    overflow: "hidden",
    overflowY: "auto",
    padding: theme.spacing(0, 2, 1),
}));

const ScrollContainer = styled("div")(({ theme }) => ({
    overflow: "hidden",
    overflowY: "auto",
    padding: theme.spacing(1, 2),
}));

type TreeDialogContentProps = {
    value: number[];
    updateValue: (ids: number[]) => void;
    data: NamedTreeNode[];
    isMulti?: boolean;
    disabled?: boolean;
    selectedOptions: Option<number>[];
    narrowSelection: boolean;
};

const TreeInputDialogContent = ({
    value,
    updateValue,
    data,
    isMulti,
    disabled,
    selectedOptions,
    narrowSelection,
}: TreeDialogContentProps) => {
    const [searchKey, setSearchKey] = useState("");

    const deferredSearchKey = useDeferredValue(searchKey);

    const [expanded, setExpanded] = React.useState<string[] | undefined>(undefined);

    const values = [value].flat();

    const { t: translate } = useTranslation();

    const selectedNodes = isMulti ? values.map(String) : (value?.toString() ?? "");

    const checkStates = new Map<number, CheckStatus>();

    traverseTree(data, aggregateSelectedMap, values, checkStates);

    const isSelectableStates = new Map<number, boolean>();

    if (narrowSelection) {
        traverseTree(data, aggregateIsSelectableMap, isSelectableStates);
    }

    // Includes nodes that match the search key + all of their parent nodes
    const visibleNodeIds =
        deferredSearchKey.length > 0
            ? (traverseTree(data, searchByName, deferredSearchKey)?.map((node) => node.id) ?? undefined)
            : undefined;

    const filteredFirstLevelNodes = visibleNodeIds !== undefined ? data.filter((node) => visibleNodeIds?.includes(node.id)) : data;

    const handleTreeSelection = (event: React.SyntheticEvent<Element, Event>, nodeIds: string | string[]): void => {
        const newValues = [nodeIds]
            .flat()
            .filter((x) => x !== "")
            .map(Number);
        updateValue(newValues);
    };

    const handleSearchInputChange = (newKey: string) => {
        setSearchKey(newKey);
        setExpanded(undefined); // reset any expanded nodes
    };

    const handleTreeExpansion = (_: React.SyntheticEvent, nodeIds: string[]): void => setExpanded(nodeIds);

    const clearSelection = () => updateValue([]);

    const removeNode = (id: number) => {
        updateValue(value.filter((v) => v !== id));
    };

    const nothingSelectedTranslation = isMulti
        ? translationKeys.VDLANG_TREE_MULTI_SELECT_NODE_HINT
        : translationKeys.VDLANG_TREE_SELECT_NODE_HINT;

    return (
        <Stack direction="column" minHeight={0} sx={{ overflowX: "hidden" }}>
            <Header elevation={0} variant="elevation">
                <Stack direction="column" spacing={1}>
                    <SearchField searchKey={searchKey} onChange={handleSearchInputChange} translate={translate} />
                    {selectedOptions.length > 0 ? (
                        <SelectedNodesStack direction="row" spacing={1} alignItems={isMulti ? "flex-start" : "center"}>
                            <Grid spacing={0.5} container sx={{ overflow: "hidden" }}>
                                {isMulti ? (
                                    selectedOptions.map((option) => (
                                        <Grid item key={option.value} sx={{ overflow: "hidden" }}>
                                            <Chip
                                                icon={option.icon}
                                                size="small"
                                                label={option.label}
                                                onDelete={() => removeNode(option.value)}
                                                disabled={disabled}
                                                deleteIcon={<CancelRounded />}
                                            />
                                        </Grid>
                                    ))
                                ) : (
                                    <Typography sx={{ mx: 1 }}>{selectedOptions[0]?.label}</Typography>
                                )}
                            </Grid>
                            {!disabled ? (
                                <IconButton onClick={clearSelection} size="small" sx={{ position: "sticky", top: 0 }}>
                                    <ClearRounded />
                                </IconButton>
                            ) : null}
                        </SelectedNodesStack>
                    ) : (
                        <Typography sx={{ p: [1.25, 2], lineHeight: 1 }}>{translate(nothingSelectedTranslation)}</Typography>
                    )}
                </Stack>
            </Header>
            <Divider flexItem />
            <ScrollContainer>
                {visibleNodeIds?.length === 0 ? (
                    <Typography color="textSecondary">
                        {translate(translationKeys.VDLANG_SEARCH_NO_RESULTS, { key: deferredSearchKey })}
                    </Typography>
                ) : (
                    <TreeInputContextProvider checkStates={checkStates} isMulti={isMulti ?? false} isSelectableStates={isSelectableStates}>
                        <TreeView
                            expanded={expanded ?? visibleNodeIds?.map(String) ?? []}
                            multiSelect={isMulti}
                            selected={selectedNodes}
                            defaultCollapseIcon={<ExpandMoreIcon color="action" />}
                            defaultExpandIcon={<ChevronRightIcon color="action" />}
                            onNodeSelect={handleTreeSelection}
                            onNodeToggle={handleTreeExpansion}
                            disableSelection={disabled}
                        >
                            {filteredFirstLevelNodes.map((node) => (
                                <TreeInputTreeItem key={node.id} node={node} visibleNodes={visibleNodeIds} disabled={disabled} />
                            ))}
                        </TreeView>
                    </TreeInputContextProvider>
                )}
            </ScrollContainer>
        </Stack>
    );
};

export default TreeInputDialogContent;
