import { Typography } from "@mui/material";
import React from "react";
import { components, GroupBase, MenuListProps } from "react-select";
import { Option } from "../types";

const SelectMenuList = <
    OptionType extends Option,
    IsMulti extends boolean,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>({
    children,
    ...props
}: MenuListProps<OptionType, IsMulti, GroupType>) => {
    const childCount = React.Children.count(children);
    const { maxOptionsCount, shortenedOptionsMessage } = props.selectProps;
    const isShortened = maxOptionsCount !== undefined && childCount > maxOptionsCount;
    const visibleChildren =
        maxOptionsCount !== undefined && isShortened ? React.Children.toArray(children).slice(0, maxOptionsCount + 1) : children;
    return (
        <components.MenuList {...props}>
            {visibleChildren}
            {isShortened && shortenedOptionsMessage ? (
                <Typography color="textSecondary" sx={{ py: 1, px: 2 }}>
                    {shortenedOptionsMessage}
                </Typography>
            ) : null}
        </components.MenuList>
    );
};

export default SelectMenuList;
