import { TooltipProps } from "@mui/material";
import React, { AriaAttributes, ReactElement } from "react";
import Tooltip from "../components/Tooltip";

export interface IWithTooltipProps {
    title?: string | ReactElement;
    tooltipProps?: Omit<TooltipProps, "children" | "title">;
}

const withTooltip =
    <T extends AriaAttributes>(WrappedComponent: React.ComponentType<T>) =>
    ({ title = "", tooltipProps = {}, ...otherProps }: IWithTooltipProps & T) => {
        if (title === "") {
            // No tooltip, avoid rendering Tooltip component at all
            return <WrappedComponent {...(otherProps as T)} />;
        }

        return (
            <Tooltip title={title} {...tooltipProps}>
                <WrappedComponent {...(otherProps as T)} />
            </Tooltip>
        );
    };
export default withTooltip;
