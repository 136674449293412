import { Dialog, Theme, styled } from "@mui/material";
import React, { EventHandler, ReactElement, ReactNode, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useIsDesktop } from "../lib/mobile";
import SidebarCloseButton from "./SidebarCloseButton";
import SidebarLayoutDrawer from "./SidebarLayoutDrawer";

const DEFAULT_DRAWER_WIDTH = 296;
const CLOSED_WIDTH = 64;

export function getSidebarLayoutTransitionStyles(theme: Theme, isOpen: boolean) {
    return theme.transitions.create(["width", "margin", "left", "right"], {
        easing: isOpen ? theme.transitions.easing.sharp : theme.transitions.easing.easeOut,
        duration: isOpen ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
    });
}

const Root = styled("div", { shouldForwardProp: (name) => name !== "isVertical" })<{ isVertical: boolean }>(({ theme, isVertical }) => ({
    display: "flex",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    ...(isVertical && {
        flexDirection: "column",
    }),
}));

const ButtonContainer = styled("div", { shouldForwardProp: (name) => name !== "sidebarWidth" && name !== "isOpen" && name !== "onRight" })<{
    sidebarWidth: number;
    isOpen: boolean;
    onRight?: boolean;
}>(({ theme, sidebarWidth, isOpen, onRight }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: "50%",
    position: "absolute",
    top: "auto", // top position as if it was not absolute positioned, but with additional margin
    marginTop: theme.spacing(2.5),
    [onRight ? "right" : "left"]: sidebarWidth,
    transform: "translateX(-50%)",
    zIndex: theme.zIndex.appBar,
    transition: getSidebarLayoutTransitionStyles(theme, isOpen),
    ...(!isOpen && {
        [onRight ? "right" : "left"]: 0, // enable transition on left/right property so button moves while closing/opening the drawer
    }),
}));

const Main = styled("main", { shouldForwardProp: (name) => name !== "isOpen" })<{ isOpen: boolean }>(({ theme, isOpen }) => ({
    flexGrow: 1,
    flexShrink: 1,
    overflow: "auto", // auto makes table put the pagination without scrolling
    WebkitOverflowScrolling: "touch",
    backgroundColor: theme.palette.background.default,
    transition: getSidebarLayoutTransitionStyles(theme, isOpen),
    height: "100%", // TODO: check if this is still needed
}));

interface ISidebarLayoutOwnProps {
    sidebarWidth?: number;
    // controlled mode props
    open?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
    // callbacks when open/close animations have ended
    onOpened?: EventHandler<SyntheticEvent>;
    onClosed?: EventHandler<SyntheticEvent>;
    sidebar: ReactNode;
    collapsedHeader?: ReactNode | ReactElement | string;
    children: ReactNode;
    onRight?: boolean;
}

const SidebarLayout = ({
    sidebarWidth = DEFAULT_DRAWER_WIDTH,
    open: openProps,
    onClose,
    onOpen,
    onOpened,
    onClosed,
    sidebar,
    collapsedHeader,
    children,
    onRight,
}: ISidebarLayoutOwnProps) => {
    const isDesktop = useIsDesktop();
    const [openState, setOpenState] = useState(isDesktop);

    const isOpen = openProps ?? openState;
    const isVertical = !isDesktop;
    const drawerWidth = isOpen ? sidebarWidth : CLOSED_WIDTH;
    const isControlled = openProps !== undefined;

    const setMenuOpen = useCallback(
        (newOpen: boolean | ((prevState: boolean) => boolean)) => {
            // set state only in uncontrolled mode
            !isControlled && setOpenState(newOpen);
            // call callbacks when in controlled mode
            newOpen && typeof onOpen === "function" && onOpen();
            !newOpen && typeof onClose === "function" && onClose();
        },
        [onOpen, onClose, isControlled],
    );

    const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen]);
    const toggleMenu = useCallback(() => {
        setMenuOpen(!isOpen);
    }, [isOpen, setMenuOpen]);

    const handleTransitionEndCallbacks = (e: React.SyntheticEvent) => {
        const callback = isOpen ? onOpened : onClosed;
        callback?.(e);
    };

    useEffect(() => {
        setMenuOpen(isDesktop);
    }, [isDesktop, setMenuOpen]);

    const drawer = (
        <SidebarLayoutDrawer
            variant={isVertical ? "temporary" : "permanent"}
            open={isOpen}
            onRight={onRight}
            onClose={closeMenu}
            width={drawerWidth}
            onTransitionEnd={handleTransitionEndCallbacks}
            onToggle={toggleMenu}
            sidebar={sidebar}
            collapsedHeader={collapsedHeader}
            isVertical={isVertical}
        />
    );
    const dialog = (
        <Dialog open={isOpen} onClose={closeMenu} fullScreen>
            {sidebar}
        </Dialog>
    );
    const drawerOrDialog = isDesktop || (!isDesktop && !isOpen) ? drawer : dialog;
    return (
        <Root isVertical={isVertical}>
            <ButtonContainer isOpen={isOpen} onRight={onRight} sidebarWidth={sidebarWidth}>
                {isOpen ? <SidebarCloseButton direction={onRight ? "right" : "left"} onClick={toggleMenu} /> : null}
            </ButtonContainer>
            {!onRight && drawerOrDialog}
            <Main isOpen={isOpen}>{children}</Main>
            {onRight && drawerOrDialog}
        </Root>
    );
};

export default SidebarLayout;
