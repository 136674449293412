import { useContext, useMemo } from "react";
import { UNSAFE_RouteContext as RouteContext, Routes, RoutesProps } from "react-router-dom";

function RootRoutes(props: RoutesProps) {
    const ctx = useContext(RouteContext);

    const value = useMemo(
        () => ({
            ...ctx,
            // reset previous matches to get absolute navigation and route matching working
            // See: https://github.com/remix-run/react-router/issues/8035#issuecomment-997737565
            matches: [],
        }),
        [ctx],
    );

    return (
        <RouteContext.Provider value={value}>
            <Routes {...props} />
        </RouteContext.Provider>
    );
}
export default RootRoutes;
