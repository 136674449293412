import { styled } from "@mui/material";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";
import React from "react";
import { type NamedTreeNode } from "../../../lib/tree";
import TreeInputTreeItemContent from "./TreeInputTreeItemContent";

const AlignedTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.group}`]: {
        // align inset with two icons (checkbox & expand icon)
        marginLeft: theme.spacing(3),
    },
}));

type TreeInputTreeItemProps = {
    node: NamedTreeNode;
    visibleNodes: number[] | undefined;
    disabled?: boolean;
};

const TreeInputTreeItem = React.forwardRef(({ node, visibleNodes, disabled }: TreeInputTreeItemProps, ref: React.Ref<HTMLLIElement>) => {
    const children = node.children
        ?.map((child) => child as NamedTreeNode)
        .filter((child) => !visibleNodes || visibleNodes.includes(child.id))
        .map((child) => <TreeInputTreeItem key={child.id} node={child} visibleNodes={visibleNodes} disabled={disabled} />);

    return (
        <AlignedTreeItem
            ref={ref}
            nodeId={node.id.toString()}
            label={node.name}
            ContentComponent={TreeInputTreeItemContent}
            disabled={disabled}
        >
            {children}
        </AlignedTreeItem>
    );
});

export default TreeInputTreeItem;
