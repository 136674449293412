import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";
import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

export interface ILinkProps extends MuiLinkProps, Omit<RouterLinkProps, keyof MuiLinkProps> {}

const Link = React.forwardRef<HTMLAnchorElement, ILinkProps>((props, ref) => <MuiLink component={RouterLink} {...props} ref={ref} />);

export const UnstyledLink = React.forwardRef<HTMLAnchorElement, ILinkProps>(({ underline, color, ...restProps }, ref) => (
    <Link underline="none" color="inherit" {...restProps} ref={ref} />
));

export default Link;
