import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthenticationDto, LOGIN_REFERRER_KEY, TwoFactorAuthenticationStatus } from "api-shared";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { put, take, takeEvery } from "redux-saga/effects";
import { z } from "zod";
import { REGISTER_QUERY_CLIENT, RegisterQueryClientAction } from "../infrastructure/react-query";
import { LoginData, apiPost } from "./api";
import { RouteFor } from "./routes";

export const SIGN_IN_SUCCEEDED = "SIGN_IN_SUCCEEDED";

export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_SUCCEEDED = "SIGN_OUT_SUCCEEDED";

export const ACCESS_TOKEN_STORAGE_KEY = "access_token";
export const TFA_REMEMBER_STORAGE_KEY = "tfa_remember";

const zRememberLookup = z.object({
    email: z.string().min(1),
    key: z.string().min(1),
});
const zRememberLookupArray = z.array(zRememberLookup);
export type RememberLookup = z.infer<typeof zRememberLookup>;

export function signOutEvent(pathname?: string, rememberLocation?: boolean) {
    return {
        type: SIGN_OUT,
        location: pathname,
        rememberLocation,
    };
}

export const useLoginMutation = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation({
        mutationFn: (credentials: LoginData) => apiPost<AuthenticationDto>("authentication/login", credentials),
        onSuccess: (response, variables) => {
            if ("token" in response) {
                saveAuthToken(response.token);
                saveTwoFactorAuthenticationRemember(response.rememberKey, variables.email);
                dispatch({ type: SIGN_IN_SUCCEEDED, queryClient });
            } else if (response.twoFactorAuthenticationActivated === TwoFactorAuthenticationStatus.SetupRequired) {
                navigate(RouteFor.user.twoFactorAuthenticationSetup, { state: variables });
            }
        },
        meta: {
            // Do not report login errors to sentry
            ignoreErrors: true,
        },
    });
};

// Side effects Services
export function getAuthToken() {
    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    return token ?? undefined;
}

export function saveAuthToken(token: string) {
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
}

function getTfaRememberStorageKey(): RememberLookup[] {
    const dataString = localStorage.getItem(TFA_REMEMBER_STORAGE_KEY);

    let data: RememberLookup[] = [];
    if (dataString !== null) {
        const result = zRememberLookupArray.safeParse(JSON.parse(dataString));
        if (result.success) {
            data = result.data;
        }
    }
    return data;
}

export function saveTwoFactorAuthenticationRemember(key: string | null, email: string) {
    if (key === null) {
        return;
    }

    const data = getTfaRememberStorageKey();
    data.push({ email, key });

    localStorage.setItem(TFA_REMEMBER_STORAGE_KEY, JSON.stringify(data));
}

export function getTwoFactorAuthenticationRemember(email: string) {
    const data = getTfaRememberStorageKey();
    const findResult = data.find((d) => d.email === email);
    return findResult !== undefined ? findResult.key : undefined;
}

export function deleteTwoFactorAuthenticationRemember(email: string) {
    const data = getTfaRememberStorageKey();
    const filteredData = data.filter((d) => d.email !== email);

    if (filteredData === undefined || filteredData.length === 0) {
        localStorage.removeItem(TFA_REMEMBER_STORAGE_KEY);
    } else {
        localStorage.setItem(TFA_REMEMBER_STORAGE_KEY, JSON.stringify(filteredData));
    }
}

export function deleteAuthToken() {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
}

export function* authSaga(): any {
    // wait for queryClient and history to be announced initially
    const { queryClient } = (yield take(REGISTER_QUERY_CLIENT)) as RegisterQueryClientAction;

    yield takeEvery(SIGN_OUT, function* handleSignOut(action: ReturnType<typeof signOutEvent>) {
        deleteAuthToken();

        // redirect to login page after logout
        // non-router navigation will also trigger application disposal
        // keep original url for redirect after login
        if (action.location != null && action.rememberLocation) {
            localStorage.setItem(LOGIN_REFERRER_KEY, action.location);
        }
        window.location.assign(RouteFor.user.login);
        yield put({ type: SIGN_OUT_SUCCEEDED, queryClient });
    });
}
