import { Grid, List, TablePagination } from "@mui/material";
import { IdeaHistory } from "api-shared";
import { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { useAllUsers } from "../../domain/users";
import IdeaHistoryItem from "./IdeaHistoryItem";

interface IIdeaHistoryList {
    history: IdeaHistory[];
    measureCreatorId?: number;
    translate: TFunction;
}

const IdeaHistoryList = ({ history, measureCreatorId, translate }: IIdeaHistoryList) => {
    const users = useAllUsers();

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const [pageRange, setPageRange] = useState([0, history.length < rowsPerPage ? history.length : rowsPerPage]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
        const isLastPage = newPage * rowsPerPage + rowsPerPage > history.length;
        const firstIndex = newPage * rowsPerPage;
        const lastIndex = isLastPage ? history.length : firstIndex + rowsPerPage;
        setPageRange([firstIndex, lastIndex]);
    };

    useEffect(() => {}, [page, setPageRange]);
    const rowLastIndex = pageRange[1] - pageRange[0];
    return (
        <>
            <List dense>
                {history.slice(pageRange[0], pageRange[1]).map((item, index) => (
                    <IdeaHistoryItem
                        measureCreatorId={measureCreatorId}
                        key={`history-${index}`}
                        historyItem={item}
                        translate={translate}
                        users={users}
                        hideDivider={rowLastIndex === index + 1}
                    />
                ))}
            </List>
            <Grid item>
                <TablePagination
                    component="div"
                    count={history.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage=""
                    rowsPerPageOptions={[]}
                />
            </Grid>
        </>
    );
};

export default IdeaHistoryList;
