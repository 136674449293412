import { useQuery } from "@tanstack/react-query";
import { AttributeTable, CostLeverListDto } from "api-shared";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { useLanguage } from "../../hooks/useLanguage";
import { matomoTrackSiteSearch } from "../../infrastructure/tracking";
import { apiGet } from "../../lib/api";
import { formatMethod } from "../../lib/formatters";
import { registerFieldData } from "../field-data";

const COST_LEVERS_PATH = "cost-levers";

export const CostLeverQueryKeys = {
    all: [COST_LEVERS_PATH] as const,
    list: () => [...CostLeverQueryKeys.all, "list"] as const,
    searches: () => [...CostLeverQueryKeys.all, "search"] as const,
    search: (query: UseCostLeverSearchQuery) => [...CostLeverQueryKeys.searches(), query] as const,
};

export const useCostLeversQuery = (enabled = true) => {
    const dispatch = useDispatch();
    return useQuery({
        queryKey: CostLeverQueryKeys.list(),
        queryFn: ({ signal }) => apiGet<CostLeverListDto>(COST_LEVERS_PATH, { signal }),
        suspense: true,
        enabled,
        onSuccess: (response) => {
            dispatch(registerFieldData(AttributeTable.CostLevers, response));
        },
    });
};

export const useCostLeverNames = () => {
    const language = useLanguage();
    const costLevers = useCostLeversQuery().data ?? [];
    return costLevers.reduce(
        (map, method) => {
            map[method.id] = formatMethod(method, language);
            return map;
        },
        {} as Record<number, string>,
    );
};

type UseCostLeverSearchQuery = {
    searchKey: string;
    valueLeverId?: number;
};

type UseCostLeverSearchOptions = UseCostLeverSearchQuery & {
    onSuccess?: (data: number[]) => void;
};

export const useCostLeverSearch = ({ searchKey, valueLeverId, onSuccess }: UseCostLeverSearchOptions) => {
    const query = { searchKey, valueLeverId };

    return useQuery({
        queryKey: CostLeverQueryKeys.search(query),
        queryFn: ({ queryKey, signal }) => {
            const query = queryString.stringify(queryKey[2]);
            const path = `${COST_LEVERS_PATH}/search?${query}`;
            return apiGet<number[]>(path, { signal });
        },
        onSuccess: (data: number[]) => {
            onSuccess?.(data);

            if (searchKey.length > 0) {
                matomoTrackSiteSearch(searchKey, "method-guide", data.length);
            }
        },
        enabled: searchKey.length > 0,
    });
};
