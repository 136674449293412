import React, { Suspense } from "react";
import { useMeasureViewersQuery } from "../../domain/measure/permission";
import { isActiveUser, useAllUsers } from "../../domain/users";
import { useMeasureContext } from "../../view/MeasureContext";
import LoadingAnimation from "../loading/LoadingAnimation";
import { MarkdownEditorUserMentionsOwnProps } from "./MarkdownEditorWithoutMentions";

const MarkdownEditor = React.lazy(() => import("./MarkdownEditor"));

export default function MarkdownEditorUserMentionsMeasure(props: MarkdownEditorUserMentionsOwnProps) {
    const allUsers = useAllUsers();
    const measure = useMeasureContext();
    const viewersQuery = useMeasureViewersQuery(measure.id);
    const mentionResolve = allUsers.map((sel) => sel.id);

    if (!viewersQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const viewers = allUsers.filter((u) => viewersQuery.data.combinedUserIds.includes(u.id));
    const mentionIds = viewers.filter(isActiveUser).map(({ id }) => id);

    return (
        <Suspense>
            <MarkdownEditor {...props} mentionActiveUsers={mentionIds} mentionAllUsers={mentionResolve} />{" "}
        </Suspense>
    );
}
