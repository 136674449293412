import { useQuery } from "@tanstack/react-query";
import { AttributeTable, DepartmentListDto } from "api-shared";
import { useDispatch } from "react-redux";
import { apiGet } from "../lib/api";
import { registerFieldData } from "./field-data";

const DEPARTMENTS_PATH = "departments";

export const DepartmentsQueryKeys = {
    all: [DEPARTMENTS_PATH] as const,
};

export const useDepartments = (enabled = true) => {
    const dispatch = useDispatch();
    return useQuery({
        enabled,
        queryKey: DepartmentsQueryKeys.all,
        queryFn: ({ signal }) => apiGet<DepartmentListDto>(DEPARTMENTS_PATH, { signal }),
        onSuccess: (response) => {
            dispatch(registerFieldData(AttributeTable.Departments, response));
        },
    });
};
