import { z } from "zod";

export const zClientEnvironment = z.object({
    devextremeLicenseKey: z
        .string({
            required_error: "No DevExtreme license key found. See https://notion.so/a5e13ebe4623467682727b946a8d44d5",
        })
        .min(1),
    trackingSiteId: z.string().optional(),
    sentryClientDsn: z.string().optional(),
    sentryEnvironment: z.string().optional(),
    hubspotTrackingCodeHubId: z.string().optional(),
    version: z.string(),
});

// classes cannot extend or implement types, but classes can implement interfaces which can extend types
type ClientEnvironmentType = z.infer<typeof zClientEnvironment>;

export async function fetchConfig() {
    return fetch("/config.json").then((body) => body.json());
}
export class Environment implements ClientEnvironmentType {
    devextremeLicenseKey!: string;

    trackingSiteId?: string;

    sentryClientDsn?: string;

    sentryEnvironment?: string;

    hubspotTrackingCodeHubId?: string;

    version = "";

    isProduction = import.meta.env.MODE === "production";

    public initialize(data: unknown) {
        // first fill all members, then validate
        Object.assign(this, data);
        this.devextremeLicenseKey = import.meta.env.VITE_DEVEXTREME_LICENSE_KEY;

        // errors might be thrown here, but in this early stage there is not much that can be done to handle them
        zClientEnvironment.parse(this);
    }

    public static from(data: unknown) {
        const newEnvironment = new Environment();
        newEnvironment.initialize(data);
        return newEnvironment;
    }
}

export const environment = new Environment();
