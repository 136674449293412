import { SubTaskStatus, SubTaskStatusFilter } from "api-shared";
import { TFunction } from "i18next";
import React from "react";
import Select, { createSelectHandler } from "../../../components/input/select/Select";
import { ISelectProps, Option, ValueHandler } from "../../../components/input/select/types";
import { translationKeys } from "../../../translations/main-translations";

const labels: Record<SubTaskStatusFilter, string | translationKeys> = {
    [SubTaskStatusFilter.OPEN]: `subtask_status.${SubTaskStatus.STATUS_OPEN}`,
    [SubTaskStatusFilter.COMPLETED]: `subtask_status.${SubTaskStatus.STATUS_COMPLETED}`,
    [SubTaskStatusFilter.ALL]: translationKeys.VDLANG_ALL,
};

interface IActivitiesStatusSelectProps<IsMulti extends boolean = false>
    extends Omit<ISelectProps<Option<SubTaskStatusFilter>, IsMulti>, "value" | "options" | "onChange"> {
    component?: React.ComponentType<ISelectProps<Option<SubTaskStatusFilter>, IsMulti>>;
    value: SubTaskStatusFilter;
    onChange: ValueHandler<SubTaskStatusFilter, IsMulti>;
    translate: TFunction;
}

const ActivitiesStatusSelect = ({ component, value, translate, onChange, ...selectProps }: IActivitiesStatusSelectProps) => {
    const Component = component ?? Select;
    const options = Object.entries(labels).map(([optionValue, optionLabel]) => ({
        value: +optionValue as SubTaskStatusFilter,
        label: translate(optionLabel),
    }));
    const selectedOption = options.find((o) => o.value === value);
    return (
        <Component
            value={selectedOption}
            options={options}
            onChange={createSelectHandler(onChange)}
            label={translate(translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_STATUS_LABEL)}
            {...selectProps}
        />
    );
};

export default ActivitiesStatusSelect;
