import { TextField, TextFieldProps } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";

const INTEGER_REGEX = /^\d*$/;

interface IIntegerInputProps extends Omit<TextFieldProps, "value" | "onChange"> {
    value: number;
    onChange: (value: number) => void;
}

const IntegerInput = (props: IIntegerInputProps) => {
    const { value, onChange, ...otherProps } = props;

    const [isLastChangeInvalid, setIsLastChangeInvalid] = useState(false);
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const change = event.target.value;

        if (!INTEGER_REGEX.test(change)) {
            setIsLastChangeInvalid(true);
            return;
        }

        const parsed = change.length > 0 ? parseInt(change) : 0;

        onChange(parsed);
        setIsLastChangeInvalid(false);
    };

    return (
        <TextField
            value={value}
            onChange={handleChange}
            helperText={isLastChangeInvalid ? t(translationKeys.VDLANG_INTEGER_INPUT_ONLY_DIGITS) : null}
            {...otherProps}
        />
    );
};

export default IntegerInput;
