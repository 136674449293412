import { InputBaseComponentProps, InputBaseProps, styled } from "@mui/material";
import React, { HTMLProps } from "react";
import useForkedRef from "../../../../hooks/useForkedRef";

const InternalComponent = styled("div", { shouldForwardProp: (name) => name !== "smallGutters" })<{ smallGutters?: boolean }>(
    ({ theme, smallGutters }) => ({
        // Important is needed here to avoid wrapping of dropdown indicator on initial render
        display: "flex !important",

        // This component inherits the default paddings/sizings of all inputs
        // Styles on "root" level are combined with styles of the general Input component, but the latter would get precedence
        // Use "&" to force generating a dedicated CSS rule with higher precedence
        "&": {
            // Height should determined by the height of the children, this is important for wrapping chips
            height: "auto",
            // Override horizontal default padding of input
            ...(smallGutters && { paddingLeft: theme.spacing(), paddingRight: 0 }),
        },
    }),
);

// Make available all props that are similar in InputBaseComponentProps and HTMLProps<HTMLDivElement>
// Skip "as" prop, because it's HTML type is not compatible with the "as" generated by the styled() function
type InputBaseComponentAndDivCommonProps = Omit<InputBaseComponentProps & HTMLProps<HTMLDivElement>, "as">;
type ISelectInternalInputComponentProps = InputBaseComponentAndDivCommonProps & Pick<InputBaseProps, "inputRef">;

const SelectInternalInputComponent = React.forwardRef<HTMLDivElement, ISelectInternalInputComponentProps>(({ inputRef, ...props }, ref) => {
    const forkedRef = useForkedRef(inputRef, ref);
    return <InternalComponent ref={forkedRef} {...props} />;
});
export default SelectInternalInputComponent;
