import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { ErrorConstantKeys } from "api-shared";
import { Store } from "redux";
import { z } from "zod";
import { ApiError } from "../lib/api";
import { signOutEvent } from "../lib/authentication-saga";
import { extractErrorMessage, getTranslationKeyForError, isIgnoredError } from "../lib/error";
import { NotificationType } from "../lib/notifications";
import { RouteFor } from "../lib/routes";
import { showNotificationEvent } from "./notifications";
import { reportFailedRequest } from "./sentry";

export const UNAUTHORIZED_ERRORS = [ErrorConstantKeys.VDERROR_INVALID_TOKEN, ErrorConstantKeys.VDERROR_UNAUTHORIZED_TOKEN].map(String);

export const REGISTER_QUERY_CLIENT = "REGISTER_QUERY_CLIENT";

export function registerQueryClientAction(queryClient: QueryClient) {
    return {
        type: REGISTER_QUERY_CLIENT,
        queryClient,
    };
}

export type RegisterQueryClientAction = ReturnType<typeof registerQueryClientAction>;

/**
 * Check if the error is one of the following error scenarios and handle them:
 *
 * 1. Deployment in progress
 * 2. Session expired (401)
 *
 * @param {unknown} error
 * @param {Store} store
 * @returns {boolean} wether one of the graceful error scenarios has matched and was handled
 */
function tryHandleGracefulErrors(error: unknown, store: Store): boolean {
    // Deployment in progress
    if (error instanceof ApiError && error.code === 503) {
        document.location = "/app_offline.html";
        return true;
    }

    // Session expired
    if (
        error instanceof ApiError &&
        error.code === 401 &&
        typeof error.content !== "string" &&
        "message" in error.content &&
        UNAUTHORIZED_ERRORS.includes(String(error.content.message))
    ) {
        // Make sure to show the login page
        if (window.location.pathname !== RouteFor.user.login) {
            store.dispatch(signOutEvent(window.location.pathname, true));
        }

        return true;
    }

    // None of the graceful error scenarios matched
    return false;
}

const zErrorsToSkip = z.union([
    z.boolean(),
    z.string(),
    z.number(),
    z.nativeEnum(ErrorConstantKeys),
    z.undefined(),
    z.union([z.string(), z.number(), z.nativeEnum(ErrorConstantKeys)]).array(),
]);

type ErrorsToSkip = z.infer<typeof zErrorsToSkip>;

function isErrorsToSkip(obj: unknown): obj is ErrorsToSkip {
    return zErrorsToSkip.safeParse(obj).success;
}

function shouldSkipError(message: string | ErrorConstantKeys, errorsToSkip: ErrorsToSkip): boolean {
    // When true, skip all reports to sentry
    if (errorsToSkip === true) {
        return true;
    }

    // When string or number (e.g. ErrorConstantKeys enum), skip the reporting for that specific error
    // Otherwise report it
    if (typeof errorsToSkip === "string" || typeof errorsToSkip === "number") {
        return String(message) === String(errorsToSkip);
    }

    // When an array, check if the current error should be skipped
    // Otherwise report it
    if (Array.isArray(errorsToSkip)) {
        return errorsToSkip.map(String).includes(String(message));
    }

    // Dont skip reporting by default
    return false;
}

export const createQueryClient = (store: Store) => {
    return new QueryClient({
        defaultOptions: {
            queries: {
                // disable refetching on reconnect to avoid excessive refetching for users with unstable internet connection which may lead to fetch errors
                // queries will refetch on navigation anyways, but only queries needed for the respective page not all queries manages by the QueryClient
                refetchOnReconnect: false,
                refetchOnWindowFocus: false,
                retry: (failureCount, error) => {
                    // do not retry on known API errors
                    if (error instanceof ApiError) {
                        return false;
                    }

                    // retry on other errors, especially network errors!
                    return failureCount < 3;
                },
                useErrorBoundary: false, // avoid queries with suspense showing the error page on error
            },
        },
        mutationCache: new MutationCache({
            onError: (error, variables, context, mutation) => {
                if (isIgnoredError(error)) {
                    return;
                }

                const isHandled = tryHandleGracefulErrors(error, store);

                if (isHandled || mutation.meta?.ignoreErrors) {
                    return;
                }

                if (!(error instanceof Error)) {
                    // rejected promise but without an error object -> This should not happen, but if it does
                    // Sentry should be told
                    reportFailedRequest(new Error("Request promise rejected with a non-error"), JSON.stringify(error));
                    return;
                }

                const { message, details } = extractErrorMessage(error);

                // With @tanstack/query v5 we can type the meta better
                // See https://valued.atlassian.net/browse/DEV-4131
                const skipNotifications = isErrorsToSkip(mutation.meta?.skipNotifications) ? mutation.meta?.skipNotifications : undefined;
                if (!shouldSkipError(message, skipNotifications)) {
                    store.dispatch(showNotificationEvent(NotificationType.ERROR, getTranslationKeyForError(message), details));
                }

                // With @tanstack/query v5 we can type the meta better
                // See https://valued.atlassian.net/browse/DEV-4131
                const skipReportToSentry = isErrorsToSkip(mutation.meta?.skipReportToSentry)
                    ? mutation.meta?.skipReportToSentry
                    : undefined;
                if (!shouldSkipError(message, skipReportToSentry)) {
                    reportFailedRequest(error, message);
                }
            },
        }),
        queryCache: new QueryCache({
            onError: (error, query) => {
                if (isIgnoredError(error)) {
                    return;
                }

                const isHandled = tryHandleGracefulErrors(error, store);

                if (isHandled || query.meta?.ignoreErrors) {
                    return;
                }

                if (!(error instanceof Error)) {
                    // rejected promise but without an error object -> This should not happen, but if it does
                    // Sentry should be told
                    reportFailedRequest(new Error("Request promise rejected with a non-error"), JSON.stringify(error), query.queryKey);
                    return;
                }

                const { message, details } = extractErrorMessage(error);

                // With @tanstack/query v5 we can type the meta better
                // See https://valued.atlassian.net/browse/DEV-4131
                const skipNotifications = isErrorsToSkip(query.meta?.skipNotifications) ? query.meta?.skipNotifications : undefined;
                if (!shouldSkipError(message, skipNotifications)) {
                    store.dispatch(showNotificationEvent(NotificationType.ERROR, getTranslationKeyForError(message), details));
                }

                // With @tanstack/query v5 we can type the meta better
                // See https://valued.atlassian.net/browse/DEV-4131
                const skipReportToSentry = isErrorsToSkip(query.meta?.skipReportToSentry) ? query.meta?.skipReportToSentry : undefined;
                if (!shouldSkipError(message, skipReportToSentry)) {
                    reportFailedRequest(error, message, query.queryKey);
                }
            },
        }),
    });
};
