import { useQueries, useQuery } from "@tanstack/react-query";
import { AclFact, AclNamespaces, AclPermissions, BasicPermissionDto, SimplePermissionsDto } from "api-shared";
import { apiPost } from "../lib/api.ts";

type UserHasPermissionQueryParams = { namespace: AclNamespaces; permission: AclPermissions; fact: AclFact };
type UsersHavingPermissionQueryParams = { namespace: AclNamespaces; permission: AclPermissions; fact: AclFact };

export const PermissionsQueryKeys = {
    all: ["permissions"] as const,
    userHasPermission: (params: UserHasPermissionQueryParams) => [PermissionsQueryKeys.all, "user", params] as const,
    usersHavingPermission: (params: UsersHavingPermissionQueryParams) => [PermissionsQueryKeys.all, "users", params] as const,
};

export const useUserHasPermissionQuery = (params: UserHasPermissionQueryParams, enabled = true) => {
    return useQuery({
        queryKey: PermissionsQueryKeys.userHasPermission(params),
        queryFn: ({ queryKey: [, , { fact, permission, namespace }], signal }) => {
            return apiPost<BasicPermissionDto>(`permissions/user/query`, { namespace, permission, fact }, { signal });
        },
        enabled,
    });
};

export const useUserHasAccessPermissionQuery = (namespace: AclNamespaces) => {
    return useUserHasPermissionQuery({ namespace: namespace, permission: AclPermissions.Access, fact: {} });
};

export const useUsersHavingPermissionQuery = (params: UsersHavingPermissionQueryParams, enabled = true) => {
    return useQuery({
        queryKey: PermissionsQueryKeys.usersHavingPermission(params),
        queryFn: ({ queryKey: [, , { fact, permission, namespace }], signal }) => {
            return apiPost<SimplePermissionsDto>(`permissions/users/query`, { namespace, permission, fact }, { signal });
        },
        enabled,
    });
};

export const useUsersHavingAccessPermissionQuery = (namespace: AclNamespaces) => {
    return useUsersHavingPermissionQuery({ namespace: namespace, permission: AclPermissions.Access, fact: {} });
};

const fetchAccessPermission = async (namespace: AclNamespaces, signal: AbortSignal | undefined) =>
    apiPost<BasicPermissionDto>(`permissions/user/query`, { namespace, permission: AclPermissions.Access, fact: {} }, { signal });

export const useInitialAccessPermissionsQueries = () => {
    const basicParams = { permission: AclPermissions.Access, fact: {} };
    const [
        hasIdeaAccessQuery,
        hasActivityAccessQuery,
        hasFeedAccessQuery,
        hasDashboardAccessQuery,
        hasProcessAccessQuery,
        hasMethodAccessQuery,
    ] = useQueries({
        queries: [
            {
                queryKey: PermissionsQueryKeys.userHasPermission({ ...basicParams, namespace: AclNamespaces.Idea }),
                queryFn: async ({ signal }: { signal?: AbortSignal }) => fetchAccessPermission(AclNamespaces.Idea, signal),
            },
            {
                queryKey: PermissionsQueryKeys.userHasPermission({ ...basicParams, namespace: AclNamespaces.Activity }),
                queryFn: async ({ signal }: { signal?: AbortSignal }) => fetchAccessPermission(AclNamespaces.Activity, signal),
            },
            {
                queryKey: PermissionsQueryKeys.userHasPermission({ ...basicParams, namespace: AclNamespaces.Feed }),
                queryFn: async ({ signal }: { signal?: AbortSignal }) => fetchAccessPermission(AclNamespaces.Feed, signal),
            },
            {
                queryKey: PermissionsQueryKeys.userHasPermission({ ...basicParams, namespace: AclNamespaces.Dashboard }),
                queryFn: async ({ signal }: { signal?: AbortSignal }) => fetchAccessPermission(AclNamespaces.Dashboard, signal),
            },
            {
                queryKey: PermissionsQueryKeys.userHasPermission({ ...basicParams, namespace: AclNamespaces.Process }),
                queryFn: async ({ signal }: { signal?: AbortSignal }) => fetchAccessPermission(AclNamespaces.Process, signal),
            },
            {
                queryKey: PermissionsQueryKeys.userHasPermission({ ...basicParams, namespace: AclNamespaces.Method }),
                queryFn: async ({ signal }: { signal?: AbortSignal }) => fetchAccessPermission(AclNamespaces.Method, signal),
            },
        ],
    });

    if (
        hasIdeaAccessQuery.isSuccess &&
        hasActivityAccessQuery.isSuccess &&
        hasFeedAccessQuery.isSuccess &&
        hasDashboardAccessQuery.isSuccess &&
        hasProcessAccessQuery.isSuccess &&
        hasMethodAccessQuery.isSuccess
    ) {
        return {
            isSuccess: true,
            hasIdeaAccessQuery,
            hasActivityAccessQuery,
            hasFeedAccessQuery,
            hasDashboardAccessQuery,
            hasProcessAccessQuery,
            hasMethodAccessQuery,
        } as const;
    }
    return {
        isSuccess: false,
        hasIdeaAccessQuery,
        hasActivityAccessQuery,
        hasFeedAccessQuery,
        hasDashboardAccessQuery,
        hasProcessAccessQuery,
        hasMethodAccessQuery,
    } as const;
};
