import { zShortText } from "api-shared";
import { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SubTaskUpdateChanges } from "../../domain/subtasks";

interface IUseEditableSubtaskTitleProps {
    updateSubtask: (changes: SubTaskUpdateChanges) => void;
}

const isValidTitle = (title: string) => zShortText.min(1).safeParse(title).success;

export const useEditableSubtaskTitle = ({ updateSubtask }: IUseEditableSubtaskTitleProps) => {
    const [modifiedTitle, setModifiedTitle] = useState<string | null>(null);
    const resetModifiedTitle = useCallback(() => setModifiedTitle(null), []);

    const updateSubtaskTitle = useCallback(
        (title: string) => {
            if (isValidTitle(title)) {
                updateSubtask({ title });
            }
        },
        [updateSubtask],
    );

    return {
        modifiedTitle,
        setModifiedTitle,
        resetModifiedTitle,
        isValidTitle,
        updateSubtaskTitle,
    };
};

/**
 * Provide access to subtask id that is stored in URL. Provides both read and write access.
 *
 * @returns
 */
export const useTaskIdFromUrl = (): [number | null, (id: number | null) => void] => {
    const location = useLocation();
    const navigate = useNavigate();

    const { taskId } = useParams();
    const setTaskId = useCallback(
        (id: number | null) => {
            navigate({
                // ".." refers to the closest <Routes> ancestor component, which should be provided by SubtaskRouter
                pathname: id !== null ? `../${id}` : "..",
                // keep search so that token is not removed when in M2M view
                search: location.search,
            });
        },
        [location.search, navigate],
    );

    return [taskId != null ? +taskId : null, setTaskId];
};
