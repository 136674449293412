import { Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { SubTaskDto, effortConverter, effortParser, validateEffortValue } from "api-shared";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SubTaskUpdateChanges } from "../../domain/subtasks";
import { translationKeys } from "../../translations/main-translations";
import Form from "../Form";
import ActionItemDialog from "../dialogues/ActionItemDialog";
import EffortIcon from "../icons/EffortIcon";

export interface ISubtaskEffortDialogProps {
    task: SubTaskDto;
    onClose: () => void;
    open: boolean;
    updateTask: (changes: SubTaskUpdateChanges) => void;
}

const SubtaskEffortDialog = ({ task, updateTask, onClose, open }: ISubtaskEffortDialogProps) => {
    const { t: translate } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isDirty },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            estimatedEffort: effortConverter(task.estimatedEffort),
            trackedTime: effortConverter(task.trackedTime),
        },
        shouldUnregister: true,
    });
    const onSubmit = handleSubmit((data) => {
        const effort = data.estimatedEffort !== "" ? effortParser(data.estimatedEffort) : null;
        const time = data.trackedTime !== "" ? effortParser(data.trackedTime) : null;
        const updatedData = { estimatedEffort: effort, trackedTime: time };
        updateTask(updatedData);
        onClose();
    });

    return (
        <ActionItemDialog
            translate={translate}
            title={translate(translationKeys.VDLANG_ACTIVITY_EFFORT_ESTIMATION_TITLE)}
            open={open}
            onClose={onClose}
            primary={translationKeys.VDLANG_SAVE}
            onPrimary={onSubmit}
            primaryDisabled={!isValid || !isDirty}
        >
            <Form onSubmit={onSubmit}>
                <Stack direction="column" gap={2}>
                    <Grid item xs={12}>
                        <TextField
                            label={translate(translationKeys.VDLANG_ACTIVITY_EFFORT_ESTIMATION_INPUT_LABEL)}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EffortIcon filled={false} />
                                    </InputAdornment>
                                ),
                            }}
                            error={errors.estimatedEffort !== undefined}
                            {...register("estimatedEffort", { validate: validateEffortValue })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={translate(translationKeys.VDLANG_ACTIVITY_TIME_TRACKED_INPUT_LABEL)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EffortIcon filled={true} />
                                    </InputAdornment>
                                ),
                            }}
                            error={errors.trackedTime !== undefined}
                            {...register("trackedTime", { validate: validateEffortValue })}
                        />
                    </Grid>
                </Stack>
                <Typography component="div" variant="body2" sx={{ marginTop: 2 }}>
                    <div>{`${translate(translationKeys.VDLANG_ACTIVITY_EFFORT_FORMAT)} 1w 4d 3h 30m`}</div>
                    <ul style={{ marginTop: 0 }}>
                        <li>{`w = ${translate(translationKeys.VDLANG_ACTIVITY_EFFORT_FORMAT_WEEKS)}`}</li>
                        <li>{`d = ${translate(translationKeys.VDLANG_ACTIVITY_EFFORT_FORMAT_DAYS)}`}</li>
                        <li>{`h = ${translate(translationKeys.VDLANG_ACTIVITY_EFFORT_FORMAT_HOURS)}`}</li>
                        <li>{`m = ${translate(translationKeys.VDLANG_ACTIVITY_EFFORT_FORMAT_MINUTES)}`}</li>
                    </ul>
                </Typography>
            </Form>
        </ActionItemDialog>
    );
};

export default SubtaskEffortDialog;
