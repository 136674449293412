import { ListItem, ListItemText } from "@mui/material";
import React from "react";

const SidebarTitleItem = ({ children }: React.PropsWithChildren<unknown>) => {
    return (
        <ListItem>
            <ListItemText primary={children} primaryTypographyProps={{ variant: "h6" }} />
        </ListItem>
    );
};

export default SidebarTitleItem;
