import { Grid, Typography, styled } from "@mui/material";
import { ActivityTemplateDto, FeedbackType } from "api-shared";
import { TFunction } from "i18next";
import { Component } from "react";
import BaseDialog from "../../components/dialogues/BaseDialog";
import DialogBackButton from "../../components/dialogues/DialogBackButton";
import DialogButton from "../../components/dialogues/DialogButton";
import FeedbackDialog from "../../components/dialogues/FeedbackDialog";
import { shallowArrayEquals } from "../../lib/utils";
import { Language, translationKeys } from "../../translations/main-translations";
import ApplyActivityTemplateList from "./ApplyActivityTemplateList";

const SuggestChangeButton = styled(DialogButton)({
    marginRight: "auto",
});

interface IApplyActivityTemplateDialogProps {
    activityTemplate: ActivityTemplateDto;
    applyActivityTemplate: (templateId: number, activityIds: number[]) => void;
    open: boolean;
    onClose: () => void;
    language: Language;
    translate: TFunction;
}

interface IApplyActivityTemplateDialogState {
    selectedActivityIds: number[];
    feedbackDialogOpen: boolean;
}

class ApplyActivityTemplateDialog extends Component<IApplyActivityTemplateDialogProps, IApplyActivityTemplateDialogState> {
    state = {
        selectedActivityIds: [],
        feedbackDialogOpen: false,
    };

    componentDidMount() {
        this.setState({
            selectedActivityIds: this.props.activityTemplate.items.map((item) => item.id),
        });
    }

    componentDidUpdate(previousProps: IApplyActivityTemplateDialogProps) {
        if (!shallowArrayEquals(this.props.activityTemplate.items, previousProps.activityTemplate.items)) {
            this.setState({
                selectedActivityIds: this.props.activityTemplate.items.map((item) => item.id),
            });
        }
    }

    toggleFeedbackDialog = () =>
        this.setState((previous) => ({
            feedbackDialogOpen: !previous.feedbackDialogOpen,
        }));

    applyActivityTemplate = () => {
        const { activityTemplate, applyActivityTemplate } = this.props;
        if (activityTemplate.id !== undefined) {
            applyActivityTemplate(activityTemplate.id, this.state.selectedActivityIds);
        }
    };

    onActivityIdsChange = (selectedActivityIds: number[]) => this.setState({ selectedActivityIds });

    renderActions = () => {
        const { onClose, translate } = this.props;
        const { selectedActivityIds } = this.state;

        const suggestion = (
            <SuggestChangeButton
                key="suggestion"
                name={translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_SUGGEST_A_CHANGE}
                onClick={this.toggleFeedbackDialog}
                translate={translate}
            />
        );

        const back = <DialogBackButton key="back" onClose={onClose} translate={translate} />;

        const create = (
            <DialogButton
                key="create"
                variant="contained"
                name={translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_CREATE_ACTIVITIES}
                onClick={this.applyActivityTemplate}
                onClose={onClose}
                disabled={selectedActivityIds.length < 1}
                translate={translate}
            />
        );

        return (
            <Grid container justifyContent="space-between">
                <Grid item>{suggestion}</Grid>
                <Grid item flexGrow={1}>
                    <Grid container spacing={1} justifyContent="flex-end" wrap="nowrap">
                        <Grid item>{back}</Grid>
                        <Grid item>{create}</Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    render() {
        const { activityTemplate, open, onClose, language, translate } = this.props;
        const { selectedActivityIds, feedbackDialogOpen } = this.state;

        return (
            <BaseDialog
                open={open}
                onClose={onClose}
                title={translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE)}
                actions={this.renderActions()}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>{translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_DIALOG_DESCRIPTION_1)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ApplyActivityTemplateList
                            activityTemplateItems={activityTemplate.items}
                            selectedActivityIds={selectedActivityIds}
                            onChange={this.onActivityIdsChange}
                            language={language}
                            translate={translate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>{translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_DIALOG_DESCRIPTION_2)}</Typography>
                    </Grid>
                </Grid>
                <FeedbackDialog
                    open={feedbackDialogOpen}
                    title={translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_SUGGEST_A_CHANGE)}
                    type={FeedbackType.CHANGE_ACTIVITY_TEMPLATE}
                    onClose={this.toggleFeedbackDialog}
                    translate={translate}
                />
            </BaseDialog>
        );
    }
}

export default ApplyActivityTemplateDialog;
