import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, legacy_createStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { createRootReducer } from "./reducer";
import valueSaga from "./sagas";

export type AppState = ReturnType<ReturnType<typeof createRootReducer>>; // TODO TS move into rootReducer

export function configureApplicationStore(preloadedState?: AppState) {
    // create own middleware and reducer for each store instance
    const sagaMiddleware = createSagaMiddleware();
    const rootReducer = createRootReducer();
    // createStore is marked deprecated to shift users towards redux toolkit, but it won't be removed in future and in fact redux toolkit
    // would be overkill for our use case
    // See https://github.com/reduxjs/redux/releases/tag/v4.2.0
    const store: Store<AppState> = legacy_createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(sagaMiddleware)));

    sagaMiddleware.run(valueSaga);
    return store;
}
