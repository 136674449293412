import { styled } from "@mui/material";
import { MaterialDesignContent, SnackbarProvider as NotistackSnackbarProvider } from "notistack";

// https://notistack.com/features/customization#override-styles
const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
    ...theme.typography.body2,
    "&.notistack-MuiContent-error": {
        backgroundColor: theme.palette.error.main,
    },
}));

const SnackbarProvider = ({ children }: React.PropsWithChildren<unknown>) => {
    return (
        <NotistackSnackbarProvider
            preventDuplicate
            autoHideDuration={10 * 1000}
            Components={{ error: StyledMaterialDesignContent, success: StyledMaterialDesignContent }}
        >
            {children}
        </NotistackSnackbarProvider>
    );
};

export default SnackbarProvider;
