import ErrorOutlineRounded from "@mui/icons-material/ErrorOutlineRounded";
import {
    Button,
    InputAdornment,
    InputProps,
    TextField,
    Typography,
    TypographyProps,
    alpha,
    buttonClasses,
    inputBaseClasses,
    styled,
} from "@mui/material";
import React, { useState } from "react";
import { isEnterEvent, isEscapeEvent } from "../../lib/keybindings";
import ErrorTooltip from "../ErrorTooltip";

const Grow = styled("div")({
    flexGrow: 1,
    minWidth: 0,
});

const RootButton = styled(Button)<{
    ownerState: {
        inputSize: InputProps["size"];
        hasError: boolean;
    };
}>(({ theme, ownerState }) => ({
    [`& .${buttonClasses.endIcon}`]: {},

    justifyContent: "flex-start",
    textAlign: "left", // override browser default of "center" for all buttons
    // The sizes need to match the paddings applied by TextField/Input
    // In case of an error, the padding is reduced to make space for the additional border
    ...(!ownerState.hasError && ownerState.inputSize === "small" && { padding: theme.spacing(0.75, 1.5) }),
    ...(!ownerState.hasError && ownerState.inputSize === "medium" && { padding: theme.spacing(1, 1.5) }),
    ...(!ownerState.hasError && ownerState.inputSize === "large" && { padding: theme.spacing(1.25, 1.5) }),
    ...(!ownerState.hasError && ownerState.inputSize === "extralarge" && { padding: theme.spacing(1.75, 1.5) }),
    ...(ownerState.hasError && ownerState.inputSize === "small" && { padding: theme.spacing(0.5, 1.25) }),
    ...(ownerState.hasError && ownerState.inputSize === "medium" && { padding: theme.spacing(0.75, 1.25) }),
    ...(ownerState.hasError && ownerState.inputSize === "large" && { padding: theme.spacing(1, 1.25) }),
    ...(ownerState.hasError && ownerState.inputSize === "extralarge" && { padding: theme.spacing(1.5, 1.25) }),
    ...(ownerState.hasError && { backgroundColor: alpha(theme.palette.error.light, theme.palette.action.selectedOpacity) }),
    ...(ownerState.hasError && { border: `1px solid ${theme.palette.error.light}` }),
}));

const RestingTypography = styled(Typography)({
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
});

const TypographyTextField = styled(TextField, { shouldForwardProp: (name) => name !== "typographyVariant" })<{
    typographyVariant: TypographyProps["variant"];
}>(({ theme, typographyVariant }) => ({
    [`& .${inputBaseClasses.root}`]: {
        padding: 0,
    },
    [`& .${inputBaseClasses.input}`]: {
        ...(typographyVariant != null && typographyVariant !== "inherit" && theme.typography[typographyVariant]),
    },
}));

const getErrorAdornment = (errorText: string | undefined) => (
    <ErrorTooltip title={errorText}>
        <ErrorOutlineRounded color="error" fontSize="small" />
    </ErrorTooltip>
);

interface IEditableTextInlineProps extends TypographyProps, Pick<InputProps, "size" | "placeholder" | "disabled"> {
    text: string;
    defaultIsEditing?: boolean;
    onTextSave: (text: string) => any;
    onTextChange?: (text: string) => any;
    onEditStart?: () => any;
    onEditEnd?: () => any;
    onEditAbort?: () => any;
    errorText?: string;
    resetTextOnEmptyBlur?: boolean;
}

const EditableTextInline = ({
    text,
    placeholder,
    variant = "body2",
    disabled = false,
    defaultIsEditing = false,
    onTextSave,
    onTextChange,
    onEditStart,
    onEditEnd,
    onEditAbort,
    errorText,
    resetTextOnEmptyBlur = true,
    className,
    size = "medium",
    ...typographyProps
}: IEditableTextInlineProps) => {
    const [isEditing, setIsEditing] = useState(!!defaultIsEditing);
    const [editedText, setEditedText] = useState(text);

    const startEditing = () => {
        setEditedText(text);
        setIsEditing(true);
        typeof onEditStart === "function" && onEditStart();
    };

    const stopEditing = () => {
        setIsEditing(false);
        typeof onEditEnd === "function" && onEditEnd();
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedText(event.target.value);
        typeof onTextChange === "function" && onTextChange(event.target.value);
    };

    const onBlur = () => {
        if (editedText.trim() !== "") {
            stopEditing();
            onTextSave(editedText);
        } else if (resetTextOnEmptyBlur) {
            stopEditing();
            setEditedText(text);
        } else {
            onTextSave(editedText);
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (isEnterEvent(event)) {
            onBlur(); // Trigger blur on enter key
        } else if (isEscapeEvent(event)) {
            stopEditing();
            setEditedText(text);
            typeof onEditAbort === "function" && onEditAbort();
        }
    };

    const onClick = () => {
        if (!disabled) {
            startEditing();
        }
    };
    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => event.target.select();

    const hasError = errorText !== undefined && errorText !== "";

    return isEditing ? (
        <TypographyTextField
            autoFocus
            fullWidth
            margin="none"
            value={editedText}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            error={hasError}
            className={className}
            size={size}
            typographyVariant={variant}
            InputProps={
                hasError
                    ? {
                          endAdornment: (
                              <InputAdornment position="end" sx={{ mr: 1, cursor: "default", fontSize: 20 }}>
                                  {getErrorAdornment(errorText)}
                              </InputAdornment>
                          ),
                      }
                    : undefined
            }
        />
    ) : (
        <RootButton
            fullWidth
            variant="text"
            color="inherit"
            disabled={disabled}
            onClick={onClick}
            className={className}
            ownerState={{ inputSize: size, hasError }}
            endIcon={hasError ? getErrorAdornment(errorText) : null}
        >
            <RestingTypography variant={variant} {...typographyProps}>
                {text}
            </RestingTypography>
            <Grow />
        </RootButton>
    );
};

export default EditableTextInline;
