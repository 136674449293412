import { Stack, StackProps, styled } from "@mui/material";
import { ReactNode } from "react";
import SidebarDivider from "./SidebarDivider";

export const sidebarPreferencesClasses = {
    content: "SidebarPreferences-content",
};

const Root = styled(Stack)(({ theme }) => ({
    overflow: "hidden",
    height: "100%",
    paddingTop: theme.spacing(2),
}));

const Content = styled("div")(({ theme }) => ({
    flexGrow: 1,
    flexShrink: 1,
    overflowY: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // react-select's getScrollParent() for menu positioning ignores static positioned elements
    // because our menu is absolute positioned. Set position relative to scroll container to workaround in addition to
    // cherry picking: https://github.com/JedWatson/react-select/pull/3830
    position: "relative",
}));

interface ISidebarPreferencesProps extends StackProps {
    header?: ReactNode;
    footer?: ReactNode;
}

const SidebarPreferences = ({ header, children, footer, ...stackProps }: ISidebarPreferencesProps) => {
    return (
        <Root {...stackProps}>
            {header != null ? (
                <>
                    {header}
                    <SidebarDivider />
                </>
            ) : null}
            <Content className={sidebarPreferencesClasses.content}>{children}</Content>
            {footer ?? null}
        </Root>
    );
};

export default SidebarPreferences;
