import { useEffect, useRef } from "react";

/**
 * Hook to access a value from the previous render. Can be used to access previous state or props.
 *
 * @template T
 * @param {T} value
 * @returns
 */
const usePrevious = <T>(value: T) => {
    const ref = useRef<T>(value);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
export default usePrevious;
