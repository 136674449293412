import { List } from "@mui/material";
import { TFunction } from "i18next";
import CommentInput from "../comment/CommentInput";
import MarkdownEditorUserMentionsMeasure from "../markdowneditor/MarkdownEditorUserMentionsMeasure";

interface ISubtaskDetailFooterProps {
    translate: TFunction;
    createComment: (markdown: string) => void;
}

const SubtaskDetailFooter = ({ translate, createComment }: ISubtaskDetailFooterProps) => {
    return (
        <List sx={{ p: 3 }}>
            <CommentInput
                sx={{ py: 0 }}
                onCreate={createComment}
                customEditorComponent={MarkdownEditorUserMentionsMeasure}
                disableGutters
            />
        </List>
    );
};

export default SubtaskDetailFooter;
