import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AclPermissions, FileDto, FileListDto, FileTableNames } from "api-shared";
import { useProgress } from "../hooks/useProgress";
import { apiDelete, apiGet, apiUpload } from "../lib/api";
import { NotificationQueryKeys } from "./measure-notifications";
import { MeasurePermissionsQueryKeys } from "./measure/permission";

export const FileQueryKeys = {
    all: ["files", "measures"] as const,
    forMeasure: (measureId: number) => [...FileQueryKeys.all, measureId] as const,
};

type UseMeasureFilesOptions = {
    measureId: number;
};

export const useMeasureFiles = ({ measureId }: UseMeasureFilesOptions, ignoreErrors = false) => {
    return useQuery({
        queryKey: FileQueryKeys.forMeasure(measureId),
        queryFn: ({ queryKey, signal }) => apiGet<FileListDto>(`measures/${queryKey[2]}/files`, { signal }),
        meta: {
            ignoreErrors,
        },
    });
};

type UseUploadMeasureFileOptions = {
    measureId: number;
    file: File;
};

export const useUploadMeasureFile = () => {
    const queryClient = useQueryClient();
    const [progress, setProgress] = useProgress();

    const mutation = useMutation({
        mutationFn: ({ measureId, file }: UseUploadMeasureFileOptions) => {
            const data = new FormData();
            data.append("file", file);
            data.append("dataType", FileTableNames.Measure);
            data.append("dataId", String(measureId));

            return apiUpload<FileDto>("files", data, {
                onProgress: (event) => setProgress(event),
            });
        },
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(FileQueryKeys.forMeasure(variables.measureId));
            queryClient.invalidateQueries(
                MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Read, measureId: variables.measureId }),
            );
            queryClient.invalidateQueries(
                MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Update, measureId: variables.measureId }),
            );
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(variables.measureId));
        },
        onSettled: () => {
            setProgress(0);
        },
    });

    return { ...mutation, progress };
};

type UseDeleteFileOptions = {
    hash: string;
    measureId: number;
};

export const useDeleteFile = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ hash }: UseDeleteFileOptions) => apiDelete(`files/${hash}`),
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(FileQueryKeys.forMeasure(variables.measureId));
            queryClient.invalidateQueries(
                MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Read, measureId: variables.measureId }),
            );
            queryClient.invalidateQueries(
                MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Update, measureId: variables.measureId }),
            );
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(variables.measureId));
        },
    });
};
