import React from "react";
import useTimezone, { IDateFormatOptions } from "../hooks/useTimezone";
import Tooltip from "./Tooltip";

interface IDateTzProps {
    date: any;
    nullValue?: React.ReactNode;
    hideTime?: boolean;
    hideDate?: boolean;
    disableTimezone?: boolean;
    fromNow?: boolean;
    withTooltip?: boolean;
}

const DateTz = ({ date, hideTime, hideDate, nullValue = "--", disableTimezone, fromNow = false, withTooltip }: IDateTzProps) => {
    const { formatDateTime, formatDate, formatTime } = useTimezone();

    if (hideTime && hideDate) {
        return null;
    }

    const options: IDateFormatOptions = {};

    if (disableTimezone) {
        options.noTimezone = true;
    }

    if (fromNow) {
        options.fromNow = true;
    }

    if (date == null) {
        return <>{nullValue}</>;
    }

    let formattedDate = "";
    if (hideTime) {
        formattedDate = formatDate(date, options);
    } else if (hideDate) {
        formattedDate = formatTime(date, options);
    } else {
        formattedDate = formatDateTime(date, options);
    }

    if (withTooltip) {
        return (
            <Tooltip title={formatDateTime(date, { ...options, fromNow: false })}>
                <span>{formattedDate}</span>
            </Tooltip>
        );
    }

    return <>{formattedDate}</>;
};

export default DateTz;
