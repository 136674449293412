import { alpha, avatarClasses, Chip, darken, FormLabel, Popover, Stack, styled, Typography } from "@mui/material";

import KeyboardArrowDownRounded from "@mui/icons-material/KeyboardArrowDownRounded";
import { AclNamespaces } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import SingleUserContent from "../../components/user/SingleUserContent";
import UserAvatar from "../../components/user/UserAvatar";
import UserDetails from "../../components/user/UserDetails";
import { useCompany } from "../../domain/endpoint";
import { useUsersHavingAccessPermissionQuery } from "../../domain/permissions";
import { useAllUsers, useUser } from "../../domain/users";
import { formatUser } from "../../lib/formatters";
import { translationKeys } from "../../translations/main-translations";

export interface IIdeaSingleUserButton {
    translate: TFunction;
    ownerId: number | null;
    updateOwnerId: (ownerId: number) => void;
    isIdeaReadonly: boolean;
    dense?: boolean;
}

const chipHeightOverrideClass = "chipHeightOverride";

const StyledChip = styled(Chip, { shouldForwardProp: (name) => name !== "dense" })<{ dense: boolean }>(({ theme, dense }) => ({
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
        backgroundColor: darken(theme.palette.background.paper, theme.palette.action.focusOpacity),
    },
    maxWidth: "100%", // support proper ellipsis on low space
    height: theme.spacing(5),
    border: `1px solid ${alpha(theme.palette.divider, 0.12)}`,
    borderRadius: theme.spacing(dense ? 1.5 : 2.25), // half of height
    // disable hover effect if the user cannot change the value
    "&.Mui-disabled": {
        opacity: "initial",
    },
    "& .MuiChip-avatar": {
        color: theme.palette.background.default, // Chip overwrites avatar default text color, restore
    },
    [`&.${chipHeightOverrideClass}`]: {
        // Without the additional class, SingleUserButton chip class' height would be used instead the the height here
        height: theme.spacing(dense ? 2.5 : 4.75),

        [`& .${avatarClasses.root}`]: {
            marginLeft: 0, // avatar should be shown without 2px marginin left/top/bottom
        },
    },
}));

const Avatar = styled(UserAvatar)(({ theme }) => ({
    marginLeft: theme.spacing(0.25),
    width: theme.spacing(4.75),
    height: theme.spacing(4.75),
    ...theme.typography.body2,
    cursor: "pointer",
    pointerEvents: "auto",
}));

const TypographyLabel = styled(Typography, { shouldForwardProp: (name) => name !== "dense" })<{ dense: boolean }>(({ dense }) => ({
    marginTop: !dense ? -4 : 0,
    marginBottom: !dense ? 4 : 0,
}));

export const OverlineLabel = styled(FormLabel)(({ theme }) => ({
    letterSpacing: 2,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "0.625rem",
    textTransform: "uppercase",
    lineHeight: 1.5,
    display: "inline-block",
    color: theme.palette.text.secondary,
    marginTop: 4,
}));

export const ArrowDropDownIcon = styled(KeyboardArrowDownRounded)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const IdeaModalSingleUserButton = ({ translate, ownerId, updateOwnerId, isIdeaReadonly, dense = false }: IIdeaSingleUserButton) => {
    const user = useUser(ownerId);
    const havingIdeaAccessPermissionQuery = useUsersHavingAccessPermissionQuery(AclNamespaces.Idea);
    const allUsers = useAllUsers();

    const company = useCompany(user?.companyId ?? undefined);

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    if (!havingIdeaAccessPermissionQuery.isSuccess) {
        return null;
    }

    const users = allUsers.filter((u) => havingIdeaAccessPermissionQuery.data.combinedUserIds.includes(u.id));

    const handleAvatarClickEvent = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if ("currentTarget" in event) {
            setAnchorEl(event.currentTarget);
        }
    };

    const onClosePopover = (e: React.MouseEvent | React.KeyboardEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(null);
    };

    const handleUpdateOwnerId = (newOwnerId: number | null) => {
        if (newOwnerId !== null) {
            updateOwnerId(newOwnerId);
        }
        setAnchorEl(null);
    };

    return (
        <>
            {user != null && (
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={onClosePopover}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    {isIdeaReadonly ? (
                        <UserDetails user={user} company={company} translate={translate} />
                    ) : (
                        <SingleUserContent
                            user={user}
                            translate={translate}
                            updateUser={handleUpdateOwnerId}
                            users={users}
                            showSelectedUsers={false}
                        />
                    )}
                </Popover>
            )}

            <StyledChip
                onClick={handleAvatarClickEvent}
                avatar={<Avatar user={user} size={dense ? 18 : 36} />}
                label={
                    isIdeaReadonly ? (
                        <>
                            {!dense && <OverlineLabel>{translate(translationKeys.VDLANG_IDEAS_IDEA_RESPONSIBLE_LABEL)}</OverlineLabel>}
                            <TypographyLabel variant="body2" dense={dense}>
                                {user != null ? formatUser(user, { translate }) : translate(translationKeys.VDLANG_DECISION_EMPTY_DECIDER)}
                            </TypographyLabel>
                        </>
                    ) : (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Stack>
                                {!dense && <OverlineLabel>{translate(translationKeys.VDLANG_IDEAS_IDEA_RESPONSIBLE_LABEL)}</OverlineLabel>}
                                <TypographyLabel variant="body2" dense={dense}>
                                    {user != null
                                        ? formatUser(user, { translate })
                                        : translate(translationKeys.VDLANG_DECISION_EMPTY_DECIDER)}
                                </TypographyLabel>
                            </Stack>
                            <ArrowDropDownIcon />
                        </Stack>
                    )
                }
                dense={dense}
                className={chipHeightOverrideClass}
            />
        </>
    );
};

export default IdeaModalSingleUserButton;
