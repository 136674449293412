import { AttributeTable, CompanyDto } from "api-shared";
import { useDataForTableName } from "../../lib/field-options";
import Select from "./select/Select";
import { ISelectProps } from "./select/types";

interface ICompanySelectProps extends Omit<ISelectProps, "value"> {
    value: number | null;
    handleChange: (companyId: number | number[] | null) => void;
}

const CompanySelect = ({ value, handleChange, ...selectProps }: ICompanySelectProps) => {
    const companies = useDataForTableName(AttributeTable.Companies) as CompanyDto[];
    const options = companies.map((company) => ({ value: company.id, label: `${company.name}` }));
    const selectedCompany = options.find((option) => option.value === value);
    return <Select value={selectedCompany} options={options} onChange={(value) => handleChange(value?.value ?? null)} {...selectProps} />;
};

export default CompanySelect;
