import { useMemo } from "react";
import { GroupBase, SelectComponentsConfig } from "react-select";
import { ISelectProps, Option } from "../types";
import SelectCheckboxOption from "./SelectCheckboxOption";
import SelectClearIndicator from "./SelectClearIndicator";
import SelectControl from "./SelectControl";
import SelectDropdownIndicator from "./SelectDropdownIndicator";
import SelectIndicatorsContainer from "./SelectIndicatorsContainer";
import SelectMenu from "./SelectMenu";
import SelectMenuList from "./SelectMenuList";
import SelectMultiValue from "./SelectMultiValue";
import SelectNoOptionsMessage from "./SelectNoOptionsMessage";
import SelectOption from "./SelectOption";
import SelectSingleValue from "./SelectSingleValue";
import SelectValueContainer from "./SelectValueContainer";
import SelectVirtualMenuList from "./SelectVirtualMenuList";
import UnobtrusiveSelectPlaceholder from "./UnobtrusiveSelectPlaceholder";

const MAX_NON_VIRTUALIZED_OPTIONS = 100;

export const components = {
    ClearIndicator: SelectClearIndicator,
    Control: SelectControl,
    Menu: SelectMenu,
    MultiValue: SelectMultiValue,
    NoOptionsMessage: SelectNoOptionsMessage,
    IndicatorsContainer: SelectIndicatorsContainer,
    Option: SelectOption,
    Placeholder: UnobtrusiveSelectPlaceholder,
    SingleValue: SelectSingleValue,
    ValueContainer: SelectValueContainer,
    DropdownIndicator: SelectDropdownIndicator,
    MenuList: SelectMenuList,
    IndicatorSeparator: undefined,
};

export const useSelectComponents = <OptionType extends Option, IsMulti extends boolean>(
    props: ISelectProps<OptionType, IsMulti>,
): SelectComponentsConfig<OptionType, IsMulti, GroupBase<OptionType>> => {
    return useMemo(
        () => ({
            ...components,
            Option: props.isMulti ? SelectCheckboxOption : components.Option,
            MenuList:
                props.options !== undefined && props.options.length > MAX_NON_VIRTUALIZED_OPTIONS
                    ? SelectVirtualMenuList
                    : components.MenuList,
            ...props.components,
        }),
        [props.components, props.isMulti, props.options],
    );
};

export const getDefaultComponents = <OptionType extends Option, IsMulti extends boolean>(): SelectComponentsConfig<
    OptionType,
    IsMulti,
    GroupBase<OptionType>
> => components;
