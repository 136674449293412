import { Box, ButtonBase, Stack, styled, Typography, TypographyProps } from "@mui/material";
import React from "react";

interface IUserEntryInlineProps {
    disabled?: boolean;
    typographyProps?: TypographyProps;
    onClick?: React.MouseEventHandler<HTMLElement>;
    avatar: React.ReactNode;
    userName: React.ReactNode;
}

const CustomButtonBase = styled(ButtonBase)({
    // reset browser default styles applied to native button element
    fontSize: "inherit",
    fontFamily: "inherit",
    fontWeight: "inherit",
    letterSpacing: "inherit",
    lineHeight: "inherit",
});

const UserEntryInline = React.forwardRef<HTMLDivElement, IUserEntryInlineProps>(
    ({ disabled, onClick, avatar, userName, typographyProps }, ref) => {
        return (
            <Stack
                ref={ref}
                direction="row"
                spacing={1}
                alignItems="center"
                // enable ellipsis shortening together with minWidth={0} and noWrap below
                // use maxWidth instead of width to get "inline" instead of "block" behavior
                maxWidth="100%"
                useFlexGap
                {...(!disabled && onClick && { component: CustomButtonBase, onClick })}
            >
                {avatar}
                <Box minWidth={0}>
                    <Typography noWrap {...typographyProps}>
                        {userName}
                    </Typography>
                </Box>
            </Stack>
        );
    },
);

export default UserEntryInline;
