import { Paper, styled } from "@mui/material";
import React, { CSSProperties } from "react";
import { CoercedMenuPlacement, MenuProps } from "react-select";
import useForkedRef from "../../../../hooks/useForkedRef";
import { dropLegacyRef } from "../../../../lib/utils";
import { ISelectProps, Option } from "../types";

const StyledSelectMenuContainer = styled(Paper, { shouldForwardProp: (prop) => prop !== "placement" && prop !== "horizontalMenuOrigin" })<
    { placement: CoercedMenuPlacement } & Pick<ISelectProps, "horizontalMenuOrigin">
>(({ placement, horizontalMenuOrigin, theme }) => ({
    position: "absolute",
    zIndex: theme.zIndex.modal,
    width: "100%",
    minWidth: theme.spacing(32), // dont make it wider than the sidebar
    overflowX: "auto", // dont let content scroll abover paper shadow
    overflowY: "hidden",
    border: "none",
    boxShadow: theme.shadows[8],
    ...(placement === "top" && {
        bottom: "100%",
        // Maintain a little spacing on menu edge so shadow can provide visual indication about the menu boundary
        marginTop: theme.spacing(),
    }),
    ...(placement === "bottom" && {
        top: "100%",
        // Maintain a little spacing on menu edge so shadow can provide visual indication about the menu boundary
        marginBottom: theme.spacing(),
    }),
    ...(horizontalMenuOrigin === "end" && {
        right: 0,
    }),
    ...(horizontalMenuOrigin === "start" && {
        left: 0,
    }),
}));

interface ISelectMenuContainerProps<OptionType extends Option, IsMulti extends boolean> extends MenuProps<OptionType, IsMulti> {
    style?: CSSProperties; // injected by Transition
}

/**
 * A select menu container component that positions itself absolutely according to the placement provided via props.
 *
 * @param {ISelectMenuContainerProps} { selectProps, placement = "bottom", innerProps, children, style, paperRef }
 * @param {any} ref
 * @returns
 */
const SelectMenuContainer = React.forwardRef<HTMLDivElement, ISelectMenuContainerProps<any, any>>(
    ({ selectProps, placement = "bottom", innerProps, children, style, innerRef }, ref) => {
        const { ref: divRef, ...forwardedInnerProps } = innerProps;
        // 3 refs to merge here:
        // - The one from forwardRef to support Grow animation in parent container
        // - innerRef from react-select (important so that MenuPlacer works correctly)
        // - ref on the typings of innerProps (typed as all available props on a div element, which also includes legacy string refs)
        // String refs cannot be supported by useForkedRef, so skip if any occurs (very unlikely as they are deprecated for a long time)
        const forkedRef = useForkedRef(ref, dropLegacyRef(divRef), innerRef);
        return (
            <StyledSelectMenuContainer
                ref={forkedRef}
                square
                placement={placement}
                horizontalMenuOrigin={selectProps.horizontalMenuOrigin}
                style={style}
                {...forwardedInnerProps}
            >
                {children}
            </StyledSelectMenuContainer>
        );
    },
);

export default SelectMenuContainer;
