import { TextField } from "@mui/material";
import {
    AttributeTable,
    FieldDefinitionsDto,
    FieldTypes,
    ICompareExpression,
    IFieldDefinition,
    INTEGER_OPERATORS,
    IgnoreOperatorsForField,
    MEASURE_GATE_TASK_FIELD_PREFIX,
    MeasureAttributeDto,
    MeasureFieldNames,
    OPERATORS_FOR_TYPE,
    Operators,
    RELATIVE_DATE_OPERATORS,
    TranslationType,
    UserDto,
    formatDateForAPI,
} from "api-shared";
import { TFunction } from "i18next";
import moment from "moment";
import { useMemo } from "react";
import SmallIconButton from "../../../../components/SmallIconButton";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import CurrencyInput from "../../../../components/input/CurrencyInput";
import EffortInput from "../../../../components/input/EffortInput";
import FieldSelect from "../../../../components/input/FieldSelect";
import FormattedNumberInput from "../../../../components/input/FormattedNumberInput";
import IntegerInput from "../../../../components/input/IntegerInput";
import MultiIntegerInput from "../../../../components/input/MultiIntegerInput";
import RelativeTimerangeInput from "../../../../components/input/RelativeTimerangeInput";
import DatePicker from "../../../../components/input/date/DatePicker";
import Select from "../../../../components/input/select/Select";
import { Option } from "../../../../components/input/select/types";
import FieldTreeInput from "../../../../components/input/tree/FieldTreeInput";
import { useDefaultCurrency } from "../../../../domain/currencies";
import { useCurrentUser } from "../../../../domain/users";
import { useGetDynamicFieldLabel } from "../../../../hooks/useGetDynamicFieldLabel";
import { DateTimeFormatter } from "../../../../hooks/useTimezone";
import { reportError } from "../../../../infrastructure/sentry";
import { FieldOptions, FilledField, findField, mapConstantsToTranslations, removeDependsFromField } from "../../../../lib/fields";
import { translationKeys } from "../../../../translations/main-translations";
import OptionsProvider, { OptionsProviderInjectedProps } from "../../../OptionsProvider";
import TreeProvider from "../../../TreeProvider";
import CurrentUserEntry from "./CurrentUserEntry";

const ExcludedFields: string[] = [MeasureFieldNames.ProcessPulse];

const getOperatorsSelectOptions = (operators: Operators[], field: IFieldDefinition, translate: TFunction) =>
    operators.map((operator) => ({
        label: translate(field.type === FieldTypes.Date ? `operator_date_${operator}` : `operator_${operator}`),
        value: operator,
    }));

const getDefaultValue = (field: IFieldDefinition, operator: Operators, currentUserId: number) => {
    if (RELATIVE_DATE_OPERATORS.includes(operator)) {
        return ["7"];
    }
    switch (field.type) {
        case FieldTypes.Currency:
        case FieldTypes.Boolean:
            return field.name === MeasureFieldNames.Favorite ? [1] : [0];
        case FieldTypes.Integer:
            if (operator === Operators.In || operator === Operators.NotIn) {
                return [];
            }
            return [0];
        case FieldTypes.Double:
            return [0];
        case FieldTypes.TimeEstimate:
            return [0];
        case FieldTypes.User:
        case FieldTypes.Users:
            if (operator === Operators.In || operator === Operators.NotIn) {
                return [currentUserId];
            }
            return [];
        case FieldTypes.Text:
            return [""];
        case FieldTypes.Single:
        case FieldTypes.Set:
            return [];
        case FieldTypes.Date:
            return [formatDateForAPI(new Date())];
        default:
            reportError(new Error("Could not get default value for field"), {
                extras: {
                    field: JSON.stringify(field),
                },
            });
            return [];
    }
};

const getOverrideOptions = (fieldName: string, values: string[] | number[]) =>
    values.map((v) => ({
        id: v as number, // Not actually true, because IdeaStatus enum is string, but changing the id type everywhere would be cumbersome
        name: mapConstantsToTranslations(fieldName, v) ?? undefined,
    }));

interface ConditionProps {
    disabled?: boolean;
    fieldDefinitions: FieldDefinitionsDto;
    translate: TFunction;
    field: string;
    operator: Operators;
    values: (string | number)[];
    canFilterByPerson: boolean;
    updateCondition: (condition: Partial<ICompareExpression>) => void;
    removeCondition: () => void;
    measureAttributes: MeasureAttributeDto[];
    users: UserDto[];
    formatDate: DateTimeFormatter;
    ignoreOperators?: IgnoreOperatorsForField;
}

const Condition = ({
    disabled,
    fieldDefinitions,
    translate,
    field,
    canFilterByPerson,
    operator,
    updateCondition,
    values,
    measureAttributes,
    users,
    formatDate,
    removeCondition,
    ignoreOperators,
}: ConditionProps) => {
    const currentUser = useCurrentUser();
    const clientCurrency = useDefaultCurrency();
    const { getDynamicColumnLabel } = useGetDynamicFieldLabel();
    const fieldSelectOptions = useMemo(() => {
        return Object.keys(fieldDefinitions)
            .filter((name) => !ExcludedFields.includes(name))
            .map((name) => ({
                label: getDynamicColumnLabel(name, clientCurrency.isoCode),
                value: name,
            }))
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
    }, [clientCurrency.isoCode, fieldDefinitions, getDynamicColumnLabel]);

    let fieldDefinition = fieldDefinitions[field];

    if (currentUser === null) {
        return null;
    }

    // Handle special case for level date fields for which a user can not see the gate task config (because of missing measure config permission)
    if (fieldDefinition === undefined && field.startsWith(MEASURE_GATE_TASK_FIELD_PREFIX)) {
        // Add a dummy field definition in this case (field select will be shown as empty)
        fieldDefinition = { type: FieldTypes.Date, name: field };
    }

    const isSetOperator =
        operator === Operators.In ||
        operator === Operators.NotIn ||
        (fieldDefinition.type === FieldTypes.Set && (operator === Operators.Equals || operator === Operators.NotEquals)) ||
        (fieldDefinition.type === FieldTypes.Users && (operator === Operators.Equals || operator === Operators.NotEquals));

    const getOperators = (fieldDefinition: IFieldDefinition, ignoreOperators: IgnoreOperatorsForField = {}) => {
        return fieldDefinition?.type != null
            ? OPERATORS_FOR_TYPE[fieldDefinition.type].filter((operator) => !ignoreOperators[fieldDefinition.name]?.includes(operator))
            : [];
    };

    // Change handlers for field/operator/values
    const updateField = (option: Option<string> | null) => {
        if (option == null) {
            // Ignore attempts to clear
            return;
        }
        const newFieldName = option.value;
        const newFieldDefinition = fieldDefinitions[newFieldName];
        const operators = getOperators(newFieldDefinition);

        const operator = operators[0];
        if (operator === undefined) {
            return;
        }
        const values = getDefaultValue(newFieldDefinition, operator, currentUser?.id);
        updateCondition({ field: newFieldName, operator, values });
    };

    const updateOperator = (option: Option<Operators> | null) => {
        if (option == null) {
            // Ignore attempts to clear
            return;
        }
        const values = getDefaultValue(fieldDefinition, option.value, currentUser?.id);
        updateCondition({ operator: option.value, values });
    };

    const updateValues = (newValues: number[] | string[]) => updateCondition({ values: newValues });

    // Specialized input component change handlers
    const updateCurrencyValue = (newValue: number | string | null) => updateValues(newValue == null ? [] : [`${newValue}`]);

    const handleSelectValueChange = (selectedOptions: unknown) => {
        let values: number[] = [];
        // interface of some of the select components needs unknown, so do runtime checking here
        if (Array.isArray(selectedOptions)) {
            values = selectedOptions.map(Number);
        }
        if (typeof selectedOptions === "number" || typeof selectedOptions === "string") {
            values = [+selectedOptions];
        }
        updateValues(values);
    };

    const getDateField = (value: string | number | null) => {
        if (INTEGER_OPERATORS.includes(operator)) {
            return (
                <DatePicker
                    date={value != null ? moment(value) : null}
                    onDateChange={(date) => updateValues(date == null ? [] : [formatDateForAPI(date)])}
                    disabled={disabled}
                    isClearable={false}
                />
            );
        }
        return (
            <RelativeTimerangeInput
                translate={translate}
                value={typeof value === "string" ? value : ""}
                formatDate={formatDate}
                operator={operator}
                updateValues={(v) => updateValues([v])}
                disabled={disabled}
                fullWidth
            />
        );
    };

    const getIntegerField = (multiValue: (string | number)[], singleValue: number | string | null) => {
        if (isSetOperator) {
            return <MultiIntegerInput value={multiValue} onChange={updateValues} isDisabled={disabled} />;
        }
        return (
            <IntegerInput
                value={singleValue != null ? +singleValue : 0}
                onChange={(v) => updateValues([v])}
                disabled={disabled}
                fullWidth
            />
        );
    };

    const getDoubleField = (singleValue: number | string | null) => {
        return (
            <FormattedNumberInput
                value={singleValue}
                updateValue={(v) => updateValues(v != null ? [+v] : [])}
                disabled={disabled}
                translate={translate}
            />
        );
    };

    const getTimeEstimateField = (value: number | string | null) => {
        return <EffortInput value={value != null ? +value : 0} updateValue={(v) => updateValues(v != null ? [v] : [])} fullWidth />;
    };

    const getFieldSelectInput = (
        value: (string | number)[] | string | number | null,
        field: IFieldDefinition,
        overrideOptions?: FieldOptions,
    ) => {
        const attribute = findField(measureAttributes, fieldDefinitions, field.name);
        let fakeField: FilledField = {
            title: field.name,
            options: {},
            translate: null,
            order: null,
            type: "",
            mandatory: false,
            tableName: null,
            isCreatable: null,
            ...attribute,
            quantity: isSetOperator ? 0 : 1,
            value,
        };

        // convert to number[]
        const valueAsArray = Array.isArray(value) ? value.map(Number) : value != null ? [+value] : [];

        fakeField = removeDependsFromField(fakeField);
        if (overrideOptions?.values) {
            // Set options values if they are defined => tableName must be null to avoid
            // data fetching from store in OptionsProvider
            fakeField.options = {
                ...fakeField.options,
                values: overrideOptions.values,
            };
            fakeField.tableName = null;
        }
        if (field.values) {
            fakeField.options = {
                ...fakeField.options,
                values: getOverrideOptions(field.name, field.values),
                name: "name",
            };
            fakeField.translate = TranslationType.MapAndTranslate; // use translate function
        }

        if (attribute?.tableName === AttributeTable.TreeNodes) {
            return (
                <TreeProvider
                    field={fakeField}
                    component={FieldTreeInput}
                    componentProps={{
                        field: fakeField,
                        translate,
                        updateValue: handleSelectValueChange,
                        isMulti: fakeField.quantity === 0,
                        value: valueAsArray,
                        label: translate(fakeField.title),
                        disabled: disabled ?? false,
                        dense: true,
                        narrowSelection: false,
                    }}
                />
            );
        }
        return (
            <OptionsProvider field={fakeField} updateValue={handleSelectValueChange} value={value}>
                <FieldSelect
                    isMulti={fakeField.quantity === 0}
                    {...OptionsProviderInjectedProps}
                    field={fakeField}
                    updateValue={handleSelectValueChange}
                    value={isSetOperator ? valueAsArray : (valueAsArray[0] ?? null)}
                    menuPortalTarget={document.body}
                    disabled={disabled ?? false}
                    isClearable={false}
                />
            </OptionsProvider>
        );
    };

    const getField = () => {
        // No field needed for this operator
        if (fieldDefinition === undefined || operator === Operators.NotSet) {
            return <div />;
        }

        const type = fieldDefinition.type;
        const multiValue = values;
        const singleValue = values[0] ?? null;

        switch (type) {
            case FieldTypes.Date:
                return getDateField(singleValue);
            case FieldTypes.User:
            case FieldTypes.Users:
                if (!canFilterByPerson) {
                    return <CurrentUserEntry />;
                }
                // Set values for users here to override store data
                return getFieldSelectInput(multiValue, fieldDefinition, { values: users });
            case FieldTypes.Single:
            case FieldTypes.Set:
            case FieldTypes.Boolean:
                return getFieldSelectInput(multiValue, fieldDefinition);
            case FieldTypes.Integer:
                return getIntegerField(multiValue, singleValue);
            case FieldTypes.TimeEstimate:
                return getTimeEstimateField(singleValue);
            case FieldTypes.Currency:
                return (
                    <CurrencyInput
                        value={singleValue}
                        updateValue={updateCurrencyValue}
                        translate={translate}
                        disabled={disabled}
                        fullWidth
                    />
                );
            case FieldTypes.Double:
                return getDoubleField(singleValue);
            case FieldTypes.Text:
            default:
                return (
                    <TextField
                        placeholder={translate("Please enter a name")}
                        value={singleValue ?? ""}
                        onChange={(e) => updateValues([e.target.value])}
                        disabled={disabled}
                        fullWidth
                    />
                );
        }
    };

    const operators = getOperators(fieldDefinition, ignoreOperators);
    const operatorOptions = getOperatorsSelectOptions(operators, fieldDefinition, translate);
    const selectedOperator = operatorOptions.find((o) => o.value === operator);
    const selectedFieldOption = fieldSelectOptions.find((o) => o.value === field);

    return (
        <>
            <Select
                value={selectedFieldOption}
                options={fieldSelectOptions}
                onChange={updateField}
                isSearchable
                noOptionsMessage={() => translate(translationKeys.VDLANG_SELECT_NO_RESULTS)}
                // make sure menu is outside of modal to fix scrolling
                menuPortalTarget={document.body}
                margin="none"
                isDisabled={disabled}
            />
            <Select
                value={selectedOperator}
                options={operatorOptions}
                onChange={updateOperator}
                // make sure menu is outside of modal to fix scrolling
                menuPortalTarget={document.body}
                margin="none"
                isDisabled={disabled}
            />
            {getField()}
            {!disabled ? (
                <SmallIconButton onClick={removeCondition}>
                    <DeleteIcon />
                </SmallIconButton>
            ) : (
                <div />
            )}
        </>
    );
};

export default Condition;
