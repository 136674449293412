import { FormGroup, styled, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { z } from "zod";
import BlueStandaloneCard from "../../components/BlueStandaloneCard";
import Form from "../../components/Form";
import EmailInput from "../../components/input/EmailInput";
import LoadingButton from "../../components/LoadingButton";
import { usePasswordReset } from "../../domain/password-reset";
import { translationKeys } from "../../translations/main-translations";

const StyledEmailInput = styled(EmailInput)(({ theme }) => ({
    margin: theme.spacing(5, 0),
}));

const zLocationState = z.string().min(1);

const PasswordReset = () => {
    const location = useLocation();

    // Pre-fill the email from the location state, if there is any
    const parsedLocationState = zLocationState.safeParse(location.state);
    const [email, setEmail] = useState(parsedLocationState.success ? parsedLocationState.data : "");

    const isEmailValid = isEmail(email.trim());
    const { t: translate } = useTranslation();

    const passwordResetMutation = usePasswordReset();

    function onSubmit() {
        if (isEmailValid) {
            passwordResetMutation.mutate(email);
            setEmail("");
        }
    }

    return (
        <BlueStandaloneCard>
            <Typography variant="h4" align="center">
                {translate(translationKeys.VDLANG_PASSWORD_RESET_TITLE)}
            </Typography>
            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <StyledEmailInput value={email} size="extralarge" onChange={(event) => setEmail(event.target.value)} />
                </FormGroup>
                <LoadingButton
                    isLoading={passwordResetMutation.isLoading}
                    fullWidth
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={!isEmailValid}
                    onClick={onSubmit}
                >
                    {translate(translationKeys.VDLANG_PASSWORD_RESET_BUTTON)}
                </LoadingButton>
            </Form>
        </BlueStandaloneCard>
    );
};

export default PasswordReset;
