import { Grid, styled, Typography } from "@mui/material";

import { effortConverter } from "api-shared";
import { TFunction } from "i18next";
import { translationKeys } from "../../translations/main-translations";
import { EffortStatus, IBarData } from "./SubtaskEffortChart";
const Label = styled(Grid)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));
const OverbookedTime = styled("span")(({ theme }) => ({
    color: theme.palette.secondary.dark,
}));
const SubtaskEffortChartLabel = ({
    estimatedEffort,
    trackedTime,
    overbookedTime,
    status,
    translate,
    dense = false,
}: IBarData & {
    status: EffortStatus;
    translate: TFunction;
    dense?: boolean;
}) => {
    const typographyVariant = dense ? "caption" : "body2";
    switch (status) {
        case EffortStatus.EMPTY:
            return (
                <Label container justifyContent="space-between" width="100%" wrap="nowrap" gap={2}>
                    <Typography noWrap overflow="visible" variant={typographyVariant}>
                        {translate(translationKeys.VDLANG_ACTIVITY_EMPTY_LABEL)}
                    </Typography>
                </Label>
            );

        case EffortStatus.ESTIMATED:
            return (
                <Label container justifyContent="flex-end" width="100%" wrap="nowrap" gap={2}>
                    <Typography noWrap variant={typographyVariant}>
                        {`${effortConverter(estimatedEffort)} ${translate(translationKeys.VDLANG_ACTIVITY_EFFORT_ESTIMATED_LABEL)}`}
                    </Typography>
                </Label>
            );
        case EffortStatus.ACTUAL:
        case EffortStatus.ESTIMATED_AND_ACTUAL:
        case EffortStatus.ESTIMATED_AND_ACTUAL_OVERBOOKED:
            return (
                <Label container justifyContent="space-between" width="100%" wrap="nowrap" gap={2}>
                    <>
                        <Typography noWrap overflow="visible" variant={typographyVariant}>
                            {`${effortConverter(trackedTime + overbookedTime)} ${translate(
                                translationKeys.VDLANG_ACTIVITY_TIME_TRACKED_LABEL,
                            )}`}
                        </Typography>
                        {estimatedEffort > 0 && (
                            <Typography noWrap variant={typographyVariant}>
                                {`${effortConverter(estimatedEffort)} ${translate(translationKeys.VDLANG_ACTIVITY_EFFORT_ESTIMATED_LABEL)}`}
                                {overbookedTime !== 0 && (
                                    <>
                                        {" / "}
                                        <OverbookedTime>{`${effortConverter(overbookedTime)} ${translate(
                                            translationKeys.VDLANG_ACTIVITY_TIME_OVERDRAWN_LABEL,
                                        )}`}</OverbookedTime>
                                    </>
                                )}
                            </Typography>
                        )}
                    </>
                </Label>
            );
    }
};

export default SubtaskEffortChartLabel;
