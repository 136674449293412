import { Stack, Typography, styled } from "@mui/material";
import UserEntryWithPopup from "../../../components/user/UserEntryWithPopup";
import { useUserQuery } from "../../../domain/users";
import { EmbeddedObjectRoot } from "./EmbeddedObjectRoot";
import EmbeddedUserEventError from "./EmbeddedUserEventError";

const UserEventContent = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    gap: theme.spacing(2),
    alignItems: "center",
}));

type EmbeddedUserEventProps = { id: number; text: React.ReactElement };

const EmbeddedUserEvent = ({ id, text }: EmbeddedUserEventProps) => {
    const userQuery = useUserQuery(id);

    const user = userQuery.data;

    return (
        <EmbeddedObjectRoot isLoading={userQuery.isLoading} isError={userQuery.isError} errorTemplate={<EmbeddedUserEventError />}>
            <UserEventContent>
                <UserEntryWithPopup avatarProps={{ size: 40 }} iconOnly user={user} />
                <Typography variant="body2">{text}</Typography>
            </UserEventContent>
        </EmbeddedObjectRoot>
    );
};

export default EmbeddedUserEvent;
