import { Stack, styled } from "@mui/material";
import React, { useState } from "react";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel, { CarouselItem } from "./Carousel";
import CarouselButton from "./CarouselButton";

const ImageWrapper = styled("div")(() => ({
    aspectRatio: 16 / 9,
    overflow: "hidden",
    "&:hover": {
        opacity: 0.9,
        cursor: "pointer",
    },
    backgroundColor: "rgba(0, 0, 0, 0.20)",
}));

const BackgroundWrapper = styled("div")(() => ({
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "center",
}));

const SingleImage = styled("img")(() => ({
    objectFit: "contain",
    width: "100%",
    height: "100%",
}));

const ResponsiveInlineCarousel = styled(ResponsiveCarousel)(() => ({
    // this is a hack to prevent half-pixel borders of other slides showing
    ["& .carousel .slide"]: {
        visibility: "hidden",
        transition: "visibility 0.4s ease-out",
    },
    ["& .carousel .slide.selected"]: {
        visibility: "visible",
    },
}));

export type ThumbnailCarouselItem = {
    displayName?: string;
    imgLink: string;
    backgroundImgLink: string;
};

interface CarouselProps {
    onChange: (index: number) => void;
    currentIndex: number | null;
    itemsThumbs: ThumbnailCarouselItem[];
    items: CarouselItem[];
}

const preventPropagation = (event: React.SyntheticEvent) => {
    event.stopPropagation();
};

const InlineCarousel = ({ items, itemsThumbs, currentIndex, onChange }: CarouselProps) => {
    const [showLightbox, setShowLightbox] = useState<boolean>(false);

    const onClickItem = () => {
        setShowLightbox(true);
        onChange(currentIndex ?? 0);
    };

    const closeLightbox = (index: number) => {
        setShowLightbox(false);
        onChange(index);
    };

    const renderPrevButton = (onClick: () => void, hasItems: boolean, label: string) => (
        <CarouselButton role="previous" show={hasItems} label={label} onClick={onClick} />
    );

    const renderNextButton = (onClick: () => void, hasItems: boolean, label: string) => (
        <CarouselButton role="next" show={hasItems} label={label} onClick={onClick} />
    );

    if (items.length === 0) {
        return <></>;
    }

    return (
        <>
            {items.length > 1 ? (
                <ResponsiveInlineCarousel
                    showArrows={true}
                    showThumbs={false}
                    showStatus={false}
                    onClickItem={onClickItem}
                    selectedItem={currentIndex ?? 0}
                    onChange={onChange}
                    renderArrowPrev={renderPrevButton}
                    renderArrowNext={renderNextButton}
                    useKeyboardArrows
                >
                    {itemsThumbs.map((src) => (
                        <Stack spacing={2} justifyContent="center" key={src.imgLink}>
                            <BackgroundWrapper sx={{ backgroundImage: `url(${src.backgroundImgLink})` }}>
                                <ImageWrapper>
                                    <SingleImage src={src.imgLink} alt={src.displayName} onClick={preventPropagation} />
                                </ImageWrapper>
                            </BackgroundWrapper>
                        </Stack>
                    ))}
                </ResponsiveInlineCarousel>
            ) : (
                <BackgroundWrapper sx={{ backgroundImage: `url(${itemsThumbs[0].backgroundImgLink})` }}>
                    <ImageWrapper>
                        <SingleImage src={itemsThumbs[0].imgLink} alt={itemsThumbs[0].displayName} onClick={onClickItem} />
                    </ImageWrapper>
                </BackgroundWrapper>
            )}

            {showLightbox ? (
                <Carousel onClose={() => closeLightbox(currentIndex ?? 0)} onChange={onChange} currentIndex={currentIndex} items={items} />
            ) : null}
        </>
    );
};

export default InlineCarousel;
