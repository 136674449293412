import { styled } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SidebarLayout from "../../components/SidebarLayout";
import SubtaskRouter from "../../components/tasks/SubtaskRouter";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useIsMobile } from "../../lib/mobile";
import { translationKeys } from "../../translations/main-translations";
import ActivitiesViewContent from "./ActivitiesViewContent";
import ActivitiesPreferences from "./preferences/ActivitiesPreferences";

const Container = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    height: "100%",
    backgroundColor: theme.palette.background.light,
}));

const ActivitiesView = () => {
    const { t: translate } = useTranslation();
    useDocumentTitle(translationKeys.VDLANG_MY_ACTIVITIES_TITLE);
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useIsMobile();
    return (
        <SidebarLayout
            open={isMobile ? isOpen : undefined}
            onOpen={() => (isMobile ? setIsOpen(true) : undefined)}
            sidebar={<ActivitiesPreferences setIsOpen={setIsOpen} />}
            collapsedHeader={translate(translationKeys.VDLANG_MY_ACTIVITIES_TITLE)}
        >
            <Container>
                <SubtaskRouter>
                    <ActivitiesViewContent />
                </SubtaskRouter>
            </Container>
        </SidebarLayout>
    );
};

export default ActivitiesView;
