import { i18n } from "i18next";
import { useTranslation } from "react-i18next";
import { Language } from "../translations/main-translations";

export function getLanguageFromI18nInstance({ language, languages, resolvedLanguage }: i18n): Language {
    // According to docs, languages should be preferred because it actually resembles the resolving of the translation key
    return (resolvedLanguage ?? languages[0] ?? language) as Language;
}

/**
 * Returns the current language of the user interface.
 *
 * It is set on application startup from either
 *  - The users configured language
 *  - Derived from the browsers locale
 *
 * @export
 * @return {Language} The current language in which the user interface is shown
 */
export function useLanguage(): Language {
    const { i18n } = useTranslation();
    return getLanguageFromI18nInstance(i18n);
}
