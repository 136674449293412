import AlarmOnIcon from "@mui/icons-material/AlarmOnRounded";
import EventIcon from "@mui/icons-material/EventRounded";
import { styled } from "@mui/material";

const Container = styled("div")(({ theme }) => ({
    position: "relative",
    lineHeight: "1em",
    height: "1em",
    fontSize: "1.5rem",
}));

const AlarmIcon = styled(AlarmOnIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: "0.5em",
    position: "absolute",
    top: 0,
    left: 0,
    transform: "translate(-25%, -25%)",
    borderRadius: "50%", // make item rounded, so white background does not hide underlaying icon parts
    backgroundColor: theme.palette.background.paper,
}));

interface IDatePickerIconProps {
    remindAt?: Date | null;
    className?: string;
}

const DatePickerIcon = ({ remindAt, className }: IDatePickerIconProps) => {
    return (
        <Container className={className}>
            <EventIcon fontSize="inherit" color="action" />
            {remindAt != null ? <AlarmIcon /> : null}
        </Container>
    );
};

export default DatePickerIcon;
