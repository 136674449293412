import FileCopyIcon from "@mui/icons-material/FileCopyRounded";
import { IconButton, InputAdornment, OutlinedTextFieldProps, TextField } from "@mui/material";
import React, { ReactElement, useRef } from "react";

interface ICopieableTextFieldProps extends Omit<OutlinedTextFieldProps, "variant"> {
    additionalAdornments?: ReactElement;
}
const CopieableTextField = React.forwardRef<HTMLDivElement, ICopieableTextFieldProps>(
    ({ additionalAdornments, ...textfieldProps }, ref) => {
        const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);

        const copyToClipboard = (e: React.MouseEvent<HTMLButtonElement>) => {
            if (inputRef.current == null) {
                return;
            }
            inputRef.current.select();
            navigator.clipboard.writeText(inputRef.current.value);
            if (e.target instanceof HTMLElement) {
                e.target.focus();
            }
        };

        const endAdornment = (
            <InputAdornment position="end">
                <IconButton size="small" onClick={copyToClipboard} edge="end">
                    <FileCopyIcon fontSize="small" />
                </IconButton>
                {additionalAdornments}
            </InputAdornment>
        );

        return <TextField inputRef={inputRef} InputProps={{ endAdornment }} {...textfieldProps} ref={ref} />;
    },
);

export default CopieableTextField;
