import { zNumericId } from "api-shared";
import { useMemo } from "react";
import { z } from "zod";
import { DEFAULT_SEARCH_DELAY, useGlobalSearch } from "../domain/search";
import { useDebounce } from "./useDebounce";

const DEFAULT_MIN_LENGTH = 3;

export default function useNavbarSearch(searchKey: string, minLength = DEFAULT_MIN_LENGTH) {
    const debouncedKey = useDebounce(searchKey.trim(), DEFAULT_SEARCH_DELAY);

    const zValidator = useMemo(() => zNumericId.positive().or(z.string().min(minLength)), [minLength]);

    const searchQuery = useGlobalSearch(debouncedKey, zValidator.safeParse(debouncedKey).success);
    return {
        // Pretend to be loading when searchKey has not yet been passed to query
        searchInProgress: searchQuery.isFetching || debouncedKey !== searchKey.trim(),
        results: searchQuery.data,
        isValidKey: zValidator.safeParse(searchKey).success, // provide validation status of non-debounced searchKey for immediate user feedback
    };
}
