import { Collapse, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextFieldProps } from "@mui/material";
import { AttributeTitle, DiscardReasons as discardReasons, nonNullable } from "api-shared";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { Language } from "../../translations/main-translations";
import Select from "../input/select/Select";
import { Option } from "../input/select/types";
import MarkdownEditorUserMentionsIdea from "../markdowneditor/MarkdownEditorUserMentionsIdea";
import MarkdownEditorUserMentionsMeasure from "../markdowneditor/MarkdownEditorUserMentionsMeasure";

const discardSubReasons = [4, 5, 6, 7];

const isValidReason = (reason: number | null) => reason != null && reason !== 3;
const isValidStatement = (statement: unknown) => typeof statement === "string" && statement.trim().length > 0;

interface DiscardEntityFormProps extends Pick<TextFieldProps, "margin" | "disabled"> {
    reason: number | null;
    statement: string | null | undefined;
    onChange?: (name: "statement" | "reason", value: number | string, isValid: boolean) => void;
    updateValue: (name: "statement" | "reason", value: number | string, isValid: boolean) => void;
    translate: TFunction;
    currentLanguage: Language;
    isInMeasureContext?: boolean;
}

const DiscardEntityForm = ({
    reason,
    statement,
    onChange,
    updateValue,
    translate,
    currentLanguage,
    margin = "normal",
    disabled,
    isInMeasureContext = false,
}: DiscardEntityFormProps) => {
    const updateReason = (eventOrId: React.ChangeEvent<HTMLInputElement> | Option<number> | null) => {
        if (eventOrId == null) {
            return;
        }

        const id = "value" in eventOrId ? eventOrId.value : +eventOrId.target.value;
        updateValue("reason", id, isValidReason(id) && isValidStatement(statement));
    };

    const onChangeStatement = useCallback(
        (newStatement: string) => {
            if (typeof onChange === "function") {
                onChange("statement", newStatement, isValidReason(reason) && isValidStatement(newStatement));
            }
        },
        [onChange, reason],
    );

    const setDiscardStatement = useCallback(
        (newStatement: string) => {
            updateValue("statement", newStatement, isValidReason(reason) && isValidStatement(newStatement));
        },
        [reason, updateValue],
    );

    const mainReason = reason != null && discardSubReasons.includes(reason) ? 3 : reason;
    const mainReasons = discardReasons.filter((r) => !discardSubReasons.includes(r.id));
    const mainReasonOptions = mainReasons.map((r) => ({
        value: r.id,
        label: r[currentLanguage],
    }));

    return (
        <FormGroup>
            <Select
                isDisabled={disabled}
                margin={margin}
                label={translate(AttributeTitle.DiscardReason)}
                placeholder={translate("Please select...")}
                options={mainReasonOptions}
                value={mainReasonOptions.find((o) => o.value === mainReason)}
                onChange={updateReason}
                name="reason"
                inputId="discard-reason-select" // used for cypress testing; `data-testid` does not work
                menuPortalTarget={document.body}
                closeMenuOnScroll
            />
            <Collapse in={reason === 3 || (reason != null && discardSubReasons.includes(reason))}>
                <FormControl component="fieldset" margin={margin} disabled={disabled} fullWidth>
                    <RadioGroup name="subreason" value={reason} onChange={updateReason} row>
                        {discardSubReasons
                            .map((id) => discardReasons.find((r) => r.id === id))
                            .filter(nonNullable)
                            .map((subReason) => (
                                <Grid item xs={12} sm={6} key={subReason.id}>
                                    <FormControlLabel
                                        key={subReason.id}
                                        value={String(subReason.id)}
                                        control={<Radio size="small" style={{ padding: 4, marginLeft: 5 }} />}
                                        label={subReason[currentLanguage]}
                                    />
                                </Grid>
                            ))}
                    </RadioGroup>
                </FormControl>
            </Collapse>
            <FormControl margin={margin} required disabled={disabled}>
                {isInMeasureContext ? (
                    <MarkdownEditorUserMentionsMeasure
                        disabled={disabled}
                        value={statement}
                        onChange={onChangeStatement} // catch updates when blurring the editor
                        updateValue={setDiscardStatement} // catch also updates on keystroke
                        placeholder={translate("discard_reason_placeholder")}
                    />
                ) : (
                    <MarkdownEditorUserMentionsIdea
                        disabled={disabled}
                        value={statement}
                        onChange={onChangeStatement} // catch updates when blurring the editor
                        updateValue={setDiscardStatement} // catch also updates on keystroke
                        placeholder={translate("discard_reason_placeholder")}
                    />
                )}
            </FormControl>
        </FormGroup>
    );
};

export default DiscardEntityForm;
