import ArrowBackRounded from "@mui/icons-material/ArrowBackRounded";
import {
    Button,
    buttonClasses,
    Card,
    CardContent,
    Container,
    Grid,
    Stack,
    styled,
    svgIconClasses,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { UserTier } from "api-shared";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useCurrentClient } from "../../domain/client";
import { useFeedSummary } from "../../domain/feed";
import { useCurrentUser } from "../../domain/users";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { baseUrl } from "../../lib/api";
import { useFieldDataSubscription } from "../../lib/field-options";
import { RouteFor } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";
import FeedEntryDetails from "./FeedDetailView";
import FeedSummary from "./FeedSummary";
import FeedUserGreeting from "./FeedUserGreeting";
import InfiniteFeedEntryList from "./InfiniteFeedEntryList";
import { RecentMeasuresCard } from "./RecentMeasuresCard";

const FeedRoot = styled("div")(({ theme }) => ({
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.light,
}));

const WelcomeCardContent = styled(CardContent)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    "&:last-child": {
        paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

const Logo = styled("img")(({ theme }) => ({
    maxWidth: 320,
    maxHeight: 52,
    order: -1,
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
        order: "unset",
        paddingBottom: "unset",
    },
}));

const BackButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    borderColor: theme.palette.border.light,
    [`& .${buttonClasses.startIcon}`]: {
        marginRight: theme.spacing(0.5),
    },
    [`& .${svgIconClasses.root}`]: {
        fontSize: theme.typography.body1.fontSize,
    },
})) as typeof Button;

const BackButtonIcon = styled(ArrowBackRounded)(({ theme }) => ({
    color: theme.palette.action.active,
}));

const FeedView = () => {
    const { feedEntryId } = useParams<{ feedEntryId: string }>();
    const theme = useTheme();
    const isDenseLayout = useMediaQuery(theme.breakpoints.down("md"));
    const feed = useFeedSummary();
    const user = useCurrentUser();
    const client = useCurrentClient();
    const { t } = useTranslation();
    const rootRef = useRef<HTMLDivElement>(null);
    const isDetailPage = feedEntryId !== undefined;

    useFieldDataSubscription(); // fetch data for fields in preview/edit dialogs

    useDocumentTitle(translationKeys.VDLANG_FEED);

    if (user == null) {
        return <LoadingAnimation />;
    }

    const userCanSeeProcesses = user.tier !== UserTier.Light;

    return (
        <FeedRoot ref={rootRef}>
            <Container maxWidth="lg" sx={{ p: 4 }}>
                <Grid container spacing={2}>
                    <Grid item md={userCanSeeProcesses ? 8 : 12} xs={12}>
                        <Stack spacing={1} role="feed">
                            {!isDetailPage ? (
                                <>
                                    <Card>
                                        <WelcomeCardContent>
                                            <div>
                                                {user.displayname !== null ? (
                                                    <Typography variant="h5">
                                                        <FeedUserGreeting name={user.displayname} />
                                                    </Typography>
                                                ) : null}
                                            </div>
                                            {client.logoHash ? <Logo alt="logo" src={`${baseUrl}static/${client.logoHash}`} /> : null}
                                        </WelcomeCardContent>
                                    </Card>
                                    {isDenseLayout && userCanSeeProcesses && <RecentMeasuresCard />}
                                    <InfiniteFeedEntryList rootRef={rootRef}></InfiniteFeedEntryList>
                                </>
                            ) : (
                                <>
                                    <Grid>
                                        <BackButton
                                            variant="outlined"
                                            color="inherit"
                                            startIcon={<BackButtonIcon />}
                                            size="small"
                                            component={Link}
                                            to={RouteFor.feed.view}
                                        >
                                            {t(translationKeys.VDLANG_FEED_DETAIL_BACK_BUTTON)}
                                        </BackButton>
                                    </Grid>
                                    <FeedEntryDetails feedEntryId={+feedEntryId} />
                                </>
                            )}
                        </Stack>
                    </Grid>

                    {!isDenseLayout && userCanSeeProcesses && (
                        <Grid item md={4}>
                            <Stack spacing={1}>
                                <RecentMeasuresCard />
                                <FeedSummary data={feed.data} user={user} />
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </FeedRoot>
    );
};

export default React.memo(FeedView);
