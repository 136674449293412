import { alpha, Chip, ChipProps, styled } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import useCurrency from "../hooks/useCurrency";

interface IMoneyChipProps extends ChipProps {
    approx?: boolean;
    value: number;
    short?: boolean;
    autoHeight?: boolean;
}

const StyledChip = styled(Chip, { shouldForwardProp: (name) => name !== "autoHeight" && name !== "value" })<{
    value: number;
    autoHeight?: boolean;
}>(({ theme, autoHeight, value }) => {
    let color: string = red[800];
    let backgroundColor = alpha(theme.palette.error.main, 0.1);
    if (value > 0) {
        color = green[800];
        backgroundColor = alpha(theme.palette.natureGreen.main, 0.1);
    } else if (value === 0) {
        color = grey[800];
        backgroundColor = alpha(theme.palette.grey[500], 0.1);
    }

    return {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: "normal",
        height: autoHeight ? "auto" : theme.spacing(3),
        cursor: "inherit",
        color,
        backgroundColor,
    };
});

const MoneyChip = ({ approx, value, short, autoHeight, ...otherProps }: IMoneyChipProps) => {
    const { formatCurrencyShort, formatCurrency } = useCurrency();

    const shortLabel = short ? formatCurrencyShort(value) : formatCurrency(value);

    const label = approx && shortLabel !== null && value !== 0 ? "~" + shortLabel : shortLabel;

    return <StyledChip as="span" value={value} autoHeight={autoHeight} label={label} {...otherProps} />;
};

export default MoneyChip;
