import { alpha, ButtonBase, Checkbox, styled, Tooltip, Typography } from "@mui/material";
import { treeItemClasses, useTreeItem, type TreeItemContentProps } from "@mui/x-tree-view";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { CheckStatus } from "../../../lib/tree";
import { translationKeys } from "../../../translations/main-translations";
import { useTreeInputContext } from "./TreeInputContext";

const MULTI_CLASS = "TreeInputTreeItemContent-multi";
const NOT_SELECTABLE_CLASS = "TreeInputTreeItemContent-notSelectable";

const Root = styled("div")(({ theme }) => ({
    [`&.${treeItemClasses.content}`]: {
        padding: 0,
        cursor: "default",
        [`&.${MULTI_CLASS}`]: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
        [`&:hover`]: {
            backgroundColor: "transparent",
        },
        [`&:not(.${NOT_SELECTABLE_CLASS})`]: {
            [`& .${treeItemClasses.label}:hover`]: {
                cursor: "pointer",
                backgroundColor: theme.palette.action.hover,
            },
        },
        [`& .${treeItemClasses.iconContainer}`]: {
            margin: 0,
            width: theme.spacing(2.5),
            [`& > svg`]: {
                fontSize: "1.25rem",
            },

            [`&:hover`]: {
                backgroundColor: theme.palette.action.hover,
                borderRadius: theme.shape.borderRadius,
                cursor: "pointer",
            },
        },
        [`& .${treeItemClasses.label}`]: {
            ...theme.typography.body2,
            padding: theme.spacing(0.75, 0),
            lineHeight: "20px",
            marginLeft: theme.spacing(0.5),
            paddingLeft: theme.spacing(0.5),
            borderRadius: theme.shape.borderRadius,
        },
        [`&.${treeItemClasses.selected}:not(.${MULTI_CLASS})`]: {
            backgroundColor: "transparent",
            [`& .${treeItemClasses.label}`]: {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
                ),
            },
        },
        [`&.${treeItemClasses.focused}`]: {
            backgroundColor: "transparent",
        },
    },
}));

const TreeInputTreeItemContent = React.forwardRef<HTMLDivElement, TreeItemContentProps>(function CustomContent(props, ref) {
    const { classes, className, label, nodeId, expansionIcon } = props;

    const { checkStates, isMulti, isSelectableStates } = useTreeInputContext();
    const { t } = useTranslation();

    const checkState = checkStates.get(Number(nodeId)) ?? CheckStatus.NO;
    const isSelectable = isSelectableStates.get(Number(nodeId)) ?? true;

    const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } = useTreeItem(nodeId);

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        // prevent text selection
        preventSelection(event);
    };

    const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        // Simulate ctrl key press, because useTreeItem hook only triggers selection if any of the modifiers is pressed :ugly:
        event.ctrlKey = isMulti;

        handleSelection(event);
    };

    const disabledOrNotSelectable = disabled || !isSelectable;

    return (
        <Root
            className={classNames(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: !isMulti && selected,
                [classes.focused]: !isMulti && focused,
                [classes.disabled]: disabled,
                [MULTI_CLASS]: isMulti,
                [NOT_SELECTABLE_CLASS]: !isSelectable,
            })}
            onMouseDown={handleMouseDown}
            ref={ref}
        >
            <ButtonBase
                className={classes.iconContainer}
                onClick={handleExpansionClick as React.MouseEventHandler}
                disabled={disabled}
                disableRipple
            >
                {expansionIcon}
            </ButtonBase>
            {isMulti ? (
                <Checkbox
                    indeterminate={checkState === CheckStatus.PARTIALLY}
                    checked={selected}
                    disabled={disabledOrNotSelectable}
                    size="small"
                    onClick={handleSelectionClick as React.MouseEventHandler}
                    // padding provides spacing left&right in resting state
                    // is used by hover effect in hover state
                    sx={{ p: 0.5 }}
                />
            ) : null}

            {isSelectable ? (
                <Typography
                    onClick={!disabled ? (handleSelectionClick as React.MouseEventHandler) : undefined}
                    component="div"
                    className={classes.label}
                    sx={{ pl: 0 }}
                >
                    {label}
                </Typography>
            ) : (
                <Tooltip title={t(translationKeys.VDLANG_TREE_NODE_NOT_SELECTABLE)} placement="bottom-start">
                    <Typography
                        onClick={!disabled ? (handleExpansionClick as React.MouseEventHandler) : undefined}
                        component="div"
                        className={classes.label}
                        sx={{ pl: 0 }}
                    >
                        {label}
                    </Typography>
                </Tooltip>
            )}
        </Root>
    );
});

export default TreeInputTreeItemContent;
