import SubtaskMasterDetail, { ISubtaskMasterDetailProps } from "../../components/tasks/SubtaskMasterDetail";
import { useAllUsers } from "../../domain/users";
import { useSubTaskQuery } from "./useSubTaskQuery";

const ActivitiesViewContent = (props: Omit<ISubtaskMasterDetailProps, "tasks" | "users">) => {
    const subTaskQuery = useSubTaskQuery();
    const users = useAllUsers();

    return (
        <SubtaskMasterDetail
            isFetching={!subTaskQuery.isSuccess}
            users={users}
            tasks={subTaskQuery.data?.tasks ?? []}
            groups={subTaskQuery.data?.groups}
            {...props}
        />
    );
};

export default ActivitiesViewContent;
