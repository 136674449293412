import { Store } from "redux";
import { take } from "redux-saga/effects";
import { REGISTER_QUERY_CLIENT, RegisterQueryClientAction } from "../infrastructure/react-query";

const DISPOSE_APPLICATION = "DISPOSE_APPLICATION";

export function disposeApplicationEvent(event: PageTransitionEvent) {
    return {
        type: DISPOSE_APPLICATION,
        event,
    };
}

export function registerDisposeListener(store: Store) {
    // The recommended way would be to use "visibilitychange" event but there is no way to find out if the page is going to be
    // terminated or re-used afterwards, see: https://developer.chrome.com/articles/page-lifecycle-api/#event-visibilitychange
    // Although the "pagehide" event is not fully reliable triggered (e.g. on mobile), it is the event most close to tab/browser being closed
    // See: https://developer.chrome.com/articles/page-lifecycle-api/#event-pagehide
    window.addEventListener("pagehide", (event) => {
        if (!event.persisted) {
            // page won't be stored in backward/forward cache but will be terminated afterwards -> dispatch application disposal event
            store.dispatch(disposeApplicationEvent(event));
        }
    });
}

export function* disposeSaga() {
    const { queryClient } = (yield take(REGISTER_QUERY_CLIENT)) as RegisterQueryClientAction;

    yield take(DISPOSE_APPLICATION);

    // Cancel ALL pending queries when application is disposed to avoid "Failed to fetch" errors issues captured by Sentry
    queryClient.cancelQueries();
}
