import ArrowDownwardIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpwardRounded";
import RemoveIcon from "@mui/icons-material/RemoveRounded";
import { styled, SvgIconProps } from "@mui/material";
import { SubTaskPriority } from "api-shared";
import React from "react";
import withTooltip from "../../hocs/withTooltip";

const IconComponents = {
    [SubTaskPriority.PRIO_LOW]: styled(ArrowDownwardIcon)(({ theme }) => ({ color: theme.palette.natureGreen.main })),
    [SubTaskPriority.PRIO_MEDIUM]: styled(RemoveIcon)(({ theme }) => ({ color: theme.palette.secondary.main })),
    [SubTaskPriority.PRIO_HIGH]: styled(ArrowUpwardIcon)(({ theme }) => ({ color: theme.palette.error.main })),
};

interface IPriorityIconProps extends SvgIconProps {
    priority?: SubTaskPriority;
}

const PriorityIcon = React.forwardRef<SVGSVGElement, IPriorityIconProps>(({ priority, ...other }, ref) => {
    if (priority === undefined) {
        return null;
    }
    const Component = IconComponents[priority];
    return <Component {...other} ref={ref} />;
});

export default withTooltip(PriorityIcon);
