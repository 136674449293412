import { AclNamespaces, AclPermissions } from "api-shared";
import React from "react";
import { useUserHasPermissionQuery } from "../domain/permissions";

interface IAuthorizationProps {
    namespace: AclNamespaces;
    permission?: AclPermissions;
    fallbackComponent?: React.ComponentType;
}

const Authorization = ({
    namespace,
    permission = AclPermissions.Access,
    fallbackComponent: FallbackComponent,
    children,
}: React.PropsWithChildren<IAuthorizationProps>) => {
    const hasPermissionQuery = useUserHasPermissionQuery({ namespace, permission, fact: {} });

    if (!hasPermissionQuery.isSuccess) {
        return null;
    }

    if (hasPermissionQuery.data.hasPermission) {
        // Not wrapping in a fragment yields typescript errors
        return <>{children}</>;
    }
    if (FallbackComponent != null) {
        return <FallbackComponent />;
    }
    return null;
};

export default Authorization;
