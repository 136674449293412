import { InputAdornment, TextField, styled } from "@mui/material";
import { CURRENCY_MAX_FRACTIONAL_DIGITS, MAX_INTEGER_DIGITS } from "api-shared";
import { TFunction } from "i18next";
import SquareRoot from "mdi-material-ui/SquareRoot";
import React, { useEffect, useState } from "react";
import useCurrency from "../../hooks/useCurrency";
import { isValidCurrencyFormulaInput, isValidCurrencyInput, isValidCurrencyValue } from "../../lib/currency";
import { isEnterEvent } from "../../lib/keybindings";
import { translationKeys } from "../../translations/main-translations";
import Tooltip from "../Tooltip";

const FormulaInputAdornment = styled(InputAdornment)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

function getCurrencyHint(
    translate: TFunction,
    { hasInvalidCharacter, hasInvalidCurrencyValue, hasInvalidFormula }: Record<string, boolean>,
): string | null {
    if (hasInvalidCharacter) {
        return translate("NoLettersInCurrency");
    }

    if (hasInvalidCurrencyValue) {
        return translate(translationKeys.VDLANG_CURRENCY_INPUT_DIGIT_LIMIT, {
            maxIntegerDigits: MAX_INTEGER_DIGITS,
            maxFractionalDigits: CURRENCY_MAX_FRACTIONAL_DIGITS,
        });
    }

    if (hasInvalidFormula) {
        return translate(translationKeys.VDLANG_CURRENCY_INPUT_INVALID_FORMULA);
    }
    return null;
}

interface ICurrencyInputProps {
    value: string | number | null;
    formula?: string | null;
    disabled?: boolean;
    updateValue: (newValue: number | string | null, forceUpdate?: boolean) => void;
    translate: TFunction;
    fullWidth?: boolean;
    testId?: string;
}

const CurrencyInput = ({ value, formula = null, disabled, updateValue, translate, fullWidth = true, testId }: ICurrencyInputProps) => {
    const [input, setInput] = useState<string | null>(null);
    const [hasInvalidCharacter, setHasInvalidCharacter] = useState(false);
    const [hasInvalidCurrencyValue, setHasInvalidCurrencyValue] = useState(false);
    const hasInvalidFormula = formula !== null && value === null;

    const { currencyIsoCode, formatCurrencyNoCode, sanitizeCurrencyInput, formatCurrencyInput } = useCurrency();

    // Reset input state when value or formula has changed
    useEffect(() => {
        setInput(null);
    }, [value, formula]);

    const isOriginalFormula = formula != null && isValidCurrencyFormulaInput(formula);

    let originalInput = value ? value.toString() : "";
    if (isOriginalFormula) {
        originalInput = formula ?? "";
    }

    function onFocus() {
        if (input === null) {
            const formattedOriginalInput = formatCurrencyInput(originalInput);
            setInput(formattedOriginalInput);
        }
    }

    function onBlur() {
        // if input is null here, neither onFocus nor onChange have been fired so nothing to do
        if (input === null) {
            return;
        }

        // Use the standard format that is saved to the database
        const sanitizedInput = sanitizeCurrencyInput(input);

        // Check if sanitizing the input produced valid values
        const isValidFormula = isValidCurrencyFormulaInput(sanitizedInput);
        const isValid = sanitizedInput === "" || isValidCurrencyValue(sanitizedInput) || isValidFormula;

        // Show an error when the currency values are too large
        if (!isValid) {
            setHasInvalidCurrencyValue(true);
            return;
        }

        const hasChanges = originalInput !== sanitizedInput;

        // Only send an update request when there are changes
        if (hasChanges) {
            const value = isValidFormula ? sanitizedInput : Number(sanitizedInput);
            updateValue(value, true);
        } else {
            setInput(null);
        }

        // At this point everything is valid and the error hints can be removed
        setHasInvalidCharacter(false);
        setHasInvalidCurrencyValue(false);
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const input = e.target.value;

        // Check if the input characters are valid on each keystroke
        if (isValidCurrencyInput(input)) {
            setInput(input);
            setHasInvalidCharacter(false);
        } else {
            setHasInvalidCharacter(true);
        }
    }

    function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (isEnterEvent(event)) {
            onBlur();
        }
    }

    const startAdornment = <InputAdornment position="start">{currencyIsoCode}</InputAdornment>;
    const endAdornment = isOriginalFormula && (
        <Tooltip title={translate("calculated_field_hint")}>
            <FormulaInputAdornment position="end">
                <SquareRoot />
            </FormulaInputAdornment>
        </Tooltip>
    );

    let currentTextFieldValue = input;
    if (currentTextFieldValue == null && value != null) {
        currentTextFieldValue = formatCurrencyNoCode(value);
    }

    const helperText = getCurrencyHint(translate, { hasInvalidCharacter, hasInvalidCurrencyValue, hasInvalidFormula });

    return (
        <Tooltip title={formula ?? ""}>
            <TextField
                value={currentTextFieldValue ?? ""}
                helperText={helperText ?? ""}
                disabled={disabled}
                error={helperText !== null}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={onKeyDown}
                InputProps={{ startAdornment, endAdornment }}
                fullWidth={fullWidth}
                inputProps={{
                    "data-testid": testId,
                }}
            />
        </Tooltip>
    );
};

export default CurrencyInput;
