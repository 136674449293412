import { Container, Stack, styled, Typography } from "@mui/material";
import { zLogin } from "api-shared";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SplitLayout from "../../components/SplitLayout";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import background from "../../static/images/login_illustration.svg";
import logo from "../../static/images/logo_blau.svg";
import { translationKeys } from "../../translations/main-translations";
import TwoFactorAuthenticationSetupStepper from "./TwoFactorAuthenticationSetupStepper";

const Image = styled("img")(({ theme }) => ({
    width: 256,
}));

function TwoFactorAuthenticationSetupPage() {
    const { t: translate } = useTranslation();
    const location = useLocation();
    const parseResult = zLogin.safeParse(location.state);

    useDocumentTitle(translationKeys.VDLANG_LOGIN_TITLE);

    if (!parseResult.success) {
        // Hard throw for gloabal error reporting - this should never happen
        throw new Error("TwoFactorAuthenticationSetupPage was called without required params.");
    }

    return (
        <SplitLayout image={background}>
            <Container fixed maxWidth="xs" sx={{ height: "100%" }}>
                <Stack sx={{ height: "100%" }} justifyContent="center" spacing={4}>
                    <Image src={logo} alt="Valuedesk" />
                    <Typography variant="h4">{translate(translationKeys.VDLANG_TFA)}</Typography>
                    <TwoFactorAuthenticationSetupStepper
                        email={parseResult.data.email}
                        defaultPassword={parseResult.data.password}
                        returnToLogin={true}
                    />
                </Stack>
            </Container>
        </SplitLayout>
    );
}

export default React.memo(TwoFactorAuthenticationSetupPage);
