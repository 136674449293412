import { Grid, Skeleton, SkeletonProps, Stack } from "@mui/material";
import SubtaskLabel from "./SubtaskLabel";

function LabeledSkeleton(props: SkeletonProps) {
    return (
        <Stack>
            <SubtaskLabel>
                <Skeleton width={100} />
            </SubtaskLabel>
            <Skeleton height={40} width="100%" {...props} />
        </Stack>
    );
}

const SubtaskDetailSkeleton = () => {
    return (
        <Grid container spacing={5} justifyContent="space-between">
            <Grid item xs={3}>
                {/* Complete button */}
                <Skeleton variant="rounded" width="100%" height={40} />
            </Grid>
            <Grid item xs={2}>
                {/* Menu + close button */}
                <Skeleton variant="rounded" width="100%" height={40} />
            </Grid>
            <Grid item xs={8}>
                {/* Title */}
                <Skeleton width="100%" height={40} />
            </Grid>
            <Grid item xs={4}>
                {/* Priority */}
                <Skeleton width="100%" height={40} />
            </Grid>
            <Grid item xs={12}>
                {/* Effort */}
                <LabeledSkeleton />
            </Grid>
            <Grid item xs={6}>
                {/* Measure */}
                <LabeledSkeleton />
            </Grid>
            <Grid item xs={6}>
                {/* Level */}
                <LabeledSkeleton />
            </Grid>
            <Grid item xs={6}>
                {/* Responsible */}
                <LabeledSkeleton />
            </Grid>
            <Grid item xs={6}>
                {/* Duedate */}
                <LabeledSkeleton />
            </Grid>
            <Grid item xs={12}>
                {/* Description */}
                <LabeledSkeleton variant="rounded" height={144 /* default editor height */} />
            </Grid>
        </Grid>
    );
};

export default SubtaskDetailSkeleton;
