import AddIcon from "@mui/icons-material/AddRounded";
import { Button, buttonClasses, ButtonProps, styled } from "@mui/material";
import { blueGrey as BlueGrey } from "@mui/material/colors";
import { AclNamespaces, AclPermissions } from "api-shared";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateIdeaMutation } from "../../domain/ideas";
import { useMeasureConfigs } from "../../domain/measure-config";
import { useCreateMeasure } from "../../domain/measure/detail";
import { useUserHasAccessPermissionQuery, useUserHasPermissionQuery } from "../../domain/permissions";
import { trackEvent } from "../../infrastructure/tracking";
import CallToActionDialog from "./CallToActionDialog";

const SmallButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    // same padding as default top/bottom padding of button - make right wider to compensate smaller icon in relation to it's bounding box
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    whiteSpace: "nowrap",
    ["& span"]: {
        paddingLeft: theme.spacing(0.75),
    },
    // Hide button text on devices < lg (1200) and shrink borders
    [theme.breakpoints.down("lg")]: {
        paddingLeft: theme.spacing(0.75),
        paddingRight: theme.spacing(0.75),
        ["& span"]: {
            display: "none",
        },
    },
    [`&.${buttonClasses.contained}.Mui-disabled`]: {
        color: theme.palette.common.white,
        backgroundColor: BlueGrey[500],
    },
}));

const PlusButton = ({ onClick, ...buttonProps }: ButtonProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const measureConfigs = useMeasureConfigs();
    const navigate = useNavigate();
    const { t: translate } = useTranslation();

    const createMeasure = useCreateMeasure(({ id }) => navigate(`/measure/${id}`));

    const createIdea = useCreateIdeaMutation(({ id }) => {
        trackEvent({ category: "Opp", action: "Create CTA" });
        navigate(`/opportunities/${id}`);
    });

    const open = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            typeof onClick === "function" && onClick(event);
        },
        [setAnchorEl, onClick],
    );

    const canCreateProcessQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.Process,
        permission: AclPermissions.Create,
        fact: {},
    });
    const hasIdeaAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.Idea);
    if (!hasIdeaAccessPermissionQuery.isSuccess || !canCreateProcessQuery.isSuccess) {
        return null;
    }

    const userCanCreateProcess = canCreateProcessQuery.data.hasPermission;
    const userHasIdeaAccess = hasIdeaAccessPermissionQuery.data.hasPermission;
    if (measureConfigs.length <= 0 && !userHasIdeaAccess) {
        return null;
    }

    return (
        <>
            <CallToActionDialog
                show={Boolean(anchorEl)}
                onHide={() => setAnchorEl(null)}
                translate={translate}
                createMeasure={createMeasure.mutate}
                createIdea={createIdea.mutate}
                measureConfigs={measureConfigs}
                userCanCreateProcess={userCanCreateProcess}
                userHasIdeaAccess={userHasIdeaAccess}
            />
            <SmallButton onClick={open} variant="contained" color="secondary" {...buttonProps}>
                <AddIcon /> <span>{translate("Create")}</span>
            </SmallButton>
        </>
    );
};
export default PlusButton;
