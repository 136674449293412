import { Stack, styled } from "@mui/material";
import { SubTaskPriority } from "api-shared";
import { TFunction } from "i18next";
import { translationKeys } from "../../translations/main-translations";
import PriorityIcon from "../icons/PriorityIcon";

const IconWrapper = styled("span")(({ theme }) => ({
    marginRight: theme.spacing(),
    paddingTop: theme.spacing(0.5),
}));

interface ISubtaskListPriorityGroupHeaderProps {
    priority: SubTaskPriority;
    translate: TFunction;
}

const SubtaskListPriorityGroupHeader = ({ priority, translate }: ISubtaskListPriorityGroupHeaderProps) => {
    let title = "";
    switch (priority) {
        case SubTaskPriority.PRIO_LOW:
            title = translate(translationKeys.VDLANG_LOW);
            break;
        case SubTaskPriority.PRIO_HIGH:
            title = translate(translationKeys.VDLANG_HIGH);
            break;
        default:
            title = translate(translationKeys.VDLANG_MEDIUM);
    }

    return (
        <Stack direction="row" alignItems="center">
            <IconWrapper>
                <PriorityIcon priority={priority} title={title} fontSize="inherit" />
            </IconWrapper>
            {title}
        </Stack>
    );
};

export default SubtaskListPriorityGroupHeader;
