import { Sort, SubTaskGroupBy, SubTaskGroupOrderBy } from "api-shared";
import { TFunction } from "i18next";
import SidebarSelect from "../../../components/sidebar/SidebarSelect";
import { OrderedOptionHandler, useOrderOptions } from "../../../hooks/useOrderOptions";
import { translationKeys } from "../../../translations/main-translations";

const labels = {
    [SubTaskGroupOrderBy.EFFECT]: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_MEASURE_ORDER_BY_EFFECT,
    [SubTaskGroupOrderBy.ID]: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_PROCESS_ORDER_BY_PROCESS_ID,
    [SubTaskGroupOrderBy.TITLE]: "title",
    [SubTaskGroupOrderBy.DAYS_UNTIL_EFFECTIVENESS]:
        translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_MEASURE_ORDER_BY_DAYS_UNTIL_EFFECTIVENESS,
    [SubTaskGroupOrderBy.NAME]: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_ALPHABETICALLY,
    [SubTaskGroupOrderBy.DUEDATE]: translationKeys.VDLANG_DUE_DATE,
    [SubTaskGroupOrderBy.PRIORITY]: translationKeys.VDLANG_PRIORITY,
};
export const ACTIVITIES_GROUP_ORDER_DEPENDENCIES = {
    [SubTaskGroupBy.MEASURE]: [
        SubTaskGroupOrderBy.EFFECT,
        SubTaskGroupOrderBy.ID,
        SubTaskGroupOrderBy.TITLE,
        SubTaskGroupOrderBy.DAYS_UNTIL_EFFECTIVENESS,
    ],
    [SubTaskGroupBy.CREATED_BY]: [SubTaskGroupOrderBy.NAME],
    [SubTaskGroupBy.ASSIGNED_TO]: [SubTaskGroupOrderBy.NAME],
    [SubTaskGroupBy.DUEDATE]: [SubTaskGroupOrderBy.DUEDATE],
    [SubTaskGroupBy.PRIORITY]: [SubTaskGroupOrderBy.PRIORITY],
};

interface IActivitiesGroupOrderSelectProps {
    value: SubTaskGroupOrderBy;
    order: Sort;
    parentValue?: SubTaskGroupBy;
    translate: TFunction;
    onChange: OrderedOptionHandler<SubTaskGroupOrderBy>;
}

const ActivitiesGroupOrderSelect = ({
    parentValue = SubTaskGroupBy.MEASURE,
    order,
    value,
    onChange,
    translate,
    ...otherProps
}: IActivitiesGroupOrderSelectProps) => {
    const availableValues = ACTIVITIES_GROUP_ORDER_DEPENDENCIES[parentValue];
    const options = availableValues.map((optionValue) => ({
        value: optionValue,
        label: translate(labels[optionValue]),
    }));

    const orderOptions = useOrderOptions(value, order, options, onChange);

    return <SidebarSelect margin="none" {...orderOptions} {...otherProps} />;
};

export default ActivitiesGroupOrderSelect;
