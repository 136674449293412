import { useQuery } from "@tanstack/react-query";
import { ProcessPulseListDto, nonNullable } from "api-shared";
import Dataloader from "dataloader";
import queryString from "query-string";
import { apiGet } from "../lib/api";

/**
 * Make a combined signal, that aborts when any of the given signals is aborted
 *
 * @param {AbortSignal[]} signals
 * @returns {AbortSignal}
 */
function multiplexSignal(signals: AbortSignal[]): AbortSignal {
    const controller = new AbortController();
    signals.forEach((signal) => signal.addEventListener("abort", (reason) => controller.abort(reason)));
    return controller.signal;
}

export const ProcessPulseQueryKeys = {
    all: ["process-pulse"] as const,
    forProcess: (processId: number) => [...ProcessPulseQueryKeys.all, "forProcess", processId] as const,
};

const PROCESS_PULSE_PATH = "/measures/pulse";

type PulseRequestOptions = {
    measureId: number;
    signal?: AbortSignal;
};

const processPulseLoader = new Dataloader(
    async (inputs: readonly PulseRequestOptions[]) => {
        const ids = inputs.map(({ measureId }) => measureId);
        const query = queryString.stringify({ measureIds: [ids] }, { arrayFormat: "comma" });

        const signals = inputs.map(({ signal }) => signal);
        const multiplexedSignal = multiplexSignal(signals.filter(nonNullable));

        // request will be cancelled as soon as any of the individual requests is cancelled
        const processPulseData = await apiGet<ProcessPulseListDto>(`${PROCESS_PULSE_PATH}?${query}`, { signal: multiplexedSignal });

        return ids.map((id) => processPulseData.find((ppd) => ppd.processId === id) ?? null);
    },
    {
        cache: false,
        maxBatchSize: 10,
    },
);

export const useProcessPulse = (processId: number, enabled = false) => {
    return useQuery({
        queryKey: ProcessPulseQueryKeys.forProcess(processId),
        queryFn: ({ queryKey, signal }) => processPulseLoader.load({ measureId: queryKey[2], signal }),
        enabled,
    });
};
