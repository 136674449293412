import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Alert, Grid, IconButton, styled } from "@mui/material";
import { SubTaskStatus } from "api-shared";
import { TFunction } from "i18next";
import React from "react";
import { translationKeys } from "../../translations/main-translations";
import DangerMenuItem from "../DangerMenuItem";
import MoreActionsMenu from "../MoreActionsMenu";
import SubtaskCloseButton from "./SubtaskCloseButton";

const Container = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
}));

const Actions = styled(Grid)(({ theme }) => ({
    margin: theme.spacing(-1),
}));

interface ISubtaskDetailHeaderProps {
    disabled: boolean;
    status: SubTaskStatus;
    onClose: () => void;
    onRemove: () => void;
    updateStatus: (newStatus: SubTaskStatus) => void;
    translate: TFunction;
    showHint?: boolean;
    CloseIcon?: React.ComponentType;
}

const SubtaskDetailHeader = ({
    disabled,
    status,
    onClose,
    onRemove,
    updateStatus,
    translate,
    showHint = false,
    CloseIcon = ChevronRightRoundedIcon,
}: ISubtaskDetailHeaderProps) => {
    return (
        <>
            {showHint && (
                <Container>
                    <Alert severity="warning">{translate(translationKeys.VDLANG_ACTIVITIES_NOT_VISIBLE_IN_LIST_HINT)}</Alert>
                </Container>
            )}
            <Grid component={Container} container alignItems="center" justifyContent="space-between">
                <SubtaskCloseButton disabled={disabled} status={status} updateStatus={updateStatus} translate={translate} />
                <Actions item>
                    {!disabled && (
                        <MoreActionsMenu
                            actions={[
                                <DangerMenuItem key={translationKeys.VDLANG_REMOVE} onClick={onRemove}>
                                    {translate(translationKeys.VDLANG_ACTIVITIES_REMOVE_ACTIVITY)}
                                </DangerMenuItem>,
                            ]}
                        />
                    )}
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Actions>
            </Grid>
        </>
    );
};

export default SubtaskDetailHeader;
