import { Paper } from "@mui/material";
import { CalendarPickerProps } from "@mui/x-date-pickers/CalendarPicker";
import { TFunction } from "i18next";
import { Moment } from "moment";
import React, { CSSProperties } from "react";
import DatePickerInfoPopover from "./DatePickerInfoPopover";
import DatePickerReminderPopover from "./DatePickerReminderPopover";

interface IDatePickerPopoverProps extends Omit<CalendarPickerProps<Moment>, "translate"> {
    remindAt?: Date | null;
    translate: TFunction;
    onReminderToggled?: () => void;
    onReminderChange: (newValue: Moment | null) => void;
    style?: CSSProperties;
    infoMessage?: string;
}

const DatePickerPopover = React.forwardRef<HTMLDivElement, IDatePickerPopoverProps>(
    ({ remindAt, translate, onReminderToggled, onReminderChange, style, infoMessage, ...pickerProps }, ref) => {
        return (
            // ref and styles needed so that this component can be animated
            <Paper style={style} ref={ref} variant="elevation" elevation={8}>
                {remindAt !== undefined ? (
                    <DatePickerReminderPopover
                        translate={translate}
                        remindAt={remindAt}
                        onReminderChange={onReminderChange}
                        onReminderToggled={onReminderToggled}
                        infoMessage={infoMessage}
                        {...pickerProps}
                    />
                ) : (
                    <DatePickerInfoPopover {...pickerProps} infoMessage={infoMessage} />
                )}
            </Paper>
        );
    },
);

export default DatePickerPopover;
