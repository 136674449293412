import { ListItem, ListItemAvatar, ListItemText, Paper, Skeleton } from "@mui/material";
import React from "react";

const FeedEntrySkeleton = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
    <Paper ref={ref} sx={{ px: 4, pt: 0, pb: 1 }}>
        <ListItem disableGutters>
            <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
        </ListItem>
        <Skeleton variant="rectangular" height={100} />
    </Paper>
));

export default FeedEntrySkeleton;
