import React from "react";
import { GroupBase, MenuListProps } from "react-select";
import { FixedSizeList } from "react-window";
import { Option } from "../types";

const VIRTUAL_ITEM_HEIGHT = 35;

const SelectVirtualMenuList = <
    OptionType extends Option,
    IsMulti extends boolean,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>({
    children,
    ...props
}: MenuListProps<OptionType, IsMulti, GroupType>) => {
    const { options } = props.selectProps;
    const [value] = props.getValue();
    const initialOffset = options.indexOf(value) * VIRTUAL_ITEM_HEIGHT;
    const childCount = React.Children.count(children);
    return (
        <FixedSizeList
            height={props.maxHeight}
            itemCount={childCount}
            itemSize={VIRTUAL_ITEM_HEIGHT}
            initialScrollOffset={initialOffset}
            width="100%"
        >
            {({ index, style }) => <div style={style}>{React.Children.toArray(children)[index]}</div>}
        </FixedSizeList>
    );
};

export default SelectVirtualMenuList;
