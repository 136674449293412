import ArrowRightIcon from "@mui/icons-material/ArrowForward";
import { Stack, Typography, alpha, styled } from "@mui/material";
import { LATEST_PREFIX } from "api-shared";
import { useState } from "react";
import Dotdotdot from "react-dotdotdot";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MarkdownMentions from "../../../components/MarkdownMentions";
import { CarouselItem } from "../../../components/carousel/Carousel";
import InlineCarousel, { ThumbnailCarouselItem } from "../../../components/carousel/InlineCarousel";
import { useMeasureFiles } from "../../../domain/files";
import { useMeasureQuery } from "../../../domain/measure/detail";
import useCurrency from "../../../hooks/useCurrency";
import { trackEvent } from "../../../infrastructure/tracking";
import { baseUrl } from "../../../lib/api";
import { RouteFor } from "../../../lib/routes";
import { translationKeys } from "../../../translations/main-translations";
import EmbeddedMeasureError from "./EmbeddedMeasureError";
import { EmbeddedObjectRoot } from "./EmbeddedObjectRoot";

const measureLinkIconClass = "Vd-EmbeddedMeasure-measureLinkIcon";
const contentStackClass = "Vd-EmbeddedMeasure-contentStack";

const EmbeddedMeasureContainerLink = styled(Link)(({ theme }) => ({
    textDecoration: "none",
    color: theme.palette.text.primary,
}));

const EmbeddedMeasureContainer = styled("div")(({ theme }) => ({
    position: "relative",
    maxHeight: 320,
    ["@media (hover: hover)"]: {
        [`& .${measureLinkIconClass}`]: {
            opacity: 0,
        },
        "&:hover": {
            [`& .${measureLinkIconClass}`]: {
                opacity: 1,
            },
            [`& .${contentStackClass}`]: {
                mask: `linear-gradient(to right, ${theme.palette.background.paper}, ${alpha(theme.palette.background.paper, 0)} 100%)`,
            },
        },
    },
}));

const MeasureTitle = styled(Typography)({
    overflowWrap: "anywhere",
});

const MeasureLinkIcon = styled(ArrowRightIcon)({ position: "absolute", right: 0, bottom: 0 });

const EmbeddedMeasure = ({ id }: { id: number }) => {
    const { t } = useTranslation();
    const { formatCurrency } = useCurrency();

    const measureQuery = useMeasureQuery(id, undefined, true, (error: any) => {
        if ("message" in error && error.message === "VDERROR_NOT_FOUND_MEASURE") {
            trackEvent({ category: "Feed", action: "Feed Not Found Measure" });
        }
    });
    const filesQuery = useMeasureFiles({ measureId: id }, true);

    const description = measureQuery.data?.fields.description?.value ?? "";
    const potential = measureQuery.data?.effects[LATEST_PREFIX]?.effect;
    const processName = t(measureQuery.data?.measureConfig.name ?? "measure");

    const [currentImage, setCurrentImage] = useState<number | null>(null);

    const imageThumbUrls: ThumbnailCarouselItem[] =
        filesQuery?.data
            ?.filter((file) => file.mimetype?.includes("image"))
            .map(({ hash, filename }) => {
                return {
                    displayName: filename,
                    imgLink: `${baseUrl}static/thumb/${hash}`,
                    backgroundImgLink: `${baseUrl}static/thumb/blur/${hash}`,
                };
            }) ?? [];

    const imageUrls: CarouselItem[] =
        filesQuery?.data
            ?.filter((file) => file.mimetype?.includes("image"))
            .map(({ hash, filename }) => ({
                displayName: filename,
                imgLink: `${baseUrl}static/${hash}`,
            })) ?? [];

    return (
        <>
            <InlineCarousel onChange={setCurrentImage} currentIndex={currentImage} itemsThumbs={imageThumbUrls} items={imageUrls} />
            <EmbeddedObjectRoot isLoading={measureQuery.isLoading} isError={measureQuery.isError} errorTemplate={<EmbeddedMeasureError />}>
                {measureQuery.isSuccess && (
                    <EmbeddedMeasureContainerLink
                        to={RouteFor.measure.forId(measureQuery.data.id)}
                        onClick={() => trackEvent({ category: "Feed", action: "Feed Process Opened" })}
                    >
                        <EmbeddedMeasureContainer>
                            <Stack spacing={1} className={contentStackClass}>
                                <Typography variant="body2">
                                    {t(translationKeys.VDLANG_ID)}: {measureQuery.data.clientIid} {processName}
                                    {typeof potential === "number" ? ` • ${String(formatCurrency(potential))}` : ""}
                                </Typography>
                                <Dotdotdot clamp={2} useNativeClamp>
                                    <MeasureTitle variant="subtitle1">{measureQuery.data.title}</MeasureTitle>
                                </Dotdotdot>
                                {typeof description === "string" && description.length > 0 ? (
                                    <Dotdotdot clamp={3} useNativeClamp>
                                        {/* Only add first 4 lines to component as ... will clamp it after 3 and markdown parsing with mentions is expensive */}
                                        <MarkdownMentions markdown={description.split("\n\n").slice(0, 4).join("\n\n")} />
                                    </Dotdotdot>
                                ) : null}
                            </Stack>
                            <MeasureLinkIcon className={measureLinkIconClass} />
                        </EmbeddedMeasureContainer>
                    </EmbeddedMeasureContainerLink>
                )}
            </EmbeddedObjectRoot>
        </>
    );
};

export default EmbeddedMeasure;
