import { MenuItem, MenuItemProps } from "@mui/material";
import { OptionProps } from "react-select";
import useForkedRef from "../../../../hooks/useForkedRef";
import { dropLegacyRef } from "../../../../lib/utils";
import { Option } from "../types";

const SelectOptionContainer = <OptionType extends Option, IsMulti extends boolean>({
    innerRef,
    isFocused,
    isDisabled,
    innerProps,
    children,
    dense,
}: OptionProps<OptionType, IsMulti> & Pick<MenuItemProps, "dense">) => {
    const divRef = useForkedRef(innerRef, dropLegacyRef(innerProps.ref));
    return (
        <MenuItem
            {...innerProps}
            ref={divRef}
            selected={isFocused}
            component="div"
            dense={dense}
            disabled={isDisabled}
            // Using the `sx` prop instead of `styled` due to hard generic type conflicts with `react-select`
            sx={{ whiteSpace: "normal", height: "inherit" }}
        >
            {children}
        </MenuItem>
    );
};

export default SelectOptionContainer;
