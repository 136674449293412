import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FeatureFlags, SubTaskDto, UserDto } from "api-shared";
import { TFunction } from "i18next";
import React from "react";
import { useClientHasFeature } from "../../domain/client";
import { translationKeys } from "../../translations/main-translations";
import ActivityLevelChip from "../../view/measure/levels/ActivityLevelChip";
import DateTz from "../DateTz";
import Tooltip from "../Tooltip";
import UserAvatar from "../user/UserAvatar";
import UserEntryWithPopup from "../user/UserEntryWithPopup";
import SubtaskListItemDueIcon from "./SubtaskListItemDueIcon";
import SubtaskListItemEffortIcon from "./SubtaskListItemEffortIcon";
import SubtaskListItemPriorityIcon from "./SubtaskListItemPriorityIcon";
import SubtaskListItemTitle from "./SubtaskListItemTitle";
import SubtaskStatusIcon from "./SubtaskStatusIcon";

const Chevron = styled(ArrowForwardIosIcon)(({ theme }) => ({
    fontSize: "1rem",
    color: theme.palette.action.disabled,
}));

const CommentIcon = styled(ChatBubbleOutlineOutlinedIcon)(({ theme }) => ({
    color: theme.palette.text.disabled,
    fontSize: theme.typography.pxToRem(13),
    marginLeft: theme.spacing(0.5),
}));

const classes = {
    text: "SubtaskListItemContent-text",
    inset: "SubtaskListItemContent-inset",
};

const Root = styled(Stack)(({ theme }) => ({
    width: "100%",
    [`&.${classes.inset}`]: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(3),
        },
    },
    [`& .${classes.text}`]: {
        // By default the line-height is larger than the font-size, which is good for multi-line text
        // Unfortunately, the text is not centered in its linebox but shown (roughly) at the top of the box
        // Text should be centered, so make the line-height the same as its font-size
        lineHeight: "normal",
    },
}));

const TitleContainer = styled("div")({
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
    display: "inline-flex", // inline to allow ellipsis for child component
});

export interface SubtaskListItemContentProps {
    item: SubTaskDto;
    translate: TFunction;
    users: UserDto[];
    inset?: boolean;
    // optional props for editing
    onAbortTitle?: () => void;
    saveTitle?: (newTitle: string, addNewTask?: boolean) => void;
    title?: string | null;
    showLevelChip: boolean;
}

const SubtaskListItemContent = ({
    item,
    translate,
    users,
    onAbortTitle,
    saveTitle,
    title,
    inset = false,
    showLevelChip,
}: SubtaskListItemContentProps) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const hasEffortEstimationFeature = useClientHasFeature(FeatureFlags.FEATURE_EFFORT_ESTIMATION);

    const user = Array.isArray(users) ? users.find((u) => u.id === item.assignedToId) : null;
    return (
        <Root spacing={isDesktop ? 2 : 1} alignItems="center" direction="row" className={inset ? classes.inset : undefined}>
            <SubtaskStatusIcon status={item.status} />
            <TitleContainer>
                <SubtaskListItemTitle
                    item={item}
                    translate={translate}
                    onAbortTitle={onAbortTitle}
                    saveTitle={saveTitle}
                    title={title}
                    className={classes.text}
                />
            </TitleContainer>
            {item.comments != null && item.comments.length > 0 ? (
                <Tooltip title={translate(translationKeys.VDLANG_ACTIVITIES_NUMBER_OF_COMMENTS_HINT, { count: item.comments.length })}>
                    <Stack flexShrink={0} alignItems="center" direction="row">
                        <Typography color="textSecondary" className={classes.text}>
                            {item.comments.length}
                        </Typography>
                        <CommentIcon />
                    </Stack>
                </Tooltip>
            ) : null}
            {showLevelChip && item.gateTaskId != null ? <ActivityLevelChip subTask={item} /> : null}
            {hasEffortEstimationFeature && <SubtaskListItemEffortIcon subtask={item} />}
            <SubtaskListItemDueIcon subtask={item} translate={translate} />
            <SubtaskListItemPriorityIcon priority={item.priority} translate={translate} />
            {item.duedate != null ? (
                <Typography color="textPrimary" className={classes.text}>
                    <DateTz date={item.duedate} hideTime disableTimezone />
                </Typography>
            ) : null}
            <UserEntryWithPopup triggerElement={<UserAvatar user={user} size={24} />} user={user} />
            <Chevron />
        </Root>
    );
};
export default React.memo(SubtaskListItemContent);
