import { useEffect } from "react";
import { useTranslation } from "react-i18next";

/**
 * @exports
 * @param {string} newTitle The new document title which should be set
 * @param {boolean} [shouldTranslate=true] A flag which indicates if the title should be translated or not
 */
export function useDocumentTitle(newTitle: string, shouldTranslate = true) {
    const { t: translate } = useTranslation();

    useEffect(() => {
        document.title = shouldTranslate ? translate(newTitle) : newTitle;
    }, [newTitle, shouldTranslate, translate]);
}
