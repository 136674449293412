import { Box, Stack, styled } from "@mui/material";
import { TFunction } from "i18next";
import { ComponentType, ReactElement, ReactNode } from "react";
import { SingleValue } from "react-select";
import OverlineLabel from "../../../components/OverlineLabel";
import Tooltip from "../../../components/Tooltip";
import InfoIcon from "../../../components/icons/InfoIcon";
import Select from "../../../components/input/select/Select";
import { ISelectProps, Option, ValueHandler } from "../../../components/input/select/types";
import { translationKeys } from "../../../translations/main-translations";

export enum SubTaskShowOnlyFilter {
    ALL = 0,
    ASSIGNED_TO_ME = 1,
    CREATED_BY_ME = 2,
}

const labels: Record<number, translationKeys> = {
    [SubTaskShowOnlyFilter.ASSIGNED_TO_ME]: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ASSIGNED_TO_ME,
    [SubTaskShowOnlyFilter.CREATED_BY_ME]: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_CREATED_BY_ME,
    [SubTaskShowOnlyFilter.ALL]: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ALL_I_CAN_SEE,
};

export type ActivityShowSelectValue = { assignedToIds?: number[]; createdByIds?: number[] };

interface IActivitiesShowSelectProps<IsMulti extends boolean = false>
    extends Omit<ISelectProps<Option<SubTaskShowOnlyFilter>, IsMulti>, "options" | "value" | "onChange"> {
    component?: ComponentType<ISelectProps<Option<SubTaskShowOnlyFilter>, IsMulti>>;
    currentUserId: number;
    value: ActivityShowSelectValue;
    toolTip?: string;
    onChange: ValueHandler<ActivityShowSelectValue, IsMulti>;
    translate: TFunction;
    labelComponent?: ReactNode | ReactElement;
}

const StyledIcon = styled(InfoIcon)(({ theme }) => ({
    color: theme.palette.action.active,
}));

const ActivitiesShowSelect = ({
    component,
    value,
    translate,
    onChange,
    toolTip,
    labelComponent,
    currentUserId,
    ...selectProps
}: IActivitiesShowSelectProps) => {
    const Component = component ?? Select;
    const options = Object.entries(labels).map(([optionValue, optionLabel]) => ({
        value: +optionValue as SubTaskShowOnlyFilter,
        label: translate(optionLabel),
    }));

    const isAssignedToMe = value.assignedToIds?.includes(currentUserId);
    const isCreatedByMe = value.createdByIds?.includes(currentUserId);
    const selectedOption = isAssignedToMe
        ? options.find((o) => o.value === SubTaskShowOnlyFilter.ASSIGNED_TO_ME)
        : isCreatedByMe
          ? options.find((o) => o.value === SubTaskShowOnlyFilter.CREATED_BY_ME)
          : options.find((o) => o.value === SubTaskShowOnlyFilter.ALL);
    const overLineLabel = <OverlineLabel>{translate(translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_LABEL)}</OverlineLabel>;
    const titleLabel = labelComponent ?? overLineLabel;

    const handleChange = (newValue: SingleValue<Option<SubTaskShowOnlyFilter>>) => {
        if (newValue == null) {
            onChange({ assignedToIds: [], createdByIds: [] });
            return;
        }
        switch (newValue.value) {
            case SubTaskShowOnlyFilter.ALL:
                onChange({ assignedToIds: [], createdByIds: [] });
                break;
            case SubTaskShowOnlyFilter.ASSIGNED_TO_ME:
                onChange({ assignedToIds: [currentUserId], createdByIds: [] });
                break;
            case SubTaskShowOnlyFilter.CREATED_BY_ME:
                onChange({ assignedToIds: [], createdByIds: [currentUserId] });
                break;
        }
    };

    return (
        <>
            {toolTip ? (
                <Stack direction="row" alignItems="center">
                    <>
                        {titleLabel}
                        <Tooltip title={translate(toolTip)}>
                            <Box>
                                <StyledIcon fontSize="inherit" title={translate(toolTip)} />
                            </Box>
                        </Tooltip>
                    </>
                </Stack>
            ) : null}
            <Component
                options={options}
                value={selectedOption}
                onChange={handleChange}
                label={toolTip === undefined ? translate(translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_LABEL) : null}
                {...selectProps}
            />
        </>
    );
};

export default ActivitiesShowSelect;
