import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";
import React, { useCallback, useState } from "react";

const DEFAULT_DELAY = 300;

export enum OverflowOrientation {
    Horizontal,
    Vertical,
}

const hasOverflow = (element: HTMLElement, orientation: OverflowOrientation) =>
    orientation === OverflowOrientation.Horizontal
        ? element.offsetWidth < element.scrollWidth
        : element.offsetHeight < element.scrollHeight;

export interface ITooltipProps extends Omit<TooltipProps, "title"> {
    /**
     * Only render tooltip, if child element overflows its parent (e.g. when it is ellipsized). Only checks for direct child
     *
     * @type {boolean}
     * @memberof ITooltipProps
     */
    onlyOverflowing?: boolean;

    orientation?: OverflowOrientation;

    title: TooltipProps["title"] | null | undefined;
}

const Tooltip = React.forwardRef<unknown, ITooltipProps>(
    ({ onlyOverflowing, children, title, orientation = OverflowOrientation.Horizontal, ...props }, ref) => {
        const [isOverflowing, setIsOverflowing] = useState(false);

        const onMouseEnter: React.MouseEventHandler<HTMLElement> = useCallback(
            (e) => {
                if (typeof children?.props?.onMouseEnter === "function") {
                    children.props.onMouseEnter(e);
                }
                const newOverflow =
                    hasOverflow(e.currentTarget, orientation) || (e.target instanceof HTMLElement && hasOverflow(e.target, orientation));
                if (newOverflow !== isOverflowing) {
                    setIsOverflowing(newOverflow);
                }
            },
            [isOverflowing, children, orientation],
        );

        const child = onlyOverflowing ? React.cloneElement(children, { onMouseEnter }) : children;

        if (title == null) {
            return child;
        }

        if (onlyOverflowing && !isOverflowing) {
            return child;
        }

        return (
            <MuiTooltip
                title={title}
                enterDelay={DEFAULT_DELAY}
                enterTouchDelay={DEFAULT_DELAY}
                enterNextDelay={DEFAULT_DELAY}
                {...props}
                ref={ref}
            >
                {child}
            </MuiTooltip>
        );
    },
);

export default Tooltip;
