import { SvgIconProps } from "@mui/material";
import { effortConverter, SubTaskDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";
import EffortIcon from "../icons/EffortIcon";
import Tooltip from "../Tooltip";

interface ISubtaskListItemEffortIconProps extends SvgIconProps {
    subtask: SubTaskDto;
}

const SubtaskListItemEffortIcon = ({ subtask, ...iconProps }: ISubtaskListItemEffortIconProps) => {
    const { t: translate } = useTranslation();

    const estimationLabel = `${effortConverter(subtask.estimatedEffort)} ${translate(
        translationKeys.VDLANG_ACTIVITY_EFFORT_ESTIMATED_LABEL,
    )}`;
    const trackedLabel = `${effortConverter(subtask.trackedTime)} ${translate(translationKeys.VDLANG_ACTIVITY_TIME_TRACKED_LABEL)}`;

    return subtask.trackedTime ? (
        <Tooltip title={subtask.estimatedEffort ? `${estimationLabel} - ${trackedLabel}` : trackedLabel}>
            <EffortIcon filled={true} fontSize="inherit" {...iconProps} />
        </Tooltip>
    ) : subtask.estimatedEffort ? (
        <Tooltip title={estimationLabel}>
            <EffortIcon filled={false} fontSize="inherit" {...iconProps} />
        </Tooltip>
    ) : null;
};

export default SubtaskListItemEffortIcon;
