import { styled } from "@mui/material";
import DialogButton, { IDialogButtonProps } from "./DialogButton";

interface IDialogBackButtonProps extends Omit<IDialogButtonProps, "name"> {
    name?: string; // optionalize name prop
}

const StyledDialogButton = styled(DialogButton)(({ theme }) => ({
    borderColor: theme.palette.defaultDark.main,
    color: theme.palette.text.primary,
    ":hover": {
        borderColor: theme.palette.defaultDark.main,
    },
}));

const DialogBackButton = (props: IDialogBackButtonProps) => <StyledDialogButton disabled={false} name="Cancel" {...props} />;
export default DialogBackButton;
