import { Stack, Typography } from "@mui/material";

/*
 * This component is used to display an error message in the feed when a measure is not available.
 * The dummy data is used to show the layout of the component. It's blurred and not clickable.
 */
const EmbeddedMeasureError = () => (
    <Stack>
        <Typography variant="body2">Lorem ipsum dolor sit amet, consectetur adipisicing elit!</Typography>
        <Typography variant="subtitle1" sx={{ overflowWrap: "anywhere" }}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit!
        </Typography>
        <Typography variant="body2">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
            aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
            takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
            tempor invidunt ut labore et dolore magna aliquyam erat...
        </Typography>
    </Stack>
);

export default EmbeddedMeasureError;
