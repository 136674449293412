import { AttributeTable, MeasureAttributeDto, zMinMaxShortText } from "api-shared";
import { useSnackbar } from "notistack";
import { Endpoint, useCreateEndpointMutation } from "../../domain/endpoint";
import { FilledField, mergeFieldValues } from "../../lib/fields";
import { translationKeys } from "../../translations/main-translations";
import FieldSelect, { IFieldSelectProps } from "./FieldSelect";

interface IExtendableFieldSelectProps extends IFieldSelectProps {
    field: FilledField & Partial<Pick<MeasureAttributeDto, "id">>;
    isMulti: boolean;
}

const ExtendableFieldSelect = (props: IExtendableFieldSelectProps) => {
    const { field, updateValue, isMulti, disabled, translate } = props;
    const { enqueueSnackbar } = useSnackbar();

    if (field.tableName !== Endpoint.Projects && field.tableName !== Endpoint.CustomValues) {
        throw new Error(`Extendable field has unsupported tableName: ${String(field.tableName)}`);
    }

    const createOptionMutation = useCreateEndpointMutation(field.tableName);

    // projects and custom values can both only have 255 characters, so we can assume the validation here without parsing the mutation error data
    const isValid = (name: string) => zMinMaxShortText.safeParse(name).success;

    const handleCreateOption = (name: string) => {
        if (!isValid(name)) {
            enqueueSnackbar(translate(translationKeys.VDLANG_VALUE_TOO_LONG_SHORT_TEXT), { variant: "error" });
            return;
        }

        const payload =
            field.tableName === AttributeTable.CustomValues && field.id != null ? { measureAttributeId: field.id, value: name } : { name };

        createOptionMutation.mutate(payload, {
            onSuccess: (response) => {
                const merged = mergeFieldValues(field.value, response.id, isMulti);
                const values = merged.split(",").map((value) => Number(value));
                updateValue(values.length === 1 ? values[0] : values);
            },
        });
    };

    const hasValue = field.value !== 0;

    return (
        <FieldSelect
            isClearable={hasValue}
            onCreateOption={handleCreateOption}
            {...props}
            disabled={createOptionMutation.isLoading || disabled}
        />
    );
};

export default ExtendableFieldSelect;
