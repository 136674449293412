import ClearIcon from "@mui/icons-material/ClearRounded";
import SearchIcon from "@mui/icons-material/SearchRounded";
import { ButtonBase, InputAdornment, TextField, TextFieldProps } from "@mui/material";

import { TFunction } from "i18next";
import React from "react";
interface ISearchInputProps extends Omit<TextFieldProps, "onChange" | "translate"> {
    onChange: (newSearchKey: string) => void;
    onReset?: () => void;
    translate: TFunction;
    searchKey: string;
    endAdornment?: React.ReactNode;
    clearIcon?: React.ReactNode;
    hideSearchIcon?: boolean;
}

function getAdornment(searchKey: string, hideSearchIcon: boolean, clearIcon: React.ReactNode | undefined, resetSearchKey: () => void) {
    if (searchKey != "") {
        return (
            <ButtonBase onClick={resetSearchKey} onMouseDown={resetSearchKey}>
                {clearIcon ?? <ClearIcon fontSize="small" />}
            </ButtonBase>
        );
    }
    if (!hideSearchIcon) {
        return <SearchIcon fontSize="small" />;
    }
    return null;
}

const SearchInput = React.forwardRef<HTMLDivElement, ISearchInputProps>(
    ({ translate, searchKey, onChange, onReset, endAdornment, InputProps, clearIcon, hideSearchIcon = false, ...textFieldProps }, ref) => {
        const updateSearchKey = (e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value);
        const resetSearchKey = () => {
            onChange?.("");
            onReset?.();
        };

        const adornment = getAdornment(searchKey, hideSearchIcon, clearIcon, resetSearchKey);

        return (
            <TextField
                ref={ref}
                name="search"
                placeholder={`${translate("Search")}…`}
                value={searchKey}
                onChange={updateSearchKey}
                fullWidth
                InputProps={{
                    ...InputProps,
                    endAdornment:
                        endAdornment ?? (adornment != null ? <InputAdornment position="end">{adornment}</InputAdornment> : undefined),
                }}
                {...textFieldProps}
            />
        );
    },
);

export default SearchInput;
