import { useTheme } from "@mui/material";
import { StylesConfig } from "react-select";
import { ISelectProps, Option } from "../types";

export const useSelectStyles = <OptionType extends Option, IsMulti extends boolean>({
    fullWidth,
    ...other
}: ISelectProps<OptionType, IsMulti>): StylesConfig<OptionType, IsMulti> => {
    const theme = useTheme();

    return {
        container: (base) => ({
            ...base,
            ...(fullWidth ? { maxWidth: "100%" } : {}),
        }),
        menuList: (base) => ({
            ...base,
        }),
        input: (base) => ({
            ...base,
            // Remove any padding and margin, so the input element does not increase the height of the whole component
            padding: 0,
            margin: 0,
            color: theme.palette.text.secondary,
            "& input": {
                font: "inherit",
            },
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: theme.zIndex.modal + 1,
        }),
        dropdownIndicator: (base, { isDisabled }) => ({
            ...base,
            cursor: !isDisabled ? "pointer" : undefined,
            padding: 0,
        }),
        clearIndicator: (base, { selectProps: { isDisabled } }) => ({
            ...base,
            cursor: !isDisabled ? "pointer" : undefined,
            padding: theme.spacing(0.5),
        }),
        indicatorsContainer: (base) => ({
            ...base,
            // Rely on the global paddings for inputs to gain the standard input sizes
            // Those paddings assume a child height of 20px which is not true for the IndicatorsContainer that contains icons that are 24px high
            // So we need to compensate for that with negative margins
            // This does not cause issues as long as the global input paddings are always larger than theme.spacing(0.5)
            marginTop: theme.spacing(-0.5),
            marginBottom: theme.spacing(-0.5),
        }),
    };
};
