import { Alert, Button, Card, CardActions, CardContent, CardHeader, Grid } from "@mui/material";
import { IdentityProviderType } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useLoginProvider } from "../../domain/login-provider";
import { useCurrentUser } from "../../domain/users";
import { translationKeys } from "../../translations/main-translations";
import TwoFactorAuthenticationSetupStepper from "./TwoFactorAuthenticationSetupStepper";

const TwoFactorAuthenticationProfile = () => {
    const currentUser = useCurrentUser();
    const loginProviderTypeQuery = useLoginProvider({ email: currentUser?.email ?? "", enabled: currentUser?.email != null });
    const [showTfaStepper, setShowTfaStepper] = useState(false);

    const { t: translate } = useTranslation();

    if (currentUser === null) {
        return null;
    }

    if (!loginProviderTypeQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const isPasswordProvider = loginProviderTypeQuery.data.type === IdentityProviderType.Password;

    let content = null;
    if (!isPasswordProvider) {
        content = <Alert severity="info">{translate(translationKeys.VDLANG_USER_PROFILE_PASSWORD_SSO_INFO)}</Alert>;
    } else if (currentUser.twoFactorAuthenticationValidated) {
        content = <Alert severity="info">{translate(translationKeys.VDLANG_TFA_SETUP_ALREADY_DONE)}</Alert>;
    } else if (showTfaStepper) {
        content = <TwoFactorAuthenticationSetupStepper email={currentUser.email ?? ""} returnToLogin={false} />;
    }

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={7}>
                <Card>
                    <CardHeader
                        title={translate(translationKeys.VDLANG_TFA)}
                        subheader={translate(translationKeys.VDLANG_TFA_SETUP_INFO)}
                        subheaderTypographyProps={{ variant: "body2" }}
                    />
                    {content != null ? <CardContent>{content}</CardContent> : null}
                    {isPasswordProvider && !currentUser.twoFactorAuthenticationValidated && !showTfaStepper ? (
                        <CardActions>
                            <Button onClick={() => setShowTfaStepper(true)} color="primary" variant="contained">
                                {translate(translationKeys.VDLANG_TFA_ACTIVATE)}
                            </Button>
                        </CardActions>
                    ) : null}
                </Card>
            </Grid>
        </Grid>
    );
};

export default TwoFactorAuthenticationProfile;
