export const defaultFilename = "valuedesk_export";

function toBlob(data: string | Blob) {
    if (typeof data !== "string") {
        return data;
    }
    return new Blob([data], { type: "text/csv;charset=utf-8;" });
}

function generateFilename(customFilename?: string) {
    let filename = `${defaultFilename}.csv`;
    if (customFilename) {
        if (customFilename.endsWith(".csv")) {
            filename = customFilename;
        } else {
            filename = `${customFilename}.csv`;
        }
    }
    return filename;
}

export function download(data: string | Blob, customFilename?: string) {
    const blob = toBlob(data);
    const filename = generateFilename(customFilename);
    const url = URL.createObjectURL(blob);
    const anchorElement = document.createElement("a");

    anchorElement.setAttribute("style", "display: none;");
    anchorElement.setAttribute("target", "_blank");
    anchorElement.setAttribute("href", url);
    anchorElement.setAttribute("download", filename);

    anchorElement.click();
    anchorElement.remove();
    URL.revokeObjectURL(url);
}
