import { Button, Link, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import QRCode from "qrcode";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Form from "../../components/Form";
import CopieableTextField from "../../components/input/CopieableTextField";
import PasswordInput from "../../components/input/PasswordInput";
import { useTwoFactorAuthenticationTotpSetup, useTwoFactorAuthenticationVerifySetup } from "../../domain/login-provider";
import { RouteFor } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";

enum StepperSteps {
    ENTER_PASSWORD,
    SCAN_QR,
    VALIDATE_TOKEN,
}

interface ITwoFactorAuthenticationSetupStepperProps {
    email: string;
    defaultPassword?: string;
    returnToLogin: boolean;
}

const StepActions = ({ children }: React.PropsWithChildren<unknown>) => (
    <Stack spacing={1} direction="row" mb={2} mt={1}>
        {children}
    </Stack>
);

const TwoFactorAuthenticationSetupStepper = ({ ...props }: ITwoFactorAuthenticationSetupStepperProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const { t: translate } = useTranslation();
    const navigate = useNavigate();

    const setupTotpMutation = useTwoFactorAuthenticationTotpSetup();
    const verifyTotpMutation = useTwoFactorAuthenticationVerifySetup();

    const [password, setPassword] = useState(props.defaultPassword ?? "");
    const [token, setToken] = useState("");
    const [wizardState, setWizardState] = useState(StepperSteps.ENTER_PASSWORD);

    useEffect(() => {
        const otpauthUrl = setupTotpMutation.data?.otpauthUrl;
        const canvas = canvasRef.current;

        if (otpauthUrl === undefined || canvas === null || wizardState !== StepperSteps.SCAN_QR) {
            return;
        }

        QRCode.toCanvas(canvas, otpauthUrl, { width: 286 });
    }, [canvasRef, setupTotpMutation.data, wizardState]);

    const reset = useCallback(() => {
        setPassword("");
        setToken("");
        setWizardState(StepperSteps.ENTER_PASSWORD);
        setupTotpMutation.reset();
        verifyTotpMutation.reset();
    }, [setupTotpMutation, verifyTotpMutation]);

    const totpVerified = () => {
        reset();
        if (props.returnToLogin) {
            navigate(RouteFor.user.login);
        }
    };

    function backToQrCode() {
        setWizardState(StepperSteps.SCAN_QR);
    }

    function onSubmit() {
        switch (wizardState) {
            case StepperSteps.ENTER_PASSWORD:
                setupTotpMutation.mutate({ email: props.email, password }, { onSuccess: () => setWizardState(StepperSteps.SCAN_QR) });
                break;
            case StepperSteps.SCAN_QR:
                setWizardState(StepperSteps.VALIDATE_TOKEN);
                break;
            case StepperSteps.VALIDATE_TOKEN:
                verifyTotpMutation.mutate(
                    { email: props.email, password, twoFactorAuthenticationToken: token },
                    { onSuccess: totpVerified },
                );
                break;
        }
    }

    function disablePrimaryForStep() {
        switch (wizardState) {
            case StepperSteps.ENTER_PASSWORD:
                return password.length < 1;
            case StepperSteps.VALIDATE_TOKEN:
                return token.length < 6;
        }
        return false;
    }

    function extractSecret(text: string) {
        if (text.length < 1) {
            return "";
        }
        const url = new URL(text);
        return url.searchParams.get("secret");
    }

    return (
        <Form onSubmit={onSubmit}>
            <Stepper activeStep={wizardState} orientation="vertical">
                <Step>
                    <StepLabel>{translate(translationKeys.VDLANG_TFA_SETUP_TITLE_ENTER_PASSWORD)}</StepLabel>
                    <StepContent>
                        <Typography>{translate(translationKeys.VDLANG_TFA_SETUP_HINT_ENTER_PASSWORD)}</Typography>
                        <PasswordInput
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            label={translate("Password")}
                            margin="normal"
                            required
                        />
                        <StepActions>
                            <Button variant="contained" disabled={disablePrimaryForStep()} onClick={onSubmit}>
                                {translate(translationKeys.VDLANG_LOGIN_NEXT)}
                            </Button>
                        </StepActions>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>{translate(translationKeys.VDLANG_TFA_SETUP_TITLE_SCAN_QR)}</StepLabel>
                    <StepContent>
                        <Typography>
                            <ol>
                                <li>
                                    {translate(`${translationKeys.VDLANG_TFA_SETUP_HINT_SCAN_QR}.1`)}
                                    <Link href={translate(translationKeys.VDLANG_TFA_SETUP_AUTHENTICATOR_1_LINK)} target="_blank">
                                        {translate(translationKeys.VDLANG_TFA_SETUP_AUTHENTICATOR_1_NAME)}
                                    </Link>
                                    {translate(`${translationKeys.VDLANG_TFA_SETUP_HINT_SCAN_QR}.2`)}
                                    <Link href={translate(translationKeys.VDLANG_TFA_SETUP_AUTHENTICATOR_2_LINK)} target="_blank">
                                        {translate(translationKeys.VDLANG_TFA_SETUP_AUTHENTICATOR_2_NAME)}
                                    </Link>
                                    .
                                </li>
                                <li>{translate(`${translationKeys.VDLANG_TFA_SETUP_HINT_SCAN_QR}.3`)}</li>
                            </ol>
                        </Typography>
                        <Stack alignItems="center">
                            <canvas ref={canvasRef}></canvas>
                            <CopieableTextField label="" value={extractSecret(setupTotpMutation.data?.otpauthUrl ?? "")} fullWidth />
                        </Stack>
                        <StepActions>
                            <Button variant="contained" disabled={disablePrimaryForStep()} onClick={onSubmit}>
                                {translate(translationKeys.VDLANG_LOGIN_NEXT)}
                            </Button>
                        </StepActions>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>{translate(translationKeys.VDLANG_TFA_SETUP_TITLE_VALIDATE_TOKEN)}</StepLabel>
                    <StepContent>
                        <TextField
                            margin="none"
                            value={token}
                            onChange={(event) => setToken(event.target.value)}
                            name="token"
                            label={translate(translationKeys.VDLANG_TFA_SETUP_ENTER_CODE)}
                            fullWidth
                        />
                        <StepActions>
                            <Button variant="contained" disabled={disablePrimaryForStep()} onClick={onSubmit}>
                                {translate(translationKeys.VDLANG_TFA_ACTIVATE)}
                            </Button>
                            <Button onClick={backToQrCode}>{translate(translationKeys.VDLANG_BACK)}</Button>
                        </StepActions>
                    </StepContent>
                </Step>
            </Stepper>
        </Form>
    );
};

export default TwoFactorAuthenticationSetupStepper;
