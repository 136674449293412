import { Icon, styled } from "@mui/material";

const EmojiIcon = styled(Icon)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "content-box",
    padding: theme.spacing(0.25),
    fontSize: theme.typography.body1.fontSize,
}));

export default EmojiIcon;
