import { Grid, Link, styled, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { Trans } from "react-i18next";
import { ExternalRoutes } from "../../lib/routes";
import alert from "../../static/images/alert.svg";
import logo from "../../static/images/logo_blau.svg";
import { translationKeys } from "../../translations/main-translations";

const Root = styled(Grid)(({ theme }) => ({
    height: "100vh",
    backgroundColor: theme.palette.background.paper,
}));

const Container = styled(Grid)(({ theme }) => ({
    maxWidth: theme.breakpoints.values.sm,
}));

const Image = styled("img", { shouldForwardProp: (name) => name !== "width" })<{ width: number }>(({ width }) => ({ width }));

export interface TokenExpiredProps {
    dense?: boolean;
    translate: TFunction;
}

function TokenExpired({ dense, translate }: Readonly<TokenExpiredProps>) {
    const component = (
        <Container container direction="column" justifyContent="center" alignItems="center">
            {!dense ? <Image width={256} sx={{ mb: 6 }} src={logo} alt="Valuedesk" /> : null}
            <Image width={128} sx={{ my: 2 }} src={alert} alt="Alert" />
            <Typography variant="h4" my={2}>
                {translate(translationKeys.VDLANG_TOKEN_EXPIRED_TITLE)}
            </Typography>
            <Typography variant="subtitle1" align="center" my={2}>
                <Trans<translationKeys> t={translate} i18nKey={translationKeys.VDLANG_TOKEN_EXPIRED_CONTENT} />
            </Typography>
            <Typography variant="caption" align="center">
                {translate(translationKeys.VDLANG_TOKEN_EXPIRED_HINT)}
                <Link href={ExternalRoutes.support}>support@valuedesk.de</Link>
            </Typography>
        </Container>
    );

    if (dense) {
        return component;
    }

    return (
        <Root container justifyContent="center">
            {component}
        </Root>
    );
}

export default TokenExpired;
