import { Button, Grid, styled } from "@mui/material";
import { AttributeTitle } from "api-shared";
import { TFunction } from "i18next";
import TextBoxMultipleOutline from "mdi-material-ui/TextBoxMultipleOutline";
import { ReactNode } from "react";
import { translationKeys } from "../translations/main-translations";
import Tooltip from "./Tooltip";

const SideButton = styled(Button)(({ theme }) => ({
    // Align button height with the select
    padding: theme.spacing(0.625),
    minWidth: 0,
}));

const labels = {
    [AttributeTitle.CostLeverId]: <TextBoxMultipleOutline />,
};
const tooltips = {
    [AttributeTitle.CostLeverId]: translationKeys.VDLANG_METHOD_GUIDE_MAIN_TITLE,
};

interface IHelpButtonProps {
    helpKey: keyof typeof labels;
    translate: TFunction;
    children?: ReactNode;
    onOpen: (key: string) => void;
    disabled?: boolean;
}

const HelpButton = ({ disabled, helpKey, translate, children, onOpen }: IHelpButtonProps) => {
    let key = labels[helpKey];
    if (typeof key === "string") {
        key = <>{translate(key)}</>;
    }
    const label = key ?? helpKey;
    const title = tooltips[helpKey] != null ? translate(tooltips[helpKey]) : "";

    return (
        <Grid container wrap="nowrap" spacing={1} alignItems="stretch">
            <Grid item zeroMinWidth flexGrow={1} maxWidth="100%">
                {children}
            </Grid>
            <Grid item>
                <Tooltip title={title}>
                    <span>
                        <SideButton onClick={() => onOpen(helpKey)} disabled={disabled}>
                            {label}
                        </SideButton>
                    </span>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default HelpButton;
