import { IdeaDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useConvertIdeaMutation } from "../../../domain/ideas";
import useCurrency from "../../../hooks/useCurrency";
import { RouteFor } from "../../../lib/routes";
import { translationKeys } from "../../../translations/main-translations";
import { useIdeaEstimateConversion } from "./useIdeaEstimates";

export const CONVERTED_IDEA_PATH = "converted-idea";

export const useIdeaConversion = () => {
    const { currencyIsoCode: currencyCode } = useCurrency();
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const getIdeaConversionValues = useIdeaEstimateConversion();
    const convertIdeaMutation = useConvertIdeaMutation((response) => {
        navigate(RouteFor.measure.forId(response.measureId));
    });

    return (idea: Omit<IdeaDto, "createdAt" | "ownerId">, userId: number, measureConfigId: number) => {
        const { potentialValue, timeValue, effortValue } = getIdeaConversionValues({
            potentialEstimateValue: idea.potentialEstimate,
            timeEstimateValue: idea.timeEstimate,
            effortEstimateValue: idea.effortEstimate,
        });

        let description = `${translate(translationKeys.VDLANG_IDEAS_DESCRIPTION_PREFIX)}:\n\n`;
        description += `${idea.description}\n\n`;

        if (idea.potentialEstimate != null) {
            description += `${translate(translationKeys.VDLANG_IDEAS_IDEA_POTENTIAL_ESTIMATE_LABEL, {
                currencyCode,
            })}: ${potentialValue}\n\n`;
        }
        if (idea.timeEstimate != null) {
            description += `${translate(translationKeys.VDLANG_IDEAS_IDEA_TIME_ESTIMATE_LABEL)}: ${timeValue}\n\n`;
        }
        if (idea.effortEstimate != null) {
            description += `${translate(translationKeys.VDLANG_IDEAS_EFFORT_ESTIMATE_LABEL)}: ${effortValue}\n\n`;
        }

        return convertIdeaMutation.mutate({
            ideaId: idea.id,
            assignedToUserId: userId,
            measureConfigId,
            description,
        });
    };
};
