import { TextField, Typography, inputClasses, styled } from "@mui/material";
import { SubTaskDto, zShortText } from "api-shared";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { isEnterEvent, isEscapeEvent } from "../../lib/keybindings";
import { translationKeys } from "../../translations/main-translations";
import Tooltip from "../Tooltip";

const SuperSmallTextField = styled(TextField)(({ theme }) => ({
    [`& .${inputClasses.input}`]: {
        // Decrease padding so that SubtaskListItem height is not increased
        padding: theme.spacing(0.25, 0),
    },
}));

interface ISubtaskListItemTitleProps {
    className?: string;
    item: SubTaskDto;
    translate: TFunction;
    onAbortTitle?: () => void;
    saveTitle?: (newTitle: string, addNewTask?: boolean) => void;
    title?: string | null;
}

const SubtaskListItemTitle = ({ className, item, translate, onAbortTitle, saveTitle, title }: ISubtaskListItemTitleProps) => {
    const [saveOnBlur, setSaveOnBlur] = useState(true);
    const [isTitleTooLong, setIsTitleTooLong] = useState(false);

    const resolvedTitle = title ?? item.title;

    // Return early to gain some performance
    if (item.id > 0) {
        return (
            <Tooltip onlyOverflowing title={resolvedTitle}>
                {/* span (inline) to only occupy space as needed, so tooltip positioning will follow the text */}
                <Typography className={className} noWrap color={item.title ? "textPrimary" : "textSecondary"} component="span">
                    {resolvedTitle}
                </Typography>
            </Tooltip>
        );
    }

    const keyHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!(event.target instanceof HTMLInputElement)) {
            // Keyboard event may theoretically be dispatched on a window element
            // Ignore that case so it is safe to access event.target.value
            return;
        }
        if (isEnterEvent(event) && saveTitle != null) {
            // we first need to disable blur, otherwise a second save will be triggered when focus changes
            setSaveOnBlur(false);
            saveTitle(event.target.value, true);
        } else if (isEscapeEvent(event)) {
            typeof onAbortTitle === "function" && onAbortTitle();
        }
    };

    const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        if (saveOnBlur && saveTitle != null) {
            saveTitle(e.target.value);
        }
    };

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsTitleTooLong(!zShortText.safeParse(e.target.value).success);
    };

    return (
        // explicitly uncontrolled component
        <SuperSmallTextField
            size="small"
            autoFocus
            fullWidth
            variant="standard" // Editing a title should still use an underline innput
            placeholder={translate(translationKeys.VDLANG_ACTIVITIES_NEW_ACTIVITY_TEXTFIELD_PLACEHOLDER)}
            onBlur={blurHandler}
            onKeyDown={keyHandler}
            onChange={changeHandler}
            error={isTitleTooLong}
            helperText={isTitleTooLong ? translate(translationKeys.VDLANG_ACTIVITIES_TITLE_LENGTH_ERROR) : null}
        />
    );
};

export default SubtaskListItemTitle;
