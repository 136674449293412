import VisibilityOff from "@mui/icons-material/VisibilityOffRounded";
import Visibility from "@mui/icons-material/VisibilityRounded";
import { IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ValidatedInput, { IValidatedInputProps } from "./ValidatedInput";
import { translationKeys } from "../../translations/main-translations";

const PasswordInput = React.forwardRef<HTMLDivElement, IValidatedInputProps>((validatedInputProps, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const { t: translate } = useTranslation();

    const togglePasswordVisibility = () => setShowPassword((old) => !old);

    return (
        <ValidatedInput
            name="password"
            type={showPassword ? "text" : "password"}
            label={translate("Password")}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={translate(translationKeys.VDLANG_PASSWORD_TOGGLE_VISIBILITY)}
                            onClick={togglePasswordVisibility}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...validatedInputProps}
            ref={ref}
        />
    );
});
export default PasswordInput;
