import { HistoryEventType } from "api-shared";
import { translationKeys } from "./main-translations";

export const historyTranslations = {
    process_history: {
        [translationKeys.VDLANG_PROCESS_HISTORY_FILE_INSERT]: [
            'The document "{{newValue}}" was uploaded',
            'Das Dokument "{{newValue}}" wurde hinzugefügt',
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_FILE_DELETE]: [
            'The document "{{previousValue}}" was deleted',
            'Das Dokument "{{previousValue}}" wurde gelöscht',
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_INSERT]: [
            '{{processName}} "{{newValue}}" created',
            'Die {{processName}} "{{newValue}}" wurde erzeugt',
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_COPY]: [
            '{{processName}} "{{newValue}}" was copied from {{processName}} {{previousValue}}',
            'Die {{processName}} "{{newValue}}" wurde aus der {{processName}} {{previousValue}} kopiert',
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_TITLE]: [
            '{{processName}} title was changed from "{{previousValue}}" to "{{newValue}}"',
            'Der Titel der {{processName}} wurde von "{{previousValue}}" zu "{{newValue}}" geändert',
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_0_1]: [
            "{{processName}} discarded",
            "Die {{processName}} wurde verworfen",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_ON_BEHALF_0_1]: [
            "{{processName}} discarded on behalf of {{userName}}",
            "Die {{processName}} wurde im Namen von {{userName}} verworfen",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_1_2]: [
            "{{processName}} completed",
            "Die {{processName}} wurde abgeschlossen",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_2_0]: [
            "{{processName}} discarded",
            "Die {{processName}} wurde verworfen",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_TO_1]: [
            "{{processName}} reopened",
            "Die {{processName}} wurde wieder geöffnet",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_1_2]: [
            "Visibility changed to public",
            "Die Sichtbarkeit wurde auf öffentlich gestellt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_2_1]: [
            "Visibility changed to private",
            "Die Sichtbarkeit wurde auf privat gestellt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_0_1]: [
            "{{processName}} was restored with private visibility",
            "{{processName}} wurde mit privater Sichtbarkeit wiederhergestellt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_0_2]: [
            "{{processName}} was restored with public visibility",
            "{{processName}} wurde mit öffentlicher Sichtbarkeit wiederhergestellt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_TO_0]: [
            "{{processName}} has been archived",
            "{{processName}} wurde archiviert",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_ASSIGNED_TO]: [
            "{{newValue}} is now responsible for the {{processName}}",
            "{{newValue}} ist nun für die {{processName}} verantwortlich",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_ASSIGNED_TO_TO_EMPTY]: [
            "{{previousValue}} is no longer responsible for the {{processName}}",
            "{{previousValue}} ist nicht mehr verantwortlich für die {{processName}}",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_CURRENCY]: [
            "The process currency was changed from {{previousValue}} to {{newValue}}",
            "Die Prozesswährung wurde von {{previousValue}} auf {{newValue}} geändert",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VALUESTREAM]: [
            "The Valuestream was changed from {{previousValue}} to {{newValue}}",
            "Der Valuestream wurde von {{previousValue}} auf {{newValue}} geändert",
        ],

        [translationKeys.VDLANG_PROCESS_HISTORY_MEASURE_VALUE_INSERT]: [
            'The field "{{attributeName}}" was filled with {{newValue}}',
            'Das Feld "{{attributeName}}" wurde mit {{newValue}} ausgefüllt',
        ],

        [translationKeys.VDLANG_PROCESS_HISTORY_MEASURE_VALUE_UPDATE_TO_NULL]: [
            'The value {{previousValue}} was deleted from "{{attributeName}}"',
            'Der Wert {{previousValue}} wurde aus dem Feld "{{attributeName}}" gelöscht',
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_MEASURE_VALUE_UPDATE]: [
            "The value {{previousValue}} in {{attributeName}} was changed to {{newValue}}",
            "Der Wert {{previousValue}} in {{attributeName}} wurde zu {{newValue}} geändert",
        ],

        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1000_1002]: [
            "Level {{gateName}} was completed",
            "Härtegrad {{gateName}} wurde abgeschlossen",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1000_1002_DECISION]: [
            'Level {{gateName}} was completed with result "{{result}}"',
            'Härtegrad {{gateName}} wurde dem Ergebnis "{{result}}" abgeschlossen',
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1002_1000]: [
            "Level {{gateName}} was reopened",
            "Härtegrad {{gateName}} wurde wieder geöffnet",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_ASSIGNED_TO_TO_EMPTY]: [
            "{{userName}} was removed from Level {{gateName}}",
            "{{userName}} wurde vom Härtegrad {{gateName}} entfernt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_ASSIGNED_TO]: [
            "{{userName}} was assigned to Level {{gateName}}",
            "{{userName}} wurde zum Härtegrad {{gateName}} als verantwortlich zugeordnet",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_DATE_FROM_EMPTY]: [
            "The start date for Level {{gateName}} was set to {{newValue}}",
            "Das Startdatum des Härtegrads {{gateName}} wurde auf {{newValue}} gesetzt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_DATE]: [
            "The start date of Level {{gateName}} was changed from {{previousValue}} to {{newValue}}",
            "Das Startdatum von Härtegrad {{gateName}} wurde von {{previousValue}} zu {{newValue}} geändert",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_DUEDATE_FROM_EMPTY]: [
            "The duedate for Level {{gateName}} was set to {{newValue}}",
            "Die Fälligkeit des Härtegrads {{gateName}} wurde auf {{newValue}} gesetzt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_DUEDATE]: [
            "The duedate of Level {{gateName}} was changed from {{previousValue}} to {{newValue}}",
            "Die Fälligkeit von Härtegrad {{gateName}} wurde von {{previousValue}} zu {{newValue}} geändert",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_REMIND_AT_TO_EMPTY]: [
            "Deactivated the reminder for the assigned person for the start date of Level {{gateName}}",
            "Erinnerung für die verantwortliche Person für das Startdatum von Härtegrad {{gateName}} deaktiviert",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_REMIND_AT]: [
            "Set the reminder for the assigned person for the start date of Level {{gateName}} to {{date}} at {{time}}",
            "Erinnerung für die verantwortliche Person für das Startdatum von Härtegrad {{gateName}} auf {{date}} um {{time}} gestellt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_REMIND_AT_TO_EMPTY]: [
            "Deactivated the reminder for the assigned person for the duedate of Level {{gateName}}",
            "Erinnerung für die verantwortliche Person für die Fälligkeit von Härtegrad {{gateName}} deaktiviert",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_REMIND_AT]: [
            "Set the reminder for the assigned person for the duedate of Level {{gateName}} to {{date}} at {{time}}",
            "Erinnerung für die verantwortliche Person für die Fälligkeit von Härtegrad {{gateName}} auf {{date}} um {{time}} gestellt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_DELETE_DUEDATE]: [
            "The duedate of Level {{gateName}} was removed",
            "Die Fälligkeit von Härtegrad {{gateName}} wurde entfernt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_GATE_TASK_DELETE_START_DATE]: [
            "The start date of Level {{gateName}} was removed",
            "Das Startdatum von Härtegrad {{gateName}} wurde entfernt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_SUBTASK_INSERT]: ["An activity was created", "Eine Aktivität wurde angelegt"],
        [translationKeys.VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_1_2]: [
            "An activity was completed",
            "Eine Aktivität wurde abgeschlossen",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_TO_0]: ["An activity was removed", "Eine Aktivität wurde entfernt"],
        [translationKeys.VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_2_1]: [
            "A completed activity was reopened",
            "Eine abgeschlossene Aktivität wurde wieder geöffnet",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_EFFECT_CATEGORY_INSERT]: [
            "A category with savings or additional costs was added to {{processName}}",
            "Eine Kategorie mit Einsparungen oder Mehrkosten wurde zu der {{processName}} hinzugefügt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_EFFECT_CATEGORY_DELETE]: [
            "A category with savings or additional costs has been removed from the {{processName}}",
            "Eine Kategorie mit Einsparungen oder Mehrkosten wurde aus der {{processName}} entfernt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_DECISION_UPDATE_REQUESTED_DECIDER]: [
            "A decision was requested from {{userName}}",
            "Eine Entscheidung wurde von {{userName}} angefragt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_DECISION_USER_DECIDER]: [
            "{{userName}} was set as decider",
            "{{userName}} wurde als Entscheider ausgewählt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_DECISION_USER_DECIDER_REMOVE]: [
            "{{userName}} was removed as decider",
            "{{userName}} wurde als Entscheider entfernt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_ACL_USER_INSERT_READ]: [
            "{{userName}} was assigned to view the {{processName}}",
            "{{userName}} wurde als Beobachter hinzugefügt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_ACL_USER_INSERT_UPDATE]: [
            "{{userName}} was assigned to edit the {{processName}}",
            "{{userName}} wurde als Bearbeiter hinzugefügt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_ACL_USER_DELETE_READ]: [
            "{{userName}} was removed as viewer",
            "{{userName}} wurde als Beobachter entfernt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_ACL_USER_DELETE_UPDATE]: [
            "{{userName}} was removed as editor",
            "{{userName}} wurde als Bearbeiter entfernt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_ACL_GROUP_INSERT_READ]: [
            "The group {{groupName}} was assigned to view the {{processName}}",
            "Die Gruppe {{groupName}} wurde als Beobachter hinzugefügt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_ACL_GROUP_INSERT_UPDATE]: [
            "The group {{groupName}} was assigned to edit the {{processName}}",
            "Die Gruppe {{groupName}} wurde als Bearbeiter hinzugefügt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_ACL_GROUP_DELETE_READ]: [
            "The group {{groupName}} was removed as viewer",
            "Die Gruppe {{groupName}} wurde als Beobachter entfernt",
        ],
        [translationKeys.VDLANG_PROCESS_HISTORY_ACL_GROUP_DELETE_UPDATE]: [
            "The group {{groupName}} was removed as editor",
            "Die Gruppe {{groupName}} wurde als Bearbeiter entfernt",
        ],
    },
    subtask_history: {
        status: {
            [HistoryEventType.UPDATE]: ["has marked the activity as {{newValue}}", "hat die Aktivität als {{newValue}} markiert"],
        },
        duedate: {
            [HistoryEventType.UPDATE]: ["has changed the due date to {{newValue}}", "hat die Fälligkeit zu {{newValue}} geändert"],
            [HistoryEventType.CLEAR]: ["has removed the due date", "hat die Fälligkeit entfernt"],
            [HistoryEventType.FILL]: ["has filled the due date with {{newValue}}", "hat die Fälligkeit mit {{newValue}} ausgefüllt"],
        },
        title: {
            [HistoryEventType.INSERT]: ["has created this activity", "hat diese Aktivität erstellt"],
            [HistoryEventType.UPDATE]: [
                'has changed the title from "{{previousValue}}" to "{{newValue}}"',
                'hat den Titel von "{{previousValue}}" auf "{{newValue}}" geändert',
            ],
        },
        description: {
            clear_override: ["has deleted the description.", "hat die Beschreibung gelöscht"],
            [HistoryEventType.UPDATE]: ["has changed the description", "hat die Beschreibung geändert"],
            [HistoryEventType.FILL]: ["has filled the description", "hat die Beschreibung ausgefüllt"],
        },
        assigned_to: {
            [HistoryEventType.UPDATE]: ["has assigned {{newValue}}", "hat {{newValue}} zugeordnet"],
            [HistoryEventType.FILL]: ["has assigned {{newValue}}", "hat {{newValue}} zugeordnet"],
            [HistoryEventType.CLEAR]: ["has unassigned {{previousValue}}", "hat {{previousValue}} entfernt"],
        },
        remind_at: {
            [HistoryEventType.UPDATE]: [
                "has set the reminder for the assigned person to {{date}} at {{time}}",
                "hat die Erinnerung für die verantwortliche Person auf {{date}} um {{time}} gestellt",
            ],
            [HistoryEventType.FILL]: [
                "has set the reminder for the assigned person to {{date}} at {{time}}",
                "hat die Erinnerung für die verantwortliche Person auf {{date}} um {{time}} gestellt",
            ],
            [HistoryEventType.CLEAR]: [
                "has deactivated the reminder for the assigned person",
                "hat die Erinnerung für die verantwortliche Person deaktiviert",
            ],
        },
        tracked_time: {
            [HistoryEventType.UPDATE]: ["has changed the time needed to {{newValue}}", "hat die benötigte Zeit auf {{newValue}} geändert"],
            [HistoryEventType.CLEAR]: ["has removed the time needed", "hat die benötigte Zeit entfernt"],
            [HistoryEventType.FILL]: ["has filled the time needed with {{newValue}}", "hat die benötigte Zeit mit {{newValue}} ausgefüllt"],
        },
        estimated_effort: {
            [HistoryEventType.UPDATE]: [
                "has changed the estimated effort to {{newValue}}",
                "hat den geschätzten Aufwand auf {{newValue}} geändert",
            ],
            [HistoryEventType.CLEAR]: ["has removed the estimated effort", "hat den geschätzten Aufwand entfernt"],
            [HistoryEventType.FILL]: [
                "has filled the estimated effort with {{newValue}}",
                "hat den geschätzten Aufwand mit {{newValue}} ausgefüllt",
            ],
        },
        priority: {
            [HistoryEventType.UPDATE]: ["has changed the priority to {{newValue}}", "hat die Priorität zu {{newValue}} geändert"],
        },
        gate_task_id: {
            [HistoryEventType.FILL]: [
                "has assigned the activity to level {{newValue}}",
                "hat die Aktivität dem Härtegrad {{newValue}} zugeordnet",
            ],
            [HistoryEventType.UPDATE]: [
                "has assigned the activity to level {{newValue}}",
                "hat die Aktivität dem Härtegrad {{newValue}} zugeordnet",
            ],
            [HistoryEventType.CLEAR]: ["has removed the level assignment", "hat die Härtegradzuordnung entfernt"],
        },
    },
    calculation_history: {
        [translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_SAVINGS]: [
            "Savings were created.",
            "Einsparungen wurden angelegt.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_EXTRACOSTS]: [
            "Extra Costs were created.",
            "Mehrkosten wurden angelegt.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_VALUE]: [
            "The field {{attributeName}} was filled with {{newValue}}.",
            "Das Feld {{attributeName}} wurde mit {{newValue}} ausgefüllt.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_UPDATE_VALUE]: [
            "The field {{attributeName}} was changed from {{previousValue}} to {{newValue}}.",
            "Das Feld {{attributeName}} wurde von {{previousValue}} zu {{newValue}} geändert.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_CURRENCY]: [
            "The currency was set to {{newValue}}.",
            "Die Währung wurde auf {{newValue}} gestellt.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_UPDATE_CURRENCY]: [
            "The currency was changed from {{oldValue}} to {{newValue}}.",
            "Die Währung wurde von {{oldValue}} auf {{newValue}} geändert.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_COPY]: [
            "The {{ source }} values have been copied to {{ target }}.",
            "Die {{ source }} Werte wurden nach {{ target }} kopiert.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_INSERT_HAS_INITIAL]: [
            "The capture type was set to {{captureType}}.",
            "Die Erfassungsart wurde auf {{captureType}} gestellt.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_HAS_INITIAL]: [
            "The capture type {{oldCaptureType}} was changed to {{newCaptureType}}.",
            "Die Erfassungsart {{oldCaptureType}} wurde auf {{newCaptureType}} umgestellt.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FROM_EMPTY]: [
            "The field {{attributeName}} was filled with {{newValue}}.",
            "Das Feld {{attributeName}} wurde mit {{newValue}} ausgefüllt.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_TO_EMPTY]: [
            "The value {{previousValue}} was deleted from {{attributeName}}.",
            "Der Wert {{previousValue}} wurde aus dem Feld {{attributeName}} gelöscht.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_DELETE_FROM_EMPTY]: [
            "The field {{attributeName}} was deleted.",
            "Das Feld {{attributeName}} wurde gelöscht.",
        ],
        [translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FIELD]: [
            "The field {{attributeName}} was changed from {{previousValue}} to {{newValue}}.",
            "Das Feld {{attributeName}} wurde von {{previousValue}} zu {{newValue}} geändert.",
        ],
        [translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_FROM_EMPTY]: [
            "{{attributeName}} for {{effectDate}} was filled with {{newValue}}.",
            "{{attributeName}} wurde für {{effectDate}} mit {{newValue}} ausgefüllt.",
        ],
        [translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_UPDATE]: [
            "{{attributeName}} for {{effectDate}} was changed from {{previousValue}} to {{newValue}}.",
            "{{attributeName}} für {{effectDate}} wurde von {{previousValue}} zu {{newValue}} geändert.",
        ],
        [translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_TO_EMPTY]: [
            "{{attributeName}} for {{effectDate}} was removed.",
            "{{attributeName}} für {{effectDate}} wurde entfernt.",
        ],
    },
    idea_history: {
        title: {
            [HistoryEventType.INSERT]: ["has created the opportunity", "hat die Opportunität erstellt"],
            [HistoryEventType.UPDATE]: ['has changed the title to "{{newValue}}"', 'hat den Titel auf "{{newValue}}" geändert'],
        },
        description: {
            [HistoryEventType.INSERT]: ['has set the description to "{{newValue}}"', 'hat die Beschreibung auf "{{newValue}}" gesetzt'],
            [HistoryEventType.UPDATE]: [
                'has changed the description to "{{newValue}}"',
                'hat die Beschreibung auf "{{newValue}}" geändert',
            ],
            [HistoryEventType.DELETE]: ["has deleted the description", "hat die Beschreibung gelöscht"],
        },
        potentialEstimate: {
            [HistoryEventType.INSERT]: ['has set the potential to "{{newValue}}"', 'hat das Potenzial auf "{{newValue}}" gesetzt'],
            [HistoryEventType.UPDATE]: ['has changed the potential to "{{newValue}}"', 'hat das Potenzial auf "{{newValue}}" geändert'],
        },
        timeEstimate: {
            [HistoryEventType.INSERT]: [
                'has set the implementation period to "{{newValue}}" months',
                'hat den Umsetzungszeitraum auf "{{newValue}}" Monate gesetzt',
            ],
            [HistoryEventType.UPDATE]: [
                'has changed the implementation period to "{{newValue}}" months',
                'hat den Umsetzungszeitraum auf "{{newValue}}" Monate geändert',
            ],
        },
        effortEstimate: {
            [HistoryEventType.INSERT]: [
                'has set the estimated effort to "{{newValue}}"',
                'hat den geschätzten Aufwand auf "{{newValue}}" gesetzt',
            ],
            [HistoryEventType.UPDATE]: [
                'has changed the estimated effort to "{{newValue}}"',
                'hat den geschätzten Aufwand auf "{{newValue}}" geändert',
            ],
        },
        convertedById: {
            [HistoryEventType.UPDATE]: ["has converted the opportunity to a process", "hat die Opportunität in einen Prozess konvertiert"],
        },
        discardedById: {
            [HistoryEventType.UPDATE]: ["has discarded the opportunity", "hat die Opportunität verworfen"],
        },
        discardReason: {
            [HistoryEventType.UPDATE]: [
                'has set the discard reason to "{{newValue}}"',
                'hat den Verwerfungsgrund auf "{{newValue}}" gesetzt',
            ],
        },
        discardStatement: {
            [HistoryEventType.UPDATE]: [
                'has set the discard statement to "{{newValue}}"',
                'hat den Verwerfungskommentar auf "{{newValue}}" gesetzt',
            ],
        },
        fields: {
            [HistoryEventType.INSERT]: [
                'has filled the field "{{attribute}}" with "{{value}}"',
                'hat das Feld "{{attribute}}" mit "{{value}}" ausgefüllt',
            ],
            [HistoryEventType.UPDATE]: [
                'has changed the value in "{{attribute}}" to "{{value}}"',
                'hat den Wert in "{{attribute}}" zu "{{value}}" geändert',
            ],
            [HistoryEventType.DELETE]: ['has deleted the value from "{{attribute}}"', 'hat den Wert aus "{{attribute}}" gelöscht'],
        },
        ownerId: {
            [HistoryEventType.UPDATE]: [
                "has set {{newValue}} as responsible person",
                "hat {{newValue}} als verantwortliche Person gesetzt",
            ],
        },
    },
};
