import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDownRounded";
import { Avatar, avatarClasses, Chip, chipClasses, darken, ListItemAvatar, ListItemButton, ListItemText, styled } from "@mui/material";
import { UserDto } from "api-shared";
import { TFunction } from "i18next";
import React from "react";
import { formatUser } from "../../lib/formatters";
import { translationKeys } from "../../translations/main-translations";
import CircularButton from "../CircularButton";
import UserAvatar from "./UserAvatar";
import UserEntry from "./UserEntry";

const EmptyAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: "#2799a0",
    color: theme.palette.common.white,
    padding: theme.spacing(0.5), // extend default of 3px to avoid content shift if replaced by avatar,
}));

const UserChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
        backgroundColor: darken(theme.palette.background.paper, theme.palette.action.focusOpacity),
    },
    maxWidth: "100%", // support proper ellipsis on low space
    height: theme.spacing(5),
    borderRadius: theme.spacing(2.5),
    [`& .${avatarClasses.root}`]: {
        marginLeft: theme.spacing(0.25),
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
        ...theme.typography.body2,
        color: theme.palette.background.default, // Chip overwrites avatar default text color, restore
    },
    [`& .${chipClasses.label}`]: {
        ...theme.typography.body2,
    },
}));

export interface ISingleUserButtonProps {
    variant?: "avatar" | "default" | "chip";
    disabled?: boolean;
    avatarSize?: number;
    user?: UserDto | null;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    translate: TFunction;
    className?: string;
}

// erase ref type, because Chip and ListItem have special typings that causes issues
const SingleUserButton = React.forwardRef<any, ISingleUserButtonProps>(
    ({ user, onClick, translate, avatarSize = 40, variant = "default", ...tooltipProps }, ref) => {
        // besides the explicitly declared props, a surrounding tooltip may inject additional DOM props for events listeners and aria
        // attributes, so these props need to be spread to the child components so that the tooltip works
        const { disabled } = tooltipProps;
        const otherProps = { ...tooltipProps, ref };

        if (variant === "chip") {
            return (
                <UserChip
                    onClick={onClick}
                    onDelete={onClick} // needs to be set, so that the icon is displayed
                    deleteIcon={<ArrowDropDownIcon />}
                    avatar={<UserAvatar user={user} size={avatarSize} />}
                    label={user != null ? formatUser(user, { translate }) : translate(translationKeys.VDLANG_DECISION_EMPTY_DECIDER)}
                    {...otherProps}
                />
            );
        }

        if (user) {
            return (
                <UserEntry
                    iconOnly={variant === "avatar"}
                    user={user}
                    onClick={!disabled ? onClick : undefined}
                    onAvatarClick={!disabled ? onClick : undefined}
                    disableGutters
                    disablePadding
                    avatarProps={{ size: avatarSize }}
                    translate={translate}
                    {...otherProps}
                />
            );
        }

        const emptyAvatar = <EmptyAvatar sx={{ width: avatarSize, height: avatarSize }}>{disabled ? " " : <AddRoundedIcon />}</EmptyAvatar>;

        if (variant === "avatar") {
            return (
                <CircularButton onClick={onClick} {...otherProps}>
                    {emptyAvatar}
                </CircularButton>
            );
        }

        return (
            <ListItemButton onClick={onClick} disableGutters sx={{ p: 0 }} {...otherProps}>
                {/* reduce right spacing from 2 to 1 */}
                <ListItemAvatar sx={{ minWidth: (theme) => `calc(${avatarSize}px + ${theme.spacing()})` }}>{emptyAvatar}</ListItemAvatar>
                <ListItemText
                    primary={translate(
                        disabled ? translationKeys.VDLANG_RESPONSIBLE_NOT_ASSIGNED : translationKeys.VDLANG_RESPONSIBLE_ASSIGN_PERSON,
                    )}
                    primaryTypographyProps={{ noWrap: true, variant: "body2" }}
                />
            </ListItemButton>
        );
    },
);
export default SingleUserButton;
