import { Popover } from "@mui/material";
import { CompanyDto } from "api-shared";
import React, { Fragment, ReactElement, ReactNode, useState } from "react";
import { translationKeys } from "../../translations/main-translations";
import Tooltip from "../Tooltip";
import UserDetails from "./UserDetails";
import UserEntry, { IUserEntryProps } from "./UserEntry";

export interface IUnconnectedUserEntryWithPopupProps extends IUserEntryProps {
    title?: string | ReactNode | null;
    children?: ReactNode;
    triggerElement?: ReactElement<{ onClick?: React.MouseEventHandler<HTMLDivElement> }>;
    company?: CompanyDto;
}

// unconnected component is needed for mention rendering, because those are rendered in seperate react subtrees without an available store
const UnconnectedUserEntryWithPopup = React.forwardRef<any, IUnconnectedUserEntryWithPopupProps>(
    ({ title, user, translate, children, triggerElement, onClick, avatarProps, company, ...other }, ref) => {
        const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

        const open = (e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
            if ("currentTarget" in e) {
                setAnchorEl(e.currentTarget);
            }
        };

        const close = (e: React.MouseEvent | React.KeyboardEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorEl(null);
        };

        const content =
            triggerElement != null ? (
                React.cloneElement(triggerElement, { onClick: open })
            ) : (
                <UserEntry
                    ref={ref}
                    user={user}
                    onAvatarClick={open}
                    onClick={onClick ?? open}
                    avatarProps={avatarProps}
                    translate={translate}
                    {...other}
                />
            );

        if (user == null) {
            return <Tooltip title={translate(translationKeys.VDLANG_USER_NOT_ASSIGNED)}>{content}</Tooltip>;
        }

        return (
            <Fragment>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={close}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    {children ?? <UserDetails user={user} company={company} translate={translate} />}
                </Popover>
                {title != null ? <Tooltip title={title}>{content}</Tooltip> : content}
            </Fragment>
        );
    },
);

export default UnconnectedUserEntryWithPopup;
