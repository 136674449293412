import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    accordionSummaryClasses,
    Box,
    Button,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Stack,
    styled,
    Typography,
} from "@mui/material";

import {
    AclNamespaces,
    AclPermissions,
    CreateIdeaRequestBody,
    DiscardIdeaRequestBody,
    IdeaAttributeDto,
    IdeaDto,
    ReactionType,
    SearchIdeaRequestBody,
} from "api-shared";
import { TFunction } from "i18next";
import { debounce, groupBy, sortBy } from "lodash";
import { useState } from "react";
import { Control, FieldError, useController } from "react-hook-form";
import IdeaIdChip from "../../../src/view/ideas/IdeaIdChip";
import Form from "../../components/Form";
import EditableText from "../../components/input/EditableText";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import MarkdownEditorUserMentionsIdea from "../../components/markdowneditor/MarkdownEditorUserMentionsIdea";
import { MeasureBreadcrumb } from "../../components/MeasureBreadcrumb";
import SingleReactionButton from "../../components/reactions/SingleReactionButton";
import Tooltip from "../../components/Tooltip";
import { useAttributeCategoriesQuery } from "../../domain/attributes";
import { useIdeaReactionMutation } from "../../domain/ideas";
import { useMeasureConfigs } from "../../domain/measure-config";
import { useUserHasPermissionQuery } from "../../domain/permissions";
import { useUiState } from "../../domain/ui-state";
import { useCurrentUserId } from "../../domain/users";
import useCurrency from "../../hooks/useCurrency";
import useDialog from "../../hooks/useDialog";
import { useLanguage } from "../../hooks/useLanguage";
import useTimezone from "../../hooks/useTimezone";
import { useIsDesktop } from "../../lib/mobile";
import { translateFromProperty } from "../../lib/translate";
import { translationKeys } from "../../translations/main-translations";
import ConvertIdeaDialog from "./ConvertIdeaDialog";
import EstimatesSlider from "./EstimatesSlider";
import { useIdeaEstimates } from "./hooks";
import IdeaContextMenu from "./IdeaContextMenu";
import { IdeaFieldList } from "./IdeaFieldList";
import IdeaModalSingleUserButton from "./IdeaModalSingleUserButton";
import IdeaProgress from "./IdeaProgress";

export interface IIdeaContextFormProps {
    translate: TFunction;
    onSubmit: () => void;
    measureCreatorId?: number;
    idea?: IdeaDto;
    attributes?: IdeaAttributeDto[];
    errors?: FieldError;
    onConvert: (userId: number, measureConfigId: number) => void;
    hasUnsavedChanges: boolean;
    control: Control<CreateIdeaRequestBody>;
    onDiscard: (discardInfo: DiscardIdeaRequestBody) => void;
    onClose: () => void;
    isReadonly: boolean;
    searchQuery?: SearchIdeaRequestBody;
    ownerId: number | null;
    updateOwnerId: (ownerId: number) => void;
}

// Paddings add up together with padding of DialogConent component placed by BaseDialog
const Header = styled("div")(({ theme }) => ({
    padding: theme.spacing(3, 3, 2),
}));

// Paddings add up together with padding of DialogConent component placed by BaseDialog
const Content = styled("div")(({ theme }) => ({
    padding: theme.spacing(2, 3, 1.5),
}));

const TitleEditableText = styled(EditableText)(({ theme, disabled }) => ({
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
}));

const SliderLabel = styled(FormLabel)(({ theme }) => ({
    marginLeft: -theme.spacing(2),
}));

const GrowingDiv = styled("div")(({ theme }) => ({
    flexGrow: 1,
    flexBasis: 0,
}));

const SectionAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1, 0, 2),
}));

const SectionAccordion = styled(Accordion)(({ theme }) => ({
    "&::before, &.Mui-expanded::before": {
        // hide divider between adjacent Accordions in both expanded and closed state
        opacity: 0,
    },
}));

const SectionAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    gap: theme.spacing(),
    flexDirection: "row-reverse",
    borderRadius: theme.shape.borderRadius,

    // height and hover
    minHeight: theme.spacing(5),
    [`&.${accordionSummaryClasses.expanded}`]: {
        minHeight: theme.spacing(5),
        backgroundColor: theme.palette.background.default,
    },
    [`&:hover`]: {
        backgroundColor: theme.palette.action.hover,
    },

    // expand icon
    [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
        transform: "rotate(-90deg)",
        [`&.${accordionSummaryClasses.expanded}`]: {
            transform: "rotate(0deg)",
        },
    },

    // no padding by default, only transitioned padding on hover/expanded
    padding: 0,
    transition: theme.transitions.create("padding"),
    [`&.${accordionSummaryClasses.expanded}, &:hover`]: {
        padding: theme.spacing(0, 1),
    },
    [`& .${accordionSummaryClasses.content}, & .${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]: {
        margin: 0,
    },
}));

const IdeaContextForm = ({
    translate,
    onSubmit,
    onConvert,
    idea,
    attributes,
    measureCreatorId,
    hasUnsavedChanges,
    errors,
    control,
    onDiscard,
    isReadonly,
    searchQuery,
    onClose,
    ownerId,
    updateOwnerId,
}: IIdeaContextFormProps) => {
    const { formatDate } = useTimezone();
    const { field: titleField } = useController({
        name: "title",
        control,
    });
    const { field: descriptionField } = useController({ name: "description", control, defaultValue: "" });
    const { field: potentialField } = useController({ name: "potentialEstimate", control });
    const { field: timeField } = useController({ name: "timeEstimate", control });

    const { field: effortField } = useController({ name: "effortEstimate", control });

    const { potentialEstimates, timeEstimates, effortEstimates } = useIdeaEstimates();

    const { currencyIsoCode } = useCurrency();
    const currentUserId = useCurrentUserId();
    const language = useLanguage();

    const [uiState, updateUiState] = useUiState();

    const attributeCategories = useAttributeCategoriesQuery();
    const measureConfigs = useMeasureConfigs();

    const [isEditingTitle, setIsEditingTitle] = useState(idea === undefined);

    const isDesktop = useIsDesktop();
    const isMobile = !isDesktop;
    const canCreateProcessQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.Process,
        permission: AclPermissions.Create,
        fact: {},
    });

    const convertIdeaDialog = useDialog();

    const ideaReactionMutation = useIdeaReactionMutation();

    const onToggle = (ideaId: number, enabled?: boolean) => {
        ideaReactionMutation.mutate({ ideaId, enabled, searchQuery });
    };

    const errorText = (errors: FieldError | undefined) => {
        if (errors === undefined) {
            return errors;
        }
        return errors && errors.type?.indexOf("too_big") !== -1
            ? translate(translationKeys.VDLANG_IDEAS_TITLE_TOO_LONG_ERROR)
            : translate(translationKeys.VDLANG_IDEAS_TITLE_EMPTY_ERROR);
    };

    const singleUserButton = (
        <IdeaModalSingleUserButton
            translate={translate}
            ownerId={ownerId}
            updateOwnerId={updateOwnerId}
            dense={isMobile}
            isIdeaReadonly={isReadonly}
        />
    );

    if (!canCreateProcessQuery.isSuccess || !attributeCategories.isSuccess) {
        return <LoadingAnimation />;
    }

    const sortedAttributes = sortBy(attributes, (a) => a.order);
    const attributeGroups = groupBy(sortedAttributes, (attribute) => attribute.categoryId);

    const visibleCategories = attributeCategories.data.filter((category) => attributeGroups[category.id] != null);
    const orderedCategories = sortBy(visibleCategories, (category) => category.order);

    const handleDescriptionUpdate = debounce((value: string) => {
        !isReadonly && descriptionField.onChange(value);
    }, 300);

    const canCreateProcess = canCreateProcessQuery.data.hasPermission && measureConfigs.length > 0;

    return (
        <>
            {idea && (
                <ConvertIdeaDialog
                    isOpen={convertIdeaDialog.isOpen}
                    onClose={convertIdeaDialog.close}
                    onConvert={onConvert}
                    ownerId={ownerId}
                    idea={idea}
                    hasUnsavedChanges={hasUnsavedChanges}
                />
            )}
            <Form onSubmit={onSubmit}>
                <Header>
                    <Stack direction="row" spacing={2} alignItems="flex-start">
                        <GrowingDiv>
                            <TitleEditableText
                                defaultIsEditing={idea === undefined}
                                variant="h6"
                                placeholder={`${translate("Enter an opportunity title")}...`}
                                onTextSave={titleField.onChange}
                                errorText={errorText(errors)}
                                resetTextOnEmptyBlur={false}
                                text={titleField.value ?? ""}
                                onTextChange={titleField.onChange}
                                onEditStart={() => setIsEditingTitle(true)}
                                onEditEnd={() => setIsEditingTitle(false)}
                                disabled={isReadonly}
                                color="textPrimary"
                            />
                            <Stack direction="row" spacing={1} alignItems="center">
                                {/* Use Stack so that all items on the right can be aligned vertically independently of EditableText */}
                                {idea &&
                                    (idea.referencedMeasure !== null ? (
                                        <MeasureBreadcrumb
                                            ideaDisplayId={idea.displayId}
                                            measureDisplayId={idea.referencedMeasure.displayId}
                                            dense
                                        />
                                    ) : (
                                        <IdeaIdChip ideaId={idea.displayId} />
                                    ))}
                                <Tooltip title={translate(translationKeys.VDLANG_CREATED_AT)}>
                                    <Typography color="textSecondary" sx={{ lineHeight: "normal" }}>
                                        {formatDate(idea?.createdAt)}
                                    </Typography>
                                </Tooltip>
                                {isMobile && singleUserButton}
                            </Stack>
                        </GrowingDiv>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ paddingTop: isEditingTitle && isDesktop ? 0.75 : 0.25 }}
                            alignItems="center"
                        >
                            {isDesktop && singleUserButton}
                            {isDesktop && idea !== undefined && !isReadonly && idea.convertedAt === null && (
                                <Tooltip title={!canCreateProcess ? translate(translationKeys.VDLANG_IDEAS_CONVERT_IDEA_NOT_ALLOWED) : ""}>
                                    <Box component="span" sx={{ flexShrink: 0 }}>
                                        <Button disabled={!canCreateProcess} onClick={convertIdeaDialog.open}>
                                            {translate(translationKeys.VDLANG_IDEAS_CONVERT_IDEA)}
                                        </Button>
                                    </Box>
                                </Tooltip>
                            )}
                            {(idea !== undefined || isMobile) && !isReadonly ? (
                                <IdeaContextMenu idea={idea} onDiscard={onDiscard} onConvert={convertIdeaDialog.open} />
                            ) : null}
                            {isMobile && (
                                // Stack's left margin is too much here on mobile. Use element styles because Stack uses selector with high
                                // specificity
                                <IconButton onClick={onClose} style={{ marginLeft: 0 }}>
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </Stack>
                    </Stack>
                </Header>
                <Content>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <FormGroup>
                                <FormControl fullWidth>
                                    <FormLabel sx={{ typography: "subtitle2", mb: 0.5, color: "text.primary" }}>
                                        {translate(translationKeys.VDLANG_IDEAS_IDEA_DESCRIPTION)}
                                    </FormLabel>
                                    <MarkdownEditorUserMentionsIdea
                                        value={descriptionField.value}
                                        disabled={isReadonly}
                                        updateValue={handleDescriptionUpdate}
                                        onChange={handleDescriptionUpdate}
                                        fullHeight={isDesktop}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {/* pt to align with upper edge of description editor */}
                            <Stack spacing={1} sx={{ pt: 3.25, pb: 2 }}>
                                <FormControl>
                                    <SliderLabel htmlFor={potentialField.name}>
                                        {translate(translationKeys.VDLANG_IDEAS_IDEA_POTENTIAL_ESTIMATE_LABEL, {
                                            currencyCode: currencyIsoCode,
                                        })}
                                    </SliderLabel>
                                    <EstimatesSlider
                                        {...potentialField}
                                        id={potentialField.name}
                                        size="small"
                                        marks={potentialEstimates}
                                        disabled={isReadonly}
                                    />
                                </FormControl>
                                <FormControl>
                                    <SliderLabel htmlFor={timeField.name}>
                                        {translate(translationKeys.VDLANG_IDEAS_IDEA_TIME_ESTIMATE_LABEL)}
                                    </SliderLabel>
                                    <EstimatesSlider
                                        {...timeField}
                                        id={timeField.name}
                                        size="small"
                                        marks={timeEstimates}
                                        disabled={isReadonly}
                                    />
                                </FormControl>
                                <FormControl>
                                    <SliderLabel htmlFor={effortField.name}>
                                        {translate(translationKeys.VDLANG_IDEAS_EFFORT_ESTIMATE_LABEL)}
                                    </SliderLabel>
                                    <EstimatesSlider
                                        {...effortField}
                                        id={effortField.name}
                                        size="small"
                                        marks={effortEstimates}
                                        disabled={isReadonly}
                                    />
                                </FormControl>
                            </Stack>
                            {orderedCategories.map((category) => (
                                <SectionAccordion
                                    variant="elevation"
                                    elevation={0}
                                    key={category.id}
                                    defaultExpanded={uiState.ideaAttributeCategoryState[category.id] ?? true}
                                    onChange={(_, expanded) => {
                                        updateUiState({
                                            ideaAttributeCategoryState: {
                                                ...uiState.ideaAttributeCategoryState,
                                                [category.id]: expanded,
                                            },
                                        });
                                    }}
                                >
                                    <SectionAccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="subtitle2">{translateFromProperty(category, "name", language)}</Typography>
                                    </SectionAccordionSummary>
                                    <SectionAccordionDetails>
                                        <IdeaFieldList
                                            control={control}
                                            attributes={attributeGroups[category.id]}
                                            fields={idea?.fields ?? {}}
                                            disabled={isReadonly}
                                        />
                                    </SectionAccordionDetails>
                                </SectionAccordion>
                            ))}
                        </Grid>
                    </Grid>
                </Content>
                {idea != null && currentUserId !== null && (
                    <Box px={3} py={1}>
                        <SingleReactionButton
                            reactions={idea.reactions}
                            userId={currentUserId}
                            onToggle={(enabled) => onToggle(idea.id, enabled)}
                            disabled={isReadonly}
                            type={ReactionType.Thumbsup}
                        />
                    </Box>
                )}
                {idea != null && <IdeaProgress ideaId={idea.id} measureCreatorId={measureCreatorId} isReadonly={isReadonly} />}
            </Form>
        </>
    );
};

export default IdeaContextForm;
