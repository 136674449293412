import SendIcon from "@mui/icons-material/SendRounded";
import { IconButton, ListItem, ListItemAvatar, ListItemProps, ListItemSecondaryAction, ListItemText, styled } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "../../domain/users";
import { isSubmitEvent } from "../../lib/keybindings";
import Tooltip from "../Tooltip";
import MarkdownEditorWithoutMentions, { MarkdownEditorUserMentionsOwnProps } from "../markdowneditor/MarkdownEditorWithoutMentions";
import UserEntryWithPopup from "../user/UserEntryWithPopup";

const editorClass = "Vd-CommentInput-editor";

const RootListItem = styled(ListItem)(({ theme }) => ({
    // reduce right padding so that input is more close to the send icon
    paddingRight: theme.spacing(4),

    [`& .${editorClass}`]: {
        backgroundColor: theme.palette.background.paper,
    },
}));

// ListItem is not directy typed with ListItemProps, but it is wrapped with a conditional on property "button" which causes issues when applying ListItemProps directly on ListItem
interface ICommentInputProps extends ListItemProps {
    onCreate: (markdown: string) => void;
    customEditorComponent?: React.ComponentType<MarkdownEditorUserMentionsOwnProps>;
}

const CommentInput = ({ onCreate, customEditorComponent, ...listItemProps }: ICommentInputProps) => {
    const EditorComponent = customEditorComponent ?? MarkdownEditorWithoutMentions;
    const { t: translate } = useTranslation();

    const [text, setText] = useState("");

    // key to reset editor after saving
    const [key, setKey] = useState(1);

    const currentUser = useCurrentUser();

    const updateText = (value: string) => setText(value);

    const isValidComment = () => text.trim().length > 0;

    const createComment = () => {
        onCreate(text);
        setText("");
        // update key to force re-init of editor with empty value, because it is used in un-controlled mode
        setKey(key + 1);
    };

    const submitOnEnter = (e: KeyboardEvent) => {
        if (isSubmitEvent(e) && isValidComment()) {
            createComment();
        }
    };

    return (
        <RootListItem {...listItemProps}>
            <ListItemAvatar>
                <UserEntryWithPopup iconOnly user={currentUser} />
            </ListItemAvatar>
            <ListItemText>
                <EditorComponent
                    // uncontrolled mode, only capture changes
                    key={key}
                    onChange={updateText}
                    placeholder={translate("Enter comment...")}
                    onKeyDown={submitOnEnter}
                    hideToolbar
                    compact
                    className={editorClass}
                />
            </ListItemText>
            <ListItemSecondaryAction>
                <Tooltip title={translate("Save comment")}>
                    <span>
                        <IconButton edge="end" color="primary" onClick={createComment} disabled={!isValidComment()}>
                            <SendIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </ListItemSecondaryAction>
        </RootListItem>
    );
};

export default CommentInput;
