import { CircularProgress, CircularProgressProps, Grid } from "@mui/material";
import React from "react";
/*
    Loading animation that is automatically centered using a flex container.

    All props except the className will be attached to the CircularProgress component.

    The className prop will be passed to the flex container.
*/
const LoadingAnimation = React.forwardRef<HTMLDivElement, CircularProgressProps>(({ className, ...circularProgressProps }, ref) => (
    <Grid ref={ref} className={className} container justifyContent="center" alignItems="center">
        <Grid item>
            <CircularProgress {...circularProgressProps} />
        </Grid>
    </Grid>
));
export default LoadingAnimation;
