import { components, GroupBase, IndicatorsContainerProps } from "react-select";
import { Option } from "../types";

const SelectIndicatorsContainer = <
    OptionType extends Option,
    IsMulti extends boolean,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
    props: IndicatorsContainerProps<OptionType, IsMulti, GroupType>,
) => <components.IndicatorsContainer {...props} />;

export default SelectIndicatorsContainer;
