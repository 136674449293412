import { styled } from "@mui/material";
import { SubTaskPriority } from "api-shared";
import { TFunction } from "i18next";
import { translationKeys } from "../../translations/main-translations";
import PriorityIcon from "../icons/PriorityIcon";

const StyledPriorityIcon = styled(PriorityIcon)({
    display: "inline-flex",
    fontSize: "1rem",
});

const Labels = {
    [SubTaskPriority.PRIO_LOW]: translationKeys.VDLANG_LOW,
    [SubTaskPriority.PRIO_MEDIUM]: translationKeys.VDLANG_MEDIUM,
    [SubTaskPriority.PRIO_HIGH]: translationKeys.VDLANG_HIGH,
};

interface ISubtaskListPriorityGroupHeaderProps {
    priority: SubTaskPriority;
    translate: TFunction;
}

const SubtaskListItemPriorityIcon = ({ priority, translate }: ISubtaskListPriorityGroupHeaderProps) => {
    return <StyledPriorityIcon priority={priority} title={translate(Labels[priority])} />;
};

export default SubtaskListItemPriorityIcon;
