import { stringExcel } from "@json2csv/formatters";
import { Parser } from "@json2csv/plainjs";
import { merge } from "lodash";

const defaultOptions = {
    withBOM: true,
    delimiter: ";",
};

export function parse(
    data: Record<string, unknown> | Array<Record<string, unknown>>,
    options?: object,
    formatForPrint = false,
    formatForExcel = false,
) {
    const opts = merge(
        {},
        defaultOptions,
        formatForPrint && {
            eol: "\r\n",
            excelStrings: true,
            formatters: {
                string: stringExcel,
            },
        },
        {
            withBOM: formatForExcel ? false : defaultOptions.withBOM,
        },
        options,
    );
    const CsvParser = new Parser(opts);
    const csv = CsvParser.parse(data);

    // Reminder: CSV files can only be opened in MS Excel automatically if a metadata row with sep=delimiter is added at the beginning of the CSV.
    // But this metadata line not supported by the CSV RFC and may break other usages of the CSV files.
    // withBOM option needs to be disabled in this case and BOM handling needs to be done manually.
    const bom = opts.withBOM ? "\ufeff" : "";
    return formatForExcel ? `${bom}sep=${opts.delimiter}\n${csv}` : csv;
}
