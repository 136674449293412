import { AclNamespaces } from "api-shared";
import React, { Suspense } from "react";
import { useUsersHavingAccessPermissionQuery } from "../../domain/permissions";
import { isActiveUser, useAllUsers } from "../../domain/users";
import { MarkdownEditorUserMentionsOwnProps } from "./MarkdownEditorWithoutMentions";

const MarkdownEditor = React.lazy(() => import("./MarkdownEditor"));

export default function MarkdownEditorUserMentionsIdea(props: MarkdownEditorUserMentionsOwnProps) {
    const havingIdeaAccessPermissionQuery = useUsersHavingAccessPermissionQuery(AclNamespaces.Idea);
    const users = useAllUsers();

    if (!havingIdeaAccessPermissionQuery.isSuccess) {
        return null;
    }

    const allAllowedUsers = users.filter((user) => havingIdeaAccessPermissionQuery.data.combinedUserIds.includes(user.id));

    const mentionIds = allAllowedUsers.filter(isActiveUser).map(({ id }) => id);
    const mentionResolve = allAllowedUsers.map((sel) => sel.id);

    return (
        <Suspense>
            <MarkdownEditor {...props} mentionActiveUsers={mentionIds} mentionAllUsers={mentionResolve} />
        </Suspense>
    );
}
