import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    CompanyDto,
    CreateCustomValueRequestBody,
    CreateProjectRequestBody,
    CustomValueDto,
    MeasureAttributeDto,
    ProjectDto,
    ValueLeverDto,
    WidgetTemplateDto,
} from "api-shared";
import { chain } from "lodash";
import { useDispatch } from "react-redux";
import { apiGet, apiPost } from "../lib/api";
import { isFieldDataSource, registerFieldData } from "./field-data";

export enum Endpoint {
    Companies = "companies",
    ValueLevers = "value-levers",
    Projects = "projects",
    CustomValues = "custom-values",
    MeasureAttributes = "measure-attributes",
    WidgetTemplates = "widget-templates",
}

export const EndpointQueryKeys = {
    all: ["endpoints"] as const,
    forEndpoint: (endpoint: Endpoint) => [...EndpointQueryKeys.all, endpoint] as const,
};

type ResponseType = {
    [Endpoint.Companies]: CompanyDto;
    [Endpoint.ValueLevers]: ValueLeverDto;
    [Endpoint.Projects]: ProjectDto;
    [Endpoint.CustomValues]: CustomValueDto;
    [Endpoint.MeasureAttributes]: MeasureAttributeDto;
    [Endpoint.WidgetTemplates]: WidgetTemplateDto;
};

type CreateInputType = {
    [Endpoint.Projects]: CreateProjectRequestBody;
    [Endpoint.CustomValues]: CreateCustomValueRequestBody;
};

interface UseMeasureConfigsQueryOptions<TResponse, TSelect> {
    select?: (response: Array<TResponse>) => TSelect;
    enabled?: boolean;
}

export function useEndpointQuery<EndpointType extends Endpoint, SelectType = Array<ResponseType[EndpointType]>>(
    endpoint: EndpointType,
    { enabled = true, select }: UseMeasureConfigsQueryOptions<ResponseType[EndpointType], SelectType> = {},
) {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: EndpointQueryKeys.forEndpoint(endpoint),
        queryFn: ({ queryKey, signal }) => apiGet<Array<ResponseType[EndpointType]>>(queryKey[1], { signal }),
        suspense: true,
        enabled,
        onSuccess: (response) => {
            if (!isFieldDataSource(endpoint)) {
                // Only register field data, if an endpoint is used, that belongs to field data
                return;
            }
            // In case a custom select is provided, gather the original response from queryClient
            const currentData = select == null ? response : queryClient.getQueryData(EndpointQueryKeys.forEndpoint(endpoint));
            // Help the compiler, which does not now about: select == null => SelectType === Array<ResponseType[EndpointType]>
            const typedData = currentData as Array<ResponseType[EndpointType]>;
            dispatch(registerFieldData(endpoint, typedData));
        },
        select,
        staleTime: 5 * 60 * 1000,
    });
}

export function useCreateEndpointMutation<EndpointType extends keyof CreateInputType>(endpoint: EndpointType) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: CreateInputType[EndpointType]) => apiPost<ResponseType[EndpointType]>(endpoint, data),
        onSuccess: () => {
            queryClient.invalidateQueries(EndpointQueryKeys.forEndpoint(endpoint));
        },
    });
}

function generateNameMapping<T extends { id: number; name: string }>(items: T[]) {
    return chain(items)
        .keyBy(({ id }) => id)
        .mapValues((value) => value.name)
        .value();
}

export const useProjectNames = () => {
    return useEndpointQuery(Endpoint.Projects, { select: generateNameMapping }).data ?? {};
};

export const useCompanies = () => {
    return useEndpointQuery(Endpoint.Companies).data ?? [];
};

export const useCompany = (id?: number) => {
    return useEndpointQuery(Endpoint.Companies, { select: (response) => response.find((c) => c.id === id) }).data;
};
export const useCompanyNames = () => {
    return useEndpointQuery(Endpoint.Companies, { select: generateNameMapping }).data ?? {};
};

export const useMeasureAttributes = () => {
    return useEndpointQuery(Endpoint.MeasureAttributes).data ?? [];
};

export const useValueLevers = () => {
    return useEndpointQuery(Endpoint.ValueLevers).data ?? [];
};
