import { ListItem, ListItemButton, ListItemProps } from "@mui/material";
import React from "react";
import DndItem from "../draggable-list/DndItem";
import SubtaskListItemContent, { SubtaskListItemContentProps } from "./SubtaskListItemContent";

interface ISubtaskListItemProps extends Pick<ListItemProps, "divider">, SubtaskListItemContentProps {
    isSortable: boolean;
    onClick?: (id: number) => void;
    selected?: boolean;
    id: number;
}

const SubtaskListItem = ({ id, isSortable, onClick, selected, divider, ...contentProps }: ISubtaskListItemProps) => {
    if (isSortable) {
        return (
            <DndItem showHandle id={id} divider={divider} selected={selected} onClick={onClick}>
                <SubtaskListItemContent {...contentProps} />
            </DndItem>
        );
    }

    if (onClick != null) {
        return (
            <ListItemButton divider={divider} selected={selected} onClick={() => onClick(id)}>
                <SubtaskListItemContent {...contentProps} />
            </ListItemButton>
        );
    }

    return (
        <ListItem divider={divider}>
            <SubtaskListItemContent {...contentProps} />
        </ListItem>
    );
};

export default React.memo(SubtaskListItem);
