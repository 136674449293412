import { Sort } from "api-shared";
import { TFunction } from "i18next";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Option } from "../components/input/select/types";
import { translationKeys } from "../translations/main-translations";

const ORDERS = {
    [Sort.ASCENDING]: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_ASC,
    [Sort.DESCENDING]: translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_DESC,
};

const SEPERATOR = "-";

type OrderOptionValue = number | string;

export type OrderedOptionHandler<T extends OrderOptionValue> = (values: T | null, orders: Sort | null) => void;

const wrapValue = <T extends OrderOptionValue>(value: T, order: Sort) => `${value.toString()}${SEPERATOR}${order}`;

const wrapLabel = (optionLabel: string, optionOrder: translationKeys, translate: TFunction) => `${optionLabel} ${translate(optionOrder)}`;

const unwrapOrderOption = <T extends OrderOptionValue>(selectValue: string, options: Option<T>[]): [T, Sort] => {
    const sepIndex = selectValue.lastIndexOf(SEPERATOR);
    const valueStr = selectValue.slice(0, sepIndex);
    const value = options.length > 0 && typeof options[0].value === "number" ? +valueStr : valueStr;
    return [value as T, selectValue.slice(sepIndex + 1) as Sort];
};

export const useOrderOptions = <T extends OrderOptionValue>(
    value: T | null,
    order: Sort,
    options: Option<T>[],
    onChange: OrderedOptionHandler<T>,
) => {
    const { t: translate } = useTranslation();

    const handleChange = useCallback(
        (selectedOption: Option<string> | null) => {
            if (selectedOption == null) {
                return onChange(null, null);
            }

            const [optionValue, optionOrder] = unwrapOrderOption(selectedOption.value, options);
            onChange(optionValue, optionOrder);
        },
        [onChange, options],
    );

    const orderOptions = useMemo(
        () =>
            options.reduce((acc: Option<string>[], option: Option<T>) => {
                Object.entries(ORDERS).forEach(([key, value]) => {
                    acc.push({
                        value: wrapValue(option.value, key as Sort),
                        label: wrapLabel(option.label, value, translate),
                    });
                });
                return acc;
            }, []),
        [options, translate],
    );

    const selectedValue = value != null ? wrapValue(value, order) : null;

    // force null if not found to signal selectbox that no option is selected
    const selectedOption = orderOptions.find((o) => o.value === selectedValue) ?? null;

    return {
        value: selectedOption,
        options: orderOptions,
        onChange: handleChange,
        isMulti: false as const, // only single selection mode is supported
    };
};
