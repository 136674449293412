import { FormGroup, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import BlueStandaloneCard from "../../components/BlueStandaloneCard";
import Form from "../../components/Form";
import SetPasswordInput from "../../components/input/SetPasswordInput";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import LoadingButton from "../../components/LoadingButton";
import { useConfirmPasswordReset, useValidatePasswordResetToken } from "../../domain/password-reset";
import { useLanguageChange } from "../../domain/translation";
import { LoginData } from "../../lib/api";
import { useLoginMutation } from "../../lib/authentication-saga";
import { isLanguage, translationKeys } from "../../translations/main-translations";
import PageNotFound from "../error/PageNotFound";
import TokenExpired from "./TokenExpired";

const Title = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(0, 0, 4),
}));

const Description = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(1, 0, 4),
}));

const PasswordResetConfirm = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const language = searchParams.get("language");

    const { t: translate } = useTranslation();

    const changeLanguage = useLanguageChange();
    const loginMutation = useLoginMutation();
    const confirmPasswordResetMutation = useConfirmPasswordReset();
    const validatePasswordResetTokenMutation = useValidatePasswordResetToken();
    const validateToken = validatePasswordResetTokenMutation.mutate;
    const navigate = useNavigate();

    useEffect(() => {
        token != null && validateToken(token);
    }, [validateToken, token]);

    useEffect(() => {
        isLanguage(language) && changeLanguage(language);
    }, [changeLanguage, language]);

    const [password, setPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    function updatePassword(password: string, isPasswordValid: boolean) {
        setPassword(password);
        setIsPasswordValid(isPasswordValid);
    }

    const loginAndRedirect = async (email: string, password: string): Promise<void> => {
        try {
            await loginMutation.mutateAsync(new LoginData(email, password));
            // redirect after successful login to avoid redirect to login page because user is not authenticated (yet)
            navigate("/");
        } catch (e) {
            // Auto-login is not critical, so just ignore any errors caused by the mutation
        }
    };

    function submit() {
        if (isPasswordValid && token != null) {
            confirmPasswordResetMutation.mutate(
                { token, password },
                {
                    onSuccess: ({ email }, { password }) => {
                        loginAndRedirect(email, password);
                    },
                },
            );
        }
    }

    if (validatePasswordResetTokenMutation.isLoading) {
        return <LoadingAnimation />;
    }

    if (!validatePasswordResetTokenMutation.isSuccess) {
        return <PageNotFound />;
    }

    const isValidToken = validatePasswordResetTokenMutation.data.success;

    return (
        <BlueStandaloneCard>
            {isValidToken ? (
                <>
                    <Title variant="h4" align="center">
                        {translate(translationKeys.VDLANG_PASSWORD_RESET_CONFIRM_TITLE)}
                    </Title>
                    <Form onSubmit={submit}>
                        <FormGroup>
                            <SetPasswordInput size="extralarge" onPasswordChange={updatePassword} />
                            <Description variant="caption">{translate(translationKeys.VDLANG_PASSWORD_REQUIREMENTS)}</Description>
                        </FormGroup>
                    </Form>
                    <LoadingButton
                        isLoading={confirmPasswordResetMutation.isLoading}
                        fullWidth
                        color="primary"
                        variant="contained"
                        size="large"
                        disabled={!isPasswordValid}
                        onClick={submit}
                    >
                        {translate(translationKeys.VDLANG_PASSWORD_RESET_CONFIRM_BUTTON)}
                    </LoadingButton>
                </>
            ) : (
                <TokenExpired dense translate={translate} />
            )}
        </BlueStandaloneCard>
    );
};

export default PasswordResetConfirm;
