import { DialogContentText } from "@mui/material";
import { Partialize } from "../../types/helpers";
import ActionItemDialog, { IActionItemDialogProps } from "./ActionItemDialog";

export interface IConfirmDialogProps extends Partialize<Omit<IActionItemDialogProps, "onPrimary">, "primary"> {
    onConfirm: IActionItemDialogProps["onPrimary"];
    item: string;
}

const ConfirmDialog = ({ onConfirm, children, item, ...dialogProps }: IConfirmDialogProps) => (
    <ActionItemDialog primary={item} onPrimary={onConfirm} action="confirm" {...dialogProps}>
        <DialogContentText>{children}</DialogContentText>
    </ActionItemDialog>
);
export default ConfirmDialog;
