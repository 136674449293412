import AddIcon from "@mui/icons-material/AddRounded";
import CheckBoxOutlineIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxIcon from "@mui/icons-material/CheckBoxRounded";
import { ListItemIcon, ListItemText, styled } from "@mui/material";
import { OptionProps } from "react-select";
import Tooltip from "../../../Tooltip";
import { Option } from "../types";
import SelectOptionContainer from "./SelectOptionContainer";

const specificityFixClass = "specificityFix";

const StyledListItemIcon = styled(ListItemIcon)({
    [`&.${specificityFixClass}`]: {
        minWidth: 0,
    },
});

const SelectCheckboxOption = <OptionType extends Option, IsMulti extends boolean>(props: OptionProps<OptionType, IsMulti>) => {
    const { data, children, isSelected } = props;
    const { primaryLabel, secondaryLabel, isNew } = data;

    const primary = primaryLabel ?? children;

    // Do not use Checkbox component here, because its interaction handling is not needed here
    // all interaction handling is done by the ListItem/MenuItem
    let IconComponent = isSelected ? CheckBoxIcon : CheckBoxOutlineIcon;

    if (isNew) {
        IconComponent = AddIcon;
    }

    return (
        <SelectOptionContainer dense {...props}>
            <StyledListItemIcon className={specificityFixClass}>
                <IconComponent color={isSelected ? "primary" : undefined} fontSize="small" sx={{ mr: 1 }} />
            </StyledListItemIcon>
            <Tooltip onlyOverflowing title={primary}>
                <ListItemText
                    primary={primary}
                    secondary={secondaryLabel}
                    primaryTypographyProps={{ noWrap: true }}
                    secondaryTypographyProps={{ noWrap: true, variant: "caption" }}
                />
            </Tooltip>
        </SelectOptionContainer>
    );
};

export default SelectCheckboxOption;
