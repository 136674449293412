import {
    Box,
    Card,
    CardContent,
    CardHeader,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Skeleton,
    useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MeasureIdChip from "../../components/MeasureIdChip";
import { useLatestViewedMeasures } from "../../domain/measure/views";
import { RouteFor } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";

export const RecentMeasuresCard = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const latestViewedQuery = useLatestViewedMeasures();

    return (
        <Card>
            <CardHeader
                title={t(translationKeys.VDLANG_FEED_RECENT_MEASURES)}
                titleTypographyProps={{ variant: "subtitle1" }}
                sx={{ p: theme.spacing(2, 3, 1) }}
            />
            <CardContent sx={{ p: theme.spacing(0, 3, 2) }}>
                {latestViewedQuery.isLoading && (
                    <Box>
                        {[...Array(10).keys()].map((index) => (
                            <Skeleton key={index} height={theme.spacing(4.5)} />
                        ))}
                    </Box>
                )}
                {latestViewedQuery.isSuccess && (
                    <List disablePadding>
                        {latestViewedQuery.data.map(({ id, clientIid, title }, index, all) => (
                            <ListItem key={id} divider={index < all.length - 1} disableGutters dense disablePadding>
                                <ListItemButton disableGutters component={Link} to={RouteFor.measure.forId(id)}>
                                    <ListItemIcon sx={{ minWidth: theme.spacing(7) }}>
                                        <MeasureIdChip measureId={clientIid} />
                                    </ListItemIcon>
                                    <ListItemText primaryTypographyProps={{ noWrap: true }}>{title}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                        {
                            // Show test message if users is new and hasn't seen a process in the past
                            latestViewedQuery.data.length === 0 && (
                                <ListItemText secondary={t(translationKeys.VDLANG_FEED_RECENT_MEASURES_EMPTY)} />
                            )
                        }
                    </List>
                )}
            </CardContent>
        </Card>
    );
};
