import { AttributeTable, AttributeTitle, CurrentGateType, TranslationType, TreeNodeDto } from "api-shared";
import { TFunction } from "i18next";
import { camelCase, sortBy } from "lodash";
import { useSelector } from "react-redux";
import { useCurrencies } from "../domain/currencies";
import { useDepartments } from "../domain/departments";
import { Endpoint, useEndpointQuery } from "../domain/endpoint";
import { isFieldDataSource, selectFieldData } from "../domain/field-data";
import { useGroups } from "../domain/group";
import { useGateTaskConfigs, useMeasureConfigs, useMeasureConfigsQuery } from "../domain/measure-config";
import { useCostLeversQuery } from "../domain/methods/cost-lever";
import { useSuppliersQuery } from "../domain/suppliers";
import { useTreeNodeQuery } from "../domain/tree-node";
import { useUsersQuery } from "../domain/users";
import { reportError } from "../infrastructure/sentry";
import { AppState } from "../infrastructure/store";
import { Language, translationKeys } from "../translations/main-translations";
import { Field, LabelAccessor } from "./fields";
import { getCostLeverCompareByProperty } from "./sort";
import { translateFromProperty } from "./translate";

export interface RawOption extends Record<string, unknown> {
    id: number;
    order: number;
    parentId?: number | null;
    isFixed?: boolean;
    isDuplicate?: boolean;
}

export interface TranslatedOption extends Pick<RawOption, "id" | "order" | "isFixed" | "parentId"> {
    name: string;
    // add value/label here as compatibility, in the long run, id/name should be migrated to value/label
    // have a consistent interface to the select component
    value: RawOption["id"];
    label: string;
    data: RawOption;
}

export interface TranslatedTreeNode {
    id: number;
    parentId: number | null;
    order: number;
    children: TranslatedTreeNode[];
    name: string;
    clientId: number;
    createdAt: Date;
    createdById: number;
    updatedAt: Date;
    updatedById: number;
    selectable: boolean;
}

export const translateTreeNodes = (nodes: TreeNodeDto[], language: string): TranslatedTreeNode[] => {
    const translateNode = (node: TreeNodeDto, language: string): TranslatedTreeNode => ({
        ...node,
        children: translateTreeNodes(node.children, language),
        name: language === "de" ? node.nameDe : node.nameEn,
    });

    return nodes.map((node) => translateNode(node, language));
};

export function getRawFieldOptions(storeOptions: unknown[], flatOptions: unknown[] | null, field: Field, clientName: string): RawOption[] {
    let options = field.tableName != null ? storeOptions : field.options?.values;

    // Normal OptionsProvider will return a tree as a flattened array
    if (field.type === "tree" && flatOptions !== null) {
        options = flatOptions;
    }

    if (options == null) {
        if (!field.ignoreResolvingErrors) {
            reportError(new Error("Could not resolve options for field"), {
                extras: {
                    title: field?.title,
                    type: field?.type,
                    tableName: String(field?.tableName),
                    fieldOptions: JSON.stringify(field.options),
                    storeOptions: JSON.stringify(storeOptions),
                },
            });
        }
        return [];
    }

    // for originatorClient, add additional option which resembles current client
    if (field.title === AttributeTitle.OriginatorClient && clientName) {
        // manually add current client as first option
        options = [
            {
                id: 0,
                name: clientName,
            },
            ...options,
        ];
    }

    if (field.title === "currentGateTaskConfigId") {
        options = [
            ...options,
            {
                id: CurrentGateType.GATE_CLOSED,
                name: translationKeys.VDLANG_GATE_CLOSED,
            },
        ];
    }
    return options as RawOption[];
}

export const filterFieldOptions = (rawOptions: RawOption[], field: Field, value: unknown, parentValue?: number | null): RawOption[] => {
    let optionsFiltered = rawOptions;
    const { depends, dependsValue } = field.options ?? {};
    const filteredValues = field.options?.filteredValues ?? field.filteredValues;

    const overriddenParentValue = dependsValue ?? parentValue;

    if (depends != null && overriddenParentValue !== undefined) {
        // check for new property names, e.g. value_lever_id => valueLeverId
        const attributeName = camelCase(depends);
        optionsFiltered = rawOptions.filter((x) =>
            // parentValue can be of type string (on initial view) or other type (on change by user)
            // we do not want no make any assumptions abount parentValue here
            // thus we need to stringify both values to compare them
            overriddenParentValue === null
                ? x[attributeName] === null
                : x[attributeName] != null && String(x[attributeName]) === String(overriddenParentValue),
        );
    }

    if (filteredValues != null) {
        optionsFiltered = optionsFiltered.filter((value) => !filteredValues.includes(value.id));
    }

    // Remove deleted options when they are not in the value
    optionsFiltered = optionsFiltered.filter(
        (option) => option.deletedAt == null || (Array.isArray(value) ? value.includes(option.id) : value === option.id),
    );

    return optionsFiltered;
};

const getLabelAccessor = (lang: Language, field: Field): LabelAccessor => {
    const { tableName, options, translate } = field;
    const name = options?.name;

    if (tableName === AttributeTable.CostLevers) {
        return (option: unknown) => `${(option as { code: number }).code} - ${translateFromProperty(option, "name", lang)}`;
    }

    if (translate === TranslationType.MapToLangProperty) {
        return (option: unknown) => translateFromProperty(option, "name", lang);
    }

    const key = typeof name === "string" ? name : "name";

    if (
        translate != null &&
        ([TranslationType.Map, TranslationType.MapAndTranslate].includes(translate) || field.options?.resolveDuplicates) &&
        typeof name === "function"
    ) {
        // mapping already provided by accessor function
        return name;
    }

    return ((option: Record<string, string>) => option[key]) as LabelAccessor;
};

export const translateFieldOptions = (
    filteredOptions: RawOption[],
    field: Field,
    lang: Language,
    translate: TFunction,
): TranslatedOption[] => {
    const labelAccessor = getLabelAccessor(lang, field);
    const { translate: translationType } = field;

    return filteredOptions.map((option) => {
        let extendedOption = option;
        if (field.options?.resolveDuplicates) {
            extendedOption = {
                ...extendedOption,
                isDuplicate: filteredOptions.filter((filteredOption) => filteredOption.name === extendedOption.name).length > 1,
            };
        }
        const rawLabel = labelAccessor(extendedOption, translate);
        const name =
            translationType === TranslationType.MapAndTranslate
                ? translate(rawLabel, { ...extendedOption, defaultValue: rawLabel })
                : rawLabel;

        const decoratedName = extendedOption.deletedAt == null ? name : translate(translationKeys.VDLANG_SELECT_DELETED_OPTION, { name });

        return {
            id: extendedOption.id,
            order: extendedOption.order,
            isFixed: extendedOption.isFixed,
            name: decoratedName,
            label: decoratedName,
            value: extendedOption.id,
            data: extendedOption,
            parentId: extendedOption.parentId,
        };
    });
};

export const sortFieldOptions = (options: TranslatedOption[], field: Field) => {
    // CostLevers and TreeNodes should be sorted after their
    // translated labels as fallback to their order number
    const sortByAttribute = "name";
    switch (field.tableName) {
        case AttributeTable.Departments:
            return sortBy(options, (x) => x[sortByAttribute].toLowerCase());
        case AttributeTable.CostLevers:
            return options.sort(getCostLeverCompareByProperty(sortByAttribute));
        case "gate_task_configs":
            return sortBy(options, ["data.measureConfigId", "order"]);
        case AttributeTable.TreeNodes:
            // TreeNodes should be sorted by their order attribute with fallback to name
            return sortBy(options, ["order", "name"]);
        case AttributeTable.CustomValues:
            // CustomValues should be sorted by their order attribute with fallback to id
            return sortBy(options, ["order", "id"]);
        default:
            return sortBy(options, [sortByAttribute]);
    }
};

export interface SyntheticField extends Field, Partial<Omit<SyntheticFieldAttributes, "type">> {}

export const useFieldDataSubscription = () => {
    // Pull in all queries that may contribute data that resolve to options
    // But only enable the query, that will actually be used later
    useMeasureConfigs();
    useCostLeversQuery();
    useTreeNodeQuery();
    useSuppliersQuery();
    useDepartments();
    useEndpointQuery(Endpoint.Companies);
    useEndpointQuery(Endpoint.ValueLevers);
    useEndpointQuery(Endpoint.Projects);
    useEndpointQuery(Endpoint.CustomValues);
    useUsersQuery();
    useGroups();
};

export const useDataForTableNameFromStore = (tableName: string | null): IEntity[] | null => {
    return useSelector((state: AppState) => selectFieldData(state, tableName));
};

export const useDataForTableName = (tableName: string | null): IEntity[] => {
    const measureConfigs = useMeasureConfigsQuery(tableName === "measure_configs");
    const gateTaskConfigs = useGateTaskConfigs(tableName === "gate_task_configs");
    const costLevers = useCostLeversQuery(tableName === AttributeTable.CostLevers);
    const treeNodes = useTreeNodeQuery(tableName === AttributeTable.TreeNodes);
    const suppliers = useSuppliersQuery(tableName === AttributeTable.Suppliers);
    const companies = useEndpointQuery(Endpoint.Companies, { enabled: tableName === AttributeTable.Companies });
    const valueLevers = useEndpointQuery(Endpoint.ValueLevers, { enabled: tableName === "value-levers" });
    const projects = useEndpointQuery(Endpoint.Projects, { enabled: tableName === AttributeTable.Projects });
    const customValues = useEndpointQuery(Endpoint.CustomValues, { enabled: tableName === AttributeTable.CustomValues });
    const users = useUsersQuery({ enabled: tableName === AttributeTable.Users });
    const currencies = useCurrencies();
    const departments = useDepartments(tableName === AttributeTable.Departments);
    const groups = useGroups(tableName === AttributeTable.GroupsWithAccess);

    if (!isFieldDataSource(tableName)) {
        return [];
    }

    const mapping = {
        measure_configs: measureConfigs.data ?? [],
        gate_task_configs: gateTaskConfigs,
        currencies: currencies,
        [AttributeTable.CostLevers]: costLevers.data ?? [],
        [AttributeTable.TreeNodes]: treeNodes.data ?? [],
        [AttributeTable.Suppliers]: suppliers.data ?? [],
        [AttributeTable.Companies]: companies.data ?? [],
        [Endpoint.ValueLevers]: valueLevers.data ?? [],
        [AttributeTable.Projects]: projects.data ?? [],
        [AttributeTable.CustomValues]: customValues.data ?? [],
        [AttributeTable.Users]: users.data ?? [],
        [AttributeTable.Departments]: departments.data ?? [],
        [AttributeTable.GroupsWithAccess]: groups.data ?? [],
    };

    return mapping[tableName];
};

interface SyntheticFieldAttributes {
    filteredValues: number[];
    type: string;
}

export interface IEntity {
    id: number;
}
