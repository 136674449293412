import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import IdeaCommentList from "./IdeaCommentList";

import { useState } from "react";
import { CommentParentType, useComments } from "../../domain/comment";
import { useIdeaHistoryQuery } from "../../domain/ideas";
import { translationKeys } from "../../translations/main-translations";
import IdeaHistoryList from "./IdeaHistoryList";

interface IIdeaProgress {
    ideaId: number;
    measureCreatorId?: number;
    isReadonly?: boolean;
}

enum IdeaTab {
    Comments = 0,
    History = 1,
}

const IdeaProgress = ({ ideaId, measureCreatorId, isReadonly = false }: IIdeaProgress) => {
    const { t: translate } = useTranslation();

    const [toggleTab, setToggleTab] = useState(IdeaTab.Comments);

    const commentsQuery = useComments({ parentType: CommentParentType.IDEA, parentId: ideaId, enabled: ideaId != null });
    const ideaHistoryQuery = useIdeaHistoryQuery(ideaId);

    return (
        <Box>
            <Tabs
                value={toggleTab}
                onChange={(_, value) => setToggleTab(value)}
                sx={{
                    px: 3,
                    borderBottom: 1,
                    borderColor: "divider",
                }}
            >
                <Tab
                    value={IdeaTab.Comments}
                    label={translate(translationKeys.VDLANG_IDEAS_IDEA_COMMENTS, { count: commentsQuery.data?.length ?? 0 })}
                />
                <Tab value={IdeaTab.History} label={translate(translationKeys.VDLANG_IDEAS_IDEA_HISTORY)} />
            </Tabs>
            <Box sx={{ px: 3, py: 2 }}>
                {toggleTab === IdeaTab.Comments && (
                    <IdeaCommentList ideaId={ideaId} comments={commentsQuery.isSuccess ? commentsQuery.data : []} isReadonly={isReadonly} />
                )}
                {toggleTab === IdeaTab.History && (
                    <IdeaHistoryList
                        history={ideaHistoryQuery.isSuccess ? ideaHistoryQuery.data : []}
                        measureCreatorId={measureCreatorId}
                        translate={translate}
                    />
                )}
            </Box>
        </Box>
    );
};

export default IdeaProgress;
