import { List, ListItem, ListItemText, ListProps } from "@mui/material";
import { CommentDto } from "api-shared";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";
import DeleteDialog from "../dialogues/DeleteDialog";
import { MarkdownEditorUserMentionsOwnProps } from "../markdowneditor/MarkdownEditorWithoutMentions";
import Comment from "./Comment";
import CommentInput from "./CommentInput";

interface ICommentListProps extends Omit<ListProps, "translate"> {
    hideMessage?: boolean;
    parentId: number;
    comments: CommentDto[];
    createComment?: (parentId: number, comment: string) => void;
    deleteComment: (commentId: number) => void;
    updateComment: (commentId: number, comment: string) => void;
    customEditorComponent?: React.ComponentType<MarkdownEditorUserMentionsOwnProps>;
    disabled?: boolean;
}

const CommentList = ({
    hideMessage,
    parentId,
    comments,
    createComment,
    deleteComment,
    updateComment,
    customEditorComponent,
    disabled = false,
    ...listProps
}: ICommentListProps) => {
    const { t } = useTranslation();
    const [commentToDelete, setCommentToDelete] = useState<number | null>(null);

    const handleDelete = () => {
        if (commentToDelete !== null) {
            deleteComment(commentToDelete);
            setCommentToDelete(null);
        }
    };

    return (
        <List {...listProps}>
            <DeleteDialog
                hideDescription
                translate={t}
                open={commentToDelete != null}
                onClose={() => setCommentToDelete(null)}
                onDelete={handleDelete}
                item="comment"
            />
            {comments.map((comment) => (
                <Comment
                    disableGutters
                    comment={comment}
                    key={comment.id}
                    deleteComment={(id: number) => setCommentToDelete(id)}
                    updateComment={updateComment}
                    customEditorComponent={customEditorComponent}
                    disabled={disabled}
                />
            ))}
            {comments.length <= 0 && !hideMessage && (
                <ListItem disableGutters>
                    <ListItemText
                        primary={t(translationKeys.VDLANG_ACTIVITIES_NO_COMMENTS_MESSAGE)}
                        primaryTypographyProps={{ color: "textSecondary", variant: "body2" }}
                    />
                </ListItem>
            )}
            {createComment != null && !disabled && (
                <CommentInput
                    disableGutters
                    onCreate={(text) => createComment(parentId, text)}
                    customEditorComponent={customEditorComponent}
                />
            )}
        </List>
    );
};

export default CommentList;
