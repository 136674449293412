import React from "react";
import { Route, Routes } from "react-router-dom";

const SubtaskRouter = ({ children }: React.PropsWithChildren<unknown>) => {
    // Establish a new relative routing context, so that child components can simply navigate using ".." and "../:taskId"
    return (
        <Routes>
            <Route path="*" element={children} />
            <Route path=":taskId" element={children} />
        </Routes>
    );
};

export default SubtaskRouter;
