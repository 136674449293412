import {
    Alert,
    Box,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Stack,
    styled,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { CalendarPicker, CalendarPickerProps } from "@mui/x-date-pickers/CalendarPicker";
import { DateValidationError } from "@mui/x-date-pickers/internals";
import { TimeValidationError } from "@mui/x-date-pickers/internals/hooks/validation/useTimeValidation";
import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import momentTimezone from "moment-timezone";
import { ChangeEvent, useState } from "react";
import useTimezone from "../../../hooks/useTimezone";
import { translationKeys } from "../../../translations/main-translations";
import OverlineLabel from "../../OverlineLabel";

const Root = styled(Stack)(({ theme }) => ({
    paddingBottom: theme.spacing(2),
    width: "328px",
}));

const ReminderLabel = styled(OverlineLabel)(({ theme }) => ({
    marginTop: 0,
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
}));

const DATE_FORMAT_TEMPLATE = "YYYY-MM-DD";

function getErrorLabel(date: moment.Moment | null, minDate?: Moment | null, maxDate?: Moment | null) {
    if (date != null && minDate != null && date.isBefore(minDate)) {
        return translationKeys.VDLANG_DATEPICKER_TOO_EARLY;
    }
    if (date != null && maxDate != null && date.isAfter(maxDate)) {
        return translationKeys.VDLANG_DATEPICKER_TOO_LATE;
    }

    return null;
}

/**
 * MaterialUi always returns Moments in the timezone of the browser.
 * In case this differs from the timezone in userprofile we need to create a moment in the correct timezone and transform it into utc.
 *
 * @param {Moment} date
 * @param {string} userTimezone
 * @returns {Moment}
 */
function createUTCMoment(date: Moment, userTimezone: string): Moment {
    return momentTimezone.tz(`${date.format(DATE_FORMAT_TEMPLATE)} ${date.format("HH:mm")}`, userTimezone).utc();
}

interface IDatePickerReminderPopoverProps extends Omit<CalendarPickerProps<Moment>, "translate"> {
    translate: TFunction;
    remindAt: Date | null;
    onReminderChange: (newValue: Moment | null) => void;
    onReminderToggled?: () => void;
    infoMessage?: string;
}

const DatePickerReminderPopover = ({
    date,
    onChange,
    translate,
    remindAt,
    onReminderChange,
    onReminderToggled,
    infoMessage,
    ...calendarProps
}: IDatePickerReminderPopoverProps) => {
    const { timezone } = useTimezone();

    const [reminderActive, setReminderActive] = useState<boolean>(remindAt != null);
    const [reminderDay, setReminderDay] = useState<Moment | null>(remindAt != null ? momentTimezone.utc(remindAt).tz(timezone) : null);
    const [reminderTime, setReminderTime] = useState<Moment | null>(reminderDay);

    // Keep track of errors provided by the onError callback of the reminder date/time components
    const [reminderDateError, setReminderDateError] = useState<DateValidationError>(null);
    const [reminderTimeError, setReminderTimeError] = useState<TimeValidationError>(null);

    const setReminderFromDuedate = () => {
        if (date == null) {
            return;
        }
        const newReminder = moment(date.format(DATE_FORMAT_TEMPLATE)).subtract(3, "days").add(9, "hours");

        if (newReminder.isAfter(moment())) {
            onReminderChange(createUTCMoment(newReminder, timezone));
            setReminderDay(newReminder);
            setReminderTime(newReminder);
        }
    };

    const handleReminderActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked === reminderActive) {
            return;
        }
        setReminderActive(checked);
        if (checked) {
            setReminderFromDuedate();
            return;
        }
        setReminderDay(null);
        setReminderTime(null);
        onReminderChange(null);
    };

    const updateReminderIfComplete = (newDate: Moment | null, newTime: Moment | null) => {
        if (newDate?.isValid() && newTime?.isValid()) {
            const updatedDateTime = newDate.clone().set({
                hour: newTime.hour(),
                minute: newTime.minute(),
                second: newTime.second(),
            });
            onReminderChange(createUTCMoment(updatedDateTime, timezone));
        }
    };

    const handleReminderDateChange = (newDate: Moment | null) => {
        setReminderDay(newDate);
        updateReminderIfComplete(newDate, reminderTime);
    };

    const handleReminderTimeChange = (newTime: Moment | null) => {
        setReminderTime(newTime);
        updateReminderIfComplete(reminderDay, newTime);
    };

    const error = getErrorLabel(date, calendarProps.minDate, calendarProps.maxDate);

    let reminderDateErrorMessage: string | undefined = undefined;
    if (reminderDay?.isValid() && reminderDateError === "disablePast") {
        reminderDateErrorMessage = translate(translationKeys.VDLANG_REMINDER_IN_PAST);
    } else if (reminderDateError != null) {
        reminderDateErrorMessage = translate("Invalid date");
    }

    // Do not show the error message while filling the time input
    const reminderTimeErrorMessage = reminderTime?.isValid() && reminderTimeError != null ? translate("Invalid date") : undefined;

    return (
        <Root>
            {error != null && (
                <Alert severity="error" variant="inline">
                    {translate(error)}
                </Alert>
            )}
            {infoMessage && (
                <Alert severity="info" variant="inline">
                    {infoMessage}
                </Alert>
            )}
            <CalendarPicker<Moment> date={date} onChange={onChange} {...calendarProps} />
            <Divider />
            <FormControlLabel
                sx={{ pt: 1, px: 3 }}
                control={<Switch checked={reminderActive} onChange={handleReminderActiveChange} />}
                label={translate(translationKeys.VDLANG_EMAIL_REMINDER)}
            />
            <Collapse in={reminderActive} onEntered={onReminderToggled} onExited={onReminderToggled}>
                <Box sx={{ pt: 1, px: 3 }}>
                    <Typography color="textSecondary" sx={{ mb: 1 }}>
                        {translate(translationKeys.VDLANG_REMINDER_HINT)}
                    </Typography>
                    <Grid sx={{ pt: 1 }} container direction="row" wrap="nowrap" spacing={1}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <ReminderLabel>{translate("date")}</ReminderLabel>
                                <DatePicker<Moment>
                                    disableOpenPicker // keyboard input only
                                    renderInput={(props) => <TextField {...props} helperText={reminderDateErrorMessage} size="small" />}
                                    value={reminderDay}
                                    onChange={handleReminderDateChange}
                                    disablePast
                                    onError={setReminderDateError}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <ReminderLabel>{translate("time")}</ReminderLabel>
                                <TimePicker<Moment>
                                    disableOpenPicker // keyboard input only
                                    ampm={false}
                                    renderInput={(props) => <TextField {...props} helperText={reminderTimeErrorMessage} size="small" />}
                                    value={reminderTime}
                                    onChange={handleReminderTimeChange}
                                    onError={setReminderTimeError}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
        </Root>
    );
};
export default DatePickerReminderPopover;
