import { CurrencyDto, CurrentGateType, MeasureType } from "api-shared";

type DeskColumnConfig = CurrentGateType[];
export const DESK_MEASURE_COLUMN_CONFIG: DeskColumnConfig = [
    CurrentGateType.GATE_IDEA,
    CurrentGateType.GATE_CALCULATION,
    CurrentGateType.GATE_DECISION,
    CurrentGateType.GATE_IMPLEMENTATION,
    CurrentGateType.GATE_EFFECTIVENESS,
    CurrentGateType.GATE_CLOSED,
];
export const DESK_POSTCAPTURE_COLUMN_CONFIG: DeskColumnConfig = [
    CurrentGateType.GATE_POST_CAPTURE,
    CurrentGateType.GATE_EFFECTIVENESS,
    CurrentGateType.GATE_CLOSED,
];
export const DESK_COLUMN_CONFIGS: { [x: number]: DeskColumnConfig } = {
    [MeasureType.TYPE_STANDARD]: DESK_MEASURE_COLUMN_CONFIG,
    [MeasureType.TYPE_POSTCAPTURE]: DESK_POSTCAPTURE_COLUMN_CONFIG,
};

export const DEFAULT_FILTER_PAGE_SIZE = 50;

export const defaultCurrency: CurrencyDto = {
    id: -1,
    isDefault: true,
    isoCode: "USD",
    name: "US-Dollar",
    exchangeRates: [],
};
