import { DialogContentText } from "@mui/material";
import React from "react";
import { translationKeys } from "../../translations/main-translations";
import ActionItemDialog, { IActionItemDialogProps } from "./ActionItemDialog";

// Those props are injected into ActionItemDialog by this component. Still they are optional to allow overriding
type ProvidedProps = "onPrimary" | "primary" | "title";

export interface IDeleteDialogProps
    extends Omit<IActionItemDialogProps, ProvidedProps>,
        Partial<Pick<IActionItemDialogProps, ProvidedProps>> {
    children?: React.ReactNode;
    onDelete: (e: React.MouseEvent | React.KeyboardEvent) => void;
    item?: string;
    hideDescription?: boolean;
}

const DeleteDialog = ({ translate, children, onDelete, item, title = "", hideDescription, ...otherProps }: IDeleteDialogProps) => {
    let description = null;
    if (children != null) {
        description = React.Children.map(children, (child) => <DialogContentText>{child}</DialogContentText>);
    } else if (!hideDescription && item != null) {
        description = <DialogContentText>{translate(`remove_${item}_description`)}</DialogContentText>;
    }

    return (
        <ActionItemDialog
            action="delete"
            primary={translationKeys.VDLANG_REMOVE}
            onPrimary={onDelete}
            title={item != null ? translate(`remove_${item}_title`) : title}
            translate={translate}
            {...otherProps}
            primaryDanger
        >
            {!hideDescription ? (
                <>
                    {description}
                    <br />
                </>
            ) : null}
            <DialogContentText>{translate("action_cannot_be_undone")}</DialogContentText>
        </ActionItemDialog>
    );
};
export default DeleteDialog;
