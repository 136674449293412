import { nonNullable, TreeNodeDto } from "api-shared";
import { useMemo } from "react";
import { createSelector } from "reselect";
import { IEntity } from "./field-options";
import { safeFlatMap } from "./utils";

export interface TreeNode {
    id: number;
    children?: TreeNode[];

    selectable: boolean;
}

// TODO: TS: Use generic for TreeNode once TS migration is finished
export interface NamedTreeNode extends TreeNode {
    name: string;
}

export enum CheckStatus {
    NO,
    YES,
    PARTIALLY,
}

type Visitor<T> = (node: TreeNode | undefined, childrenResults: T[], ...visitorArgs: any[]) => T;

export interface PathWithSelection {
    status: CheckStatus;
    path: TreeNode[];
}

type TraversableNode = TreeNode[] | TreeNode | null | undefined;

/**
 * This loosely implements the visitor pattern for tree-nodes. Each node is called with a visitor function of signature:
 * (node, childResults, ...remainingArgs) => any. childResults is of type Array<any> and the visitor is responsible of combining the results
 * of the child nodes and the current node into a single result.
 *
 * @param {TreeNode[] | TreeNode | null | undefined} node root node or array of nodes to traverse
 * @param {Visitor} visitor function to call for each node
 * @param {...any[]} remainingArgs all remaining args will be passed to the visitor function as parameters
 * @returns {T | null} result of the visitor function applied to the given tree
 */
export const traverseTree = <T>(node: TraversableNode, visitor: Visitor<T>, ...visitorArgs: any[]): T | null => {
    if (node == null) {
        return null;
    }

    const children = Array.isArray(node) ? node : node.children;
    const parentNode = Array.isArray(node) ? undefined : node;

    const childResults: T[] =
        children != null ? children.map((child) => traverseTree(child, visitor, ...visitorArgs)).filter(nonNullable) : [];
    return visitor(parentNode, childResults, ...visitorArgs);
};

/**
 * checks if the node value is inside the given array of the selected values.
 *
 * @param {TreeNode | undefined} node node whose value should be checked
 * @param {number[]} selectedIds values to search in
 * @returns {CheckStatus} nodes selection status if any provided, otherwise NO
 */
export const getSelectionStatus = (node: TreeNode | undefined, selectedIds: number[]): CheckStatus =>
    Array.isArray(selectedIds) && node !== undefined && selectedIds.includes(node.id) ? CheckStatus.YES : CheckStatus.NO;

/**
 * Visitor function: Aggregates the selection status (Selected, Not Selected, Partially Selected) of a node based on its subtree:
 *
 * The status depends only on the statuses of the nodes children. If there are no children, the status is solely based on the current nodes
 * value.
 *
 * If current is not provided, only the children statuses will determine the result.
 *
 * @param {TreeNode | undefined} current node for which the status should be computed
 * @param {number[]} childrenStatuses statuses of children nodes
 * @param {number[]} selectedValues current selection to compute if the node itself is selected
 * @returns {CheckStatus} selection status of the given node
 */
export const aggregateSelected = (
    current: TreeNode | undefined,
    childrenStatuses: CheckStatus[],
    selectedValues: number[],
): CheckStatus => {
    if (current !== undefined && childrenStatuses.length <= 0) {
        return getSelectionStatus(current, selectedValues);
    }
    // node is not selected, check if children are selected
    if (childrenStatuses.every((c) => c === CheckStatus.YES)) {
        return CheckStatus.YES;
    }
    if (childrenStatuses.every((c) => c === CheckStatus.NO)) {
        return CheckStatus.NO;
    }
    return CheckStatus.PARTIALLY;
};

export const aggregateSelectedMap = (
    current: TreeNode | undefined,
    childrenStatuses: CheckStatus[],
    selectedValues: number[],
    resultMap: Map<number, CheckStatus>,
): CheckStatus => {
    const cachedValue = resultMap.get(current?.id ?? -1);
    if (cachedValue !== undefined) {
        return cachedValue;
    }

    const ownStatus = getSelectionStatus(current, selectedValues);

    const combinedStatus = childrenStatuses.some((c) => c !== ownStatus) ? CheckStatus.PARTIALLY : ownStatus;
    resultMap.set(current?.id ?? -1, combinedStatus);
    return combinedStatus;
};

export const aggregateIsSelectableMap = (
    current: TreeNode | undefined,
    _childrenStatuses: boolean[],
    resultMap: Map<number, boolean>,
): boolean => {
    const cachedValue = resultMap.get(current?.id ?? -1);
    if (cachedValue !== undefined) {
        return cachedValue;
    }

    resultMap.set(current?.id ?? -1, current?.selectable ?? true);
    return current?.selectable ?? true;
};

/**
 * Returns a visitor function, that searches a value inside the tree an returns its path from the root the the matching node. If found, the
 * path ends with the value to match for. The values of the nodes on the path form the result array, starting with the value of the root
 * node.
 *
 * @param {number} targetId
 * @returns {(Visitor<number[] | null>)} Visitor that returns path from root to a node that holds the target value, or null if no node found
 */
export const findPath =
    (targetId: number): Visitor<number[] | null> =>
    (currentNode: TreeNode | undefined, childrenResults: (number[] | null)[]): number[] | null => {
        const id = currentNode !== undefined ? currentNode.id : undefined;
        const matchingPath = childrenResults.find((child) => child != null);
        if (matchingPath != null) {
            if (id !== undefined) {
                matchingPath.unshift(id);
            }
            return matchingPath;
        }
        return id !== undefined && id === targetId ? [id] : null;
    };

/**
 * Creates a visitor function, that searches the target value inside the tree and returns the matching node (if any)
 *
 * @param {number} targetId value to search for
 * @returns {Visitor<TreeNode | null>} Visitor that returns the node with target value or null
 */
export const findNode =
    (targetId: number): Visitor<TreeNode | null> =>
    (current: TreeNode | undefined, childrenResults: (TreeNode | null)[]) => {
        return current !== undefined && current.id === targetId ? current : (childrenResults.find((n) => n != null) ?? null);
    };

export function findNodes(targets: number[]): Visitor<TreeNode[] | null> {
    return (current: TreeNode | undefined, childrenResults: (TreeNode[] | null)[]) => {
        const children = childrenResults.filter(nonNullable).flat();
        if (current === undefined || !targets.includes(current.id)) {
            return children;
        }
        return [current, ...children];
    };
}

/**
 * Visitor function: Generates all paths from root node(s) to all leaf nodes. If includeInner is true, also the paths to the inner nodes are
 * returned. A path is described by an ordered list of tree nodes.
 *
 * If no current node is provided, only the paths of the children are returned.
 *
 * @param {TreeNode | undefined} current node(s) to start with
 * @param {TreeNode[][][]} childrenResults paths of the children nodes
 * @param {boolean} includeInner whether inner nodes or only leaf nodes should also generate paths
 * @returns {TreeNode[][]} array of generated paths
 */
export const generatePaths = (current: TreeNode | undefined, childrenResults: TreeNode[][][], includeInner: boolean): TreeNode[][] => {
    const ids = safeFlatMap(childrenResults);

    if (current?.id !== undefined) {
        ids.forEach((element) => {
            element.unshift(current);
        });
        if (includeInner || (childrenResults != null && childrenResults.length <= 0)) {
            ids.push([current]);
        }
    }
    return ids;
};

/**
 * A combination of generatePaths and aggregateSelection. This returns a list paths of type {status: CheckStatus, path: node[]}.
 * status indicates if the whole subtree is selected. In that case, only the path to the root node of the subtree part of the output.
 * Leaf nodes will have their status according to their value and the given selectedValues.
 * @param {TreeNode | undefined} current node to start with
 * @param {PathWithSelection[][]} childrenResults path's with selection of children nodes
 * @param {number[]} selectedValues current selected node's ids
 * @returns {PathWithSelection[]} array of paths and subtree selection statuses inside the tree, capped at the root of fully selected subtrees
 */
export const generatePathsWithSelection = (
    current: TreeNode | undefined,
    childrenResults: PathWithSelection[][],
    selectedValues: number[],
): PathWithSelection[] => {
    let status = getSelectionStatus(current, selectedValues);
    if (status === CheckStatus.YES) {
        status = childrenResults.every((c) => c.every((cc) => cc.status === CheckStatus.YES)) ? CheckStatus.YES : CheckStatus.NO;
    }

    const currentPath: PathWithSelection = { status, path: current !== undefined ? [current] : [] };
    if (status !== CheckStatus.YES) {
        // merge children paths with current node
        const childPaths = safeFlatMap(childrenResults);

        if (current?.id !== undefined) {
            childPaths.forEach((e) => e.path.unshift(current));
            // add current node
            childPaths.push(currentPath);
        }
        return childPaths;
    }
    return [currentPath];
};

/**
 * Visitor function: Collect all values inside the subtree of the given node.
 *
 * If no node provided, returns all children ids.
 *
 * @param {TreeNode | undefined} node node to start with
 * @param {number[][]} childrenResults values of all subtrees of the children nodes
 * @returns {number[]} array of node values
 */
export const getIds = (node: TreeNode | undefined, childrenResults: number[][]): number[] => {
    // collapse children values into a single array
    const ids =
        Array.isArray(childrenResults) && childrenResults.length > 0
            ? childrenResults.reduce((acc, childIds) => [...acc, ...childIds], [])
            : [];
    if (node?.id !== undefined) {
        ids.push(node.id);
    }
    return ids;
};

/**
 * Return all nodes of the tree as flat list.
 *
 * If no node is provided, it returns all children nodes (and their descendants)
 *
 * @param {TreeNode | undefined} node node(s) to start with
 * @param {TreeNode[][]} childrenResults list of nodes of its children
 * @returns {TreeNode[]} array of all nodes within this subtree
 */
export const flattenTree = (node: TreeNode | undefined, children: TreeNode[][]): TreeNode[] => {
    const childNodes = Array.isArray(children) && children.length > 0 ? children.reduce((acc, ids) => [...acc, ...ids], []) : [];
    if (node?.id !== undefined) {
        childNodes.push(node);
    }
    return childNodes;
};

export const searchByName = (node: TreeNode | undefined, childrenResults: TreeNode[][], search: string): TreeNode[] => {
    const mergedChildren = safeFlatMap(childrenResults);

    if (node == null) {
        // (artificial) root node
        return mergedChildren;
    }

    const isMatch = (node as NamedTreeNode).name.toLowerCase().includes(search.toLowerCase());

    if (mergedChildren.length === 0 && !isMatch) {
        return [];
    }

    return [node, ...mergedChildren];
};

export const memoizedGetPathsWithSelection = createSelector(
    (data: TraversableNode, value: number[]) => data,
    (data: TraversableNode, value: number[]) => value,
    (data, value) => traverseTree(data, generatePathsWithSelection, value),
);

export const memoizedGetPaths = createSelector(
    (data: TraversableNode, includeInner: boolean) => data,
    (data: TraversableNode, includeInner: boolean) => includeInner,
    (data, includeInner) => traverseTree(data, generatePaths, includeInner),
);

/**
 * Flattens an array of tree nodes and optionally replaces the value of each leaf with its full path.
 */
export const useFlatTree = (fieldType: string, options: IEntity[], valuesWithFullPath = false) => {
    return useMemo(() => {
        if (fieldType !== "tree") {
            return null;
        }
        const paths = memoizedGetPaths(options as TreeNodeDto[], true) as TreeNodeDto[][] | null;
        if (paths == null) {
            return null;
        }
        return paths
            .filter((path) => path.length > 0)
            .map((path) => {
                if (valuesWithFullPath) {
                    const nameDe = renderPath(path, "nameDe");
                    const nameEn = renderPath(path, "nameEn");
                    return { ...path[path.length - 1], nameDe, nameEn };
                }
                return path[path.length - 1];
            });
    }, [fieldType, options, valuesWithFullPath]);
};

export const isLeaf = (node: TreeNode): boolean => {
    const hasChildren = Boolean(node.children?.length);
    return !hasChildren;
};

export function renderPath(path: Record<"name", unknown>[]): string;
export function renderPath<S extends string>(path: Record<S, unknown>[], key: S): string;
export function renderPath(path: Record<string, unknown>[], key = "name"): string {
    return path.map((node) => node[key ?? "name"]).join(" > ");
}
