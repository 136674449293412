import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNewRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import UploadIcon from "@mui/icons-material/Upload";
import {
    Button,
    Grid,
    IconButton,
    ListItem,
    ListItemIcon,
    listItemIconClasses,
    ListItemText,
    Menu,
    menuClasses,
    MenuItem,
    menuItemClasses,
    styled,
} from "@mui/material";
import { AclNamespaces, AclPermissions, UserDto } from "api-shared";
import { TFunction } from "i18next";
import { Location } from "react-router-dom";
import PrivacyPolicyLink from "../../components/PrivacyPolicyLink";
import UnstyledNavLink from "../../components/UnstyledNavLink";
import UserAvatar from "../../components/user/UserAvatar";
import UserName from "../../components/user/UserName";
import { useUserHasAccessPermissionQuery, useUserHasPermissionQuery } from "../../domain/permissions";
import { useLanguage } from "../../hooks/useLanguage";
import useMenu from "../../hooks/useMenu";
import { getUserMenuNavItems, NavItems } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";
import useUserLogout from "./useUserLogout";

const AvatarIconButton = styled(IconButton)(({ theme }) => ({
    padding: 0,
    marginRight: theme.spacing(3),
    transition: theme.transitions.create("margin"),
    [theme.breakpoints.down("lg")]: {
        marginRight: theme.spacing(1.5),
    },
}));

const UserNameListItem = styled(ListItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    pointerEvents: "none",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
}));

const UserMenu = styled(Menu)(({ theme }) => ({
    [`& .${menuClasses.list}`]: {
        paddingTop: 0,
    },
}));

const UserMenuNavItem = styled(MenuItem)(({ theme }) => ({
    minHeight: theme.spacing(4.5),
    [`& .${listItemIconClasses.root}`]: {
        minWidth: theme.spacing(4.5),
    },
    [`&.${menuItemClasses.selected}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        [`& .${listItemIconClasses.root}`]: {
            color: "inherit", // apply contrastText color to icon as well
        },
    },
})) as typeof MenuItem; // https://github.com/mui/material-ui/issues/15759#issuecomment-984553630

interface INavbarUserButtonProps {
    user: UserDto | null;
    location: Location;
    translate: TFunction;
}

const Icons = {
    [NavItems.Settings.route]: SettingsRoundedIcon,
    [NavItems.SuperAdminSection.route]: AccountTreeRoundedIcon,
    [NavItems.AdminSection.route]: SupervisorAccountRoundedIcon,
    [NavItems.PrivacyPolicyDe.route]: OpenInNewIcon,
    [NavItems.PrivacyPolicyEn.route]: OpenInNewIcon,
    [NavItems.ExcelImport.route]: UploadIcon,
};

const NavbarUserButton = ({ user, location, translate }: INavbarUserButtonProps) => {
    const { menuProps, openMenu, closeMenu } = useMenu();
    const language = useLanguage();

    const logout = useUserLogout();

    const hasSuperAdminAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.SuperAdmin);
    const hasAdminAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.Admin);
    const hasExcelImportPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.Api,
        permission: AclPermissions.Import,
        fact: {},
    });

    if (
        !hasSuperAdminAccessPermissionQuery.isSuccess ||
        !hasAdminAccessPermissionQuery.isSuccess ||
        !hasExcelImportPermissionQuery.isSuccess
    ) {
        return null;
    }

    if (user == null) {
        return (
            <Grid container spacing={2}>
                <Grid item>
                    <Button variant="contained" startIcon={<OpenInNewIcon />} component={PrivacyPolicyLink} target="_blank">
                        {translate(translationKeys.VDLANG_PRIVACY_POLICY)}
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" component="a" sx={{ mr: 3 }} href="/">
                        {translate("Login")}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    const menuItems = getUserMenuNavItems(
        language,
        hasSuperAdminAccessPermissionQuery.data.hasPermission,
        hasAdminAccessPermissionQuery.data.hasPermission,
        hasExcelImportPermissionQuery.data.hasPermission,
    );

    return (
        <>
            <UserMenu {...menuProps} MenuListProps={{ dense: false }}>
                <UserNameListItem divider>
                    <ListItemText
                        primaryTypographyProps={{
                            maxWidth: 320,
                            noWrap: true,
                            textOverflow: "ellipsis",
                            variant: "body2",
                        }}
                    >
                        <UserName user={user} translate={translate} withBadge />
                    </ListItemText>
                </UserNameListItem>
                {menuItems.map((item) => {
                    const Icon = Icons[item.route];
                    return (
                        <UnstyledNavLink key={item.route} to={item.route} target={item.external ? "_blank" : undefined} onClick={closeMenu}>
                            {({ isActive }) => (
                                <UserMenuNavItem selected={isActive}>
                                    <ListItemIcon>
                                        <Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={translate(item.label)} primaryTypographyProps={{ variant: "body2" }} />
                                </UserMenuNavItem>
                            )}
                        </UnstyledNavLink>
                    );
                })}
                <UserMenuNavItem onClick={logout}>
                    <ListItemIcon>
                        <LogoutRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={translate(translationKeys.VDLANG_SECTIONS_LOGOUT)}
                        primaryTypographyProps={{ variant: "body2" }}
                    />
                </UserMenuNavItem>
            </UserMenu>
            <AvatarIconButton onClick={openMenu} data-testid="navbar-user-button">
                <UserAvatar user={user} size={40} />
            </AvatarIconButton>
        </>
    );
};

export default NavbarUserButton;
