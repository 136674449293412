import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import HourglassFullRoundedIcon from "@mui/icons-material/HourglassFullRounded";
import { styled, SvgIconProps } from "@mui/material";
import React from "react";

const FilledHourglass = styled(HourglassFullRoundedIcon)(({ theme }) => ({
    color: theme.palette.action.active,
}));

const UnfilledHourglass = styled(HourglassEmptyRoundedIcon)(({ theme }) => ({
    color: theme.palette.action.active,
}));

interface IEffortIconProps extends SvgIconProps {
    filled: boolean;
}

const EffortIcon = React.forwardRef<SVGSVGElement, IEffortIconProps>(({ filled, ...other }, ref) => {
    if (filled) {
        return <FilledHourglass {...other} ref={ref} />;
    }
    return <UnfilledHourglass {...other} ref={ref} />;
});

export default EffortIcon;
