import { Popover } from "@mui/material";
import { UserDto } from "api-shared";
import { TFunction } from "i18next";
import React, { Fragment, useState } from "react";
import { translationKeys } from "../../translations/main-translations";
import Tooltip from "../Tooltip";
import SingleUserButton, { ISingleUserButtonProps } from "./SingleUserButton";
import SingleUserContent, { ISingleUserContentProps } from "./SingleUserContent";

interface ISingleUserProps
    extends Pick<ISingleUserContentProps, "users" | "available" | "hintText">,
        Pick<ISingleUserButtonProps, "variant" | "avatarSize"> {
    update: (id: number | null) => void;
    closeOnSelect?: boolean;
    disabled?: boolean;
    user: UserDto | null | undefined;
    translate: TFunction;
    testId?: string;
    className?: string;
    hideRemoveIcon?: boolean;
}

const SingleUser = ({
    update,
    closeOnSelect,
    disabled,
    user,
    translate,
    users,
    available,
    hintText,
    testId,
    variant,
    avatarSize,
    className,
    hideRemoveIcon,
}: ISingleUserProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const close = () => setAnchorEl(null);
    const open = (event: React.MouseEvent<HTMLElement>) => "currentTarget" in event && setAnchorEl(event.currentTarget);

    const updateUser = (userId: number | null) => {
        update(userId);
        if (closeOnSelect) {
            close();
        }
    };

    return (
        <Fragment>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={close}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <SingleUserContent
                    disabled={disabled}
                    user={user}
                    translate={translate}
                    updateUser={updateUser}
                    users={users}
                    available={available}
                    hintText={hintText}
                    hideRemoveIcon={hideRemoveIcon}
                />
            </Popover>
            <Tooltip
                title={translate(
                    user == null ? translationKeys.VDLANG_RESPONSIBLE_ASSIGN_PERSON : translationKeys.VDLANG_RESPONSIBLE_CURRENT_PERSON,
                )}
                data-testid={testId ? `${testId}_assignPerson` : null}
            >
                <SingleUserButton
                    user={user}
                    translate={translate}
                    disabled={disabled}
                    onClick={open}
                    variant={variant}
                    avatarSize={avatarSize}
                    className={className}
                />
            </Tooltip>
        </Fragment>
    );
};
export default SingleUser;
