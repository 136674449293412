import { InfiniteData, QueryKey, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

/**
 * This hook prevents react query from refetching all previously loaded pages except the first one for a given
 * queryKey if the page navigated to again. All other pages will be removed from the query cache on cleanup.
 * This is a approved workaround for the default behavior by a react-query maintainer: https://github.com/TanStack/query/discussions/3576#discussioncomment-7038671
 *
 * This should only be used for infinite queries!
 */
export default function useInfiniteQueryPageReset(queryKey: QueryKey) {
    const queryClient = useQueryClient();
    useEffect(() => {
        return () => {
            queryClient.setQueryData<InfiniteData<unknown>>(queryKey, (existingData) => {
                return existingData != null
                    ? {
                          pageParams: [existingData.pageParams[0]],
                          pages: [existingData.pages[0]],
                      }
                    : undefined;
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
