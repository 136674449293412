import { DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps, Stack, styled } from "@mui/material";
import { PropsWithChildren, ReactElement, ReactNode } from "react";
import patternImage from "../../static/images/dialog-header-pattern.svg";
import MoreActionsMenu from "../MoreActionsMenu";

const Grow = styled("div")({
    flexGrow: 1,
    minWidth: 0,
});

const ShapedDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    position: "relative",
    backgroundImage: `linear-gradient(90deg, ${theme.palette.valueBlue.main} 0%, ${theme.palette.primary.main} 100%)`,
    backgroundColor: theme.palette.primary.main,
    lineHeight: "normal",
}));

const Pattern = styled("img")(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
}));

interface IDialogTitleProps extends MuiDialogTitleProps {
    actions?: ReactElement[];
    onClick?: () => void;
    shaped?: boolean;
    titleLabel?: ReactNode;
}

const DialogTitle = ({ actions = [], onClick, children, shaped, titleLabel, ...other }: PropsWithChildren<IDialogTitleProps>) => {
    const Component = shaped ? ShapedDialogTitle : MuiDialogTitle;

    const showMoreActionsMenu = Array.isArray(actions) && actions.length > 1;
    const hasSingleAction = Array.isArray(actions) && actions.length === 1;
    return (
        <Component {...other}>
            {shaped ? <Pattern srcSet={patternImage} alt="" /> : null}
            <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
                {children}
                {titleLabel !== undefined && titleLabel}
                <Grow />
                {showMoreActionsMenu ? <MoreActionsMenu actions={actions} /> : null}
                {hasSingleAction ? actions[0] : null}
            </Stack>
        </Component>
    );
};
export default DialogTitle;
