import DragIndicator from "@mui/icons-material/DragIndicatorRounded";
import { styled } from "@mui/material";
import { TFunction } from "i18next";
import React from "react";
import Tooltip from "../Tooltip";

const DragIndicatorWithCursor = styled(DragIndicator)({
    cursor: "grab",
});

interface IDragHandleProps {
    translate: TFunction;
}

const stopPropagation = (e: React.MouseEvent): void => e.stopPropagation();

const DragHandle = ({ translate }: IDragHandleProps) => (
    <Tooltip title={translate("dragHandle")}>
        <DragIndicatorWithCursor
            fontSize="small"
            onMouseDown={
                // avoid any mousedown events triggering the ripple effect, when used inside of a button component
                stopPropagation
            }
        />
    </Tooltip>
);

export default React.memo(DragHandle);
