import TodayIcon from "@mui/icons-material/TodayRounded";
import { Stack, styled, Typography } from "@mui/material";
import { FlatMeasureDto } from "api-shared";
import momentTimezone from "moment-timezone";
import { Link } from "react-router-dom";
import { RouteFor } from "../../lib/routes";
import MeasureIdChip from "../MeasureIdChip";
import MoneyChip from "../MoneyChip";
import Tooltip from "../Tooltip";

const titleClass = "measureTitleClass";

const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.primary,
    // enable underline on hover only for the title, not other text of children, e.g. chips
    textDecoration: "none",
    [`&:hover .${titleClass}`]: {
        textDecoration: "underline",
    },
}));

interface ISubtaskListMeasureGroupHeaderProps {
    measure: FlatMeasureDto;
    effect?: number;
    effectivenessStart?: Date;
}

const SubtaskListMeasureGroupHeader = ({ measure, effect, effectivenessStart }: ISubtaskListMeasureGroupHeaderProps) => {
    let daysUntilEffectiveness: string | null = null;

    if (effectivenessStart !== undefined) {
        const days = momentTimezone.utc(effectivenessStart).startOf("day").diff(momentTimezone.utc().startOf("day"), "days");
        daysUntilEffectiveness = `${days > 0 ? "+" : ""}${days}`;
    }

    return (
        <Tooltip title={measure.title}>
            <StyledLink to={RouteFor.measure.forId(measure.id)}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <MeasureIdChip measureId={measure.clientIid} />
                    <Typography className={titleClass} noWrap lineHeight="normal">
                        {measure.title}
                    </Typography>
                    {effect !== undefined ? <MoneyChip value={effect} /> : null}
                    {daysUntilEffectiveness != null ? (
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <TodayIcon color="action" sx={{ fontSize: "1rem" }} />
                            <Typography color="textSecondary" lineHeight="normal">
                                {daysUntilEffectiveness}
                            </Typography>{" "}
                        </Stack>
                    ) : null}
                </Stack>
            </StyledLink>
        </Tooltip>
    );
};

export default SubtaskListMeasureGroupHeader;
