import { Avatar, AvatarProps } from "@mui/material";
import { UserBasicDto, UserDto } from "api-shared";
import React from "react";
import { baseUrl } from "../../lib/api";
import { formatUserShort } from "../../lib/formatters";
import systemUserAvatar from "../../static/images/system_user_avatar.svg";

export interface IUserAvatarProps extends AvatarProps {
    isSystemUser?: boolean;
    component?: React.ElementType;
    user?: UserDto | UserBasicDto | null;
    size?: number;
}
const UserAvatar = React.forwardRef<any, IUserAvatarProps>(({ isSystemUser, user, size, style, children, ...other }, ref) => {
    const combinedStyle =
        size != null
            ? {
                  width: size,
                  height: size,
                  fontSize: size / 2, // make sure that font does not exceed circle
                  lineHeight: "normal",
                  ...style,
              }
            : style;

    const additionalProps = {} as Partial<AvatarProps>;
    let namePlaceholder = "";
    if (isSystemUser) {
        additionalProps.src = systemUserAvatar;
    } else if (user) {
        const { imageHash } = user;
        namePlaceholder = formatUserShort(user);

        if (imageHash) {
            additionalProps.src = `${baseUrl}static/${imageHash}`;
        } else {
            additionalProps.children = namePlaceholder;
        }
    } else {
        // display just an empty avatar if there is no user given
        additionalProps.children = children;
    }

    return <Avatar ref={ref} alt={namePlaceholder} style={combinedStyle} {...additionalProps} {...other} />;
});

export default UserAvatar;
