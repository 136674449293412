import { ImageListItem, ImageListItemBar, Link, List, ListItem, ListItemText, styled, Typography } from "@mui/material";
import { CompanyDto, nonNullable, UserBasicDto, UserDto } from "api-shared";
import { TFunction } from "i18next";
import { Fragment } from "react";
import { translationKeys } from "../../translations/main-translations";
import Tooltip from "../Tooltip";
import UserAvatar from "./UserAvatar";
import UserName from "./UserName";

const WIDTH = 250;

interface IUserDetailsProps {
    user: UserDto | UserBasicDto;
    company?: CompanyDto;
    translate: TFunction; // pass translate function explicitly so it can be used outside localization context (e.g. mentions)
}

const LargeAvatar = styled(UserAvatar)(({ theme }) => ({
    ...theme.typography.h2,
}));

const AvatarCaption = styled(ImageListItemBar)({
    background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
});

const SlimList = styled(List)({
    maxWidth: WIDTH,
});

const UserDetails = (props: IUserDetailsProps) => {
    const {
        user,
        user: { position, email, mobileNumber, companyId },
        company,
        translate,
    } = props;

    return (
        <div>
            <ImageListItem component="div">
                <LargeAvatar user={user} size={WIDTH} variant="square" />
                <AvatarCaption
                    title={
                        <Fragment>
                            <Tooltip title={<UserName user={user} translate={translate} />}>
                                <Typography color="inherit" noWrap>
                                    <UserName user={user} translate={translate} />
                                </Typography>
                            </Tooltip>
                            {position != null ? (
                                <Tooltip title={position}>
                                    <Typography color="inherit" variant="caption" noWrap>
                                        {position}
                                    </Typography>
                                </Tooltip>
                            ) : null}
                        </Fragment>
                    }
                />
            </ImageListItem>
            {email != null || companyId != null || (mobileNumber != null && mobileNumber !== "") ? (
                <SlimList dense>
                    {company != null ? (
                        <ListItem>
                            <ListItemText
                                primary={company.name}
                                secondary={[company.city, company.country].filter(nonNullable).join(", ")}
                                secondaryTypographyProps={{ noWrap: true }}
                            />
                        </ListItem>
                    ) : null}
                    {email != null ? (
                        <ListItem>
                            <ListItemText
                                primary="Email"
                                secondary={
                                    <Link color="textSecondary" href={`mailto:${email}`} target="_blank">
                                        {email}
                                    </Link>
                                }
                                secondaryTypographyProps={{ noWrap: true }}
                            />
                        </ListItem>
                    ) : null}
                    {mobileNumber != null && mobileNumber !== "" ? (
                        <ListItem>
                            <ListItemText
                                primary={translate("MobileNumber")}
                                secondary={
                                    <Link color="textSecondary" href={`tel:${mobileNumber}`} target="_blank">
                                        {mobileNumber}
                                    </Link>
                                }
                                secondaryTypographyProps={{ noWrap: true }}
                            />
                        </ListItem>
                    ) : null}
                    {"tier" in user ? (
                        <ListItem>
                            <ListItemText
                                primary={translate(translationKeys.VDLANG_USER_TIER)}
                                secondary={translate(`${translationKeys.VDLANG_USER_TIERS}.${user.tier}`)}
                            />
                        </ListItem>
                    ) : null}
                </SlimList>
            ) : null}
        </div>
    );
};

export default UserDetails;
