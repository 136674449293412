import LaunchRounded from "@mui/icons-material/LaunchRounded";
import { Box, Card, CardContent, InputLabel, Link, Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import UserEntryWithPopup from "../../components/user/UserEntryWithPopup";
import { useCurrentClient } from "../../domain/client";
import { useUsersQuery } from "../../domain/users";
import { useLanguage } from "../../hooks/useLanguage";
import { ExternalRoutes } from "../../lib/routes";
import { Language } from "../../translations/main-translations";

const KnowledgeBaseLink = styled(Link)(({ theme }) => ({
    padding: theme.spacing(0.75, 0),
    textDecoration: "none",
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const FooterWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const ContentWrapper = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(0.75, 2, 0, 2),
    gap: theme.spacing(0.25),
}));

const CardBody = styled(CardContent)(({ theme }) => ({
    padding: 0,
    "&:last-child": {
        padding: 0,
    },
}));

const SmallInputLabel = styled(InputLabel)(({ theme }) => ({
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightMedium,
}));

function NavbarHelpPopupContent() {
    const usersQuery = useUsersQuery();
    const { t: translate } = useTranslation();
    const client = useCurrentClient();
    const language = useLanguage();

    const knowledgeBaseURL =
        language === Language.EN ? "https://knowledge.valuedesk.de/en/knowledge" : "https://knowledge.valuedesk.de/knowledge";

    return (
        <Card sx={{ maxWidth: 300 }} elevation={2}>
            <CardBody>
                <HeaderWrapper>
                    <Typography variant="body1" sx={{ fontWeight: 500 }} component="div">
                        {translate("Support")}
                    </Typography>
                </HeaderWrapper>
                <ContentWrapper>
                    {client.internalContactId && (
                        <Stack>
                            <SmallInputLabel>{translate("internalContactPerson")}:</SmallInputLabel>
                            <UserEntryWithPopup
                                disableGutters
                                avatarProps={{ size: 24 }}
                                user={usersQuery.isSuccess ? usersQuery.data.find((user) => user.id === client.internalContactId) : null}
                            />
                        </Stack>
                    )}
                    <KnowledgeBaseLink color="inherit" underline="none" href={knowledgeBaseURL} target="_blank">
                        <Stack direction="row" gap={1} alignItems="center">
                            <LaunchRounded color="action" />
                            <Typography sx={{ textDecoration: "none" }}>{translate("KnowledgeCenter")}</Typography>
                        </Stack>
                    </KnowledgeBaseLink>
                </ContentWrapper>
                <FooterWrapper>
                    <Typography variant="caption" component="div">
                        <span>{translate("TechnicalQuestions")}</span>
                        <Link href={ExternalRoutes.support}>support@valuedesk.de</Link>
                    </Typography>
                </FooterWrapper>
            </CardBody>
        </Card>
    );
}

export default NavbarHelpPopupContent;
