import CheckIcon from "@mui/icons-material/CheckRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Collapse, FormHelperText, Grid, styled } from "@mui/material";
import { green } from "@mui/material/colors";
import { PasswordValidityCriteria, getPasswordWeaknesses, isValidPassword } from "api-shared";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import PasswordInput from "./PasswordInput";
import { IValidatedInputProps } from "./ValidatedInput";

const ValidCriteria = styled("div")(({ theme }) => ({
    // same green as in notifications
    color: green[600],
}));

const InvalidCriteria = styled("div")(({ theme }) => ({
    color: theme.palette.error.main,
}));

interface ISetPasswordInputProps extends IValidatedInputProps {
    onPasswordChange: (newPassword: string, isValid: boolean) => void;
}
const SetPasswordInput = ({ onPasswordChange, ...other }: ISetPasswordInputProps) => {
    const [password, setPassword] = useState("");
    const [touched, setTouched] = useState(false);

    const { t: translate } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setPassword(value);
        onPasswordChange(value, isValidPassword(value));
    };

    const weaknesses = getPasswordWeaknesses(password);

    // get enum values an numbers
    const criteria = Object.values(PasswordValidityCriteria).filter(
        (value): value is PasswordValidityCriteria => typeof value === "number",
    );

    return (
        <Fragment>
            <PasswordInput
                value={password}
                onChange={handleChange}
                isValid={(newValue) => typeof newValue === "string" && isValidPassword(newValue)}
                onFocus={() => setTouched(true)}
                {...other}
            />
            <Collapse in={touched}>
                <FormHelperText component="div">
                    <Grid container spacing={1}>
                        {criteria.map((c) => (
                            <Grid key={c} item component={weaknesses.indexOf(c) > -1 ? InvalidCriteria : ValidCriteria}>
                                <Grid container alignItems="center">
                                    {weaknesses.indexOf(c) > -1 ? <CloseIcon fontSize="inherit" /> : <CheckIcon fontSize="inherit" />}
                                    {translate(`password_criteria.${c}`)}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </FormHelperText>
            </Collapse>
        </Fragment>
    );
};

export default SetPasswordInput;
