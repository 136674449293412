import WarningAmber from "@mui/icons-material/WarningAmberRounded";
import { AlertProps, Alert as MuiAlert, alertClasses, styled } from "@mui/material";

interface IAlertProps extends AlertProps {
    dense?: boolean;
}

const DenseAlert = styled(MuiAlert)(({ theme }) => ({
    padding: theme.spacing(1),
    flexWrap: "nowrap",

    [`& .${alertClasses.icon}`]: {
        fontSize: "1rem",
        padding: 0,
        flexShrink: 0,
        lineHeight: 1.5,
    },
    [`& .${alertClasses.message}`]: {
        padding: 0,
    },
}));

const Alert = ({ dense, icon, ...alertProps }: IAlertProps) => {
    const Component = dense ? DenseAlert : MuiAlert;

    // custom warning icon
    const customizedIcon = alertProps.severity === "warning" ? <WarningAmber fontSize="inherit" /> : icon;

    return <Component icon={customizedIcon} {...alertProps} />;
};

export default Alert;
