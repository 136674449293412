import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { makeCurrentUserQuery, useTrackedSessionMutation } from "../domain/users";
import { useHubspotTrackingCode } from "../infrastructure/hubspot/provider";

const useTrackedHubspotSession = () => {
    const emailQuery = useQuery({
        ...makeCurrentUserQuery(),
        enabled: false,
        select: (currentUser) => currentUser.email,
    });

    const trackingCode = useHubspotTrackingCode();

    useEffect(() => {
        if (!emailQuery.isSuccess) {
            return;
        }
        const email = emailQuery.data;
        if (email != null) {
            trackingCode.identify({ email });
            trackingCode.trackPageView();
        }
    }, [trackingCode, emailQuery.data, emailQuery.isSuccess]);
};

const useTrackedSession = () => {
    useTrackedHubspotSession();

    const trackSessionMutation = useTrackedSessionMutation();

    // Keep record of the last user that has been tracked, so the same user is not tracked multiple times during a session
    const lastTrackedUserId = useRef<number | null>(null);

    const query = useQuery({
        ...makeCurrentUserQuery(),
        enabled: false,
        select: (currentUser) => currentUser.id,
    });

    useEffect(() => {
        // track user only if it's id has changed from last tracking
        if (query.isSuccess && query.data !== lastTrackedUserId.current) {
            lastTrackedUserId.current = query.data;
            trackSessionMutation.mutate();
        }
    }, [query.isSuccess, query.data, trackSessionMutation]);

    // Reset the tracked id on error, so next successful login will be tracked again
    useEffect(() => {
        if (query.isError) {
            lastTrackedUserId.current = null;
        }
    }, [query.isError]);
};

export default useTrackedSession;
