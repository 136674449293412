import { styled } from "@mui/material";
import { IdeaDto } from "api-shared";
import { CellProps } from "react-table";
import { RouteFor } from "../../lib/routes";
import TableLinkCell from "./TableLinkCell";

const RootContainer = styled("div")(({ theme }) => ({
    position: "relative",
    margin: theme.spacing(-1, -2),
    padding: theme.spacing(1, 2),
}));

const TableIdeaTitleCell = (props: CellProps<IdeaDto>) => {
    const idea = props.row.original;

    return (
        <RootContainer>
            <TableLinkCell link={RouteFor.opportunities.forId(idea.id)} {...props} />
        </RootContainer>
    );
};

export default TableIdeaTitleCell;
