import { mergeCamelized } from "api-shared";
import { TFunction } from "i18next";
import { reportError } from "../infrastructure/sentry";
import { Language } from "../translations/main-translations";

const REPORT_CACHE: Record<string, unknown> = {};

/**
 * Translates an object that contains its own translations as properties. propertyName and language will be combined into a camelized
 * property name, e.g. "name" and "en" will lookup the translation in "data.nameEn".
 *
 * @param {*} data
 * @param {string} propertyName
 * @param {Language} language
 * @returns {string}
 */
export const translateFromProperty = (data: any, propertyName: string, language: Language): string => {
    const key = mergeCamelized(propertyName, language);
    return data[key] ?? "";
};

/**
 * Try to translate the key and return it if there is no translation.
 *
 * @param {TFunction} translate - A translation function
 * @param {string} key - A string that may or may not be a translation key
 * @return {string}
 */
export const tryTranslate = (translate: TFunction, key: string): string => translate(key, { defaultValue: null }) ?? key;

export const notifySentryAboutTranslation = (translationId: string) => {
    if (REPORT_CACHE[translationId] != null) {
        // Skip reporting same missing translations over an over again
        return translationId;
    }
    // Make sure all events of a missing translation key will be grouped as a single issue. Usually those also have the same stracktrace
    // do modify grouping if no translationId is provided (this might happen in different places)
    const fingerprint = translationId != null ? ["Translation not found", translationId] : undefined;
    // include translationId in error's name for better overview in sentries issue list
    reportError(new Error(`Translation not found: ${translationId}`), {
        fingerprint,
        tags: { translation_id: translationId },
        extras: { translationId },
    });

    REPORT_CACHE[translationId] = true;
    return translationId;
};

export const notifySentryAboutInterpolation = (interpolationParam: string) => {
    if (REPORT_CACHE[interpolationParam] != null) {
        return interpolationParam;
    }
    const fingerprint = interpolationParam != null ? ["Missing interpolation parameter", interpolationParam] : undefined;
    reportError(new Error(`Missing interpolation parameter: ${interpolationParam}`), {
        fingerprint,
        tags: { interpolation_param: interpolationParam },
        extras: { interpolationParam },
    });

    REPORT_CACHE[interpolationParam] = true;
    return interpolationParam;
};
