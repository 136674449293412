import { Chip, ChipProps, styled } from "@mui/material";

const PurpleChip = styled(Chip)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightMedium,
    height: 16,
    backgroundColor: theme.palette.magicPurple.main,
    color: theme.palette.common.white,
    // ensure that line-height does not break vertical centering of text
    lineHeight: "normal",
    cursor: "inherit",
}));

interface IMeasureIdChipProps extends ChipProps {
    measureId: number;
}

const MeasureIdChip = ({ measureId, ...chipProps }: IMeasureIdChipProps) => <PurpleChip label={measureId} {...chipProps} />;

export default MeasureIdChip;
