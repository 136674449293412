// other locales than en must be added manually
import { QueryClient } from "@tanstack/react-query";
import { TranslationListDto } from "api-shared";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { TranslationQueryKeys } from "../domain/translation";
import { notifySentryAboutInterpolation, notifySentryAboutTranslation } from "../lib/translate";
import { errorTranslations } from "./error-translations";
import { fieldTranslations } from "./field-translations";
import { historyTranslations } from "./history-translations";
import { Language, languages, translations } from "./main-translations";
import { feedbackTranslations } from "./notification-translations";

type SimpleTranslation = string[];
// Record type does not work here, would break recursiveness
type RecursiveTranslations = { [key: string]: SimpleTranslation | RecursiveTranslations };

function extractDataForLanguage(translations: RecursiveTranslations, language: string) {
    const index = languages.indexOf(language as Language);
    return Object.entries(translations).reduce(
        (acc, [key, values]) => {
            acc[key] = Array.isArray(values) ? values[index] : extractDataForLanguage(values, language);
            return acc;
        },
        {} as Record<string, unknown>,
    );
}

const AllStaticTranslations: RecursiveTranslations = {
    ...translations,
    ...errorTranslations,
    ...historyTranslations,
    ...feedbackTranslations,
    ...fieldTranslations,
};

// Store as module variable, so JS module singletons avoid costly recomputation in each test run
const I18N_RESOURCES = languages.reduce(
    (bundles, language) => {
        bundles[language] = {
            translation: extractDataForLanguage(AllStaticTranslations, language as string),
        };
        return bundles;
    },
    {} as Record<string, any>,
);

function onMissingInterpolationParam(_text: string, value: string[]) {
    const [, interpolationParam] = value;
    notifySentryAboutInterpolation(interpolationParam);
}

export function setupTranslations(queryClient: QueryClient) {
    const queryClientRefetch = async (key: string) => {
        const newData: TranslationListDto = await queryClient.fetchQuery(TranslationQueryKeys.default);
        if (!newData.some((t) => t.key === key)) {
            notifySentryAboutTranslation(key);
        }
    };

    const handleMissingTranslation = (
        lngs: readonly string[],
        ns: string,
        key: string,
        fallbackValue: string,
        updateMissing: boolean,
        options: any,
    ): void => {
        if (fallbackValue !== key) {
            // fallback value has been provided, so a missing key is kind of expected
            // no need to report to sentry in this case
            return;
        }
        queryClientRefetch(key);
    };

    return i18next
        .use(initReactI18next) // passes i18next down to react-i18next
        .init({
            // no language is configured here, that happens during boostrapping
            supportedLngs: languages,
            fallbackLng: Language.EN,
            // always add bundles for all languages for global translations
            // on demand loading results in issues with client specific translations
            resources: I18N_RESOURCES,
            saveMissing: true, // needed so that missingKeyHandler is called
            missingKeyHandler: handleMissingTranslation,
            missingInterpolationHandler: onMissingInterpolationParam,
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
            react: {
                bindI18n: "languageChanged", // trigger re-render on language changed events
                transSupportBasicHtmlNodes: true, // allow Trans component to render html tags specified below
                transKeepBasicHtmlNodesFor: ["br", "strong", "i", "em"],
            },
        });
}
