import { Button, FormHelperText, InputProps, TextField, Typography, TypographyProps, inputBaseClasses, styled } from "@mui/material";
import React, { Fragment, useState } from "react";
import { isEnterEvent, isEscapeEvent } from "../../lib/keybindings";

const RootButton = styled(Button, { shouldForwardProp: (name) => name !== "inputSize" })<{ inputSize: InputProps["size"] }>(
    ({ theme, inputSize }) => ({
        justifyContent: "flex-start",
        textAlign: "left", // override browser default of "center" for all buttons
        // The sizes need to match the paddings applied by TextField/Input
        ...(inputSize === "small" && { padding: theme.spacing(0.75, 1.5) }),
        ...(inputSize === "medium" && { padding: theme.spacing(1, 1.5) }),
        ...(inputSize === "large" && { padding: theme.spacing(1.25, 1.5) }),
        ...(inputSize === "extralarge" && { padding: theme.spacing(1.75, 1.5) }),
    }),
);

const RestingTypography = styled(Typography)({
    overflowWrap: "anywhere",
    verticalAlign: "middle",
});

const TypographyTextField = styled(TextField, { shouldForwardProp: (name) => name !== "typographyVariant" })<{
    typographyVariant: TypographyProps["variant"];
}>(({ theme, typographyVariant }) => ({
    [`& .${inputBaseClasses.root}`]: {
        padding: 0,
    },
    [`& .${inputBaseClasses.input}`]: {
        ...(typographyVariant != null && typographyVariant !== "inherit" && theme.typography[typographyVariant]),
    },
}));

interface IEditableTextProps extends TypographyProps, Pick<InputProps, "size" | "placeholder" | "disabled"> {
    text: string;
    defaultIsEditing?: boolean;
    onTextSave: (text: string) => any;
    onTextChange?: (text: string) => any;
    onEditStart?: () => any;
    onEditEnd?: () => any;
    onEditAbort?: () => any;
    errorText?: React.ReactNode;
    resetTextOnEmptyBlur?: boolean;
}

const EditableText = ({
    text,
    placeholder,
    variant = "body2",
    disabled = false,
    defaultIsEditing = false,
    onTextSave,
    onTextChange,
    onEditStart,
    onEditEnd,
    onEditAbort,
    errorText,
    resetTextOnEmptyBlur = true,
    className,
    size = "medium",
    ...typographyProps
}: IEditableTextProps) => {
    const [isEditing, setIsEditing] = useState(!!defaultIsEditing);
    const [editedText, setEditedText] = useState(text);

    const startEditing = () => {
        setEditedText(text);
        setIsEditing(true);
        typeof onEditStart === "function" && onEditStart();
    };

    const stopEditing = () => {
        setIsEditing(false);
        typeof onEditEnd === "function" && onEditEnd();
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedText(event.target.value);
        typeof onTextChange === "function" && onTextChange(event.target.value);
    };

    const onBlur = () => {
        if (editedText.trim() !== "") {
            stopEditing();
            onTextSave(editedText);
        } else if (resetTextOnEmptyBlur) {
            stopEditing();
            setEditedText(text);
        } else {
            onTextSave(editedText);
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (isEnterEvent(event)) {
            onBlur(); // Trigger blur on enter key
        } else if (isEscapeEvent(event)) {
            stopEditing();
            setEditedText(text);
            typeof onEditAbort === "function" && onEditAbort();
        }
    };

    const onClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (!disabled) {
            startEditing();
        }
    };
    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => event.target.select();

    return isEditing ? (
        <TypographyTextField
            autoFocus
            fullWidth
            multiline
            margin="none"
            value={editedText}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            error={Boolean(errorText)}
            helperText={errorText}
            className={className}
            size={size}
            typographyVariant={variant}
        />
    ) : (
        <Fragment>
            <RootButton
                fullWidth
                variant="text"
                color="inherit"
                disabled={disabled}
                onClick={onClick}
                className={className}
                inputSize={size}
            >
                <RestingTypography variant={variant} {...typographyProps}>
                    {text}
                </RestingTypography>
            </RootButton>
            {errorText != null ? (
                <FormHelperText error sx={{ ml: 2 }}>
                    {errorText}
                </FormHelperText>
            ) : null}
        </Fragment>
    );
};

export default EditableText;
