import { ExportLocale } from "api-shared";
import { useCallback } from "react";
import { delocalizeDecimalSeparator, localizeDecimalSeparator, removeGroupingSeparator } from "../lib/currency";
import { formatNumber } from "../lib/formatters";
import { Language } from "../translations/main-translations";
import { useLanguage } from "./useLanguage";

export type RationalNumberFormatter = (value: number, options?: RationalNumberFormatterOptions) => string | null;

interface UseRationalNumberProps {
    language?: Language | ExportLocale;
}

interface RationalNumberFormatterOptions {
    numberOfFractionalDigits?: number;
    language?: Language | ExportLocale;
}

export interface UseRationalNumberResult {
    /**
     * Format value with localized separators.
     *
     *
     * @param {(string | number)} value
     * @returns
     */
    formatRationalNumber: RationalNumberFormatter;

    /**
     * Removes thousand separators and replaces the decimal separator by the standard one: .
     *
     * @param {string} value
     * @returns
     */
    formatRationalNumberInput: (value: string) => string;

    /**
     * Format a rational value to be put as value into a RationalNumberInput component. Replaces the default decimal separator by the localized
     * version
     *
     * @param {string} value
     */
    sanitizeRationalNumberInput: (value: string) => string;
}

const useRationalNumber = ({ language: propsLanguage }: UseRationalNumberProps = {}): UseRationalNumberResult => {
    const userLanguage = useLanguage();

    const hookLanguage = propsLanguage ?? userLanguage;

    const localFormatNumber: RationalNumberFormatter = useCallback(
        (value, { numberOfFractionalDigits, language = hookLanguage } = {}) => {
            if (value == null || Number.isNaN(value)) {
                return null;
            }

            return formatNumber(value, numberOfFractionalDigits, language);
        },
        [hookLanguage],
    );

    const sanitizeRationalNumberInput = useCallback(
        (value: string) => {
            const ungrouped = removeGroupingSeparator(value, hookLanguage);
            return delocalizeDecimalSeparator(ungrouped, hookLanguage);
        },
        [hookLanguage],
    );

    const formatRationalNumberInput = useCallback((value: string) => localizeDecimalSeparator(value, hookLanguage), [hookLanguage]);

    return {
        formatRationalNumber: localFormatNumber,
        formatRationalNumberInput,
        sanitizeRationalNumberInput,
    };
};

export default useRationalNumber;
