import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FiscalMoment, MeasureCalculationGranularity } from "api-shared";
import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import { translationKeys } from "../translations/main-translations";

export const IntervalFromGranularity = {
    [MeasureCalculationGranularity.MONTH]: "month" as const,
    [MeasureCalculationGranularity.FISCAL_QUARTER]: "quarter" as const,
    [MeasureCalculationGranularity.FISCAL_YEAR]: "year" as const,
};

export function calendarToFiscal(
    calendarMoment: Moment,
    fiscalYearStart: number,
    granularity: MeasureCalculationGranularity,
): FiscalMoment {
    const interval = IntervalFromGranularity[granularity];
    return {
        fiscalYearStart,
        calendarMoment,
        fiscalMoment: calendarMoment.clone().subtract(fiscalYearStart, "months").startOf(interval),
    };
}

export function fiscalToCalendar(m: Moment | FiscalMoment, fiscalYearStart: number): Moment {
    return "calendarMoment" in m ? m.calendarMoment : m.clone().add(fiscalYearStart, "months");
}

export function formatFiscalUnit(input: FiscalMoment, granularity: MeasureCalculationGranularity, translate: TFunction): string {
    const { fiscalYearStart, calendarMoment, fiscalMoment } = input;

    if (granularity === MeasureCalculationGranularity.MONTH) {
        // no need for special formatting of fiscal units, format as "Apr. 2020"
        return calendarMoment.format("MMM YYYY");
    }

    if (fiscalYearStart === 0) {
        // isSame does not work, because fiscalMoment is startOf() the interval
        // no custom fiscal year configured, format as "Q1 2020" or "2020"
        const prefix = granularity === MeasureCalculationGranularity.FISCAL_QUARTER ? "[Q]Q " : "";
        return fiscalMoment.format(`${prefix}YYYY`);
    }

    // select proper fiscal prefix (FY/FQ)
    const fiscalPrefix = translate(`${translationKeys.VDLANG_MEASURE_CALCULATION_GRANULARITY}.${granularity}`);
    const quarter = granularity === MeasureCalculationGranularity.FISCAL_QUARTER ? "Q" : "";

    // fiscal year will overlap with next calendar year, so show as 20/21 for both yearly and quarter views
    const nextYear = fiscalMoment.clone().add(1, "year").format("YY");

    return fiscalMoment.format(`[${fiscalPrefix}]${quarter} YY[/${nextYear}]`);
}

/**
 * Create an AdapterMoment that formats quarters and years as fiscal quarter/year when a custom fiscal year is configured
 *
 * This is achieved by two ways:
 * - Customize the formatting output fo output "FQ1 20/21" respective "FY 20/21"
 * - Replace monthly behavior by quarterly behavior because the date pickers do not support quarters yet
 *
 * For monthly granularity regular calendar formatting is used, regardless of a custom fiscal year
 *
 * @param {number} fiscalYearStart
 * @param {MeasureCalculationGranularity} granularity
 * @param {TFunction} translate
 * @returns
 */
export const makeAdapterFiscalMoment = (fiscalYearStart: number, granularity: MeasureCalculationGranularity, translate: TFunction) => {
    if (granularity === MeasureCalculationGranularity.MONTH) {
        return AdapterMoment;
    }
    // The dateio adapter for moment is typed as class, but is in fact just an object with some bound functions
    // So inheritance seems to work, but references to super are not possible, e.g. calling super.date() as fallback does not work
    // Workaround: create an instance of the parent class as member and call functions on them instead of using super
    // Rebind the functions of the super instance, so in case they access "this", it will point to the child instance
    class AdapterFiscalMoment extends AdapterMoment {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __super: AdapterMoment;

        constructor(...p: ConstructorParameters<typeof AdapterMoment>) {
            super(...p);
            this.__super = new AdapterMoment(...p);

            // rebind function of super to this child object, so if they call any other functions, they will be called on the child
            this.__super.date = this.__super.date.bind(this);
            this.__super.format = this.__super.format.bind(this);
        }

        date: typeof this.__super.date = (value) => {
            return value == null ? calendarToFiscal(moment(), fiscalYearStart, granularity).fiscalMoment : this.__super.date(value);
        };

        getMonth: typeof this.__super.getMonth = (date) => {
            return date.quarter();
        };

        setMonth: typeof this.__super.setMonth = (date, count) => {
            return date.quarter(count);
        };

        format: typeof this.__super.format = (date, formatString) => {
            if (formatString === "monthShort") {
                const label =
                    fiscalYearStart > 0
                        ? translate(
                              `${translationKeys.VDLANG_MEASURE_CALCULATION_GRANULARITY}.${MeasureCalculationGranularity.FISCAL_QUARTER}`,
                          )
                        : "Q";
                return date.format(`[${label}]Q`);
            }

            if (formatString === "year") {
                return getFiscalYearText(date, fiscalYearStart, translate);
            }

            if (formatString === "monthAndYear") {
                return formatFiscalUnit(
                    { fiscalYearStart, calendarMoment: fiscalToCalendar(date, fiscalYearStart), fiscalMoment: date },
                    MeasureCalculationGranularity.FISCAL_QUARTER,
                    translate,
                );
            }
            return this.__super.format(date, formatString);
        };

        startOfMonth: typeof this.__super.startOfMonth = (date) => {
            return date.startOf("quarter");
        };

        getMonthArray: typeof this.__super.getMonthArray = (date) => {
            return Array.from(Array(4)).map((e, i) =>
                date
                    .clone()
                    .startOf("quarter")
                    .quarter(i + 1),
            );
        };
    }
    return AdapterFiscalMoment;
};

export function getFiscalYearText(date: moment.Moment, fiscalYearStart: number, translate: TFunction): string {
    return formatFiscalUnit(
        { fiscalYearStart, calendarMoment: fiscalToCalendar(date, fiscalYearStart), fiscalMoment: date },
        MeasureCalculationGranularity.FISCAL_YEAR,
        translate,
    );
}

export function compareFiscalMoments(a: FiscalMoment, b: FiscalMoment): number {
    return a.fiscalMoment.valueOf() - b.fiscalMoment.valueOf();
}

export const formatFiscalYear = (fiscalYearStart: Moment) => {
    const currentYear = fiscalYearStart.format("Y");
    const nextYear = fiscalYearStart.clone().add(1, "years").format("YY");
    const startsInJanuary = fiscalYearStart.month() === 0;
    return startsInJanuary ? currentYear : `${currentYear}/${nextYear}`;
};
