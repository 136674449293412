import ChevronLeftIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import { IconButton, SvgIcon, styled } from "@mui/material";
import React from "react";

const ButtonComponent = styled(IconButton, { shouldForwardProp: (name) => name !== "role" })<{ role: "previous" | "next" }>(
    ({ theme, role }) => ({
        color: theme.palette.common.white,
        top: "50%",
        transform: "translateY(-50%)",
        position: "absolute",
        zIndex: 1, // always put above siblings (image container spanning full Width)
        ...(role === "previous" && { left: 0 }),
        ...(role === "next" && { right: 0 }),
    }),
);

interface ICarouselButtonProps {
    role: "previous" | "next";
    show: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    label: string;
    className?: string;
}

const CarouselButton = ({ role, show, onClick, label, className }: ICarouselButtonProps) => {
    let Icon = role === "previous" ? ChevronLeftIcon : ChevronRightIcon;
    if (!show) {
        /* show empty placeholder icon, to occupy same space as a regular icon */
        Icon = SvgIcon;
    }

    const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        show && onClick(e);
        // prevent event propagation even if not visible, this avoids automatically leaving the carousel when clicking after reaching
        // beginning/end
        e.preventDefault();
        e.stopPropagation();
    };

    // if the button is not to be shown (at beginning/end of carousel), it is still rendered as "enabled" (but with not icon/animation) so
    // it is able to receive and stop click events. This avoids close/open file events when performing an additional click at the
    // beginning/end of the carousel
    return (
        <ButtonComponent className={className} onClick={clickHandler} role={role} aria-label={label} disableRipple={!show}>
            <Icon />
        </ButtonComponent>
    );
};

export default CarouselButton;
