import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
    IconButton,
    InputAdornment,
    Popover,
    PopoverProps,
    alpha,
    emphasize,
    inputAdornmentClasses,
    inputBaseClasses,
    popoverClasses,
    styled,
} from "@mui/material";
import classNames from "classnames";
import { TFunction } from "i18next";
import React, { useState } from "react";
import SearchInput from "../../components/input/SearchInput";
import useNavbarSearch from "../../hooks/useNavbarSearch";
import SearchResultPopup from "./SearchResultPopup";

const Root = styled("span")(({ theme }) => ({
    marginRight: theme.spacing(3.5),
    transition: theme.transitions.create("margin"),
    [theme.breakpoints.down("lg")]: {
        marginRight: theme.spacing(1),
    },
}));

interface INavbarSearchProps {
    translate: TFunction;
    searchKey: string;
    updateSearchKey: (newKey: string) => void;
}

const NoPaperPopover = styled(({ className, classes, ...props }: PopoverProps) => (
    // workaround for nested css styling not working with portals
    // See: https://mui.com/material-ui/guides/interoperability/#portals
    <Popover className={className} classes={{ ...classes, paper: classNames(classes?.paper, className) }} {...props} />
))({
    // Apply styles only to the paper component, not the root component itself
    [`&.${popoverClasses.paper}`]: {
        backgroundColor: "transparent",
        borderRadius: 0,
    },
});

const LightClearIcon = styled(CancelRoundedIcon)(({ theme }) => ({
    color: alpha(theme.palette.valueBlue.contrastText, 0.54),
}));

const DarkSearchInput = styled(SearchInput)(({ theme }) => ({
    width: theme.spacing(26),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.valueBlue.contrastText,
    backgroundColor: theme.palette.valueBlue.light,
    "&:hover": {
        backgroundColor: emphasize(theme.palette.valueBlue.light, theme.palette.action.hoverOpacity),
    },
    [`& .${inputBaseClasses.root}, .${inputAdornmentClasses.root}`]: {
        color: "inherit",
    },
    "& fieldset": {
        // hide any borders from hover/focus states
        // Exceptionally use !important to avoid creating too complex nested css using hover and focus states
        borderColor: "transparent !important",
    },
}));

const NavbarSearch = ({ searchKey, updateSearchKey, translate }: INavbarSearchProps) => {
    const { searchInProgress, results, isValidKey } = useNavbarSearch(searchKey);

    const inputRef = React.createRef<HTMLDivElement>();

    const [inputAnchor, setInputAnchor] = useState<HTMLButtonElement | null>(null);
    const [resultAnchor, setResultAnchor] = useState<HTMLDivElement | null>(null);

    const handleKeyChange = (key: string) => {
        if (inputRef.current != null) {
            setResultAnchor(inputRef.current);
        }
        updateSearchKey(key);
    };

    const hideInput = () => {
        setInputAnchor(null);
        setResultAnchor(null);
    };

    const openInput = (event: React.MouseEvent<HTMLButtonElement>) => {
        setInputAnchor(event.target as any);
        setResultAnchor(null);
    };

    const clearSearchKey = (e?: React.MouseEvent | React.KeyboardEvent | HTMLElement) => {
        if (e !== undefined && !(e instanceof HTMLElement)) {
            e.preventDefault();
            e.stopPropagation();
        }
        updateSearchKey("");
    };

    return (
        <Root>
            <IconButton size="small" onClick={openInput} color="inherit">
                <SearchRoundedIcon />
            </IconButton>
            <NoPaperPopover
                open={Boolean(inputAnchor)}
                anchorEl={inputAnchor}
                marginThreshold={0}
                onClose={hideInput}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                TransitionProps={{
                    onExited: clearSearchKey,
                }}
            >
                <DarkSearchInput
                    ref={inputRef}
                    searchKey={searchKey}
                    translate={translate}
                    onReset={clearSearchKey}
                    onChange={handleKeyChange}
                    onFocus={({ target }) => handleKeyChange(target.value)}
                    autoFocus
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchRoundedIcon color="inherit" />
                            </InputAdornment>
                        ),
                    }}
                    clearIcon={<LightClearIcon fontSize="small" />}
                    hideSearchIcon
                />
            </NoPaperPopover>
            <SearchResultPopup
                anchorEl={resultAnchor}
                show={Boolean(resultAnchor)}
                onHide={hideInput}
                searchKey={searchKey}
                isValidKey={isValidKey}
                translate={translate}
                results={results}
                searchInProgress={searchInProgress}
            />
        </Root>
    );
};

export default NavbarSearch;
