import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    BasicMessageDto,
    CreateConvertedIdeaRequestBody,
    CreateIdeaRequestBody,
    DiscardIdeaRequestBody,
    DiscardIdeaRequestParams,
    IdeaAttributeListDto,
    IdeaDto,
    IdeaHistory,
    IdeaListDto,
    ReactionType,
    SearchIdeaRequestBody,
    UpdateIdeaRequest,
} from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../lib/api";
import { NotificationType } from "../lib/notifications";
import { FeedbackTranslationKeys } from "../translations/notification-translations";
import { Endpoint, EndpointQueryKeys } from "./endpoint";
import { useCurrentUserId } from "./users";

const IDEAS_PATH = "ideas";
const IDEA_ATTRIBUTES_PATH = "idea-attributes";
const CONVERTED_IDEA_PATH = "converted-idea";

export const IdeasQueryKeys = {
    all: [IDEAS_PATH] as const,
    attributes: [IDEA_ATTRIBUTES_PATH] as const,
    search: (body: SearchIdeaRequestBody) => [...IdeasQueryKeys.all, "search", body] as const,
    byId: (ideaId: number | null) => [...IdeasQueryKeys.all, "byId", ideaId] as const,
    history: (ideaId: number) => [...IdeasQueryKeys.all, ideaId, "history"] as const,
};

interface IdeaSearchQueryOptions {
    refetchInterval?: number;
    ignoreErrors?: boolean;
    enabled?: boolean;
    onSuccess?: (response: IdeaListDto) => void;
}

export const useIdeaSearchQuery = (body: SearchIdeaRequestBody, options?: IdeaSearchQueryOptions) => {
    const { refetchInterval, ignoreErrors, enabled = true, onSuccess } = options ?? {};
    return useQuery({
        enabled,
        queryKey: IdeasQueryKeys.search(body),
        queryFn: ({ signal, queryKey }) => apiPost<IdeaListDto>(`${IDEAS_PATH}/search`, queryKey[2], { signal }),
        refetchInterval,
        // Filtering ideas sends queries with a new set of query parameters => keep the old data until new data is available
        // to avoid flickering in the ui
        keepPreviousData: true,
        meta: {
            ignoreErrors,
        },
        onSuccess: (data) => onSuccess && onSuccess(data),
    });
};

export const useCreateIdeaMutation = (onSuccess?: (response: IdeaDto) => void) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (body: CreateIdeaRequestBody) => apiPost<IdeaDto>(IDEAS_PATH, body),
        onSuccess: (response) => {
            queryClient.invalidateQueries(IdeasQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_IDEA_CREATED));
            onSuccess?.(response);
        },
    });
};

export const useUpdateIdeaMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ ideaId, ...body }: UpdateIdeaRequest) => apiPatch<IdeaDto>(`${IDEAS_PATH}/${ideaId}`, body),
        onSuccess: () => {
            queryClient.invalidateQueries(IdeasQueryKeys.all);
            const softDeletableEndpoints = [Endpoint.Projects, Endpoint.CustomValues];
            [...softDeletableEndpoints].forEach((endpoint) => {
                queryClient.invalidateQueries(EndpointQueryKeys.forEndpoint(endpoint));
            });
        },
    });
};

export const useDiscardIdeaMutation = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ ideaId, ...content }: DiscardIdeaRequestParams & DiscardIdeaRequestBody) =>
            apiPost<BasicMessageDto>(`discarded-idea/${String(ideaId)}`, content),
        onSuccess: () => {
            queryClient.invalidateQueries(IdeasQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_IDEA_DISCARDED));
        },
    });
};

export const useIdeaQuery = (ideaId: number | null) => {
    return useQuery({
        queryKey: IdeasQueryKeys.byId(ideaId),
        // ideaId cannot be null anymore when query function is executed because of enabled flag
        queryFn: ({ signal }) => apiGet<IdeaDto>(`${IDEAS_PATH}/${ideaId ?? 0}`, { signal }),
        enabled: ideaId !== null,
    });
};

export const useIdeaHistoryQuery = (ideaId: number) => {
    return useQuery({
        queryKey: IdeasQueryKeys.history(ideaId),
        queryFn: ({ signal, queryKey }) => apiGet<IdeaHistory[]>(`${IDEAS_PATH}/${queryKey[1]}/history`, { signal }),
    });
};

export const useIdeaAttributesQuery = (enabled = true) => {
    return useQuery({
        enabled,
        queryKey: IdeasQueryKeys.attributes,
        queryFn: ({ signal }) => apiGet<IdeaAttributeListDto>(IDEA_ATTRIBUTES_PATH, { signal }),
    });
};

export const useIdeaReactionMutation = () => {
    const queryClient = useQueryClient();
    const currentUserId = useCurrentUserId();

    function setIdeaReactions(ideaId: number, idea?: IdeaDto, enabled?: boolean): IdeaDto | undefined {
        if (idea?.id !== ideaId) {
            return idea;
        }
        const newDataMultipleUsers =
            idea?.reactions.filter((reaction) => reaction.userId !== currentUserId).length === 0
                ? [{ userId: currentUserId, type: ReactionType.Thumbsup, ideaId }]
                : [
                      ...(idea?.reactions.filter((reaction) => reaction.userId !== currentUserId) ?? []),
                      { userId: currentUserId, type: ReactionType.Thumbsup, ideaId },
                  ];
        const newDataEnabledValidUser =
            enabled && currentUserId ? newDataMultipleUsers : idea?.reactions.filter((reaction) => reaction.userId !== currentUserId);
        const newData = {
            ...idea,
            reactions: idea !== undefined ? newDataEnabledValidUser : [],
        };
        return {
            ...newData,
        } as IdeaDto;
    }

    return useMutation({
        mutationFn: ({ ideaId, enabled }: { ideaId: number; enabled?: boolean; searchQuery?: SearchIdeaRequestBody }) => {
            const url = `${IDEAS_PATH}/${ideaId}/reactions`;
            return enabled ? apiPost<BasicMessageDto>(url, {}) : apiDelete<BasicMessageDto>(url);
        },

        onSuccess: (_, { ideaId, searchQuery, enabled }) => {
            // Update the query data for one id instead of triggering refetches
            queryClient.setQueryData<IdeaDto>(IdeasQueryKeys.byId(ideaId), (oldData) => setIdeaReactions(ideaId, oldData, enabled));
            queryClient.invalidateQueries(IdeasQueryKeys.byId(ideaId));

            // Also update the search query data to show reactions in idea table and grid view without refetching
            // No invalidation needed due to polling
            queryClient.setQueryData<IdeaListDto>(IdeasQueryKeys.search(searchQuery), (oldData) => {
                if (!oldData) {
                    return oldData;
                }
                return oldData.map((idea) => {
                    const ideaWithUpdatedReaction = setIdeaReactions(ideaId, idea, enabled);
                    return ideaWithUpdatedReaction ?? idea;
                });
            });
        },
    });
};

export const useConvertIdeaMutation = (onSuccess?: (response: { measureId: number }) => void) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation({
        mutationFn: (data: CreateConvertedIdeaRequestBody) => apiPost<{ measureId: number }>(CONVERTED_IDEA_PATH, data),
        onSuccess: (response) => {
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_IDEA_TO_MEASURE));

            queryClient.invalidateQueries(IdeasQueryKeys.all);
            onSuccess?.(response);
        },
    });
};
