import { useTranslation } from "react-i18next";
import ErrorBanner from "../../components/ErrorBanner";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { translationKeys } from "../../translations/main-translations";

const PageNotFound = () => {
    useDocumentTitle("Page not found");
    const { t: translate } = useTranslation();
    return <ErrorBanner title={translate("Page not found")}>{translate(translationKeys.VDLANG_CHECK_LINK)}</ErrorBanner>;
};

export default PageNotFound;
