import { ToggleButton, ToggleButtonGroup, styled, toggleButtonClasses } from "@mui/material";
import { Reaction, ReactionType } from "api-shared";
import { useTranslation } from "react-i18next";
import { useAllUsers } from "../../domain/users";
import useDialog from "../../hooks/useDialog";
import { translationKeys } from "../../translations/main-translations";
import ReactionSummaryDialog from "./ReactionSummaryDialog";

const RootButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    maxHeight: theme.spacing(4),
    [`& .${toggleButtonClasses.root}`]: {
        textTransform: "none",
        padding: theme.spacing(1, 1.5),
        color: theme.palette.text.primary,
        "&.Mui-selected": {
            color: theme.palette.primary.main,
            backgroundColor: "transparent",
        },
    },
}));

interface SingleReactionButtonProps {
    reactions: Omit<Reaction<any>, "type">[];
    onToggle: (enabled?: boolean) => void;
    userId: number;
    type: ReactionType;
    disabled: boolean;
}

const SingleReactionButton = ({ reactions, onToggle, userId, type, disabled }: SingleReactionButtonProps) => {
    const { t } = useTranslation();
    const dialog = useDialog();
    const users = useAllUsers();

    const hasUserReacted = reactions.some((reaction) => reaction.userId === userId);
    const mappedReactions = reactions.map((reaction) => ({ userId: reaction.userId, type }));
    const sortedReactionGroups = new Map([[type, mappedReactions]]);
    const reactionConfig = new Map([[type, { icon: "👍", translationKey: translationKeys.VDLANG_REACTION_EMOJI_LIKE }]]);

    return (
        <RootButtonGroup size="small" value={hasUserReacted ? type : undefined} color="standard">
            <ToggleButton value={type} disabled={disabled} onClick={() => onToggle(!hasUserReacted)}>
                👍 {t(translationKeys.VDLANG_REACTION_EMOJI_LIKE)}
            </ToggleButton>
            <ToggleButton disabled={reactions.length === 0} onClick={dialog.open} value={-1}>
                {reactions.length}
            </ToggleButton>
            <ReactionSummaryDialog
                open={dialog.isOpen}
                onClose={dialog.close}
                users={users}
                reactionConfig={reactionConfig}
                sortedReactionGroups={sortedReactionGroups}
            />
        </RootButtonGroup>
    );
};

export default SingleReactionButton;
