import { styled } from "@mui/material";
import { UserBasicDto, UserDto } from "api-shared";
import { TFunction } from "i18next";
import { formatUser, getStatusAdornment } from "../../lib/formatters";
import InlineChip from "../InlineChip";

function validateProps(
    translate: TFunction | undefined,
    user: UserDto | UserBasicDto | null | undefined,
    userId: number | null | undefined,
    users: UserDto[] | undefined,
) {
    if (translate != null && typeof translate !== "function") {
        // eslint-disable-next-line no-console
        console.warn("Got non-function translate prop");
    }
    if (user == null && userId == null && users == null) {
        // eslint-disable-next-line no-console
        console.warn("Either user or userId and users have to be passed to UserName");
    }
    if ((userId != null && users == null) || (userId == null && users != null)) {
        // eslint-disable-next-line no-console
        console.warn("Only one of users and userId has been provided, falling back to user");
    }
}

interface IUserNameProps {
    user?: UserDto | UserBasicDto | null;
    userId?: number | null;
    users?: UserDto[];
    withBadge?: boolean;
    translate: TFunction;
}

// Vertically center text and chip by having their vertical center aligned on the height of the parent using vertical-align: middle
// They will be centered on the baseline+x-height/2 of the parent, which is good enough to call it vertical centering here

const VerticalCenteredSpan = styled("span")({
    verticalAlign: "middle",
});

const UserName = ({ user, userId, users, withBadge = false, translate }: IUserNameProps) => {
    validateProps(translate, user, userId, users);

    const options = {
        withAdornment: !withBadge, // suppress status when displaying badge
        translate,
    };

    const resolvedUser = userId != null && Array.isArray(users) ? users.find((u) => u.id === userId) : user;
    const name = resolvedUser != null ? formatUser(resolvedUser, options) : null;

    if (resolvedUser == null || !withBadge) {
        return <>{name}</>;
    }

    // manually put status text in a badge behind username
    const adornment = getStatusAdornment(resolvedUser.status, translate);

    if (adornment == null) {
        return <>{name}</>;
    }

    return (
        // Don't use flexbox here, as it breaks browsers capability to shorten text with ellipsis
        // Strictly use inline elements here, because this allows the browser to ellipsize elements one after each other
        // here: chip first, then name
        <>
            <VerticalCenteredSpan>{name}</VerticalCenteredSpan>
            <InlineChip label={adornment} size="small" variant={adornment == null ? "outlined" : undefined} />
        </>
    );
};
export default UserName;
