import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircleRounded";
import { styled, SvgIconProps } from "@mui/material";
import { SubTaskStatus } from "api-shared";

const StatusIconComponents = {
    [SubTaskStatus.STATUS_OPEN]: styled(CheckCircleOutlineIcon)(({ theme }) => ({
        color: theme.palette.text.disabled,
    })),
    [SubTaskStatus.STATUS_COMPLETED]: styled(CheckCircleIcon)(({ theme }) => ({
        color: theme.palette.natureGreen.main,
    })),
};

interface ISubtaskStatusIconProps extends SvgIconProps {
    className?: string;
    status: SubTaskStatus;
}

const SubtaskStatusIcon = ({ status, ...other }: ISubtaskStatusIconProps) => {
    if (status === SubTaskStatus.STATUS_REMOVED) {
        return null;
    }

    const IconComponent = StatusIconComponents[status];
    return <IconComponent {...other} />;
};

export default SubtaskStatusIcon;
