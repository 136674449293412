import { Box, styled } from "@mui/material";
import { nonNullable } from "api-shared";
import { TFunction } from "i18next";
import React from "react";
import Alert from "../Alert";
import BaseDialog, { IBaseDialogProps } from "./BaseDialog";
import DialogBackButton from "./DialogBackButton";
import DialogButton from "./DialogButton";
import type { OverridableStringUnion } from "@mui/types";
import { AlertColor, AlertPropsColorOverrides } from "@mui/material/Alert/Alert";

const TertiaryDialogButton = styled(DialogButton)(({ theme }) => ({
    borderColor: theme.palette.defaultDark.main,
    color: theme.palette.text.primary,
    ":hover": {
        borderColor: theme.palette.defaultDark.main,
    },
}));

export interface IActionItemDialogProps
    extends Omit<IBaseDialogProps, "actions" | "translate" | "title">,
        Partial<Pick<IBaseDialogProps, "title">> {
    translate: TFunction;
    primary?: string;
    onPrimary?: (e: React.KeyboardEvent | React.MouseEvent) => void;
    primaryDisabled?: boolean;
    primaryDanger?: boolean;
    primaryIsTranslated?: boolean;
    secondary?: string;
    onSecondary?: (e: React.KeyboardEvent | React.MouseEvent) => void;
    secondaryDisabled?: boolean;
    tertiary?: string;
    onTertiary?: (e: React.KeyboardEvent | React.MouseEvent) => void;
    tertiaryDisabled?: boolean;
    item?: string;
    action?: string;
    keepDialog?: boolean;
    hintText?: string;
    hintSeverity?: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
}

// This component is intended to provide an easy way to add new dialogues that
// follow the styleguide and are of the scheme "action_item", like "create project"
// "edit supplier", and many others
const ActionItemDialog = ({
    secondary,
    onSecondary,
    secondaryDisabled,
    translate,
    primary,
    onPrimary,
    primaryDisabled,
    primaryDanger,
    primaryIsTranslated,
    tertiary,
    tertiaryDisabled,
    onTertiary,
    keepDialog,
    onClose,
    item,
    action,
    title,
    hintText,
    hintSeverity = "info",
    ...other
}: IActionItemDialogProps) => {
    let secondaryButton = <DialogBackButton key="back" translate={translate} onClose={onClose as React.MouseEventHandler} />;
    if (secondary != null) {
        secondaryButton = (
            <DialogButton
                name={secondary}
                key={secondary}
                onClick={onSecondary}
                disabled={secondaryDisabled}
                onClose={onClose as React.MouseEventHandler}
                translate={translate}
            />
        );
    }

    const primaryButton =
        primary != null && onPrimary != null ? (
            <DialogButton
                name={primary}
                key={primary}
                onClick={onPrimary}
                disabled={primaryDisabled}
                variant="contained"
                danger={primaryDanger}
                onClose={!keepDialog ? onClose : undefined}
                translate={!primaryIsTranslated ? translate : undefined}
                testId="submit"
            />
        ) : null;

    const tertiaryButton =
        tertiary != null ? (
            <TertiaryDialogButton
                name={tertiary}
                key={tertiary}
                onClick={onTertiary}
                disabled={tertiaryDisabled}
                onClose={onClose as React.MouseEventHandler}
                translate={translate}
            />
        ) : null;

    let generatedTitle = title;
    if (title == null && (action != null || item != null)) {
        const titleTranslationKey = [action, item].filter(nonNullable).join("_");
        generatedTitle = translate(titleTranslationKey);
    }

    const actions = [secondaryButton];
    if (primaryButton != null) {
        actions.push(primaryButton);
    }
    if (tertiaryButton != null) {
        // If tertiary button is rendered, add a spacer to move the button to the left-hand side
        actions.unshift(<Box key="tertiary_spacer" flexGrow={1} />);
        actions.unshift(tertiaryButton);
    }
    if (hintText != null && hintText.length > 0) {
        actions.unshift(
            <Alert severity={hintSeverity} dense>
                {hintText}
            </Alert>,
        );
    }

    return <BaseDialog title={generatedTitle} actions={actions} onClose={onClose} {...other} />;
};

export default ActionItemDialog;
