import { Dialog, Divider, IconButton, List, Stack, Tab, Tabs, Typography, styled } from "@mui/material";

import CloseIcon from "@mui/icons-material/CloseRounded";
import { Reaction, ReactionType, UserDto } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";
import UserEntry from "../user/UserEntry";
import EmojiIcon from "./EmojiIcon";

const DenseTab = styled(Tab)(({ theme }) => ({
    minHeight: 0,
    padding: theme.spacing(1.5, 0, 1, 0),
}));

type ReactionSummaryDialogProps<T extends ReactionType> = {
    open: boolean;
    onClose: () => void;
    reactionConfig: Map<T, { icon: string; translationKey: string }>;
    users: UserDto[];
    sortedReactionGroups: Map<T, Reaction<T>[]>;
};

const ALL_TAB = "all";

function getDialogTitle<T extends ReactionType>(
    t: TFunction,
    numberOfGivenReactions: number,
    sortedReactionGroups: Map<T, Reaction<T>[]>,
    reactionConfig: Map<T, { icon: string; translationKey: string }>,
) {
    const title = t(translationKeys.VDLANG_REACTION_REACTION, { count: numberOfGivenReactions });
    if (sortedReactionGroups.size > 1) {
        return title;
    }

    const mostFrequentReaction = [...sortedReactionGroups.keys()][0];
    const mostFrequentReactionLabel = reactionConfig.get(mostFrequentReaction)?.translationKey;
    if (mostFrequentReactionLabel == null) {
        return title;
    }

    return t(translationKeys.VDLANG_REACTION_REACTION_UNIFORM, { count: numberOfGivenReactions, type: t(mostFrequentReactionLabel) });
}

function ReactionSummaryDialog<T extends ReactionType>({
    open,
    onClose,
    reactionConfig,
    users,
    sortedReactionGroups,
}: Readonly<ReactionSummaryDialogProps<T>>) {
    const [tabValue, setTabValue] = useState<T | typeof ALL_TAB>(ALL_TAB);

    const { t } = useTranslation();

    const allReactions = Array.from(sortedReactionGroups.values()).flat();

    const numberOfGivenReactions = allReactions.length;

    const filteredUserReactions = tabValue === ALL_TAB ? allReactions : (sortedReactionGroups.get(tabValue) ?? []);

    const handleTabChange = (_: React.SyntheticEvent, newValue: T) => {
        setTabValue(newValue);
    };

    const hasUniformReactions = sortedReactionGroups.size === 1;

    const title = getDialogTitle(t, numberOfGivenReactions, sortedReactionGroups, reactionConfig);

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { minWidth: 400 } }}>
            <Stack>
                <Stack direction="row" justifyContent="space-between" py={1.5} px={2}>
                    <Typography variant="subtitle1">{title}</Typography>
                    <IconButton onClick={onClose} sx={{ p: 0 }}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                {!hasUniformReactions ? (
                    <Tabs value={tabValue} onChange={handleTabChange} sx={{ px: 2, minHeight: 0 }}>
                        <DenseTab value={ALL_TAB} label={`${t(translationKeys.VDLANG_ALL)} ${numberOfGivenReactions}`} />
                        {[...sortedReactionGroups.entries()].map(([reactionType, reactions]) => (
                            <DenseTab
                                key={reactionType}
                                iconPosition="start"
                                label={reactions.length}
                                value={reactionType}
                                icon={
                                    <EmojiIcon sx={{ fontSize: (theme) => theme.typography.body1.fontSize }}>
                                        {reactionConfig.get(reactionType)?.icon}
                                    </EmojiIcon>
                                }
                            />
                        ))}
                    </Tabs>
                ) : null}
                <Divider />
                <List>
                    {filteredUserReactions.map((reaction) => (
                        <UserEntry
                            key={reaction.userId}
                            avatarProps={{ size: 32 }}
                            user={users.find((u) => u.id === reaction.userId)}
                            translate={t}
                            action={!hasUniformReactions ? <EmojiIcon>{reactionConfig.get(reaction.type)?.icon}</EmojiIcon> : undefined}
                        />
                    ))}
                </List>
            </Stack>
        </Dialog>
    );
}

export default ReactionSummaryDialog;
