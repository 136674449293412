import { useQuery } from "@tanstack/react-query";
import type { AttributeCategoryListDto } from "api-shared";
import { apiGet } from "../lib/api";

const ATTRIBUTE_CATEGORIES_PATH = "attribute-categories";

export const AttributeQueryKeys = {
    categories: [ATTRIBUTE_CATEGORIES_PATH] as const,
};

export const useAttributeCategoriesQuery = () => {
    return useQuery({
        queryKey: AttributeQueryKeys.categories,
        queryFn: ({ signal }) => apiGet<AttributeCategoryListDto>(ATTRIBUTE_CATEGORIES_PATH, { signal }),
    });
};
