import { Chip, ChipProps, styled } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

export interface IIdeaIdChipProps extends ChipProps {
    ideaId: number;
}

const StyledChip = styled(Chip)(({ theme }) => ({
    fontSize: "0.625rem",
    height: 16,
    backgroundColor: blueGrey[50],
    // ensure that line-height does not break vertical centering of text
    lineHeight: "normal",
    cursor: "inherit",
    padding: 0,
    minWidth: 40,
    flexShrink: 0,
    fontWeight: theme.typography.fontWeightMedium,

    "&.MuiChip-clickable": {
        cursor: "pointer",
        ":hover": {
            backgroundColor: blueGrey[300],
        },
    },
}));

const IdeaIdChip = ({ ideaId, ...restProps }: IIdeaIdChipProps) => <StyledChip label={ideaId} {...restProps} />;

export default IdeaIdChip;
