import { Chip, styled, ThemeProvider, Typography, TypographyProps, useTheme } from "@mui/material";
import React, { useLayoutEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import { useCompanies } from "../domain/endpoint";
import { useAllUsers } from "../domain/users";
import { formatUserFromId } from "../lib/formatters";
import { markdownToSafeHtml } from "../lib/markdown";
import UnconnectedUserEntryWithPopup from "./user/UnconnectedUserEntryWithPopup";
import UserAvatar from "./user/UserAvatar";

const MentionsChip = styled("span")({
    maxWidth: "100%", // Ellipsize mention chips when too little space is available
});

const MentionsRoot = styled("div")(({ theme }) => ({
    overflowWrap: "anywhere",
    "& p": {
        margin: 0,
        marginBottom: theme.spacing(),
    },
    "& img": {
        maxWidth: "100%",
    },
    "& a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

interface IMarkdownMentionsProps extends TypographyProps<"div"> {
    markdown: string;
}

interface MentionElement {
    spanId: string;
    id: number;
}

const MarkdownMentions = React.forwardRef<HTMLDivElement, IMarkdownMentionsProps>(({ markdown, ...typographyProps }, ref) => {
    /**
     * This tracks the span IDs created by {@link renderMentionElement} which will be magically replaced by real
     * React components in {@link componentDidMount}, {@link componentDidUpdate}.
     *
     * @memberof MarkdownMentions
     */
    const magicMentionIds = useRef<MentionElement[]>([]);
    const { t: translate } = useTranslation();

    const theme = useTheme();

    const users = useAllUsers();
    const companies = useCompanies();

    const getNameForId = (id: number) => formatUserFromId(+id, users, { translate });

    const renderMentionElement = (id: number, name: string) => {
        // Only draw placeholders here - these will be replaced in componentDidMount/componentDidUpdate.
        const spanId = `magicMention${Math.random().toString(36).substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`;
        const chip = `<span id="${spanId}"></span>`;
        magicMentionIds.current.push({ spanId, id });

        return chip;
    };

    const replaceMentionPlaceholder = () => {
        magicMentionIds.current.forEach(({ spanId, id }) => {
            const user = users.find((u) => u.id === +id);
            if (user === undefined) {
                return;
            }

            const company = companies?.find(({ id }) => id === user.companyId);

            /* Replace the placeholder spans with real react elements. According to the docu
             * https://reactjs.org/docs/react-dom.html#render
             * these elements are in the scope of the React virtual dom handler and behave as
             * (new) child components of this Component.
             * We have to set the components to spans as the default divs are not allowed inside
             * various html tags (e.g. <p>) which will cause the react __html helper to escape those
             * resulting in unexpected line breaks.
             */
            const container = document.getElementById(spanId);
            if (container === null) {
                return;
            }
            const root = createRoot(container);
            root.render(
                // make sure to pass the current theme into the react subtree
                <ThemeProvider theme={theme}>
                    <UnconnectedUserEntryWithPopup
                        user={user}
                        triggerElement={
                            <Chip
                                avatar={<UserAvatar user={user} component="span" />}
                                label={formatUserFromId(+id, users, { translate })}
                                component={MentionsChip}
                                size="small"
                            />
                        }
                        translate={translate}
                        company={company}
                    />
                </ThemeProvider>,
            );
        });

        // We have created all elements by now. Next time there must be new ones.
        magicMentionIds.current = [];
    };

    // Render mentions EVERY time component was updated (no dependency array)
    // use layout effect by intention, because DOM needs to be ready
    useLayoutEffect(replaceMentionPlaceholder);

    return (
        <Typography component={MentionsRoot} {...typographyProps} ref={ref}>
            <div dangerouslySetInnerHTML={{ __html: markdownToSafeHtml(markdown, getNameForId, renderMentionElement) }} />
        </Typography>
    );
});

export default MarkdownMentions;
